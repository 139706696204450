import React, { useState } from 'react';

import { Modal } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';

import "../../css/Sidebar.css";
import "../../css/Components.css";

// Targetted help for Sandhi part and step 1 of sandhi analysis.
//  Shows 4 sandhi help pdfs in one modal 
const SandhiHelp =({open, setOpen}) =>{

	const [helpPDF, setHelpPDF] =useState("appendixD2.1-VowelSandhi.pdf");

	const allHelp =[
		{ "name":"Vowel sandhi", "pdf":"appendixD2.1-VowelSandhi.pdf" }, 
		{ "name":"Final stop sandhi", "pdf":"appendixD2.2.1-FinalStopSandhi.pdf" },
		{ "name":"Final nasal sandhi", "pdf":"appendixD2.2.2-FinalNasalSandhi.pdf" },
		{ "name":"Visarga sandhi", "pdf":"appendixD2.2.3-VisargaSandhi.pdf" },
	];

	return (
		<Modal 
			show={open} 
			onHide={()=>setOpen(false)} 
			dialogClassName="pdfmodal-90w"
		>
			<ButtonGroup aria-label="sandhiHelpPdfs">
				{allHelp.map((help, idx)=>(
					<Button key={`sandhiHelp${idx}`} variant="outline-primary" 
						active={helpPDF===help.pdf}
						onClick={() =>{setHelpPDF(help.pdf)}}
					>	
						{help.name}
					</Button>
				))}
			</ButtonGroup>
			<embed
				title="helpPdf"
				src={require("../../../docs/"+helpPDF)+"#zoom=150"}
				frameBorder="0"
				scrolling="auto"
				height="100%"
				width="100%"
			/>
		</Modal>
	);
};

export default SandhiHelp;