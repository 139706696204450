// import React, {useState, useContext, Fragment} from 'react';
import React, {useState, useEffect, useContext} from "react";
import  {UserContext} from "../../providers/UserProvider";

import {Button, FloatingLabel, Form} from 'react-bootstrap';
// import {Line} from "./helpers/blocks";

import BigText from './helpers/BigText';
import { keyListener, ShowTree } from "./helpers/blocks";
import LoadingPage from "./helpers/LoadingPage";

import './helpers/blocks.css';
import '../css/Components.css';

const Translation =({curQ, curPi, setCurQComplete, type, setPartInst, setAnsSaved, btnClicked}) =>{
	
	// console.log('translation: curQ=', curQ);
	// To autosave when next or previous buttons are clicked
	useEffect(()=>{
		if (btnClicked===true){
			check();
			setAnsSaved(true);
		};
	}, [btnClicked]);

	// curQ.text.q is a list. 0th element is the qDeva and the rest of them are boxes	
	const [loading, setLoading] =useState(false);
	const {currentUser} =useContext(UserContext);

	// This is constructed in the backend (python) and passed as part of the curQ
	const [ansState, setAnsState] = useState(curQ.uans); 

	const [completed, setCompleted] =useState('');
	useEffect(()=>{
		try {
			if (curQ.text.meta.allComplete)
				setCompleted('all');
			else if (curQ.text.meta.primaryComplete)
				setCompleted('primary');
		} catch (err) {
			setCompleted('');
			console.log('got err:', err.message);
		}
	}, [curQ]);
	// console.log('completed', completed);

	const  handleInputChange =(event, type) => {
		const newAns ={...ansState};
		switch(type){
			case "translation":
				newAns.translation =event.target.value;
				break;
			case "comment":
				newAns.comment =event.target.value;
				break;
			default:
				break;
		};
		setAnsState(newAns);
	};

	const handleToggle =(checked) =>{
		const newAns ={...ansState};
		newAns.addComment =checked;
		// console.log('event', checked);
		if (!newAns.addComment)
			newAns.comment ="";
		
		setAnsState(newAns);
		// console.log('newAns', newAns);
	};

	const check =() => {
		const postObj ={
			method: 'POST',
			cache: 'no-cache',
			headers: {'Content-Type': 'application/json'},
			body: JSON.stringify({ uans:ansState, pi: curPi, 'email':currentUser.email,
											curQ: {'p':curQ.p, 'a':curQ.a, 'type':curQ.type, 'n':curQ.n, 's': curQ.s, 
													'parts': curQ.parts, 'text': curQ.text.q, 'xmlid':curQ.xmlid, 'total':curQ.total }
										})
		}
		const getFeedback =async () => {
			setLoading(true);
			
			const response = await fetch(process.env.REACT_APP_FLASK_API+'check_uans', postObj);
			const data = await response.json();
			//Temporarily just accept the input
			// console.log(data.analysis);
			setPartInst(data.analysis.inst);
			setCurQComplete(data.completed);
			console.log('score', data.analysis.score);
			setLoading(false);
		};
		getFeedback();
	};

	//Enter = Submit
	const [btn, setBtn]=useState(null);
	useEffect(() => {
		if (btn){
			// create an eventListener
			window.addEventListener('keydown', e=>keyListener(e, btn));
			// cleanup this component
			return () => {
				window.removeEventListener('keydown', e=>keyListener(e, btn));
			};
		}
	}, [btn]);
	
	return (
		<div>
			<ShowTree xmlid={curQ.xmlid} completed={completed}/>
			
			<LoadingPage loading={loading} />
	
			{curQ.takeInput &&
			<>
				<div className="d-flex align-items-center">
					<BigText sp="Q:" />
					<div className="d-flex-column">
						<span style={{fontFamily:'Praja'}}>
							<BigText text={curQ.text.q[0]} />
						</span>
						<div className="containerBox">
								{curQ.text.q.slice(1).map((cur, index)=>(
									<div className="detailsBox" key={`${cur.title}${index}`}>

										<div style={{fontFamily:'Praja', fontSize:'22pt'}}>
											{cur.title}
										</div>		
										<div className='line'/>
										
										{'inflection' in cur &&
											cur.inflection.length
											?<div>{cur.inflection}</div>
											: <br/>
										}
										{'pos' in cur &&
											cur.pos.length
											?<div>{cur.pos}</div>
											: <br/>
										}

										{'addRoot' in cur && cur.addRoot===true &&
											<div>{cur.root}, {cur.rootPos}</div>
										}
				
										<Form.Control style={{marginTop:'5px'}} className="text-center" type="text" value={cur.trans} readOnly />
				
										{'relation' in cur && 'parents' in cur &&
											cur.relation.length >0 &&
											<div style={{fontFamily:"Praja", fontSize:"20pt", textAlign:"center", marginTop:'10px'}}>
												<div style={{color:'#DAA520'}}>{cur.relation}</div>
												<div style={{marginTop:'-10px'}}>{cur.parents}</div>
											</div>
										}
										{'secondaryRelation' in cur && 'targets' in cur &&
											cur.secondaryRelation.length >0 &&
											<div style={{fontFamily:"Praja", fontSize:"20pt", textAlign:"center", marginTop:'10px'}}>
												<div style={{color:'#ACAC9A'}}>{cur.secondaryRelation}</div>
												<div style={{marginTop:'-10px'}}>{cur.targets}</div>
											</div>
										}
									
									</div>
								))}
						</div>
					</div>
			
				</div>
	
				<div className="FlexBox">
					<BigText sp="A:" />
					<div className="ansContainer">
						
						<div className="form-group">
							<FloatingLabel
									controlId="translation"
									label="Translation"
									className="mb-3"
							>
								<textarea
									autoFocus
									autoComplete="off"
									className="form-control"

									name="translation"
									id={"translation"}
									aria-describedby={"translationError"}
									
									placeholder="Type your translation"
									value={ansState.translation}
									onChange={(e) => handleInputChange(e, "translation")}
									onFocus={e => {
										var val = e.target.value;
										e.target.value = '';
										e.target.value = val;
									}}
								/>
							</FloatingLabel>
						</div>

						<div className ="KeepRight">
							<label htmlFor ="commentBtn" 
								className ="form-check-label"
								style ={{marginRight:'5px'}}
							>
								Add comment
							</label>
							<input
								type ="checkbox"
								id ="commentBtn"
								name ="commentBtn"
								className ="form-check-input"
								checked ={ansState.addComment}
								onChange ={e=>handleToggle(e.target.checked)}
							/>
						</div>
						

						{ansState.addComment===true &&
							<Form>
								<FloatingLabel
									controlId="comment"
									label="Comment/Query"
									className="mb-3"
								>
									<textarea
										autoFocus
										autoComplete="off"
										className="form-control"

										name="comment"
										id={"comment"}
										aria-describedby={"commentError"}
										
										placeholder="Type your comment"
										value={ansState.comment}
										onChange={(e) => handleInputChange(e, "comment")}
										onFocus={e => {
											var val = e.target.value;
											e.target.value = '';
											e.target.value = val;
										}}
									/>
								</FloatingLabel>
							</Form>
						}
						{type !='lElement' && 
							<div className="KeepRight">
								<Button onClick={()=>check()}
									ref={node=>setBtn(node)}
								>
									Check
								</Button>
							</div>
						}
						{type ==='lElement' && 
							<div className="KeepRight" style={{visibility:'hidden'}}>
								<Button onClick={()=>check()}
									ref={node=>setBtn(node)}
								>
									Check
								</Button>
							</div>
						}
						
					</div>

				</div>
			</>
			}
		</div>
	);
};

export default Translation;