import React, {useState, useEffect, useContext, Fragment} from 'react';

import {Button} from "react-bootstrap";
// import Tooltip from 'react-bootstrap/Tooltip'; 
import ImageFadeIn from "react-image-fade-in";
// import OverlayTrigger from 'react-bootstrap/OverlayTrigger';

import Select, { createFilter } from "react-select";
import createFilterOptions from "react-select-fast-filter-options";

import { vocab, pos } from '../helpers/data';
import { UserContext } from "../../../providers/UserProvider";
import { Box, colorStyles, keyListener } from "../helpers/blocks";

import cross from '../helpers/cross.png';
import LoadingPage from "../helpers/LoadingPage";
import question from '../helpers/questionOrange.png';

import '../helpers/blocks.css';
import '../../css/Components.css';

const CompoundConstituents =({curQ, cxmlid, setPartInst, curPi, setCurQComplete,
												btnClicked, setAnsSaved, setCompounds, type}) =>{
	// To autosave when next or previous buttons are clicked
	useEffect(()=>{
		if (btnClicked===true){
			check(0, 'All');
			setAnsSaved(true);
		};
	}, [btnClicked]);
												
	const [loading, setLoading] =useState(false);

	const {currentUser} = useContext(UserContext);
	const [qText, setqText] =useState(curQ.text.question);

	//Misc Functions !
	const filterOptions = createFilterOptions({vocab});

	const getBorderColor =(error, correct)=>{
		if (error)
			return 'red';
		
		if (correct)
			return 'green';

		return 'transparent';
	};

	const getPlaceholder =(selected, temp, options=[])=>{
		// console.log(selected, temp, options);
		if (selected.length>0)
			return selected
		else if (options.length>1)
			return options[0].value+'?';
		else
			return temp;
	};

	// This is constructed in the backend (python) and passed as part of the curQ
	const [ansState, setAnsState] = useState(curQ.uans); 

	// useEffect(()=>{
	// 	console.log("newState:", ansState);
	// }, [ansState]);
	
	const handleSelectChange = (index, name, selected) => {
		const values = [...ansState];
		// console.log('selected',selected);
		if (selected===null){
			return;
		}
		var options =[];
		switch(name){
			case "lemma":
				values[index].lemma = selected.value;
				// console.log("lemma", values[index].lemma);

				options =[];
				pos[selected.value].forEach(tag =>{
					options.push({ label:tag, value: tag })
				});
				values[index].posOptions =options
				if (options.length===1)
					values[index].pos =options[0].value;
				else
					values[index].pos =''
				// console.log("posOptions", values[index].posOptions);

				if(!selected.isLemma) {
					// Add rootOptions
					values[index].addRoot=true;
					var rootOptions = [];
					selected.parents.forEach(root =>{
						rootOptions.push({ label:root, value: root })
					});
					values[index].rootOptions =rootOptions;
					if (options.length===1)
						values[index].root =rootOptions[0].value;
					else
						values[index].root =''
					
					// Add rootPosOptions
					var rootPosOptions =[]; //for rootPos
					pos[values[index].root].forEach(tag =>{
						rootPosOptions.push({ label:tag, value: tag })
					});
					values[index].rootPosOptions = rootPosOptions;
					if (options.length===1)
						values[index].rootPos =rootPosOptions[0].value;
					else
						values[index].rootPos =''
					// console.log("rootOptions", values[index].rootOptions);
				}
				else{
					//Remove rootOptions
					values[index].addRoot=false;
					values[index].root ='';
					values[index].rootOptions =[];
					values[index].rootPosOptions =[];
					// console.log("rootOptions", values[index].rootOptions);
				}

				break;

			case "pos":
				values[index].pos =selected.value;
				break;

			case "root":
				values[index].root = selected.value;
				// console.log("root", values[index].root);

				options=[]; //for rootPos
				pos[selected.value].forEach(tag =>{
					options.push({ label:tag, value: tag })
				});
				values[index].rootPosOptions = options
				values[index].rootPos =options[0].value;
				// console.log("rootPosOptions", values[index].rootPosOptions);
				break

			case "rootPos":
				values[index].rootPos =selected.value;
				break;
			
			default:
				break;
		}
	
		// console.log('new values:',values);
		setAnsState(values);
	};

	// Checking the user's answers

	//checking box or all depending on checkType
	const check =(index, checkType) =>{	
		// console.log("checking", checkType);
		// console.log("values@", ansState[index]);

		const postObj = {
			method: 'POST',
			cache: 'no-cache',
			headers: {'Content-Type': 'application/json'},
			body: JSON.stringify({ uans: ansState, pi: curPi, email: currentUser.email, checkType: checkType,
											curPart: 'compound-constituents',
											curQ: {'p':curQ.p, 'a':curQ.a, 'type':curQ.type, 'n':curQ.n, 's': curQ.s, 'ai':index,
													'parts': curQ.parts, 'text': curQ.text, 'xmlid': cxmlid, 'total':curQ.total }
										})
		}
	
		const getFeedback =async () => {
			setLoading(true);

			const response = await fetch(process.env.REACT_APP_FLASK_API+'check_uans', postObj);
			const data = await response.json();
			// console.log("data:", data.analysis.allComplete);

			setPartInst(data.analysis.inst);
			setqText(data.analysis.afterState[0])
			setAnsState(data.analysis.afterState[1]);
			setCurQComplete(data.completed);

			// setCompounds(data.analysis.compounds);

			setLoading(false);
		};
		getFeedback();
	};

	//Enter = Submit
	const [btn, setBtn]=useState(null);

	useEffect(() => {
		if (btn){
			// create an eventListener
			console.log('compound constituents keyListener');
			window.addEventListener('keydown', e=>keyListener(e, btn));
		}
	}, [btn]);
	useEffect(() =>{
		console.log('mounted Compound Constituents');
		// cleanup this component
		return () => {
			console.log('unmounted Compound Constituents');
			window.removeEventListener('keydown', e=>keyListener(e, btn));
		};
	}, [])

	return (
		<div>
			<LoadingPage loading={loading} />

			{curQ.takeInput &&
			<>
				<h5>
					<span className="blueText">Q:</span> <span className="praja32px" dangerouslySetInnerHTML={{__html: qText}} />
				</h5>
	
				<div className="FlexBox">
					<h5><span className="blueText">A:</span></h5>
					<div className="containerBox">
						{ansState.map((cur, index)=>(
							
							<Fragment key={`${cur.title}${index}`}>
								<Box borderColor={getBorderColor(cur.errorFound, cur.allCorrect)}>

								<div className='boxTitle' style={{fontFamily:'Praja', fontSize:'32px'}}>
									{cur.title}
									<div className="line" />
								</div>
	
								<div className="form-group">
									<label htmlFor={`"lemma"${index}`} className="smText">Lemma
									{cur.lemmaError===true&&
										<ImageFadeIn opacityTransition={3} style={{float:'right'}} 
										width={25} height={25} src={cross} alt='wrong'/>
									}
									{cur.lemmaError==='?'&&
										<ImageFadeIn opacityTransition={3} style={{float:'right'}} 
										width={25} height={25} src={question} alt='blank'/>
									}
									</label>
								
									<Select
										autoFocus={index===0}
										onFocus={e => {
											var val = e.target.value;
											e.target.value = '';
											e.target.value = val;
										}}
										
										name="lemma"
										id={`"lemma"${index}`}
										aria-describedby={`"lemmaError"${index}`}
										
										options={vocab}
										styles={colorStyles}
										filterOptions={filterOptions}
										filterOption={createFilter({ignoreCase:false, ignoreAccents:false, trim:true,  matchFrom:'start'})}
										
										placeholder={getPlaceholder(cur.lemma, "First")}
										onChange={(selectedOption) => handleSelectChange(index, "lemma", selectedOption )}

										// menuPortalTarget={document.querySelector("body")}
									/>	
								</div>

								<div className="form-group">
									<label htmlFor={`"pos"${index}`} className="smText">Lexical Category
									{cur.posError===true&&
										<ImageFadeIn opacityTransition={3} style={{float:'right'}} 
										width={25} height={25} src={cross} alt='wrong'/>
									}
									{cur.posError==='?'&&
										<ImageFadeIn opacityTransition={3} style={{float:'right'}} 
										width={25} height={25} src={question} alt='blank'/>
									}
									</label>
									<Select
										name="pos"
										id={`"pos"${index}`}
										aria-describedby={`"posError"${index}`}
										
										options={cur.posOptions}

										placeholder={getPlaceholder(cur.pos, "Second", cur.posOptions)}
										value={cur.pos}
										// selectValue ={cur.pos}
										onChange={(selectedOption) => handleSelectChange(index, "pos", selectedOption )}

										// menuPortalTarget={document.querySelector("body")}
									/>	
								</div>

								{cur.addRoot && 
									<>

										<div className="form-group">
											<label htmlFor={`"root"${index}`} className="smText">Root
											{cur.rootError===true&&
												<ImageFadeIn opacityTransition={3} style={{float:'right'}} 
												width={25} height={25} src={cross} alt='wrong'/>
											}
											{cur.rootError==='?'&&
												<ImageFadeIn opacityTransition={3} style={{float:'right'}} 
												width={25} height={25} src={question} alt='blank'/>
											}
											</label>
											<Select
												name="root"
												id={`"root"${index}`}
												aria-describedby={`"rootError"${index}`}

												options={cur.rootOptions}

												placeholder={getPlaceholder(cur.root, "Third", cur.rootOptions)}
												value ={cur.root}
												onChange={(selectedOption) => handleSelectChange(index, "root", selectedOption )}

												// menuPortalTarget={document.querySelector("body")}
											/>
										</div>
										<div className="form-group">
								
											<label htmlFor={`"rootPos"${index}`} className="smText" style={{float:'left'}}>
												Root's Lexical Category
												{cur.rootError===true&&
													<ImageFadeIn opacityTransition={3} style={{float:'right'}} 
													width={25} height={25} src={cross} alt='wrong'/>
												}
												{cur.rootError==='?'&&
													<ImageFadeIn opacityTransition={3} style={{float:'right'}} 
													width={25} height={25} src={question} alt='blank'/>
												}
											</label>
											
											<Select
												name="rootPos"
												id={`"rootPos"${index}`}
												aria-describedby={`"rootPosError"${index}`}

												options={cur.rootPosOptions}

												placeholder={getPlaceholder(cur.rootPos, "Fourth", cur.rootPosOptions)}
												value={cur.rootPos}
												onChange={(selectedOption) => handleSelectChange(index, "rootPos", selectedOption )}

												// menuPortalTarget={document.querySelector("body")}
											/>
										</div>
									</>
								}

								<Button variant='outline-primary' size='sm' style={{'float':'right', 'marginTop':'5px'}}
									onClick={()=>check(index, 'Box')}>Check
								</Button>

								</Box>
							</Fragment>
						))}
					</div>
				</div>
				{type !='lElement' && 
					<div className="KeepRight">
						<Button variant='primary' 
							onClick={()=>check(0, 'All')} 
							ref={node=>setBtn(node)}
						>
							Check all
						</Button>
					</div>
				}
				{type ==='lElement' && 
					<div className="KeepRight"  style={{visibility:'hidden'}}>
						<Button variant='primary' 
							onClick={()=>check(0, 'All')} 
							ref={node=>setBtn(node)}
						>
							Check all
						</Button>
					</div>
				}

			</>
			}
		</div>
	);
};

export default CompoundConstituents;