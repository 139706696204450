import React, { useState } from "react";
import {Popover, OverlayTrigger, Modal} from 'react-bootstrap';

import BigText  from "../helpers/BigText";
import SandhiHelp from "./SandhiHelp";

import '../../css/Components.css';


const generateTokens =(units, setSandhiHelpOpen, setSandhiAnalysisHelpOpen, setPDFfile) =>{
	let tokens = [];
	console.log('generateTokens | units:', units);
	for (let i=0; i<units.length; i++){
		let unit = units[i];
		console.log('FOR:', i, unit);
		// if true, no need of popover and highlighting
		if(unit['type'] === 'm')
			tokens.push(<span key={i}>{unit['text']}</span>);
		
		// else, error which needs to be highlighted
		else{
			console.log('--highlight')
			let styleColor ={}
			let backgroundColor={}
			if (unit['popover']['color']==="red") {
				styleColor ={backgroundColor:"red"};//, color:"white"};
				backgroundColor="var(--popoverRed)";
			}
			else {
				styleColor ={backgroundColor:"rgb(0, 123, 255)"};//, color:"white"};
				backgroundColor="var(--popoverBlue)";
			}

			let popover =(
				<Popover id="popover-basic">
					<Popover.Header as="h3"  style={{backgroundColor: backgroundColor, textAlign:"center"}}>
						{unit['popover']['head']}
					</Popover.Header>
					<Popover.Body>
						{unit['popover']['message']}
					</Popover.Body>
				</Popover>
			);
			

			if (unit['help']['generic']) {
				tokens.push(
					<a key={i} target="_blank" rel="noopener noreferrer" onClick={()=>setSandhiHelpOpen(true)}>
						<OverlayTrigger key={i} trigger={["hover", "focus"]} placement="right" overlay={popover}>
							<span style={styleColor}>
								{unit['text']}
							</span>
						</OverlayTrigger>
					</a>
				);
			}
			else if (unit['help']['type']==="static") {
				tokens.push(
					<a key={i} target="_blank" rel="noopener noreferrer">
						<OverlayTrigger key={i} trigger={["hover", "focus"]} placement="right" overlay={popover}>
							<span style={styleColor}>
								{unit['text']}
							</span>
						</OverlayTrigger>
					</a>
				);
			}
			else if (unit['help']['type']==="link") {
				tokens.push(
					<a key={i} href={unit['help']['target']} target="_blank" rel="noopener noreferrer" onClick={()=>{setPDFfile(unit['help']['pdf']);setSandhiAnalysisHelpOpen(true)}}>
						<OverlayTrigger key={i} trigger={["hover", "focus"]} placement="right" overlay={popover}>
							<span style={styleColor}>
								{unit['text']}
							</span>
						</OverlayTrigger>
					</a>
				);
			}
			else if (unit['help']['type']==="file") {
				tokens.push(
					<a key={i} target="_blank" rel="noopener noreferrer" onClick={()=>{setPDFfile(unit['help']['pdf']);setSandhiAnalysisHelpOpen(true)}}>
						<OverlayTrigger key={i} trigger={["hover", "focus"]} placement="right" overlay={popover}>
							<span style={styleColor}>
								{unit['text']}
							</span>
						</OverlayTrigger>
					</a>
				);
			}
		}
	};

	return tokens;
};

const SandhiAnalysisPopUp = (props) => {
	// Part 1 help
	const [sandhiHelpOpen, setSandhiHelpOpen] =useState(false);
	
	// Part 2 help
	const [pdfFile, setPDFfile]=useState("appendixD2.1-VowelSandhi.pdf");
	const [sandhiAnalysisHelpOpen, setSandhiAnalysisHelpOpen] =useState(false);

	const SandhiAnalysisHelp=({open, setOpen}) =>{
		
		return (
			<Modal 
				show={open} 
				onHide={()=>setOpen(false)} 
				dialogClassName="pdfmodal-90w"
			>
				<embed
					title="helpPdf"
					src={require("../../../docs/"+pdfFile)+"#zoom=150"}
					frameBorder="0"
					scrolling="auto"
					height="100%"
					width="100%"
				/>
			</Modal>
		);
	}

	let devaTokens = generateTokens(props.devaUnits, setSandhiHelpOpen, setSandhiAnalysisHelpOpen, setPDFfile);
	let romanTokens =generateTokens(props.romanUnits, setSandhiHelpOpen, setSandhiAnalysisHelpOpen, setPDFfile);

	return(
		<>
			<span style={{fontFamily:'Praja'}}>
				<BigText text={devaTokens} />
			</span>
			<br/>
			<SandhiHelp open={sandhiHelpOpen} setOpen={setSandhiHelpOpen} />
			<SandhiAnalysisHelp open={sandhiAnalysisHelpOpen} setOpen={setSandhiAnalysisHelpOpen} />
			{romanTokens}
		</>
	);
};

export default SandhiAnalysisPopUp;

// // Red Highlights (Result vs Question)
// const popupForQMistake = (
// 	<Popover id="popupForQMistake">
// 		<Popover.Header as="h3" style={{backgroundColor: "var(--popoverRed)", textAlign:"center"}}>
// 			Wrong Analysis
// 		</Popover.Header>
// 		<Popover.Body>
// 			Found extra/wrong characters on comparing the result, of doing sandhi on your input, with the question.
// 		</Popover.Body>
// 	</Popover>
// );
// const popupForQUnderline = (
// 	<Popover id="popupForQUnderline">
// 		<Popover.Header as="h3" style={{backgroundColor: "var(--popoverRed)", textAlign:"center"}}>
// 			Wrong Analysis
// 		</Popover.Header>
// 		<Popover.Body>
// 			Found missing characters on comparing the result, of doing sandhi on your input, with the question.
// 		</Popover.Body>
// 	</Popover>
// );
// // Blue Highlights (uInput vs Answer)
// const popupForSpace = (
// 	<Popover id="popupForSpace">
// 		<Popover.Header as="h3" style={{backgroundColor: "var(--popoverBlue)", textAlign:"center"}}>
// 			Spacing Error
// 		</Popover.Header>
// 		<Popover.Body>
// 			This space needs to be deleted.
// 		</Popover.Body>
// 	</Popover>
// );
// const popupForAMistake = (
// 	<Popover id="popupForAMistake">
// 		<Popover.Header as="h3"  style={{backgroundColor: "var(--popoverBlue)", textAlign:"center"}}>
// 			Delete/Replace
// 		</Popover.Header>
// 		<Popover.Body>
// 			You may have to delete or replace the highlighted characters.
// 		</Popover.Body>
// 	</Popover>
// );
// const popupForAUnderline = (
// 	<Popover id="popupForAUnderline">
// 		<Popover.Header as="h3"  style={{backgroundColor: "var(--popoverBlue)", textAlign:"center"}}>
// 			Add
// 		</Popover.Header>
// 		<Popover.Body>
// 			Your answer is missing character(s), add them in place of the highlighted underscore.
// 		</Popover.Body>
// 	</Popover>
// );
