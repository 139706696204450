import React, { useState, useEffect, useContext } from 'react';

import Accordion from 'react-bootstrap/Accordion';
import AccordionContext from 'react-bootstrap/AccordionContext';
import { useAccordionButton } from 'react-bootstrap/AccordionButton';

import Error from '../Error';
import Button from 'react-bootstrap/Button';

import CompoundQnA from './CompoundQnA';

import '../css/Components.css';

const Compounds =({parentQ, parentPi,  
					setParentQComplete, setParentPartInst,
					// states required to implement autosave on next and previous buttons
					setAnsSaved ,btnClicked,
					// Extra to be similar to OneQuestion
					p, a, type, n, s, email,
					// compounds
					compoundsInit, setSidebar
				}) =>{
	
	const [compounds, setCompounds] =useState(compoundsInit);

	useEffect(()=>{
		console.log('compounds changed:', compounds);
	}, [compounds]);

	const OneCompound =({compound, setCompounds, setSidebar}) =>{
		const [curQ, setCurQ] =useState({});
		const [curQComplete, setCurQComplete] =useState(false);

		const [parts, setParts] =useState(['compound-identification', 'compound-sandhi-analysis', 'compound-constituents', 'vigraha']);
		const [curPart, setCurPart] =useState("compound-identification");	//previously pi/curPi or cur part's index
		const [partInst, setPartInst] =useState("");

		const [done, setDone] = useState(false);
		const [access, setAccess] = useState({restricted: false, msg: "Context Restricted"});

		useEffect(() => {
			const getDetails =async () => {
				setDone(false);
				const postObj ={
					method: 'POST',
					cache: 'no-cache',
					headers: {'Content-Type': 'application/json'},
					body: JSON.stringify({p: p, a: a, type: type, n: n, s: s,
													curPart: curPart, cxmlid: compound.cxmlid, email: email})
				}
				const response = await fetch(process.env.REACT_APP_FLASK_API+'get_question', postObj);
				const data = await response.json();
				
				console.log("Compound DATA:", data);
				setCurQ(data.curQ);
				// setParts(data.curQ.parts);	
				setPartInst(data.curQ.partInst);
				// setSidebar(data.curQ.sidebar);
				setCurQComplete(data.curQ.completed);								
				setAccess({restricted: data.restricted, msg: data.msg});
				
				setDone(true);
			};
			getDetails();
	   }, [p, a, type, n, s, curPart, email]);

		const CustomToggle = ({idx, btnPart, eventKey, callback }) => {
			const currentEventKey = useContext(AccordionContext);
	
			const isCurrentEventKey = currentEventKey.activeEventKey === eventKey;
			const decoratedOnClick = useAccordionButton(eventKey, 
				() => callback && callback(eventKey)
			); 
			// console.log('accordion', idx, btnPart);
			return (
				<Button key={btnPart} variant="outline-primary" 
					active={isCurrentEventKey}
					onClick={() =>{
						decoratedOnClick();
						setCurPart(btnPart);
					}}>
					
					{btnPart.charAt(0).toUpperCase()+btnPart.toLowerCase().replaceAll('-', ' ').substring(1)}
				</Button>
			);
		};
		const toggleText=(i)=>{
			var dots = document.getElementsByName(`${compound.cxmlid}-dots`);
			var moreText = document.getElementsByName(`${compound.cxmlid}-more`);
			var btnText = document.getElementsByName(`${compound.cxmlid}-triggerText`);
	
			if (dots[i].style.display === "none") {
				dots[i].style.display = "inline";
				btnText[i].innerHTML = "See more";
				moreText[i].style.display = "none";
			} else {
				dots[i].style.display = "none";
				btnText[i].innerHTML = "See less";
				moreText[i].style.display = "inline";
			}
		};

		const isEmpty = (obj) =>{
			return Object.keys(obj).length===0 &&
						obj.constructor === Object;
		};

	// 	if(done && access.restricted){
	// 	   return(<Error message={access.msg} />)
	//    }
	//    else if(done && isEmpty(curQ)){
	// 		return(<h1>No compound here.</h1>)
	// 	}
		if (done){	
			if (compound.show) { // if compound.show
				return(
					<div className="compoundsItem">
						<Accordion key={compound.cxmlid} defaultActiveKey={curPart} style={{paddingTop:'20px'}}>
							<div className="compoundVW d-flex justify-content-between">	
								<div>
									{parts.map((part, idx) => (
										<CustomToggle idx ={idx} key={part} 
											btnPart={part} eventKey={part}/>
									))}
								</div>
							</div>
							{parts.map((part, i) => (
								<Accordion.Collapse key={part} eventKey={part}>
									<div className="partInst compoundVW">
										<span dangerouslySetInnerHTML={{__html: partInst.slice(0,100)}} />
										{partInst.length>100 &&
										<>
											<span name={`${compound.cxmlid}-dots`} className="dots">...</span>
											<span name={`${compound.cxmlid}-more`} className="more" dangerouslySetInnerHTML={{__html: partInst.slice(100)}} />
											<span name={`${compound.cxmlid}-triggerText`} className="triggerText" variant="secondary" 
														size="sm" onClick={()=>toggleText(i)}>See more</span>
										</>
										}
									</div>
								</Accordion.Collapse>
							))}
						</Accordion>
						
						<div className="compoundQnABox">
							<CompoundQnA  curQ={curQ} cxmlid={compound.cxmlid} curPi={parts.indexOf(curPart)} 
								setPartInst={setPartInst} setCurQComplete={setCurQComplete} type={type}
								// required to implement autosave on next and previous buttons
								setAnsSaved={setAnsSaved} btnClicked={btnClicked} setCompounds={setCompounds}
							/>
						</div>
					</div>
				);
			}
			else {
				return <></>
			}
		}
		else {
			return <></>
		}
	}

	// console.log(compounds, Object.keys(compounds).length);
	if (compounds===undefined) {
		return <>No compounds here.</>
	}
	else if (Object.keys(compounds).length == 0) {
		return <>No compounds here.</>
	}
	else {
		return (
			<div className="compoundsContainer widthVW">
				{Object.keys(compounds).map((cxmlid)=>(
					<OneCompound key={cxmlid} compound={compounds[cxmlid]} 
						setCompounds={setCompounds} setSidebar={setSidebar}/>
				))}
			</div>
		)
	}
};

export default Compounds;