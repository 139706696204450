export const vocab = [
	{'value': 'aklizwa', 'label': 'aklizwa', 'attrib': [{'pos': 'adj', 'defs': 'unafflicted, untarnished'}], 'isLemma': 1},
	{'value': 'aklizwakarman', 'label': 'aklizwakarman', 'attrib': [{'pos': 'adj', 'defs': 'of untarnished deeds'}], 'isLemma': 1},
	{'value': 'akza', 'label': 'akza', 'attrib': [{'pos': 'm', 'defs': 'a die for gambling'}], 'isLemma': 1},
	{'value': 'akzaya', 'label': 'akzaya', 'attrib': [{'pos': 'adj', 'defs': 'imperishable, inexhaustible'}], 'isLemma': 1},
	{'value': 'agni', 'label': 'agni', 'attrib': [{'pos': 'm', 'defs': 'fire'}], 'isLemma': 1},
	{'value': 'aNga', 'label': 'aNga', 'attrib': [{'pos': 'n', 'defs': 'limb, body'}, {'pos': 'm', 'defs': 'Bengal'}], 'isLemma': 1},
	{'value': 'aNgadeSa', 'label': 'aNgadeSa', 'attrib': [{'pos': 'm', 'defs': 'place on the body'}], 'isLemma': 1},
	{'value': 'acira', 'label': 'acira', 'attrib': [{'pos': 'adj', 'defs': 'not a long time'}], 'isLemma': 1},
	{'value': 'ajYAta', 'label': 'ajYAta', 'attrib': [{'pos': 'adj', 'defs': 'unknown'}], 'isLemma': 1},
	{'value': 'aYc', 'label': 'aYc', 'attrib': [{'pos': 'v1am', 'defs': 'bend'}], 'isLemma': 1},
	{'value': 'ati', 'label': 'ati', 'attrib': [{'pos': 'preverb', 'defs': 'beyond'}], 'isLemma': 1},
	{'value': 'atiBAra', 'label': 'atiBAra', 'attrib': [{'pos': 'm', 'defs': 'an excessive burden'}], 'isLemma': 1},
	{'value': 'attavya', 'label': 'attavya', 'attrib': [{'pos': 'ger', 'defs': 'eat'}], 'isLemma': 0, 'parents': ['ad']},
	{'value': 'attum', 'label': 'attum', 'attrib': [{'pos': 'inf', 'defs': 'eat'}], 'isLemma': 0, 'parents': ['ad']},
	{'value': 'attvA', 'label': 'attvA', 'attrib': [{'pos': 'abs', 'defs': 'eat'}], 'isLemma': 0, 'parents': ['ad']},
	{'value': 'atsyat', 'label': 'atsyat', 'attrib': [{'pos': 'fap', 'defs': 'eat'}], 'isLemma': 0, 'parents': ['ad']},
	{'value': 'atsyamAna', 'label': 'atsyamAna', 'attrib': [{'pos': 'fpp', 'defs': 'eat'}], 'isLemma': 0, 'parents': ['ad']},
	{'value': 'aTa', 'label': 'aTa', 'attrib': [{'pos': 'pcl', 'defs': 'now, then'}], 'isLemma': 1},
	{'value': 'ad', 'label': 'ad', 'attrib': [{'pos': 'vt2a', 'defs': 'eat'}], 'isLemma': 1},
	{'value': 'adat', 'label': 'adat', 'attrib': [{'pos': 'prap', 'defs': 'eat'}], 'isLemma': 0, 'parents': ['ad']},
	{'value': 'adanIya', 'label': 'adanIya', 'attrib': [{'pos': 'ger', 'defs': 'eat'}], 'isLemma': 0, 'parents': ['ad']},
	{'value': 'adyamAna', 'label': 'adyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'eat'}], 'isLemma': 0, 'parents': ['ad']},
	{'value': 'aDi', 'label': 'aDi', 'attrib': [{'pos': 'preverb', 'defs': 'over'}], 'isLemma': 1},
	{'value': 'aDijagAna', 'label': 'aDijagAna', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'go over, study, read, recite'}], 'isLemma': 0, 'parents': ['aDi_i']},
	{'value': 'aDi_i', 'label': 'aDi_i', 'attrib': [{'pos': 'vt2m', 'defs': 'go over, study, read, recite'}], 'isLemma': 1},
	{'value': 'aDIta', 'label': 'aDIta', 'attrib': [{'pos': 'ppp', 'defs': 'go over, study, read, recite'}], 'isLemma': 0, 'parents': ['aDi_i']},
	{'value': 'aDItavat', 'label': 'aDItavat', 'attrib': [{'pos': 'pap', 'defs': 'go over, study, read, recite'}], 'isLemma': 0, 'parents': ['aDi_i']},
	{'value': 'aDItya', 'label': 'aDItya', 'attrib': [{'pos': 'abs', 'defs': 'go over, study, read, recite'}], 'isLemma': 0, 'parents': ['aDi_i']},
	{'value': 'aDIyamAna', 'label': 'aDIyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'go over, study, read, recite'}], 'isLemma': 0, 'parents': ['aDi_i']},
	{'value': 'aDIyAna', 'label': 'aDIyAna', 'attrib': [{'pos': 'prmp', 'defs': 'go over, study, read, recite'}], 'isLemma': 0, 'parents': ['aDi_i']},
	{'value': 'aDyayanIya', 'label': 'aDyayanIya', 'attrib': [{'pos': 'ger', 'defs': 'go over, study, read, recite'}], 'isLemma': 0, 'parents': ['aDi_i']},
	{'value': 'aDyAya', 'label': 'aDyAya', 'attrib': [{'pos': 'm', 'defs': 'chapter'}], 'isLemma': 1},
	{'value': 'aDyetavya', 'label': 'aDyetavya', 'attrib': [{'pos': 'ger', 'defs': 'go over, study, read, recite'}], 'isLemma': 0, 'parents': ['aDi_i']},
	{'value': 'aDyetum', 'label': 'aDyetum', 'attrib': [{'pos': 'inf', 'defs': 'go over, study, read, recite'}], 'isLemma': 0, 'parents': ['aDi_i']},
	{'value': 'aDyeya', 'label': 'aDyeya', 'attrib': [{'pos': 'ger', 'defs': 'go over, study, read, recite'}], 'isLemma': 0, 'parents': ['aDi_i']},
	{'value': 'aDyezyamARa', 'label': 'aDyezyamARa', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'go over, study, read, recite'}], 'isLemma': 0, 'parents': ['aDi_i']},
	{'value': 'anu', 'label': 'anu', 'attrib': [{'pos': 'preverb', 'defs': 'after'}], 'isLemma': 1},
	{'value': 'anucintita', 'label': 'anucintita', 'attrib': [{'pos': 'ppp', 'defs': 'consider'}], 'isLemma': 0, 'parents': ['anu_cint']},
	{'value': 'anuja', 'label': 'anuja', 'attrib': [{'pos': 'adj', 'defs': 'born after'}, {'pos': 'm', 'defs': 'younger brother'}], 'isLemma': 1},
	{'value': 'anujajYAna', 'label': 'anujajYAna', 'attrib': [{'pos': 'pfpp', 'defs': 'consent, permit'}], 'isLemma': 0, 'parents': ['anu_jYA']},
	{'value': 'anujajYivas', 'label': 'anujajYivas', 'attrib': [{'pos': 'pfap', 'defs': 'consent, permit'}], 'isLemma': 0, 'parents': ['anu_jYA']},
	{'value': 'anujA', 'label': 'anujA', 'attrib': [{'pos': 'f', 'defs': 'younger sister'}], 'isLemma': 1},
	{'value': 'anujAnat', 'label': 'anujAnat', 'attrib': [{'pos': 'prap', 'defs': 'consent, permit'}], 'isLemma': 0, 'parents': ['anu_jYA']},
	{'value': 'anujYAta', 'label': 'anujYAta', 'attrib': [{'pos': 'ppp', 'defs': 'consent, permit'}], 'isLemma': 0, 'parents': ['anu_jYA']},
	{'value': 'anujYAtavat', 'label': 'anujYAtavat', 'attrib': [{'pos': 'pap', 'defs': 'consent, permit'}], 'isLemma': 0, 'parents': ['anu_jYA']},
	{'value': 'anujYAtavya', 'label': 'anujYAtavya', 'attrib': [{'pos': 'ger', 'defs': 'consent, permit'}], 'isLemma': 0, 'parents': ['anu_jYA']},
	{'value': 'anujYAtum', 'label': 'anujYAtum', 'attrib': [{'pos': 'inf', 'defs': 'consent, permit'}], 'isLemma': 0, 'parents': ['anu_jYA']},
	{'value': 'anujYAnIya', 'label': 'anujYAnIya', 'attrib': [{'pos': 'ger', 'defs': 'consent, permit'}], 'isLemma': 0, 'parents': ['anu_jYA']},
	{'value': 'anujYApya', 'label': 'anujYApya', 'attrib': [{'pos': 'abs', 'defs': 'consent, permit'}], 'isLemma': 0, 'parents': ['anu_jYA']},
	{'value': 'anujYAya', 'label': 'anujYAya', 'attrib': [{'pos': 'abs', 'defs': 'consent, permit'}], 'isLemma': 0, 'parents': ['anu_jYA']},
	{'value': 'anujYAyamAna', 'label': 'anujYAyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'consent, permit'}], 'isLemma': 0, 'parents': ['anu_jYA']},
	{'value': 'anujYAsyat', 'label': 'anujYAsyat', 'attrib': [{'pos': 'fap', 'defs': 'consent, permit'}], 'isLemma': 0, 'parents': ['anu_jYA']},
	{'value': 'anujYAsyamAna', 'label': 'anujYAsyamAna', 'attrib': [{'pos': 'fpp', 'defs': 'consent, permit'}], 'isLemma': 0, 'parents': ['anu_jYA']},
	{'value': 'anujYeya', 'label': 'anujYeya', 'attrib': [{'pos': 'ger', 'defs': 'consent, permit'}], 'isLemma': 0, 'parents': ['anu_jYA']},
	{'value': 'anu_cint', 'label': 'anu_cint', 'attrib': [{'pos': 'vt10am', 'defs': 'consider'}], 'isLemma': 1},
	{'value': 'anu_jan', 'label': 'anu_jan', 'attrib': [{'pos': 'vi4m', 'defs': 'be born after'}], 'isLemma': 1},
	{'value': 'anu_jYA', 'label': 'anu_jYA', 'attrib': [{'pos': 'vt9a', 'defs': 'consent, permit'}], 'isLemma': 1},
	{'value': 'anna', 'label': 'anna', 'attrib': [{'pos': 'ppp', 'defs': 'eat'}, {'pos': 'n', 'defs': 'food'}], 'isLemma': 1},
	{'value': 'anna', 'label': 'anna', 'attrib': [{'pos': 'ppp', 'defs': 'eat'}, {'pos': 'n', 'defs': 'food'}], 'isLemma': 0, 'parents': ['ad']},
	{'value': 'annavat', 'label': 'annavat', 'attrib': [{'pos': 'pap', 'defs': 'eat'}], 'isLemma': 0, 'parents': ['ad']},
	{'value': 'anya', 'label': 'anya', 'attrib': [{'pos': 'pron, pron_adj', 'defs': 'other'}], 'isLemma': 1},
	{'value': 'api', 'label': 'api', 'attrib': [{'pos': 'pcl', 'defs': 'even'}], 'isLemma': 1},
	{'value': 'apramatta', 'label': 'apramatta', 'attrib': [{'pos': 'adj', 'defs': 'not careless, careful'}], 'isLemma': 1},
	{'value': 'apramattotTita', 'label': 'apramattotTita', 'attrib': [{'pos': 'adj', 'defs': 'risen carefully'}], 'isLemma': 1},
	{'value': 'amita', 'label': 'amita', 'attrib': [{'pos': 'adj', 'defs': 'unmeasured, infinite'}], 'isLemma': 1},
	{'value': 'amitatejas', 'label': 'amitatejas', 'attrib': [{'pos': 'adj', 'defs': 'infinitely brilliant'}], 'isLemma': 1},
	{'value': 'amitOjas', 'label': 'amitOjas', 'attrib': [{'pos': 'adj', 'defs': 'infinitely vigorous'}], 'isLemma': 1},
	{'value': 'ayanIya', 'label': 'ayanIya', 'attrib': [{'pos': 'ger', 'defs': 'go'}], 'isLemma': 0, 'parents': ['i']},
	{'value': 'ari', 'label': 'ari', 'attrib': [{'pos': 'm', 'defs': 'enemy'}], 'isLemma': 1},
	{'value': 'ariMdama', 'label': 'ariMdama', 'attrib': [{'pos': 'm', 'defs': 'enemy tamer'}], 'isLemma': 1},
	{'value': 'arjuna', 'label': 'arjuna', 'attrib': [{'pos': 'm_pn', 'defs': 'Arjuna'}], 'isLemma': 1},
	{'value': 'arTa', 'label': 'arTa', 'attrib': [{'pos': 'm', 'defs': 'aim, object'}], 'isLemma': 1},
	{'value': 'ava', 'label': 'ava', 'attrib': [{'pos': 'preverb', 'defs': 'down'}], 'isLemma': 1},
	{'value': 'ava_DA', 'label': 'ava_DA', 'attrib': [{'pos': 'vt3am', 'defs': 'put down, deposit, apply'}], 'isLemma': 1},
	{'value': 'avaso', 'label': 'avaso', 'attrib': [{'pos': 'v4a', 'defs': 'end, finish'}], 'isLemma': 1},
	{'value': 'avahita', 'label': 'avahita', 'attrib': [{'pos': 'ppp', 'defs': 'put down, deposit, apply'}, {'pos': 'adj', 'defs': 'attentive'}], 'isLemma': 1},
	{'value': 'avahita', 'label': 'avahita', 'attrib': [{'pos': 'ppp', 'defs': 'put down, deposit, apply'}, {'pos': 'adj', 'defs': 'attentive'}], 'isLemma': 0, 'parents': ['ava_DA']},
	{'value': 'as', 'label': 'as', 'attrib': [{'pos': 'vi2a', 'defs': 'be'}], 'isLemma': 1},
	{'value': 'asura', 'label': 'asura', 'attrib': [{'pos': 'm', 'defs': 'demon'}], 'isLemma': 1},
	{'value': 'asmad', 'label': 'asmad', 'attrib': [{'pos': 'pers_pron', 'defs': 'we, us'}], 'isLemma': 1},
	{'value': 'ahita', 'label': 'ahita', 'attrib': [{'pos': 'adj', 'defs': 'not good'}, {'pos': 'n', 'defs': 'enemy'}], 'isLemma': 1},
	{'value': 'A', 'label': 'A', 'attrib': [{'pos': 'preverb', 'defs': 'unto'}], 'isLemma': 1},
	{'value': 'A_i', 'label': 'A_i', 'attrib': [{'pos': 'vt2a', 'defs': 'come'}], 'isLemma': 1},
	{'value': 'A_gam', 'label': 'A_gam', 'attrib': [{'pos': 'v1a', 'defs': 'come'}], 'isLemma': 1},
	{'value': 'A_car ', 'label': 'A_car ', 'attrib': [{'pos': 'vt1a', 'defs': 'move, do'}], 'isLemma': 1},
	{'value': 'A_ci', 'label': 'A_ci', 'attrib': [{'pos': 'vt5am', 'defs': 'accumulate'}], 'isLemma': 1},
	{'value': 'A_dA', 'label': 'A_dA', 'attrib': [{'pos': 'vt3am', 'defs': 'receive, take'}], 'isLemma': 1},
	{'value': 'A_diS', 'label': 'A_diS', 'attrib': [{'pos': 'vt6am', 'defs': 'point out, assign, order'}], 'isLemma': 1},
	{'value': 'A_DA', 'label': 'A_DA', 'attrib': [{'pos': 'vt3am', 'defs': 'put on, apply'}], 'isLemma': 1},
	{'value': 'A_DI', 'label': 'A_DI', 'attrib': [{'pos': 'vt2m', 'defs': 'recite, read, study'}], 'isLemma': 1},
	{'value': 'A_BA', 'label': 'A_BA', 'attrib': [{'pos': 'vi2a', 'defs': 'shine, appear'}], 'isLemma': 1},
	{'value': 'A_raB', 'label': 'A_raB', 'attrib': [{'pos': 'vt1m', 'defs': 'begin'}], 'isLemma': 1},
	{'value': 'A_vah', 'label': 'A_vah', 'attrib': [{'pos': 'vt1am', 'defs': 'carry towards, bring'}], 'isLemma': 1},
	{'value': 'A_vraj', 'label': 'A_vraj', 'attrib': [{'pos': 'vt1a', 'defs': 'walk to'}], 'isLemma': 1},
	{'value': 'Atta', 'label': 'Atta', 'attrib': [{'pos': 'ppp', 'defs': 'receive, take'}], 'isLemma': 0, 'parents': ['A_dA']},
	{'value': 'Adattavat', 'label': 'Adattavat', 'attrib': [{'pos': 'pap', 'defs': 'receive, take'}], 'isLemma': 0, 'parents': ['A_dA']},
	{'value': 'Adadat', 'label': 'Adadat', 'attrib': [{'pos': 'prap', 'defs': 'receive, take'}], 'isLemma': 0, 'parents': ['A_dA']},
	{'value': 'AdadAna', 'label': 'AdadAna', 'attrib': [{'pos': 'prmp', 'defs': 'receive, take'}, {'pos': 'pfmp, pfpp', 'defs': 'receive, take'}], 'isLemma': 0, 'parents': ['A_dA']},
	{'value': 'Adadivas', 'label': 'Adadivas', 'attrib': [{'pos': 'pfap', 'defs': 'receive, take'}], 'isLemma': 0, 'parents': ['A_dA']},
	{'value': 'AdaDat', 'label': 'AdaDat', 'attrib': [{'pos': 'prap', 'defs': 'put on, apply'}], 'isLemma': 0, 'parents': ['A_DA']},
	{'value': 'AdaDAna', 'label': 'AdaDAna', 'attrib': [{'pos': 'prmp', 'defs': 'put on, apply'}, {'pos': 'pfmp, pfpp', 'defs': 'put on, apply'}], 'isLemma': 0, 'parents': ['A_DA']},
	{'value': 'AdaDivas', 'label': 'AdaDivas', 'attrib': [{'pos': 'pfap', 'defs': 'put on, apply'}], 'isLemma': 0, 'parents': ['A_DA']},
	{'value': 'AdAtavya', 'label': 'AdAtavya', 'attrib': [{'pos': 'ger', 'defs': 'receive, take'}], 'isLemma': 0, 'parents': ['A_dA']},
	{'value': 'AdAtum', 'label': 'AdAtum', 'attrib': [{'pos': 'inf', 'defs': 'receive, take'}], 'isLemma': 0, 'parents': ['A_dA']},
	{'value': 'AdAna', 'label': 'AdAna', 'attrib': [{'pos': 'pfpp', 'defs': 'eat'}], 'isLemma': 0, 'parents': ['ad']},
	{'value': 'AdAnIya', 'label': 'AdAnIya', 'attrib': [{'pos': 'ger', 'defs': 'receive, take'}], 'isLemma': 0, 'parents': ['A_dA']},
	{'value': 'AdAya', 'label': 'AdAya', 'attrib': [{'pos': 'abs', 'defs': 'receive, take'}], 'isLemma': 0, 'parents': ['A_dA']},
	{'value': 'AdAsyat', 'label': 'AdAsyat', 'attrib': [{'pos': 'fap', 'defs': 'receive, take'}], 'isLemma': 0, 'parents': ['A_dA']},
	{'value': 'AdAsyamAna', 'label': 'AdAsyamAna', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'receive, take'}], 'isLemma': 0, 'parents': ['A_dA']},
	{'value': 'Adi', 'label': 'Adi', 'attrib': [{'pos': 'm', 'defs': 'beginning'}], 'isLemma': 1},
	{'value': 'Adiparvan', 'label': 'Adiparvan', 'attrib': [{'pos': 'n', 'defs': 'first book'}], 'isLemma': 1},
	{'value': 'Adivas', 'label': 'Adivas', 'attrib': [{'pos': 'pfap', 'defs': 'eat'}], 'isLemma': 0, 'parents': ['ad']},
	{'value': 'AdIyamAna', 'label': 'AdIyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'receive, take'}], 'isLemma': 0, 'parents': ['A_dA']},
	{'value': 'Adeya', 'label': 'Adeya', 'attrib': [{'pos': 'ger', 'defs': 'receive, take'}], 'isLemma': 0, 'parents': ['A_dA']},
	{'value': 'Adya', 'label': 'Adya', 'attrib': [{'pos': 'ger', 'defs': 'eat'}], 'isLemma': 0, 'parents': ['ad']},
	{'value': 'ADAtavya', 'label': 'ADAtavya', 'attrib': [{'pos': 'ger', 'defs': 'put on, apply'}], 'isLemma': 0, 'parents': ['A_DA']},
	{'value': 'ADAtum', 'label': 'ADAtum', 'attrib': [{'pos': 'inf', 'defs': 'put on, apply'}], 'isLemma': 0, 'parents': ['A_DA']},
	{'value': 'ADAnIya', 'label': 'ADAnIya', 'attrib': [{'pos': 'ger', 'defs': 'put on, apply'}], 'isLemma': 0, 'parents': ['A_DA']},
	{'value': 'ADAya', 'label': 'ADAya', 'attrib': [{'pos': 'abs', 'defs': 'put on, apply'}], 'isLemma': 0, 'parents': ['A_DA']},
	{'value': 'ADAsyat', 'label': 'ADAsyat', 'attrib': [{'pos': 'fap', 'defs': 'put on, apply'}], 'isLemma': 0, 'parents': ['A_DA']},
	{'value': 'ADAsyamAna', 'label': 'ADAsyamAna', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'put on, apply'}], 'isLemma': 0, 'parents': ['A_DA']},
	{'value': 'ADIyamAna', 'label': 'ADIyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'put on, apply'}], 'isLemma': 0, 'parents': ['A_DA']},
	{'value': 'ADeya', 'label': 'ADeya', 'attrib': [{'pos': 'ger', 'defs': 'put on, apply'}], 'isLemma': 0, 'parents': ['A_DA']},
	{'value': 'Ap', 'label': 'Ap', 'attrib': [{'pos': 'vt5a', 'defs': 'attain'}], 'isLemma': 1},
	{'value': 'ApanIya', 'label': 'ApanIya', 'attrib': [{'pos': 'ger', 'defs': 'attain'}], 'isLemma': 0, 'parents': ['Ap']},
	{'value': 'ApAna', 'label': 'ApAna', 'attrib': [{'pos': 'pfpp', 'defs': 'attain'}], 'isLemma': 0, 'parents': ['Ap']},
	{'value': 'Apivas', 'label': 'Apivas', 'attrib': [{'pos': 'pfap', 'defs': 'attain'}], 'isLemma': 0, 'parents': ['Ap']},
	{'value': 'Apta', 'label': 'Apta', 'attrib': [{'pos': 'ppp', 'defs': 'attain'}], 'isLemma': 0, 'parents': ['Ap']},
	{'value': 'Aptavat', 'label': 'Aptavat', 'attrib': [{'pos': 'pap', 'defs': 'attain'}], 'isLemma': 0, 'parents': ['Ap']},
	{'value': 'Aptavya', 'label': 'Aptavya', 'attrib': [{'pos': 'ger', 'defs': 'attain'}], 'isLemma': 0, 'parents': ['Ap']},
	{'value': 'Aptum', 'label': 'Aptum', 'attrib': [{'pos': 'inf', 'defs': 'attain'}], 'isLemma': 0, 'parents': ['Ap']},
	{'value': 'AptvA', 'label': 'AptvA', 'attrib': [{'pos': 'abs', 'defs': 'attain'}], 'isLemma': 0, 'parents': ['Ap']},
	{'value': 'Apnuvat', 'label': 'Apnuvat', 'attrib': [{'pos': 'prap', 'defs': 'attain'}], 'isLemma': 0, 'parents': ['Ap']},
	{'value': 'Apya', 'label': 'Apya', 'attrib': [{'pos': 'ger', 'defs': 'attain'}], 'isLemma': 0, 'parents': ['Ap']},
	{'value': 'ApyamAna', 'label': 'ApyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'attain'}], 'isLemma': 0, 'parents': ['Ap']},
	{'value': 'Apsyat', 'label': 'Apsyat', 'attrib': [{'pos': 'fap', 'defs': 'attain'}], 'isLemma': 0, 'parents': ['Ap']},
	{'value': 'ApsyamAna', 'label': 'ApsyamAna', 'attrib': [{'pos': 'fpp', 'defs': 'attain'}], 'isLemma': 0, 'parents': ['Ap']},
	{'value': 'Ayat', 'label': 'Ayat', 'attrib': [{'pos': 'prap', 'defs': 'come'}], 'isLemma': 0, 'parents': ['A_i']},
	{'value': 'AyanIya', 'label': 'AyanIya', 'attrib': [{'pos': 'ger', 'defs': 'come'}], 'isLemma': 0, 'parents': ['A_i']},
	{'value': 'ArapsyamAna', 'label': 'ArapsyamAna', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'begin'}], 'isLemma': 0, 'parents': ['A_raB']},
	{'value': 'ArabDa', 'label': 'ArabDa', 'attrib': [{'pos': 'ppp', 'defs': 'begin'}], 'isLemma': 0, 'parents': ['A_raB']},
	{'value': 'ArabDavat', 'label': 'ArabDavat', 'attrib': [{'pos': 'pap', 'defs': 'begin'}], 'isLemma': 0, 'parents': ['A_raB']},
	{'value': 'ArabDavya', 'label': 'ArabDavya', 'attrib': [{'pos': 'ger', 'defs': 'begin'}], 'isLemma': 0, 'parents': ['A_raB']},
	{'value': 'ArabDum', 'label': 'ArabDum', 'attrib': [{'pos': 'inf', 'defs': 'begin'}], 'isLemma': 0, 'parents': ['A_raB']},
	{'value': 'AraBamARa', 'label': 'AraBamARa', 'attrib': [{'pos': 'prmp', 'defs': 'begin'}], 'isLemma': 0, 'parents': ['A_raB']},
	{'value': 'AraBya', 'label': 'AraBya', 'attrib': [{'pos': 'abs', 'defs': 'begin'}, {'pos': 'ger', 'defs': 'begin'}], 'isLemma': 0, 'parents': ['A_raB']},
	{'value': 'AraByamARa', 'label': 'AraByamARa', 'attrib': [{'pos': 'prpp', 'defs': 'begin'}], 'isLemma': 0, 'parents': ['A_raB']},
	{'value': 'AramBanIya', 'label': 'AramBanIya', 'attrib': [{'pos': 'ger', 'defs': 'begin'}], 'isLemma': 0, 'parents': ['A_raB']},
	{'value': 'AreBARa', 'label': 'AreBARa', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'begin'}], 'isLemma': 0, 'parents': ['A_raB']},
	{'value': 'Avaha', 'label': 'Avaha', 'attrib': [{'pos': 'adj', 'defs': 'bringing'}], 'isLemma': 1},
	{'value': 'ASis', 'label': 'ASis', 'attrib': [{'pos': 'f', 'defs': "serpent's fang"}], 'isLemma': 1},
	{'value': 'ASI', 'label': 'ASI', 'attrib': [{'pos': 'f', 'defs': "serpent's fang"}], 'isLemma': 1},
	{'value': 'ASIviza', 'label': 'ASIviza', 'attrib': [{'pos': 'adj', 'defs': 'venom-fanged'}], 'isLemma': 1},
	{'value': 'Ahita', 'label': 'Ahita', 'attrib': [{'pos': 'ppp', 'defs': 'put on, apply'}], 'isLemma': 0, 'parents': ['A_DA']},
	{'value': 'Ahitavat', 'label': 'Ahitavat', 'attrib': [{'pos': 'pap', 'defs': 'put on, apply'}], 'isLemma': 0, 'parents': ['A_DA']},
	{'value': 'i', 'label': 'i', 'attrib': [{'pos': 'vt2a', 'defs': 'go'}], 'isLemma': 1},
	{'value': 'icCat', 'label': 'icCat', 'attrib': [{'pos': 'prap', 'defs': 'desire'}], 'isLemma': 0, 'parents': ['iz']},
	{'value': 'ita', 'label': 'ita', 'attrib': [{'pos': 'ppp', 'defs': 'go'}], 'isLemma': 0, 'parents': ['i']},
	{'value': 'itavat', 'label': 'itavat', 'attrib': [{'pos': 'pap', 'defs': 'go'}], 'isLemma': 0, 'parents': ['i']},
	{'value': 'iti', 'label': 'iti', 'attrib': [{'pos': 'pcl', 'defs': 'thus, close quote'}], 'isLemma': 1},
	{'value': 'itya', 'label': 'itya', 'attrib': [{'pos': 'ger', 'defs': 'go'}], 'isLemma': 0, 'parents': ['i']},
	{'value': 'itvA', 'label': 'itvA', 'attrib': [{'pos': 'abs', 'defs': 'go'}], 'isLemma': 0, 'parents': ['i']},
	{'value': 'idam', 'label': 'idam', 'attrib': [{'pos': 'dem_adj, dem_pron', 'defs': 'this'}], 'isLemma': 1},
	{'value': 'indra', 'label': 'indra', 'attrib': [{'pos': 'm_pn', 'defs': 'Indra'}], 'isLemma': 1},
	{'value': 'iva', 'label': 'iva', 'attrib': [{'pos': 'pcl', 'defs': 'like, as, as if'}], 'isLemma': 1},
	{'value': 'iz', 'label': 'iz', 'attrib': [{'pos': 'vt6a', 'defs': 'desire'}], 'isLemma': 1},
	{'value': 'izu', 'label': 'izu', 'attrib': [{'pos': 'm, f', 'defs': 'arrow'}], 'isLemma': 1},
	{'value': 'izuDi', 'label': 'izuDi', 'attrib': [{'pos': 'm', 'defs': 'quiver'}], 'isLemma': 1},
	{'value': 'izwa', 'label': 'izwa', 'attrib': [{'pos': 'ppp', 'defs': 'desire'}], 'isLemma': 0, 'parents': ['iz']},
	{'value': 'izwavat', 'label': 'izwavat', 'attrib': [{'pos': 'pap', 'defs': 'desire'}], 'isLemma': 0, 'parents': ['iz']},
	{'value': 'izwvA', 'label': 'izwvA', 'attrib': [{'pos': 'abs', 'defs': 'desire'}], 'isLemma': 0, 'parents': ['iz']},
	{'value': 'izyamARa', 'label': 'izyamARa', 'attrib': [{'pos': 'prpp', 'defs': 'desire'}], 'isLemma': 0, 'parents': ['iz']},
	{'value': 'IyamAna', 'label': 'IyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'go'}], 'isLemma': 0, 'parents': ['i']},
	{'value': 'IyAna', 'label': 'IyAna', 'attrib': [{'pos': 'pfpp', 'defs': 'go'}], 'isLemma': 0, 'parents': ['i']},
	{'value': 'Iyivas', 'label': 'Iyivas', 'attrib': [{'pos': 'pfap', 'defs': 'go'}], 'isLemma': 0, 'parents': ['i']},
	{'value': 'IS', 'label': 'IS', 'attrib': [{'pos': 'vt2m', 'defs': 'rule'}], 'isLemma': 1},
	{'value': 'ISa', 'label': 'ISa', 'attrib': [{'pos': 'm', 'defs': 'lord'}], 'isLemma': 1},
	{'value': 'IzARa', 'label': 'IzARa', 'attrib': [{'pos': 'pfpp', 'defs': 'desire'}], 'isLemma': 0, 'parents': ['iz']},
	{'value': 'Izivas', 'label': 'Izivas', 'attrib': [{'pos': 'pfap', 'defs': 'desire'}], 'isLemma': 0, 'parents': ['iz']},
	{'value': 'ukta', 'label': 'ukta', 'attrib': [{'pos': 'ppp', 'defs': 'say, speak'}], 'isLemma': 0, 'parents': ['vac']},
	{'value': 'uktavat', 'label': 'uktavat', 'attrib': [{'pos': 'pap', 'defs': 'say, speak'}], 'isLemma': 0, 'parents': ['vac']},
	{'value': 'uktvA', 'label': 'uktvA', 'attrib': [{'pos': 'abs', 'defs': 'say, speak'}], 'isLemma': 0, 'parents': ['vac']},
	{'value': 'ucyamAna', 'label': 'ucyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'say, speak'}], 'isLemma': 0, 'parents': ['vac']},
	{'value': 'uttama', 'label': 'uttama', 'attrib': [{'pos': 'superlative_adj', 'defs': 'highest, supreme'}], 'isLemma': 1},
	{'value': 'uttasTAna', 'label': 'uttasTAna', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'stand up, arise'}], 'isLemma': 0, 'parents': ['ud_sTA']},
	{'value': 'uttasTivas', 'label': 'uttasTivas', 'attrib': [{'pos': 'pfap', 'defs': 'stand up, arise'}], 'isLemma': 0, 'parents': ['ud_sTA']},
	{'value': 'uttizWat', 'label': 'uttizWat', 'attrib': [{'pos': 'prap', 'defs': 'stand up, arise'}], 'isLemma': 0, 'parents': ['ud_sTA']},
	{'value': 'uttizWamAna', 'label': 'uttizWamAna', 'attrib': [{'pos': 'prmp', 'defs': 'stand up, arise'}], 'isLemma': 0, 'parents': ['ud_sTA']},
	{'value': 'utTAtavya', 'label': 'utTAtavya', 'attrib': [{'pos': 'ger', 'defs': 'stand up, arise'}], 'isLemma': 0, 'parents': ['ud_sTA']},
	{'value': 'utTAtum', 'label': 'utTAtum', 'attrib': [{'pos': 'inf', 'defs': 'stand up, arise'}], 'isLemma': 0, 'parents': ['ud_sTA']},
	{'value': 'utTAnIya', 'label': 'utTAnIya', 'attrib': [{'pos': 'ger', 'defs': 'stand up, arise'}], 'isLemma': 0, 'parents': ['ud_sTA']},
	{'value': 'utTAya', 'label': 'utTAya', 'attrib': [{'pos': 'abs', 'defs': 'stand up, arise'}], 'isLemma': 0, 'parents': ['ud_sTA']},
	{'value': 'utTAsyat', 'label': 'utTAsyat', 'attrib': [{'pos': 'fap', 'defs': 'stand up, arise'}], 'isLemma': 0, 'parents': ['ud_sTA']},
	{'value': 'utTAsyamAna', 'label': 'utTAsyamAna', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'stand up, arise'}], 'isLemma': 0, 'parents': ['ud_sTA']},
	{'value': 'utTita', 'label': 'utTita', 'attrib': [{'pos': 'ppp', 'defs': 'stand up, arise'}], 'isLemma': 0, 'parents': ['ud_sTA']},
	{'value': 'utTitavat', 'label': 'utTitavat', 'attrib': [{'pos': 'pap', 'defs': 'stand up, arise'}], 'isLemma': 0, 'parents': ['ud_sTA']},
	{'value': 'utTeya', 'label': 'utTeya', 'attrib': [{'pos': 'ger', 'defs': 'stand up, arise'}], 'isLemma': 0, 'parents': ['ud_sTA']},
	{'value': 'utsattavya', 'label': 'utsattavya', 'attrib': [{'pos': 'ger', 'defs': 'sit up, withdraw, disappear, be ruined; caus.: ruin, destroy'}], 'isLemma': 0, 'parents': ['ud_sad']},
	{'value': 'utsattum', 'label': 'utsattum', 'attrib': [{'pos': 'inf', 'defs': 'sit up, withdraw, disappear, be ruined; caus.: ruin, destroy'}], 'isLemma': 0, 'parents': ['ud_sad']},
	{'value': 'utsatsyat', 'label': 'utsatsyat', 'attrib': [{'pos': 'fap', 'defs': 'sit up, withdraw, disappear, be ruined; caus.: ruin, destroy'}], 'isLemma': 0, 'parents': ['ud_sad']},
	{'value': 'utsatsyamAna', 'label': 'utsatsyamAna', 'attrib': [{'pos': 'fpp', 'defs': 'sit up, withdraw, disappear, be ruined; caus.: ruin, destroy'}], 'isLemma': 0, 'parents': ['ud_sad']},
	{'value': 'utsadanIya', 'label': 'utsadanIya', 'attrib': [{'pos': 'ger', 'defs': 'sit up, withdraw, disappear, be ruined; caus.: ruin, destroy'}], 'isLemma': 0, 'parents': ['ud_sad']},
	{'value': 'utsadya', 'label': 'utsadya', 'attrib': [{'pos': 'abs', 'defs': 'sit up, withdraw, disappear, be ruined; caus.: ruin, destroy'}], 'isLemma': 0, 'parents': ['ud_sad']},
	{'value': 'utsadyamAna', 'label': 'utsadyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'sit up, withdraw, disappear, be ruined; caus.: ruin, destroy'}], 'isLemma': 0, 'parents': ['ud_sad']},
	{'value': 'utsanna', 'label': 'utsanna', 'attrib': [{'pos': 'ppp', 'defs': 'sit up, withdraw, disappear, be ruined; caus.: ruin, destroy'}], 'isLemma': 0, 'parents': ['ud_sad']},
	{'value': 'utsannavat', 'label': 'utsannavat', 'attrib': [{'pos': 'pap', 'defs': 'sit up, withdraw, disappear, be ruined; caus.: ruin, destroy'}], 'isLemma': 0, 'parents': ['ud_sad']},
	{'value': 'utsAdya', 'label': 'utsAdya', 'attrib': [{'pos': 'abs', 'defs': 'sit up, withdraw, disappear, be ruined; caus.: ruin, destroy'}, {'pos': 'ger', 'defs': 'sit up, withdraw, disappear, be ruined; caus.: ruin, destroy'}], 'isLemma': 0, 'parents': ['ud_sad']},
	{'value': 'utsIdat', 'label': 'utsIdat', 'attrib': [{'pos': 'prap', 'defs': 'sit up, withdraw, disappear, be ruined; caus.: ruin, destroy'}], 'isLemma': 0, 'parents': ['ud_sad']},
	{'value': 'utsedAna', 'label': 'utsedAna', 'attrib': [{'pos': 'pfpp', 'defs': 'sit up, withdraw, disappear, be ruined; caus.: ruin, destroy'}], 'isLemma': 0, 'parents': ['ud_sad']},
	{'value': 'utsedivas', 'label': 'utsedivas', 'attrib': [{'pos': 'pfap', 'defs': 'sit up, withdraw, disappear, be ruined; caus.: ruin, destroy'}], 'isLemma': 0, 'parents': ['ud_sad']},
	{'value': 'utsTIyamAna', 'label': 'utsTIyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'stand up, arise'}], 'isLemma': 0, 'parents': ['ud_sTA']},
	{'value': 'ud', 'label': 'ud', 'attrib': [{'pos': 'preverb', 'defs': 'up'}], 'isLemma': 1},
	{'value': 'udaYc', 'label': 'udaYc', 'attrib': [{'pos': 'adj', 'defs': 'upward, northern'}], 'isLemma': 1},
	{'value': 'udara', 'label': 'udara', 'attrib': [{'pos': 'n', 'defs': 'uterus, womb, belly'}], 'isLemma': 1},
	{'value': 'udita', 'label': 'udita', 'attrib': [{'pos': 'ppp', 'defs': 'tell'}], 'isLemma': 0, 'parents': ['vad']},
	{'value': 'uditavat', 'label': 'uditavat', 'attrib': [{'pos': 'pap', 'defs': 'tell'}], 'isLemma': 0, 'parents': ['vad']},
	{'value': 'uditvA', 'label': 'uditvA', 'attrib': [{'pos': 'abs', 'defs': 'tell'}], 'isLemma': 0, 'parents': ['vad']},
	{'value': 'udIcI', 'label': 'udIcI', 'attrib': [{'pos': 'f', 'defs': 'the north'}], 'isLemma': 1},
	{'value': 'ud_aYc', 'label': 'ud_aYc', 'attrib': [{'pos': 'v1am', 'defs': 'bend up, rise'}], 'isLemma': 1},
	{'value': 'ud_sad', 'label': 'ud_sad', 'attrib': [{'pos': 'vi1a, vi6a', 'defs': 'sit up, withdraw, disappear, be ruined; caus.: ruin, destroy'}], 'isLemma': 1},
	{'value': 'ud_sah', 'label': 'ud_sah', 'attrib': [{'pos': 'vt1m', 'defs': 'be eager; caus.: encourage, inspire'}], 'isLemma': 1},
	{'value': 'ud_sTA', 'label': 'ud_sTA', 'attrib': [{'pos': 'vi1am', 'defs': 'stand up, arise'}], 'isLemma': 1},
	{'value': 'udyamAna', 'label': 'udyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'tell'}], 'isLemma': 0, 'parents': ['vad']},
	{'value': 'upa', 'label': 'upa', 'attrib': [{'pos': 'preverb', 'defs': 'under, near'}], 'isLemma': 1},
	{'value': 'upa_i', 'label': 'upa_i', 'attrib': [{'pos': 'vt2a', 'defs': 'approach'}], 'isLemma': 1},
	{'value': 'upayat', 'label': 'upayat', 'attrib': [{'pos': 'prap', 'defs': 'approach'}], 'isLemma': 0, 'parents': ['upa_i']},
	{'value': 'upAya', 'label': 'upAya', 'attrib': [{'pos': 'm', 'defs': 'approach, means'}], 'isLemma': 1},
	{'value': 'upAyanIya', 'label': 'upAyanIya', 'attrib': [{'pos': 'ger', 'defs': 'approach'}], 'isLemma': 0, 'parents': ['upa_i']},
	{'value': 'upeta', 'label': 'upeta', 'attrib': [{'pos': 'ppp', 'defs': 'approach'}, {'pos': 'adj', 'defs': 'endowed with'}], 'isLemma': 1},
	{'value': 'upeta', 'label': 'upeta', 'attrib': [{'pos': 'ppp', 'defs': 'approach'}, {'pos': 'adj', 'defs': 'endowed with'}], 'isLemma': 0, 'parents': ['upa_i']},
	{'value': 'upetavat', 'label': 'upetavat', 'attrib': [{'pos': 'pap', 'defs': 'approach'}], 'isLemma': 0, 'parents': ['upa_i']},
	{'value': 'upetya', 'label': 'upetya', 'attrib': [{'pos': 'abs', 'defs': 'approach'}, {'pos': 'ger', 'defs': 'approach'}], 'isLemma': 0, 'parents': ['upa_i']},
	{'value': 'upeyamAna', 'label': 'upeyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'approach'}], 'isLemma': 0, 'parents': ['upa_i']},
	{'value': 'upeyAna', 'label': 'upeyAna', 'attrib': [{'pos': 'pfpp', 'defs': 'approach'}], 'isLemma': 0, 'parents': ['upa_i']},
	{'value': 'upeyivas', 'label': 'upeyivas', 'attrib': [{'pos': 'pfap', 'defs': 'approach'}], 'isLemma': 0, 'parents': ['upa_i']},
	{'value': 'upEtavya', 'label': 'upEtavya', 'attrib': [{'pos': 'ger', 'defs': 'approach'}], 'isLemma': 0, 'parents': ['upa_i']},
	{'value': 'upEtum', 'label': 'upEtum', 'attrib': [{'pos': 'inf', 'defs': 'approach'}], 'isLemma': 0, 'parents': ['upa_i']},
	{'value': 'upEzyat', 'label': 'upEzyat', 'attrib': [{'pos': 'fap', 'defs': 'approach'}], 'isLemma': 0, 'parents': ['upa_i']},
	{'value': 'upEzyamARa', 'label': 'upEzyamARa', 'attrib': [{'pos': 'fpp', 'defs': 'approach'}], 'isLemma': 0, 'parents': ['upa_i']},
	{'value': 'uSat', 'label': 'uSat', 'attrib': [{'pos': 'prap', 'defs': 'wish'}], 'isLemma': 0, 'parents': ['vaS']},
	{'value': 'uSita', 'label': 'uSita', 'attrib': [{'pos': 'ppp', 'defs': 'wish'}], 'isLemma': 0, 'parents': ['vaS']},
	{'value': 'uSitavat', 'label': 'uSitavat', 'attrib': [{'pos': 'pap', 'defs': 'wish'}], 'isLemma': 0, 'parents': ['vaS']},
	{'value': 'uSyamAna', 'label': 'uSyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'wish'}], 'isLemma': 0, 'parents': ['vaS']},
	{'value': 'uzita', 'label': 'uzita', 'attrib': [{'pos': 'ppp', 'defs': 'dwell, live'}], 'isLemma': 0, 'parents': ['vas']},
	{'value': 'uzitavat', 'label': 'uzitavat', 'attrib': [{'pos': 'pap', 'defs': 'dwell, live'}], 'isLemma': 0, 'parents': ['vas']},
	{'value': 'uzitvA', 'label': 'uzitvA', 'attrib': [{'pos': 'abs', 'defs': 'dwell, live'}], 'isLemma': 0, 'parents': ['vas']},
	{'value': 'uzyamARa', 'label': 'uzyamARa', 'attrib': [{'pos': 'prpp', 'defs': 'dwell, live'}], 'isLemma': 0, 'parents': ['vas']},
	{'value': 'uhyamAna', 'label': 'uhyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'carry, flow, wed'}], 'isLemma': 0, 'parents': ['vah']},
	{'value': 'UcAna', 'label': 'UcAna', 'attrib': [{'pos': 'pfpp', 'defs': 'say, speak'}], 'isLemma': 0, 'parents': ['vac']},
	{'value': 'Ucivas', 'label': 'Ucivas', 'attrib': [{'pos': 'pfap', 'defs': 'say, speak'}], 'isLemma': 0, 'parents': ['vac']},
	{'value': 'UQa', 'label': 'UQa', 'attrib': [{'pos': 'ppp', 'defs': 'carry, flow, wed'}], 'isLemma': 0, 'parents': ['vah']},
	{'value': 'UQavat', 'label': 'UQavat', 'attrib': [{'pos': 'pap', 'defs': 'carry, flow, wed'}], 'isLemma': 0, 'parents': ['vah']},
	{'value': 'UQvA', 'label': 'UQvA', 'attrib': [{'pos': 'abs', 'defs': 'carry, flow, wed'}], 'isLemma': 0, 'parents': ['vah']},
	{'value': 'UdAna', 'label': 'UdAna', 'attrib': [{'pos': 'pfpp', 'defs': 'tell'}], 'isLemma': 0, 'parents': ['vad']},
	{'value': 'Udivas', 'label': 'Udivas', 'attrib': [{'pos': 'pfap', 'defs': 'tell'}], 'isLemma': 0, 'parents': ['vad']},
	{'value': 'UzARa', 'label': 'UzARa', 'attrib': [{'pos': 'pfpp', 'defs': 'dwell, live'}], 'isLemma': 0, 'parents': ['vas']},
	{'value': 'Uzivas', 'label': 'Uzivas', 'attrib': [{'pos': 'pfap', 'defs': 'dwell, live'}], 'isLemma': 0, 'parents': ['vas']},
	{'value': 'UhAna', 'label': 'UhAna', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'carry, flow, wed'}], 'isLemma': 0, 'parents': ['vah']},
	{'value': 'Uhivas', 'label': 'Uhivas', 'attrib': [{'pos': 'pfap', 'defs': 'carry, flow, wed'}], 'isLemma': 0, 'parents': ['vah']},
	{'value': 'fzaBa', 'label': 'fzaBa', 'attrib': [{'pos': 'm', 'defs': 'bull'}], 'isLemma': 1},
	{'value': 'fzi', 'label': 'fzi', 'attrib': [{'pos': 'm', 'defs': 'Vedic seer'}], 'isLemma': 1},
	{'value': 'eka', 'label': 'eka', 'attrib': [{'pos': 'card_num', 'defs': 'one'}], 'isLemma': 1},
	{'value': 'ekacakra', 'label': 'ekacakra', 'attrib': [{'pos': 'adj', 'defs': "one-wheeled, epithet of the sun's chariot, one-kinged, one-armied"}], 'isLemma': 1},
	{'value': 'ekacakrA', 'label': 'ekacakrA', 'attrib': [{'pos': 'f_pn', 'defs': 'Ekacakrā'}], 'isLemma': 1},
	{'value': 'eta', 'label': 'eta', 'attrib': [{'pos': 'ppp', 'defs': 'come'}], 'isLemma': 0, 'parents': ['A_i']},
	{'value': 'etad', 'label': 'etad', 'attrib': [{'pos': 'dem_adj, dem_pron', 'defs': 'this'}], 'isLemma': 1},
	{'value': 'etavat', 'label': 'etavat', 'attrib': [{'pos': 'pap', 'defs': 'come'}], 'isLemma': 0, 'parents': ['A_i']},
	{'value': 'etavya', 'label': 'etavya', 'attrib': [{'pos': 'ger', 'defs': 'go'}], 'isLemma': 0, 'parents': ['i']},
	{'value': 'etum', 'label': 'etum', 'attrib': [{'pos': 'inf', 'defs': 'go'}], 'isLemma': 0, 'parents': ['i']},
	{'value': 'etya', 'label': 'etya', 'attrib': [{'pos': 'abs', 'defs': 'come'}, {'pos': 'ger', 'defs': 'come'}], 'isLemma': 0, 'parents': ['A_i']},
	{'value': 'enad', 'label': 'enad', 'attrib': [{'pos': 'dem_adj, dem_pron', 'defs': 'this, it'}], 'isLemma': 1},
	{'value': 'eyamAna', 'label': 'eyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'come'}], 'isLemma': 0, 'parents': ['A_i']},
	{'value': 'eyAna', 'label': 'eyAna', 'attrib': [{'pos': 'pfpp', 'defs': 'come'}], 'isLemma': 0, 'parents': ['A_i']},
	{'value': 'eyivas', 'label': 'eyivas', 'attrib': [{'pos': 'pfap', 'defs': 'come'}], 'isLemma': 0, 'parents': ['A_i']},
	{'value': 'eva', 'label': 'eva', 'attrib': [{'pos': 'pcl', 'defs': 'only'}], 'isLemma': 1},
	{'value': 'evam', 'label': 'evam', 'attrib': [{'pos': 'dem_adv', 'defs': 'in this way, thus'}], 'isLemma': 1},
	{'value': 'ezaRIya', 'label': 'ezaRIya', 'attrib': [{'pos': 'ger', 'defs': 'desire'}], 'isLemma': 0, 'parents': ['iz']},
	{'value': 'ezizyat', 'label': 'ezizyat', 'attrib': [{'pos': 'fap', 'defs': 'desire'}], 'isLemma': 0, 'parents': ['iz']},
	{'value': 'ezizyamARa', 'label': 'ezizyamARa', 'attrib': [{'pos': 'fpp', 'defs': 'desire'}], 'isLemma': 0, 'parents': ['iz']},
	{'value': 'ezwavya', 'label': 'ezwavya', 'attrib': [{'pos': 'ger', 'defs': 'desire'}], 'isLemma': 0, 'parents': ['iz']},
	{'value': 'ezwum', 'label': 'ezwum', 'attrib': [{'pos': 'inf', 'defs': 'desire'}], 'isLemma': 0, 'parents': ['iz']},
	{'value': 'ezya', 'label': 'ezya', 'attrib': [{'pos': 'ger', 'defs': 'desire'}], 'isLemma': 0, 'parents': ['iz']},
	{'value': 'ezyat', 'label': 'ezyat', 'attrib': [{'pos': 'fap', 'defs': 'go'}], 'isLemma': 0, 'parents': ['i']},
	{'value': 'ezyamARa', 'label': 'ezyamARa', 'attrib': [{'pos': 'fpp', 'defs': 'go'}], 'isLemma': 0, 'parents': ['i']},
	{'value': 'Etavya', 'label': 'Etavya', 'attrib': [{'pos': 'ger', 'defs': 'come'}], 'isLemma': 0, 'parents': ['A_i']},
	{'value': 'Etum', 'label': 'Etum', 'attrib': [{'pos': 'inf', 'defs': 'come'}], 'isLemma': 0, 'parents': ['A_i']},
	{'value': 'Ezyat', 'label': 'Ezyat', 'attrib': [{'pos': 'fap', 'defs': 'come'}], 'isLemma': 0, 'parents': ['A_i']},
	{'value': 'EzyamARa', 'label': 'EzyamARa', 'attrib': [{'pos': 'fpp', 'defs': 'come'}], 'isLemma': 0, 'parents': ['A_i']},
	{'value': 'ojas', 'label': 'ojas', 'attrib': [{'pos': 'n', 'defs': 'vigour, energy'}], 'isLemma': 1},
	{'value': 'kaT', 'label': 'kaT', 'attrib': [{'pos': 'vt10am', 'defs': 'tell, narrate'}], 'isLemma': 1},
	{'value': 'kaTanIya', 'label': 'kaTanIya', 'attrib': [{'pos': 'ger', 'defs': 'tell, narrate'}], 'isLemma': 0, 'parents': ['kaT']},
	{'value': 'kaTam', 'label': 'kaTam', 'attrib': [{'pos': 'int_adv', 'defs': 'how'}], 'isLemma': 1},
	{'value': 'kaTayat', 'label': 'kaTayat', 'attrib': [{'pos': 'prap', 'defs': 'tell, narrate'}], 'isLemma': 0, 'parents': ['kaT']},
	{'value': 'kaTayamAna', 'label': 'kaTayamAna', 'attrib': [{'pos': 'prmp', 'defs': 'tell, narrate'}], 'isLemma': 0, 'parents': ['kaT']},
	{'value': 'kaTayAm', 'label': 'kaTayAm', 'attrib': [{'pos': 'ppf_actn', 'defs': 'tell, narrate'}], 'isLemma': 0, 'parents': ['kaT']},
	{'value': 'kaTayitavya', 'label': 'kaTayitavya', 'attrib': [{'pos': 'ger', 'defs': 'tell, narrate'}], 'isLemma': 0, 'parents': ['kaT']},
	{'value': 'kaTayitum', 'label': 'kaTayitum', 'attrib': [{'pos': 'inf', 'defs': 'tell, narrate'}], 'isLemma': 0, 'parents': ['kaT']},
	{'value': 'kaTayitvA', 'label': 'kaTayitvA', 'attrib': [{'pos': 'abs', 'defs': 'tell, narrate'}], 'isLemma': 0, 'parents': ['kaT']},
	{'value': 'kaTayizyat', 'label': 'kaTayizyat', 'attrib': [{'pos': 'fap', 'defs': 'tell, narrate'}], 'isLemma': 0, 'parents': ['kaT']},
	{'value': 'kaTayizyamARa', 'label': 'kaTayizyamARa', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'tell, narrate'}], 'isLemma': 0, 'parents': ['kaT']},
	{'value': 'kaTA', 'label': 'kaTA', 'attrib': [{'pos': 'f', 'defs': 'story, narration'}], 'isLemma': 1},
	{'value': 'kaTita', 'label': 'kaTita', 'attrib': [{'pos': 'ppp', 'defs': 'tell, narrate'}], 'isLemma': 0, 'parents': ['kaT']},
	{'value': 'kaTitavat', 'label': 'kaTitavat', 'attrib': [{'pos': 'pap', 'defs': 'tell, narrate'}], 'isLemma': 0, 'parents': ['kaT']},
	{'value': 'kaTya', 'label': 'kaTya', 'attrib': [{'pos': 'ger', 'defs': 'tell, narrate'}], 'isLemma': 0, 'parents': ['kaT']},
	{'value': 'kaTyamAna', 'label': 'kaTyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'tell, narrate'}], 'isLemma': 0, 'parents': ['kaT']},
	{'value': 'kadA', 'label': 'kadA', 'attrib': [{'pos': 'int_adv', 'defs': 'when'}], 'isLemma': 1},
	{'value': 'kanyA', 'label': 'kanyA', 'attrib': [{'pos': 'f', 'defs': 'girl'}], 'isLemma': 1},
	{'value': 'kapi', 'label': 'kapi', 'attrib': [{'pos': 'm', 'defs': 'monkey'}], 'isLemma': 1},
	{'value': 'kapilakzaRa', 'label': 'kapilakzaRa', 'attrib': [{'pos': 'adj', 'defs': 'monkey-bannered'}], 'isLemma': 1},
	{'value': 'karaRIya', 'label': 'karaRIya', 'attrib': [{'pos': 'ger', 'defs': 'do, make'}], 'isLemma': 0, 'parents': ['kf']},
	{'value': 'karizyat', 'label': 'karizyat', 'attrib': [{'pos': 'fap', 'defs': 'do, make'}], 'isLemma': 0, 'parents': ['kf']},
	{'value': 'karizyamARa', 'label': 'karizyamARa', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'do, make'}], 'isLemma': 0, 'parents': ['kf']},
	{'value': 'karRa', 'label': 'karRa', 'attrib': [{'pos': 'm', 'defs': 'ear'}, {'pos': 'm_pn', 'defs': 'Karṇa'}], 'isLemma': 1},
	{'value': 'kartavya', 'label': 'kartavya', 'attrib': [{'pos': 'ger', 'defs': 'do, make'}], 'isLemma': 0, 'parents': ['kf']},
	{'value': 'kartum', 'label': 'kartum', 'attrib': [{'pos': 'inf', 'defs': 'do, make'}], 'isLemma': 0, 'parents': ['kf']},
	{'value': 'karman', 'label': 'karman', 'attrib': [{'pos': 'n', 'defs': 'action, deed'}], 'isLemma': 1},
	{'value': 'kAraka', 'label': 'kAraka', 'attrib': [{'pos': 'adj', 'defs': 'making, causing'}, {'pos': 'n', 'defs': 'cause'}], 'isLemma': 1},
	{'value': 'kArya', 'label': 'kArya', 'attrib': [{'pos': 'ger', 'defs': 'do, make'}], 'isLemma': 0, 'parents': ['kf']},
	{'value': 'kAS', 'label': 'kAS', 'attrib': [{'pos': 'vi1m, vi4m', 'defs': 'shine'}], 'isLemma': 1},
	{'value': 'kASanIya', 'label': 'kASanIya', 'attrib': [{'pos': 'ger', 'defs': 'shine'}], 'isLemma': 0, 'parents': ['kAS']},
	{'value': 'kASamAna', 'label': 'kASamAna', 'attrib': [{'pos': 'prmp', 'defs': 'shine'}], 'isLemma': 0, 'parents': ['kAS']},
	{'value': 'kASARa', 'label': 'kASARa', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'shine'}], 'isLemma': 0, 'parents': ['kAS']},
	{'value': 'kASita', 'label': 'kASita', 'attrib': [{'pos': 'ppp', 'defs': 'shine'}], 'isLemma': 0, 'parents': ['kAS']},
	{'value': 'kASitavat', 'label': 'kASitavat', 'attrib': [{'pos': 'pap', 'defs': 'shine'}], 'isLemma': 0, 'parents': ['kAS']},
	{'value': 'kASitavya', 'label': 'kASitavya', 'attrib': [{'pos': 'ger', 'defs': 'shine'}], 'isLemma': 0, 'parents': ['kAS']},
	{'value': 'kASitum', 'label': 'kASitum', 'attrib': [{'pos': 'inf', 'defs': 'shine'}], 'isLemma': 0, 'parents': ['kAS']},
	{'value': 'kASitvA', 'label': 'kASitvA', 'attrib': [{'pos': 'abs', 'defs': 'shine'}], 'isLemma': 0, 'parents': ['kAS']},
	{'value': 'kASizyamARa', 'label': 'kASizyamARa', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'shine'}], 'isLemma': 0, 'parents': ['kAS']},
	{'value': 'kASya', 'label': 'kASya', 'attrib': [{'pos': 'ger', 'defs': 'shine'}], 'isLemma': 0, 'parents': ['kAS']},
	{'value': 'kASyamAna', 'label': 'kASyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'shine'}], 'isLemma': 0, 'parents': ['kAS']},
	{'value': 'kim', 'label': 'kim', 'attrib': [{'pos': 'int_adj, int_pron', 'defs': 'what'}], 'isLemma': 1},
	{'value': 'kuntI', 'label': 'kuntI', 'attrib': [{'pos': 'f_pn', 'defs': 'Kuntī'}], 'isLemma': 1},
	{'value': 'kuru', 'label': 'kuru', 'attrib': [{'pos': 'm_pn', 'defs': 'Kuru ; pl. his descendants'}], 'isLemma': 1},
	{'value': 'kurupARqava', 'label': 'kurupARqava', 'attrib': [{'pos': 'm', 'defs': 'the descendants of Kuru and the sons of Pāṇḍu'}], 'isLemma': 1},
	{'value': 'kurvat', 'label': 'kurvat', 'attrib': [{'pos': 'prap', 'defs': 'do, make'}], 'isLemma': 0, 'parents': ['kf']},
	{'value': 'kurvARa', 'label': 'kurvARa', 'attrib': [{'pos': 'prmp', 'defs': 'do, make'}], 'isLemma': 0, 'parents': ['kf']},
	{'value': 'kf', 'label': 'kf', 'attrib': [{'pos': 'vt8am', 'defs': 'do, make'}], 'isLemma': 1},
	{'value': 'kfta', 'label': 'kfta', 'attrib': [{'pos': 'ppp', 'defs': 'do, make'}], 'isLemma': 0, 'parents': ['kf']},
	{'value': 'kftavat', 'label': 'kftavat', 'attrib': [{'pos': 'pap', 'defs': 'do, make'}], 'isLemma': 0, 'parents': ['kf']},
	{'value': 'kftya', 'label': 'kftya', 'attrib': [{'pos': 'ger', 'defs': 'do, make'}], 'isLemma': 0, 'parents': ['kf']},
	{'value': 'kftvA', 'label': 'kftvA', 'attrib': [{'pos': 'abs', 'defs': 'do, make'}], 'isLemma': 0, 'parents': ['kf']},
	{'value': 'kftsna', 'label': 'kftsna', 'attrib': [{'pos': 'adj', 'defs': 'whole, entire'}], 'isLemma': 1},
	{'value': 'kfzRa', 'label': 'kfzRa', 'attrib': [{'pos': 'adj', 'defs': 'dark, black'}, {'pos': 'm_pn', 'defs': 'Kṛṣṇa'}], 'isLemma': 1},
	{'value': 'kfzRasarpa', 'label': 'kfzRasarpa', 'attrib': [{'pos': 'm', 'defs': 'black snake, cobra'}], 'isLemma': 1},
	{'value': 'keSa', 'label': 'keSa', 'attrib': [{'pos': 'm', 'defs': 'hair (of the head), mane'}], 'isLemma': 1},
	{'value': 'keSava', 'label': 'keSava', 'attrib': [{'pos': 'adj', 'defs': 'having hair'}], 'isLemma': 1},
	{'value': 'kowi', 'label': 'kowi', 'attrib': [{'pos': 'f', 'defs': 'end, top'}], 'isLemma': 1},
	{'value': 'kOnteya', 'label': 'kOnteya', 'attrib': [{'pos': 'm', 'defs': 'son of Kuntī'}], 'isLemma': 1},
	{'value': 'kram', 'label': 'kram', 'attrib': [{'pos': 'vt1a', 'defs': 'step'}], 'isLemma': 1},
	{'value': 'krama', 'label': 'krama', 'attrib': [{'pos': 'm', 'defs': 'step, succession'}], 'isLemma': 1},
	{'value': 'kriyamARa', 'label': 'kriyamARa', 'attrib': [{'pos': 'prpp', 'defs': 'do, make'}], 'isLemma': 0, 'parents': ['kf']},
	{'value': 'krudDa', 'label': 'krudDa', 'attrib': [{'pos': 'ppp', 'defs': 'be angry'}], 'isLemma': 0, 'parents': ['kruD']},
	{'value': 'krudDavat', 'label': 'krudDavat', 'attrib': [{'pos': 'pap', 'defs': 'be angry'}], 'isLemma': 0, 'parents': ['kruD']},
	{'value': 'krudDvA', 'label': 'krudDvA', 'attrib': [{'pos': 'abs', 'defs': 'be angry'}], 'isLemma': 0, 'parents': ['kruD']},
	{'value': 'kruD', 'label': 'kruD', 'attrib': [{'pos': 'vi4a', 'defs': 'be angry'}], 'isLemma': 1},
	{'value': 'kruDyat', 'label': 'kruDyat', 'attrib': [{'pos': 'prap', 'defs': 'be angry'}], 'isLemma': 0, 'parents': ['kruD']},
	{'value': 'kruDyamAna', 'label': 'kruDyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'be angry'}], 'isLemma': 0, 'parents': ['kruD']},
	{'value': 'krUra', 'label': 'krUra', 'attrib': [{'pos': 'adj', 'defs': 'cruel'}], 'isLemma': 1},
	{'value': 'krotsyat', 'label': 'krotsyat', 'attrib': [{'pos': 'fap', 'defs': 'be angry'}], 'isLemma': 0, 'parents': ['kruD']},
	{'value': 'krotsyamAna', 'label': 'krotsyamAna', 'attrib': [{'pos': 'fpp', 'defs': 'be angry'}], 'isLemma': 0, 'parents': ['kruD']},
	{'value': 'krodDavya', 'label': 'krodDavya', 'attrib': [{'pos': 'ger', 'defs': 'be angry'}], 'isLemma': 0, 'parents': ['kruD']},
	{'value': 'krodDum', 'label': 'krodDum', 'attrib': [{'pos': 'inf', 'defs': 'be angry'}], 'isLemma': 0, 'parents': ['kruD']},
	{'value': 'kroDanIya', 'label': 'kroDanIya', 'attrib': [{'pos': 'ger', 'defs': 'be angry'}], 'isLemma': 0, 'parents': ['kruD']},
	{'value': 'kroDya', 'label': 'kroDya', 'attrib': [{'pos': 'ger', 'defs': 'be angry'}], 'isLemma': 0, 'parents': ['kruD']},
	{'value': 'kliS', 'label': 'kliS', 'attrib': [{'pos': 'vt9a', 'defs': 'torment, afflict'}], 'isLemma': 1},
	{'value': 'kliSita', 'label': 'kliSita', 'attrib': [{'pos': 'ppp', 'defs': 'torment, afflict'}], 'isLemma': 0, 'parents': ['kliS']},
	{'value': 'kliSitavat', 'label': 'kliSitavat', 'attrib': [{'pos': 'pap', 'defs': 'torment, afflict'}], 'isLemma': 0, 'parents': ['kliS']},
	{'value': 'kliSitvA', 'label': 'kliSitvA', 'attrib': [{'pos': 'abs', 'defs': 'torment, afflict'}], 'isLemma': 0, 'parents': ['kliS']},
	{'value': 'kliSnat', 'label': 'kliSnat', 'attrib': [{'pos': 'prap', 'defs': 'torment, afflict'}], 'isLemma': 0, 'parents': ['kliS']},
	{'value': 'kliSyamAna', 'label': 'kliSyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'torment, afflict'}], 'isLemma': 0, 'parents': ['kliS']},
	{'value': 'klizwa', 'label': 'klizwa', 'attrib': [{'pos': 'ppp', 'defs': 'torment, afflict'}], 'isLemma': 0, 'parents': ['kliS']},
	{'value': 'klizwavat', 'label': 'klizwavat', 'attrib': [{'pos': 'pap', 'defs': 'torment, afflict'}], 'isLemma': 0, 'parents': ['kliS']},
	{'value': 'klizwvA', 'label': 'klizwvA', 'attrib': [{'pos': 'abs', 'defs': 'torment, afflict'}], 'isLemma': 0, 'parents': ['kliS']},
	{'value': 'klekzyat', 'label': 'klekzyat', 'attrib': [{'pos': 'fap', 'defs': 'torment, afflict'}], 'isLemma': 0, 'parents': ['kliS']},
	{'value': 'klekzyamARa', 'label': 'klekzyamARa', 'attrib': [{'pos': 'fpp', 'defs': 'torment, afflict'}], 'isLemma': 0, 'parents': ['kliS']},
	{'value': 'kleSanIya', 'label': 'kleSanIya', 'attrib': [{'pos': 'ger', 'defs': 'torment, afflict'}], 'isLemma': 0, 'parents': ['kliS']},
	{'value': 'kleSitavya', 'label': 'kleSitavya', 'attrib': [{'pos': 'ger', 'defs': 'torment, afflict'}], 'isLemma': 0, 'parents': ['kliS']},
	{'value': 'kleSitum', 'label': 'kleSitum', 'attrib': [{'pos': 'inf', 'defs': 'torment, afflict'}], 'isLemma': 0, 'parents': ['kliS']},
	{'value': 'kleSizyat', 'label': 'kleSizyat', 'attrib': [{'pos': 'fap', 'defs': 'torment, afflict'}], 'isLemma': 0, 'parents': ['kliS']},
	{'value': 'kleSizyamARa', 'label': 'kleSizyamARa', 'attrib': [{'pos': 'fpp', 'defs': 'torment, afflict'}], 'isLemma': 0, 'parents': ['kliS']},
	{'value': 'kleSya', 'label': 'kleSya', 'attrib': [{'pos': 'ger', 'defs': 'torment, afflict'}], 'isLemma': 0, 'parents': ['kliS']},
	{'value': 'klezwavya', 'label': 'klezwavya', 'attrib': [{'pos': 'ger', 'defs': 'torment, afflict'}], 'isLemma': 0, 'parents': ['kliS']},
	{'value': 'klezwum', 'label': 'klezwum', 'attrib': [{'pos': 'inf', 'defs': 'torment, afflict'}], 'isLemma': 0, 'parents': ['kliS']},
	{'value': 'kzaMsyat', 'label': 'kzaMsyat', 'attrib': [{'pos': 'fap', 'defs': 'tolerate, forgive'}], 'isLemma': 0, 'parents': ['kzam']},
	{'value': 'kzaMsyamAna', 'label': 'kzaMsyamAna', 'attrib': [{'pos': 'fpp', 'defs': 'tolerate, forgive'}], 'isLemma': 0, 'parents': ['kzam']},
	{'value': 'kzantavya', 'label': 'kzantavya', 'attrib': [{'pos': 'ger', 'defs': 'tolerate, forgive'}], 'isLemma': 0, 'parents': ['kzam']},
	{'value': 'kzantum', 'label': 'kzantum', 'attrib': [{'pos': 'inf', 'defs': 'tolerate, forgive'}], 'isLemma': 0, 'parents': ['kzam']},
	{'value': 'kzantvA', 'label': 'kzantvA', 'attrib': [{'pos': 'abs', 'defs': 'tolerate, forgive'}], 'isLemma': 0, 'parents': ['kzam']},
	{'value': 'kzam', 'label': 'kzam', 'attrib': [{'pos': 'vi4a, vi1m', 'defs': 'tolerate, forgive'}], 'isLemma': 1},
	{'value': 'kzamaRIya', 'label': 'kzamaRIya', 'attrib': [{'pos': 'ger', 'defs': 'tolerate, forgive'}], 'isLemma': 0, 'parents': ['kzam']},
	{'value': 'kzamARa', 'label': 'kzamARa', 'attrib': [{'pos': 'pfpp', 'defs': 'tolerate, forgive'}], 'isLemma': 0, 'parents': ['kzam']},
	{'value': 'kzamitavya', 'label': 'kzamitavya', 'attrib': [{'pos': 'ger', 'defs': 'tolerate, forgive'}], 'isLemma': 0, 'parents': ['kzam']},
	{'value': 'kzamitum', 'label': 'kzamitum', 'attrib': [{'pos': 'inf', 'defs': 'tolerate, forgive'}], 'isLemma': 0, 'parents': ['kzam']},
	{'value': 'kzamitvA', 'label': 'kzamitvA', 'attrib': [{'pos': 'abs', 'defs': 'tolerate, forgive'}], 'isLemma': 0, 'parents': ['kzam']},
	{'value': 'kzamivas', 'label': 'kzamivas', 'attrib': [{'pos': 'pfap', 'defs': 'tolerate, forgive'}], 'isLemma': 0, 'parents': ['kzam']},
	{'value': 'kzamizyat', 'label': 'kzamizyat', 'attrib': [{'pos': 'fap', 'defs': 'tolerate, forgive'}], 'isLemma': 0, 'parents': ['kzam']},
	{'value': 'kzamizyamARa', 'label': 'kzamizyamARa', 'attrib': [{'pos': 'fpp', 'defs': 'tolerate, forgive'}], 'isLemma': 0, 'parents': ['kzam']},
	{'value': 'kzamya', 'label': 'kzamya', 'attrib': [{'pos': 'ger', 'defs': 'tolerate, forgive'}], 'isLemma': 0, 'parents': ['kzam']},
	{'value': 'kzamyamARa', 'label': 'kzamyamARa', 'attrib': [{'pos': 'prpp', 'defs': 'tolerate, forgive'}], 'isLemma': 0, 'parents': ['kzam']},
	{'value': 'kzaya', 'label': 'kzaya', 'attrib': [{'pos': 'm', 'defs': 'destruction'}], 'isLemma': 1},
	{'value': 'kzayanIya', 'label': 'kzayanIya', 'attrib': [{'pos': 'ger', 'defs': 'perish, destroy'}, {'pos': 'ger', 'defs': 'possess, rule'}, {'pos': 'ger', 'defs': 'destroy'}], 'isLemma': 0, 'parents': ['kzi']},
	{'value': 'kzayya', 'label': 'kzayya', 'attrib': [{'pos': 'ger', 'defs': 'perish, destroy'}], 'isLemma': 0, 'parents': ['kzi']},
	{'value': 'kzAnta', 'label': 'kzAnta', 'attrib': [{'pos': 'ppp', 'defs': 'tolerate, forgive'}], 'isLemma': 0, 'parents': ['kzam']},
	{'value': 'kzAntavat', 'label': 'kzAntavat', 'attrib': [{'pos': 'pap', 'defs': 'tolerate, forgive'}], 'isLemma': 0, 'parents': ['kzam']},
	{'value': 'kzAmyat', 'label': 'kzAmyat', 'attrib': [{'pos': 'prap', 'defs': 'tolerate, forgive'}], 'isLemma': 0, 'parents': ['kzam']},
	{'value': 'kzi', 'label': 'kzi', 'attrib': [{'pos': 'vt1a', 'defs': 'perish, destroy'}, {'pos': 'vt6a', 'defs': 'possess, rule'}, {'pos': 'vt5a', 'defs': 'destroy'}], 'isLemma': 1},
	{'value': 'kziRvat', 'label': 'kziRvat', 'attrib': [{'pos': 'prap', 'defs': 'destroy'}], 'isLemma': 0, 'parents': ['kzi']},
	{'value': 'kzit', 'label': 'kzit', 'attrib': [{'pos': 'adj', 'defs': 'ruling'}], 'isLemma': 1},
	{'value': 'kzita', 'label': 'kzita', 'attrib': [{'pos': 'ppp', 'defs': 'perish, destroy'}, {'pos': 'ppp', 'defs': 'possess, rule'}, {'pos': 'ppp', 'defs': 'destroy'}], 'isLemma': 0, 'parents': ['kzi']},
	{'value': 'kzitavat', 'label': 'kzitavat', 'attrib': [{'pos': 'pap', 'defs': 'perish, destroy'}, {'pos': 'pap', 'defs': 'possess, rule'}, {'pos': 'pap', 'defs': 'destroy'}], 'isLemma': 0, 'parents': ['kzi']},
	{'value': 'kzitvA', 'label': 'kzitvA', 'attrib': [{'pos': 'abs', 'defs': 'perish, destroy'}, {'pos': 'abs', 'defs': 'possess, rule'}, {'pos': 'abs', 'defs': 'destroy'}], 'isLemma': 0, 'parents': ['kzi']},
	{'value': 'kzip', 'label': 'kzip', 'attrib': [{'pos': 'vt4a, vt6am', 'defs': 'throw'}], 'isLemma': 1},
	{'value': 'kzipat', 'label': 'kzipat', 'attrib': [{'pos': 'prap', 'defs': 'throw'}], 'isLemma': 0, 'parents': ['kzip']},
	{'value': 'kzipta', 'label': 'kzipta', 'attrib': [{'pos': 'ppp', 'defs': 'throw'}], 'isLemma': 0, 'parents': ['kzip']},
	{'value': 'kziptavat', 'label': 'kziptavat', 'attrib': [{'pos': 'pap', 'defs': 'throw'}], 'isLemma': 0, 'parents': ['kzip']},
	{'value': 'kziptum', 'label': 'kziptum', 'attrib': [{'pos': 'inf', 'defs': 'throw'}], 'isLemma': 0, 'parents': ['kzip']},
	{'value': 'kziptvA', 'label': 'kziptvA', 'attrib': [{'pos': 'abs', 'defs': 'throw'}], 'isLemma': 0, 'parents': ['kzip']},
	{'value': 'kzipyat', 'label': 'kzipyat', 'attrib': [{'pos': 'prap', 'defs': 'throw'}], 'isLemma': 0, 'parents': ['kzip']},
	{'value': 'kzipyamARa', 'label': 'kzipyamARa', 'attrib': [{'pos': 'prmp, prpp', 'defs': 'throw'}], 'isLemma': 0, 'parents': ['kzip']},
	{'value': 'kzipsyat', 'label': 'kzipsyat', 'attrib': [{'pos': 'fap', 'defs': 'throw'}], 'isLemma': 0, 'parents': ['kzip']},
	{'value': 'kzipsyamARa', 'label': 'kzipsyamARa', 'attrib': [{'pos': 'fpp', 'defs': 'throw'}], 'isLemma': 0, 'parents': ['kzip']},
	{'value': 'kziyat', 'label': 'kziyat', 'attrib': [{'pos': 'prap', 'defs': 'perish, destroy'}, {'pos': 'prap', 'defs': 'possess, rule'}], 'isLemma': 0, 'parents': ['kzi']},
	{'value': 'kzIRa', 'label': 'kzIRa', 'attrib': [{'pos': 'ppp', 'defs': 'perish, destroy'}, {'pos': 'ppp', 'defs': 'possess, rule'}, {'pos': 'ppp', 'defs': 'destroy'}], 'isLemma': 0, 'parents': ['kzi']},
	{'value': 'kzIRavat', 'label': 'kzIRavat', 'attrib': [{'pos': 'pap', 'defs': 'perish, destroy'}, {'pos': 'pap', 'defs': 'possess, rule'}, {'pos': 'pap', 'defs': 'destroy'}], 'isLemma': 0, 'parents': ['kzi']},
	{'value': 'kzIyamARa', 'label': 'kzIyamARa', 'attrib': [{'pos': 'prpp', 'defs': 'perish, destroy'}, {'pos': 'prpp', 'defs': 'possess, rule'}, {'pos': 'prpp', 'defs': 'destroy'}], 'isLemma': 0, 'parents': ['kzi']},
	{'value': 'kzetavya', 'label': 'kzetavya', 'attrib': [{'pos': 'ger', 'defs': 'perish, destroy'}, {'pos': 'ger', 'defs': 'possess, rule'}, {'pos': 'ger', 'defs': 'destroy'}], 'isLemma': 0, 'parents': ['kzi']},
	{'value': 'kzetum', 'label': 'kzetum', 'attrib': [{'pos': 'inf', 'defs': 'perish, destroy'}, {'pos': 'inf', 'defs': 'possess, rule'}, {'pos': 'inf', 'defs': 'destroy'}], 'isLemma': 0, 'parents': ['kzi']},
	{'value': 'kzepaRIya', 'label': 'kzepaRIya', 'attrib': [{'pos': 'ger', 'defs': 'throw'}], 'isLemma': 0, 'parents': ['kzip']},
	{'value': 'kzeptavya', 'label': 'kzeptavya', 'attrib': [{'pos': 'ger', 'defs': 'throw'}], 'isLemma': 0, 'parents': ['kzip']},
	{'value': 'kzepya', 'label': 'kzepya', 'attrib': [{'pos': 'ger', 'defs': 'throw'}], 'isLemma': 0, 'parents': ['kzip']},
	{'value': 'kzepsyat', 'label': 'kzepsyat', 'attrib': [{'pos': 'fap', 'defs': 'throw'}], 'isLemma': 0, 'parents': ['kzip']},
	{'value': 'kzepsyamARa', 'label': 'kzepsyamARa', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'throw'}], 'isLemma': 0, 'parents': ['kzip']},
	{'value': 'kzeya', 'label': 'kzeya', 'attrib': [{'pos': 'ger', 'defs': 'perish, destroy'}, {'pos': 'ger', 'defs': 'possess, rule'}, {'pos': 'ger', 'defs': 'destroy'}], 'isLemma': 0, 'parents': ['kzi']},
	{'value': 'kzezyat', 'label': 'kzezyat', 'attrib': [{'pos': 'fap', 'defs': 'perish, destroy'}, {'pos': 'fap', 'defs': 'possess, rule'}, {'pos': 'fap', 'defs': 'destroy'}], 'isLemma': 0, 'parents': ['kzi']},
	{'value': 'kzezyamARa', 'label': 'kzezyamARa', 'attrib': [{'pos': 'fpp', 'defs': 'perish, destroy'}, {'pos': 'fpp', 'defs': 'possess, rule'}, {'pos': 'fpp', 'defs': 'destroy'}], 'isLemma': 0, 'parents': ['kzi']},
	{'value': 'Kan', 'label': 'Kan', 'attrib': [{'pos': 'vt1am', 'defs': 'dig'}], 'isLemma': 1},
	{'value': 'Kanitra', 'label': 'Kanitra', 'attrib': [{'pos': 'n', 'defs': 'instrument for digging, shovel'}], 'isLemma': 1},
	{'value': 'Kanitrin', 'label': 'Kanitrin', 'attrib': [{'pos': 'm', 'defs': 'an excavator'}], 'isLemma': 1},
	{'value': 'KanitrI', 'label': 'KanitrI', 'attrib': [{'pos': 'f', 'defs': 'shovel'}], 'isLemma': 1},
	{'value': 'KARqava', 'label': 'KARqava', 'attrib': [{'pos': 'm_pn, n_pn', 'defs': 'Khāṇḍava'}], 'isLemma': 1},
	{'value': 'KARqavaprasTa', 'label': 'KARqavaprasTa', 'attrib': [{'pos': 'm', 'defs': 'the Khāṇḍava plain'}], 'isLemma': 1},
	{'value': 'gaNgA', 'label': 'gaNgA', 'attrib': [{'pos': 'f_pn', 'defs': 'the river Ganges'}], 'isLemma': 1},
	{'value': 'gacCat', 'label': 'gacCat', 'attrib': [{'pos': 'prap', 'defs': 'go'}], 'isLemma': 0, 'parents': ['gam']},
	{'value': 'gaRa', 'label': 'gaRa', 'attrib': [{'pos': 'm', 'defs': 'group, multitude'}], 'isLemma': 1},
	{'value': 'gata', 'label': 'gata', 'attrib': [{'pos': 'ppp', 'defs': 'go'}], 'isLemma': 0, 'parents': ['gam']},
	{'value': 'gatamanyu', 'label': 'gatamanyu', 'attrib': [{'pos': 'adj', 'defs': 'free of anger'}], 'isLemma': 1},
	{'value': 'gatavat', 'label': 'gatavat', 'attrib': [{'pos': 'pap', 'defs': 'go'}], 'isLemma': 0, 'parents': ['gam']},
	{'value': 'gatavyaTa', 'label': 'gatavyaTa', 'attrib': [{'pos': 'adj', 'defs': 'fearless, free of trembling'}], 'isLemma': 1},
	{'value': 'gatvA', 'label': 'gatvA', 'attrib': [{'pos': 'abs', 'defs': 'go'}], 'isLemma': 0, 'parents': ['gam']},
	{'value': 'gantavya', 'label': 'gantavya', 'attrib': [{'pos': 'ger', 'defs': 'go'}], 'isLemma': 0, 'parents': ['gam']},
	{'value': 'gantum', 'label': 'gantum', 'attrib': [{'pos': 'inf', 'defs': 'go'}], 'isLemma': 0, 'parents': ['gam']},
	{'value': 'gam', 'label': 'gam', 'attrib': [{'pos': 'vt1a', 'defs': 'go'}], 'isLemma': 1},
	{'value': 'gamanIya', 'label': 'gamanIya', 'attrib': [{'pos': 'ger', 'defs': 'go'}], 'isLemma': 0, 'parents': ['gam']},
	{'value': 'gamizyat', 'label': 'gamizyat', 'attrib': [{'pos': 'fap', 'defs': 'go'}], 'isLemma': 0, 'parents': ['gam']},
	{'value': 'gamizyamARa', 'label': 'gamizyamARa', 'attrib': [{'pos': 'fpp', 'defs': 'go'}], 'isLemma': 0, 'parents': ['gam']},
	{'value': 'gamya', 'label': 'gamya', 'attrib': [{'pos': 'ger', 'defs': 'go'}], 'isLemma': 0, 'parents': ['gam']},
	{'value': 'gamyamAna', 'label': 'gamyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'go'}], 'isLemma': 0, 'parents': ['gam']},
	{'value': 'gARqI', 'label': 'gARqI', 'attrib': [{'pos': 'f', 'defs': 'rhinoceros'}], 'isLemma': 1},
	{'value': 'gARqIva', 'label': 'gARqIva', 'attrib': [{'pos': 'm_pn, n_pn', 'defs': 'Gāṇḍīva'}], 'isLemma': 1},
	{'value': 'guru', 'label': 'guru', 'attrib': [{'pos': 'adj', 'defs': 'heavy'}, {'pos': 'm', 'defs': 'teacher, elder'}], 'isLemma': 1},
	{'value': 'gfha', 'label': 'gfha', 'attrib': [{'pos': 'n', 'defs': 'room, house'}], 'isLemma': 1},
	{'value': 'grah', 'label': 'grah', 'attrib': [{'pos': 'vt9a', 'defs': 'grab'}], 'isLemma': 1},
	{'value': 'GAtya', 'label': 'GAtya', 'attrib': [{'pos': 'ger', 'defs': 'smite'}], 'isLemma': 0, 'parents': ['han']},
	{'value': 'Gora', 'label': 'Gora', 'attrib': [{'pos': 'adj', 'defs': 'awful'}], 'isLemma': 1},
	{'value': 'Gnat', 'label': 'Gnat', 'attrib': [{'pos': 'prap', 'defs': 'smite'}], 'isLemma': 0, 'parents': ['han']},
	{'value': 'ca', 'label': 'ca', 'attrib': [{'pos': 'conj_pcl', 'defs': 'and'}], 'isLemma': 1},
	{'value': 'cakfvas', 'label': 'cakfvas', 'attrib': [{'pos': 'pfap', 'defs': 'do, make'}], 'isLemma': 0, 'parents': ['kf']},
	{'value': 'cakra', 'label': 'cakra', 'attrib': [{'pos': 'n', 'defs': 'wheel, discus, circle'}], 'isLemma': 1},
	{'value': 'cakrARa', 'label': 'cakrARa', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'do, make'}], 'isLemma': 0, 'parents': ['kf']},
	{'value': 'catur', 'label': 'catur', 'attrib': [{'pos': 'card_num', 'defs': 'four'}], 'isLemma': 1},
	{'value': 'caturdaSa', 'label': 'caturdaSa', 'attrib': [{'pos': 'ord_num', 'defs': 'fourteenth'}], 'isLemma': 1},
	{'value': 'cana', 'label': 'cana', 'attrib': [{'pos': 'pcl', 'defs': '/'}], 'isLemma': 1},
	{'value': 'cayanIya', 'label': 'cayanIya', 'attrib': [{'pos': 'ger', 'defs': 'pile up'}], 'isLemma': 0, 'parents': ['ci']},
	{'value': 'car', 'label': 'car', 'attrib': [{'pos': 'vt1am', 'defs': 'move, do'}], 'isLemma': 1},
	{'value': 'caraRIya', 'label': 'caraRIya', 'attrib': [{'pos': 'ger', 'defs': 'move, do'}], 'isLemma': 0, 'parents': ['car']},
	{'value': 'carat', 'label': 'carat', 'attrib': [{'pos': 'prap', 'defs': 'move, do'}], 'isLemma': 0, 'parents': ['car']},
	{'value': 'caramARa', 'label': 'caramARa', 'attrib': [{'pos': 'prmp', 'defs': 'move, do'}], 'isLemma': 0, 'parents': ['car']},
	{'value': 'carita', 'label': 'carita', 'attrib': [{'pos': 'ppp', 'defs': 'move, do'}], 'isLemma': 0, 'parents': ['car']},
	{'value': 'caritavat', 'label': 'caritavat', 'attrib': [{'pos': 'pap', 'defs': 'move, do'}], 'isLemma': 0, 'parents': ['car']},
	{'value': 'caritavya', 'label': 'caritavya', 'attrib': [{'pos': 'ger', 'defs': 'move, do'}], 'isLemma': 0, 'parents': ['car']},
	{'value': 'caritum', 'label': 'caritum', 'attrib': [{'pos': 'inf', 'defs': 'move, do'}], 'isLemma': 0, 'parents': ['car']},
	{'value': 'caritvA', 'label': 'caritvA', 'attrib': [{'pos': 'abs', 'defs': 'move, do'}], 'isLemma': 0, 'parents': ['car']},
	{'value': 'carizyat', 'label': 'carizyat', 'attrib': [{'pos': 'fap', 'defs': 'move, do'}], 'isLemma': 0, 'parents': ['car']},
	{'value': 'carizyamARa', 'label': 'carizyamARa', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'move, do'}], 'isLemma': 0, 'parents': ['car']},
	{'value': 'carya', 'label': 'carya', 'attrib': [{'pos': 'ger', 'defs': 'move, do'}], 'isLemma': 0, 'parents': ['car']},
	{'value': 'caryamARa', 'label': 'caryamARa', 'attrib': [{'pos': 'prpp', 'defs': 'move, do'}], 'isLemma': 0, 'parents': ['car']},
	{'value': 'ci', 'label': 'ci', 'attrib': [{'pos': 'vt5am', 'defs': 'pile up'}], 'isLemma': 1},
	{'value': 'cikivas', 'label': 'cikivas', 'attrib': [{'pos': 'pfap', 'defs': 'pile up'}], 'isLemma': 0, 'parents': ['ci']},
	{'value': 'cikliSAna', 'label': 'cikliSAna', 'attrib': [{'pos': 'pfpp', 'defs': 'torment, afflict'}], 'isLemma': 0, 'parents': ['kliS']},
	{'value': 'cikliSivas', 'label': 'cikliSivas', 'attrib': [{'pos': 'pfap', 'defs': 'torment, afflict'}], 'isLemma': 0, 'parents': ['kliS']},
	{'value': 'cikzipARa', 'label': 'cikzipARa', 'attrib': [{'pos': 'pfpp', 'defs': 'throw'}], 'isLemma': 0, 'parents': ['kzip']},
	{'value': 'cikzipivas', 'label': 'cikzipivas', 'attrib': [{'pos': 'pfap', 'defs': 'throw'}], 'isLemma': 0, 'parents': ['kzip']},
	{'value': 'cikziyARa', 'label': 'cikziyARa', 'attrib': [{'pos': 'pfpp', 'defs': 'perish, destroy'}, {'pos': 'pfpp', 'defs': 'possess, rule'}, {'pos': 'pfpp', 'defs': 'destroy'}], 'isLemma': 0, 'parents': ['kzi']},
	{'value': 'cikzivas', 'label': 'cikzivas', 'attrib': [{'pos': 'pfap', 'defs': 'perish, destroy'}, {'pos': 'pfap', 'defs': 'possess, rule'}, {'pos': 'pfap', 'defs': 'destroy'}], 'isLemma': 0, 'parents': ['kzi']},
	{'value': 'cicivas', 'label': 'cicivas', 'attrib': [{'pos': 'pfap', 'defs': 'pile up'}], 'isLemma': 0, 'parents': ['ci']},
	{'value': 'cicCidAna', 'label': 'cicCidAna', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'split, cut'}], 'isLemma': 0, 'parents': ['Cid']},
	{'value': 'cicCidvas', 'label': 'cicCidvas', 'attrib': [{'pos': 'pfap', 'defs': 'split, cut'}], 'isLemma': 0, 'parents': ['Cid']},
	{'value': 'cit', 'label': 'cit', 'attrib': [{'pos': 'pcl', 'defs': '/'}], 'isLemma': 1},
	{'value': 'cita', 'label': 'cita', 'attrib': [{'pos': 'ppp', 'defs': 'pile up'}], 'isLemma': 0, 'parents': ['ci']},
	{'value': 'citavat', 'label': 'citavat', 'attrib': [{'pos': 'pap', 'defs': 'pile up'}], 'isLemma': 0, 'parents': ['ci']},
	{'value': 'citvA', 'label': 'citvA', 'attrib': [{'pos': 'abs', 'defs': 'pile up'}], 'isLemma': 0, 'parents': ['ci']},
	{'value': 'cint', 'label': 'cint', 'attrib': [{'pos': 'vt10am', 'defs': 'consider'}], 'isLemma': 1},
	{'value': 'cintanIya', 'label': 'cintanIya', 'attrib': [{'pos': 'ger', 'defs': 'consider'}], 'isLemma': 0, 'parents': ['cint']},
	{'value': 'cintayat', 'label': 'cintayat', 'attrib': [{'pos': 'prap', 'defs': 'consider'}], 'isLemma': 0, 'parents': ['cint']},
	{'value': 'cintayamAna', 'label': 'cintayamAna', 'attrib': [{'pos': 'prmp', 'defs': 'consider'}], 'isLemma': 0, 'parents': ['cint']},
	{'value': 'cintayAm', 'label': 'cintayAm', 'attrib': [{'pos': 'ppf_actn', 'defs': 'consider'}], 'isLemma': 0, 'parents': ['cint']},
	{'value': 'cintayizyat', 'label': 'cintayizyat', 'attrib': [{'pos': 'fap', 'defs': 'consider'}], 'isLemma': 0, 'parents': ['cint']},
	{'value': 'cintayizyamARa', 'label': 'cintayizyamARa', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'consider'}], 'isLemma': 0, 'parents': ['cint']},
	{'value': 'cintita', 'label': 'cintita', 'attrib': [{'pos': 'ppp', 'defs': 'consider'}], 'isLemma': 0, 'parents': ['cint']},
	{'value': 'cintitavat', 'label': 'cintitavat', 'attrib': [{'pos': 'pap', 'defs': 'consider'}], 'isLemma': 0, 'parents': ['cint']},
	{'value': 'cintitavya', 'label': 'cintitavya', 'attrib': [{'pos': 'ger', 'defs': 'consider'}], 'isLemma': 0, 'parents': ['cint']},
	{'value': 'cintitum', 'label': 'cintitum', 'attrib': [{'pos': 'inf', 'defs': 'consider'}], 'isLemma': 0, 'parents': ['cint']},
	{'value': 'cintitvA', 'label': 'cintitvA', 'attrib': [{'pos': 'abs', 'defs': 'consider'}], 'isLemma': 0, 'parents': ['cint']},
	{'value': 'cintya', 'label': 'cintya', 'attrib': [{'pos': 'ger', 'defs': 'consider'}], 'isLemma': 0, 'parents': ['cint']},
	{'value': 'cintyamAna', 'label': 'cintyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'consider'}], 'isLemma': 0, 'parents': ['cint']},
	{'value': 'cinvat', 'label': 'cinvat', 'attrib': [{'pos': 'prap', 'defs': 'pile up'}], 'isLemma': 0, 'parents': ['ci']},
	{'value': 'cinvAna', 'label': 'cinvAna', 'attrib': [{'pos': 'prmp', 'defs': 'pile up'}], 'isLemma': 0, 'parents': ['ci']},
	{'value': 'ciyAna', 'label': 'ciyAna', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'pile up'}], 'isLemma': 0, 'parents': ['ci']},
	{'value': 'cira', 'label': 'cira', 'attrib': [{'pos': 'adj', 'defs': 'long'}, {'pos': 'n', 'defs': 'delay'}], 'isLemma': 1},
	{'value': 'cIyamARa', 'label': 'cIyamARa', 'attrib': [{'pos': 'prpp', 'defs': 'pile up'}], 'isLemma': 0, 'parents': ['ci']},
	{'value': 'cukruDAna', 'label': 'cukruDAna', 'attrib': [{'pos': 'pfpp', 'defs': 'be angry'}], 'isLemma': 0, 'parents': ['kruD']},
	{'value': 'cukruDvas', 'label': 'cukruDvas', 'attrib': [{'pos': 'pfap', 'defs': 'be angry'}], 'isLemma': 0, 'parents': ['kruD']},
	{'value': 'cetavya', 'label': 'cetavya', 'attrib': [{'pos': 'ger', 'defs': 'pile up'}], 'isLemma': 0, 'parents': ['ci']},
	{'value': 'cetum', 'label': 'cetum', 'attrib': [{'pos': 'inf', 'defs': 'pile up'}], 'isLemma': 0, 'parents': ['ci']},
	{'value': 'ceya', 'label': 'ceya', 'attrib': [{'pos': 'ger', 'defs': 'pile up'}], 'isLemma': 0, 'parents': ['ci']},
	{'value': 'cerARa', 'label': 'cerARa', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'move, do'}], 'isLemma': 0, 'parents': ['car']},
	{'value': 'cerivas', 'label': 'cerivas', 'attrib': [{'pos': 'pfap', 'defs': 'move, do'}], 'isLemma': 0, 'parents': ['car']},
	{'value': 'cezyat', 'label': 'cezyat', 'attrib': [{'pos': 'fap', 'defs': 'pile up'}], 'isLemma': 0, 'parents': ['ci']},
	{'value': 'cezyamARa', 'label': 'cezyamARa', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'pile up'}], 'isLemma': 0, 'parents': ['ci']},
	{'value': 'CittvA', 'label': 'CittvA', 'attrib': [{'pos': 'abs', 'defs': 'split, cut'}], 'isLemma': 0, 'parents': ['Cid']},
	{'value': 'Cid', 'label': 'Cid', 'attrib': [{'pos': 'vt7am', 'defs': 'split, cut'}], 'isLemma': 1},
	{'value': 'CidyamAna', 'label': 'CidyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'split, cut'}], 'isLemma': 0, 'parents': ['Cid']},
	{'value': 'Cindat', 'label': 'Cindat', 'attrib': [{'pos': 'prap', 'defs': 'split, cut'}], 'isLemma': 0, 'parents': ['Cid']},
	{'value': 'CindAna', 'label': 'CindAna', 'attrib': [{'pos': 'prmp', 'defs': 'split, cut'}], 'isLemma': 0, 'parents': ['Cid']},
	{'value': 'Cinna', 'label': 'Cinna', 'attrib': [{'pos': 'ppp', 'defs': 'split, cut'}], 'isLemma': 0, 'parents': ['Cid']},
	{'value': 'Cinnavat', 'label': 'Cinnavat', 'attrib': [{'pos': 'pap', 'defs': 'split, cut'}], 'isLemma': 0, 'parents': ['Cid']},
	{'value': 'Cettavya', 'label': 'Cettavya', 'attrib': [{'pos': 'ger', 'defs': 'split, cut'}], 'isLemma': 0, 'parents': ['Cid']},
	{'value': 'Cettum', 'label': 'Cettum', 'attrib': [{'pos': 'inf', 'defs': 'split, cut'}], 'isLemma': 0, 'parents': ['Cid']},
	{'value': 'Cetsyat', 'label': 'Cetsyat', 'attrib': [{'pos': 'fap', 'defs': 'split, cut'}], 'isLemma': 0, 'parents': ['Cid']},
	{'value': 'CetsyamAna', 'label': 'CetsyamAna', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'split, cut'}], 'isLemma': 0, 'parents': ['Cid']},
	{'value': 'CedanIya', 'label': 'CedanIya', 'attrib': [{'pos': 'ger', 'defs': 'split, cut'}], 'isLemma': 0, 'parents': ['Cid']},
	{'value': 'Cedya', 'label': 'Cedya', 'attrib': [{'pos': 'ger', 'defs': 'split, cut'}], 'isLemma': 0, 'parents': ['Cid']},
	{'value': 'ja', 'label': 'ja', 'attrib': [{'pos': 'adj', 'defs': 'born'}], 'isLemma': 1},
	{'value': 'jaganvas', 'label': 'jaganvas', 'attrib': [{'pos': 'pfap', 'defs': 'go'}], 'isLemma': 0, 'parents': ['gam']},
	{'value': 'jagmAna', 'label': 'jagmAna', 'attrib': [{'pos': 'pfpp', 'defs': 'go'}], 'isLemma': 0, 'parents': ['gam']},
	{'value': 'jagmivas', 'label': 'jagmivas', 'attrib': [{'pos': 'pfap', 'defs': 'go'}], 'isLemma': 0, 'parents': ['gam']},
	{'value': 'jaGanvas', 'label': 'jaGanvas', 'attrib': [{'pos': 'pfap', 'defs': 'smite'}], 'isLemma': 0, 'parents': ['han']},
	{'value': 'jaGnAna', 'label': 'jaGnAna', 'attrib': [{'pos': 'pfpp', 'defs': 'smite'}], 'isLemma': 0, 'parents': ['han']},
	{'value': 'jaGnivas', 'label': 'jaGnivas', 'attrib': [{'pos': 'pfap', 'defs': 'smite'}], 'isLemma': 0, 'parents': ['han']},
	{'value': 'jajarARa', 'label': 'jajarARa', 'attrib': [{'pos': 'pfpp', 'defs': 'wither, age, grow old; caus.: digest'}], 'isLemma': 0, 'parents': ['jF']},
	{'value': 'jajarvas', 'label': 'jajarvas', 'attrib': [{'pos': 'pfap', 'defs': 'wither, age, grow old; caus.: digest'}], 'isLemma': 0, 'parents': ['jF']},
	{'value': 'jajYAna', 'label': 'jajYAna', 'attrib': [{'pos': 'pfpp', 'defs': 'know'}], 'isLemma': 0, 'parents': ['jYA']},
	{'value': 'jajYivas', 'label': 'jajYivas', 'attrib': [{'pos': 'pfap', 'defs': 'know'}], 'isLemma': 0, 'parents': ['jYA']},
	{'value': 'jatu', 'label': 'jatu', 'attrib': [{'pos': 'n', 'defs': 'lac'}], 'isLemma': 1},
	{'value': 'jan', 'label': 'jan', 'attrib': [{'pos': 'vi4m', 'defs': 'be born'}], 'isLemma': 1},
	{'value': 'jana', 'label': 'jana', 'attrib': [{'pos': 'm', 'defs': 'people'}], 'isLemma': 1},
	{'value': 'janapada', 'label': 'janapada', 'attrib': [{'pos': 'n', 'defs': 'district'}], 'isLemma': 1},
	{'value': 'janapadopeta', 'label': 'janapadopeta', 'attrib': [{'pos': 'adj', 'defs': 'endowed with a district'}], 'isLemma': 1},
	{'value': 'janamejaya', 'label': 'janamejaya', 'attrib': [{'pos': 'm_pn', 'defs': 'Janamejaya'}], 'isLemma': 1},
	{'value': 'jaya', 'label': 'jaya', 'attrib': [{'pos': 'm', 'defs': 'victory'}], 'isLemma': 1},
	{'value': 'jayat', 'label': 'jayat', 'attrib': [{'pos': 'prap', 'defs': 'conquer'}], 'isLemma': 0, 'parents': ['ji']},
	{'value': 'jayanIya', 'label': 'jayanIya', 'attrib': [{'pos': 'ger', 'defs': 'conquer'}], 'isLemma': 0, 'parents': ['ji']},
	{'value': 'jaraRIya', 'label': 'jaraRIya', 'attrib': [{'pos': 'ger', 'defs': 'wither, age, grow old; caus.: digest'}], 'isLemma': 0, 'parents': ['jF']},
	{'value': 'jarayAm', 'label': 'jarayAm', 'attrib': [{'pos': 'ppf_actn', 'defs': 'wither, age, grow old; caus.: digest'}], 'isLemma': 0, 'parents': ['jF']},
	{'value': 'jaritavya', 'label': 'jaritavya', 'attrib': [{'pos': 'ger', 'defs': 'wither, age, grow old; caus.: digest'}], 'isLemma': 0, 'parents': ['jF']},
	{'value': 'jaritum', 'label': 'jaritum', 'attrib': [{'pos': 'inf', 'defs': 'wither, age, grow old; caus.: digest'}], 'isLemma': 0, 'parents': ['jF']},
	{'value': 'jaritvA', 'label': 'jaritvA', 'attrib': [{'pos': 'abs', 'defs': 'wither, age, grow old; caus.: digest'}], 'isLemma': 0, 'parents': ['jF']},
	{'value': 'jarizyat', 'label': 'jarizyat', 'attrib': [{'pos': 'fap', 'defs': 'wither, age, grow old; caus.: digest'}], 'isLemma': 0, 'parents': ['jF']},
	{'value': 'jarizyamARa', 'label': 'jarizyamARa', 'attrib': [{'pos': 'fpp', 'defs': 'wither, age, grow old; caus.: digest'}], 'isLemma': 0, 'parents': ['jF']},
	{'value': 'jarItavya', 'label': 'jarItavya', 'attrib': [{'pos': 'ger', 'defs': 'wither, age, grow old; caus.: digest'}], 'isLemma': 0, 'parents': ['jF']},
	{'value': 'jarItum', 'label': 'jarItum', 'attrib': [{'pos': 'inf', 'defs': 'wither, age, grow old; caus.: digest'}], 'isLemma': 0, 'parents': ['jF']},
	{'value': 'jarItvA', 'label': 'jarItvA', 'attrib': [{'pos': 'abs', 'defs': 'wither, age, grow old; caus.: digest'}], 'isLemma': 0, 'parents': ['jF']},
	{'value': 'jarya', 'label': 'jarya', 'attrib': [{'pos': 'ger', 'defs': 'wither, age, grow old; caus.: digest'}], 'isLemma': 0, 'parents': ['jF']},
	{'value': 'jAtuza', 'label': 'jAtuza', 'attrib': [{'pos': 'adj', 'defs': 'made of lac'}], 'isLemma': 1},
	{'value': 'jAnat', 'label': 'jAnat', 'attrib': [{'pos': 'prap', 'defs': 'know'}], 'isLemma': 0, 'parents': ['jYA']},
	{'value': 'jArya', 'label': 'jArya', 'attrib': [{'pos': 'ger', 'defs': 'wither, age, grow old; caus.: digest'}], 'isLemma': 0, 'parents': ['jF']},
	{'value': 'ji', 'label': 'ji', 'attrib': [{'pos': 'vt1a', 'defs': 'conquer'}], 'isLemma': 1},
	{'value': 'jigivas', 'label': 'jigivas', 'attrib': [{'pos': 'pfap', 'defs': 'conquer'}], 'isLemma': 0, 'parents': ['ji']},
	{'value': 'jigyAna', 'label': 'jigyAna', 'attrib': [{'pos': 'pfpp', 'defs': 'conquer'}], 'isLemma': 0, 'parents': ['ji']},
	{'value': 'jijIvas', 'label': 'jijIvas', 'attrib': [{'pos': 'pfap', 'defs': 'live'}], 'isLemma': 0, 'parents': ['jIv']},
	{'value': 'jijIvAna', 'label': 'jijIvAna', 'attrib': [{'pos': 'pfpp', 'defs': 'live'}], 'isLemma': 0, 'parents': ['jIv']},
	{'value': 'jita', 'label': 'jita', 'attrib': [{'pos': 'ppp', 'defs': 'conquer'}], 'isLemma': 0, 'parents': ['ji']},
	{'value': 'jitavat', 'label': 'jitavat', 'attrib': [{'pos': 'pap', 'defs': 'conquer'}], 'isLemma': 0, 'parents': ['ji']},
	{'value': 'jitvA', 'label': 'jitvA', 'attrib': [{'pos': 'abs', 'defs': 'conquer'}], 'isLemma': 0, 'parents': ['ji']},
	{'value': 'jIyamAna', 'label': 'jIyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'conquer'}], 'isLemma': 0, 'parents': ['ji']},
	{'value': 'jIrRa', 'label': 'jIrRa', 'attrib': [{'pos': 'ppp', 'defs': 'wither, age, grow old; caus.: digest'}], 'isLemma': 0, 'parents': ['jF']},
	{'value': 'jIrRavat', 'label': 'jIrRavat', 'attrib': [{'pos': 'pap', 'defs': 'wither, age, grow old; caus.: digest'}], 'isLemma': 0, 'parents': ['jF']},
	{'value': 'jIryat', 'label': 'jIryat', 'attrib': [{'pos': 'prap', 'defs': 'wither, age, grow old; caus.: digest'}], 'isLemma': 0, 'parents': ['jF']},
	{'value': 'jIryamARa', 'label': 'jIryamARa', 'attrib': [{'pos': 'prpp', 'defs': 'wither, age, grow old; caus.: digest'}], 'isLemma': 0, 'parents': ['jF']},
	{'value': 'jIv', 'label': 'jIv', 'attrib': [{'pos': 'vi1a', 'defs': 'live'}], 'isLemma': 1},
	{'value': 'jIva', 'label': 'jIva', 'attrib': [{'pos': 'm', 'defs': 'a living being, individual soul'}], 'isLemma': 1},
	{'value': 'jIvat', 'label': 'jIvat', 'attrib': [{'pos': 'prap', 'defs': 'live'}], 'isLemma': 0, 'parents': ['jIv']},
	{'value': 'jIvanIya', 'label': 'jIvanIya', 'attrib': [{'pos': 'ger', 'defs': 'live'}], 'isLemma': 0, 'parents': ['jIv']},
	{'value': 'jIvaloka', 'label': 'jIvaloka', 'attrib': [{'pos': 'm', 'defs': 'the world of living beings'}], 'isLemma': 1},
	{'value': 'jIvita', 'label': 'jIvita', 'attrib': [{'pos': 'ppp', 'defs': 'live'}], 'isLemma': 0, 'parents': ['jIv']},
	{'value': 'jIvitavat', 'label': 'jIvitavat', 'attrib': [{'pos': 'pap', 'defs': 'live'}], 'isLemma': 0, 'parents': ['jIv']},
	{'value': 'jIvitavya', 'label': 'jIvitavya', 'attrib': [{'pos': 'ger', 'defs': 'live'}], 'isLemma': 0, 'parents': ['jIv']},
	{'value': 'jIvitum', 'label': 'jIvitum', 'attrib': [{'pos': 'inf', 'defs': 'live'}], 'isLemma': 0, 'parents': ['jIv']},
	{'value': 'jIvitvA', 'label': 'jIvitvA', 'attrib': [{'pos': 'abs', 'defs': 'live'}], 'isLemma': 0, 'parents': ['jIv']},
	{'value': 'jIvizyat', 'label': 'jIvizyat', 'attrib': [{'pos': 'fap', 'defs': 'live'}], 'isLemma': 0, 'parents': ['jIv']},
	{'value': 'jIvizyamARa', 'label': 'jIvizyamARa', 'attrib': [{'pos': 'fpp', 'defs': 'live'}], 'isLemma': 0, 'parents': ['jIv']},
	{'value': 'jIvya', 'label': 'jIvya', 'attrib': [{'pos': 'ger', 'defs': 'live'}], 'isLemma': 0, 'parents': ['jIv']},
	{'value': 'jIvyamAna', 'label': 'jIvyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'live'}], 'isLemma': 0, 'parents': ['jIv']},
	{'value': 'juhavAm', 'label': 'juhavAm', 'attrib': [{'pos': 'ppf_actn', 'defs': 'offer, worship'}], 'isLemma': 0, 'parents': ['hu']},
	{'value': 'juhvat', 'label': 'juhvat', 'attrib': [{'pos': 'prap', 'defs': 'offer, worship'}], 'isLemma': 0, 'parents': ['hu']},
	{'value': 'jF', 'label': 'jF', 'attrib': [{'pos': 'vi4a', 'defs': 'wither, age, grow old; caus.: digest'}], 'isLemma': 1},
	{'value': 'jetavya', 'label': 'jetavya', 'attrib': [{'pos': 'ger', 'defs': 'conquer'}], 'isLemma': 0, 'parents': ['ji']},
	{'value': 'jetum', 'label': 'jetum', 'attrib': [{'pos': 'inf', 'defs': 'conquer'}], 'isLemma': 0, 'parents': ['ji']},
	{'value': 'jeya', 'label': 'jeya', 'attrib': [{'pos': 'ger', 'defs': 'conquer'}], 'isLemma': 0, 'parents': ['ji']},
	{'value': 'jerARa', 'label': 'jerARa', 'attrib': [{'pos': 'pfpp', 'defs': 'wither, age, grow old; caus.: digest'}], 'isLemma': 0, 'parents': ['jF']},
	{'value': 'jerivas', 'label': 'jerivas', 'attrib': [{'pos': 'pfap', 'defs': 'wither, age, grow old; caus.: digest'}], 'isLemma': 0, 'parents': ['jF']},
	{'value': 'jezyat', 'label': 'jezyat', 'attrib': [{'pos': 'fap', 'defs': 'conquer'}], 'isLemma': 0, 'parents': ['ji']},
	{'value': 'jezyamARa', 'label': 'jezyamARa', 'attrib': [{'pos': 'fpp', 'defs': 'conquer'}], 'isLemma': 0, 'parents': ['ji']},
	{'value': 'jYA', 'label': 'jYA', 'attrib': [{'pos': 'vt9am', 'defs': 'know'}], 'isLemma': 1},
	{'value': 'jYAta', 'label': 'jYAta', 'attrib': [{'pos': 'ppp', 'defs': 'know'}], 'isLemma': 0, 'parents': ['jYA']},
	{'value': 'jYAtavat', 'label': 'jYAtavat', 'attrib': [{'pos': 'pap', 'defs': 'know'}], 'isLemma': 0, 'parents': ['jYA']},
	{'value': 'jYAtavya', 'label': 'jYAtavya', 'attrib': [{'pos': 'ger', 'defs': 'know'}], 'isLemma': 0, 'parents': ['jYA']},
	{'value': 'jYAtum', 'label': 'jYAtum', 'attrib': [{'pos': 'inf', 'defs': 'know'}], 'isLemma': 0, 'parents': ['jYA']},
	{'value': 'jYAtvA', 'label': 'jYAtvA', 'attrib': [{'pos': 'abs', 'defs': 'know'}], 'isLemma': 0, 'parents': ['jYA']},
	{'value': 'jYAnIya', 'label': 'jYAnIya', 'attrib': [{'pos': 'ger', 'defs': 'know'}], 'isLemma': 0, 'parents': ['jYA']},
	{'value': 'jYApya', 'label': 'jYApya', 'attrib': [{'pos': 'abs', 'defs': 'know'}], 'isLemma': 0, 'parents': ['jYA']},
	{'value': 'jYAyamAna', 'label': 'jYAyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'know'}], 'isLemma': 0, 'parents': ['jYA']},
	{'value': 'jYAsyat', 'label': 'jYAsyat', 'attrib': [{'pos': 'fap', 'defs': 'know'}], 'isLemma': 0, 'parents': ['jYA']},
	{'value': 'jYAsyamAna', 'label': 'jYAsyamAna', 'attrib': [{'pos': 'fpp', 'defs': 'know'}], 'isLemma': 0, 'parents': ['jYA']},
	{'value': 'jYeya', 'label': 'jYeya', 'attrib': [{'pos': 'ger', 'defs': 'know'}], 'isLemma': 0, 'parents': ['jYA']},
	{'value': 'taMsyat', 'label': 'taMsyat', 'attrib': [{'pos': 'fap', 'defs': 'extend'}], 'isLemma': 0, 'parents': ['tan']},
	{'value': 'taMsyamAna', 'label': 'taMsyamAna', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'extend'}], 'isLemma': 0, 'parents': ['tan']},
	{'value': 'tata', 'label': 'tata', 'attrib': [{'pos': 'ppp', 'defs': 'extend'}], 'isLemma': 0, 'parents': ['tan']},
	{'value': 'tatavat', 'label': 'tatavat', 'attrib': [{'pos': 'pap', 'defs': 'extend'}], 'isLemma': 0, 'parents': ['tan']},
	{'value': 'tatas', 'label': 'tatas', 'attrib': [{'pos': 'dem_adv', 'defs': 'after that, therefore, then'}], 'isLemma': 1},
	{'value': 'tatfpivas', 'label': 'tatfpivas', 'attrib': [{'pos': 'pfap', 'defs': 'become satisfied, be pleased; caus. satiate, satisfy, please'}], 'isLemma': 0, 'parents': ['tfp']},
	{'value': 'tatra', 'label': 'tatra', 'attrib': [{'pos': 'dem_adv', 'defs': 'in that, there'}], 'isLemma': 1},
	{'value': 'taTA', 'label': 'taTA', 'attrib': [{'pos': 'dem_adv', 'defs': 'thus'}], 'isLemma': 1},
	{'value': 'tad', 'label': 'tad', 'attrib': [{'pos': 'dem_adj, dem_pron', 'defs': 'that'}], 'isLemma': 1},
	{'value': 'tadA', 'label': 'tadA', 'attrib': [{'pos': 'dem_adv', 'defs': 'at that time, then'}], 'isLemma': 1},
	{'value': 'tan', 'label': 'tan', 'attrib': [{'pos': 'vt8am', 'defs': 'extend'}], 'isLemma': 1},
	{'value': 'tananIya', 'label': 'tananIya', 'attrib': [{'pos': 'ger', 'defs': 'extend'}], 'isLemma': 0, 'parents': ['tan']},
	{'value': 'tanitavya', 'label': 'tanitavya', 'attrib': [{'pos': 'ger', 'defs': 'extend'}], 'isLemma': 0, 'parents': ['tan']},
	{'value': 'tanitum', 'label': 'tanitum', 'attrib': [{'pos': 'inf', 'defs': 'extend'}], 'isLemma': 0, 'parents': ['tan']},
	{'value': 'tanitvA', 'label': 'tanitvA', 'attrib': [{'pos': 'abs', 'defs': 'extend'}], 'isLemma': 0, 'parents': ['tan']},
	{'value': 'tanizyat', 'label': 'tanizyat', 'attrib': [{'pos': 'fap', 'defs': 'extend'}], 'isLemma': 0, 'parents': ['tan']},
	{'value': 'tanizyamARa', 'label': 'tanizyamARa', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'extend'}], 'isLemma': 0, 'parents': ['tan']},
	{'value': 'tanu', 'label': 'tanu', 'attrib': [{'pos': 'adj', 'defs': 'thin'}, {'pos': 'f', 'defs': 'body, self'}], 'isLemma': 1},
	{'value': 'tanyamAna', 'label': 'tanyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'extend'}], 'isLemma': 0, 'parents': ['tan']},
	{'value': 'tanvat', 'label': 'tanvat', 'attrib': [{'pos': 'prap', 'defs': 'extend'}], 'isLemma': 0, 'parents': ['tan']},
	{'value': 'tanvAna', 'label': 'tanvAna', 'attrib': [{'pos': 'prmp', 'defs': 'extend'}], 'isLemma': 0, 'parents': ['tan']},
	{'value': 'tap', 'label': 'tap', 'attrib': [{'pos': 'vi4m', 'defs': 'burn, feel pain'}, {'pos': 'vt1a', 'defs': 'heat, scorch'}], 'isLemma': 1},
	{'value': 'tapa', 'label': 'tapa', 'attrib': [{'pos': 'adj', 'defs': 'scorching'}], 'isLemma': 1},
	{'value': 'tapat', 'label': 'tapat', 'attrib': [{'pos': 'prap', 'defs': 'heat, scorch'}], 'isLemma': 0, 'parents': ['tap']},
	{'value': 'tapanIya', 'label': 'tapanIya', 'attrib': [{'pos': 'ger', 'defs': 'heat, scorch'}], 'isLemma': 0, 'parents': ['tap']},
	{'value': 'tapta', 'label': 'tapta', 'attrib': [{'pos': 'ppp', 'defs': 'heat, scorch'}], 'isLemma': 0, 'parents': ['tap']},
	{'value': 'taptavat', 'label': 'taptavat', 'attrib': [{'pos': 'pap', 'defs': 'heat, scorch'}], 'isLemma': 0, 'parents': ['tap']},
	{'value': 'taptavya', 'label': 'taptavya', 'attrib': [{'pos': 'ger', 'defs': 'heat, scorch'}], 'isLemma': 0, 'parents': ['tap']},
	{'value': 'taptum', 'label': 'taptum', 'attrib': [{'pos': 'inf', 'defs': 'heat, scorch'}], 'isLemma': 0, 'parents': ['tap']},
	{'value': 'taptvA', 'label': 'taptvA', 'attrib': [{'pos': 'abs', 'defs': 'heat, scorch'}], 'isLemma': 0, 'parents': ['tap']},
	{'value': 'tapya', 'label': 'tapya', 'attrib': [{'pos': 'ger', 'defs': 'heat, scorch'}], 'isLemma': 0, 'parents': ['tap']},
	{'value': 'tapyamAna', 'label': 'tapyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'heat, scorch'}], 'isLemma': 0, 'parents': ['tap']},
	{'value': 'tapsyat', 'label': 'tapsyat', 'attrib': [{'pos': 'fap', 'defs': 'heat, scorch'}], 'isLemma': 0, 'parents': ['tap']},
	{'value': 'tapsyamARa', 'label': 'tapsyamARa', 'attrib': [{'pos': 'fpp', 'defs': 'heat, scorch'}], 'isLemma': 0, 'parents': ['tap']},
	{'value': 'tarpitavya', 'label': 'tarpitavya', 'attrib': [{'pos': 'ger', 'defs': 'become satisfied, be pleased; caus. satiate, satisfy, please'}], 'isLemma': 0, 'parents': ['tfp']},
	{'value': 'tarpitum', 'label': 'tarpitum', 'attrib': [{'pos': 'inf', 'defs': 'become satisfied, be pleased; caus. satiate, satisfy, please'}], 'isLemma': 0, 'parents': ['tfp']},
	{'value': 'tarpitvA', 'label': 'tarpitvA', 'attrib': [{'pos': 'abs', 'defs': 'become satisfied, be pleased; caus. satiate, satisfy, please'}], 'isLemma': 0, 'parents': ['tfp']},
	{'value': 'tarpizyat', 'label': 'tarpizyat', 'attrib': [{'pos': 'fap', 'defs': 'become satisfied, be pleased; caus. satiate, satisfy, please'}], 'isLemma': 0, 'parents': ['tfp']},
	{'value': 'tarptavya', 'label': 'tarptavya', 'attrib': [{'pos': 'ger', 'defs': 'become satisfied, be pleased; caus. satiate, satisfy, please'}], 'isLemma': 0, 'parents': ['tfp']},
	{'value': 'tarptum', 'label': 'tarptum', 'attrib': [{'pos': 'inf', 'defs': 'become satisfied, be pleased; caus. satiate, satisfy, please'}], 'isLemma': 0, 'parents': ['tfp']},
	{'value': 'tarpsyat', 'label': 'tarpsyat', 'attrib': [{'pos': 'fap', 'defs': 'become satisfied, be pleased; caus. satiate, satisfy, please'}], 'isLemma': 0, 'parents': ['tfp']},
	{'value': 'tasTAna', 'label': 'tasTAna', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'stand, stay, be present'}], 'isLemma': 0, 'parents': ['sTA']},
	{'value': 'tasTivas', 'label': 'tasTivas', 'attrib': [{'pos': 'pfap', 'defs': 'stand, stay, be present'}], 'isLemma': 0, 'parents': ['sTA']},
	{'value': 'tAta', 'label': 'tAta', 'attrib': [{'pos': 'm', 'defs': 'dad, son'}], 'isLemma': 1},
	{'value': 'tAnya', 'label': 'tAnya', 'attrib': [{'pos': 'ger', 'defs': 'extend'}], 'isLemma': 0, 'parents': ['tan']},
	{'value': 'tizWat', 'label': 'tizWat', 'attrib': [{'pos': 'prap', 'defs': 'stand, stay, be present'}], 'isLemma': 0, 'parents': ['sTA']},
	{'value': 'tizWamAna', 'label': 'tizWamAna', 'attrib': [{'pos': 'prmp', 'defs': 'stand, stay, be present'}], 'isLemma': 0, 'parents': ['sTA']},
	{'value': 'tu', 'label': 'tu', 'attrib': [{'pos': 'conj_pcl', 'defs': 'but'}], 'isLemma': 1},
	{'value': 'tfp', 'label': 'tfp', 'attrib': [{'pos': 'vi4a', 'defs': 'become satisfied, be pleased; caus. satiate, satisfy, please'}], 'isLemma': 1},
	{'value': 'tfpta', 'label': 'tfpta', 'attrib': [{'pos': 'ppp', 'defs': 'become satisfied, be pleased; caus. satiate, satisfy, please'}], 'isLemma': 0, 'parents': ['tfp']},
	{'value': 'tfptavat', 'label': 'tfptavat', 'attrib': [{'pos': 'pap', 'defs': 'become satisfied, be pleased; caus. satiate, satisfy, please'}], 'isLemma': 0, 'parents': ['tfp']},
	{'value': 'tfptvA', 'label': 'tfptvA', 'attrib': [{'pos': 'abs', 'defs': 'become satisfied, be pleased; caus. satiate, satisfy, please'}], 'isLemma': 0, 'parents': ['tfp']},
	{'value': 'tfpyat', 'label': 'tfpyat', 'attrib': [{'pos': 'prap', 'defs': 'become satisfied, be pleased; caus. satiate, satisfy, please'}], 'isLemma': 0, 'parents': ['tfp']},
	{'value': 'tfpyamARa', 'label': 'tfpyamARa', 'attrib': [{'pos': 'prpp', 'defs': 'become satisfied, be pleased; caus. satiate, satisfy, please'}], 'isLemma': 0, 'parents': ['tfp']},
	{'value': 'tejas', 'label': 'tejas', 'attrib': [{'pos': 'n', 'defs': 'brilliance'}], 'isLemma': 1},
	{'value': 'tenAna', 'label': 'tenAna', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'extend'}], 'isLemma': 0, 'parents': ['tan']},
	{'value': 'tenivas', 'label': 'tenivas', 'attrib': [{'pos': 'pfap', 'defs': 'extend'}], 'isLemma': 0, 'parents': ['tan']},
	{'value': 'tepAna', 'label': 'tepAna', 'attrib': [{'pos': 'pfpp', 'defs': 'heat, scorch'}], 'isLemma': 0, 'parents': ['tap']},
	{'value': 'tepivas', 'label': 'tepivas', 'attrib': [{'pos': 'pfap', 'defs': 'heat, scorch'}], 'isLemma': 0, 'parents': ['tap']},
	{'value': 'toya', 'label': 'toya', 'attrib': [{'pos': 'n', 'defs': 'water'}], 'isLemma': 1},
	{'value': 'traptavya', 'label': 'traptavya', 'attrib': [{'pos': 'ger', 'defs': 'become satisfied, be pleased; caus. satiate, satisfy, please'}], 'isLemma': 0, 'parents': ['tfp']},
	{'value': 'traptum', 'label': 'traptum', 'attrib': [{'pos': 'inf', 'defs': 'become satisfied, be pleased; caus. satiate, satisfy, please'}], 'isLemma': 0, 'parents': ['tfp']},
	{'value': 'trapsyat', 'label': 'trapsyat', 'attrib': [{'pos': 'fap', 'defs': 'become satisfied, be pleased; caus. satiate, satisfy, please'}], 'isLemma': 0, 'parents': ['tfp']},
	{'value': 'trayas', 'label': 'trayas', 'attrib': [{'pos': 'm', 'defs': 'three'}], 'isLemma': 1},
	{'value': 'trayodaSa', 'label': 'trayodaSa', 'attrib': [{'pos': 'ord_num', 'defs': 'thirteenth'}], 'isLemma': 1},
	{'value': 'tri', 'label': 'tri', 'attrib': [{'pos': 'card_num', 'defs': 'three'}], 'isLemma': 1},
	{'value': 'tvad', 'label': 'tvad', 'attrib': [{'pos': 'pers_pron', 'defs': 'you'}], 'isLemma': 1},
	{'value': 'daMS', 'label': 'daMS', 'attrib': [{'pos': 'vt1a', 'defs': 'bite'}], 'isLemma': 1},
	{'value': 'daMSanIya', 'label': 'daMSanIya', 'attrib': [{'pos': 'ger', 'defs': 'bite'}], 'isLemma': 0, 'parents': ['daMS']},
	{'value': 'daMSya', 'label': 'daMSya', 'attrib': [{'pos': 'ger', 'defs': 'bite'}], 'isLemma': 0, 'parents': ['daMS']},
	{'value': 'daMzwavya', 'label': 'daMzwavya', 'attrib': [{'pos': 'ger', 'defs': 'bite'}], 'isLemma': 0, 'parents': ['daMS']},
	{'value': 'daMzwum', 'label': 'daMzwum', 'attrib': [{'pos': 'inf', 'defs': 'bite'}], 'isLemma': 0, 'parents': ['daMS']},
	{'value': 'dakziRa', 'label': 'dakziRa', 'attrib': [{'pos': 'adj', 'defs': 'skilful'}, {'pos': 'pron_adj, pron', 'defs': 'right, southern'}], 'isLemma': 1},
	{'value': 'dakziRA', 'label': 'dakziRA', 'attrib': [{'pos': 'f', 'defs': "the south, officiant's fee"}], 'isLemma': 1},
	{'value': 'dagDa', 'label': 'dagDa', 'attrib': [{'pos': 'ppp', 'defs': 'burn'}], 'isLemma': 0, 'parents': ['dah']},
	{'value': 'dagDavat', 'label': 'dagDavat', 'attrib': [{'pos': 'pap', 'defs': 'burn'}], 'isLemma': 0, 'parents': ['dah']},
	{'value': 'dagDavya', 'label': 'dagDavya', 'attrib': [{'pos': 'ger', 'defs': 'burn'}], 'isLemma': 0, 'parents': ['dah']},
	{'value': 'dagDum', 'label': 'dagDum', 'attrib': [{'pos': 'inf', 'defs': 'burn'}], 'isLemma': 0, 'parents': ['dah']},
	{'value': 'dagDvA', 'label': 'dagDvA', 'attrib': [{'pos': 'abs', 'defs': 'burn'}], 'isLemma': 0, 'parents': ['dah']},
	{'value': 'daNkzyat', 'label': 'daNkzyat', 'attrib': [{'pos': 'fap', 'defs': 'bite'}], 'isLemma': 0, 'parents': ['daMS']},
	{'value': 'datta', 'label': 'datta', 'attrib': [{'pos': 'ppp', 'defs': 'give'}], 'isLemma': 0, 'parents': ['dA']},
	{'value': 'dattavat', 'label': 'dattavat', 'attrib': [{'pos': 'pap', 'defs': 'give'}], 'isLemma': 0, 'parents': ['dA']},
	{'value': 'dattvA', 'label': 'dattvA', 'attrib': [{'pos': 'abs', 'defs': 'give'}], 'isLemma': 0, 'parents': ['dA']},
	{'value': 'dadaMSivas', 'label': 'dadaMSivas', 'attrib': [{'pos': 'pfap', 'defs': 'bite'}], 'isLemma': 0, 'parents': ['daMS']},
	{'value': 'dadat', 'label': 'dadat', 'attrib': [{'pos': 'prap', 'defs': 'give'}], 'isLemma': 0, 'parents': ['dA']},
	{'value': 'dadahAna', 'label': 'dadahAna', 'attrib': [{'pos': 'pfpp', 'defs': 'burn'}], 'isLemma': 0, 'parents': ['dah']},
	{'value': 'dadahvas', 'label': 'dadahvas', 'attrib': [{'pos': 'pfap', 'defs': 'burn'}], 'isLemma': 0, 'parents': ['dah']},
	{'value': 'dadAna', 'label': 'dadAna', 'attrib': [{'pos': 'prmp', 'defs': 'give'}, {'pos': 'pfmp, pfpp', 'defs': 'give'}], 'isLemma': 0, 'parents': ['dA']},
	{'value': 'dadivas', 'label': 'dadivas', 'attrib': [{'pos': 'pfap', 'defs': 'give'}], 'isLemma': 0, 'parents': ['dA']},
	{'value': 'dadfSAna', 'label': 'dadfSAna', 'attrib': [{'pos': 'pfpp', 'defs': 'see, watch'}], 'isLemma': 0, 'parents': ['dfS']},
	{'value': 'dadfSivas', 'label': 'dadfSivas', 'attrib': [{'pos': 'pfap', 'defs': 'see, watch'}], 'isLemma': 0, 'parents': ['dfS']},
	{'value': 'dadfSvas', 'label': 'dadfSvas', 'attrib': [{'pos': 'pfap', 'defs': 'see, watch'}], 'isLemma': 0, 'parents': ['dfS']},
	{'value': 'daDat', 'label': 'daDat', 'attrib': [{'pos': 'prap', 'defs': 'put'}], 'isLemma': 0, 'parents': ['DA']},
	{'value': 'daDAna', 'label': 'daDAna', 'attrib': [{'pos': 'prmp', 'defs': 'put'}, {'pos': 'pfmp, pfpp', 'defs': 'put'}], 'isLemma': 0, 'parents': ['DA']},
	{'value': 'daDivas', 'label': 'daDivas', 'attrib': [{'pos': 'pfap', 'defs': 'put'}], 'isLemma': 0, 'parents': ['DA']},
	{'value': 'daDfvas', 'label': 'daDfvas', 'attrib': [{'pos': 'pfap', 'defs': 'hold'}], 'isLemma': 0, 'parents': ['Df']},
	{'value': 'daDrARa', 'label': 'daDrARa', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'hold'}], 'isLemma': 0, 'parents': ['Df']},
	{'value': 'dam', 'label': 'dam', 'attrib': [{'pos': 'vt4a', 'defs': 'tame'}], 'isLemma': 1},
	{'value': 'dama', 'label': 'dama', 'attrib': [{'pos': 'adj', 'defs': 'taming'}], 'isLemma': 1},
	{'value': 'darSanIya', 'label': 'darSanIya', 'attrib': [{'pos': 'ger', 'defs': 'see, watch'}], 'isLemma': 0, 'parents': ['dfS']},
	{'value': 'daSat', 'label': 'daSat', 'attrib': [{'pos': 'prap', 'defs': 'bite'}], 'isLemma': 0, 'parents': ['daMS']},
	{'value': 'daSan', 'label': 'daSan', 'attrib': [{'pos': 'card_num', 'defs': 'ten'}], 'isLemma': 1},
	{'value': 'daSyamAna', 'label': 'daSyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'bite'}], 'isLemma': 0, 'parents': ['daMS']},
	{'value': 'dazwa', 'label': 'dazwa', 'attrib': [{'pos': 'ppp', 'defs': 'bite'}], 'isLemma': 0, 'parents': ['daMS']},
	{'value': 'dazwavat', 'label': 'dazwavat', 'attrib': [{'pos': 'pap', 'defs': 'bite'}], 'isLemma': 0, 'parents': ['daMS']},
	{'value': 'dazwvA', 'label': 'dazwvA', 'attrib': [{'pos': 'abs', 'defs': 'bite'}], 'isLemma': 0, 'parents': ['daMS']},
	{'value': 'dah', 'label': 'dah', 'attrib': [{'pos': 'vt1a', 'defs': 'burn'}], 'isLemma': 1},
	{'value': 'dahat', 'label': 'dahat', 'attrib': [{'pos': 'prap', 'defs': 'burn'}], 'isLemma': 0, 'parents': ['dah']},
	{'value': 'dahanIya', 'label': 'dahanIya', 'attrib': [{'pos': 'ger', 'defs': 'burn'}], 'isLemma': 0, 'parents': ['dah']},
	{'value': 'dahyamAna', 'label': 'dahyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'burn'}], 'isLemma': 0, 'parents': ['dah']},
	{'value': 'dA', 'label': 'dA', 'attrib': [{'pos': 'vt3am', 'defs': 'give'}], 'isLemma': 1},
	{'value': 'dAtavya', 'label': 'dAtavya', 'attrib': [{'pos': 'ger', 'defs': 'give'}], 'isLemma': 0, 'parents': ['dA']},
	{'value': 'dAtum', 'label': 'dAtum', 'attrib': [{'pos': 'inf', 'defs': 'give'}], 'isLemma': 0, 'parents': ['dA']},
	{'value': 'dAnIya', 'label': 'dAnIya', 'attrib': [{'pos': 'ger', 'defs': 'give'}], 'isLemma': 0, 'parents': ['dA']},
	{'value': 'dAsyat', 'label': 'dAsyat', 'attrib': [{'pos': 'fap', 'defs': 'give'}], 'isLemma': 0, 'parents': ['dA']},
	{'value': 'dAsyamAna', 'label': 'dAsyamAna', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'give'}], 'isLemma': 0, 'parents': ['dA']},
	{'value': 'dAhya', 'label': 'dAhya', 'attrib': [{'pos': 'ger', 'defs': 'burn'}], 'isLemma': 0, 'parents': ['dah']},
	{'value': 'didiSAna', 'label': 'didiSAna', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'point'}], 'isLemma': 0, 'parents': ['diS']},
	{'value': 'didiSivas', 'label': 'didiSivas', 'attrib': [{'pos': 'pfap', 'defs': 'point'}], 'isLemma': 0, 'parents': ['diS']},
	{'value': 'div', 'label': 'div', 'attrib': [{'pos': 'm, f', 'defs': 'sky, the vault of heaven, day'}], 'isLemma': 1},
	{'value': 'divya', 'label': 'divya', 'attrib': [{'pos': 'adj', 'defs': 'divine'}], 'isLemma': 1},
	{'value': 'diS', 'label': 'diS', 'attrib': [{'pos': 'f', 'defs': 'direction'}, {'pos': 'vt6am', 'defs': 'point'}], 'isLemma': 1},
	{'value': 'diSat', 'label': 'diSat', 'attrib': [{'pos': 'prap', 'defs': 'point'}], 'isLemma': 0, 'parents': ['diS']},
	{'value': 'diSamAna', 'label': 'diSamAna', 'attrib': [{'pos': 'prmp', 'defs': 'point'}], 'isLemma': 0, 'parents': ['diS']},
	{'value': 'diSyamAna', 'label': 'diSyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'point'}], 'isLemma': 0, 'parents': ['diS']},
	{'value': 'dizwa', 'label': 'dizwa', 'attrib': [{'pos': 'ppp', 'defs': 'point'}], 'isLemma': 0, 'parents': ['diS']},
	{'value': 'dizwavat', 'label': 'dizwavat', 'attrib': [{'pos': 'pap', 'defs': 'point'}], 'isLemma': 0, 'parents': ['diS']},
	{'value': 'dizwvA', 'label': 'dizwvA', 'attrib': [{'pos': 'abs', 'defs': 'point'}], 'isLemma': 0, 'parents': ['diS']},
	{'value': 'dIyamAna', 'label': 'dIyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'give'}], 'isLemma': 0, 'parents': ['dA']},
	{'value': 'duHSAsana', 'label': 'duHSAsana', 'attrib': [{'pos': 'm_pn', 'defs': 'Duḥśāsana'}], 'isLemma': 1},
	{'value': 'dudruvas', 'label': 'dudruvas', 'attrib': [{'pos': 'pfap', 'defs': 'run'}], 'isLemma': 0, 'parents': ['dru']},
	{'value': 'dudruvARa', 'label': 'dudruvARa', 'attrib': [{'pos': 'pfpp', 'defs': 'run'}], 'isLemma': 0, 'parents': ['dru']},
	{'value': 'durmati', 'label': 'durmati', 'attrib': [{'pos': 'adj', 'defs': 'ill-minded, weak-minded, evil-minded'}], 'isLemma': 1},
	{'value': 'duryoDana', 'label': 'duryoDana', 'attrib': [{'pos': 'm_pn', 'defs': 'Duryodhana'}], 'isLemma': 1},
	{'value': 'dus', 'label': 'dus', 'attrib': [{'pos': 'preverb', 'defs': 'ill'}], 'isLemma': 1},
	{'value': 'dfS', 'label': 'dfS', 'attrib': [{'pos': 'vt1a', 'defs': 'see, watch'}], 'isLemma': 1},
	{'value': 'dfSya', 'label': 'dfSya', 'attrib': [{'pos': 'ger', 'defs': 'see, watch'}], 'isLemma': 0, 'parents': ['dfS']},
	{'value': 'dfSyamAna', 'label': 'dfSyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'see, watch'}], 'isLemma': 0, 'parents': ['dfS']},
	{'value': 'dfzwa', 'label': 'dfzwa', 'attrib': [{'pos': 'ppp', 'defs': 'see, watch'}], 'isLemma': 0, 'parents': ['dfS']},
	{'value': 'dfzwavat', 'label': 'dfzwavat', 'attrib': [{'pos': 'pap', 'defs': 'see, watch'}], 'isLemma': 0, 'parents': ['dfS']},
	{'value': 'dfzwvA', 'label': 'dfzwvA', 'attrib': [{'pos': 'abs', 'defs': 'see, watch'}], 'isLemma': 0, 'parents': ['dfS']},
	{'value': 'dekzyat', 'label': 'dekzyat', 'attrib': [{'pos': 'fap', 'defs': 'point'}], 'isLemma': 0, 'parents': ['diS']},
	{'value': 'dekzyamARa', 'label': 'dekzyamARa', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'point'}], 'isLemma': 0, 'parents': ['diS']},
	{'value': 'deya', 'label': 'deya', 'attrib': [{'pos': 'ger', 'defs': 'give'}], 'isLemma': 0, 'parents': ['dA']},
	{'value': 'deva', 'label': 'deva', 'attrib': [{'pos': 'm', 'defs': 'luminous being, god'}], 'isLemma': 1},
	{'value': 'deSa', 'label': 'deSa', 'attrib': [{'pos': 'm', 'defs': 'place'}], 'isLemma': 1},
	{'value': 'deSanIya', 'label': 'deSanIya', 'attrib': [{'pos': 'ger', 'defs': 'point'}], 'isLemma': 0, 'parents': ['diS']},
	{'value': 'deSya', 'label': 'deSya', 'attrib': [{'pos': 'ger', 'defs': 'point'}], 'isLemma': 0, 'parents': ['diS']},
	{'value': 'dezwavya', 'label': 'dezwavya', 'attrib': [{'pos': 'ger', 'defs': 'point'}], 'isLemma': 0, 'parents': ['diS']},
	{'value': 'dezwum', 'label': 'dezwum', 'attrib': [{'pos': 'inf', 'defs': 'point'}], 'isLemma': 0, 'parents': ['diS']},
	{'value': 'dEva', 'label': 'dEva', 'attrib': [{'pos': 'n', 'defs': 'caused by the gods (deva), fate'}], 'isLemma': 1},
	{'value': 'dEvaBAvyarTarakzita', 'label': 'dEvaBAvyarTarakzita', 'attrib': [{'pos': 'adj', 'defs': 'protected for future purposes by divine cause'}], 'isLemma': 1},
	{'value': 'dyUta', 'label': 'dyUta', 'attrib': [{'pos': 'n', 'defs': 'playing with dice, gambling'}], 'isLemma': 1},
	{'value': 'dyUtasaMBUta', 'label': 'dyUtasaMBUta', 'attrib': [{'pos': 'adj', 'defs': 'arisen from the gambling match'}], 'isLemma': 1},
	{'value': 'drakzyat', 'label': 'drakzyat', 'attrib': [{'pos': 'fap', 'defs': 'see, watch'}], 'isLemma': 0, 'parents': ['dfS']},
	{'value': 'drakzyamARa', 'label': 'drakzyamARa', 'attrib': [{'pos': 'fpp', 'defs': 'see, watch'}], 'isLemma': 0, 'parents': ['dfS']},
	{'value': 'dravaRIya', 'label': 'dravaRIya', 'attrib': [{'pos': 'ger', 'defs': 'run'}], 'isLemma': 0, 'parents': ['dru']},
	{'value': 'dravat', 'label': 'dravat', 'attrib': [{'pos': 'prap', 'defs': 'run'}], 'isLemma': 0, 'parents': ['dru']},
	{'value': 'dravya', 'label': 'dravya', 'attrib': [{'pos': 'ger', 'defs': 'run'}], 'isLemma': 0, 'parents': ['dru']},
	{'value': 'drazwavya', 'label': 'drazwavya', 'attrib': [{'pos': 'ger', 'defs': 'see, watch'}], 'isLemma': 0, 'parents': ['dfS']},
	{'value': 'drazwum', 'label': 'drazwum', 'attrib': [{'pos': 'inf', 'defs': 'see, watch'}], 'isLemma': 0, 'parents': ['dfS']},
	{'value': 'dru', 'label': 'dru', 'attrib': [{'pos': 'm, n', 'defs': 'wood, wooden implement'}, {'pos': 'vt1a', 'defs': 'run'}], 'isLemma': 1},
	{'value': 'druta', 'label': 'druta', 'attrib': [{'pos': 'ppp', 'defs': 'run'}, {'pos': 'adj', 'defs': 'swift, fluid'}], 'isLemma': 1},
	{'value': 'druta', 'label': 'druta', 'attrib': [{'pos': 'ppp', 'defs': 'run'}, {'pos': 'adj', 'defs': 'swift, fluid'}], 'isLemma': 0, 'parents': ['dru']},
	{'value': 'drutavat', 'label': 'drutavat', 'attrib': [{'pos': 'pap', 'defs': 'run'}], 'isLemma': 0, 'parents': ['dru']},
	{'value': 'drutvA', 'label': 'drutvA', 'attrib': [{'pos': 'abs', 'defs': 'run'}], 'isLemma': 0, 'parents': ['dru']},
	{'value': 'drupada', 'label': 'drupada', 'attrib': [{'pos': 'm_pn', 'defs': 'Drupada'}, {'pos': 'n', 'defs': 'wooden pillar'}], 'isLemma': 1},
	{'value': 'drUyamARa', 'label': 'drUyamARa', 'attrib': [{'pos': 'prpp', 'defs': 'run'}], 'isLemma': 0, 'parents': ['dru']},
	{'value': 'drotavya', 'label': 'drotavya', 'attrib': [{'pos': 'ger', 'defs': 'run'}], 'isLemma': 0, 'parents': ['dru']},
	{'value': 'drotum', 'label': 'drotum', 'attrib': [{'pos': 'inf', 'defs': 'run'}], 'isLemma': 0, 'parents': ['dru']},
	{'value': 'drozyat', 'label': 'drozyat', 'attrib': [{'pos': 'fap', 'defs': 'run'}], 'isLemma': 0, 'parents': ['dru']},
	{'value': 'drozyamARa', 'label': 'drozyamARa', 'attrib': [{'pos': 'fpp', 'defs': 'run'}], 'isLemma': 0, 'parents': ['dru']},
	{'value': 'drOpadI', 'label': 'drOpadI', 'attrib': [{'pos': 'f', 'defs': 'daughter of Drupada'}], 'isLemma': 1},
	{'value': 'dvAra', 'label': 'dvAra', 'attrib': [{'pos': 'n', 'defs': 'door, gate'}], 'isLemma': 1},
	{'value': 'dvAravat', 'label': 'dvAravat', 'attrib': [{'pos': 'adj', 'defs': 'having doors, many-gated'}], 'isLemma': 1},
	{'value': 'dvAravatI', 'label': 'dvAravatI', 'attrib': [{'pos': 'f', 'defs': 'Dvāraka'}], 'isLemma': 1},
	{'value': 'dvi', 'label': 'dvi', 'attrib': [{'pos': 'card_num', 'defs': 'two'}], 'isLemma': 1},
	{'value': 'dvija', 'label': 'dvija', 'attrib': [{'pos': 'adj', 'defs': 'twice-born'}], 'isLemma': 1},
	{'value': 'Dakzyat', 'label': 'Dakzyat', 'attrib': [{'pos': 'fap', 'defs': 'burn'}], 'isLemma': 0, 'parents': ['dah']},
	{'value': 'DakzyamARa', 'label': 'DakzyamARa', 'attrib': [{'pos': 'fpp', 'defs': 'burn'}], 'isLemma': 0, 'parents': ['dah']},
	{'value': 'Dana', 'label': 'Dana', 'attrib': [{'pos': 'n', 'defs': 'wealth'}], 'isLemma': 1},
	{'value': 'DanaMjaya', 'label': 'DanaMjaya', 'attrib': [{'pos': 'm', 'defs': 'winner of wealth'}], 'isLemma': 1},
	{'value': 'Danus', 'label': 'Danus', 'attrib': [{'pos': 'n', 'defs': 'bow'}], 'isLemma': 1},
	{'value': 'Dara', 'label': 'Dara', 'attrib': [{'pos': 'adj', 'defs': 'holding'}], 'isLemma': 1},
	{'value': 'DaraRIya', 'label': 'DaraRIya', 'attrib': [{'pos': 'ger', 'defs': 'hold'}], 'isLemma': 0, 'parents': ['Df']},
	{'value': 'Darat', 'label': 'Darat', 'attrib': [{'pos': 'prap', 'defs': 'hold'}], 'isLemma': 0, 'parents': ['Df']},
	{'value': 'DaramARa', 'label': 'DaramARa', 'attrib': [{'pos': 'prmp', 'defs': 'hold'}], 'isLemma': 0, 'parents': ['Df']},
	{'value': 'Darizyat', 'label': 'Darizyat', 'attrib': [{'pos': 'fap', 'defs': 'hold'}], 'isLemma': 0, 'parents': ['Df']},
	{'value': 'DarizyamARa', 'label': 'DarizyamARa', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'hold'}], 'isLemma': 0, 'parents': ['Df']},
	{'value': 'Dartavya', 'label': 'Dartavya', 'attrib': [{'pos': 'ger', 'defs': 'hold'}], 'isLemma': 0, 'parents': ['Df']},
	{'value': 'Dartum', 'label': 'Dartum', 'attrib': [{'pos': 'inf', 'defs': 'hold'}], 'isLemma': 0, 'parents': ['Df']},
	{'value': 'Darma', 'label': 'Darma', 'attrib': [{'pos': 'm', 'defs': 'that which upholds, cosmic order, truth, proper function, right action, duty'}], 'isLemma': 1},
	{'value': 'DarmapraDAna', 'label': 'DarmapraDAna', 'attrib': [{'pos': 'adj', 'defs': 'devoted to dharma'}], 'isLemma': 1},
	{'value': 'DarmarAja', 'label': 'DarmarAja', 'attrib': [{'pos': 'm', 'defs': 'king dharma'}], 'isLemma': 1},
	{'value': 'DA', 'label': 'DA', 'attrib': [{'pos': 'vt3am', 'defs': 'put'}], 'isLemma': 1},
	{'value': 'DAtavya', 'label': 'DAtavya', 'attrib': [{'pos': 'ger', 'defs': 'put'}], 'isLemma': 0, 'parents': ['DA']},
	{'value': 'DAtum', 'label': 'DAtum', 'attrib': [{'pos': 'inf', 'defs': 'put'}], 'isLemma': 0, 'parents': ['DA']},
	{'value': 'DAnIya', 'label': 'DAnIya', 'attrib': [{'pos': 'ger', 'defs': 'put'}], 'isLemma': 0, 'parents': ['DA']},
	{'value': 'DAsyat', 'label': 'DAsyat', 'attrib': [{'pos': 'fap', 'defs': 'put'}], 'isLemma': 0, 'parents': ['DA']},
	{'value': 'DAsyamAna', 'label': 'DAsyamAna', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'put'}], 'isLemma': 0, 'parents': ['DA']},
	{'value': 'DI', 'label': 'DI', 'attrib': [{'pos': 'f', 'defs': 'intelligence'}], 'isLemma': 1},
	{'value': 'DImat', 'label': 'DImat', 'attrib': [{'pos': 'adj', 'defs': 'intelligent, wise'}], 'isLemma': 1},
	{'value': 'DIyamAna', 'label': 'DIyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'put'}], 'isLemma': 0, 'parents': ['DA']},
	{'value': 'Df', 'label': 'Df', 'attrib': [{'pos': 'vt1am', 'defs': 'hold'}], 'isLemma': 1},
	{'value': 'Dfta', 'label': 'Dfta', 'attrib': [{'pos': 'ppp', 'defs': 'hold'}], 'isLemma': 0, 'parents': ['Df']},
	{'value': 'DftarAzwra', 'label': 'DftarAzwra', 'attrib': [{'pos': 'm_pn', 'defs': 'Dhṛtarāṣṭra'}], 'isLemma': 1},
	{'value': 'DftarAzwraja', 'label': 'DftarAzwraja', 'attrib': [{'pos': 'adj', 'defs': 'born of Dhṛtarāṣṭra'}], 'isLemma': 1},
	{'value': 'DftarAzwraBIzma', 'label': 'DftarAzwraBIzma', 'attrib': [{'pos': 'm', 'defs': 'Dhṛtarāṣṭra and Bhīṣma'}], 'isLemma': 1},
	{'value': 'Dftavat', 'label': 'Dftavat', 'attrib': [{'pos': 'pap', 'defs': 'hold'}], 'isLemma': 0, 'parents': ['Df']},
	{'value': 'Dftya', 'label': 'Dftya', 'attrib': [{'pos': 'ger', 'defs': 'hold'}], 'isLemma': 0, 'parents': ['Df']},
	{'value': 'DftvA', 'label': 'DftvA', 'attrib': [{'pos': 'abs', 'defs': 'hold'}], 'isLemma': 0, 'parents': ['Df']},
	{'value': 'Deya', 'label': 'Deya', 'attrib': [{'pos': 'ger', 'defs': 'put'}], 'isLemma': 0, 'parents': ['DA']},
	{'value': 'DriyamARa', 'label': 'DriyamARa', 'attrib': [{'pos': 'prpp', 'defs': 'hold'}], 'isLemma': 0, 'parents': ['Df']},
	{'value': 'na', 'label': 'na', 'attrib': [{'pos': 'neg_pcl', 'defs': 'not'}], 'isLemma': 1},
	{'value': 'naMsyat', 'label': 'naMsyat', 'attrib': [{'pos': 'fap', 'defs': 'bow'}], 'isLemma': 0, 'parents': ['nam']},
	{'value': 'naMsyamAna', 'label': 'naMsyamAna', 'attrib': [{'pos': 'fpp', 'defs': 'bow'}], 'isLemma': 0, 'parents': ['nam']},
	{'value': 'nakula', 'label': 'nakula', 'attrib': [{'pos': 'm_pn', 'defs': 'Nakula'}], 'isLemma': 1},
	{'value': 'nagara', 'label': 'nagara', 'attrib': [{'pos': 'n', 'defs': 'city'}], 'isLemma': 1},
	{'value': 'nacira', 'label': 'nacira', 'attrib': [{'pos': 'adj', 'defs': 'not long'}], 'isLemma': 1},
	{'value': 'nata', 'label': 'nata', 'attrib': [{'pos': 'ppp', 'defs': 'bow'}], 'isLemma': 0, 'parents': ['nam']},
	{'value': 'natavat', 'label': 'natavat', 'attrib': [{'pos': 'pap', 'defs': 'bow'}], 'isLemma': 0, 'parents': ['nam']},
	{'value': 'natvA', 'label': 'natvA', 'attrib': [{'pos': 'abs', 'defs': 'bow'}], 'isLemma': 0, 'parents': ['nam']},
	{'value': 'nadI', 'label': 'nadI', 'attrib': [{'pos': 'f', 'defs': 'river'}], 'isLemma': 1},
	{'value': 'nantavya', 'label': 'nantavya', 'attrib': [{'pos': 'ger', 'defs': 'bow'}], 'isLemma': 0, 'parents': ['nam']},
	{'value': 'nantum', 'label': 'nantum', 'attrib': [{'pos': 'inf', 'defs': 'bow'}], 'isLemma': 0, 'parents': ['nam']},
	{'value': 'nam', 'label': 'nam', 'attrib': [{'pos': 'vt1a', 'defs': 'bow'}], 'isLemma': 1},
	{'value': 'namat', 'label': 'namat', 'attrib': [{'pos': 'prap', 'defs': 'bow'}], 'isLemma': 0, 'parents': ['nam']},
	{'value': 'namanIya', 'label': 'namanIya', 'attrib': [{'pos': 'ger', 'defs': 'bow'}], 'isLemma': 0, 'parents': ['nam']},
	{'value': 'namaScakfvas', 'label': 'namaScakfvas', 'attrib': [{'pos': 'pfap', 'defs': 'do obeisance, make salutation, bow to'}], 'isLemma': 0, 'parents': ['namas_kf']},
	{'value': 'namaScakrARa', 'label': 'namaScakrARa', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'do obeisance, make salutation, bow to'}], 'isLemma': 0, 'parents': ['namas_kf']},
	{'value': 'namas', 'label': 'namas', 'attrib': [{'pos': 'n', 'defs': 'obeisance, salutation'}], 'isLemma': 1},
	{'value': 'namaskaraRIya', 'label': 'namaskaraRIya', 'attrib': [{'pos': 'ger', 'defs': 'do obeisance, make salutation, bow to'}], 'isLemma': 0, 'parents': ['namas_kf']},
	{'value': 'namaskarizyat', 'label': 'namaskarizyat', 'attrib': [{'pos': 'fap', 'defs': 'do obeisance, make salutation, bow to'}], 'isLemma': 0, 'parents': ['namas_kf']},
	{'value': 'namaskarizyamARa', 'label': 'namaskarizyamARa', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'do obeisance, make salutation, bow to'}], 'isLemma': 0, 'parents': ['namas_kf']},
	{'value': 'namaskartavya', 'label': 'namaskartavya', 'attrib': [{'pos': 'ger', 'defs': 'do obeisance, make salutation, bow to'}], 'isLemma': 0, 'parents': ['namas_kf']},
	{'value': 'namaskartum', 'label': 'namaskartum', 'attrib': [{'pos': 'inf', 'defs': 'do obeisance, make salutation, bow to'}], 'isLemma': 0, 'parents': ['namas_kf']},
	{'value': 'namaskArya', 'label': 'namaskArya', 'attrib': [{'pos': 'ger', 'defs': 'do obeisance, make salutation, bow to'}], 'isLemma': 0, 'parents': ['namas_kf']},
	{'value': 'namaskurvat', 'label': 'namaskurvat', 'attrib': [{'pos': 'prap', 'defs': 'do obeisance, make salutation, bow to'}], 'isLemma': 0, 'parents': ['namas_kf']},
	{'value': 'namaskurvARa', 'label': 'namaskurvARa', 'attrib': [{'pos': 'prmp', 'defs': 'do obeisance, make salutation, bow to'}], 'isLemma': 0, 'parents': ['namas_kf']},
	{'value': 'namaskfta', 'label': 'namaskfta', 'attrib': [{'pos': 'ppp', 'defs': 'do obeisance, make salutation, bow to'}], 'isLemma': 0, 'parents': ['namas_kf']},
	{'value': 'namaskftavat', 'label': 'namaskftavat', 'attrib': [{'pos': 'pap', 'defs': 'do obeisance, make salutation, bow to'}], 'isLemma': 0, 'parents': ['namas_kf']},
	{'value': 'namaskftya', 'label': 'namaskftya', 'attrib': [{'pos': 'abs', 'defs': 'do obeisance, make salutation, bow to'}, {'pos': 'ger', 'defs': 'do obeisance, make salutation, bow to'}], 'isLemma': 0, 'parents': ['namas_kf']},
	{'value': 'namaskriyamARa', 'label': 'namaskriyamARa', 'attrib': [{'pos': 'prpp', 'defs': 'do obeisance, make salutation, bow to'}], 'isLemma': 0, 'parents': ['namas_kf']},
	{'value': 'namas_kf', 'label': 'namas_kf', 'attrib': [{'pos': 'vt8am', 'defs': 'do obeisance, make salutation, bow to'}], 'isLemma': 1},
	{'value': 'namya', 'label': 'namya', 'attrib': [{'pos': 'ger', 'defs': 'bow'}], 'isLemma': 0, 'parents': ['nam']},
	{'value': 'namyamAna', 'label': 'namyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'bow'}], 'isLemma': 0, 'parents': ['nam']},
	{'value': 'naS', 'label': 'naS', 'attrib': [{'pos': 'vi4a', 'defs': 'perish'}], 'isLemma': 1},
	{'value': 'naSanIya', 'label': 'naSanIya', 'attrib': [{'pos': 'ger', 'defs': 'perish'}], 'isLemma': 0, 'parents': ['naS']},
	{'value': 'naSyat', 'label': 'naSyat', 'attrib': [{'pos': 'prap', 'defs': 'perish'}], 'isLemma': 0, 'parents': ['naS']},
	{'value': 'nazwa', 'label': 'nazwa', 'attrib': [{'pos': 'ppp', 'defs': 'perish'}], 'isLemma': 0, 'parents': ['naS']},
	{'value': 'nazwavat', 'label': 'nazwavat', 'attrib': [{'pos': 'ppp', 'defs': 'perish'}], 'isLemma': 0, 'parents': ['naS']},
	{'value': 'nazwavya', 'label': 'nazwavya', 'attrib': [{'pos': 'ger', 'defs': 'perish'}], 'isLemma': 0, 'parents': ['naS']},
	{'value': 'nazwum', 'label': 'nazwum', 'attrib': [{'pos': 'inf', 'defs': 'perish'}], 'isLemma': 0, 'parents': ['naS']},
	{'value': 'nazwvA', 'label': 'nazwvA', 'attrib': [{'pos': 'abs', 'defs': 'perish'}], 'isLemma': 0, 'parents': ['naS']},
	{'value': 'nAman', 'label': 'nAman', 'attrib': [{'pos': 'n', 'defs': 'name'}], 'isLemma': 1},
	{'value': 'nASya', 'label': 'nASya', 'attrib': [{'pos': 'ger', 'defs': 'perish'}], 'isLemma': 0, 'parents': ['naS']},
	{'value': 'ni', 'label': 'ni', 'attrib': [{'pos': 'preverb', 'defs': 'in'}], 'isLemma': 1},
	{'value': 'nigraha', 'label': 'nigraha', 'attrib': [{'pos': 'm', 'defs': 'restraint, suppression, defeat'}], 'isLemma': 1},
	{'value': 'nigrahanirvAsa', 'label': 'nigrahanirvAsa', 'attrib': [{'pos': 'm', 'defs': 'defeat and exile'}], 'isLemma': 1},
	{'value': 'niGAtya', 'label': 'niGAtya', 'attrib': [{'pos': 'ger', 'defs': 'smite'}], 'isLemma': 0, 'parents': ['ni_han']},
	{'value': 'niGnat', 'label': 'niGnat', 'attrib': [{'pos': 'prap', 'defs': 'smite'}], 'isLemma': 0, 'parents': ['ni_han']},
	{'value': 'nijaGanvas', 'label': 'nijaGanvas', 'attrib': [{'pos': 'pfap', 'defs': 'smite'}], 'isLemma': 0, 'parents': ['ni_han']},
	{'value': 'nijaGnAna', 'label': 'nijaGnAna', 'attrib': [{'pos': 'pfpp', 'defs': 'smite'}], 'isLemma': 0, 'parents': ['ni_han']},
	{'value': 'nijaGnivas', 'label': 'nijaGnivas', 'attrib': [{'pos': 'pfap', 'defs': 'smite'}], 'isLemma': 0, 'parents': ['ni_han']},
	{'value': 'ni_grah', 'label': 'ni_grah', 'attrib': [{'pos': 'vt9a', 'defs': 'hold down, suppress'}], 'isLemma': 1},
	{'value': 'ni_vas', 'label': 'ni_vas', 'attrib': [{'pos': 'vi1a', 'defs': 'dwell, live'}], 'isLemma': 1},
	{'value': 'ni_han', 'label': 'ni_han', 'attrib': [{'pos': 'vt2a', 'defs': 'smite'}], 'isLemma': 1},
	{'value': 'nitya', 'label': 'nitya', 'attrib': [{'pos': 'adj', 'defs': 'innate, eternal, constant'}], 'isLemma': 1},
	{'value': 'nityam', 'label': 'nityam', 'attrib': [{'pos': 'adv', 'defs': 'always'}], 'isLemma': 1},
	{'value': 'nimitta', 'label': 'nimitta', 'attrib': [{'pos': 'n', 'defs': 'condition, cause, reason'}], 'isLemma': 1},
	{'value': 'nir_vas', 'label': 'nir_vas', 'attrib': [{'pos': 'vi1a', 'defs': 'dwell abroad'}], 'isLemma': 1},
	{'value': 'nirvAsa', 'label': 'nirvAsa', 'attrib': [{'pos': 'm', 'defs': 'exile, banishment'}], 'isLemma': 1},
	{'value': 'nis', 'label': 'nis', 'attrib': [{'pos': 'preverb', 'defs': 'out'}], 'isLemma': 1},
	{'value': 'nihaMsyat', 'label': 'nihaMsyat', 'attrib': [{'pos': 'fap', 'defs': 'smite'}], 'isLemma': 0, 'parents': ['ni_han']},
	{'value': 'nihaMsyamAna', 'label': 'nihaMsyamAna', 'attrib': [{'pos': 'fpp', 'defs': 'smite'}], 'isLemma': 0, 'parents': ['ni_han']},
	{'value': 'nihata', 'label': 'nihata', 'attrib': [{'pos': 'ppp', 'defs': 'smite'}], 'isLemma': 0, 'parents': ['ni_han']},
	{'value': 'nihatavat', 'label': 'nihatavat', 'attrib': [{'pos': 'pap', 'defs': 'smite'}], 'isLemma': 0, 'parents': ['ni_han']},
	{'value': 'nihananIya', 'label': 'nihananIya', 'attrib': [{'pos': 'ger', 'defs': 'smite'}], 'isLemma': 0, 'parents': ['ni_han']},
	{'value': 'nihanizyat', 'label': 'nihanizyat', 'attrib': [{'pos': 'fap', 'defs': 'smite'}], 'isLemma': 0, 'parents': ['ni_han']},
	{'value': 'nihanizyamARa', 'label': 'nihanizyamARa', 'attrib': [{'pos': 'fpp', 'defs': 'smite'}], 'isLemma': 0, 'parents': ['ni_han']},
	{'value': 'nihantavya', 'label': 'nihantavya', 'attrib': [{'pos': 'ger', 'defs': 'smite'}], 'isLemma': 0, 'parents': ['ni_han']},
	{'value': 'nihantum', 'label': 'nihantum', 'attrib': [{'pos': 'inf', 'defs': 'smite'}], 'isLemma': 0, 'parents': ['ni_han']},
	{'value': 'nihanya', 'label': 'nihanya', 'attrib': [{'pos': 'abs', 'defs': 'smite'}], 'isLemma': 0, 'parents': ['ni_han']},
	{'value': 'nihanyamAna', 'label': 'nihanyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'smite'}], 'isLemma': 0, 'parents': ['ni_han']},
	{'value': 'nf', 'label': 'nf', 'attrib': [{'pos': 'm', 'defs': 'man'}], 'isLemma': 1},
	{'value': 'nfpa', 'label': 'nfpa', 'attrib': [{'pos': 'm', 'defs': 'king'}], 'isLemma': 1},
	{'value': 'nfpasattama', 'label': 'nfpasattama', 'attrib': [{'pos': 'm', 'defs': 'most excellent king'}], 'isLemma': 1},
	{'value': 'nemivas', 'label': 'nemivas', 'attrib': [{'pos': 'pfap', 'defs': 'bow'}], 'isLemma': 0, 'parents': ['nam']},
	{'value': 'neSivas', 'label': 'neSivas', 'attrib': [{'pos': 'pfap', 'defs': 'perish'}], 'isLemma': 0, 'parents': ['naS']},
	{'value': 'neSvas', 'label': 'neSvas', 'attrib': [{'pos': 'pfap', 'defs': 'perish'}], 'isLemma': 0, 'parents': ['naS']},
	{'value': 'paYcan', 'label': 'paYcan', 'attrib': [{'pos': 'card_num', 'defs': 'five'}], 'isLemma': 1},
	{'value': 'paYcapaYcASat', 'label': 'paYcapaYcASat', 'attrib': [{'pos': 'card_num', 'defs': 'fifty-five'}], 'isLemma': 1},
	{'value': 'paYcapaYcASattama', 'label': 'paYcapaYcASattama', 'attrib': [{'pos': 'ord_num', 'defs': 'fifty-fifth'}], 'isLemma': 1},
	{'value': 'paYcAla', 'label': 'paYcAla', 'attrib': [{'pos': 'm', 'defs': 'the people of the region between the Yamuna and Gomati rivers in north India, the region itself'}], 'isLemma': 1},
	{'value': 'paYcASat', 'label': 'paYcASat', 'attrib': [{'pos': 'card_num', 'defs': 'fifty'}], 'isLemma': 1},
	{'value': 'pattavya', 'label': 'pattavya', 'attrib': [{'pos': 'ger', 'defs': 'step'}], 'isLemma': 0, 'parents': ['pad']},
	{'value': 'pattum', 'label': 'pattum', 'attrib': [{'pos': 'inf', 'defs': 'step'}], 'isLemma': 0, 'parents': ['pad']},
	{'value': 'pattvA', 'label': 'pattvA', 'attrib': [{'pos': 'abs', 'defs': 'step'}], 'isLemma': 0, 'parents': ['pad']},
	{'value': 'patsyamAna', 'label': 'patsyamAna', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'step'}], 'isLemma': 0, 'parents': ['pad']},
	{'value': 'paTin', 'label': 'paTin', 'attrib': [{'pos': 'm', 'defs': 'path, road'}], 'isLemma': 1},
	{'value': 'pad', 'label': 'pad', 'attrib': [{'pos': 'vt4m', 'defs': 'step'}], 'isLemma': 1},
	{'value': 'pada', 'label': 'pada', 'attrib': [{'pos': 'n', 'defs': 'step, foot'}], 'isLemma': 1},
	{'value': 'padanIya', 'label': 'padanIya', 'attrib': [{'pos': 'ger', 'defs': 'step'}], 'isLemma': 0, 'parents': ['pad']},
	{'value': 'padyamAna', 'label': 'padyamAna', 'attrib': [{'pos': 'prmp', 'defs': 'step'}, {'pos': 'prpp', 'defs': 'step'}], 'isLemma': 0, 'parents': ['pad']},
	{'value': 'panna', 'label': 'panna', 'attrib': [{'pos': 'ppp', 'defs': 'step'}], 'isLemma': 0, 'parents': ['pad']},
	{'value': 'pannavat', 'label': 'pannavat', 'attrib': [{'pos': 'pap', 'defs': 'step'}], 'isLemma': 0, 'parents': ['pad']},
	{'value': 'paparARa', 'label': 'paparARa', 'attrib': [{'pos': 'pfpp', 'defs': 'fill'}], 'isLemma': 0, 'parents': ['pF']},
	{'value': 'paparvas', 'label': 'paparvas', 'attrib': [{'pos': 'pfap', 'defs': 'fill'}], 'isLemma': 0, 'parents': ['pF']},
	{'value': 'papAna', 'label': 'papAna', 'attrib': [{'pos': 'pfpp', 'defs': 'drink'}], 'isLemma': 0, 'parents': ['pA']},
	{'value': 'papivas', 'label': 'papivas', 'attrib': [{'pos': 'pfap', 'defs': 'drink'}], 'isLemma': 0, 'parents': ['pA']},
	{'value': 'papFvas', 'label': 'papFvas', 'attrib': [{'pos': 'pfap', 'defs': 'fill'}], 'isLemma': 0, 'parents': ['pF']},
	{'value': 'papracCAna', 'label': 'papracCAna', 'attrib': [{'pos': 'pfpp', 'defs': 'ask'}], 'isLemma': 0, 'parents': ['pracC']},
	{'value': 'papracCvas', 'label': 'papracCvas', 'attrib': [{'pos': 'pfap', 'defs': 'ask'}], 'isLemma': 0, 'parents': ['pracC']},
	{'value': 'paprARa', 'label': 'paprARa', 'attrib': [{'pos': 'pfpp', 'defs': 'fill'}], 'isLemma': 0, 'parents': ['pF']},
	{'value': 'para', 'label': 'para', 'attrib': [{'pos': 'pron, pron_adj', 'defs': 'farther, other'}, {'pos': 'm', 'defs': 'enemy'}], 'isLemma': 1},
	{'value': 'paraMtapa', 'label': 'paraMtapa', 'attrib': [{'pos': 'adj', 'defs': 'enemy-scorching'}], 'isLemma': 1},
	{'value': 'paravIra', 'label': 'paravIra', 'attrib': [{'pos': 'm', 'defs': 'enemy hero'}], 'isLemma': 1},
	{'value': 'paravIrahan', 'label': 'paravIrahan', 'attrib': [{'pos': 'adj', 'defs': 'slaying enemy heroes'}], 'isLemma': 1},
	{'value': 'parA', 'label': 'parA', 'attrib': [{'pos': 'preverb', 'defs': 'far'}], 'isLemma': 1},
	{'value': 'parAkrama', 'label': 'parAkrama', 'attrib': [{'pos': 'm', 'defs': 'boldness'}], 'isLemma': 1},
	{'value': 'parA_kram', 'label': 'parA_kram', 'attrib': [{'pos': 'vt1a', 'defs': 'step beyond, be bold'}], 'isLemma': 1},
	{'value': 'parAyaRa', 'label': 'parAyaRa', 'attrib': [{'pos': 'n', 'defs': 'final aim'}], 'isLemma': 1},
	{'value': 'pari', 'label': 'pari', 'attrib': [{'pos': 'preverb', 'defs': 'around'}], 'isLemma': 1},
	{'value': 'parizyat', 'label': 'parizyat', 'attrib': [{'pos': 'fap', 'defs': 'fill'}], 'isLemma': 0, 'parents': ['pF']},
	{'value': 'parizyamARa', 'label': 'parizyamARa', 'attrib': [{'pos': 'fpp', 'defs': 'fill'}], 'isLemma': 0, 'parents': ['pF']},
	{'value': 'parisaMvatsara', 'label': 'parisaMvatsara', 'attrib': [{'pos': 'm', 'defs': 'a full year'}], 'isLemma': 1},
	{'value': 'parisaMvatsarozita', 'label': 'parisaMvatsarozita', 'attrib': [{'pos': 'adj', 'defs': 'dwelt a full year'}], 'isLemma': 1},
	{'value': 'parispanda', 'label': 'parispanda', 'attrib': [{'pos': 'm', 'defs': 'stirring, retinue'}], 'isLemma': 1},
	{'value': 'parIzyat', 'label': 'parIzyat', 'attrib': [{'pos': 'fap', 'defs': 'fill'}], 'isLemma': 0, 'parents': ['pF']},
	{'value': 'parIzyamARa', 'label': 'parIzyamARa', 'attrib': [{'pos': 'fpp', 'defs': 'fill'}], 'isLemma': 0, 'parents': ['pF']},
	{'value': 'pare', 'label': 'pare', 'attrib': [{'pos': 'vt2a', 'defs': 'go away, flee, pass away'}], 'isLemma': 1},
	{'value': 'parvan', 'label': 'parvan', 'attrib': [{'pos': 'n', 'defs': 'joint, section'}], 'isLemma': 1},
	{'value': 'pavanIya', 'label': 'pavanIya', 'attrib': [{'pos': 'ger', 'defs': 'purify'}], 'isLemma': 0, 'parents': ['pU']},
	{'value': 'pavita', 'label': 'pavita', 'attrib': [{'pos': 'ppp', 'defs': 'purify'}], 'isLemma': 0, 'parents': ['pU']},
	{'value': 'pavitavat', 'label': 'pavitavat', 'attrib': [{'pos': 'pap', 'defs': 'purify'}], 'isLemma': 0, 'parents': ['pU']},
	{'value': 'pavitavya', 'label': 'pavitavya', 'attrib': [{'pos': 'ger', 'defs': 'purify'}], 'isLemma': 0, 'parents': ['pU']},
	{'value': 'pavitum', 'label': 'pavitum', 'attrib': [{'pos': 'inf', 'defs': 'purify'}], 'isLemma': 0, 'parents': ['pU']},
	{'value': 'pavitvA', 'label': 'pavitvA', 'attrib': [{'pos': 'abs', 'defs': 'purify'}], 'isLemma': 0, 'parents': ['pU']},
	{'value': 'pavizyat', 'label': 'pavizyat', 'attrib': [{'pos': 'fap', 'defs': 'purify'}], 'isLemma': 0, 'parents': ['pU']},
	{'value': 'pavizyamARa', 'label': 'pavizyamARa', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'purify'}], 'isLemma': 0, 'parents': ['pU']},
	{'value': 'pavya', 'label': 'pavya', 'attrib': [{'pos': 'ger', 'defs': 'purify'}], 'isLemma': 0, 'parents': ['pU']},
	{'value': 'paSyat', 'label': 'paSyat', 'attrib': [{'pos': 'prap', 'defs': 'see, watch'}], 'isLemma': 0, 'parents': ['dfS']},
	{'value': 'pA', 'label': 'pA', 'attrib': [{'pos': 'vt2a', 'defs': 'protect'}, {'pos': 'vt1a', 'defs': 'drink'}], 'isLemma': 1},
	{'value': 'pAYcAla', 'label': 'pAYcAla', 'attrib': [{'pos': 'adj', 'defs': 'of Pañcāla'}], 'isLemma': 1},
	{'value': 'pARqava', 'label': 'pARqava', 'attrib': [{'pos': 'm', 'defs': 'son of Pāṇḍu'}], 'isLemma': 1},
	{'value': 'pARqu', 'label': 'pARqu', 'attrib': [{'pos': 'adj', 'defs': 'pale'}, {'pos': 'm_pn', 'defs': 'Pāṇḍu'}], 'isLemma': 1},
	{'value': 'pAtavya', 'label': 'pAtavya', 'attrib': [{'pos': 'ger', 'defs': 'drink'}], 'isLemma': 0, 'parents': ['pA']},
	{'value': 'pAtum', 'label': 'pAtum', 'attrib': [{'pos': 'inf', 'defs': 'drink'}], 'isLemma': 0, 'parents': ['pA']},
	{'value': 'pAtra', 'label': 'pAtra', 'attrib': [{'pos': 'n', 'defs': 'drinking-vessel, cup, begging bowl, worthy person'}], 'isLemma': 1},
	{'value': 'pAdya', 'label': 'pAdya', 'attrib': [{'pos': 'ger', 'defs': 'step'}], 'isLemma': 0, 'parents': ['pad']},
	{'value': 'pAnIya', 'label': 'pAnIya', 'attrib': [{'pos': 'ger', 'defs': 'drink'}], 'isLemma': 0, 'parents': ['pA']},
	{'value': 'pApa', 'label': 'pApa', 'attrib': [{'pos': 'adj', 'defs': 'evil'}, {'pos': 'n', 'defs': 'evil'}], 'isLemma': 1},
	{'value': 'pArTa', 'label': 'pArTa', 'attrib': [{'pos': 'm', 'defs': 'son of Pṛthā'}], 'isLemma': 1},
	{'value': 'pAvaka', 'label': 'pAvaka', 'attrib': [{'pos': 'adj', 'defs': 'purifier, epithet of fire'}], 'isLemma': 1},
	{'value': 'pAsyat', 'label': 'pAsyat', 'attrib': [{'pos': 'fap', 'defs': 'drink'}], 'isLemma': 0, 'parents': ['pA']},
	{'value': 'pAsyamAna', 'label': 'pAsyamAna', 'attrib': [{'pos': 'fpp', 'defs': 'drink'}], 'isLemma': 0, 'parents': ['pA']},
	{'value': 'pitf', 'label': 'pitf', 'attrib': [{'pos': 'm', 'defs': 'father'}], 'isLemma': 1},
	{'value': 'pibat', 'label': 'pibat', 'attrib': [{'pos': 'prap', 'defs': 'drink'}], 'isLemma': 0, 'parents': ['pA']},
	{'value': 'pIta', 'label': 'pIta', 'attrib': [{'pos': 'ppp', 'defs': 'drink'}], 'isLemma': 0, 'parents': ['pA']},
	{'value': 'pItavat', 'label': 'pItavat', 'attrib': [{'pos': 'pap', 'defs': 'drink'}], 'isLemma': 0, 'parents': ['pA']},
	{'value': 'pItvA', 'label': 'pItvA', 'attrib': [{'pos': 'abs', 'defs': 'drink'}], 'isLemma': 0, 'parents': ['pA']},
	{'value': 'pIyamAna', 'label': 'pIyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'drink'}], 'isLemma': 0, 'parents': ['pA']},
	{'value': 'putra', 'label': 'putra', 'attrib': [{'pos': 'm', 'defs': 'son, child'}], 'isLemma': 1},
	{'value': 'punat', 'label': 'punat', 'attrib': [{'pos': 'prap', 'defs': 'purify'}], 'isLemma': 0, 'parents': ['pU']},
	{'value': 'punar', 'label': 'punar', 'attrib': [{'pos': 'adv', 'defs': 'again'}], 'isLemma': 1},
	{'value': 'punAna', 'label': 'punAna', 'attrib': [{'pos': 'prmp', 'defs': 'purify'}], 'isLemma': 0, 'parents': ['pU']},
	{'value': 'pupuvAna', 'label': 'pupuvAna', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'purify'}], 'isLemma': 0, 'parents': ['pU']},
	{'value': 'pupUvas', 'label': 'pupUvas', 'attrib': [{'pos': 'pfap', 'defs': 'purify'}], 'isLemma': 0, 'parents': ['pU']},
	{'value': 'pura', 'label': 'pura', 'attrib': [{'pos': 'n', 'defs': 'city'}], 'isLemma': 1},
	{'value': 'purA', 'label': 'purA', 'attrib': [{'pos': 'adv', 'defs': 'before, formerly'}], 'isLemma': 1},
	{'value': 'purAvftta', 'label': 'purAvftta', 'attrib': [{'pos': 'adj', 'defs': 'occurred in former times'}, {'pos': 'n, ck', 'defs': 'event or account of the past'}], 'isLemma': 1},
	{'value': 'pU', 'label': 'pU', 'attrib': [{'pos': 'vt9am', 'defs': 'purify'}], 'isLemma': 1},
	{'value': 'pUj', 'label': 'pUj', 'attrib': [{'pos': 'vt10m', 'defs': 'honor, perform a pūjā'}], 'isLemma': 1},
	{'value': 'pUjanIya', 'label': 'pUjanIya', 'attrib': [{'pos': 'ger', 'defs': 'honor, perform a pūjā'}], 'isLemma': 0, 'parents': ['pUj']},
	{'value': 'pUjayamAna', 'label': 'pUjayamAna', 'attrib': [{'pos': 'prmp', 'defs': 'honor, perform a pūjā'}], 'isLemma': 0, 'parents': ['pUj']},
	{'value': 'pUjayAm', 'label': 'pUjayAm', 'attrib': [{'pos': 'ppf_actn', 'defs': 'honor, perform a pūjā'}], 'isLemma': 0, 'parents': ['pUj']},
	{'value': 'pUjayitum', 'label': 'pUjayitum', 'attrib': [{'pos': 'inf', 'defs': 'honor, perform a pūjā'}], 'isLemma': 0, 'parents': ['pUj']},
	{'value': 'pUjayitvA', 'label': 'pUjayitvA', 'attrib': [{'pos': 'abs', 'defs': 'honor, perform a pūjā'}], 'isLemma': 0, 'parents': ['pUj']},
	{'value': 'pUjayizyamARa', 'label': 'pUjayizyamARa', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'honor, perform a pūjā'}], 'isLemma': 0, 'parents': ['pUj']},
	{'value': 'pUjita', 'label': 'pUjita', 'attrib': [{'pos': 'ppp', 'defs': 'honor, perform a pūjā'}], 'isLemma': 0, 'parents': ['pUj']},
	{'value': 'pUjitavat', 'label': 'pUjitavat', 'attrib': [{'pos': 'pap', 'defs': 'honor, perform a pūjā'}], 'isLemma': 0, 'parents': ['pUj']},
	{'value': 'pUjitavya', 'label': 'pUjitavya', 'attrib': [{'pos': 'ger', 'defs': 'honor, perform a pūjā'}], 'isLemma': 0, 'parents': ['pUj']},
	{'value': 'pUjya', 'label': 'pUjya', 'attrib': [{'pos': 'ger', 'defs': 'honor, perform a pūjā'}], 'isLemma': 0, 'parents': ['pUj']},
	{'value': 'pUjyamAna', 'label': 'pUjyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'honor, perform a pūjā'}], 'isLemma': 0, 'parents': ['pUj']},
	{'value': 'pUta', 'label': 'pUta', 'attrib': [{'pos': 'ppp', 'defs': 'purify'}], 'isLemma': 0, 'parents': ['pU']},
	{'value': 'pUtavat', 'label': 'pUtavat', 'attrib': [{'pos': 'pap', 'defs': 'purify'}], 'isLemma': 0, 'parents': ['pU']},
	{'value': 'pUtvA', 'label': 'pUtvA', 'attrib': [{'pos': 'abs', 'defs': 'purify'}], 'isLemma': 0, 'parents': ['pU']},
	{'value': 'pUyamAna', 'label': 'pUyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'purify'}], 'isLemma': 0, 'parents': ['pU']},
	{'value': 'pUraRIya', 'label': 'pUraRIya', 'attrib': [{'pos': 'ger', 'defs': 'fill'}], 'isLemma': 0, 'parents': ['pF']},
	{'value': 'pUritavya', 'label': 'pUritavya', 'attrib': [{'pos': 'ger', 'defs': 'fill'}], 'isLemma': 0, 'parents': ['pF']},
	{'value': 'pUritum', 'label': 'pUritum', 'attrib': [{'pos': 'inf', 'defs': 'fill'}], 'isLemma': 0, 'parents': ['pF']},
	{'value': 'pUrRa', 'label': 'pUrRa', 'attrib': [{'pos': 'adj', 'defs': 'full'}], 'isLemma': 1},
	{'value': 'pUrta', 'label': 'pUrta', 'attrib': [{'pos': 'ppp', 'defs': 'fill'}], 'isLemma': 0, 'parents': ['pF']},
	{'value': 'pUrtavat', 'label': 'pUrtavat', 'attrib': [{'pos': 'pap', 'defs': 'fill'}], 'isLemma': 0, 'parents': ['pF']},
	{'value': 'pUrtvA', 'label': 'pUrtvA', 'attrib': [{'pos': 'abs', 'defs': 'fill'}], 'isLemma': 0, 'parents': ['pF']},
	{'value': 'pUrya', 'label': 'pUrya', 'attrib': [{'pos': 'ger', 'defs': 'fill'}], 'isLemma': 0, 'parents': ['pF']},
	{'value': 'pUryamARa', 'label': 'pUryamARa', 'attrib': [{'pos': 'prpp', 'defs': 'fill'}], 'isLemma': 0, 'parents': ['pF']},
	{'value': 'pfcCat', 'label': 'pfcCat', 'attrib': [{'pos': 'prap', 'defs': 'ask'}], 'isLemma': 0, 'parents': ['pracC']},
	{'value': 'pfcCya', 'label': 'pfcCya', 'attrib': [{'pos': 'ger', 'defs': 'ask'}], 'isLemma': 0, 'parents': ['pracC']},
	{'value': 'pfcCyamAna', 'label': 'pfcCyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'ask'}], 'isLemma': 0, 'parents': ['pracC']},
	{'value': 'pfRat', 'label': 'pfRat', 'attrib': [{'pos': 'prap', 'defs': 'fill'}], 'isLemma': 0, 'parents': ['pF']},
	{'value': 'pfTa', 'label': 'pfTa', 'attrib': [{'pos': 'adj', 'defs': 'spread out, broad'}], 'isLemma': 1},
	{'value': 'pfTA', 'label': 'pfTA', 'attrib': [{'pos': 'f', 'defs': 'Kuntī'}], 'isLemma': 1},
	{'value': 'pfTivI', 'label': 'pfTivI', 'attrib': [{'pos': 'f', 'defs': 'earth'}], 'isLemma': 1},
	{'value': 'pfTivIkzaya', 'label': 'pfTivIkzaya', 'attrib': [{'pos': 'm', 'defs': 'destruction of the earth'}], 'isLemma': 1},
	{'value': 'pfTivIkzayakAraka', 'label': 'pfTivIkzayakAraka', 'attrib': [{'pos': 'adj', 'defs': 'causing the destruction of the earth'}], 'isLemma': 1},
	{'value': 'pfTu', 'label': 'pfTu', 'attrib': [{'pos': 'adj', 'defs': 'broad'}], 'isLemma': 1},
	{'value': 'pfTvI', 'label': 'pfTvI', 'attrib': [{'pos': 'f', 'defs': 'the earth'}], 'isLemma': 1},
	{'value': 'pfzwa', 'label': 'pfzwa', 'attrib': [{'pos': 'ppp', 'defs': 'ask'}], 'isLemma': 0, 'parents': ['pracC']},
	{'value': 'pfzwavat', 'label': 'pfzwavat', 'attrib': [{'pos': 'pap', 'defs': 'ask'}], 'isLemma': 0, 'parents': ['pracC']},
	{'value': 'pfzwvA', 'label': 'pfzwvA', 'attrib': [{'pos': 'abs', 'defs': 'ask'}], 'isLemma': 0, 'parents': ['pracC']},
	{'value': 'pF', 'label': 'pF', 'attrib': [{'pos': 'vt9a', 'defs': 'fill'}], 'isLemma': 1},
	{'value': 'pedAna', 'label': 'pedAna', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'step'}], 'isLemma': 0, 'parents': ['pad']},
	{'value': 'peya', 'label': 'peya', 'attrib': [{'pos': 'ger', 'defs': 'drink'}], 'isLemma': 0, 'parents': ['pA']},
	{'value': 'pOra', 'label': 'pOra', 'attrib': [{'pos': 'adj', 'defs': 'urban'}, {'pos': 'm', 'defs': 'citizen'}], 'isLemma': 1},
	{'value': 'pOrasaMmata', 'label': 'pOrasaMmata', 'attrib': [{'pos': 'adj', 'defs': 'approved of by the citizens'}], 'isLemma': 1},
	{'value': 'pra', 'label': 'pra', 'attrib': [{'pos': 'preverb', 'defs': 'before'}], 'isLemma': 1},
	{'value': 'prakAra', 'label': 'prakAra', 'attrib': [{'pos': 'm', 'defs': 'doing, kind, manner'}], 'isLemma': 1},
	{'value': 'prakzipat', 'label': 'prakzipat', 'attrib': [{'pos': 'prap', 'defs': 'throw, project'}], 'isLemma': 0, 'parents': ['pra_kzip']},
	{'value': 'prakzipta', 'label': 'prakzipta', 'attrib': [{'pos': 'ppp', 'defs': 'throw, project'}], 'isLemma': 0, 'parents': ['pra_kzip']},
	{'value': 'prakziptavat', 'label': 'prakziptavat', 'attrib': [{'pos': 'pap', 'defs': 'throw, project'}], 'isLemma': 0, 'parents': ['pra_kzip']},
	{'value': 'prakzipya', 'label': 'prakzipya', 'attrib': [{'pos': 'abs', 'defs': 'throw, project'}], 'isLemma': 0, 'parents': ['pra_kzip']},
	{'value': 'prakzipyat', 'label': 'prakzipyat', 'attrib': [{'pos': 'prap', 'defs': 'throw, project'}], 'isLemma': 0, 'parents': ['pra_kzip']},
	{'value': 'prakzipyamARa', 'label': 'prakzipyamARa', 'attrib': [{'pos': 'prmp, prpp', 'defs': 'throw, project'}], 'isLemma': 0, 'parents': ['pra_kzip']},
	{'value': 'prakzipsyat', 'label': 'prakzipsyat', 'attrib': [{'pos': 'fap', 'defs': 'throw, project'}], 'isLemma': 0, 'parents': ['pra_kzip']},
	{'value': 'prakzipsyamARa', 'label': 'prakzipsyamARa', 'attrib': [{'pos': 'fpp', 'defs': 'throw, project'}], 'isLemma': 0, 'parents': ['pra_kzip']},
	{'value': 'prakzepayaRIya', 'label': 'prakzepayaRIya', 'attrib': [{'pos': 'ger', 'defs': 'throw, project'}], 'isLemma': 0, 'parents': ['pra_kzip']},
	{'value': 'prakzeptavya', 'label': 'prakzeptavya', 'attrib': [{'pos': 'ger', 'defs': 'throw, project'}], 'isLemma': 0, 'parents': ['pra_kzip']},
	{'value': 'prakzeptum', 'label': 'prakzeptum', 'attrib': [{'pos': 'inf', 'defs': 'throw, project'}], 'isLemma': 0, 'parents': ['pra_kzip']},
	{'value': 'prakzepya', 'label': 'prakzepya', 'attrib': [{'pos': 'ger', 'defs': 'throw, project'}], 'isLemma': 0, 'parents': ['pra_kzip']},
	{'value': 'prakzepsyat', 'label': 'prakzepsyat', 'attrib': [{'pos': 'fap', 'defs': 'throw, project'}], 'isLemma': 0, 'parents': ['pra_kzip']},
	{'value': 'prakzepsyamARa', 'label': 'prakzepsyamARa', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'throw, project'}], 'isLemma': 0, 'parents': ['pra_kzip']},
	{'value': 'prakzyat', 'label': 'prakzyat', 'attrib': [{'pos': 'fap', 'defs': 'ask'}], 'isLemma': 0, 'parents': ['pracC']},
	{'value': 'prakzyamARa', 'label': 'prakzyamARa', 'attrib': [{'pos': 'fpp', 'defs': 'ask'}], 'isLemma': 0, 'parents': ['pracC']},
	{'value': 'pracikzipARa', 'label': 'pracikzipARa', 'attrib': [{'pos': 'pfpp', 'defs': 'throw, project'}], 'isLemma': 0, 'parents': ['pra_kzip']},
	{'value': 'pracikzipivas', 'label': 'pracikzipivas', 'attrib': [{'pos': 'pfap', 'defs': 'throw, project'}], 'isLemma': 0, 'parents': ['pra_kzip']},
	{'value': 'pracC', 'label': 'pracC', 'attrib': [{'pos': 'vt6a', 'defs': 'ask'}], 'isLemma': 1},
	{'value': 'pracCanIya', 'label': 'pracCanIya', 'attrib': [{'pos': 'ger', 'defs': 'ask'}], 'isLemma': 0, 'parents': ['pracC']},
	{'value': 'pra_Ap', 'label': 'pra_Ap', 'attrib': [{'pos': 'vt5a', 'defs': 'attain, obtain'}], 'isLemma': 1},
	{'value': 'pra_ud_sah', 'label': 'pra_ud_sah', 'attrib': [{'pos': 'vt1m, vt10am', 'defs': 'be eager'}], 'isLemma': 1},
	{'value': 'pra_kf', 'label': 'pra_kf', 'attrib': [{'pos': 'vt8am', 'defs': 'make, produce'}], 'isLemma': 1},
	{'value': 'pra_kzip', 'label': 'pra_kzip', 'attrib': [{'pos': 'vt4a, vt6am', 'defs': 'throw, project'}], 'isLemma': 1},
	{'value': 'pra_tap', 'label': 'pra_tap', 'attrib': [{'pos': 'vt1a', 'defs': 'heat, burn, shine forth'}], 'isLemma': 1},
	{'value': 'pra_dru', 'label': 'pra_dru', 'attrib': [{'pos': 'vt1a', 'defs': 'run forth, escape, flee'}], 'isLemma': 1},
	{'value': 'pra_buD', 'label': 'pra_buD', 'attrib': [{'pos': 'vt1am', 'defs': 'know'}, {'pos': 'vt4m', 'defs': 'awake, be aware, know'}], 'isLemma': 1},
	{'value': 'pra_mad', 'label': 'pra_mad', 'attrib': [{'pos': 'vi4a', 'defs': 'err, be careless'}], 'isLemma': 1},
	{'value': 'pra_vac', 'label': 'pra_vac', 'attrib': [{'pos': 'vt2a', 'defs': 'proclaim, declare, tell'}], 'isLemma': 1},
	{'value': 'pra_sTA', 'label': 'pra_sTA', 'attrib': [{'pos': 'vt1m', 'defs': 'set out'}], 'isLemma': 1},
	{'value': 'pratapat', 'label': 'pratapat', 'attrib': [{'pos': 'prap', 'defs': 'heat, burn, shine forth'}], 'isLemma': 0, 'parents': ['pra_tap']},
	{'value': 'pratapanIya', 'label': 'pratapanIya', 'attrib': [{'pos': 'ger', 'defs': 'heat, burn, shine forth'}], 'isLemma': 0, 'parents': ['pra_tap']},
	{'value': 'pratapta', 'label': 'pratapta', 'attrib': [{'pos': 'ppp', 'defs': 'heat, burn, shine forth'}], 'isLemma': 0, 'parents': ['pra_tap']},
	{'value': 'prataptavat', 'label': 'prataptavat', 'attrib': [{'pos': 'pap', 'defs': 'heat, burn, shine forth'}], 'isLemma': 0, 'parents': ['pra_tap']},
	{'value': 'prataptavya', 'label': 'prataptavya', 'attrib': [{'pos': 'ger', 'defs': 'heat, burn, shine forth'}], 'isLemma': 0, 'parents': ['pra_tap']},
	{'value': 'prataptum', 'label': 'prataptum', 'attrib': [{'pos': 'inf', 'defs': 'heat, burn, shine forth'}], 'isLemma': 0, 'parents': ['pra_tap']},
	{'value': 'pratapya', 'label': 'pratapya', 'attrib': [{'pos': 'abs', 'defs': 'heat, burn, shine forth'}, {'pos': 'ger', 'defs': 'heat, burn, shine forth'}], 'isLemma': 0, 'parents': ['pra_tap']},
	{'value': 'pratapyamAna', 'label': 'pratapyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'heat, burn, shine forth'}], 'isLemma': 0, 'parents': ['pra_tap']},
	{'value': 'pratapsyat', 'label': 'pratapsyat', 'attrib': [{'pos': 'fap', 'defs': 'heat, burn, shine forth'}], 'isLemma': 0, 'parents': ['pra_tap']},
	{'value': 'pratapsyamARa', 'label': 'pratapsyamARa', 'attrib': [{'pos': 'fpp', 'defs': 'heat, burn, shine forth'}], 'isLemma': 0, 'parents': ['pra_tap']},
	{'value': 'pratasTAna', 'label': 'pratasTAna', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'set out'}], 'isLemma': 0, 'parents': ['pra_sTA']},
	{'value': 'pratApa', 'label': 'pratApa', 'attrib': [{'pos': 'm', 'defs': 'blazing'}], 'isLemma': 1},
	{'value': 'prati', 'label': 'prati', 'attrib': [{'pos': 'preverb, pcl', 'defs': 'toward, against'}], 'isLemma': 1},
	{'value': 'pratiGAta', 'label': 'pratiGAta', 'attrib': [{'pos': 'm', 'defs': 'resistance, opposition, defense'}], 'isLemma': 1},
	{'value': 'prati_A_gam', 'label': 'prati_A_gam', 'attrib': [{'pos': 'vt1a', 'defs': 'come back, return'}], 'isLemma': 1},
	{'value': 'prati_pad', 'label': 'prati_pad', 'attrib': [{'pos': 'vt4m', 'defs': 'step back, return to'}], 'isLemma': 1},
	{'value': 'pratipattavya', 'label': 'pratipattavya', 'attrib': [{'pos': 'ger', 'defs': 'step back, return to'}], 'isLemma': 0, 'parents': ['prati_pad']},
	{'value': 'pratipattum', 'label': 'pratipattum', 'attrib': [{'pos': 'inf', 'defs': 'step back, return to'}], 'isLemma': 0, 'parents': ['prati_pad']},
	{'value': 'pratipattvA', 'label': 'pratipattvA', 'attrib': [{'pos': 'abs', 'defs': 'step back, return to'}], 'isLemma': 0, 'parents': ['prati_pad']},
	{'value': 'pratipatsyamAna', 'label': 'pratipatsyamAna', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'step back, return to'}], 'isLemma': 0, 'parents': ['prati_pad']},
	{'value': 'pratipadanIya', 'label': 'pratipadanIya', 'attrib': [{'pos': 'ger', 'defs': 'step back, return to'}], 'isLemma': 0, 'parents': ['prati_pad']},
	{'value': 'pratipadya', 'label': 'pratipadya', 'attrib': [{'pos': 'abs', 'defs': 'step back, return to'}], 'isLemma': 0, 'parents': ['prati_pad']},
	{'value': 'pratipadyamAna', 'label': 'pratipadyamAna', 'attrib': [{'pos': 'prmp', 'defs': 'step back, return to'}, {'pos': 'prpp', 'defs': 'step back, return to'}], 'isLemma': 0, 'parents': ['prati_pad']},
	{'value': 'pratipanna', 'label': 'pratipanna', 'attrib': [{'pos': 'ppp', 'defs': 'step back, return to'}], 'isLemma': 0, 'parents': ['prati_pad']},
	{'value': 'pratipannavat', 'label': 'pratipannavat', 'attrib': [{'pos': 'pap', 'defs': 'step back, return to'}], 'isLemma': 0, 'parents': ['prati_pad']},
	{'value': 'pratipAdya', 'label': 'pratipAdya', 'attrib': [{'pos': 'ger', 'defs': 'step back, return to'}], 'isLemma': 0, 'parents': ['prati_pad']},
	{'value': 'pratipedAna', 'label': 'pratipedAna', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'step back, return to'}], 'isLemma': 0, 'parents': ['prati_pad']},
	{'value': 'pratizWamAna', 'label': 'pratizWamAna', 'attrib': [{'pos': 'prmp', 'defs': 'set out'}], 'isLemma': 0, 'parents': ['pra_sTA']},
	{'value': 'pratIcI', 'label': 'pratIcI', 'attrib': [{'pos': 'f', 'defs': 'the west'}], 'isLemma': 1},
	{'value': 'pratepAna', 'label': 'pratepAna', 'attrib': [{'pos': 'pfpp', 'defs': 'heat, burn, shine forth'}], 'isLemma': 0, 'parents': ['pra_tap']},
	{'value': 'pratepivas', 'label': 'pratepivas', 'attrib': [{'pos': 'pfap', 'defs': 'heat, burn, shine forth'}], 'isLemma': 0, 'parents': ['pra_tap']},
	{'value': 'pratyaYc', 'label': 'pratyaYc', 'attrib': [{'pos': 'adj', 'defs': 'turned back or against, western'}], 'isLemma': 1},
	{'value': 'pratyAgacCat', 'label': 'pratyAgacCat', 'attrib': [{'pos': 'prap', 'defs': 'come back, return'}], 'isLemma': 0, 'parents': ['prati_A_gam']},
	{'value': 'pratyAgata', 'label': 'pratyAgata', 'attrib': [{'pos': 'ppp', 'defs': 'come back, return'}], 'isLemma': 0, 'parents': ['prati_A_gam']},
	{'value': 'pratyAgatavat', 'label': 'pratyAgatavat', 'attrib': [{'pos': 'pap', 'defs': 'come back, return'}], 'isLemma': 0, 'parents': ['prati_A_gam']},
	{'value': 'pratyAgatya', 'label': 'pratyAgatya', 'attrib': [{'pos': 'abs', 'defs': 'come back, return'}], 'isLemma': 0, 'parents': ['prati_A_gam']},
	{'value': 'pratyAgantavya', 'label': 'pratyAgantavya', 'attrib': [{'pos': 'ger', 'defs': 'come back, return'}], 'isLemma': 0, 'parents': ['prati_A_gam']},
	{'value': 'pratyAgantum', 'label': 'pratyAgantum', 'attrib': [{'pos': 'inf', 'defs': 'come back, return'}], 'isLemma': 0, 'parents': ['prati_A_gam']},
	{'value': 'pratyAgamanIya', 'label': 'pratyAgamanIya', 'attrib': [{'pos': 'ger', 'defs': 'come back, return'}], 'isLemma': 0, 'parents': ['prati_A_gam']},
	{'value': 'pratyAgamizyat', 'label': 'pratyAgamizyat', 'attrib': [{'pos': 'fap', 'defs': 'come back, return'}], 'isLemma': 0, 'parents': ['prati_A_gam']},
	{'value': 'pratyAgamizyamARa', 'label': 'pratyAgamizyamARa', 'attrib': [{'pos': 'fpp', 'defs': 'come back, return'}], 'isLemma': 0, 'parents': ['prati_A_gam']},
	{'value': 'pratyAgamya', 'label': 'pratyAgamya', 'attrib': [{'pos': 'abs', 'defs': 'come back, return'}, {'pos': 'ger', 'defs': 'come back, return'}], 'isLemma': 0, 'parents': ['prati_A_gam']},
	{'value': 'pratyAgamyamAna', 'label': 'pratyAgamyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'come back, return'}], 'isLemma': 0, 'parents': ['prati_A_gam']},
	{'value': 'pratyAjaganvas', 'label': 'pratyAjaganvas', 'attrib': [{'pos': 'pfap', 'defs': 'come back, return'}], 'isLemma': 0, 'parents': ['prati_A_gam']},
	{'value': 'pratyAjagmAna', 'label': 'pratyAjagmAna', 'attrib': [{'pos': 'pfpp', 'defs': 'come back, return'}], 'isLemma': 0, 'parents': ['prati_A_gam']},
	{'value': 'pratyAjagmivas', 'label': 'pratyAjagmivas', 'attrib': [{'pos': 'pfap', 'defs': 'come back, return'}], 'isLemma': 0, 'parents': ['prati_A_gam']},
	{'value': 'praty_A_gam', 'label': 'praty_A_gam', 'attrib': [{'pos': 'vt1a', 'defs': 'come back, return'}], 'isLemma': 1},
	{'value': 'praT', 'label': 'praT', 'attrib': [{'pos': 'vi1m', 'defs': 'extend, spread'}], 'isLemma': 1},
	{'value': 'praDAna', 'label': 'praDAna', 'attrib': [{'pos': 'adj', 'defs': 'principal'}], 'isLemma': 1},
	{'value': 'prabudDa', 'label': 'prabudDa', 'attrib': [{'pos': 'ppp', 'defs': 'awake, be aware, know'}], 'isLemma': 0, 'parents': ['pra_buD']},
	{'value': 'prabudDavat', 'label': 'prabudDavat', 'attrib': [{'pos': 'pap', 'defs': 'awake, be aware, know'}], 'isLemma': 0, 'parents': ['pra_buD']},
	{'value': 'prabudDvA', 'label': 'prabudDvA', 'attrib': [{'pos': 'abs', 'defs': 'awake, be aware, know'}], 'isLemma': 0, 'parents': ['pra_buD']},
	{'value': 'prabuDya', 'label': 'prabuDya', 'attrib': [{'pos': 'abs', 'defs': 'awake, be aware, know'}], 'isLemma': 0, 'parents': ['pra_buD']},
	{'value': 'prabuDyamAna', 'label': 'prabuDyamAna', 'attrib': [{'pos': 'prmp', 'defs': 'awake, be aware, know'}, {'pos': 'prpp', 'defs': 'awake, be aware, know'}], 'isLemma': 0, 'parents': ['pra_buD']},
	{'value': 'prabubuDAna', 'label': 'prabubuDAna', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'awake, be aware, know'}], 'isLemma': 0, 'parents': ['pra_buD']},
	{'value': 'prabodDavya', 'label': 'prabodDavya', 'attrib': [{'pos': 'ger', 'defs': 'awake, be aware, know'}], 'isLemma': 0, 'parents': ['pra_buD']},
	{'value': 'prabodDum', 'label': 'prabodDum', 'attrib': [{'pos': 'inf', 'defs': 'awake, be aware, know'}], 'isLemma': 0, 'parents': ['pra_buD']},
	{'value': 'praboDanIya', 'label': 'praboDanIya', 'attrib': [{'pos': 'ger', 'defs': 'awake, be aware, know'}], 'isLemma': 0, 'parents': ['pra_buD']},
	{'value': 'praboDya', 'label': 'praboDya', 'attrib': [{'pos': 'ger', 'defs': 'awake, be aware, know'}], 'isLemma': 0, 'parents': ['pra_buD']},
	{'value': 'praBotsyamAna', 'label': 'praBotsyamAna', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'awake, be aware, know'}], 'isLemma': 0, 'parents': ['pra_buD']},
	{'value': 'pramatta', 'label': 'pramatta', 'attrib': [{'pos': 'ppp', 'defs': 'err, be careless'}], 'isLemma': 0, 'parents': ['pra_mad']},
	{'value': 'pramARa', 'label': 'pramARa', 'attrib': [{'pos': 'n', 'defs': 'means of knowledge, measure, standard, authority'}], 'isLemma': 1},
	{'value': 'pramARakowi', 'label': 'pramARakowi', 'attrib': [{'pos': 'f_pn', 'defs': 'Pramāṇakoṭi'}], 'isLemma': 1},
	{'value': 'pravaktavya', 'label': 'pravaktavya', 'attrib': [{'pos': 'ger', 'defs': 'proclaim, declare, tell'}], 'isLemma': 0, 'parents': ['pra_vac']},
	{'value': 'pravaktum', 'label': 'pravaktum', 'attrib': [{'pos': 'inf', 'defs': 'proclaim, declare, tell'}], 'isLemma': 0, 'parents': ['pra_vac']},
	{'value': 'pravakzyat', 'label': 'pravakzyat', 'attrib': [{'pos': 'fap', 'defs': 'proclaim, declare, tell'}], 'isLemma': 0, 'parents': ['pra_vac']},
	{'value': 'pravakzyamARa', 'label': 'pravakzyamARa', 'attrib': [{'pos': 'fpp', 'defs': 'proclaim, declare, tell'}], 'isLemma': 0, 'parents': ['pra_vac']},
	{'value': 'pravacanIya', 'label': 'pravacanIya', 'attrib': [{'pos': 'ger', 'defs': 'proclaim, declare, tell'}], 'isLemma': 0, 'parents': ['pra_vac']},
	{'value': 'pravAkya', 'label': 'pravAkya', 'attrib': [{'pos': 'ger', 'defs': 'proclaim, declare, tell'}], 'isLemma': 0, 'parents': ['pra_vac']},
	{'value': 'prazwavya', 'label': 'prazwavya', 'attrib': [{'pos': 'ger', 'defs': 'ask'}], 'isLemma': 0, 'parents': ['pracC']},
	{'value': 'prazwum', 'label': 'prazwum', 'attrib': [{'pos': 'inf', 'defs': 'ask'}], 'isLemma': 0, 'parents': ['pracC']},
	{'value': 'prasTa', 'label': 'prasTa', 'attrib': [{'pos': 'm, n', 'defs': 'plateau, plain'}], 'isLemma': 1},
	{'value': 'prasTAtavya', 'label': 'prasTAtavya', 'attrib': [{'pos': 'ger', 'defs': 'set out'}], 'isLemma': 0, 'parents': ['pra_sTA']},
	{'value': 'prasTAtum', 'label': 'prasTAtum', 'attrib': [{'pos': 'inf', 'defs': 'set out'}], 'isLemma': 0, 'parents': ['pra_sTA']},
	{'value': 'prasTAnIya', 'label': 'prasTAnIya', 'attrib': [{'pos': 'ger', 'defs': 'set out'}], 'isLemma': 0, 'parents': ['pra_sTA']},
	{'value': 'prasTApayAm', 'label': 'prasTApayAm', 'attrib': [{'pos': 'ppf_actn', 'defs': 'set out'}], 'isLemma': 0, 'parents': ['pra_sTA']},
	{'value': 'prasTAya', 'label': 'prasTAya', 'attrib': [{'pos': 'abs', 'defs': 'set out'}], 'isLemma': 0, 'parents': ['pra_sTA']},
	{'value': 'prasTAsyamAna', 'label': 'prasTAsyamAna', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'set out'}], 'isLemma': 0, 'parents': ['pra_sTA']},
	{'value': 'prasTita', 'label': 'prasTita', 'attrib': [{'pos': 'ppp', 'defs': 'set out'}], 'isLemma': 0, 'parents': ['pra_sTA']},
	{'value': 'prasTitavat', 'label': 'prasTitavat', 'attrib': [{'pos': 'pap', 'defs': 'set out'}], 'isLemma': 0, 'parents': ['pra_sTA']},
	{'value': 'prasTIyamAna', 'label': 'prasTIyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'set out'}], 'isLemma': 0, 'parents': ['pra_sTA']},
	{'value': 'prasTeya', 'label': 'prasTeya', 'attrib': [{'pos': 'ger', 'defs': 'set out'}], 'isLemma': 0, 'parents': ['pra_sTA']},
	{'value': 'prAk', 'label': 'prAk', 'attrib': [{'pos': 'adv', 'defs': 'before, first'}], 'isLemma': 1},
	{'value': 'prAcI', 'label': 'prAcI', 'attrib': [{'pos': 'f', 'defs': 'the east'}], 'isLemma': 1},
	{'value': 'prAYc', 'label': 'prAYc', 'attrib': [{'pos': 'adj', 'defs': 'turned or being forwards, front, eastward, eastern, before'}], 'isLemma': 1},
	{'value': 'prApaRIya', 'label': 'prApaRIya', 'attrib': [{'pos': 'ger', 'defs': 'attain, obtain'}], 'isLemma': 0, 'parents': ['pra_Ap']},
	{'value': 'prApARa', 'label': 'prApARa', 'attrib': [{'pos': 'pfpp', 'defs': 'attain, obtain'}], 'isLemma': 0, 'parents': ['pra_Ap']},
	{'value': 'prApivas', 'label': 'prApivas', 'attrib': [{'pos': 'pfap', 'defs': 'attain, obtain'}], 'isLemma': 0, 'parents': ['pra_Ap']},
	{'value': 'prApta', 'label': 'prApta', 'attrib': [{'pos': 'ppp', 'defs': 'attain, obtain'}], 'isLemma': 0, 'parents': ['pra_Ap']},
	{'value': 'prAptavat', 'label': 'prAptavat', 'attrib': [{'pos': 'pap', 'defs': 'attain, obtain'}], 'isLemma': 0, 'parents': ['pra_Ap']},
	{'value': 'prAptavya', 'label': 'prAptavya', 'attrib': [{'pos': 'ger', 'defs': 'attain, obtain'}], 'isLemma': 0, 'parents': ['pra_Ap']},
	{'value': 'prAptum', 'label': 'prAptum', 'attrib': [{'pos': 'inf', 'defs': 'attain, obtain'}], 'isLemma': 0, 'parents': ['pra_Ap']},
	{'value': 'prApnuvat', 'label': 'prApnuvat', 'attrib': [{'pos': 'prap', 'defs': 'attain, obtain'}], 'isLemma': 0, 'parents': ['pra_Ap']},
	{'value': 'prApya', 'label': 'prApya', 'attrib': [{'pos': 'abs', 'defs': 'attain, obtain'}, {'pos': 'ger', 'defs': 'attain, obtain'}], 'isLemma': 0, 'parents': ['pra_Ap']},
	{'value': 'prApyamAna', 'label': 'prApyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'attain, obtain'}], 'isLemma': 0, 'parents': ['pra_Ap']},
	{'value': 'prApsyat', 'label': 'prApsyat', 'attrib': [{'pos': 'fap', 'defs': 'attain, obtain'}], 'isLemma': 0, 'parents': ['pra_Ap']},
	{'value': 'prApsyamAna', 'label': 'prApsyamAna', 'attrib': [{'pos': 'fpp', 'defs': 'attain, obtain'}], 'isLemma': 0, 'parents': ['pra_Ap']},
	{'value': 'prI', 'label': 'prI', 'attrib': [{'pos': 'vt9am', 'defs': 'please'}, {'pos': 'vi4m', 'defs': 'be pleased'}], 'isLemma': 1},
	{'value': 'prIta', 'label': 'prIta', 'attrib': [{'pos': 'ppp', 'defs': 'be pleased'}], 'isLemma': 0, 'parents': ['prI']},
	{'value': 'prokta', 'label': 'prokta', 'attrib': [{'pos': 'ppp', 'defs': 'proclaim, declare, tell'}], 'isLemma': 0, 'parents': ['pra_vac']},
	{'value': 'proktavat', 'label': 'proktavat', 'attrib': [{'pos': 'pap', 'defs': 'proclaim, declare, tell'}], 'isLemma': 0, 'parents': ['pra_vac']},
	{'value': 'proktvA', 'label': 'proktvA', 'attrib': [{'pos': 'abs', 'defs': 'proclaim, declare, tell'}], 'isLemma': 0, 'parents': ['pra_vac']},
	{'value': 'procAna', 'label': 'procAna', 'attrib': [{'pos': 'pfpp', 'defs': 'proclaim, declare, tell'}], 'isLemma': 0, 'parents': ['pra_vac']},
	{'value': 'procivas', 'label': 'procivas', 'attrib': [{'pos': 'pfap', 'defs': 'proclaim, declare, tell'}], 'isLemma': 0, 'parents': ['pra_vac']},
	{'value': 'procya', 'label': 'procya', 'attrib': [{'pos': 'abs', 'defs': 'proclaim, declare, tell'}], 'isLemma': 0, 'parents': ['pra_vac']},
	{'value': 'procyamAna', 'label': 'procyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'proclaim, declare, tell'}], 'isLemma': 0, 'parents': ['pra_vac']},
	{'value': 'baka', 'label': 'baka', 'attrib': [{'pos': 'm', 'defs': 'heron, a cheat'}, {'pos': 'm_pn', 'defs': 'Baka'}], 'isLemma': 1},
	{'value': 'badDa', 'label': 'badDa', 'attrib': [{'pos': 'ppp', 'defs': 'bind'}], 'isLemma': 0, 'parents': ['banD']},
	{'value': 'badDavat', 'label': 'badDavat', 'attrib': [{'pos': 'pap', 'defs': 'bind'}], 'isLemma': 0, 'parents': ['banD']},
	{'value': 'badDvA', 'label': 'badDvA', 'attrib': [{'pos': 'abs', 'defs': 'bind'}], 'isLemma': 0, 'parents': ['banD']},
	{'value': 'baDnat', 'label': 'baDnat', 'attrib': [{'pos': 'prap', 'defs': 'bind'}], 'isLemma': 0, 'parents': ['banD']},
	{'value': 'baDyamAna', 'label': 'baDyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'bind'}], 'isLemma': 0, 'parents': ['banD']},
	{'value': 'bandDavya', 'label': 'bandDavya', 'attrib': [{'pos': 'ger', 'defs': 'bind'}], 'isLemma': 0, 'parents': ['banD']},
	{'value': 'bandDum', 'label': 'bandDum', 'attrib': [{'pos': 'inf', 'defs': 'bind'}], 'isLemma': 0, 'parents': ['banD']},
	{'value': 'banD', 'label': 'banD', 'attrib': [{'pos': 'vt9am', 'defs': 'bind'}], 'isLemma': 1},
	{'value': 'banDana', 'label': 'banDana', 'attrib': [{'pos': 'n', 'defs': 'binding, bond'}], 'isLemma': 1},
	{'value': 'banDanIya', 'label': 'banDanIya', 'attrib': [{'pos': 'ger', 'defs': 'bind'}], 'isLemma': 0, 'parents': ['banD']},
	{'value': 'banDya', 'label': 'banDya', 'attrib': [{'pos': 'ger', 'defs': 'bind'}], 'isLemma': 0, 'parents': ['banD']},
	{'value': 'babanDAna', 'label': 'babanDAna', 'attrib': [{'pos': 'pfpp', 'defs': 'bind'}], 'isLemma': 0, 'parents': ['banD']},
	{'value': 'babanDvas', 'label': 'babanDvas', 'attrib': [{'pos': 'pfap', 'defs': 'bind'}], 'isLemma': 0, 'parents': ['banD']},
	{'value': 'baBAna', 'label': 'baBAna', 'attrib': [{'pos': 'pfpp', 'defs': 'shine, appear'}], 'isLemma': 0, 'parents': ['BA']},
	{'value': 'baBAzARa', 'label': 'baBAzARa', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'speak, say'}], 'isLemma': 0, 'parents': ['BAz']},
	{'value': 'baBivas', 'label': 'baBivas', 'attrib': [{'pos': 'pfap', 'defs': 'shine, appear'}], 'isLemma': 0, 'parents': ['BA']},
	{'value': 'baBUvas', 'label': 'baBUvas', 'attrib': [{'pos': 'pfap', 'defs': 'be, become'}], 'isLemma': 0, 'parents': ['BU']},
	{'value': 'baBfvas', 'label': 'baBfvas', 'attrib': [{'pos': 'pfap', 'defs': 'bear'}], 'isLemma': 0, 'parents': ['Bf']},
	{'value': 'baBrARa', 'label': 'baBrARa', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'bear'}], 'isLemma': 0, 'parents': ['Bf']},
	{'value': 'bala', 'label': 'bala', 'attrib': [{'pos': 'n', 'defs': 'strength'}], 'isLemma': 1},
	{'value': 'bahu', 'label': 'bahu', 'attrib': [{'pos': 'adj', 'defs': 'much, many'}], 'isLemma': 1},
	{'value': 'bARa', 'label': 'bARa', 'attrib': [{'pos': 'm', 'defs': 'reed, arrow'}], 'isLemma': 1},
	{'value': 'bAD', 'label': 'bAD', 'attrib': [{'pos': 'vt1', 'defs': 'press, harass'}], 'isLemma': 1},
	{'value': 'biBayAm', 'label': 'biBayAm', 'attrib': [{'pos': 'ppf_actn', 'defs': 'fear'}], 'isLemma': 0, 'parents': ['BI']},
	{'value': 'biBarAm', 'label': 'biBarAm', 'attrib': [{'pos': 'ppf_actn', 'defs': 'bear'}], 'isLemma': 0, 'parents': ['Bf']},
	{'value': 'biBidAna', 'label': 'biBidAna', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'split'}], 'isLemma': 0, 'parents': ['Bid']},
	{'value': 'biBidvas', 'label': 'biBidvas', 'attrib': [{'pos': 'pfap', 'defs': 'split'}], 'isLemma': 0, 'parents': ['Bid']},
	{'value': 'biBIvas', 'label': 'biBIvas', 'attrib': [{'pos': 'pfap', 'defs': 'fear'}], 'isLemma': 0, 'parents': ['BI']},
	{'value': 'biByat', 'label': 'biByat', 'attrib': [{'pos': 'prap', 'defs': 'fear'}], 'isLemma': 0, 'parents': ['BI']},
	{'value': 'biByAna', 'label': 'biByAna', 'attrib': [{'pos': 'pfpp', 'defs': 'fear'}], 'isLemma': 0, 'parents': ['BI']},
	{'value': 'biBrat', 'label': 'biBrat', 'attrib': [{'pos': 'prap', 'defs': 'bear'}], 'isLemma': 0, 'parents': ['Bf']},
	{'value': 'biBrARa', 'label': 'biBrARa', 'attrib': [{'pos': 'prmp', 'defs': 'bear'}], 'isLemma': 0, 'parents': ['Bf']},
	{'value': 'bIBatsu', 'label': 'bIBatsu', 'attrib': [{'pos': 'adj', 'defs': 'despising, loathsome, reserved, coy'}], 'isLemma': 1},
	{'value': 'budDa', 'label': 'budDa', 'attrib': [{'pos': 'ppp', 'defs': 'be aware, know'}], 'isLemma': 0, 'parents': ['buD']},
	{'value': 'budDavat', 'label': 'budDavat', 'attrib': [{'pos': 'pap', 'defs': 'be aware, know'}], 'isLemma': 0, 'parents': ['buD']},
	{'value': 'budDi', 'label': 'budDi', 'attrib': [{'pos': 'f', 'defs': 'intelligence'}], 'isLemma': 1},
	{'value': 'budDvA', 'label': 'budDvA', 'attrib': [{'pos': 'abs', 'defs': 'be aware, know'}], 'isLemma': 0, 'parents': ['buD']},
	{'value': 'buD', 'label': 'buD', 'attrib': [{'pos': 'vt1am', 'defs': 'know'}, {'pos': 'vt4m', 'defs': 'be aware, know'}], 'isLemma': 1},
	{'value': 'buDita', 'label': 'buDita', 'attrib': [{'pos': 'ppp', 'defs': 'know'}], 'isLemma': 0, 'parents': ['buD']},
	{'value': 'buDitavat', 'label': 'buDitavat', 'attrib': [{'pos': 'pap', 'defs': 'know'}], 'isLemma': 0, 'parents': ['buD']},
	{'value': 'buDitvA', 'label': 'buDitvA', 'attrib': [{'pos': 'abs', 'defs': 'know'}], 'isLemma': 0, 'parents': ['buD']},
	{'value': 'buDyamAna', 'label': 'buDyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'know'}, {'pos': 'prmp', 'defs': 'be aware, know'}, {'pos': 'prpp', 'defs': 'be aware, know'}], 'isLemma': 0, 'parents': ['buD']},
	{'value': 'bubuDAna', 'label': 'bubuDAna', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'know'}, {'pos': 'pfmp, pfpp', 'defs': 'be aware, know'}], 'isLemma': 0, 'parents': ['buD']},
	{'value': 'bubuDvas', 'label': 'bubuDvas', 'attrib': [{'pos': 'pfap', 'defs': 'know'}], 'isLemma': 0, 'parents': ['buD']},
	{'value': 'bodDavya', 'label': 'bodDavya', 'attrib': [{'pos': 'ger', 'defs': 'be aware, know'}], 'isLemma': 0, 'parents': ['buD']},
	{'value': 'bodDum', 'label': 'bodDum', 'attrib': [{'pos': 'inf', 'defs': 'be aware, know'}], 'isLemma': 0, 'parents': ['buD']},
	{'value': 'boDat', 'label': 'boDat', 'attrib': [{'pos': 'prap', 'defs': 'know'}], 'isLemma': 0, 'parents': ['buD']},
	{'value': 'boDanIya', 'label': 'boDanIya', 'attrib': [{'pos': 'ger', 'defs': 'know'}, {'pos': 'ger', 'defs': 'be aware, know'}], 'isLemma': 0, 'parents': ['buD']},
	{'value': 'boDamAna', 'label': 'boDamAna', 'attrib': [{'pos': 'prmp', 'defs': 'know'}], 'isLemma': 0, 'parents': ['buD']},
	{'value': 'boDitavya', 'label': 'boDitavya', 'attrib': [{'pos': 'ger', 'defs': 'know'}], 'isLemma': 0, 'parents': ['buD']},
	{'value': 'boDitum', 'label': 'boDitum', 'attrib': [{'pos': 'inf', 'defs': 'know'}], 'isLemma': 0, 'parents': ['buD']},
	{'value': 'boDitvA', 'label': 'boDitvA', 'attrib': [{'pos': 'abs', 'defs': 'know'}], 'isLemma': 0, 'parents': ['buD']},
	{'value': 'boDya', 'label': 'boDya', 'attrib': [{'pos': 'ger', 'defs': 'know'}, {'pos': 'ger', 'defs': 'be aware, know'}], 'isLemma': 0, 'parents': ['buD']},
	{'value': 'brahman', 'label': 'brahman', 'attrib': [{'pos': 'm', 'defs': 'a brāhmaṇa'}], 'isLemma': 1},
	{'value': 'brahmarUpa', 'label': 'brahmarUpa', 'attrib': [{'pos': 'n', 'defs': 'appearance of a brahmin'}], 'isLemma': 1},
	{'value': 'brahmarUpaDara', 'label': 'brahmarUpaDara', 'attrib': [{'pos': 'adj', 'defs': 'bearing the appearance of a brahmin'}], 'isLemma': 1},
	{'value': 'brAhmaRa', 'label': 'brAhmaRa', 'attrib': [{'pos': 'm', 'defs': 'a brahmin'}], 'isLemma': 1},
	{'value': 'brAhmaRArTa', 'label': 'brAhmaRArTa', 'attrib': [{'pos': 'm', 'defs': 'the purpose of a brāhmaṇa'}], 'isLemma': 1},
	{'value': 'Bakta', 'label': 'Bakta', 'attrib': [{'pos': 'ppp', 'defs': 'partake of, share, love, serve'}], 'isLemma': 0, 'parents': ['Baj']},
	{'value': 'Baktavat', 'label': 'Baktavat', 'attrib': [{'pos': 'pap', 'defs': 'partake of, share, love, serve'}], 'isLemma': 0, 'parents': ['Baj']},
	{'value': 'Baktavya', 'label': 'Baktavya', 'attrib': [{'pos': 'ger', 'defs': 'partake of, share, love, serve'}], 'isLemma': 0, 'parents': ['Baj']},
	{'value': 'Baktum', 'label': 'Baktum', 'attrib': [{'pos': 'inf', 'defs': 'partake of, share, love, serve'}], 'isLemma': 0, 'parents': ['Baj']},
	{'value': 'Bakzyat', 'label': 'Bakzyat', 'attrib': [{'pos': 'fap', 'defs': 'partake of, share, love, serve'}], 'isLemma': 0, 'parents': ['Baj']},
	{'value': 'BakzyamAna', 'label': 'BakzyamAna', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'partake of, share, love, serve'}], 'isLemma': 0, 'parents': ['Baj']},
	{'value': 'Baj', 'label': 'Baj', 'attrib': [{'pos': 'vt1am', 'defs': 'partake of, share, love, serve'}], 'isLemma': 1},
	{'value': 'Bajat', 'label': 'Bajat', 'attrib': [{'pos': 'prap', 'defs': 'partake of, share, love, serve'}], 'isLemma': 0, 'parents': ['Baj']},
	{'value': 'BajanIya', 'label': 'BajanIya', 'attrib': [{'pos': 'ger', 'defs': 'partake of, share, love, serve'}], 'isLemma': 0, 'parents': ['Baj']},
	{'value': 'BajamAna', 'label': 'BajamAna', 'attrib': [{'pos': 'prmp', 'defs': 'partake of, share, love, serve'}], 'isLemma': 0, 'parents': ['Baj']},
	{'value': 'Bajya', 'label': 'Bajya', 'attrib': [{'pos': 'ger', 'defs': 'partake of, share, love, serve'}], 'isLemma': 0, 'parents': ['Baj']},
	{'value': 'BajyamAna', 'label': 'BajyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'partake of, share, love, serve'}], 'isLemma': 0, 'parents': ['Baj']},
	{'value': 'Badra', 'label': 'Badra', 'attrib': [{'pos': 'adj', 'defs': 'blessed, good'}, {'pos': 'n', 'defs': 'welfare'}], 'isLemma': 1},
	{'value': 'BadraBAzin', 'label': 'BadraBAzin', 'attrib': [{'pos': 'adj', 'defs': 'speaking what is auspicious'}], 'isLemma': 1},
	{'value': 'Bantsyat', 'label': 'Bantsyat', 'attrib': [{'pos': 'fap', 'defs': 'bind'}], 'isLemma': 0, 'parents': ['banD']},
	{'value': 'BantsyamAna', 'label': 'BantsyamAna', 'attrib': [{'pos': 'fpp', 'defs': 'bind'}], 'isLemma': 0, 'parents': ['banD']},
	{'value': 'Baya', 'label': 'Baya', 'attrib': [{'pos': 'n', 'defs': 'fear'}], 'isLemma': 1},
	{'value': 'BayanIya', 'label': 'BayanIya', 'attrib': [{'pos': 'ger', 'defs': 'fear'}], 'isLemma': 0, 'parents': ['BI']},
	{'value': 'Bara', 'label': 'Bara', 'attrib': [{'pos': 'adj', 'defs': 'bearing'}], 'isLemma': 1},
	{'value': 'BaraRIya', 'label': 'BaraRIya', 'attrib': [{'pos': 'ger', 'defs': 'bear'}], 'isLemma': 0, 'parents': ['Bf']},
	{'value': 'Barata', 'label': 'Barata', 'attrib': [{'pos': 'm_pn', 'defs': 'Bharata'}], 'isLemma': 1},
	{'value': 'BaratarzaBa', 'label': 'BaratarzaBa', 'attrib': [{'pos': 'm', 'defs': 'bull-like descendant of Bharata'}], 'isLemma': 1},
	{'value': 'Barizyat', 'label': 'Barizyat', 'attrib': [{'pos': 'fap', 'defs': 'bear'}], 'isLemma': 0, 'parents': ['Bf']},
	{'value': 'BarizyamARa', 'label': 'BarizyamARa', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'bear'}], 'isLemma': 0, 'parents': ['Bf']},
	{'value': 'Bartavya', 'label': 'Bartavya', 'attrib': [{'pos': 'ger', 'defs': 'bear'}], 'isLemma': 0, 'parents': ['Bf']},
	{'value': 'Bartum', 'label': 'Bartum', 'attrib': [{'pos': 'inf', 'defs': 'bear'}], 'isLemma': 0, 'parents': ['Bf']},
	{'value': 'Bavat', 'label': 'Bavat', 'attrib': [{'pos': 'prap', 'defs': 'be, become'}], 'isLemma': 0, 'parents': ['BU']},
	{'value': 'BavanIya', 'label': 'BavanIya', 'attrib': [{'pos': 'ger', 'defs': 'be, become'}], 'isLemma': 0, 'parents': ['BU']},
	{'value': 'Bavitavya', 'label': 'Bavitavya', 'attrib': [{'pos': 'ger', 'defs': 'be, become'}], 'isLemma': 0, 'parents': ['BU']},
	{'value': 'Bavitum', 'label': 'Bavitum', 'attrib': [{'pos': 'inf', 'defs': 'be, become'}], 'isLemma': 0, 'parents': ['BU']},
	{'value': 'Bavizyat', 'label': 'Bavizyat', 'attrib': [{'pos': 'fap', 'defs': 'be, become'}], 'isLemma': 0, 'parents': ['BU']},
	{'value': 'BavizyamARa', 'label': 'BavizyamARa', 'attrib': [{'pos': 'fpp', 'defs': 'be, become'}], 'isLemma': 0, 'parents': ['BU']},
	{'value': 'Bavya', 'label': 'Bavya', 'attrib': [{'pos': 'ger', 'defs': 'be, become'}], 'isLemma': 0, 'parents': ['BU']},
	{'value': 'BA', 'label': 'BA', 'attrib': [{'pos': 'vi2a', 'defs': 'shine, appear'}], 'isLemma': 1},
	{'value': 'BAktvA', 'label': 'BAktvA', 'attrib': [{'pos': 'abs', 'defs': 'partake of, share, love, serve'}], 'isLemma': 0, 'parents': ['Baj']},
	{'value': 'BAt', 'label': 'BAt', 'attrib': [{'pos': 'prap', 'defs': 'shine, appear'}], 'isLemma': 0, 'parents': ['BA']},
	{'value': 'BAta', 'label': 'BAta', 'attrib': [{'pos': 'ppp', 'defs': 'shine, appear'}], 'isLemma': 0, 'parents': ['BA']},
	{'value': 'BAtavat', 'label': 'BAtavat', 'attrib': [{'pos': 'pap', 'defs': 'shine, appear'}], 'isLemma': 0, 'parents': ['BA']},
	{'value': 'BAtavya', 'label': 'BAtavya', 'attrib': [{'pos': 'ger', 'defs': 'shine, appear'}], 'isLemma': 0, 'parents': ['BA']},
	{'value': 'BAtum', 'label': 'BAtum', 'attrib': [{'pos': 'inf', 'defs': 'shine, appear'}], 'isLemma': 0, 'parents': ['BA']},
	{'value': 'BAnIya', 'label': 'BAnIya', 'attrib': [{'pos': 'ger', 'defs': 'shine, appear'}], 'isLemma': 0, 'parents': ['BA']},
	{'value': 'BAyamAna', 'label': 'BAyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'shine, appear'}], 'isLemma': 0, 'parents': ['BA']},
	{'value': 'BAra', 'label': 'BAra', 'attrib': [{'pos': 'm', 'defs': 'burden'}], 'isLemma': 1},
	{'value': 'BArata', 'label': 'BArata', 'attrib': [{'pos': 'm', 'defs': 'descendant of Bharata'}, {'pos': 'adj', 'defs': 'the war between the descendants of Bharata'}], 'isLemma': 1},
	{'value': 'BAryA', 'label': 'BAryA', 'attrib': [{'pos': 'f', 'defs': 'wife'}], 'isLemma': 1},
	{'value': 'BAvin', 'label': 'BAvin', 'attrib': [{'pos': 'adj', 'defs': 'future'}], 'isLemma': 1},
	{'value': 'BAvyarTa', 'label': 'BAvyarTa', 'attrib': [{'pos': 'm', 'defs': 'future purpose'}], 'isLemma': 1},
	{'value': 'BAvyarTarakzita', 'label': 'BAvyarTarakzita', 'attrib': [{'pos': 'adj', 'defs': 'protected for future purposes'}], 'isLemma': 1},
	{'value': 'BAz', 'label': 'BAz', 'attrib': [{'pos': 'vt1m', 'defs': 'speak, say'}], 'isLemma': 1},
	{'value': 'BAzaRIya', 'label': 'BAzaRIya', 'attrib': [{'pos': 'ger', 'defs': 'speak, say'}], 'isLemma': 0, 'parents': ['BAz']},
	{'value': 'BAzamARa', 'label': 'BAzamARa', 'attrib': [{'pos': 'prmp', 'defs': 'speak, say'}], 'isLemma': 0, 'parents': ['BAz']},
	{'value': 'BAzita', 'label': 'BAzita', 'attrib': [{'pos': 'ppp', 'defs': 'speak, say'}], 'isLemma': 0, 'parents': ['BAz']},
	{'value': 'BAzitavat', 'label': 'BAzitavat', 'attrib': [{'pos': 'pap', 'defs': 'speak, say'}], 'isLemma': 0, 'parents': ['BAz']},
	{'value': 'BAzitavya', 'label': 'BAzitavya', 'attrib': [{'pos': 'ger', 'defs': 'speak, say'}], 'isLemma': 0, 'parents': ['BAz']},
	{'value': 'BAzitum', 'label': 'BAzitum', 'attrib': [{'pos': 'inf', 'defs': 'speak, say'}], 'isLemma': 0, 'parents': ['BAz']},
	{'value': 'BAzitvA', 'label': 'BAzitvA', 'attrib': [{'pos': 'abs', 'defs': 'speak, say'}], 'isLemma': 0, 'parents': ['BAz']},
	{'value': 'BAzin', 'label': 'BAzin', 'attrib': [{'pos': 'adj', 'defs': 'speaking'}], 'isLemma': 1},
	{'value': 'BAzizyamARa', 'label': 'BAzizyamARa', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'speak, say'}], 'isLemma': 0, 'parents': ['BAz']},
	{'value': 'BAzya', 'label': 'BAzya', 'attrib': [{'pos': 'ger', 'defs': 'speak, say'}], 'isLemma': 0, 'parents': ['BAz']},
	{'value': 'BAzyamARa', 'label': 'BAzyamARa', 'attrib': [{'pos': 'prpp', 'defs': 'speak, say'}], 'isLemma': 0, 'parents': ['BAz']},
	{'value': 'BAsyat', 'label': 'BAsyat', 'attrib': [{'pos': 'fap', 'defs': 'shine, appear'}], 'isLemma': 0, 'parents': ['BA']},
	{'value': 'BAsyamAna', 'label': 'BAsyamAna', 'attrib': [{'pos': 'fpp', 'defs': 'shine, appear'}], 'isLemma': 0, 'parents': ['BA']},
	{'value': 'BittvA', 'label': 'BittvA', 'attrib': [{'pos': 'abs', 'defs': 'split'}], 'isLemma': 0, 'parents': ['Bid']},
	{'value': 'Bid', 'label': 'Bid', 'attrib': [{'pos': 'vt7am', 'defs': 'split'}], 'isLemma': 1},
	{'value': 'BidyamAna', 'label': 'BidyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'split'}], 'isLemma': 0, 'parents': ['Bid']},
	{'value': 'Bindat', 'label': 'Bindat', 'attrib': [{'pos': 'prap', 'defs': 'split'}], 'isLemma': 0, 'parents': ['Bid']},
	{'value': 'BindAna', 'label': 'BindAna', 'attrib': [{'pos': 'prmp', 'defs': 'split'}], 'isLemma': 0, 'parents': ['Bid']},
	{'value': 'Binna', 'label': 'Binna', 'attrib': [{'pos': 'ppp', 'defs': 'split'}], 'isLemma': 0, 'parents': ['Bid']},
	{'value': 'Binnavat', 'label': 'Binnavat', 'attrib': [{'pos': 'pap', 'defs': 'split'}], 'isLemma': 0, 'parents': ['Bid']},
	{'value': 'BI', 'label': 'BI', 'attrib': [{'pos': 'vi3a', 'defs': 'fear'}], 'isLemma': 1},
	{'value': 'BIta', 'label': 'BIta', 'attrib': [{'pos': 'ppp', 'defs': 'fear'}], 'isLemma': 0, 'parents': ['BI']},
	{'value': 'BItavat', 'label': 'BItavat', 'attrib': [{'pos': 'pap', 'defs': 'fear'}], 'isLemma': 0, 'parents': ['BI']},
	{'value': 'BItvA', 'label': 'BItvA', 'attrib': [{'pos': 'abs', 'defs': 'fear'}], 'isLemma': 0, 'parents': ['BI']},
	{'value': 'BIma', 'label': 'BIma', 'attrib': [{'pos': 'adj', 'defs': 'frightful, terrifying'}, {'pos': 'm_pn', 'defs': 'Bhīma'}], 'isLemma': 1},
	{'value': 'BImaparAkrama', 'label': 'BImaparAkrama', 'attrib': [{'pos': 'adj', 'defs': 'terrifyingly bold'}], 'isLemma': 1},
	{'value': 'BImasena', 'label': 'BImasena', 'attrib': [{'pos': 'adj', 'defs': 'having a terrifying army'}], 'isLemma': 1},
	{'value': 'BIyamAna', 'label': 'BIyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'fear'}], 'isLemma': 0, 'parents': ['BI']},
	{'value': 'BIzma', 'label': 'BIzma', 'attrib': [{'pos': 'adj', 'defs': 'terrifying'}, {'pos': 'm_pn', 'defs': 'Bhīṣma'}], 'isLemma': 1},
	{'value': 'BU', 'label': 'BU', 'attrib': [{'pos': 'vi1a', 'defs': 'be, become'}, {'pos': 'f', 'defs': 'earth'}], 'isLemma': 1},
	{'value': 'BUta', 'label': 'BUta', 'attrib': [{'pos': 'ppp', 'defs': 'be, become'}], 'isLemma': 0, 'parents': ['BU']},
	{'value': 'BUtavat', 'label': 'BUtavat', 'attrib': [{'pos': 'pap', 'defs': 'be, become'}], 'isLemma': 0, 'parents': ['BU']},
	{'value': 'BUtvA', 'label': 'BUtvA', 'attrib': [{'pos': 'abs', 'defs': 'be, become'}], 'isLemma': 0, 'parents': ['BU']},
	{'value': 'BUyamAna', 'label': 'BUyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'be, become'}], 'isLemma': 0, 'parents': ['BU']},
	{'value': 'BUyas', 'label': 'BUyas', 'attrib': [{'pos': 'comparative_adj', 'defs': 'more'}], 'isLemma': 1},
	{'value': 'BUyizWa', 'label': 'BUyizWa', 'attrib': [{'pos': 'superlative_adj', 'defs': 'most'}], 'isLemma': 1},
	{'value': 'Bf', 'label': 'Bf', 'attrib': [{'pos': 'vt3am', 'defs': 'bear'}], 'isLemma': 1},
	{'value': 'Bfta', 'label': 'Bfta', 'attrib': [{'pos': 'ppp', 'defs': 'bear'}], 'isLemma': 0, 'parents': ['Bf']},
	{'value': 'Bftavat', 'label': 'Bftavat', 'attrib': [{'pos': 'pap', 'defs': 'bear'}], 'isLemma': 0, 'parents': ['Bf']},
	{'value': 'Bftya', 'label': 'Bftya', 'attrib': [{'pos': 'ger', 'defs': 'bear'}], 'isLemma': 0, 'parents': ['Bf']},
	{'value': 'BftvA', 'label': 'BftvA', 'attrib': [{'pos': 'abs', 'defs': 'bear'}], 'isLemma': 0, 'parents': ['Bf']},
	{'value': 'BejAna', 'label': 'BejAna', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'partake of, share, love, serve'}], 'isLemma': 0, 'parents': ['Baj']},
	{'value': 'Bejivas', 'label': 'Bejivas', 'attrib': [{'pos': 'pfap', 'defs': 'partake of, share, love, serve'}], 'isLemma': 0, 'parents': ['Baj']},
	{'value': 'Betavya', 'label': 'Betavya', 'attrib': [{'pos': 'ger', 'defs': 'fear'}], 'isLemma': 0, 'parents': ['BI']},
	{'value': 'Betum', 'label': 'Betum', 'attrib': [{'pos': 'inf', 'defs': 'fear'}], 'isLemma': 0, 'parents': ['BI']},
	{'value': 'Bettavya', 'label': 'Bettavya', 'attrib': [{'pos': 'ger', 'defs': 'split'}], 'isLemma': 0, 'parents': ['Bid']},
	{'value': 'Bettum', 'label': 'Bettum', 'attrib': [{'pos': 'inf', 'defs': 'split'}], 'isLemma': 0, 'parents': ['Bid']},
	{'value': 'Betsyat', 'label': 'Betsyat', 'attrib': [{'pos': 'fap', 'defs': 'split'}], 'isLemma': 0, 'parents': ['Bid']},
	{'value': 'BetsyamAna', 'label': 'BetsyamAna', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'split'}], 'isLemma': 0, 'parents': ['Bid']},
	{'value': 'Beda', 'label': 'Beda', 'attrib': [{'pos': 'm', 'defs': 'split, division, breach'}], 'isLemma': 1},
	{'value': 'BedanIya', 'label': 'BedanIya', 'attrib': [{'pos': 'ger', 'defs': 'split'}], 'isLemma': 0, 'parents': ['Bid']},
	{'value': 'Bedya', 'label': 'Bedya', 'attrib': [{'pos': 'ger', 'defs': 'split'}], 'isLemma': 0, 'parents': ['Bid']},
	{'value': 'Beya', 'label': 'Beya', 'attrib': [{'pos': 'ger', 'defs': 'fear'}], 'isLemma': 0, 'parents': ['BI']},
	{'value': 'Bezyat', 'label': 'Bezyat', 'attrib': [{'pos': 'fap', 'defs': 'fear'}], 'isLemma': 0, 'parents': ['BI']},
	{'value': 'BezyamARa', 'label': 'BezyamARa', 'attrib': [{'pos': 'fpp', 'defs': 'fear'}], 'isLemma': 0, 'parents': ['BI']},
	{'value': 'Botsyat', 'label': 'Botsyat', 'attrib': [{'pos': 'fap', 'defs': 'know'}], 'isLemma': 0, 'parents': ['buD']},
	{'value': 'BotsyamAna', 'label': 'BotsyamAna', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'know'}, {'pos': 'fmp, fpp', 'defs': 'be aware, know'}], 'isLemma': 0, 'parents': ['buD']},
	{'value': 'BrAtf', 'label': 'BrAtf', 'attrib': [{'pos': 'm', 'defs': 'brother'}], 'isLemma': 1},
	{'value': 'BriyamARa', 'label': 'BriyamARa', 'attrib': [{'pos': 'prpp', 'defs': 'bear'}], 'isLemma': 0, 'parents': ['Bf']},
	{'value': 'maMsyamAna', 'label': 'maMsyamAna', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'think'}], 'isLemma': 0, 'parents': ['man']},
	{'value': 'mata', 'label': 'mata', 'attrib': [{'pos': 'n', 'defs': 'thought'}, {'pos': 'ppp', 'defs': 'think'}], 'isLemma': 1},
	{'value': 'mata', 'label': 'mata', 'attrib': [{'pos': 'n', 'defs': 'thought'}, {'pos': 'ppp', 'defs': 'think'}], 'isLemma': 0, 'parents': ['man']},
	{'value': 'matavat', 'label': 'matavat', 'attrib': [{'pos': 'pap', 'defs': 'think'}], 'isLemma': 0, 'parents': ['man']},
	{'value': 'mati', 'label': 'mati', 'attrib': [{'pos': 'f', 'defs': 'thought'}], 'isLemma': 1},
	{'value': 'matta', 'label': 'matta', 'attrib': [{'pos': 'ppp', 'defs': 'rejoice'}], 'isLemma': 0, 'parents': ['mad']},
	{'value': 'mattavat', 'label': 'mattavat', 'attrib': [{'pos': 'pap', 'defs': 'rejoice'}], 'isLemma': 0, 'parents': ['mad']},
	{'value': 'matvA', 'label': 'matvA', 'attrib': [{'pos': 'abs', 'defs': 'think'}], 'isLemma': 0, 'parents': ['man']},
	{'value': 'mad', 'label': 'mad', 'attrib': [{'pos': 'pers_pron', 'defs': 'I, me'}, {'pos': 'vi4a', 'defs': 'rejoice'}], 'isLemma': 1},
	{'value': 'madanIya', 'label': 'madanIya', 'attrib': [{'pos': 'ger', 'defs': 'rejoice'}], 'isLemma': 0, 'parents': ['mad']},
	{'value': 'maditavya', 'label': 'maditavya', 'attrib': [{'pos': 'ger', 'defs': 'rejoice'}], 'isLemma': 0, 'parents': ['mad']},
	{'value': 'maditum', 'label': 'maditum', 'attrib': [{'pos': 'inf', 'defs': 'rejoice'}], 'isLemma': 0, 'parents': ['mad']},
	{'value': 'maditvA', 'label': 'maditvA', 'attrib': [{'pos': 'abs', 'defs': 'rejoice'}], 'isLemma': 0, 'parents': ['mad']},
	{'value': 'madizyat', 'label': 'madizyat', 'attrib': [{'pos': 'fap', 'defs': 'rejoice'}], 'isLemma': 0, 'parents': ['mad']},
	{'value': 'madizyamARa', 'label': 'madizyamARa', 'attrib': [{'pos': 'fpp', 'defs': 'rejoice'}], 'isLemma': 0, 'parents': ['mad']},
	{'value': 'madya', 'label': 'madya', 'attrib': [{'pos': 'ger', 'defs': 'rejoice'}], 'isLemma': 0, 'parents': ['mad']},
	{'value': 'madyamAna', 'label': 'madyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'rejoice'}], 'isLemma': 0, 'parents': ['mad']},
	{'value': 'man', 'label': 'man', 'attrib': [{'pos': 'vt4m, vt8m', 'defs': 'think'}], 'isLemma': 1},
	{'value': 'mananIya', 'label': 'mananIya', 'attrib': [{'pos': 'ger', 'defs': 'think'}], 'isLemma': 0, 'parents': ['man']},
	{'value': 'manas', 'label': 'manas', 'attrib': [{'pos': 'n', 'defs': 'mind'}], 'isLemma': 1},
	{'value': 'manIya', 'label': 'manIya', 'attrib': [{'pos': 'ger', 'defs': 'measure, make'}], 'isLemma': 0, 'parents': ['mA']},
	{'value': 'manobudDisamADi', 'label': 'manobudDisamADi', 'attrib': [{'pos': 'm', 'defs': 'mind, intellect and settled awareness'}], 'isLemma': 1},
	{'value': 'mantavya', 'label': 'mantavya', 'attrib': [{'pos': 'ger', 'defs': 'think'}], 'isLemma': 0, 'parents': ['man']},
	{'value': 'mantum', 'label': 'mantum', 'attrib': [{'pos': 'inf', 'defs': 'think'}], 'isLemma': 0, 'parents': ['man']},
	{'value': 'mantr', 'label': 'mantr', 'attrib': [{'pos': 'v10m', 'defs': 'consult'}], 'isLemma': 1},
	{'value': 'mantra', 'label': 'mantra', 'attrib': [{'pos': 'm', 'defs': 'instrument of thought, sacred text, counsel'}], 'isLemma': 1},
	{'value': 'mantraRIya', 'label': 'mantraRIya', 'attrib': [{'pos': 'ger', 'defs': 'consult'}], 'isLemma': 0, 'parents': ['mantr']},
	{'value': 'mantrayamAna', 'label': 'mantrayamAna', 'attrib': [{'pos': 'prmp', 'defs': 'consult'}], 'isLemma': 0, 'parents': ['mantr']},
	{'value': 'mantrayAm', 'label': 'mantrayAm', 'attrib': [{'pos': 'ppf_actn', 'defs': 'consult'}], 'isLemma': 0, 'parents': ['mantr']},
	{'value': 'mantrayitavya', 'label': 'mantrayitavya', 'attrib': [{'pos': 'ger', 'defs': 'consult'}], 'isLemma': 0, 'parents': ['mantr']},
	{'value': 'mantrayitum', 'label': 'mantrayitum', 'attrib': [{'pos': 'inf', 'defs': 'consult'}], 'isLemma': 0, 'parents': ['mantr']},
	{'value': 'mantrayitvA', 'label': 'mantrayitvA', 'attrib': [{'pos': 'abs', 'defs': 'consult'}], 'isLemma': 0, 'parents': ['mantr']},
	{'value': 'mantrayizyamARa', 'label': 'mantrayizyamARa', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'consult'}], 'isLemma': 0, 'parents': ['mantr']},
	{'value': 'mantrita', 'label': 'mantrita', 'attrib': [{'pos': 'ppp', 'defs': 'consult'}], 'isLemma': 0, 'parents': ['mantr']},
	{'value': 'mantritavat', 'label': 'mantritavat', 'attrib': [{'pos': 'pap', 'defs': 'consult'}], 'isLemma': 0, 'parents': ['mantr']},
	{'value': 'mantrya', 'label': 'mantrya', 'attrib': [{'pos': 'ger', 'defs': 'consult'}], 'isLemma': 0, 'parents': ['mantr']},
	{'value': 'mantryamAna', 'label': 'mantryamAna', 'attrib': [{'pos': 'prpp', 'defs': 'consult'}], 'isLemma': 0, 'parents': ['mantr']},
	{'value': 'manda', 'label': 'manda', 'attrib': [{'pos': 'adj', 'defs': 'slow, stupid'}], 'isLemma': 1},
	{'value': 'mandira', 'label': 'mandira', 'attrib': [{'pos': 'n', 'defs': 'house, palace, temple'}], 'isLemma': 1},
	{'value': 'manyamAna', 'label': 'manyamAna', 'attrib': [{'pos': 'prmp', 'defs': 'think'}, {'pos': 'prpp', 'defs': 'think'}], 'isLemma': 0, 'parents': ['man']},
	{'value': 'manyu', 'label': 'manyu', 'attrib': [{'pos': 'm', 'defs': 'spirit, fury, anger'}], 'isLemma': 1},
	{'value': 'manvAna', 'label': 'manvAna', 'attrib': [{'pos': 'prmp', 'defs': 'think'}], 'isLemma': 0, 'parents': ['man']},
	{'value': 'mamARa', 'label': 'mamARa', 'attrib': [{'pos': 'pfpp', 'defs': 'measure, make'}], 'isLemma': 0, 'parents': ['mA']},
	{'value': 'mamfvas', 'label': 'mamfvas', 'attrib': [{'pos': 'pfap', 'defs': 'die'}], 'isLemma': 0, 'parents': ['mf']},
	{'value': 'mamfzAna', 'label': 'mamfzAna', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'forget, tolerate'}], 'isLemma': 0, 'parents': ['mfz']},
	{'value': 'mamfzivas', 'label': 'mamfzivas', 'attrib': [{'pos': 'pfap', 'defs': 'forget, tolerate'}], 'isLemma': 0, 'parents': ['mfz']},
	{'value': 'mamrARa', 'label': 'mamrARa', 'attrib': [{'pos': 'pfpp', 'defs': 'die'}], 'isLemma': 0, 'parents': ['mf']},
	{'value': 'maya', 'label': 'maya', 'attrib': [{'pos': 'm_pn', 'defs': 'Maya'}], 'isLemma': 1},
	{'value': 'maraRIya', 'label': 'maraRIya', 'attrib': [{'pos': 'ger', 'defs': 'die'}], 'isLemma': 0, 'parents': ['mf']},
	{'value': 'marizyat', 'label': 'marizyat', 'attrib': [{'pos': 'fap', 'defs': 'die'}], 'isLemma': 0, 'parents': ['mf']},
	{'value': 'marizyamARa', 'label': 'marizyamARa', 'attrib': [{'pos': 'fpp', 'defs': 'die'}], 'isLemma': 0, 'parents': ['mf']},
	{'value': 'martavya', 'label': 'martavya', 'attrib': [{'pos': 'ger', 'defs': 'die'}], 'isLemma': 0, 'parents': ['mf']},
	{'value': 'martum', 'label': 'martum', 'attrib': [{'pos': 'inf', 'defs': 'die'}], 'isLemma': 0, 'parents': ['mf']},
	{'value': 'marzaRIya', 'label': 'marzaRIya', 'attrib': [{'pos': 'ger', 'defs': 'forget, tolerate'}], 'isLemma': 0, 'parents': ['mfz']},
	{'value': 'marzita', 'label': 'marzita', 'attrib': [{'pos': 'ppp', 'defs': 'forget, tolerate'}], 'isLemma': 0, 'parents': ['mfz']},
	{'value': 'marzitavat', 'label': 'marzitavat', 'attrib': [{'pos': 'pap', 'defs': 'forget, tolerate'}], 'isLemma': 0, 'parents': ['mfz']},
	{'value': 'marzitavya', 'label': 'marzitavya', 'attrib': [{'pos': 'ger', 'defs': 'forget, tolerate'}], 'isLemma': 0, 'parents': ['mfz']},
	{'value': 'marzitum', 'label': 'marzitum', 'attrib': [{'pos': 'inf', 'defs': 'forget, tolerate'}], 'isLemma': 0, 'parents': ['mfz']},
	{'value': 'marzitvA', 'label': 'marzitvA', 'attrib': [{'pos': 'abs', 'defs': 'forget, tolerate'}], 'isLemma': 0, 'parents': ['mfz']},
	{'value': 'marzizyat', 'label': 'marzizyat', 'attrib': [{'pos': 'fap', 'defs': 'forget, tolerate'}], 'isLemma': 0, 'parents': ['mfz']},
	{'value': 'marzizyamARa', 'label': 'marzizyamARa', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'forget, tolerate'}], 'isLemma': 0, 'parents': ['mfz']},
	{'value': 'mah', 'label': 'mah', 'attrib': [{'pos': 'vi1m', 'defs': 'increase'}, {'pos': 'vt10a', 'defs': 'magnify, exalt'}], 'isLemma': 1},
	{'value': 'mahat', 'label': 'mahat', 'attrib': [{'pos': 'adj', 'defs': 'great'}], 'isLemma': 1},
	{'value': 'maharzi', 'label': 'maharzi', 'attrib': [{'pos': 'm', 'defs': 'great sage'}], 'isLemma': 1},
	{'value': 'mahApaTa', 'label': 'mahApaTa', 'attrib': [{'pos': 'm', 'defs': 'great path, road'}], 'isLemma': 1},
	{'value': 'mahAbala', 'label': 'mahAbala', 'attrib': [{'pos': 'adj', 'defs': 'very strong'}], 'isLemma': 1},
	{'value': 'mahABArata', 'label': 'mahABArata', 'attrib': [{'pos': 'n', 'defs': 'the great war between the descendants of Bharata'}], 'isLemma': 1},
	{'value': 'mahAmati', 'label': 'mahAmati', 'attrib': [{'pos': 'adj', 'defs': 'great-minded'}], 'isLemma': 1},
	{'value': 'mahArAja', 'label': 'mahArAja', 'attrib': [{'pos': 'm', 'defs': 'great king'}], 'isLemma': 1},
	{'value': 'mahAvana', 'label': 'mahAvana', 'attrib': [{'pos': 'n', 'defs': 'great forest'}], 'isLemma': 1},
	{'value': 'mahAsura', 'label': 'mahAsura', 'attrib': [{'pos': 'm', 'defs': 'great demon'}], 'isLemma': 1},
	{'value': 'mahI', 'label': 'mahI', 'attrib': [{'pos': 'f', 'defs': 'earth'}], 'isLemma': 1},
	{'value': 'mahIkzit', 'label': 'mahIkzit', 'attrib': [{'pos': 'm', 'defs': 'ruling the earth, king'}], 'isLemma': 1},
	{'value': 'mahendra', 'label': 'mahendra', 'attrib': [{'pos': 'm', 'defs': 'the great Indra'}], 'isLemma': 1},
	{'value': 'mA', 'label': 'mA', 'attrib': [{'pos': 'vt2a', 'defs': 'measure, make'}, {'pos': 'vt4m', 'defs': 'measure, make'}, {'pos': 'vt3m', 'defs': 'measure, make'}], 'isLemma': 1},
	{'value': 'mAtavya', 'label': 'mAtavya', 'attrib': [{'pos': 'ger', 'defs': 'measure, make'}], 'isLemma': 0, 'parents': ['mA']},
	{'value': 'mAtum', 'label': 'mAtum', 'attrib': [{'pos': 'inf', 'defs': 'measure, make'}], 'isLemma': 0, 'parents': ['mA']},
	{'value': 'mAtf', 'label': 'mAtf', 'attrib': [{'pos': 'f', 'defs': 'mother'}], 'isLemma': 1},
	{'value': 'mAdyat', 'label': 'mAdyat', 'attrib': [{'pos': 'prap', 'defs': 'rejoice'}], 'isLemma': 0, 'parents': ['mad']},
	{'value': 'mAnya', 'label': 'mAnya', 'attrib': [{'pos': 'ger', 'defs': 'think'}], 'isLemma': 0, 'parents': ['man']},
	{'value': 'mArya', 'label': 'mArya', 'attrib': [{'pos': 'ger', 'defs': 'die'}], 'isLemma': 0, 'parents': ['mf']},
	{'value': 'mAs', 'label': 'mAs', 'attrib': [{'pos': 'm', 'defs': 'the moon, a month'}], 'isLemma': 1},
	{'value': 'mAsa', 'label': 'mAsa', 'attrib': [{'pos': 'm', 'defs': 'the moon, a month'}], 'isLemma': 1},
	{'value': 'mAsyamAna', 'label': 'mAsyamAna', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'measure, make'}], 'isLemma': 0, 'parents': ['mA']},
	{'value': 'mita', 'label': 'mita', 'attrib': [{'pos': 'ppp', 'defs': 'measure, make'}], 'isLemma': 0, 'parents': ['mA']},
	{'value': 'mitavat', 'label': 'mitavat', 'attrib': [{'pos': 'pap', 'defs': 'measure, make'}], 'isLemma': 0, 'parents': ['mA']},
	{'value': 'mitvA', 'label': 'mitvA', 'attrib': [{'pos': 'abs', 'defs': 'measure, make'}], 'isLemma': 0, 'parents': ['mA']},
	{'value': 'mimAna', 'label': 'mimAna', 'attrib': [{'pos': 'prmp', 'defs': 'measure, make'}], 'isLemma': 0, 'parents': ['mA']},
	{'value': 'mIyamARa', 'label': 'mIyamARa', 'attrib': [{'pos': 'prpp', 'defs': 'measure, make'}], 'isLemma': 0, 'parents': ['mA']},
	{'value': 'mukta', 'label': 'mukta', 'attrib': [{'pos': 'ppp', 'defs': 'release'}], 'isLemma': 0, 'parents': ['muc']},
	{'value': 'muktavat', 'label': 'muktavat', 'attrib': [{'pos': 'pap', 'defs': 'release'}], 'isLemma': 0, 'parents': ['muc']},
	{'value': 'muktvA', 'label': 'muktvA', 'attrib': [{'pos': 'abs', 'defs': 'release'}], 'isLemma': 0, 'parents': ['muc']},
	{'value': 'muc', 'label': 'muc', 'attrib': [{'pos': 'vt6am', 'defs': 'release'}], 'isLemma': 1},
	{'value': 'mucyamAna', 'label': 'mucyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'release'}], 'isLemma': 0, 'parents': ['muc']},
	{'value': 'muYcat', 'label': 'muYcat', 'attrib': [{'pos': 'prap', 'defs': 'release'}], 'isLemma': 0, 'parents': ['muc']},
	{'value': 'muYcamAna', 'label': 'muYcamAna', 'attrib': [{'pos': 'prmp', 'defs': 'release'}], 'isLemma': 0, 'parents': ['muc']},
	{'value': 'mud', 'label': 'mud', 'attrib': [{'pos': 'f', 'defs': 'joy'}, {'pos': 'vi1m', 'defs': 'rejoice'}], 'isLemma': 1},
	{'value': 'mumucAna', 'label': 'mumucAna', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'release'}], 'isLemma': 0, 'parents': ['muc']},
	{'value': 'mumucvas', 'label': 'mumucvas', 'attrib': [{'pos': 'pfap', 'defs': 'release'}], 'isLemma': 0, 'parents': ['muc']},
	{'value': 'mf', 'label': 'mf', 'attrib': [{'pos': 'vi6am', 'defs': 'die'}], 'isLemma': 1},
	{'value': 'mfta', 'label': 'mfta', 'attrib': [{'pos': 'ppp', 'defs': 'die'}], 'isLemma': 0, 'parents': ['mf']},
	{'value': 'mftavat', 'label': 'mftavat', 'attrib': [{'pos': 'pap', 'defs': 'die'}], 'isLemma': 0, 'parents': ['mf']},
	{'value': 'mftvA', 'label': 'mftvA', 'attrib': [{'pos': 'abs', 'defs': 'die'}], 'isLemma': 0, 'parents': ['mf']},
	{'value': 'mfz', 'label': 'mfz', 'attrib': [{'pos': 'vt4am', 'defs': 'forget, tolerate'}], 'isLemma': 1},
	{'value': 'mfzita', 'label': 'mfzita', 'attrib': [{'pos': 'ppp', 'defs': 'forget, tolerate'}], 'isLemma': 0, 'parents': ['mfz']},
	{'value': 'mfzitavat', 'label': 'mfzitavat', 'attrib': [{'pos': 'pap', 'defs': 'forget, tolerate'}], 'isLemma': 0, 'parents': ['mfz']},
	{'value': 'mfzitvA', 'label': 'mfzitvA', 'attrib': [{'pos': 'abs', 'defs': 'forget, tolerate'}], 'isLemma': 0, 'parents': ['mfz']},
	{'value': 'mfzya', 'label': 'mfzya', 'attrib': [{'pos': 'ger', 'defs': 'forget, tolerate'}], 'isLemma': 0, 'parents': ['mfz']},
	{'value': 'mfzyat', 'label': 'mfzyat', 'attrib': [{'pos': 'prap', 'defs': 'forget, tolerate'}], 'isLemma': 0, 'parents': ['mfz']},
	{'value': 'mfzyamARa', 'label': 'mfzyamARa', 'attrib': [{'pos': 'prmp', 'defs': 'forget, tolerate'}, {'pos': 'prpp', 'defs': 'forget, tolerate'}], 'isLemma': 0, 'parents': ['mfz']},
	{'value': 'medAna', 'label': 'medAna', 'attrib': [{'pos': 'pfpp', 'defs': 'rejoice'}], 'isLemma': 0, 'parents': ['mad']},
	{'value': 'medivas', 'label': 'medivas', 'attrib': [{'pos': 'pfap', 'defs': 'rejoice'}], 'isLemma': 0, 'parents': ['mad']},
	{'value': 'menAna', 'label': 'menAna', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'think'}], 'isLemma': 0, 'parents': ['man']},
	{'value': 'meya', 'label': 'meya', 'attrib': [{'pos': 'ger', 'defs': 'measure, make'}], 'isLemma': 0, 'parents': ['mA']},
	{'value': 'moktavya', 'label': 'moktavya', 'attrib': [{'pos': 'ger', 'defs': 'release'}], 'isLemma': 0, 'parents': ['muc']},
	{'value': 'moktum', 'label': 'moktum', 'attrib': [{'pos': 'inf', 'defs': 'release'}], 'isLemma': 0, 'parents': ['muc']},
	{'value': 'mokz', 'label': 'mokz', 'attrib': [{'pos': 'vt10a', 'defs': 'liberate'}], 'isLemma': 1},
	{'value': 'mokzaRa', 'label': 'mokzaRa', 'attrib': [{'pos': 'n', 'defs': 'freeing, liberation'}], 'isLemma': 1},
	{'value': 'mokzaRIya', 'label': 'mokzaRIya', 'attrib': [{'pos': 'ger', 'defs': 'liberate'}], 'isLemma': 0, 'parents': ['mokz']},
	{'value': 'mokzayat', 'label': 'mokzayat', 'attrib': [{'pos': 'prap', 'defs': 'liberate'}], 'isLemma': 0, 'parents': ['mokz']},
	{'value': 'mokzayAm', 'label': 'mokzayAm', 'attrib': [{'pos': 'ppf_actn', 'defs': 'release'}], 'isLemma': 0, 'parents': ['muc']},
	{'value': 'mokzita', 'label': 'mokzita', 'attrib': [{'pos': 'ppp', 'defs': 'liberate'}], 'isLemma': 0, 'parents': ['mokz']},
	{'value': 'mokzitavat', 'label': 'mokzitavat', 'attrib': [{'pos': 'pap', 'defs': 'liberate'}], 'isLemma': 0, 'parents': ['mokz']},
	{'value': 'mokzitavya', 'label': 'mokzitavya', 'attrib': [{'pos': 'ger', 'defs': 'liberate'}], 'isLemma': 0, 'parents': ['mokz']},
	{'value': 'mokzitum', 'label': 'mokzitum', 'attrib': [{'pos': 'inf', 'defs': 'liberate'}], 'isLemma': 0, 'parents': ['mokz']},
	{'value': 'mokzitvA', 'label': 'mokzitvA', 'attrib': [{'pos': 'abs', 'defs': 'liberate'}], 'isLemma': 0, 'parents': ['mokz']},
	{'value': 'mokzizyat', 'label': 'mokzizyat', 'attrib': [{'pos': 'fap', 'defs': 'liberate'}], 'isLemma': 0, 'parents': ['mokz']},
	{'value': 'mokzizyamARa', 'label': 'mokzizyamARa', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'liberate'}], 'isLemma': 0, 'parents': ['mokz']},
	{'value': 'mokzya', 'label': 'mokzya', 'attrib': [{'pos': 'ger', 'defs': 'liberate'}], 'isLemma': 0, 'parents': ['mokz']},
	{'value': 'mokzyat', 'label': 'mokzyat', 'attrib': [{'pos': 'fap', 'defs': 'release'}], 'isLemma': 0, 'parents': ['muc']},
	{'value': 'mokzyamARa', 'label': 'mokzyamARa', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'release'}, {'pos': 'prpp', 'defs': 'liberate'}], 'isLemma': 0, 'parents': ['muc', 'mokz']},
	{'value': 'mocanIya', 'label': 'mocanIya', 'attrib': [{'pos': 'ger', 'defs': 'release'}], 'isLemma': 0, 'parents': ['muc']},
	{'value': 'mocya', 'label': 'mocya', 'attrib': [{'pos': 'ger', 'defs': 'release'}], 'isLemma': 0, 'parents': ['muc']},
	{'value': 'mriyamARa', 'label': 'mriyamARa', 'attrib': [{'pos': 'prmp', 'defs': 'die'}, {'pos': 'prpp', 'defs': 'die'}], 'isLemma': 0, 'parents': ['mf']},
	{'value': 'yat', 'label': 'yat', 'attrib': [{'pos': 'prap', 'defs': 'go'}], 'isLemma': 0, 'parents': ['i']},
	{'value': 'yatas', 'label': 'yatas', 'attrib': [{'pos': 'rel_adv', 'defs': 'whence, because'}], 'isLemma': 1},
	{'value': 'yaTA', 'label': 'yaTA', 'attrib': [{'pos': 'rel_adv', 'defs': 'in which manner, as'}], 'isLemma': 1},
	{'value': 'yadA', 'label': 'yadA', 'attrib': [{'pos': 'rel_adv', 'defs': 'at which time, when'}], 'isLemma': 1},
	{'value': 'yayAcAna', 'label': 'yayAcAna', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'ask'}], 'isLemma': 0, 'parents': ['yAc']},
	{'value': 'yayAcivas', 'label': 'yayAcivas', 'attrib': [{'pos': 'pfap', 'defs': 'ask'}], 'isLemma': 0, 'parents': ['yAc']},
	{'value': 'yaSas', 'label': 'yaSas', 'attrib': [{'pos': 'n', 'defs': 'glory, fame'}], 'isLemma': 1},
	{'value': 'yAc', 'label': 'yAc', 'attrib': [{'pos': 'vt1am', 'defs': 'ask'}], 'isLemma': 1},
	{'value': 'yAcat', 'label': 'yAcat', 'attrib': [{'pos': 'prap', 'defs': 'ask'}], 'isLemma': 0, 'parents': ['yAc']},
	{'value': 'yAcanIya', 'label': 'yAcanIya', 'attrib': [{'pos': 'ger', 'defs': 'ask'}], 'isLemma': 0, 'parents': ['yAc']},
	{'value': 'yAcamAna', 'label': 'yAcamAna', 'attrib': [{'pos': 'prmp', 'defs': 'ask'}], 'isLemma': 0, 'parents': ['yAc']},
	{'value': 'yAcita', 'label': 'yAcita', 'attrib': [{'pos': 'ppp', 'defs': 'ask'}], 'isLemma': 0, 'parents': ['yAc']},
	{'value': 'yAcitavat', 'label': 'yAcitavat', 'attrib': [{'pos': 'pap', 'defs': 'ask'}], 'isLemma': 0, 'parents': ['yAc']},
	{'value': 'yAcitavya', 'label': 'yAcitavya', 'attrib': [{'pos': 'ger', 'defs': 'ask'}], 'isLemma': 0, 'parents': ['yAc']},
	{'value': 'yAcitum', 'label': 'yAcitum', 'attrib': [{'pos': 'inf', 'defs': 'ask'}], 'isLemma': 0, 'parents': ['yAc']},
	{'value': 'yAcitvA', 'label': 'yAcitvA', 'attrib': [{'pos': 'abs', 'defs': 'ask'}], 'isLemma': 0, 'parents': ['yAc']},
	{'value': 'yAcizyat', 'label': 'yAcizyat', 'attrib': [{'pos': 'fap', 'defs': 'ask'}], 'isLemma': 0, 'parents': ['yAc']},
	{'value': 'yAcizyamARa', 'label': 'yAcizyamARa', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'ask'}], 'isLemma': 0, 'parents': ['yAc']},
	{'value': 'yAcya', 'label': 'yAcya', 'attrib': [{'pos': 'ger', 'defs': 'ask'}], 'isLemma': 0, 'parents': ['yAc']},
	{'value': 'yAcyamAna', 'label': 'yAcyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'ask'}], 'isLemma': 0, 'parents': ['yAc']},
	{'value': 'yukta', 'label': 'yukta', 'attrib': [{'pos': 'ppp', 'defs': 'yoke, join, unite'}], 'isLemma': 0, 'parents': ['yuj']},
	{'value': 'yuktavat', 'label': 'yuktavat', 'attrib': [{'pos': 'pap', 'defs': 'yoke, join, unite'}], 'isLemma': 0, 'parents': ['yuj']},
	{'value': 'yuktvA', 'label': 'yuktvA', 'attrib': [{'pos': 'abs', 'defs': 'yoke, join, unite'}], 'isLemma': 0, 'parents': ['yuj']},
	{'value': 'yuj', 'label': 'yuj', 'attrib': [{'pos': 'vt7am', 'defs': 'yoke, join, unite'}], 'isLemma': 1},
	{'value': 'yujyamAna', 'label': 'yujyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'yoke, join, unite'}], 'isLemma': 0, 'parents': ['yuj']},
	{'value': 'yuYjat', 'label': 'yuYjat', 'attrib': [{'pos': 'prap', 'defs': 'yoke, join, unite'}], 'isLemma': 0, 'parents': ['yuj']},
	{'value': 'yuYjAna', 'label': 'yuYjAna', 'attrib': [{'pos': 'prmp', 'defs': 'yoke, join, unite'}], 'isLemma': 0, 'parents': ['yuj']},
	{'value': 'yudDa', 'label': 'yudDa', 'attrib': [{'pos': 'n', 'defs': 'battle, war'}, {'pos': 'ppp', 'defs': 'fight'}], 'isLemma': 1},
	{'value': 'yudDa', 'label': 'yudDa', 'attrib': [{'pos': 'n', 'defs': 'battle, war'}, {'pos': 'ppp', 'defs': 'fight'}], 'isLemma': 0, 'parents': ['yuD']},
	{'value': 'yudDavat', 'label': 'yudDavat', 'attrib': [{'pos': 'pap', 'defs': 'fight'}], 'isLemma': 0, 'parents': ['yuD']},
	{'value': 'yudDvA', 'label': 'yudDvA', 'attrib': [{'pos': 'abs', 'defs': 'fight'}], 'isLemma': 0, 'parents': ['yuD']},
	{'value': 'yuD', 'label': 'yuD', 'attrib': [{'pos': 'f', 'defs': 'battle, war'}, {'pos': 'vt4m', 'defs': 'fight'}], 'isLemma': 1},
	{'value': 'yuDizWira', 'label': 'yuDizWira', 'attrib': [{'pos': 'm_pn', 'defs': 'Yudhiṣṭhira'}], 'isLemma': 1},
	{'value': 'yuDyamAna', 'label': 'yuDyamAna', 'attrib': [{'pos': 'prmp', 'defs': 'fight'}, {'pos': 'prpp', 'defs': 'fight'}], 'isLemma': 0, 'parents': ['yuD']},
	{'value': 'yuyujAna', 'label': 'yuyujAna', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'yoke, join, unite'}], 'isLemma': 0, 'parents': ['yuj']},
	{'value': 'yuyujvas', 'label': 'yuyujvas', 'attrib': [{'pos': 'pfap', 'defs': 'yoke, join, unite'}], 'isLemma': 0, 'parents': ['yuj']},
	{'value': 'yuyuDAna', 'label': 'yuyuDAna', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'fight'}], 'isLemma': 0, 'parents': ['yuD']},
	{'value': 'yuzmad', 'label': 'yuzmad', 'attrib': [{'pos': 'pers_pron', 'defs': 'you'}], 'isLemma': 1},
	{'value': 'yuzmadvAsa', 'label': 'yuzmadvAsa', 'attrib': [{'pos': 'm', 'defs': 'your dwelling'}], 'isLemma': 1},
	{'value': 'yoktavya', 'label': 'yoktavya', 'attrib': [{'pos': 'ger', 'defs': 'yoke, join, unite'}], 'isLemma': 0, 'parents': ['yuj']},
	{'value': 'yoktum', 'label': 'yoktum', 'attrib': [{'pos': 'inf', 'defs': 'yoke, join, unite'}], 'isLemma': 0, 'parents': ['yuj']},
	{'value': 'yokzyat', 'label': 'yokzyat', 'attrib': [{'pos': 'fap', 'defs': 'yoke, join, unite'}], 'isLemma': 0, 'parents': ['yuj']},
	{'value': 'yokzyamARa', 'label': 'yokzyamARa', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'yoke, join, unite'}], 'isLemma': 0, 'parents': ['yuj']},
	{'value': 'yoga', 'label': 'yoga', 'attrib': [{'pos': 'm', 'defs': 'union, conjunction, use, work, means, stratagem'}], 'isLemma': 1},
	{'value': 'yogya', 'label': 'yogya', 'attrib': [{'pos': 'ger', 'defs': 'yoke, join, unite'}], 'isLemma': 0, 'parents': ['yuj']},
	{'value': 'yojanIya', 'label': 'yojanIya', 'attrib': [{'pos': 'ger', 'defs': 'yoke, join, unite'}], 'isLemma': 0, 'parents': ['yuj']},
	{'value': 'yotsyamAna', 'label': 'yotsyamAna', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'fight'}], 'isLemma': 0, 'parents': ['yuD']},
	{'value': 'yodDavya', 'label': 'yodDavya', 'attrib': [{'pos': 'ger', 'defs': 'fight'}], 'isLemma': 0, 'parents': ['yuD']},
	{'value': 'yodDum', 'label': 'yodDum', 'attrib': [{'pos': 'inf', 'defs': 'fight'}], 'isLemma': 0, 'parents': ['yuD']},
	{'value': 'yoDanIya', 'label': 'yoDanIya', 'attrib': [{'pos': 'ger', 'defs': 'fight'}], 'isLemma': 0, 'parents': ['yuD']},
	{'value': 'yoDya', 'label': 'yoDya', 'attrib': [{'pos': 'ger', 'defs': 'fight'}], 'isLemma': 0, 'parents': ['yuD']},
	{'value': 'rakz', 'label': 'rakz', 'attrib': [{'pos': 'vt1a', 'defs': 'keep, protect'}], 'isLemma': 1},
	{'value': 'rakzat', 'label': 'rakzat', 'attrib': [{'pos': 'prap', 'defs': 'keep, protect'}], 'isLemma': 0, 'parents': ['rakz']},
	{'value': 'rakzanIya', 'label': 'rakzanIya', 'attrib': [{'pos': 'ger', 'defs': 'keep, protect'}], 'isLemma': 0, 'parents': ['rakz']},
	{'value': 'rakzamARa', 'label': 'rakzamARa', 'attrib': [{'pos': 'prmp', 'defs': 'keep, protect'}], 'isLemma': 0, 'parents': ['rakz']},
	{'value': 'rakzas', 'label': 'rakzas', 'attrib': [{'pos': 'n', 'defs': 'demon'}], 'isLemma': 1},
	{'value': 'rakzita', 'label': 'rakzita', 'attrib': [{'pos': 'ppp', 'defs': 'keep, protect'}], 'isLemma': 0, 'parents': ['rakz']},
	{'value': 'rakzitavat', 'label': 'rakzitavat', 'attrib': [{'pos': 'pap', 'defs': 'keep, protect'}], 'isLemma': 0, 'parents': ['rakz']},
	{'value': 'rakzitavya', 'label': 'rakzitavya', 'attrib': [{'pos': 'ger', 'defs': 'keep, protect'}], 'isLemma': 0, 'parents': ['rakz']},
	{'value': 'rakzitum', 'label': 'rakzitum', 'attrib': [{'pos': 'inf', 'defs': 'keep, protect'}], 'isLemma': 0, 'parents': ['rakz']},
	{'value': 'rakzitvA', 'label': 'rakzitvA', 'attrib': [{'pos': 'abs', 'defs': 'keep, protect'}], 'isLemma': 0, 'parents': ['rakz']},
	{'value': 'rakzizyat', 'label': 'rakzizyat', 'attrib': [{'pos': 'fap', 'defs': 'keep, protect'}], 'isLemma': 0, 'parents': ['rakz']},
	{'value': 'rakzizyamARa', 'label': 'rakzizyamARa', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'keep, protect'}], 'isLemma': 0, 'parents': ['rakz']},
	{'value': 'rakzya', 'label': 'rakzya', 'attrib': [{'pos': 'ger', 'defs': 'keep, protect'}], 'isLemma': 0, 'parents': ['rakz']},
	{'value': 'rakzyamARa', 'label': 'rakzyamARa', 'attrib': [{'pos': 'prpp', 'defs': 'keep, protect'}], 'isLemma': 0, 'parents': ['rakz']},
	{'value': 'rajamAna', 'label': 'rajamAna', 'attrib': [{'pos': 'prmp', 'defs': 'shine, rule'}], 'isLemma': 0, 'parents': ['rAj']},
	{'value': 'ratna', 'label': 'ratna', 'attrib': [{'pos': 'n', 'defs': 'gift, wealth, gem'}], 'isLemma': 1},
	{'value': 'raTa', 'label': 'raTa', 'attrib': [{'pos': 'm', 'defs': 'chariot, car'}], 'isLemma': 1},
	{'value': 'rapsyamARa', 'label': 'rapsyamARa', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'begin'}], 'isLemma': 0, 'parents': ['raB']},
	{'value': 'rabDa', 'label': 'rabDa', 'attrib': [{'pos': 'ppp', 'defs': 'begin'}], 'isLemma': 0, 'parents': ['raB']},
	{'value': 'rabDavat', 'label': 'rabDavat', 'attrib': [{'pos': 'pap', 'defs': 'begin'}], 'isLemma': 0, 'parents': ['raB']},
	{'value': 'rabDavya', 'label': 'rabDavya', 'attrib': [{'pos': 'ger', 'defs': 'begin'}], 'isLemma': 0, 'parents': ['raB']},
	{'value': 'rabDum', 'label': 'rabDum', 'attrib': [{'pos': 'inf', 'defs': 'begin'}], 'isLemma': 0, 'parents': ['raB']},
	{'value': 'rabDvA', 'label': 'rabDvA', 'attrib': [{'pos': 'abs', 'defs': 'begin'}], 'isLemma': 0, 'parents': ['raB']},
	{'value': 'raB', 'label': 'raB', 'attrib': [{'pos': 'vt1m', 'defs': 'begin'}], 'isLemma': 1},
	{'value': 'raBamARa', 'label': 'raBamARa', 'attrib': [{'pos': 'prmp', 'defs': 'begin'}], 'isLemma': 0, 'parents': ['raB']},
	{'value': 'raBya', 'label': 'raBya', 'attrib': [{'pos': 'ger', 'defs': 'begin'}], 'isLemma': 0, 'parents': ['raB']},
	{'value': 'raByamARa', 'label': 'raByamARa', 'attrib': [{'pos': 'prpp', 'defs': 'begin'}], 'isLemma': 0, 'parents': ['raB']},
	{'value': 'ramBaRIya', 'label': 'ramBaRIya', 'attrib': [{'pos': 'ger', 'defs': 'begin'}], 'isLemma': 0, 'parents': ['raB']},
	{'value': 'rarakzARa', 'label': 'rarakzARa', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'keep, protect'}], 'isLemma': 0, 'parents': ['rakz']},
	{'value': 'rarakzivas', 'label': 'rarakzivas', 'attrib': [{'pos': 'pfap', 'defs': 'keep, protect'}], 'isLemma': 0, 'parents': ['rakz']},
	{'value': 'rarAjAna', 'label': 'rarAjAna', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'shine, rule'}], 'isLemma': 0, 'parents': ['rAj']},
	{'value': 'rarAjivas', 'label': 'rarAjivas', 'attrib': [{'pos': 'pfap', 'defs': 'shine, rule'}], 'isLemma': 0, 'parents': ['rAj']},
	{'value': 'rAkzasa', 'label': 'rAkzasa', 'attrib': [{'pos': 'm', 'defs': 'demon'}], 'isLemma': 1},
	{'value': 'rAj', 'label': 'rAj', 'attrib': [{'pos': 'vt1am', 'defs': 'shine, rule'}], 'isLemma': 1},
	{'value': 'rAjat', 'label': 'rAjat', 'attrib': [{'pos': 'prap', 'defs': 'shine, rule'}], 'isLemma': 0, 'parents': ['rAj']},
	{'value': 'rAjan', 'label': 'rAjan', 'attrib': [{'pos': 'm', 'defs': 'king'}], 'isLemma': 1},
	{'value': 'rAjanIya', 'label': 'rAjanIya', 'attrib': [{'pos': 'ger', 'defs': 'shine, rule'}], 'isLemma': 0, 'parents': ['rAj']},
	{'value': 'rAjita', 'label': 'rAjita', 'attrib': [{'pos': 'ppp', 'defs': 'shine, rule'}], 'isLemma': 0, 'parents': ['rAj']},
	{'value': 'rAjitavat', 'label': 'rAjitavat', 'attrib': [{'pos': 'pap', 'defs': 'shine, rule'}], 'isLemma': 0, 'parents': ['rAj']},
	{'value': 'rAjitavya', 'label': 'rAjitavya', 'attrib': [{'pos': 'ger', 'defs': 'shine, rule'}], 'isLemma': 0, 'parents': ['rAj']},
	{'value': 'rAjitum', 'label': 'rAjitum', 'attrib': [{'pos': 'inf', 'defs': 'shine, rule'}], 'isLemma': 0, 'parents': ['rAj']},
	{'value': 'rAjitvA', 'label': 'rAjitvA', 'attrib': [{'pos': 'abs', 'defs': 'shine, rule'}], 'isLemma': 0, 'parents': ['rAj']},
	{'value': 'rAjizyat', 'label': 'rAjizyat', 'attrib': [{'pos': 'fap', 'defs': 'shine, rule'}], 'isLemma': 0, 'parents': ['rAj']},
	{'value': 'rAjizyamARa', 'label': 'rAjizyamARa', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'shine, rule'}], 'isLemma': 0, 'parents': ['rAj']},
	{'value': 'rAjIva', 'label': 'rAjIva', 'attrib': [{'pos': 'n', 'defs': 'blue lotus'}], 'isLemma': 1},
	{'value': 'rAjIvalocana', 'label': 'rAjIvalocana', 'attrib': [{'pos': 'adj', 'defs': 'lotus-eyed'}], 'isLemma': 1},
	{'value': 'rAjya', 'label': 'rAjya', 'attrib': [{'pos': 'ger', 'defs': 'shine, rule'}, {'pos': 'n', 'defs': 'kingdom'}], 'isLemma': 1},
	{'value': 'rAjya', 'label': 'rAjya', 'attrib': [{'pos': 'ger', 'defs': 'shine, rule'}, {'pos': 'n', 'defs': 'kingdom'}], 'isLemma': 0, 'parents': ['rAj']},
	{'value': 'rAjyamAna', 'label': 'rAjyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'shine, rule'}], 'isLemma': 0, 'parents': ['rAj']},
	{'value': 'rAjyavinASa', 'label': 'rAjyavinASa', 'attrib': [{'pos': 'm', 'defs': 'destruction of a kingdom'}], 'isLemma': 1},
	{'value': 'rAjyArTa', 'label': 'rAjyArTa', 'attrib': [{'pos': 'm', 'defs': 'purpose of a kingdom'}], 'isLemma': 1},
	{'value': 'rAzwra', 'label': 'rAzwra', 'attrib': [{'pos': 'n', 'defs': 'kingdom'}], 'isLemma': 1},
	{'value': 'rUpa', 'label': 'rUpa', 'attrib': [{'pos': 'n', 'defs': 'appearance, form, beauty'}], 'isLemma': 1},
	{'value': 'rUpavIryOjaHsaMpanna', 'label': 'rUpavIryOjaHsaMpanna', 'attrib': [{'pos': 'adj', 'defs': 'endowed with beauty, power and energy'}], 'isLemma': 1},
	{'value': 'rUpavIryOjas', 'label': 'rUpavIryOjas', 'attrib': [{'pos': 'n', 'defs': 'beauty, power and energy'}], 'isLemma': 1},
	{'value': 'reBivas', 'label': 'reBivas', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'begin'}], 'isLemma': 0, 'parents': ['raB']},
	{'value': 'lakzaRa', 'label': 'lakzaRa', 'attrib': [{'pos': 'n', 'defs': 'characteristic, sign'}], 'isLemma': 1},
	{'value': 'lakzmI', 'label': 'lakzmI', 'attrib': [{'pos': 'f', 'defs': 'sign, good fortune'}, {'pos': 'f_pn', 'defs': 'Lakṣmī'}], 'isLemma': 1},
	{'value': 'lapsyamAna', 'label': 'lapsyamAna', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'obtain'}], 'isLemma': 0, 'parents': ['laB']},
	{'value': 'labDa', 'label': 'labDa', 'attrib': [{'pos': 'ppp', 'defs': 'obtain'}], 'isLemma': 0, 'parents': ['laB']},
	{'value': 'labDavat', 'label': 'labDavat', 'attrib': [{'pos': 'pap', 'defs': 'obtain'}], 'isLemma': 0, 'parents': ['laB']},
	{'value': 'labDavya', 'label': 'labDavya', 'attrib': [{'pos': 'ger', 'defs': 'obtain'}], 'isLemma': 0, 'parents': ['laB']},
	{'value': 'labDum', 'label': 'labDum', 'attrib': [{'pos': 'inf', 'defs': 'obtain'}], 'isLemma': 0, 'parents': ['laB']},
	{'value': 'labDvA', 'label': 'labDvA', 'attrib': [{'pos': 'abs', 'defs': 'obtain'}], 'isLemma': 0, 'parents': ['laB']},
	{'value': 'laB', 'label': 'laB', 'attrib': [{'pos': 'vt1m', 'defs': 'obtain'}], 'isLemma': 1},
	{'value': 'laBanIya', 'label': 'laBanIya', 'attrib': [{'pos': 'ger', 'defs': 'obtain'}], 'isLemma': 0, 'parents': ['laB']},
	{'value': 'laBamAna', 'label': 'laBamAna', 'attrib': [{'pos': 'prmp', 'defs': 'obtain'}], 'isLemma': 0, 'parents': ['laB']},
	{'value': 'laBya', 'label': 'laBya', 'attrib': [{'pos': 'ger', 'defs': 'obtain'}], 'isLemma': 0, 'parents': ['laB']},
	{'value': 'laByamAna', 'label': 'laByamAna', 'attrib': [{'pos': 'prpp', 'defs': 'obtain'}], 'isLemma': 0, 'parents': ['laB']},
	{'value': 'lubDa', 'label': 'lubDa', 'attrib': [{'pos': 'ppp', 'defs': 'lust'}], 'isLemma': 0, 'parents': ['luB']},
	{'value': 'lubDavat', 'label': 'lubDavat', 'attrib': [{'pos': 'pap', 'defs': 'lust'}], 'isLemma': 0, 'parents': ['luB']},
	{'value': 'lubDvA', 'label': 'lubDvA', 'attrib': [{'pos': 'abs', 'defs': 'lust'}], 'isLemma': 0, 'parents': ['luB']},
	{'value': 'luB', 'label': 'luB', 'attrib': [{'pos': 'vi4a', 'defs': 'lust'}], 'isLemma': 1},
	{'value': 'luBitvA', 'label': 'luBitvA', 'attrib': [{'pos': 'abs', 'defs': 'lust'}], 'isLemma': 0, 'parents': ['luB']},
	{'value': 'luBya', 'label': 'luBya', 'attrib': [{'pos': 'ger', 'defs': 'lust'}], 'isLemma': 0, 'parents': ['luB']},
	{'value': 'luByat', 'label': 'luByat', 'attrib': [{'pos': 'prap', 'defs': 'lust'}], 'isLemma': 0, 'parents': ['luB']},
	{'value': 'luByamAna', 'label': 'luByamAna', 'attrib': [{'pos': 'prpp', 'defs': 'lust'}], 'isLemma': 0, 'parents': ['luB']},
	{'value': 'luluBAna', 'label': 'luluBAna', 'attrib': [{'pos': 'pfpp', 'defs': 'lust'}], 'isLemma': 0, 'parents': ['luB']},
	{'value': 'leBAna', 'label': 'leBAna', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'obtain'}], 'isLemma': 0, 'parents': ['laB']},
	{'value': 'loka', 'label': 'loka', 'attrib': [{'pos': 'm', 'defs': 'region'}], 'isLemma': 1},
	{'value': 'locana', 'label': 'locana', 'attrib': [{'pos': 'n', 'defs': 'eye'}], 'isLemma': 1},
	{'value': 'lobDavya', 'label': 'lobDavya', 'attrib': [{'pos': 'ger', 'defs': 'lust'}], 'isLemma': 0, 'parents': ['luB']},
	{'value': 'lobDum', 'label': 'lobDum', 'attrib': [{'pos': 'inf', 'defs': 'lust'}], 'isLemma': 0, 'parents': ['luB']},
	{'value': 'loBa', 'label': 'loBa', 'attrib': [{'pos': 'm', 'defs': 'love, desire'}], 'isLemma': 1},
	{'value': 'loBanIya', 'label': 'loBanIya', 'attrib': [{'pos': 'ger', 'defs': 'lust'}], 'isLemma': 0, 'parents': ['luB']},
	{'value': 'loBitavya', 'label': 'loBitavya', 'attrib': [{'pos': 'ger', 'defs': 'lust'}], 'isLemma': 0, 'parents': ['luB']},
	{'value': 'loBitum', 'label': 'loBitum', 'attrib': [{'pos': 'inf', 'defs': 'lust'}], 'isLemma': 0, 'parents': ['luB']},
	{'value': 'loBitvA', 'label': 'loBitvA', 'attrib': [{'pos': 'abs', 'defs': 'lust'}], 'isLemma': 0, 'parents': ['luB']},
	{'value': 'loBizyat', 'label': 'loBizyat', 'attrib': [{'pos': 'fap', 'defs': 'lust'}], 'isLemma': 0, 'parents': ['luB']},
	{'value': 'loBizyamARa', 'label': 'loBizyamARa', 'attrib': [{'pos': 'fpp', 'defs': 'lust'}], 'isLemma': 0, 'parents': ['luB']},
	{'value': 'vakta', 'label': 'vakta', 'attrib': [{'pos': 'ppp', 'defs': 'deceive'}], 'isLemma': 0, 'parents': ['vaYc']},
	{'value': 'vaktavat', 'label': 'vaktavat', 'attrib': [{'pos': 'pap', 'defs': 'deceive'}], 'isLemma': 0, 'parents': ['vaYc']},
	{'value': 'vaktavya', 'label': 'vaktavya', 'attrib': [{'pos': 'ger', 'defs': 'say, speak'}], 'isLemma': 0, 'parents': ['vac']},
	{'value': 'vaktum', 'label': 'vaktum', 'attrib': [{'pos': 'inf', 'defs': 'say, speak'}], 'isLemma': 0, 'parents': ['vac']},
	{'value': 'vaktvA', 'label': 'vaktvA', 'attrib': [{'pos': 'abs', 'defs': 'deceive'}], 'isLemma': 0, 'parents': ['vaYc']},
	{'value': 'vakzyat', 'label': 'vakzyat', 'attrib': [{'pos': 'fap', 'defs': 'say, speak'}, {'pos': 'fap', 'defs': 'carry, flow, wed'}], 'isLemma': 0, 'parents': ['vac', 'vah']},
	{'value': 'vakzyamARa', 'label': 'vakzyamARa', 'attrib': [{'pos': 'fpp', 'defs': 'say, speak'}, {'pos': 'fmp, fpp', 'defs': 'carry, flow, wed'}], 'isLemma': 0, 'parents': ['vac', 'vah']},
	{'value': 'vac', 'label': 'vac', 'attrib': [{'pos': 'vt2a', 'defs': 'say, speak'}], 'isLemma': 1},
	{'value': 'vacana', 'label': 'vacana', 'attrib': [{'pos': 'n', 'defs': 'speech'}], 'isLemma': 1},
	{'value': 'vacanIya', 'label': 'vacanIya', 'attrib': [{'pos': 'ger', 'defs': 'say, speak'}], 'isLemma': 0, 'parents': ['vac']},
	{'value': 'vacitvA', 'label': 'vacitvA', 'attrib': [{'pos': 'abs', 'defs': 'deceive'}], 'isLemma': 0, 'parents': ['vaYc']},
	{'value': 'vaYc', 'label': 'vaYc', 'attrib': [{'pos': 'vt10m', 'defs': 'deceive'}], 'isLemma': 1},
	{'value': 'vaYcanIya', 'label': 'vaYcanIya', 'attrib': [{'pos': 'ger', 'defs': 'deceive'}], 'isLemma': 0, 'parents': ['vaYc']},
	{'value': 'vaYcayamAna', 'label': 'vaYcayamAna', 'attrib': [{'pos': 'prmp', 'defs': 'deceive'}], 'isLemma': 0, 'parents': ['vaYc']},
	{'value': 'vaYcayAm', 'label': 'vaYcayAm', 'attrib': [{'pos': 'ppf_actn', 'defs': 'deceive'}], 'isLemma': 0, 'parents': ['vaYc']},
	{'value': 'vaYcayitavya', 'label': 'vaYcayitavya', 'attrib': [{'pos': 'ger', 'defs': 'deceive'}], 'isLemma': 0, 'parents': ['vaYc']},
	{'value': 'vaYcayitum', 'label': 'vaYcayitum', 'attrib': [{'pos': 'inf', 'defs': 'deceive'}], 'isLemma': 0, 'parents': ['vaYc']},
	{'value': 'vaYcayitvA', 'label': 'vaYcayitvA', 'attrib': [{'pos': 'abs', 'defs': 'deceive'}], 'isLemma': 0, 'parents': ['vaYc']},
	{'value': 'vaYcayizyamARa', 'label': 'vaYcayizyamARa', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'deceive'}], 'isLemma': 0, 'parents': ['vaYc']},
	{'value': 'vaYcitvA', 'label': 'vaYcitvA', 'attrib': [{'pos': 'abs', 'defs': 'deceive'}], 'isLemma': 0, 'parents': ['vaYc']},
	{'value': 'vaYcya', 'label': 'vaYcya', 'attrib': [{'pos': 'ger', 'defs': 'deceive'}], 'isLemma': 0, 'parents': ['vaYc']},
	{'value': 'vaYcyamAna', 'label': 'vaYcyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'deceive'}], 'isLemma': 0, 'parents': ['vaYc']},
	{'value': 'vatsyat', 'label': 'vatsyat', 'attrib': [{'pos': 'fap', 'defs': 'dwell, live'}], 'isLemma': 0, 'parents': ['vas']},
	{'value': 'vatsyamAna', 'label': 'vatsyamAna', 'attrib': [{'pos': 'fpp', 'defs': 'dwell, live'}], 'isLemma': 0, 'parents': ['vas']},
	{'value': 'vad', 'label': 'vad', 'attrib': [{'pos': 'vt1a', 'defs': 'tell'}], 'isLemma': 1},
	{'value': 'vadat', 'label': 'vadat', 'attrib': [{'pos': 'prap', 'defs': 'tell'}], 'isLemma': 0, 'parents': ['vad']},
	{'value': 'vadanIya', 'label': 'vadanIya', 'attrib': [{'pos': 'ger', 'defs': 'tell'}], 'isLemma': 0, 'parents': ['vad']},
	{'value': 'vaditavya', 'label': 'vaditavya', 'attrib': [{'pos': 'ger', 'defs': 'tell'}], 'isLemma': 0, 'parents': ['vad']},
	{'value': 'vaditum', 'label': 'vaditum', 'attrib': [{'pos': 'inf', 'defs': 'tell'}], 'isLemma': 0, 'parents': ['vad']},
	{'value': 'vadizyat', 'label': 'vadizyat', 'attrib': [{'pos': 'fap', 'defs': 'tell'}], 'isLemma': 0, 'parents': ['vad']},
	{'value': 'vadizyamARa', 'label': 'vadizyamARa', 'attrib': [{'pos': 'fpp', 'defs': 'tell'}], 'isLemma': 0, 'parents': ['vad']},
	{'value': 'vaD', 'label': 'vaD', 'attrib': [{'pos': 'vta', 'defs': 'smite, slay'}], 'isLemma': 1},
	{'value': 'vaDa', 'label': 'vaDa', 'attrib': [{'pos': 'm', 'defs': 'killing, slaughter'}], 'isLemma': 1},
	{'value': 'vana', 'label': 'vana', 'attrib': [{'pos': 'n', 'defs': 'forest'}], 'isLemma': 1},
	{'value': 'vanavAsa', 'label': 'vanavAsa', 'attrib': [{'pos': 'm', 'defs': 'forest-dwelling'}], 'isLemma': 1},
	{'value': 'vara', 'label': 'vara', 'attrib': [{'pos': 'adj', 'defs': 'elect, excellent'}, {'pos': 'm', 'defs': 'choice, boon'}], 'isLemma': 1},
	{'value': 'varaRIya', 'label': 'varaRIya', 'attrib': [{'pos': 'ger', 'defs': 'cover'}, {'pos': 'ger', 'defs': 'choose'}], 'isLemma': 0, 'parents': ['vf']},
	{'value': 'varitavya', 'label': 'varitavya', 'attrib': [{'pos': 'ger', 'defs': 'cover'}, {'pos': 'ger', 'defs': 'choose'}], 'isLemma': 0, 'parents': ['vf']},
	{'value': 'varitum', 'label': 'varitum', 'attrib': [{'pos': 'inf', 'defs': 'cover'}, {'pos': 'inf', 'defs': 'choose'}], 'isLemma': 0, 'parents': ['vf']},
	{'value': 'varizyat', 'label': 'varizyat', 'attrib': [{'pos': 'fap', 'defs': 'cover'}], 'isLemma': 0, 'parents': ['vf']},
	{'value': 'varizyamARa', 'label': 'varizyamARa', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'cover'}, {'pos': 'fmp, fpp', 'defs': 'choose'}], 'isLemma': 0, 'parents': ['vf']},
	{'value': 'varItavya', 'label': 'varItavya', 'attrib': [{'pos': 'ger', 'defs': 'cover'}], 'isLemma': 0, 'parents': ['vf']},
	{'value': 'varItum', 'label': 'varItum', 'attrib': [{'pos': 'inf', 'defs': 'cover'}, {'pos': 'inf', 'defs': 'choose'}], 'isLemma': 0, 'parents': ['vf']},
	{'value': 'varIzyat', 'label': 'varIzyat', 'attrib': [{'pos': 'fap', 'defs': 'cover'}], 'isLemma': 0, 'parents': ['vf']},
	{'value': 'varIzyamARa', 'label': 'varIzyamARa', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'cover'}, {'pos': 'fmp, fpp', 'defs': 'choose'}], 'isLemma': 0, 'parents': ['vf']},
	{'value': 'vartanIya', 'label': 'vartanIya', 'attrib': [{'pos': 'ger', 'defs': 'occur, be present'}], 'isLemma': 0, 'parents': ['vft']},
	{'value': 'vartamAna', 'label': 'vartamAna', 'attrib': [{'pos': 'prmp', 'defs': 'occur, be present'}], 'isLemma': 0, 'parents': ['vft']},
	{'value': 'vartitavya', 'label': 'vartitavya', 'attrib': [{'pos': 'ger', 'defs': 'occur, be present'}], 'isLemma': 0, 'parents': ['vft']},
	{'value': 'vartitum', 'label': 'vartitum', 'attrib': [{'pos': 'inf', 'defs': 'occur, be present'}], 'isLemma': 0, 'parents': ['vft']},
	{'value': 'vartitvA', 'label': 'vartitvA', 'attrib': [{'pos': 'abs', 'defs': 'occur, be present'}], 'isLemma': 0, 'parents': ['vft']},
	{'value': 'vartizyamARa', 'label': 'vartizyamARa', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'occur, be present'}], 'isLemma': 0, 'parents': ['vft']},
	{'value': 'varza', 'label': 'varza', 'attrib': [{'pos': 'm, n', 'defs': 'rain, year'}], 'isLemma': 1},
	{'value': 'varzaRIya', 'label': 'varzaRIya', 'attrib': [{'pos': 'ger', 'defs': 'rain'}], 'isLemma': 0, 'parents': ['vfz']},
	{'value': 'varzat', 'label': 'varzat', 'attrib': [{'pos': 'prap', 'defs': 'rain'}], 'isLemma': 0, 'parents': ['vfz']},
	{'value': 'varzitavya', 'label': 'varzitavya', 'attrib': [{'pos': 'ger', 'defs': 'rain'}], 'isLemma': 0, 'parents': ['vfz']},
	{'value': 'varzitum', 'label': 'varzitum', 'attrib': [{'pos': 'inf', 'defs': 'rain'}], 'isLemma': 0, 'parents': ['vfz']},
	{'value': 'varzitvA', 'label': 'varzitvA', 'attrib': [{'pos': 'abs', 'defs': 'rain'}], 'isLemma': 0, 'parents': ['vfz']},
	{'value': 'varzizyat', 'label': 'varzizyat', 'attrib': [{'pos': 'fap', 'defs': 'rain'}], 'isLemma': 0, 'parents': ['vfz']},
	{'value': 'varzya', 'label': 'varzya', 'attrib': [{'pos': 'ger', 'defs': 'rain'}], 'isLemma': 0, 'parents': ['vfz']},
	{'value': 'varzyamARa', 'label': 'varzyamARa', 'attrib': [{'pos': 'fpp', 'defs': 'rain'}], 'isLemma': 0, 'parents': ['vfz']},
	{'value': 'vavftAna', 'label': 'vavftAna', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'occur, be present'}], 'isLemma': 0, 'parents': ['vft']},
	{'value': 'vavfvas', 'label': 'vavfvas', 'attrib': [{'pos': 'pfap', 'defs': 'cover'}], 'isLemma': 0, 'parents': ['vf']},
	{'value': 'vavfzAna', 'label': 'vavfzAna', 'attrib': [{'pos': 'pfpp', 'defs': 'rain'}], 'isLemma': 0, 'parents': ['vfz']},
	{'value': 'vavfzivas', 'label': 'vavfzivas', 'attrib': [{'pos': 'pfap', 'defs': 'rain'}], 'isLemma': 0, 'parents': ['vfz']},
	{'value': 'vavrajAna', 'label': 'vavrajAna', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'walk'}], 'isLemma': 0, 'parents': ['vraj']},
	{'value': 'vavrajivas', 'label': 'vavrajivas', 'attrib': [{'pos': 'pfap', 'defs': 'walk'}], 'isLemma': 0, 'parents': ['vraj']},
	{'value': 'vavrARa', 'label': 'vavrARa', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'cover'}, {'pos': 'pfmp, pfpp', 'defs': 'choose'}], 'isLemma': 0, 'parents': ['vf']},
	{'value': 'vaS', 'label': 'vaS', 'attrib': [{'pos': 'vt2a', 'defs': 'wish'}], 'isLemma': 1},
	{'value': 'vaSa', 'label': 'vaSa', 'attrib': [{'pos': 'm', 'defs': 'will, wish, desire, control'}], 'isLemma': 1},
	{'value': 'vaSanIya', 'label': 'vaSanIya', 'attrib': [{'pos': 'ger', 'defs': 'wish'}], 'isLemma': 0, 'parents': ['vaS']},
	{'value': 'vaSitavya', 'label': 'vaSitavya', 'attrib': [{'pos': 'ger', 'defs': 'wish'}], 'isLemma': 0, 'parents': ['vaS']},
	{'value': 'vaSitum', 'label': 'vaSitum', 'attrib': [{'pos': 'inf', 'defs': 'wish'}], 'isLemma': 0, 'parents': ['vaS']},
	{'value': 'vaSitvA', 'label': 'vaSitvA', 'attrib': [{'pos': 'abs', 'defs': 'wish'}], 'isLemma': 0, 'parents': ['vaS']},
	{'value': 'vaSizyat', 'label': 'vaSizyat', 'attrib': [{'pos': 'fap', 'defs': 'wish'}], 'isLemma': 0, 'parents': ['vaS']},
	{'value': 'vaSizyamARa', 'label': 'vaSizyamARa', 'attrib': [{'pos': 'fpp', 'defs': 'wish'}], 'isLemma': 0, 'parents': ['vaS']},
	{'value': 'vaSya', 'label': 'vaSya', 'attrib': [{'pos': 'ger', 'defs': 'wish'}], 'isLemma': 0, 'parents': ['vaS']},
	{'value': 'vas', 'label': 'vas', 'attrib': [{'pos': 'vi1a', 'defs': 'dwell, live'}], 'isLemma': 1},
	{'value': 'vasat', 'label': 'vasat', 'attrib': [{'pos': 'prap', 'defs': 'dwell, live'}], 'isLemma': 0, 'parents': ['vas']},
	{'value': 'vasanIya', 'label': 'vasanIya', 'attrib': [{'pos': 'ger', 'defs': 'dwell, live'}], 'isLemma': 0, 'parents': ['vas']},
	{'value': 'vasitavya', 'label': 'vasitavya', 'attrib': [{'pos': 'ger', 'defs': 'dwell, live'}], 'isLemma': 0, 'parents': ['vas']},
	{'value': 'vasu', 'label': 'vasu', 'attrib': [{'pos': 'adj', 'defs': 'excellent, good, beneficent'}, {'pos': 'n', 'defs': 'goods, property'}], 'isLemma': 1},
	{'value': 'vasuMDara', 'label': 'vasuMDara', 'attrib': [{'pos': 'adj', 'defs': 'holding wealth'}], 'isLemma': 1},
	{'value': 'vasuMDarA', 'label': 'vasuMDarA', 'attrib': [{'pos': 'f', 'defs': 'the earth'}], 'isLemma': 1},
	{'value': 'vasudeva', 'label': 'vasudeva', 'attrib': [{'pos': 'm', 'defs': 'Vasudeva'}], 'isLemma': 1},
	{'value': 'vastum', 'label': 'vastum', 'attrib': [{'pos': 'inf', 'defs': 'dwell, live'}], 'isLemma': 0, 'parents': ['vas']},
	{'value': 'vah', 'label': 'vah', 'attrib': [{'pos': 'vt1am', 'defs': 'carry, flow, wed'}], 'isLemma': 1},
	{'value': 'vahat', 'label': 'vahat', 'attrib': [{'pos': 'prap', 'defs': 'carry, flow, wed'}], 'isLemma': 0, 'parents': ['vah']},
	{'value': 'vahamAna', 'label': 'vahamAna', 'attrib': [{'pos': 'prmp', 'defs': 'carry, flow, wed'}], 'isLemma': 0, 'parents': ['vah']},
	{'value': 'vahizyat', 'label': 'vahizyat', 'attrib': [{'pos': 'fap', 'defs': 'carry, flow, wed'}], 'isLemma': 0, 'parents': ['vah']},
	{'value': 'vahizyamARa', 'label': 'vahizyamARa', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'carry, flow, wed'}], 'isLemma': 0, 'parents': ['vah']},
	{'value': 'vAkya', 'label': 'vAkya', 'attrib': [{'pos': 'ger', 'defs': 'say, speak'}], 'isLemma': 0, 'parents': ['vac']},
	{'value': 'vAdya', 'label': 'vAdya', 'attrib': [{'pos': 'ger', 'defs': 'tell'}], 'isLemma': 0, 'parents': ['vad']},
	{'value': 'vAraRIya', 'label': 'vAraRIya', 'attrib': [{'pos': 'ger', 'defs': 'block'}], 'isLemma': 0, 'parents': ['vf']},
	{'value': 'vArayat', 'label': 'vArayat', 'attrib': [{'pos': 'prap', 'defs': 'block'}], 'isLemma': 0, 'parents': ['vf']},
	{'value': 'vArayamARa', 'label': 'vArayamARa', 'attrib': [{'pos': 'prmp', 'defs': 'block'}], 'isLemma': 0, 'parents': ['vf']},
	{'value': 'vArayAm', 'label': 'vArayAm', 'attrib': [{'pos': 'ppf_actn', 'defs': 'block'}], 'isLemma': 0, 'parents': ['vf']},
	{'value': 'vArayitavya', 'label': 'vArayitavya', 'attrib': [{'pos': 'ger', 'defs': 'block'}], 'isLemma': 0, 'parents': ['vf']},
	{'value': 'vArayitum', 'label': 'vArayitum', 'attrib': [{'pos': 'inf', 'defs': 'block'}], 'isLemma': 0, 'parents': ['vf']},
	{'value': 'vArayitvA', 'label': 'vArayitvA', 'attrib': [{'pos': 'abs', 'defs': 'block'}], 'isLemma': 0, 'parents': ['vf']},
	{'value': 'vArayizyat', 'label': 'vArayizyat', 'attrib': [{'pos': 'fap', 'defs': 'block'}], 'isLemma': 0, 'parents': ['vf']},
	{'value': 'vArayizyamARa', 'label': 'vArayizyamARa', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'block'}], 'isLemma': 0, 'parents': ['vf']},
	{'value': 'vArita', 'label': 'vArita', 'attrib': [{'pos': 'ppp', 'defs': 'block'}], 'isLemma': 0, 'parents': ['vf']},
	{'value': 'vAritavat', 'label': 'vAritavat', 'attrib': [{'pos': 'pap', 'defs': 'block'}], 'isLemma': 0, 'parents': ['vf']},
	{'value': 'vArya', 'label': 'vArya', 'attrib': [{'pos': 'ger', 'defs': 'block'}, {'pos': 'ger', 'defs': 'choose'}], 'isLemma': 0, 'parents': ['vf']},
	{'value': 'vAryamARa', 'label': 'vAryamARa', 'attrib': [{'pos': 'prpp', 'defs': 'block'}], 'isLemma': 0, 'parents': ['vf']},
	{'value': 'vAsa', 'label': 'vAsa', 'attrib': [{'pos': 'm', 'defs': 'dwelling, living'}], 'isLemma': 1},
	{'value': 'vAsayAm', 'label': 'vAsayAm', 'attrib': [{'pos': 'ppf_actn', 'defs': 'dwell, live'}], 'isLemma': 0, 'parents': ['vas']},
	{'value': 'vAsudeva', 'label': 'vAsudeva', 'attrib': [{'pos': 'm', 'defs': 'son of Vasudeva'}], 'isLemma': 1},
	{'value': 'vAsya', 'label': 'vAsya', 'attrib': [{'pos': 'ger', 'defs': 'dwell, live'}], 'isLemma': 0, 'parents': ['vas']},
	{'value': 'vAhana', 'label': 'vAhana', 'attrib': [{'pos': 'adj', 'defs': 'carrying'}, {'pos': 'n', 'defs': 'vehicle'}], 'isLemma': 1},
	{'value': 'vAhanIya', 'label': 'vAhanIya', 'attrib': [{'pos': 'ger', 'defs': 'carry, flow, wed'}], 'isLemma': 0, 'parents': ['vah']},
	{'value': 'vAhya', 'label': 'vAhya', 'attrib': [{'pos': 'ger', 'defs': 'carry, flow, wed'}], 'isLemma': 0, 'parents': ['vah']},
	{'value': 'vi', 'label': 'vi', 'attrib': [{'pos': 'preverb', 'defs': 'apart'}], 'isLemma': 1},
	{'value': 'vi-vftya', 'label': 'vi-vftya', 'attrib': [{'pos': 'abs', 'defs': 'uncover, reveal, explain'}], 'isLemma': 0, 'parents': ['vi_vf']},
	{'value': 'vikrama', 'label': 'vikrama', 'attrib': [{'pos': 'm', 'defs': 'wide step, valor'}], 'isLemma': 1},
	{'value': 'vigraha', 'label': 'vigraha', 'attrib': [{'pos': 'm', 'defs': 'division, discord, war'}], 'isLemma': 1},
	{'value': 'vijayanIya', 'label': 'vijayanIya', 'attrib': [{'pos': 'ger', 'defs': 'conquer'}], 'isLemma': 0, 'parents': ['vi_ji']},
	{'value': 'vijayamAna', 'label': 'vijayamAna', 'attrib': [{'pos': 'prmp', 'defs': 'conquer'}], 'isLemma': 0, 'parents': ['vi_ji']},
	{'value': 'vijigyAna', 'label': 'vijigyAna', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'conquer'}], 'isLemma': 0, 'parents': ['vi_ji']},
	{'value': 'vijita', 'label': 'vijita', 'attrib': [{'pos': 'ppp', 'defs': 'conquer'}], 'isLemma': 0, 'parents': ['vi_ji']},
	{'value': 'vijitavat', 'label': 'vijitavat', 'attrib': [{'pos': 'pap', 'defs': 'conquer'}], 'isLemma': 0, 'parents': ['vi_ji']},
	{'value': 'vijitya', 'label': 'vijitya', 'attrib': [{'pos': 'abs', 'defs': 'conquer'}], 'isLemma': 0, 'parents': ['vi_ji']},
	{'value': 'vijIyamAna', 'label': 'vijIyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'conquer'}], 'isLemma': 0, 'parents': ['vi_ji']},
	{'value': 'vijetavya', 'label': 'vijetavya', 'attrib': [{'pos': 'ger', 'defs': 'conquer'}], 'isLemma': 0, 'parents': ['vi_ji']},
	{'value': 'vijetum', 'label': 'vijetum', 'attrib': [{'pos': 'inf', 'defs': 'conquer'}], 'isLemma': 0, 'parents': ['vi_ji']},
	{'value': 'vijeya', 'label': 'vijeya', 'attrib': [{'pos': 'ger', 'defs': 'conquer'}], 'isLemma': 0, 'parents': ['vi_ji']},
	{'value': 'vijezyamARa', 'label': 'vijezyamARa', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'conquer'}], 'isLemma': 0, 'parents': ['vi_ji']},
	{'value': 'vi_ava_so', 'label': 'vi_ava_so', 'attrib': [{'pos': 'vt4a', 'defs': 'conclude, determine'}], 'isLemma': 1},
	{'value': 'vi_kram', 'label': 'vi_kram', 'attrib': [{'pos': 'vt1a', 'defs': 'step wide, be bold, advance'}], 'isLemma': 1},
	{'value': 'vi_grah', 'label': 'vi_grah', 'attrib': [{'pos': 'vt9a', 'defs': 'take apart, analyse, wage war'}], 'isLemma': 1},
	{'value': 'vi_ji', 'label': 'vi_ji', 'attrib': [{'pos': 'vt1m', 'defs': 'conquer'}], 'isLemma': 1},
	{'value': 'vi_dru', 'label': 'vi_dru', 'attrib': [{'pos': 'vt1a', 'defs': 'run apart, disperse'}], 'isLemma': 1},
	{'value': 'vi_DA', 'label': 'vi_DA', 'attrib': [{'pos': 'vt3am', 'defs': 'bestow, supply, ordain'}], 'isLemma': 1},
	{'value': 'vi_naS', 'label': 'vi_naS', 'attrib': [{'pos': 'vi4a', 'defs': 'perish'}], 'isLemma': 1},
	{'value': 'vi_ni_han', 'label': 'vi_ni_han', 'attrib': [{'pos': 'vt2a', 'defs': 'strike down, destroy'}], 'isLemma': 1},
	{'value': 'vi_Baj', 'label': 'vi_Baj', 'attrib': [{'pos': 'vt1am', 'defs': 'divide, distribute, apportion'}], 'isLemma': 1},
	{'value': 'vi_rAj', 'label': 'vi_rAj', 'attrib': [{'pos': 'vt1am', 'defs': 'shine, rule'}], 'isLemma': 1},
	{'value': 'vi_vf', 'label': 'vi_vf', 'attrib': [{'pos': 'vt5am', 'defs': 'uncover, reveal, explain'}], 'isLemma': 1},
	{'value': 'vi_SramB', 'label': 'vi_SramB', 'attrib': [{'pos': 'vi1m', 'defs': 'be careless, negligent'}], 'isLemma': 1},
	{'value': 'vi_Sru', 'label': 'vi_Sru', 'attrib': [{'pos': 'vt1am', 'defs': 'hear widely'}], 'isLemma': 1},
	{'value': 'vi_sramB', 'label': 'vi_sramB', 'attrib': [{'pos': 'vi1m', 'defs': 'be confident, trust, relax'}], 'isLemma': 1},
	{'value': 'vitta', 'label': 'vitta', 'attrib': [{'pos': 'ppp', 'defs': 'find, get'}], 'isLemma': 0, 'parents': ['vid']},
	{'value': 'vittavat', 'label': 'vittavat', 'attrib': [{'pos': 'pap', 'defs': 'find, get'}], 'isLemma': 0, 'parents': ['vid']},
	{'value': 'vittvA', 'label': 'vittvA', 'attrib': [{'pos': 'abs', 'defs': 'find, get'}], 'isLemma': 0, 'parents': ['vid']},
	{'value': 'vid', 'label': 'vid', 'attrib': [{'pos': 'vt2a', 'defs': 'know'}, {'pos': 'vt6am', 'defs': 'find, get'}], 'isLemma': 1},
	{'value': 'vidaDat', 'label': 'vidaDat', 'attrib': [{'pos': 'prap', 'defs': 'bestow, supply, ordain'}], 'isLemma': 0, 'parents': ['vi_DA']},
	{'value': 'vidaDAna', 'label': 'vidaDAna', 'attrib': [{'pos': 'prmp', 'defs': 'bestow, supply, ordain'}, {'pos': 'pfmp, pfpp', 'defs': 'bestow, supply, ordain'}], 'isLemma': 0, 'parents': ['vi_DA']},
	{'value': 'vidaDivas', 'label': 'vidaDivas', 'attrib': [{'pos': 'pfap', 'defs': 'bestow, supply, ordain'}], 'isLemma': 0, 'parents': ['vi_DA']},
	{'value': 'vidAm', 'label': 'vidAm', 'attrib': [{'pos': 'ppf_actn', 'defs': 'know'}], 'isLemma': 0, 'parents': ['vid']},
	{'value': 'vidita', 'label': 'vidita', 'attrib': [{'pos': 'ppp', 'defs': 'know'}], 'isLemma': 0, 'parents': ['vid']},
	{'value': 'viditavat', 'label': 'viditavat', 'attrib': [{'pos': 'pap', 'defs': 'know'}], 'isLemma': 0, 'parents': ['vid']},
	{'value': 'viditvA', 'label': 'viditvA', 'attrib': [{'pos': 'abs', 'defs': 'know'}, {'pos': 'abs', 'defs': 'find, get'}], 'isLemma': 0, 'parents': ['vid']},
	{'value': 'vidudruvas', 'label': 'vidudruvas', 'attrib': [{'pos': 'pfap', 'defs': 'run apart, disperse'}], 'isLemma': 0, 'parents': ['vi_dru']},
	{'value': 'vidudruvARa', 'label': 'vidudruvARa', 'attrib': [{'pos': 'pfpp', 'defs': 'run apart, disperse'}], 'isLemma': 0, 'parents': ['vi_dru']},
	{'value': 'vidura', 'label': 'vidura', 'attrib': [{'pos': 'adj', 'defs': 'knowledgeable, wise'}, {'pos': 'm_pn', 'defs': 'Vidura'}], 'isLemma': 1},
	{'value': 'vidyamAna', 'label': 'vidyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'know'}, {'pos': 'prpp', 'defs': 'find, get'}], 'isLemma': 0, 'parents': ['vid']},
	{'value': 'vidravaRIya', 'label': 'vidravaRIya', 'attrib': [{'pos': 'ger', 'defs': 'run apart, disperse'}], 'isLemma': 0, 'parents': ['vi_dru']},
	{'value': 'vidravat', 'label': 'vidravat', 'attrib': [{'pos': 'prap', 'defs': 'run apart, disperse'}], 'isLemma': 0, 'parents': ['vi_dru']},
	{'value': 'vidravya', 'label': 'vidravya', 'attrib': [{'pos': 'ger', 'defs': 'run apart, disperse'}], 'isLemma': 0, 'parents': ['vi_dru']},
	{'value': 'vidruta', 'label': 'vidruta', 'attrib': [{'pos': 'ppp', 'defs': 'run apart, disperse'}], 'isLemma': 0, 'parents': ['vi_dru']},
	{'value': 'vidrutaBUyizWa', 'label': 'vidrutaBUyizWa', 'attrib': [{'pos': 'adj', 'defs': 'mostly destroyed'}], 'isLemma': 1},
	{'value': 'vidrutavat', 'label': 'vidrutavat', 'attrib': [{'pos': 'pap', 'defs': 'run apart, disperse'}], 'isLemma': 0, 'parents': ['vi_dru']},
	{'value': 'vidrutya', 'label': 'vidrutya', 'attrib': [{'pos': 'abs', 'defs': 'run apart, disperse'}], 'isLemma': 0, 'parents': ['vi_dru']},
	{'value': 'vidrUyamARa', 'label': 'vidrUyamARa', 'attrib': [{'pos': 'prpp', 'defs': 'run apart, disperse'}], 'isLemma': 0, 'parents': ['vi_dru']},
	{'value': 'vidrotavya', 'label': 'vidrotavya', 'attrib': [{'pos': 'ger', 'defs': 'run apart, disperse'}], 'isLemma': 0, 'parents': ['vi_dru']},
	{'value': 'vidrotum', 'label': 'vidrotum', 'attrib': [{'pos': 'inf', 'defs': 'run apart, disperse'}], 'isLemma': 0, 'parents': ['vi_dru']},
	{'value': 'vidrozyat', 'label': 'vidrozyat', 'attrib': [{'pos': 'fap', 'defs': 'run apart, disperse'}], 'isLemma': 0, 'parents': ['vi_dru']},
	{'value': 'vidrozyamARa', 'label': 'vidrozyamARa', 'attrib': [{'pos': 'fpp', 'defs': 'run apart, disperse'}], 'isLemma': 0, 'parents': ['vi_dru']},
	{'value': 'vidvas', 'label': 'vidvas', 'attrib': [{'pos': 'prap', 'defs': 'know'}], 'isLemma': 0, 'parents': ['vid']},
	{'value': 'viDAtavya', 'label': 'viDAtavya', 'attrib': [{'pos': 'ger', 'defs': 'bestow, supply, ordain'}], 'isLemma': 0, 'parents': ['vi_DA']},
	{'value': 'viDAtum', 'label': 'viDAtum', 'attrib': [{'pos': 'inf', 'defs': 'bestow, supply, ordain'}], 'isLemma': 0, 'parents': ['vi_DA']},
	{'value': 'viDAnIya', 'label': 'viDAnIya', 'attrib': [{'pos': 'ger', 'defs': 'bestow, supply, ordain'}], 'isLemma': 0, 'parents': ['vi_DA']},
	{'value': 'viDAya', 'label': 'viDAya', 'attrib': [{'pos': 'abs', 'defs': 'bestow, supply, ordain'}], 'isLemma': 0, 'parents': ['vi_DA']},
	{'value': 'viDAsyat', 'label': 'viDAsyat', 'attrib': [{'pos': 'fap', 'defs': 'bestow, supply, ordain'}], 'isLemma': 0, 'parents': ['vi_DA']},
	{'value': 'viDAsyamAna', 'label': 'viDAsyamAna', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'bestow, supply, ordain'}], 'isLemma': 0, 'parents': ['vi_DA']},
	{'value': 'viDIyamAna', 'label': 'viDIyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'bestow, supply, ordain'}], 'isLemma': 0, 'parents': ['vi_DA']},
	{'value': 'viDeya', 'label': 'viDeya', 'attrib': [{'pos': 'ger', 'defs': 'bestow, supply, ordain'}], 'isLemma': 0, 'parents': ['vi_DA']},
	{'value': 'vinaSanIya', 'label': 'vinaSanIya', 'attrib': [{'pos': 'ger', 'defs': 'perish'}], 'isLemma': 0, 'parents': ['vi_naS']},
	{'value': 'vinaSya', 'label': 'vinaSya', 'attrib': [{'pos': 'abs', 'defs': 'perish'}], 'isLemma': 0, 'parents': ['vi_naS']},
	{'value': 'vinaSyat', 'label': 'vinaSyat', 'attrib': [{'pos': 'prap', 'defs': 'perish'}], 'isLemma': 0, 'parents': ['vi_naS']},
	{'value': 'vinazwa', 'label': 'vinazwa', 'attrib': [{'pos': 'ppp', 'defs': 'perish'}], 'isLemma': 0, 'parents': ['vi_naS']},
	{'value': 'vinazwavat', 'label': 'vinazwavat', 'attrib': [{'pos': 'ppp', 'defs': 'perish'}], 'isLemma': 0, 'parents': ['vi_naS']},
	{'value': 'vinazwavya', 'label': 'vinazwavya', 'attrib': [{'pos': 'ger', 'defs': 'perish'}], 'isLemma': 0, 'parents': ['vi_naS']},
	{'value': 'vinazwum', 'label': 'vinazwum', 'attrib': [{'pos': 'inf', 'defs': 'perish'}], 'isLemma': 0, 'parents': ['vi_naS']},
	{'value': 'vinASa', 'label': 'vinASa', 'attrib': [{'pos': 'm', 'defs': 'destruction'}], 'isLemma': 1},
	{'value': 'vinASya', 'label': 'vinASya', 'attrib': [{'pos': 'ger', 'defs': 'perish'}], 'isLemma': 0, 'parents': ['vi_naS']},
	{'value': 'viniGAtya', 'label': 'viniGAtya', 'attrib': [{'pos': 'ger', 'defs': 'strike down, destroy'}], 'isLemma': 0, 'parents': ['vi_ni_han']},
	{'value': 'viniGnat', 'label': 'viniGnat', 'attrib': [{'pos': 'prap', 'defs': 'strike down, destroy'}], 'isLemma': 0, 'parents': ['vi_ni_han']},
	{'value': 'vinijaGanvas', 'label': 'vinijaGanvas', 'attrib': [{'pos': 'pfap', 'defs': 'strike down, destroy'}], 'isLemma': 0, 'parents': ['vi_ni_han']},
	{'value': 'vinijaGnAna', 'label': 'vinijaGnAna', 'attrib': [{'pos': 'pfpp', 'defs': 'strike down, destroy'}], 'isLemma': 0, 'parents': ['vi_ni_han']},
	{'value': 'vinijaGnivas', 'label': 'vinijaGnivas', 'attrib': [{'pos': 'pfap', 'defs': 'strike down, destroy'}], 'isLemma': 0, 'parents': ['vi_ni_han']},
	{'value': 'vinihaMsyat', 'label': 'vinihaMsyat', 'attrib': [{'pos': 'fap', 'defs': 'strike down, destroy'}], 'isLemma': 0, 'parents': ['vi_ni_han']},
	{'value': 'vinihaMsyamAna', 'label': 'vinihaMsyamAna', 'attrib': [{'pos': 'fpp', 'defs': 'strike down, destroy'}], 'isLemma': 0, 'parents': ['vi_ni_han']},
	{'value': 'vinihata', 'label': 'vinihata', 'attrib': [{'pos': 'ppp', 'defs': 'strike down, destroy'}], 'isLemma': 0, 'parents': ['vi_ni_han']},
	{'value': 'vinihatavat', 'label': 'vinihatavat', 'attrib': [{'pos': 'pap', 'defs': 'strike down, destroy'}], 'isLemma': 0, 'parents': ['vi_ni_han']},
	{'value': 'vinihananIya', 'label': 'vinihananIya', 'attrib': [{'pos': 'ger', 'defs': 'strike down, destroy'}], 'isLemma': 0, 'parents': ['vi_ni_han']},
	{'value': 'vinihanizyat', 'label': 'vinihanizyat', 'attrib': [{'pos': 'fap', 'defs': 'strike down, destroy'}], 'isLemma': 0, 'parents': ['vi_ni_han']},
	{'value': 'vinihanizyamARa', 'label': 'vinihanizyamARa', 'attrib': [{'pos': 'fpp', 'defs': 'strike down, destroy'}], 'isLemma': 0, 'parents': ['vi_ni_han']},
	{'value': 'vinihantavya', 'label': 'vinihantavya', 'attrib': [{'pos': 'ger', 'defs': 'strike down, destroy'}], 'isLemma': 0, 'parents': ['vi_ni_han']},
	{'value': 'vinihantum', 'label': 'vinihantum', 'attrib': [{'pos': 'inf', 'defs': 'strike down, destroy'}], 'isLemma': 0, 'parents': ['vi_ni_han']},
	{'value': 'vinihanya', 'label': 'vinihanya', 'attrib': [{'pos': 'abs', 'defs': 'strike down, destroy'}], 'isLemma': 0, 'parents': ['vi_ni_han']},
	{'value': 'vinihanyamAna', 'label': 'vinihanyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'strike down, destroy'}], 'isLemma': 0, 'parents': ['vi_ni_han']},
	{'value': 'vineSivas', 'label': 'vineSivas', 'attrib': [{'pos': 'pfap', 'defs': 'perish'}], 'isLemma': 0, 'parents': ['vi_naS']},
	{'value': 'vineSvas', 'label': 'vineSvas', 'attrib': [{'pos': 'pfap', 'defs': 'perish'}], 'isLemma': 0, 'parents': ['vi_naS']},
	{'value': 'vindat', 'label': 'vindat', 'attrib': [{'pos': 'prap', 'defs': 'find, get'}], 'isLemma': 0, 'parents': ['vid']},
	{'value': 'vindamAna', 'label': 'vindamAna', 'attrib': [{'pos': 'prmp', 'defs': 'find, get'}], 'isLemma': 0, 'parents': ['vid']},
	{'value': 'viprakAra', 'label': 'viprakAra', 'attrib': [{'pos': 'm', 'defs': 'misdeed, offense'}], 'isLemma': 1},
	{'value': 'viBakta', 'label': 'viBakta', 'attrib': [{'pos': 'adj', 'defs': 'divided, distributed'}, {'pos': 'ppp', 'defs': 'divide, distribute, apportion'}], 'isLemma': 1},
	{'value': 'viBakta', 'label': 'viBakta', 'attrib': [{'pos': 'adj', 'defs': 'divided, distributed'}, {'pos': 'ppp', 'defs': 'divide, distribute, apportion'}], 'isLemma': 0, 'parents': ['vi_Baj']},
	{'value': 'viBaktavat', 'label': 'viBaktavat', 'attrib': [{'pos': 'pap', 'defs': 'divide, distribute, apportion'}], 'isLemma': 0, 'parents': ['vi_Baj']},
	{'value': 'viBaktavya', 'label': 'viBaktavya', 'attrib': [{'pos': 'ger', 'defs': 'divide, distribute, apportion'}], 'isLemma': 0, 'parents': ['vi_Baj']},
	{'value': 'viBaktum', 'label': 'viBaktum', 'attrib': [{'pos': 'inf', 'defs': 'divide, distribute, apportion'}], 'isLemma': 0, 'parents': ['vi_Baj']},
	{'value': 'viBakzyat', 'label': 'viBakzyat', 'attrib': [{'pos': 'fap', 'defs': 'divide, distribute, apportion'}], 'isLemma': 0, 'parents': ['vi_Baj']},
	{'value': 'viBakzyamAna', 'label': 'viBakzyamAna', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'divide, distribute, apportion'}], 'isLemma': 0, 'parents': ['vi_Baj']},
	{'value': 'viBajat', 'label': 'viBajat', 'attrib': [{'pos': 'prap', 'defs': 'divide, distribute, apportion'}], 'isLemma': 0, 'parents': ['vi_Baj']},
	{'value': 'viBajanIya', 'label': 'viBajanIya', 'attrib': [{'pos': 'ger', 'defs': 'divide, distribute, apportion'}], 'isLemma': 0, 'parents': ['vi_Baj']},
	{'value': 'viBajamAna', 'label': 'viBajamAna', 'attrib': [{'pos': 'prmp', 'defs': 'divide, distribute, apportion'}], 'isLemma': 0, 'parents': ['vi_Baj']},
	{'value': 'viBajya', 'label': 'viBajya', 'attrib': [{'pos': 'ger', 'defs': 'divide, distribute, apportion'}], 'isLemma': 0, 'parents': ['vi_Baj']},
	{'value': 'viBajyamAna', 'label': 'viBajyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'divide, distribute, apportion'}], 'isLemma': 0, 'parents': ['vi_Baj']},
	{'value': 'viBejAna', 'label': 'viBejAna', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'divide, distribute, apportion'}], 'isLemma': 0, 'parents': ['vi_Baj']},
	{'value': 'viBejivas', 'label': 'viBejivas', 'attrib': [{'pos': 'pfap', 'defs': 'divide, distribute, apportion'}], 'isLemma': 0, 'parents': ['vi_Baj']},
	{'value': 'virajamAna', 'label': 'virajamAna', 'attrib': [{'pos': 'prmp', 'defs': 'shine, rule'}], 'isLemma': 0, 'parents': ['vi_rAj']},
	{'value': 'virarAjAna', 'label': 'virarAjAna', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'shine, rule'}], 'isLemma': 0, 'parents': ['vi_rAj']},
	{'value': 'virarAjivas', 'label': 'virarAjivas', 'attrib': [{'pos': 'pfap', 'defs': 'shine, rule'}], 'isLemma': 0, 'parents': ['vi_rAj']},
	{'value': 'virAjat', 'label': 'virAjat', 'attrib': [{'pos': 'prap', 'defs': 'shine, rule'}], 'isLemma': 0, 'parents': ['vi_rAj']},
	{'value': 'virAjanIya', 'label': 'virAjanIya', 'attrib': [{'pos': 'ger', 'defs': 'shine, rule'}], 'isLemma': 0, 'parents': ['vi_rAj']},
	{'value': 'virAjita', 'label': 'virAjita', 'attrib': [{'pos': 'ppp', 'defs': 'shine, rule'}], 'isLemma': 0, 'parents': ['vi_rAj']},
	{'value': 'virAjitavat', 'label': 'virAjitavat', 'attrib': [{'pos': 'pap', 'defs': 'shine, rule'}], 'isLemma': 0, 'parents': ['vi_rAj']},
	{'value': 'virAjitavya', 'label': 'virAjitavya', 'attrib': [{'pos': 'ger', 'defs': 'shine, rule'}], 'isLemma': 0, 'parents': ['vi_rAj']},
	{'value': 'virAjitum', 'label': 'virAjitum', 'attrib': [{'pos': 'inf', 'defs': 'shine, rule'}], 'isLemma': 0, 'parents': ['vi_rAj']},
	{'value': 'virAjizyat', 'label': 'virAjizyat', 'attrib': [{'pos': 'fap', 'defs': 'shine, rule'}], 'isLemma': 0, 'parents': ['vi_rAj']},
	{'value': 'virAjizyamARa', 'label': 'virAjizyamARa', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'shine, rule'}], 'isLemma': 0, 'parents': ['vi_rAj']},
	{'value': 'virAjya', 'label': 'virAjya', 'attrib': [{'pos': 'abs', 'defs': 'shine, rule'}, {'pos': 'ger', 'defs': 'shine, rule'}], 'isLemma': 0, 'parents': ['vi_rAj']},
	{'value': 'virAjyamAna', 'label': 'virAjyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'shine, rule'}], 'isLemma': 0, 'parents': ['vi_rAj']},
	{'value': 'vivaraRIya', 'label': 'vivaraRIya', 'attrib': [{'pos': 'ger', 'defs': 'uncover, reveal, explain'}], 'isLemma': 0, 'parents': ['vi_vf']},
	{'value': 'vivaritavya', 'label': 'vivaritavya', 'attrib': [{'pos': 'ger', 'defs': 'uncover, reveal, explain'}], 'isLemma': 0, 'parents': ['vi_vf']},
	{'value': 'vivaritum', 'label': 'vivaritum', 'attrib': [{'pos': 'inf', 'defs': 'uncover, reveal, explain'}], 'isLemma': 0, 'parents': ['vi_vf']},
	{'value': 'vivarizyat', 'label': 'vivarizyat', 'attrib': [{'pos': 'fap', 'defs': 'uncover, reveal, explain'}], 'isLemma': 0, 'parents': ['vi_vf']},
	{'value': 'vivarizyamARa', 'label': 'vivarizyamARa', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'uncover, reveal, explain'}], 'isLemma': 0, 'parents': ['vi_vf']},
	{'value': 'vivarItavya', 'label': 'vivarItavya', 'attrib': [{'pos': 'ger', 'defs': 'uncover, reveal, explain'}], 'isLemma': 0, 'parents': ['vi_vf']},
	{'value': 'vivarItum', 'label': 'vivarItum', 'attrib': [{'pos': 'inf', 'defs': 'uncover, reveal, explain'}], 'isLemma': 0, 'parents': ['vi_vf']},
	{'value': 'vivarIzyat', 'label': 'vivarIzyat', 'attrib': [{'pos': 'fap', 'defs': 'uncover, reveal, explain'}], 'isLemma': 0, 'parents': ['vi_vf']},
	{'value': 'vivarIzyamARa', 'label': 'vivarIzyamARa', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'uncover, reveal, explain'}], 'isLemma': 0, 'parents': ['vi_vf']},
	{'value': 'vivavfvas', 'label': 'vivavfvas', 'attrib': [{'pos': 'pfap', 'defs': 'uncover, reveal, explain'}], 'isLemma': 0, 'parents': ['vi_vf']},
	{'value': 'vivavrARa', 'label': 'vivavrARa', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'uncover, reveal, explain'}], 'isLemma': 0, 'parents': ['vi_vf']},
	{'value': 'vividAna', 'label': 'vividAna', 'attrib': [{'pos': 'pfpp', 'defs': 'know'}, {'pos': 'pfmp, pfpp', 'defs': 'find, get'}], 'isLemma': 0, 'parents': ['vid']},
	{'value': 'vividivas', 'label': 'vividivas', 'attrib': [{'pos': 'pfap', 'defs': 'find, get'}], 'isLemma': 0, 'parents': ['vid']},
	{'value': 'vividvas', 'label': 'vividvas', 'attrib': [{'pos': 'pfap', 'defs': 'know'}, {'pos': 'pfap', 'defs': 'find, get'}], 'isLemma': 0, 'parents': ['vid']},
	{'value': 'viviDa', 'label': 'viviDa', 'attrib': [{'pos': 'adj', 'defs': 'various'}], 'isLemma': 1},
	{'value': 'viviDopAya', 'label': 'viviDopAya', 'attrib': [{'pos': 'm', 'defs': 'various means'}], 'isLemma': 1},
	{'value': 'vivfRvat', 'label': 'vivfRvat', 'attrib': [{'pos': 'prap', 'defs': 'uncover, reveal, explain'}], 'isLemma': 0, 'parents': ['vi_vf']},
	{'value': 'vivfRvAna', 'label': 'vivfRvAna', 'attrib': [{'pos': 'prmp', 'defs': 'uncover, reveal, explain'}], 'isLemma': 0, 'parents': ['vi_vf']},
	{'value': 'vivfta', 'label': 'vivfta', 'attrib': [{'pos': 'ppp', 'defs': 'uncover, reveal, explain'}], 'isLemma': 0, 'parents': ['vi_vf']},
	{'value': 'vivftavat', 'label': 'vivftavat', 'attrib': [{'pos': 'pap', 'defs': 'uncover, reveal, explain'}], 'isLemma': 0, 'parents': ['vi_vf']},
	{'value': 'vivftya', 'label': 'vivftya', 'attrib': [{'pos': 'ger', 'defs': 'uncover, reveal, explain'}], 'isLemma': 0, 'parents': ['vi_vf']},
	{'value': 'vivftvA', 'label': 'vivftvA', 'attrib': [{'pos': 'abs', 'defs': 'uncover, reveal, explain'}], 'isLemma': 0, 'parents': ['vi_vf']},
	{'value': 'vivyaTAna', 'label': 'vivyaTAna', 'attrib': [{'pos': 'pfpp', 'defs': 'tremble, fear'}], 'isLemma': 0, 'parents': ['vyaT']},
	{'value': 'vivyaTivas', 'label': 'vivyaTivas', 'attrib': [{'pos': 'pfap', 'defs': 'tremble, fear'}], 'isLemma': 0, 'parents': ['vyaT']},
	{'value': 'vivriyamARa', 'label': 'vivriyamARa', 'attrib': [{'pos': 'prpp', 'defs': 'uncover, reveal, explain'}], 'isLemma': 0, 'parents': ['vi_vf']},
	{'value': 'viS', 'label': 'viS', 'attrib': [{'pos': 'm', 'defs': 'settler, people'}], 'isLemma': 1},
	{'value': 'viSaMpa', 'label': 'viSaMpa', 'attrib': [{'pos': 'adj', 'defs': 'people-protecting'}], 'isLemma': 1},
	{'value': 'viSaSramBARa', 'label': 'viSaSramBARa', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'be careless, negligent'}], 'isLemma': 0, 'parents': ['vi_SramB']},
	{'value': 'viSfRvat', 'label': 'viSfRvat', 'attrib': [{'pos': 'prap', 'defs': 'hear widely'}], 'isLemma': 0, 'parents': ['vi_Sru']},
	{'value': 'viSrabDa', 'label': 'viSrabDa', 'attrib': [{'pos': 'ppp', 'defs': 'be careless, negligent'}], 'isLemma': 0, 'parents': ['vi_SramB']},
	{'value': 'viSrabDavat', 'label': 'viSrabDavat', 'attrib': [{'pos': 'pap', 'defs': 'be careless, negligent'}], 'isLemma': 0, 'parents': ['vi_SramB']},
	{'value': 'viSraBya', 'label': 'viSraBya', 'attrib': [{'pos': 'abs', 'defs': 'be careless, negligent'}], 'isLemma': 0, 'parents': ['vi_SramB']},
	{'value': 'viSraByamARa', 'label': 'viSraByamARa', 'attrib': [{'pos': 'prpp', 'defs': 'be careless, negligent'}], 'isLemma': 0, 'parents': ['vi_SramB']},
	{'value': 'viSramBaRIya', 'label': 'viSramBaRIya', 'attrib': [{'pos': 'ger', 'defs': 'be careless, negligent'}], 'isLemma': 0, 'parents': ['vi_SramB']},
	{'value': 'viSramBamARa', 'label': 'viSramBamARa', 'attrib': [{'pos': 'prmp', 'defs': 'be careless, negligent'}], 'isLemma': 0, 'parents': ['vi_SramB']},
	{'value': 'viSramBitavya', 'label': 'viSramBitavya', 'attrib': [{'pos': 'ger', 'defs': 'be careless, negligent'}], 'isLemma': 0, 'parents': ['vi_SramB']},
	{'value': 'viSramBitum', 'label': 'viSramBitum', 'attrib': [{'pos': 'inf', 'defs': 'be careless, negligent'}], 'isLemma': 0, 'parents': ['vi_SramB']},
	{'value': 'viSramBizyamARa', 'label': 'viSramBizyamARa', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'be careless, negligent'}], 'isLemma': 0, 'parents': ['vi_SramB']},
	{'value': 'viSramBya', 'label': 'viSramBya', 'attrib': [{'pos': 'ger', 'defs': 'be careless, negligent'}], 'isLemma': 0, 'parents': ['vi_SramB']},
	{'value': 'viSravaRIya', 'label': 'viSravaRIya', 'attrib': [{'pos': 'ger', 'defs': 'hear widely'}], 'isLemma': 0, 'parents': ['vi_Sru']},
	{'value': 'viSravya', 'label': 'viSravya', 'attrib': [{'pos': 'ger', 'defs': 'hear widely'}], 'isLemma': 0, 'parents': ['vi_Sru']},
	{'value': 'viSruta', 'label': 'viSruta', 'attrib': [{'pos': 'ppp', 'defs': 'hear widely'}], 'isLemma': 0, 'parents': ['vi_Sru']},
	{'value': 'viSrutavat', 'label': 'viSrutavat', 'attrib': [{'pos': 'pap', 'defs': 'hear widely'}], 'isLemma': 0, 'parents': ['vi_Sru']},
	{'value': 'viSrutya', 'label': 'viSrutya', 'attrib': [{'pos': 'abs', 'defs': 'hear widely'}], 'isLemma': 0, 'parents': ['vi_Sru']},
	{'value': 'viSrUyamARa', 'label': 'viSrUyamARa', 'attrib': [{'pos': 'prpp', 'defs': 'hear widely'}], 'isLemma': 0, 'parents': ['vi_Sru']},
	{'value': 'viSrotavya', 'label': 'viSrotavya', 'attrib': [{'pos': 'ger', 'defs': 'hear widely'}], 'isLemma': 0, 'parents': ['vi_Sru']},
	{'value': 'viSrotum', 'label': 'viSrotum', 'attrib': [{'pos': 'inf', 'defs': 'hear widely'}], 'isLemma': 0, 'parents': ['vi_Sru']},
	{'value': 'viza', 'label': 'viza', 'attrib': [{'pos': 'adj', 'defs': 'active, poisonous'}, {'pos': 'n', 'defs': 'poison'}], 'isLemma': 1},
	{'value': 'vizRu', 'label': 'vizRu', 'attrib': [{'pos': 'm_pn', 'defs': 'Viṣṇu'}], 'isLemma': 1},
	{'value': 'visasramBARa', 'label': 'visasramBARa', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'be confident, trust, relax'}], 'isLemma': 0, 'parents': ['vi_sramB']},
	{'value': 'visrabDa', 'label': 'visrabDa', 'attrib': [{'pos': 'ppp', 'defs': 'be confident, trust, relax'}], 'isLemma': 0, 'parents': ['vi_sramB']},
	{'value': 'visrabDavat', 'label': 'visrabDavat', 'attrib': [{'pos': 'pap', 'defs': 'be confident, trust, relax'}], 'isLemma': 0, 'parents': ['vi_sramB']},
	{'value': 'visraBya', 'label': 'visraBya', 'attrib': [{'pos': 'abs', 'defs': 'be confident, trust, relax'}], 'isLemma': 0, 'parents': ['vi_sramB']},
	{'value': 'visraByamARa', 'label': 'visraByamARa', 'attrib': [{'pos': 'prpp', 'defs': 'be confident, trust, relax'}], 'isLemma': 0, 'parents': ['vi_sramB']},
	{'value': 'visramBaRIya', 'label': 'visramBaRIya', 'attrib': [{'pos': 'ger', 'defs': 'be confident, trust, relax'}], 'isLemma': 0, 'parents': ['vi_sramB']},
	{'value': 'visramBamARa', 'label': 'visramBamARa', 'attrib': [{'pos': 'prmp', 'defs': 'be confident, trust, relax'}], 'isLemma': 0, 'parents': ['vi_sramB']},
	{'value': 'visramBitavya', 'label': 'visramBitavya', 'attrib': [{'pos': 'ger', 'defs': 'be confident, trust, relax'}], 'isLemma': 0, 'parents': ['vi_sramB']},
	{'value': 'visramBitum', 'label': 'visramBitum', 'attrib': [{'pos': 'inf', 'defs': 'be confident, trust, relax'}], 'isLemma': 0, 'parents': ['vi_sramB']},
	{'value': 'visramBizyamARa', 'label': 'visramBizyamARa', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'be confident, trust, relax'}], 'isLemma': 0, 'parents': ['vi_sramB']},
	{'value': 'visramBya', 'label': 'visramBya', 'attrib': [{'pos': 'ger', 'defs': 'be confident, trust, relax'}], 'isLemma': 0, 'parents': ['vi_sramB']},
	{'value': 'vihita', 'label': 'vihita', 'attrib': [{'pos': 'ppp', 'defs': 'bestow, supply, ordain'}], 'isLemma': 0, 'parents': ['vi_DA']},
	{'value': 'vihitavat', 'label': 'vihitavat', 'attrib': [{'pos': 'pap', 'defs': 'bestow, supply, ordain'}], 'isLemma': 0, 'parents': ['vi_DA']},
	{'value': 'vIra', 'label': 'vIra', 'attrib': [{'pos': 'adj', 'defs': 'heroic'}, {'pos': 'm', 'defs': 'hero'}], 'isLemma': 1},
	{'value': 'vIrya', 'label': 'vIrya', 'attrib': [{'pos': 'n', 'defs': 'virility, power'}], 'isLemma': 1},
	{'value': 'vf', 'label': 'vf', 'attrib': [{'pos': 'vt5am', 'defs': 'cover'}, {'pos': 'vt10am', 'defs': 'block'}, {'pos': 'vt9m', 'defs': 'choose'}], 'isLemma': 1},
	{'value': 'vfka', 'label': 'vfka', 'attrib': [{'pos': 'm', 'defs': 'wolf'}], 'isLemma': 1},
	{'value': 'vfkodara', 'label': 'vfkodara', 'attrib': [{'pos': 'adj', 'defs': 'wolf-bellied'}], 'isLemma': 1},
	{'value': 'vfRAna', 'label': 'vfRAna', 'attrib': [{'pos': 'prmp', 'defs': 'choose'}], 'isLemma': 0, 'parents': ['vf']},
	{'value': 'vfRvat', 'label': 'vfRvat', 'attrib': [{'pos': 'prap', 'defs': 'cover'}], 'isLemma': 0, 'parents': ['vf']},
	{'value': 'vfRvAna', 'label': 'vfRvAna', 'attrib': [{'pos': 'prmp', 'defs': 'cover'}], 'isLemma': 0, 'parents': ['vf']},
	{'value': 'vft', 'label': 'vft', 'attrib': [{'pos': 'vi1m', 'defs': 'occur, be present'}], 'isLemma': 1},
	{'value': 'vfta', 'label': 'vfta', 'attrib': [{'pos': 'ppp', 'defs': 'cover'}, {'pos': 'ppp', 'defs': 'choose'}], 'isLemma': 0, 'parents': ['vf']},
	{'value': 'vftavat', 'label': 'vftavat', 'attrib': [{'pos': 'pap', 'defs': 'cover'}, {'pos': 'pap', 'defs': 'choose'}], 'isLemma': 0, 'parents': ['vf']},
	{'value': 'vftta', 'label': 'vftta', 'attrib': [{'pos': 'ppp', 'defs': 'occur, be present'}, {'pos': 'n', 'defs': 'occurrence, event'}], 'isLemma': 1},
	{'value': 'vftta', 'label': 'vftta', 'attrib': [{'pos': 'ppp', 'defs': 'occur, be present'}, {'pos': 'n', 'defs': 'occurrence, event'}], 'isLemma': 0, 'parents': ['vft']},
	{'value': 'vfttavat', 'label': 'vfttavat', 'attrib': [{'pos': 'pap', 'defs': 'occur, be present'}], 'isLemma': 0, 'parents': ['vft']},
	{'value': 'vftya', 'label': 'vftya', 'attrib': [{'pos': 'ger', 'defs': 'cover'}, {'pos': 'ger', 'defs': 'occur, be present'}], 'isLemma': 0, 'parents': ['vf', 'vft']},
	{'value': 'vftvA', 'label': 'vftvA', 'attrib': [{'pos': 'abs', 'defs': 'cover'}, {'pos': 'abs', 'defs': 'choose'}], 'isLemma': 0, 'parents': ['vf']},
	{'value': 'vfz', 'label': 'vfz', 'attrib': [{'pos': 'vi1a', 'defs': 'rain'}], 'isLemma': 1},
	{'value': 'vfzaduHSAsana', 'label': 'vfzaduHSAsana', 'attrib': [{'pos': 'm', 'defs': 'Karṇa and Duḥśāsana'}], 'isLemma': 1},
	{'value': 'vfzaduHSAsanAdi', 'label': 'vfzaduHSAsanAdi', 'attrib': [{'pos': 'adj', 'defs': 'beginning with Karṇa and Duḥśāsana'}], 'isLemma': 1},
	{'value': 'vfzan', 'label': 'vfzan', 'attrib': [{'pos': 'm', 'defs': 'bull'}], 'isLemma': 1},
	{'value': 'vfzwa', 'label': 'vfzwa', 'attrib': [{'pos': 'ppp', 'defs': 'rain'}], 'isLemma': 0, 'parents': ['vfz']},
	{'value': 'vfzwavat', 'label': 'vfzwavat', 'attrib': [{'pos': 'pap', 'defs': 'rain'}], 'isLemma': 0, 'parents': ['vfz']},
	{'value': 'vfzwvA', 'label': 'vfzwvA', 'attrib': [{'pos': 'abs', 'defs': 'rain'}], 'isLemma': 0, 'parents': ['vfz']},
	{'value': 'vfzya', 'label': 'vfzya', 'attrib': [{'pos': 'ger', 'defs': 'rain'}], 'isLemma': 0, 'parents': ['vfz']},
	{'value': 'vfzyamARa', 'label': 'vfzyamARa', 'attrib': [{'pos': 'prpp', 'defs': 'rain'}], 'isLemma': 0, 'parents': ['vfz']},
	{'value': 'vettavya', 'label': 'vettavya', 'attrib': [{'pos': 'ger', 'defs': 'find, get'}], 'isLemma': 0, 'parents': ['vid']},
	{'value': 'vettum', 'label': 'vettum', 'attrib': [{'pos': 'inf', 'defs': 'find, get'}], 'isLemma': 0, 'parents': ['vid']},
	{'value': 'veda', 'label': 'veda', 'attrib': [{'pos': 'm', 'defs': 'knowledge, Vedic texts'}], 'isLemma': 1},
	{'value': 'vedanIya', 'label': 'vedanIya', 'attrib': [{'pos': 'ger', 'defs': 'know'}, {'pos': 'ger', 'defs': 'find, get'}], 'isLemma': 0, 'parents': ['vid']},
	{'value': 'veditavya', 'label': 'veditavya', 'attrib': [{'pos': 'ger', 'defs': 'know'}], 'isLemma': 0, 'parents': ['vid']},
	{'value': 'veditum', 'label': 'veditum', 'attrib': [{'pos': 'inf', 'defs': 'know'}], 'isLemma': 0, 'parents': ['vid']},
	{'value': 'veditvA', 'label': 'veditvA', 'attrib': [{'pos': 'abs', 'defs': 'find, get'}], 'isLemma': 0, 'parents': ['vid']},
	{'value': 'vedizyat', 'label': 'vedizyat', 'attrib': [{'pos': 'fap', 'defs': 'know'}, {'pos': 'fap', 'defs': 'find, get'}], 'isLemma': 0, 'parents': ['vid']},
	{'value': 'vedizyamARa', 'label': 'vedizyamARa', 'attrib': [{'pos': 'fpp', 'defs': 'know'}, {'pos': 'fmp, fpp', 'defs': 'find, get'}], 'isLemma': 0, 'parents': ['vid']},
	{'value': 'vedya', 'label': 'vedya', 'attrib': [{'pos': 'ger', 'defs': 'know'}, {'pos': 'ger', 'defs': 'find, get'}], 'isLemma': 0, 'parents': ['vid']},
	{'value': 'vE', 'label': 'vE', 'attrib': [{'pos': 'pcl', 'defs': 'verily'}], 'isLemma': 1},
	{'value': 'vESaMpAyana', 'label': 'vESaMpAyana', 'attrib': [{'pos': 'm_pn', 'defs': 'Vaiśaṁpāyana'}], 'isLemma': 1},
	{'value': 'voQavya', 'label': 'voQavya', 'attrib': [{'pos': 'ger', 'defs': 'carry, flow, wed'}], 'isLemma': 0, 'parents': ['vah']},
	{'value': 'voQum', 'label': 'voQum', 'attrib': [{'pos': 'inf', 'defs': 'carry, flow, wed'}], 'isLemma': 0, 'parents': ['vah']},
	{'value': 'vyaT', 'label': 'vyaT', 'attrib': [{'pos': 'vi1m', 'defs': 'tremble, fear'}], 'isLemma': 1},
	{'value': 'vyaTanIya', 'label': 'vyaTanIya', 'attrib': [{'pos': 'ger', 'defs': 'tremble, fear'}], 'isLemma': 0, 'parents': ['vyaT']},
	{'value': 'vyaTamAna', 'label': 'vyaTamAna', 'attrib': [{'pos': 'prmp', 'defs': 'tremble, fear'}], 'isLemma': 0, 'parents': ['vyaT']},
	{'value': 'vyaTA', 'label': 'vyaTA', 'attrib': [{'pos': 'f', 'defs': 'trembling, fear'}], 'isLemma': 1},
	{'value': 'vyaTita', 'label': 'vyaTita', 'attrib': [{'pos': 'ppp', 'defs': 'tremble, fear'}], 'isLemma': 0, 'parents': ['vyaT']},
	{'value': 'vyaTitavat', 'label': 'vyaTitavat', 'attrib': [{'pos': 'pap', 'defs': 'tremble, fear'}], 'isLemma': 0, 'parents': ['vyaT']},
	{'value': 'vyaTitavya', 'label': 'vyaTitavya', 'attrib': [{'pos': 'ger', 'defs': 'tremble, fear'}], 'isLemma': 0, 'parents': ['vyaT']},
	{'value': 'vyaTitum', 'label': 'vyaTitum', 'attrib': [{'pos': 'inf', 'defs': 'tremble, fear'}], 'isLemma': 0, 'parents': ['vyaT']},
	{'value': 'vyaTitvA', 'label': 'vyaTitvA', 'attrib': [{'pos': 'abs', 'defs': 'tremble, fear'}], 'isLemma': 0, 'parents': ['vyaT']},
	{'value': 'vyaTizyamARa', 'label': 'vyaTizyamARa', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'tremble, fear'}], 'isLemma': 0, 'parents': ['vyaT']},
	{'value': 'vyaTya', 'label': 'vyaTya', 'attrib': [{'pos': 'ger', 'defs': 'tremble, fear'}], 'isLemma': 0, 'parents': ['vyaT']},
	{'value': 'vyaTyamAna', 'label': 'vyaTyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'tremble, fear'}], 'isLemma': 0, 'parents': ['vyaT']},
	{'value': 'vyavasasAna', 'label': 'vyavasasAna', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'conclude, determine'}], 'isLemma': 0, 'parents': ['vi_ava_so']},
	{'value': 'vyavasasivas', 'label': 'vyavasasivas', 'attrib': [{'pos': 'pfap', 'defs': 'conclude, determine'}], 'isLemma': 0, 'parents': ['vi_ava_so']},
	{'value': 'vyavasAtavya', 'label': 'vyavasAtavya', 'attrib': [{'pos': 'ger', 'defs': 'conclude, determine'}], 'isLemma': 0, 'parents': ['vi_ava_so']},
	{'value': 'vyavasAtum', 'label': 'vyavasAtum', 'attrib': [{'pos': 'inf', 'defs': 'conclude, determine'}], 'isLemma': 0, 'parents': ['vi_ava_so']},
	{'value': 'vyavasAnIya', 'label': 'vyavasAnIya', 'attrib': [{'pos': 'ger', 'defs': 'conclude, determine'}], 'isLemma': 0, 'parents': ['vi_ava_so']},
	{'value': 'vyavasAya', 'label': 'vyavasAya', 'attrib': [{'pos': 'abs', 'defs': 'conclude, determine'}, {'pos': 'm', 'defs': 'resolution, determination'}], 'isLemma': 1},
	{'value': 'vyavasAya', 'label': 'vyavasAya', 'attrib': [{'pos': 'abs', 'defs': 'conclude, determine'}, {'pos': 'm', 'defs': 'resolution, determination'}], 'isLemma': 0, 'parents': ['vi_ava_so']},
	{'value': 'vyavasAyasahAya', 'label': 'vyavasAyasahAya', 'attrib': [{'pos': 'adj', 'defs': 'resolute'}], 'isLemma': 1},
	{'value': 'vyavasAsyat', 'label': 'vyavasAsyat', 'attrib': [{'pos': 'fap', 'defs': 'conclude, determine'}], 'isLemma': 0, 'parents': ['vi_ava_so']},
	{'value': 'vyavasAsyamAna', 'label': 'vyavasAsyamAna', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'conclude, determine'}], 'isLemma': 0, 'parents': ['vi_ava_so']},
	{'value': 'vyavasita', 'label': 'vyavasita', 'attrib': [{'pos': 'ppp', 'defs': 'conclude, determine'}], 'isLemma': 0, 'parents': ['vi_ava_so']},
	{'value': 'vyavasitavat', 'label': 'vyavasitavat', 'attrib': [{'pos': 'pap', 'defs': 'conclude, determine'}], 'isLemma': 0, 'parents': ['vi_ava_so']},
	{'value': 'vyavasIyamAna', 'label': 'vyavasIyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'conclude, determine'}], 'isLemma': 0, 'parents': ['vi_ava_so']},
	{'value': 'vyavaseya', 'label': 'vyavaseya', 'attrib': [{'pos': 'ger', 'defs': 'conclude, determine'}], 'isLemma': 0, 'parents': ['vi_ava_so']},
	{'value': 'vyavasyat', 'label': 'vyavasyat', 'attrib': [{'pos': 'prap', 'defs': 'conclude, determine'}], 'isLemma': 0, 'parents': ['vi_ava_so']},
	{'value': 'vyavasyamAna', 'label': 'vyavasyamAna', 'attrib': [{'pos': 'prmp', 'defs': 'conclude, determine'}], 'isLemma': 0, 'parents': ['vi_ava_so']},
	{'value': 'vyAsa', 'label': 'vyAsa', 'attrib': [{'pos': 'm_pn', 'defs': 'Vyāsa'}], 'isLemma': 1},
	{'value': 'vraj', 'label': 'vraj', 'attrib': [{'pos': 'vt1a', 'defs': 'walk'}], 'isLemma': 1},
	{'value': 'vrajat', 'label': 'vrajat', 'attrib': [{'pos': 'prap', 'defs': 'walk'}], 'isLemma': 0, 'parents': ['vraj']},
	{'value': 'vrajanIya', 'label': 'vrajanIya', 'attrib': [{'pos': 'ger', 'defs': 'walk'}], 'isLemma': 0, 'parents': ['vraj']},
	{'value': 'vrajita', 'label': 'vrajita', 'attrib': [{'pos': 'ppp', 'defs': 'walk'}], 'isLemma': 0, 'parents': ['vraj']},
	{'value': 'vrajitavat', 'label': 'vrajitavat', 'attrib': [{'pos': 'pap', 'defs': 'walk'}], 'isLemma': 0, 'parents': ['vraj']},
	{'value': 'vrajitavya', 'label': 'vrajitavya', 'attrib': [{'pos': 'ger', 'defs': 'walk'}], 'isLemma': 0, 'parents': ['vraj']},
	{'value': 'vrajitum', 'label': 'vrajitum', 'attrib': [{'pos': 'inf', 'defs': 'walk'}], 'isLemma': 0, 'parents': ['vraj']},
	{'value': 'vrajitvA', 'label': 'vrajitvA', 'attrib': [{'pos': 'abs', 'defs': 'walk'}], 'isLemma': 0, 'parents': ['vraj']},
	{'value': 'vrajizyat', 'label': 'vrajizyat', 'attrib': [{'pos': 'fap', 'defs': 'walk'}], 'isLemma': 0, 'parents': ['vraj']},
	{'value': 'vrajizyamARa', 'label': 'vrajizyamARa', 'attrib': [{'pos': 'fpp', 'defs': 'walk'}], 'isLemma': 0, 'parents': ['vraj']},
	{'value': 'vrajyamAna', 'label': 'vrajyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'walk'}], 'isLemma': 0, 'parents': ['vraj']},
	{'value': 'vrata', 'label': 'vrata', 'attrib': [{'pos': 'n', 'defs': 'vow'}], 'isLemma': 1},
	{'value': 'vrAjya', 'label': 'vrAjya', 'attrib': [{'pos': 'ger', 'defs': 'walk'}], 'isLemma': 0, 'parents': ['vraj']},
	{'value': 'vriyamARa', 'label': 'vriyamARa', 'attrib': [{'pos': 'prpp', 'defs': 'cover'}, {'pos': 'prpp', 'defs': 'choose'}], 'isLemma': 0, 'parents': ['vf']},
	{'value': 'SaMtanu', 'label': 'SaMtanu', 'attrib': [{'pos': 'm', 'defs': 'Śaṁtanu'}], 'isLemma': 1},
	{'value': 'Sak', 'label': 'Sak', 'attrib': [{'pos': 'vi5a', 'defs': 'be able'}], 'isLemma': 1},
	{'value': 'SakanIya', 'label': 'SakanIya', 'attrib': [{'pos': 'ger', 'defs': 'be able'}], 'isLemma': 0, 'parents': ['Sak']},
	{'value': 'Sakuni', 'label': 'Sakuni', 'attrib': [{'pos': 'm_pn', 'defs': 'Śakuni'}], 'isLemma': 1},
	{'value': 'Sakta', 'label': 'Sakta', 'attrib': [{'pos': 'ppp', 'defs': 'be able'}], 'isLemma': 0, 'parents': ['Sak']},
	{'value': 'Saktavat', 'label': 'Saktavat', 'attrib': [{'pos': 'pap', 'defs': 'be able'}], 'isLemma': 0, 'parents': ['Sak']},
	{'value': 'Saktavya', 'label': 'Saktavya', 'attrib': [{'pos': 'ger', 'defs': 'be able'}], 'isLemma': 0, 'parents': ['Sak']},
	{'value': 'Saktum', 'label': 'Saktum', 'attrib': [{'pos': 'inf', 'defs': 'be able'}], 'isLemma': 0, 'parents': ['Sak']},
	{'value': 'SaktvA', 'label': 'SaktvA', 'attrib': [{'pos': 'abs', 'defs': 'be able'}], 'isLemma': 0, 'parents': ['Sak']},
	{'value': 'Saknuvat', 'label': 'Saknuvat', 'attrib': [{'pos': 'prap', 'defs': 'be able'}], 'isLemma': 0, 'parents': ['Sak']},
	{'value': 'Sakya', 'label': 'Sakya', 'attrib': [{'pos': 'ger', 'defs': 'be able'}], 'isLemma': 0, 'parents': ['Sak']},
	{'value': 'SakyamAna', 'label': 'SakyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'be able'}], 'isLemma': 0, 'parents': ['Sak']},
	{'value': 'Sakra', 'label': 'Sakra', 'attrib': [{'pos': 'adj', 'defs': 'able, capable, powerful'}, {'pos': 'm', 'defs': 'epithet of Indra'}], 'isLemma': 1},
	{'value': 'SacI', 'label': 'SacI', 'attrib': [{'pos': 'f', 'defs': 'strength'}, {'pos': 'f_pn', 'defs': 'Śacī'}], 'isLemma': 1},
	{'value': 'Satru', 'label': 'Satru', 'attrib': [{'pos': 'm', 'defs': 'feller, enemy'}], 'isLemma': 1},
	{'value': 'SatruvaDa', 'label': 'SatruvaDa', 'attrib': [{'pos': 'm', 'defs': 'slaughter of enemies'}], 'isLemma': 1},
	{'value': 'Satruhan', 'label': 'Satruhan', 'attrib': [{'pos': 'm', 'defs': 'enemy-slayer'}], 'isLemma': 1},
	{'value': 'Sam', 'label': 'Sam', 'attrib': [{'pos': 'n', 'defs': 'peace, happiness'}, {'pos': 'pcl', 'defs': 'auspiciously, happily'}], 'isLemma': 1},
	{'value': 'SaSAsAna', 'label': 'SaSAsAna', 'attrib': [{'pos': 'pfpp', 'defs': 'rule, govern, teach'}], 'isLemma': 0, 'parents': ['SAs']},
	{'value': 'SaSAsvas', 'label': 'SaSAsvas', 'attrib': [{'pos': 'pfap', 'defs': 'rule, govern, teach'}], 'isLemma': 0, 'parents': ['SAs']},
	{'value': 'SaSramBARa', 'label': 'SaSramBARa', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'be careless, negligent'}], 'isLemma': 0, 'parents': ['SramB']},
	{'value': 'Sastra', 'label': 'Sastra', 'attrib': [{'pos': 'n', 'defs': 'sword'}], 'isLemma': 1},
	{'value': 'SastrapratApa', 'label': 'SastrapratApa', 'attrib': [{'pos': 'm', 'defs': 'blazing of swords'}], 'isLemma': 1},
	{'value': 'SAMtanava', 'label': 'SAMtanava', 'attrib': [{'pos': 'm', 'defs': 'son of Śaṁtanu'}], 'isLemma': 1},
	{'value': 'SAlA', 'label': 'SAlA', 'attrib': [{'pos': 'f', 'defs': 'hall'}], 'isLemma': 1},
	{'value': 'SAs', 'label': 'SAs', 'attrib': [{'pos': 'vt2a', 'defs': 'rule, govern, teach'}], 'isLemma': 1},
	{'value': 'SAsat', 'label': 'SAsat', 'attrib': [{'pos': 'prap', 'defs': 'rule, govern, teach'}], 'isLemma': 0, 'parents': ['SAs']},
	{'value': 'SAsanIya', 'label': 'SAsanIya', 'attrib': [{'pos': 'ger', 'defs': 'rule, govern, teach'}], 'isLemma': 0, 'parents': ['SAs']},
	{'value': 'SAsitavya', 'label': 'SAsitavya', 'attrib': [{'pos': 'ger', 'defs': 'rule, govern, teach'}], 'isLemma': 0, 'parents': ['SAs']},
	{'value': 'SAsitum', 'label': 'SAsitum', 'attrib': [{'pos': 'inf', 'defs': 'rule, govern, teach'}], 'isLemma': 0, 'parents': ['SAs']},
	{'value': 'SAsitvA', 'label': 'SAsitvA', 'attrib': [{'pos': 'abs', 'defs': 'rule, govern, teach'}], 'isLemma': 0, 'parents': ['SAs']},
	{'value': 'SAsizyat', 'label': 'SAsizyat', 'attrib': [{'pos': 'fap', 'defs': 'rule, govern, teach'}], 'isLemma': 0, 'parents': ['SAs']},
	{'value': 'SAsizyamARa', 'label': 'SAsizyamARa', 'attrib': [{'pos': 'fpp', 'defs': 'rule, govern, teach'}], 'isLemma': 0, 'parents': ['SAs']},
	{'value': 'Sizwa', 'label': 'Sizwa', 'attrib': [{'pos': 'ppp', 'defs': 'rule, govern, teach'}], 'isLemma': 0, 'parents': ['SAs']},
	{'value': 'Sizwavat', 'label': 'Sizwavat', 'attrib': [{'pos': 'pap', 'defs': 'rule, govern, teach'}], 'isLemma': 0, 'parents': ['SAs']},
	{'value': 'SizwvA', 'label': 'SizwvA', 'attrib': [{'pos': 'abs', 'defs': 'rule, govern, teach'}], 'isLemma': 0, 'parents': ['SAs']},
	{'value': 'Sizya', 'label': 'Sizya', 'attrib': [{'pos': 'ger', 'defs': 'rule, govern, teach'}], 'isLemma': 0, 'parents': ['SAs']},
	{'value': 'SizyamARa', 'label': 'SizyamARa', 'attrib': [{'pos': 'prpp', 'defs': 'rule, govern, teach'}], 'isLemma': 0, 'parents': ['SAs']},
	{'value': 'SfRvat', 'label': 'SfRvat', 'attrib': [{'pos': 'prap', 'defs': 'hear, listen'}], 'isLemma': 0, 'parents': ['Sru']},
	{'value': 'SrabDa', 'label': 'SrabDa', 'attrib': [{'pos': 'ppp', 'defs': 'be careless, negligent'}], 'isLemma': 0, 'parents': ['SramB']},
	{'value': 'SrabDavat', 'label': 'SrabDavat', 'attrib': [{'pos': 'pap', 'defs': 'be careless, negligent'}], 'isLemma': 0, 'parents': ['SramB']},
	{'value': 'SrabDvA', 'label': 'SrabDvA', 'attrib': [{'pos': 'abs', 'defs': 'be careless, negligent'}], 'isLemma': 0, 'parents': ['SramB']},
	{'value': 'SraByamARa', 'label': 'SraByamARa', 'attrib': [{'pos': 'prpp', 'defs': 'be careless, negligent'}], 'isLemma': 0, 'parents': ['SramB']},
	{'value': 'SramB', 'label': 'SramB', 'attrib': [{'pos': 'vi1m', 'defs': 'be careless, negligent'}], 'isLemma': 1},
	{'value': 'SramBaRIya', 'label': 'SramBaRIya', 'attrib': [{'pos': 'ger', 'defs': 'be careless, negligent'}], 'isLemma': 0, 'parents': ['SramB']},
	{'value': 'SramBamARa', 'label': 'SramBamARa', 'attrib': [{'pos': 'prmp', 'defs': 'be careless, negligent'}], 'isLemma': 0, 'parents': ['SramB']},
	{'value': 'SramBitavya', 'label': 'SramBitavya', 'attrib': [{'pos': 'ger', 'defs': 'be careless, negligent'}], 'isLemma': 0, 'parents': ['SramB']},
	{'value': 'SramBitum', 'label': 'SramBitum', 'attrib': [{'pos': 'inf', 'defs': 'be careless, negligent'}], 'isLemma': 0, 'parents': ['SramB']},
	{'value': 'SramBitvA', 'label': 'SramBitvA', 'attrib': [{'pos': 'abs', 'defs': 'be careless, negligent'}], 'isLemma': 0, 'parents': ['SramB']},
	{'value': 'SramBizyamARa', 'label': 'SramBizyamARa', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'be careless, negligent'}], 'isLemma': 0, 'parents': ['SramB']},
	{'value': 'SramBya', 'label': 'SramBya', 'attrib': [{'pos': 'ger', 'defs': 'be careless, negligent'}], 'isLemma': 0, 'parents': ['SramB']},
	{'value': 'SravaRIya', 'label': 'SravaRIya', 'attrib': [{'pos': 'ger', 'defs': 'hear, listen'}], 'isLemma': 0, 'parents': ['Sru']},
	{'value': 'Sravya', 'label': 'Sravya', 'attrib': [{'pos': 'ger', 'defs': 'hear, listen'}], 'isLemma': 0, 'parents': ['Sru']},
	{'value': 'SrI', 'label': 'SrI', 'attrib': [{'pos': 'f', 'defs': 'radiance, glory, wealth'}], 'isLemma': 1},
	{'value': 'SrImat', 'label': 'SrImat', 'attrib': [{'pos': 'adj', 'defs': 'radiant, glorious'}], 'isLemma': 1},
	{'value': 'SrImanmahABArata', 'label': 'SrImanmahABArata', 'attrib': [{'pos': 'n', 'defs': 'the glorious epic <i>Mahābhārata</i>'}], 'isLemma': 1},
	{'value': 'SrIyaSas', 'label': 'SrIyaSas', 'attrib': [{'pos': 'n', 'defs': 'glory and fame'}], 'isLemma': 1},
	{'value': 'SrIyaSoBft', 'label': 'SrIyaSoBft', 'attrib': [{'pos': 'adj', 'defs': 'bearing glory and fame'}], 'isLemma': 1},
	{'value': 'Sru', 'label': 'Sru', 'attrib': [{'pos': 'vt1am', 'defs': 'hear, listen'}], 'isLemma': 1},
	{'value': 'Sruta', 'label': 'Sruta', 'attrib': [{'pos': 'ppp', 'defs': 'hear, listen'}], 'isLemma': 0, 'parents': ['Sru']},
	{'value': 'Srutavat', 'label': 'Srutavat', 'attrib': [{'pos': 'pap', 'defs': 'hear, listen'}], 'isLemma': 0, 'parents': ['Sru']},
	{'value': 'SrutvA', 'label': 'SrutvA', 'attrib': [{'pos': 'abs', 'defs': 'hear, listen'}], 'isLemma': 0, 'parents': ['Sru']},
	{'value': 'SrUyamARa', 'label': 'SrUyamARa', 'attrib': [{'pos': 'prpp', 'defs': 'hear, listen'}], 'isLemma': 0, 'parents': ['Sru']},
	{'value': 'Srotavya', 'label': 'Srotavya', 'attrib': [{'pos': 'ger', 'defs': 'hear, listen'}], 'isLemma': 0, 'parents': ['Sru']},
	{'value': 'Srotum', 'label': 'Srotum', 'attrib': [{'pos': 'inf', 'defs': 'hear, listen'}], 'isLemma': 0, 'parents': ['Sru']},
	{'value': 'zawsUrya', 'label': 'zawsUrya', 'attrib': [{'pos': 'adj', 'defs': 'six-sunned'}], 'isLemma': 1},
	{'value': 'zaz', 'label': 'zaz', 'attrib': [{'pos': 'card_num', 'defs': 'six'}], 'isLemma': 1},
	{'value': 'saMkASa', 'label': 'saMkASa', 'attrib': [{'pos': 'm', 'defs': 'appearance'}], 'isLemma': 1},
	{'value': 'saMgacCamAna', 'label': 'saMgacCamAna', 'attrib': [{'pos': 'prmp', 'defs': 'go together, unite, meet'}], 'isLemma': 0, 'parents': ['sam_gam']},
	{'value': 'saMgata', 'label': 'saMgata', 'attrib': [{'pos': 'ppp', 'defs': 'go together, unite, meet'}], 'isLemma': 0, 'parents': ['sam_gam']},
	{'value': 'saMgatavat', 'label': 'saMgatavat', 'attrib': [{'pos': 'pap', 'defs': 'go together, unite, meet'}], 'isLemma': 0, 'parents': ['sam_gam']},
	{'value': 'saMgatya', 'label': 'saMgatya', 'attrib': [{'pos': 'abs', 'defs': 'go together, unite, meet'}], 'isLemma': 0, 'parents': ['sam_gam']},
	{'value': 'saMgantavya', 'label': 'saMgantavya', 'attrib': [{'pos': 'ger', 'defs': 'go together, unite, meet'}], 'isLemma': 0, 'parents': ['sam_gam']},
	{'value': 'saMgantum', 'label': 'saMgantum', 'attrib': [{'pos': 'inf', 'defs': 'go together, unite, meet'}], 'isLemma': 0, 'parents': ['sam_gam']},
	{'value': 'saMgamanIya', 'label': 'saMgamanIya', 'attrib': [{'pos': 'ger', 'defs': 'go together, unite, meet'}], 'isLemma': 0, 'parents': ['sam_gam']},
	{'value': 'saMgamizyamARa', 'label': 'saMgamizyamARa', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'go together, unite, meet'}], 'isLemma': 0, 'parents': ['sam_gam']},
	{'value': 'saMgamya', 'label': 'saMgamya', 'attrib': [{'pos': 'abs', 'defs': 'go together, unite, meet'}, {'pos': 'ger', 'defs': 'go together, unite, meet'}], 'isLemma': 0, 'parents': ['sam_gam']},
	{'value': 'saMgamyamAna', 'label': 'saMgamyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'go together, unite, meet'}], 'isLemma': 0, 'parents': ['sam_gam']},
	{'value': 'saMcicCidAna', 'label': 'saMcicCidAna', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'split, cut'}], 'isLemma': 0, 'parents': ['sam_Cid']},
	{'value': 'saMcicCidvas', 'label': 'saMcicCidvas', 'attrib': [{'pos': 'pfap', 'defs': 'split, cut'}], 'isLemma': 0, 'parents': ['sam_Cid']},
	{'value': 'saMCidya', 'label': 'saMCidya', 'attrib': [{'pos': 'abs', 'defs': 'split, cut'}], 'isLemma': 0, 'parents': ['sam_Cid']},
	{'value': 'saMCidyamAna', 'label': 'saMCidyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'split, cut'}], 'isLemma': 0, 'parents': ['sam_Cid']},
	{'value': 'saMCindat', 'label': 'saMCindat', 'attrib': [{'pos': 'prap', 'defs': 'split, cut'}], 'isLemma': 0, 'parents': ['sam_Cid']},
	{'value': 'saMCindAna', 'label': 'saMCindAna', 'attrib': [{'pos': 'prmp', 'defs': 'split, cut'}], 'isLemma': 0, 'parents': ['sam_Cid']},
	{'value': 'saMCinna', 'label': 'saMCinna', 'attrib': [{'pos': 'ppp', 'defs': 'split, cut'}], 'isLemma': 0, 'parents': ['sam_Cid']},
	{'value': 'saMCinnavat', 'label': 'saMCinnavat', 'attrib': [{'pos': 'pap', 'defs': 'split, cut'}], 'isLemma': 0, 'parents': ['sam_Cid']},
	{'value': 'saMCettavya', 'label': 'saMCettavya', 'attrib': [{'pos': 'ger', 'defs': 'split, cut'}], 'isLemma': 0, 'parents': ['sam_Cid']},
	{'value': 'saMCettum', 'label': 'saMCettum', 'attrib': [{'pos': 'inf', 'defs': 'split, cut'}], 'isLemma': 0, 'parents': ['sam_Cid']},
	{'value': 'saMCetsyat', 'label': 'saMCetsyat', 'attrib': [{'pos': 'fap', 'defs': 'split, cut'}], 'isLemma': 0, 'parents': ['sam_Cid']},
	{'value': 'saMCetsyamAna', 'label': 'saMCetsyamAna', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'split, cut'}], 'isLemma': 0, 'parents': ['sam_Cid']},
	{'value': 'saMCedanIya', 'label': 'saMCedanIya', 'attrib': [{'pos': 'ger', 'defs': 'split, cut'}], 'isLemma': 0, 'parents': ['sam_Cid']},
	{'value': 'saMCedya', 'label': 'saMCedya', 'attrib': [{'pos': 'ger', 'defs': 'split, cut'}], 'isLemma': 0, 'parents': ['sam_Cid']},
	{'value': 'saMjagmAna', 'label': 'saMjagmAna', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'go together, unite, meet'}], 'isLemma': 0, 'parents': ['sam_gam']},
	{'value': 'saMdaDat', 'label': 'saMdaDat', 'attrib': [{'pos': 'prap', 'defs': 'put together'}], 'isLemma': 0, 'parents': ['sam_DA']},
	{'value': 'saMdaDAna', 'label': 'saMdaDAna', 'attrib': [{'pos': 'prmp', 'defs': 'put together'}, {'pos': 'pfmp, pfpp', 'defs': 'put together'}], 'isLemma': 0, 'parents': ['sam_DA']},
	{'value': 'saMdaDivas', 'label': 'saMdaDivas', 'attrib': [{'pos': 'pfap', 'defs': 'put together'}], 'isLemma': 0, 'parents': ['sam_DA']},
	{'value': 'saMDAtavya', 'label': 'saMDAtavya', 'attrib': [{'pos': 'ger', 'defs': 'put together'}], 'isLemma': 0, 'parents': ['sam_DA']},
	{'value': 'saMDAtum', 'label': 'saMDAtum', 'attrib': [{'pos': 'inf', 'defs': 'put together'}], 'isLemma': 0, 'parents': ['sam_DA']},
	{'value': 'saMDAnIya', 'label': 'saMDAnIya', 'attrib': [{'pos': 'ger', 'defs': 'put together'}], 'isLemma': 0, 'parents': ['sam_DA']},
	{'value': 'saMDAya', 'label': 'saMDAya', 'attrib': [{'pos': 'abs', 'defs': 'put together'}], 'isLemma': 0, 'parents': ['sam_DA']},
	{'value': 'saMDAsyat', 'label': 'saMDAsyat', 'attrib': [{'pos': 'fap', 'defs': 'put together'}], 'isLemma': 0, 'parents': ['sam_DA']},
	{'value': 'saMDAsyamAna', 'label': 'saMDAsyamAna', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'put together'}], 'isLemma': 0, 'parents': ['sam_DA']},
	{'value': 'saMDIyamAna', 'label': 'saMDIyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'put together'}], 'isLemma': 0, 'parents': ['sam_DA']},
	{'value': 'saMDeya', 'label': 'saMDeya', 'attrib': [{'pos': 'ger', 'defs': 'put together'}], 'isLemma': 0, 'parents': ['sam_DA']},
	{'value': 'saMpattavya', 'label': 'saMpattavya', 'attrib': [{'pos': 'ger', 'defs': 'succeed, arise'}], 'isLemma': 0, 'parents': ['sam_pad']},
	{'value': 'saMpattum', 'label': 'saMpattum', 'attrib': [{'pos': 'inf', 'defs': 'succeed, arise'}], 'isLemma': 0, 'parents': ['sam_pad']},
	{'value': 'saMpatsyamAna', 'label': 'saMpatsyamAna', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'succeed, arise'}], 'isLemma': 0, 'parents': ['sam_pad']},
	{'value': 'saMpadanIya', 'label': 'saMpadanIya', 'attrib': [{'pos': 'ger', 'defs': 'succeed, arise'}], 'isLemma': 0, 'parents': ['sam_pad']},
	{'value': 'saMpadya', 'label': 'saMpadya', 'attrib': [{'pos': 'abs', 'defs': 'succeed, arise'}], 'isLemma': 0, 'parents': ['sam_pad']},
	{'value': 'saMpadyamAna', 'label': 'saMpadyamAna', 'attrib': [{'pos': 'prmp', 'defs': 'succeed, arise'}, {'pos': 'prpp', 'defs': 'succeed, arise'}], 'isLemma': 0, 'parents': ['sam_pad']},
	{'value': 'saMpanna', 'label': 'saMpanna', 'attrib': [{'pos': 'adj', 'defs': 'endowed with'}, {'pos': 'ppp', 'defs': 'succeed, arise'}], 'isLemma': 1},
	{'value': 'saMpanna', 'label': 'saMpanna', 'attrib': [{'pos': 'adj', 'defs': 'endowed with'}, {'pos': 'ppp', 'defs': 'succeed, arise'}], 'isLemma': 0, 'parents': ['sam_pad']},
	{'value': 'saMpannavat', 'label': 'saMpannavat', 'attrib': [{'pos': 'pap', 'defs': 'succeed, arise'}], 'isLemma': 0, 'parents': ['sam_pad']},
	{'value': 'saMpAdya', 'label': 'saMpAdya', 'attrib': [{'pos': 'ger', 'defs': 'succeed, arise'}], 'isLemma': 0, 'parents': ['sam_pad']},
	{'value': 'saMpUjanIya', 'label': 'saMpUjanIya', 'attrib': [{'pos': 'ger', 'defs': 'honor'}], 'isLemma': 0, 'parents': ['sam_pUj']},
	{'value': 'saMpUjayamAna', 'label': 'saMpUjayamAna', 'attrib': [{'pos': 'prmp', 'defs': 'honor'}], 'isLemma': 0, 'parents': ['sam_pUj']},
	{'value': 'saMpUjayAm', 'label': 'saMpUjayAm', 'attrib': [{'pos': 'ppf_actn', 'defs': 'honor'}], 'isLemma': 0, 'parents': ['sam_pUj']},
	{'value': 'saMpUjayitum', 'label': 'saMpUjayitum', 'attrib': [{'pos': 'inf', 'defs': 'honor'}], 'isLemma': 0, 'parents': ['sam_pUj']},
	{'value': 'saMpUjayizyamARa', 'label': 'saMpUjayizyamARa', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'honor'}], 'isLemma': 0, 'parents': ['sam_pUj']},
	{'value': 'saMpUjita', 'label': 'saMpUjita', 'attrib': [{'pos': 'ppp', 'defs': 'honor'}], 'isLemma': 0, 'parents': ['sam_pUj']},
	{'value': 'saMpUjitavat', 'label': 'saMpUjitavat', 'attrib': [{'pos': 'pap', 'defs': 'honor'}], 'isLemma': 0, 'parents': ['sam_pUj']},
	{'value': 'saMpUjitavya', 'label': 'saMpUjitavya', 'attrib': [{'pos': 'ger', 'defs': 'honor'}], 'isLemma': 0, 'parents': ['sam_pUj']},
	{'value': 'saMpUjya', 'label': 'saMpUjya', 'attrib': [{'pos': 'abs', 'defs': 'honor'}, {'pos': 'ger', 'defs': 'honor'}], 'isLemma': 0, 'parents': ['sam_pUj']},
	{'value': 'saMpUjyamAna', 'label': 'saMpUjyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'honor'}], 'isLemma': 0, 'parents': ['sam_pUj']},
	{'value': 'saMpedAna', 'label': 'saMpedAna', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'succeed, arise'}], 'isLemma': 0, 'parents': ['sam_pad']},
	{'value': 'saMpravaktavya', 'label': 'saMpravaktavya', 'attrib': [{'pos': 'ger', 'defs': 'communicate, declare, relate in full'}], 'isLemma': 0, 'parents': ['sam_pra_vac']},
	{'value': 'saMpravaktum', 'label': 'saMpravaktum', 'attrib': [{'pos': 'inf', 'defs': 'communicate, declare, relate in full'}], 'isLemma': 0, 'parents': ['sam_pra_vac']},
	{'value': 'saMpravakzyat', 'label': 'saMpravakzyat', 'attrib': [{'pos': 'fap', 'defs': 'communicate, declare, relate in full'}], 'isLemma': 0, 'parents': ['sam_pra_vac']},
	{'value': 'saMpravakzyamARa', 'label': 'saMpravakzyamARa', 'attrib': [{'pos': 'fpp', 'defs': 'communicate, declare, relate in full'}], 'isLemma': 0, 'parents': ['sam_pra_vac']},
	{'value': 'saMpravacanIya', 'label': 'saMpravacanIya', 'attrib': [{'pos': 'ger', 'defs': 'communicate, declare, relate in full'}], 'isLemma': 0, 'parents': ['sam_pra_vac']},
	{'value': 'saMpravAkya', 'label': 'saMpravAkya', 'attrib': [{'pos': 'ger', 'defs': 'communicate, declare, relate in full'}], 'isLemma': 0, 'parents': ['sam_pra_vac']},
	{'value': 'saMprokta', 'label': 'saMprokta', 'attrib': [{'pos': 'ppp', 'defs': 'communicate, declare, relate in full'}], 'isLemma': 0, 'parents': ['sam_pra_vac']},
	{'value': 'saMproktavat', 'label': 'saMproktavat', 'attrib': [{'pos': 'pap', 'defs': 'communicate, declare, relate in full'}], 'isLemma': 0, 'parents': ['sam_pra_vac']},
	{'value': 'saMproktvA', 'label': 'saMproktvA', 'attrib': [{'pos': 'abs', 'defs': 'communicate, declare, relate in full'}], 'isLemma': 0, 'parents': ['sam_pra_vac']},
	{'value': 'saMprocAna', 'label': 'saMprocAna', 'attrib': [{'pos': 'pfpp', 'defs': 'communicate, declare, relate in full'}], 'isLemma': 0, 'parents': ['sam_pra_vac']},
	{'value': 'saMprocivas', 'label': 'saMprocivas', 'attrib': [{'pos': 'pfap', 'defs': 'communicate, declare, relate in full'}], 'isLemma': 0, 'parents': ['sam_pra_vac']},
	{'value': 'saMprocya', 'label': 'saMprocya', 'attrib': [{'pos': 'abs', 'defs': 'communicate, declare, relate in full'}], 'isLemma': 0, 'parents': ['sam_pra_vac']},
	{'value': 'saMprocyamAna', 'label': 'saMprocyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'communicate, declare, relate in full'}], 'isLemma': 0, 'parents': ['sam_pra_vac']},
	{'value': 'saMbaBUvas', 'label': 'saMbaBUvas', 'attrib': [{'pos': 'pfap', 'defs': 'come to be, arise, be possible'}], 'isLemma': 0, 'parents': ['sam_BU']},
	{'value': 'saMBavat', 'label': 'saMBavat', 'attrib': [{'pos': 'prap', 'defs': 'come to be, arise, be possible'}], 'isLemma': 0, 'parents': ['sam_BU']},
	{'value': 'saMBavanIya', 'label': 'saMBavanIya', 'attrib': [{'pos': 'ger', 'defs': 'come to be, arise, be possible'}], 'isLemma': 0, 'parents': ['sam_BU']},
	{'value': 'saMBavitavya', 'label': 'saMBavitavya', 'attrib': [{'pos': 'ger', 'defs': 'come to be, arise, be possible'}], 'isLemma': 0, 'parents': ['sam_BU']},
	{'value': 'saMBavitum', 'label': 'saMBavitum', 'attrib': [{'pos': 'inf', 'defs': 'come to be, arise, be possible'}], 'isLemma': 0, 'parents': ['sam_BU']},
	{'value': 'saMBavizyat', 'label': 'saMBavizyat', 'attrib': [{'pos': 'fap', 'defs': 'come to be, arise, be possible'}], 'isLemma': 0, 'parents': ['sam_BU']},
	{'value': 'saMBavizyamARa', 'label': 'saMBavizyamARa', 'attrib': [{'pos': 'fpp', 'defs': 'come to be, arise, be possible'}], 'isLemma': 0, 'parents': ['sam_BU']},
	{'value': 'saMBavya', 'label': 'saMBavya', 'attrib': [{'pos': 'ger', 'defs': 'come to be, arise, be possible'}], 'isLemma': 0, 'parents': ['sam_BU']},
	{'value': 'saMBUta', 'label': 'saMBUta', 'attrib': [{'pos': 'ppp', 'defs': 'come to be, arise, be possible'}], 'isLemma': 0, 'parents': ['sam_BU']},
	{'value': 'saMBUtavat', 'label': 'saMBUtavat', 'attrib': [{'pos': 'pap', 'defs': 'come to be, arise, be possible'}], 'isLemma': 0, 'parents': ['sam_BU']},
	{'value': 'saMBUya', 'label': 'saMBUya', 'attrib': [{'pos': 'abs', 'defs': 'come to be, arise, be possible'}], 'isLemma': 0, 'parents': ['sam_BU']},
	{'value': 'saMBUyamAna', 'label': 'saMBUyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'come to be, arise, be possible'}], 'isLemma': 0, 'parents': ['sam_BU']},
	{'value': 'saMmaMsyamAna', 'label': 'saMmaMsyamAna', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'agree, approve, esteem, respect'}], 'isLemma': 0, 'parents': ['sam_man']},
	{'value': 'saMmata', 'label': 'saMmata', 'attrib': [{'pos': 'ppp', 'defs': 'agree, approve, esteem, respect'}], 'isLemma': 0, 'parents': ['sam_man']},
	{'value': 'saMmatavat', 'label': 'saMmatavat', 'attrib': [{'pos': 'pap', 'defs': 'agree, approve, esteem, respect'}], 'isLemma': 0, 'parents': ['sam_man']},
	{'value': 'saMmananIya', 'label': 'saMmananIya', 'attrib': [{'pos': 'ger', 'defs': 'agree, approve, esteem, respect'}], 'isLemma': 0, 'parents': ['sam_man']},
	{'value': 'saMmantavya', 'label': 'saMmantavya', 'attrib': [{'pos': 'ger', 'defs': 'agree, approve, esteem, respect'}], 'isLemma': 0, 'parents': ['sam_man']},
	{'value': 'saMmantum', 'label': 'saMmantum', 'attrib': [{'pos': 'inf', 'defs': 'agree, approve, esteem, respect'}], 'isLemma': 0, 'parents': ['sam_man']},
	{'value': 'saMmantraRIya', 'label': 'saMmantraRIya', 'attrib': [{'pos': 'ger', 'defs': 'consult'}], 'isLemma': 0, 'parents': ['sam_mantr']},
	{'value': 'saMmantrayamAna', 'label': 'saMmantrayamAna', 'attrib': [{'pos': 'prmp', 'defs': 'consult'}], 'isLemma': 0, 'parents': ['sam_mantr']},
	{'value': 'saMmantrayAm', 'label': 'saMmantrayAm', 'attrib': [{'pos': 'ppf_actn', 'defs': 'consult'}], 'isLemma': 0, 'parents': ['sam_mantr']},
	{'value': 'saMmantrayitavya', 'label': 'saMmantrayitavya', 'attrib': [{'pos': 'ger', 'defs': 'consult'}], 'isLemma': 0, 'parents': ['sam_mantr']},
	{'value': 'saMmantrayitum', 'label': 'saMmantrayitum', 'attrib': [{'pos': 'inf', 'defs': 'consult'}], 'isLemma': 0, 'parents': ['sam_mantr']},
	{'value': 'saMmantrayizyamARa', 'label': 'saMmantrayizyamARa', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'consult'}], 'isLemma': 0, 'parents': ['sam_mantr']},
	{'value': 'saMmantrita', 'label': 'saMmantrita', 'attrib': [{'pos': 'ppp', 'defs': 'consult'}], 'isLemma': 0, 'parents': ['sam_mantr']},
	{'value': 'saMmantritavat', 'label': 'saMmantritavat', 'attrib': [{'pos': 'pap', 'defs': 'consult'}], 'isLemma': 0, 'parents': ['sam_mantr']},
	{'value': 'saMmantrya', 'label': 'saMmantrya', 'attrib': [{'pos': 'abs', 'defs': 'consult'}, {'pos': 'ger', 'defs': 'consult'}], 'isLemma': 0, 'parents': ['sam_mantr']},
	{'value': 'saMmantryamAna', 'label': 'saMmantryamAna', 'attrib': [{'pos': 'prpp', 'defs': 'consult'}], 'isLemma': 0, 'parents': ['sam_mantr']},
	{'value': 'saMmanya', 'label': 'saMmanya', 'attrib': [{'pos': 'abs', 'defs': 'agree, approve, esteem, respect'}], 'isLemma': 0, 'parents': ['sam_man']},
	{'value': 'saMmanyamAna', 'label': 'saMmanyamAna', 'attrib': [{'pos': 'prmp', 'defs': 'agree, approve, esteem, respect'}, {'pos': 'prpp', 'defs': 'agree, approve, esteem, respect'}], 'isLemma': 0, 'parents': ['sam_man']},
	{'value': 'saMmanvAna', 'label': 'saMmanvAna', 'attrib': [{'pos': 'prmp', 'defs': 'agree, approve, esteem, respect'}], 'isLemma': 0, 'parents': ['sam_man']},
	{'value': 'saMmAnya', 'label': 'saMmAnya', 'attrib': [{'pos': 'ger', 'defs': 'agree, approve, esteem, respect'}], 'isLemma': 0, 'parents': ['sam_man']},
	{'value': 'saMmenAna', 'label': 'saMmenAna', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'agree, approve, esteem, respect'}], 'isLemma': 0, 'parents': ['sam_man']},
	{'value': 'saMvatsara', 'label': 'saMvatsara', 'attrib': [{'pos': 'm', 'defs': 'year'}], 'isLemma': 1},
	{'value': 'saMvatsaragaRa', 'label': 'saMvatsaragaRa', 'attrib': [{'pos': 'm', 'defs': 'many years'}], 'isLemma': 1},
	{'value': 'saMvaraRIya', 'label': 'saMvaraRIya', 'attrib': [{'pos': 'ger', 'defs': 'cover, conceal'}], 'isLemma': 0, 'parents': ['sam_vf']},
	{'value': 'saMvaritavya', 'label': 'saMvaritavya', 'attrib': [{'pos': 'ger', 'defs': 'cover, conceal'}], 'isLemma': 0, 'parents': ['sam_vf']},
	{'value': 'saMvaritum', 'label': 'saMvaritum', 'attrib': [{'pos': 'inf', 'defs': 'cover, conceal'}], 'isLemma': 0, 'parents': ['sam_vf']},
	{'value': 'saMvarizyat', 'label': 'saMvarizyat', 'attrib': [{'pos': 'fap', 'defs': 'cover, conceal'}], 'isLemma': 0, 'parents': ['sam_vf']},
	{'value': 'saMvarizyamARa', 'label': 'saMvarizyamARa', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'cover, conceal'}], 'isLemma': 0, 'parents': ['sam_vf']},
	{'value': 'saMvarItavya', 'label': 'saMvarItavya', 'attrib': [{'pos': 'ger', 'defs': 'cover, conceal'}], 'isLemma': 0, 'parents': ['sam_vf']},
	{'value': 'saMvarItum', 'label': 'saMvarItum', 'attrib': [{'pos': 'inf', 'defs': 'cover, conceal'}], 'isLemma': 0, 'parents': ['sam_vf']},
	{'value': 'saMvarIzyat', 'label': 'saMvarIzyat', 'attrib': [{'pos': 'fap', 'defs': 'cover, conceal'}], 'isLemma': 0, 'parents': ['sam_vf']},
	{'value': 'saMvarIzyamARa', 'label': 'saMvarIzyamARa', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'cover, conceal'}], 'isLemma': 0, 'parents': ['sam_vf']},
	{'value': 'saMvavfvas', 'label': 'saMvavfvas', 'attrib': [{'pos': 'pfap', 'defs': 'cover, conceal'}], 'isLemma': 0, 'parents': ['sam_vf']},
	{'value': 'saMvavrARa', 'label': 'saMvavrARa', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'cover, conceal'}], 'isLemma': 0, 'parents': ['sam_vf']},
	{'value': 'saMvfRvat', 'label': 'saMvfRvat', 'attrib': [{'pos': 'prap', 'defs': 'cover, conceal'}], 'isLemma': 0, 'parents': ['sam_vf']},
	{'value': 'saMvfRvAna', 'label': 'saMvfRvAna', 'attrib': [{'pos': 'prmp', 'defs': 'cover, conceal'}], 'isLemma': 0, 'parents': ['sam_vf']},
	{'value': 'saMvfta', 'label': 'saMvfta', 'attrib': [{'pos': 'ppp', 'defs': 'cover, conceal'}], 'isLemma': 0, 'parents': ['sam_vf']},
	{'value': 'saMvftavat', 'label': 'saMvftavat', 'attrib': [{'pos': 'pap', 'defs': 'cover, conceal'}], 'isLemma': 0, 'parents': ['sam_vf']},
	{'value': 'saMvftya', 'label': 'saMvftya', 'attrib': [{'pos': 'abs', 'defs': 'cover, conceal'}, {'pos': 'ger', 'defs': 'cover, conceal'}], 'isLemma': 0, 'parents': ['sam_vf']},
	{'value': 'saMvriyamARa', 'label': 'saMvriyamARa', 'attrib': [{'pos': 'prpp', 'defs': 'cover, conceal'}], 'isLemma': 0, 'parents': ['sam_vf']},
	{'value': 'saMsupta', 'label': 'saMsupta', 'attrib': [{'pos': 'ppp', 'defs': 'sleep'}], 'isLemma': 0, 'parents': ['sam_svap']},
	{'value': 'saMsuptavat', 'label': 'saMsuptavat', 'attrib': [{'pos': 'pap', 'defs': 'sleep'}], 'isLemma': 0, 'parents': ['sam_svap']},
	{'value': 'saMsupya', 'label': 'saMsupya', 'attrib': [{'pos': 'abs', 'defs': 'sleep'}], 'isLemma': 0, 'parents': ['sam_svap']},
	{'value': 'saMsupyamARa', 'label': 'saMsupyamARa', 'attrib': [{'pos': 'prpp', 'defs': 'sleep'}], 'isLemma': 0, 'parents': ['sam_svap']},
	{'value': 'saMsuzupAna', 'label': 'saMsuzupAna', 'attrib': [{'pos': 'pfpp', 'defs': 'sleep'}], 'isLemma': 0, 'parents': ['sam_svap']},
	{'value': 'saMsuzupivas', 'label': 'saMsuzupivas', 'attrib': [{'pos': 'pfap', 'defs': 'sleep'}], 'isLemma': 0, 'parents': ['sam_svap']},
	{'value': 'saMsvapat', 'label': 'saMsvapat', 'attrib': [{'pos': 'prap', 'defs': 'sleep'}], 'isLemma': 0, 'parents': ['sam_svap']},
	{'value': 'saMsvapanIya', 'label': 'saMsvapanIya', 'attrib': [{'pos': 'ger', 'defs': 'sleep'}], 'isLemma': 0, 'parents': ['sam_svap']},
	{'value': 'saMsvaptavya', 'label': 'saMsvaptavya', 'attrib': [{'pos': 'ger', 'defs': 'sleep'}], 'isLemma': 0, 'parents': ['sam_svap']},
	{'value': 'saMsvaptum', 'label': 'saMsvaptum', 'attrib': [{'pos': 'inf', 'defs': 'sleep'}], 'isLemma': 0, 'parents': ['sam_svap']},
	{'value': 'saMsvapya', 'label': 'saMsvapya', 'attrib': [{'pos': 'ger', 'defs': 'sleep'}], 'isLemma': 0, 'parents': ['sam_svap']},
	{'value': 'saMsvapsyat', 'label': 'saMsvapsyat', 'attrib': [{'pos': 'fap', 'defs': 'sleep'}], 'isLemma': 0, 'parents': ['sam_svap']},
	{'value': 'saMsvapsyamARa', 'label': 'saMsvapsyamARa', 'attrib': [{'pos': 'fpp', 'defs': 'sleep'}], 'isLemma': 0, 'parents': ['sam_svap']},
	{'value': 'saMhita', 'label': 'saMhita', 'attrib': [{'pos': 'ppp', 'defs': 'put together'}], 'isLemma': 0, 'parents': ['sam_DA']},
	{'value': 'saMhitavat', 'label': 'saMhitavat', 'attrib': [{'pos': 'pap', 'defs': 'put together'}], 'isLemma': 0, 'parents': ['sam_DA']},
	{'value': 'sac', 'label': 'sac', 'attrib': [{'pos': 'vt1am', 'defs': 'be associated with'}], 'isLemma': 1},
	{'value': 'saciva', 'label': 'saciva', 'attrib': [{'pos': 'm', 'defs': 'associate, counsellor, minister'}], 'isLemma': 1},
	{'value': 'sat', 'label': 'sat', 'attrib': [{'pos': 'prap', 'defs': 'be'}, {'pos': 'adj', 'defs': 'good'}], 'isLemma': 1},
	{'value': 'sat', 'label': 'sat', 'attrib': [{'pos': 'prap', 'defs': 'be'}, {'pos': 'adj', 'defs': 'good'}], 'isLemma': 0, 'parents': ['as']},
	{'value': 'sattama', 'label': 'sattama', 'attrib': [{'pos': 'adj', 'defs': 'most excellent'}], 'isLemma': 1},
	{'value': 'sattavya', 'label': 'sattavya', 'attrib': [{'pos': 'ger', 'defs': 'sit, be sad'}], 'isLemma': 0, 'parents': ['sad']},
	{'value': 'sattum', 'label': 'sattum', 'attrib': [{'pos': 'inf', 'defs': 'sit, be sad'}], 'isLemma': 0, 'parents': ['sad']},
	{'value': 'sattvA', 'label': 'sattvA', 'attrib': [{'pos': 'abs', 'defs': 'sit, be sad'}], 'isLemma': 0, 'parents': ['sad']},
	{'value': 'satya', 'label': 'satya', 'attrib': [{'pos': 'adj', 'defs': 'true, honest'}, {'pos': 'n', 'defs': 'truth'}], 'isLemma': 1},
	{'value': 'satyavikrama', 'label': 'satyavikrama', 'attrib': [{'pos': 'adj', 'defs': 'truly powerful'}], 'isLemma': 1},
	{'value': 'satyavrata', 'label': 'satyavrata', 'attrib': [{'pos': 'n', 'defs': 'truth and vows'}], 'isLemma': 1},
	{'value': 'satyavrataparAyaRa', 'label': 'satyavrataparAyaRa', 'attrib': [{'pos': 'adj', 'defs': 'devoted to truth and vows'}], 'isLemma': 1},
	{'value': 'satsyat', 'label': 'satsyat', 'attrib': [{'pos': 'fap', 'defs': 'sit, be sad'}], 'isLemma': 0, 'parents': ['sad']},
	{'value': 'satsyamAna', 'label': 'satsyamAna', 'attrib': [{'pos': 'fpp', 'defs': 'sit, be sad'}], 'isLemma': 0, 'parents': ['sad']},
	{'value': 'sad', 'label': 'sad', 'attrib': [{'pos': 'vi1a, vi6a', 'defs': 'sit, be sad'}], 'isLemma': 1},
	{'value': 'sadanIya', 'label': 'sadanIya', 'attrib': [{'pos': 'ger', 'defs': 'sit, be sad'}], 'isLemma': 0, 'parents': ['sad']},
	{'value': 'sadyamAna', 'label': 'sadyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'sit, be sad'}], 'isLemma': 0, 'parents': ['sad']},
	{'value': 'sanna', 'label': 'sanna', 'attrib': [{'pos': 'ppp', 'defs': 'sit, be sad'}], 'isLemma': 0, 'parents': ['sad']},
	{'value': 'sannavat', 'label': 'sannavat', 'attrib': [{'pos': 'pap', 'defs': 'sit, be sad'}], 'isLemma': 0, 'parents': ['sad']},
	{'value': 'saptan', 'label': 'saptan', 'attrib': [{'pos': 'card_num', 'defs': 'seven'}], 'isLemma': 1},
	{'value': 'saBA', 'label': 'saBA', 'attrib': [{'pos': 'f', 'defs': 'hall'}], 'isLemma': 1},
	{'value': 'sam', 'label': 'sam', 'attrib': [{'pos': 'preverb', 'defs': 'together'}], 'isLemma': 1},
	{'value': 'samAcayanIya', 'label': 'samAcayanIya', 'attrib': [{'pos': 'ger', 'defs': 'cover or fill with'}], 'isLemma': 0, 'parents': ['sam_A_ci']},
	{'value': 'samAcaraRIya', 'label': 'samAcaraRIya', 'attrib': [{'pos': 'ger', 'defs': 'do, practice'}], 'isLemma': 0, 'parents': ['sam_A_car']},
	{'value': 'samAcarat', 'label': 'samAcarat', 'attrib': [{'pos': 'prap', 'defs': 'do, practice'}], 'isLemma': 0, 'parents': ['sam_A_car']},
	{'value': 'samAcaramARa', 'label': 'samAcaramARa', 'attrib': [{'pos': 'prmp', 'defs': 'do, practice'}], 'isLemma': 0, 'parents': ['sam_A_car']},
	{'value': 'samAcarita', 'label': 'samAcarita', 'attrib': [{'pos': 'ppp', 'defs': 'do, practice'}], 'isLemma': 0, 'parents': ['sam_A_car']},
	{'value': 'samAcaritavat', 'label': 'samAcaritavat', 'attrib': [{'pos': 'pap', 'defs': 'do, practice'}], 'isLemma': 0, 'parents': ['sam_A_car']},
	{'value': 'samAcaritavya', 'label': 'samAcaritavya', 'attrib': [{'pos': 'ger', 'defs': 'do, practice'}], 'isLemma': 0, 'parents': ['sam_A_car']},
	{'value': 'samAcaritum', 'label': 'samAcaritum', 'attrib': [{'pos': 'inf', 'defs': 'do, practice'}], 'isLemma': 0, 'parents': ['sam_A_car']},
	{'value': 'samAcarizyat', 'label': 'samAcarizyat', 'attrib': [{'pos': 'fap', 'defs': 'do, practice'}], 'isLemma': 0, 'parents': ['sam_A_car']},
	{'value': 'samAcarizyamARa', 'label': 'samAcarizyamARa', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'do, practice'}], 'isLemma': 0, 'parents': ['sam_A_car']},
	{'value': 'samAcarya', 'label': 'samAcarya', 'attrib': [{'pos': 'abs', 'defs': 'do, practice'}, {'pos': 'ger', 'defs': 'do, practice'}], 'isLemma': 0, 'parents': ['sam_A_car']},
	{'value': 'samAcaryamARa', 'label': 'samAcaryamARa', 'attrib': [{'pos': 'prpp', 'defs': 'do, practice'}], 'isLemma': 0, 'parents': ['sam_A_car']},
	{'value': 'samAcikivas', 'label': 'samAcikivas', 'attrib': [{'pos': 'pfap', 'defs': 'cover or fill with'}], 'isLemma': 0, 'parents': ['sam_A_ci']},
	{'value': 'samAcicivas', 'label': 'samAcicivas', 'attrib': [{'pos': 'pfap', 'defs': 'cover or fill with'}], 'isLemma': 0, 'parents': ['sam_A_ci']},
	{'value': 'samAcita', 'label': 'samAcita', 'attrib': [{'pos': 'ppp', 'defs': 'cover or fill with'}], 'isLemma': 0, 'parents': ['sam_A_ci']},
	{'value': 'samAcitavat', 'label': 'samAcitavat', 'attrib': [{'pos': 'pap', 'defs': 'cover or fill with'}], 'isLemma': 0, 'parents': ['sam_A_ci']},
	{'value': 'samAcitya', 'label': 'samAcitya', 'attrib': [{'pos': 'abs', 'defs': 'cover or fill with'}], 'isLemma': 0, 'parents': ['sam_A_ci']},
	{'value': 'samAcinvat', 'label': 'samAcinvat', 'attrib': [{'pos': 'prap', 'defs': 'cover or fill with'}], 'isLemma': 0, 'parents': ['sam_A_ci']},
	{'value': 'samAcinvAna', 'label': 'samAcinvAna', 'attrib': [{'pos': 'prmp', 'defs': 'cover or fill with'}], 'isLemma': 0, 'parents': ['sam_A_ci']},
	{'value': 'samAciyAna', 'label': 'samAciyAna', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'cover or fill with'}], 'isLemma': 0, 'parents': ['sam_A_ci']},
	{'value': 'samAcIyamARa', 'label': 'samAcIyamARa', 'attrib': [{'pos': 'prpp', 'defs': 'cover or fill with'}], 'isLemma': 0, 'parents': ['sam_A_ci']},
	{'value': 'samAcetavya', 'label': 'samAcetavya', 'attrib': [{'pos': 'ger', 'defs': 'cover or fill with'}], 'isLemma': 0, 'parents': ['sam_A_ci']},
	{'value': 'samAcetum', 'label': 'samAcetum', 'attrib': [{'pos': 'inf', 'defs': 'cover or fill with'}], 'isLemma': 0, 'parents': ['sam_A_ci']},
	{'value': 'samAceya', 'label': 'samAceya', 'attrib': [{'pos': 'ger', 'defs': 'cover or fill with'}], 'isLemma': 0, 'parents': ['sam_A_ci']},
	{'value': 'samAcerARa', 'label': 'samAcerARa', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'do, practice'}], 'isLemma': 0, 'parents': ['sam_A_car']},
	{'value': 'samAcerivas', 'label': 'samAcerivas', 'attrib': [{'pos': 'pfap', 'defs': 'do, practice'}], 'isLemma': 0, 'parents': ['sam_A_car']},
	{'value': 'samAcezyat', 'label': 'samAcezyat', 'attrib': [{'pos': 'fap', 'defs': 'cover or fill with'}], 'isLemma': 0, 'parents': ['sam_A_ci']},
	{'value': 'samAcezyamARa', 'label': 'samAcezyamARa', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'cover or fill with'}], 'isLemma': 0, 'parents': ['sam_A_ci']},
	{'value': 'samAdaDat', 'label': 'samAdaDat', 'attrib': [{'pos': 'prap', 'defs': 'place together, compose, establish'}], 'isLemma': 0, 'parents': ['sam_A_DA']},
	{'value': 'samAdaDAna', 'label': 'samAdaDAna', 'attrib': [{'pos': 'prmp', 'defs': 'place together, compose, establish'}, {'pos': 'pfmp, pfpp', 'defs': 'place together, compose, establish'}], 'isLemma': 0, 'parents': ['sam_A_DA']},
	{'value': 'samAdaDivas', 'label': 'samAdaDivas', 'attrib': [{'pos': 'pfap', 'defs': 'place together, compose, establish'}], 'isLemma': 0, 'parents': ['sam_A_DA']},
	{'value': 'samADAtavya', 'label': 'samADAtavya', 'attrib': [{'pos': 'ger', 'defs': 'place together, compose, establish'}], 'isLemma': 0, 'parents': ['sam_A_DA']},
	{'value': 'samADAtum', 'label': 'samADAtum', 'attrib': [{'pos': 'inf', 'defs': 'place together, compose, establish'}], 'isLemma': 0, 'parents': ['sam_A_DA']},
	{'value': 'samADAnIya', 'label': 'samADAnIya', 'attrib': [{'pos': 'ger', 'defs': 'place together, compose, establish'}], 'isLemma': 0, 'parents': ['sam_A_DA']},
	{'value': 'samADAya', 'label': 'samADAya', 'attrib': [{'pos': 'abs', 'defs': 'place together, compose, establish'}], 'isLemma': 0, 'parents': ['sam_A_DA']},
	{'value': 'samADAsyat', 'label': 'samADAsyat', 'attrib': [{'pos': 'fap', 'defs': 'place together, compose, establish'}], 'isLemma': 0, 'parents': ['sam_A_DA']},
	{'value': 'samADAsyamAna', 'label': 'samADAsyamAna', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'place together, compose, establish'}], 'isLemma': 0, 'parents': ['sam_A_DA']},
	{'value': 'samADi', 'label': 'samADi', 'attrib': [{'pos': 'm', 'defs': 'putting together, completion, concentration, settled awareness'}], 'isLemma': 1},
	{'value': 'samADIyamAna', 'label': 'samADIyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'place together, compose, establish'}], 'isLemma': 0, 'parents': ['sam_A_DA']},
	{'value': 'samADeya', 'label': 'samADeya', 'attrib': [{'pos': 'ger', 'defs': 'place together, compose, establish'}], 'isLemma': 0, 'parents': ['sam_A_DA']},
	{'value': 'samApanIya', 'label': 'samApanIya', 'attrib': [{'pos': 'ger', 'defs': 'accomplish, complete'}], 'isLemma': 0, 'parents': ['sam_Ap']},
	{'value': 'samApAna', 'label': 'samApAna', 'attrib': [{'pos': 'pfpp', 'defs': 'accomplish, complete'}], 'isLemma': 0, 'parents': ['sam_Ap']},
	{'value': 'samApivas', 'label': 'samApivas', 'attrib': [{'pos': 'pfap', 'defs': 'accomplish, complete'}], 'isLemma': 0, 'parents': ['sam_Ap']},
	{'value': 'samApta', 'label': 'samApta', 'attrib': [{'pos': 'ppp', 'defs': 'accomplish, complete'}], 'isLemma': 0, 'parents': ['sam_Ap']},
	{'value': 'samAptavat', 'label': 'samAptavat', 'attrib': [{'pos': 'pap', 'defs': 'accomplish, complete'}], 'isLemma': 0, 'parents': ['sam_Ap']},
	{'value': 'samAptavya', 'label': 'samAptavya', 'attrib': [{'pos': 'ger', 'defs': 'accomplish, complete'}], 'isLemma': 0, 'parents': ['sam_Ap']},
	{'value': 'samAptum', 'label': 'samAptum', 'attrib': [{'pos': 'inf', 'defs': 'accomplish, complete'}], 'isLemma': 0, 'parents': ['sam_Ap']},
	{'value': 'samApnuvat', 'label': 'samApnuvat', 'attrib': [{'pos': 'prap', 'defs': 'accomplish, complete'}], 'isLemma': 0, 'parents': ['sam_Ap']},
	{'value': 'samApya', 'label': 'samApya', 'attrib': [{'pos': 'abs', 'defs': 'accomplish, complete'}, {'pos': 'ger', 'defs': 'accomplish, complete'}], 'isLemma': 0, 'parents': ['sam_Ap']},
	{'value': 'samApyamAna', 'label': 'samApyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'accomplish, complete'}], 'isLemma': 0, 'parents': ['sam_Ap']},
	{'value': 'samApsyat', 'label': 'samApsyat', 'attrib': [{'pos': 'fap', 'defs': 'accomplish, complete'}], 'isLemma': 0, 'parents': ['sam_Ap']},
	{'value': 'samApsyamAna', 'label': 'samApsyamAna', 'attrib': [{'pos': 'fpp', 'defs': 'accomplish, complete'}], 'isLemma': 0, 'parents': ['sam_Ap']},
	{'value': 'samAhita', 'label': 'samAhita', 'attrib': [{'pos': 'ppp', 'defs': 'place together, compose, establish'}], 'isLemma': 0, 'parents': ['sam_A_DA']},
	{'value': 'samAhitavat', 'label': 'samAhitavat', 'attrib': [{'pos': 'pap', 'defs': 'place together, compose, establish'}], 'isLemma': 0, 'parents': ['sam_A_DA']},
	{'value': 'sam_A_car', 'label': 'sam_A_car', 'attrib': [{'pos': 'vt1am', 'defs': 'do, practice'}], 'isLemma': 1},
	{'value': 'sam_A_ci', 'label': 'sam_A_ci', 'attrib': [{'pos': 'vt5am', 'defs': 'cover or fill with'}], 'isLemma': 1},
	{'value': 'sam_A_DA', 'label': 'sam_A_DA', 'attrib': [{'pos': 'vt3am', 'defs': 'place together, compose, establish'}], 'isLemma': 1},
	{'value': 'sam_Ap', 'label': 'sam_Ap', 'attrib': [{'pos': 'vt5a', 'defs': 'accomplish, complete'}], 'isLemma': 1},
	{'value': 'sam_ud_sah', 'label': 'sam_ud_sah', 'attrib': [{'pos': 'vt1m', 'defs': 'be encouraged, endure, overcome; caus.: encourage, inspire'}], 'isLemma': 1},
	{'value': 'sam_kAS', 'label': 'sam_kAS', 'attrib': [{'pos': 'vt1m, vt4m', 'defs': 'shine'}], 'isLemma': 1},
	{'value': 'sam_gam', 'label': 'sam_gam', 'attrib': [{'pos': 'vi1m', 'defs': 'go together, unite, meet'}], 'isLemma': 1},
	{'value': 'sam_Cid', 'label': 'sam_Cid', 'attrib': [{'pos': 'vt7am', 'defs': 'split, cut'}], 'isLemma': 1},
	{'value': 'sam_DA', 'label': 'sam_DA', 'attrib': [{'pos': 'vt3am', 'defs': 'put together'}], 'isLemma': 1},
	{'value': 'sam_pad', 'label': 'sam_pad', 'attrib': [{'pos': 'vt4m', 'defs': 'succeed, arise'}], 'isLemma': 1},
	{'value': 'sam_pUj', 'label': 'sam_pUj', 'attrib': [{'pos': 'vt10m', 'defs': 'honor'}], 'isLemma': 1},
	{'value': 'sam_pra_vac', 'label': 'sam_pra_vac', 'attrib': [{'pos': 'vt2a', 'defs': 'communicate, declare, relate in full'}], 'isLemma': 1},
	{'value': 'sam_BU', 'label': 'sam_BU', 'attrib': [{'pos': 'vi1a', 'defs': 'come to be, arise, be possible'}], 'isLemma': 1},
	{'value': 'sam_man', 'label': 'sam_man', 'attrib': [{'pos': 'vt4m, vt8m', 'defs': 'agree, approve, esteem, respect'}], 'isLemma': 1},
	{'value': 'sam_mantr', 'label': 'sam_mantr', 'attrib': [{'pos': 'vt10m', 'defs': 'consult'}], 'isLemma': 1},
	{'value': 'sam_vf', 'label': 'sam_vf', 'attrib': [{'pos': 'vt5am', 'defs': 'cover, conceal'}], 'isLemma': 1},
	{'value': 'sam_svap', 'label': 'sam_svap', 'attrib': [{'pos': 'vi2a', 'defs': 'sleep'}], 'isLemma': 1},
	{'value': 'sarpa', 'label': 'sarpa', 'attrib': [{'pos': 'm', 'defs': 'serpent, snake'}], 'isLemma': 1},
	{'value': 'sarva', 'label': 'sarva', 'attrib': [{'pos': 'pron_adj, pron', 'defs': 'all, whole'}], 'isLemma': 1},
	{'value': 'sarvaratna', 'label': 'sarvaratna', 'attrib': [{'pos': 'n', 'defs': 'all jewels'}], 'isLemma': 1},
	{'value': 'sarvaratnasamAcita', 'label': 'sarvaratnasamAcita', 'attrib': [{'pos': 'adj', 'defs': 'covered with all jewels, filled with all wealth'}], 'isLemma': 1},
	{'value': 'sarvaloka', 'label': 'sarvaloka', 'attrib': [{'pos': 'm', 'defs': 'all regions'}], 'isLemma': 1},
	{'value': 'sarvaSas', 'label': 'sarvaSas', 'attrib': [{'pos': 'adv', 'defs': 'wholly, in every way, everywhere'}], 'isLemma': 1},
	{'value': 'sasAna', 'label': 'sasAna', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'end, finish'}], 'isLemma': 0, 'parents': ['so']},
	{'value': 'sasivas', 'label': 'sasivas', 'attrib': [{'pos': 'pfap', 'defs': 'end, finish'}], 'isLemma': 0, 'parents': ['so']},
	{'value': 'sasramBARa', 'label': 'sasramBARa', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'be confident, relax'}], 'isLemma': 0, 'parents': ['sramB']},
	{'value': 'sah', 'label': 'sah', 'attrib': [{'pos': 'vt1m', 'defs': 'bear, endure, overcome'}], 'isLemma': 1},
	{'value': 'saha', 'label': 'saha', 'attrib': [{'pos': 'pcl', 'defs': 'with'}], 'isLemma': 1},
	{'value': 'sahadeva', 'label': 'sahadeva', 'attrib': [{'pos': 'm_pn', 'defs': 'Sahadeva'}], 'isLemma': 1},
	{'value': 'sahanIya', 'label': 'sahanIya', 'attrib': [{'pos': 'ger', 'defs': 'bear, endure, overcome'}], 'isLemma': 0, 'parents': ['sah']},
	{'value': 'sahamAna', 'label': 'sahamAna', 'attrib': [{'pos': 'prmp', 'defs': 'bear, endure, overcome'}], 'isLemma': 0, 'parents': ['sah']},
	{'value': 'sahasObala', 'label': 'sahasObala', 'attrib': [{'pos': 'adj', 'defs': 'with the son of Subala'}], 'isLemma': 1},
	{'value': 'sahAya', 'label': 'sahAya', 'attrib': [{'pos': 'm', 'defs': 'companion'}], 'isLemma': 1},
	{'value': 'sahita', 'label': 'sahita', 'attrib': [{'pos': 'adj', 'defs': 'accompanied by'}], 'isLemma': 1},
	{'value': 'sahitavya', 'label': 'sahitavya', 'attrib': [{'pos': 'ger', 'defs': 'bear, endure, overcome'}], 'isLemma': 0, 'parents': ['sah']},
	{'value': 'sahitum', 'label': 'sahitum', 'attrib': [{'pos': 'inf', 'defs': 'bear, endure, overcome'}], 'isLemma': 0, 'parents': ['sah']},
	{'value': 'sahitvA', 'label': 'sahitvA', 'attrib': [{'pos': 'abs', 'defs': 'bear, endure, overcome'}], 'isLemma': 0, 'parents': ['sah']},
	{'value': 'sahizyamARa', 'label': 'sahizyamARa', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'bear, endure, overcome'}], 'isLemma': 0, 'parents': ['sah']},
	{'value': 'sahya', 'label': 'sahya', 'attrib': [{'pos': 'ger', 'defs': 'bear, endure, overcome'}], 'isLemma': 0, 'parents': ['sah']},
	{'value': 'sahyamAna', 'label': 'sahyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'bear, endure, overcome'}], 'isLemma': 0, 'parents': ['sah']},
	{'value': 'sAtavya', 'label': 'sAtavya', 'attrib': [{'pos': 'ger', 'defs': 'end, finish'}], 'isLemma': 0, 'parents': ['so']},
	{'value': 'sAtum', 'label': 'sAtum', 'attrib': [{'pos': 'inf', 'defs': 'end, finish'}], 'isLemma': 0, 'parents': ['so']},
	{'value': 'sAdya', 'label': 'sAdya', 'attrib': [{'pos': 'ger', 'defs': 'sit, be sad'}], 'isLemma': 0, 'parents': ['sad']},
	{'value': 'sAnIya', 'label': 'sAnIya', 'attrib': [{'pos': 'ger', 'defs': 'end, finish'}], 'isLemma': 0, 'parents': ['so']},
	{'value': 'sAsyat', 'label': 'sAsyat', 'attrib': [{'pos': 'fap', 'defs': 'end, finish'}], 'isLemma': 0, 'parents': ['so']},
	{'value': 'sAsyamAna', 'label': 'sAsyamAna', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'end, finish'}], 'isLemma': 0, 'parents': ['so']},
	{'value': 'sita', 'label': 'sita', 'attrib': [{'pos': 'ppp', 'defs': 'end, finish'}], 'isLemma': 0, 'parents': ['so']},
	{'value': 'sitavat', 'label': 'sitavat', 'attrib': [{'pos': 'pap', 'defs': 'end, finish'}], 'isLemma': 0, 'parents': ['so']},
	{'value': 'sitvA', 'label': 'sitvA', 'attrib': [{'pos': 'abs', 'defs': 'end, finish'}], 'isLemma': 0, 'parents': ['so']},
	{'value': 'sIdat', 'label': 'sIdat', 'attrib': [{'pos': 'prap', 'defs': 'sit, be sad'}], 'isLemma': 0, 'parents': ['sad']},
	{'value': 'sIyamAna', 'label': 'sIyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'end, finish'}], 'isLemma': 0, 'parents': ['so']},
	{'value': 'su', 'label': 'su', 'attrib': [{'pos': 'preverb', 'defs': 'well'}], 'isLemma': 1},
	{'value': 'suKa', 'label': 'suKa', 'attrib': [{'pos': 'adj', 'defs': 'happy'}, {'pos': 'n', 'defs': 'happiness'}], 'isLemma': 1},
	{'value': 'suKAvaha', 'label': 'suKAvaha', 'attrib': [{'pos': 'adj', 'defs': 'bringing happiness'}], 'isLemma': 1},
	{'value': 'sudurmati', 'label': 'sudurmati', 'attrib': [{'pos': 'adj', 'defs': 'very weak-minded, very evil-minded'}], 'isLemma': 1},
	{'value': 'supta', 'label': 'supta', 'attrib': [{'pos': 'ppp', 'defs': 'sleep'}], 'isLemma': 0, 'parents': ['svap']},
	{'value': 'suptavat', 'label': 'suptavat', 'attrib': [{'pos': 'pap', 'defs': 'sleep'}], 'isLemma': 0, 'parents': ['svap']},
	{'value': 'suptvA', 'label': 'suptvA', 'attrib': [{'pos': 'abs', 'defs': 'sleep'}], 'isLemma': 0, 'parents': ['svap']},
	{'value': 'supyamARa', 'label': 'supyamARa', 'attrib': [{'pos': 'prpp', 'defs': 'sleep'}], 'isLemma': 0, 'parents': ['svap']},
	{'value': 'subala', 'label': 'subala', 'attrib': [{'pos': 'm_pn', 'defs': 'Subala'}, {'pos': 'adj', 'defs': 'very powerful'}], 'isLemma': 1},
	{'value': 'suBadra', 'label': 'suBadra', 'attrib': [{'pos': 'adj', 'defs': 'very auspicious'}], 'isLemma': 1},
	{'value': 'suBadrA', 'label': 'suBadrA', 'attrib': [{'pos': 'f_pn', 'defs': 'Subhadrā'}], 'isLemma': 1},
	{'value': 'suviBakta', 'label': 'suviBakta', 'attrib': [{'pos': 'adj', 'defs': 'well distributed'}], 'isLemma': 1},
	{'value': 'suviBaktamahApaTa', 'label': 'suviBaktamahApaTa', 'attrib': [{'pos': 'adj', 'defs': 'whose great roads are well-distributed'}], 'isLemma': 1},
	{'value': 'suzupAna', 'label': 'suzupAna', 'attrib': [{'pos': 'pfpp', 'defs': 'sleep'}], 'isLemma': 0, 'parents': ['svap']},
	{'value': 'suzupivas', 'label': 'suzupivas', 'attrib': [{'pos': 'pfap', 'defs': 'sleep'}], 'isLemma': 0, 'parents': ['svap']},
	{'value': 'suhfjjana', 'label': 'suhfjjana', 'attrib': [{'pos': 'm', 'defs': 'friendly people'}], 'isLemma': 1},
	{'value': 'suhfd', 'label': 'suhfd', 'attrib': [{'pos': 'm', 'defs': 'friend'}, {'pos': 'adj', 'defs': 'good-hearted, well-wishing'}], 'isLemma': 1},
	{'value': 'sUrya', 'label': 'sUrya', 'attrib': [{'pos': 'm', 'defs': 'sun'}], 'isLemma': 1},
	{'value': 'sUryasaMkASa', 'label': 'sUryasaMkASa', 'attrib': [{'pos': 'adj', 'defs': 'whose appearance is like the sun'}], 'isLemma': 1},
	{'value': 'sedAna', 'label': 'sedAna', 'attrib': [{'pos': 'pfpp', 'defs': 'sit, be sad'}], 'isLemma': 0, 'parents': ['sad']},
	{'value': 'sedivas', 'label': 'sedivas', 'attrib': [{'pos': 'pfap', 'defs': 'sit, be sad'}], 'isLemma': 0, 'parents': ['sad']},
	{'value': 'senA', 'label': 'senA', 'attrib': [{'pos': 'f', 'defs': 'army'}], 'isLemma': 1},
	{'value': 'seya', 'label': 'seya', 'attrib': [{'pos': 'ger', 'defs': 'end, finish'}], 'isLemma': 0, 'parents': ['so']},
	{'value': 'sehAna', 'label': 'sehAna', 'attrib': [{'pos': 'pfmp', 'defs': 'bear, endure, overcome'}], 'isLemma': 0, 'parents': ['sah']},
	{'value': 'so', 'label': 'so', 'attrib': [{'pos': 'vt4a', 'defs': 'end, finish'}], 'isLemma': 1},
	{'value': 'soQa', 'label': 'soQa', 'attrib': [{'pos': 'ppp', 'defs': 'bear, endure, overcome'}], 'isLemma': 0, 'parents': ['sah']},
	{'value': 'soQavat', 'label': 'soQavat', 'attrib': [{'pos': 'pap', 'defs': 'bear, endure, overcome'}], 'isLemma': 0, 'parents': ['sah']},
	{'value': 'soQavya', 'label': 'soQavya', 'attrib': [{'pos': 'ger', 'defs': 'bear, endure, overcome'}], 'isLemma': 0, 'parents': ['sah']},
	{'value': 'soQum', 'label': 'soQum', 'attrib': [{'pos': 'inf', 'defs': 'bear, endure, overcome'}], 'isLemma': 0, 'parents': ['sah']},
	{'value': 'soQvA', 'label': 'soQvA', 'attrib': [{'pos': 'abs', 'defs': 'bear, endure, overcome'}], 'isLemma': 0, 'parents': ['sah']},
	{'value': 'sObala', 'label': 'sObala', 'attrib': [{'pos': 'm', 'defs': 'son of Subala'}], 'isLemma': 1},
	{'value': 'sTa', 'label': 'sTa', 'attrib': [{'pos': 'adj', 'defs': 'standing'}], 'isLemma': 1},
	{'value': 'sTA', 'label': 'sTA', 'attrib': [{'pos': 'vi1am', 'defs': 'stand, stay, be present'}], 'isLemma': 1},
	{'value': 'sTAtavya', 'label': 'sTAtavya', 'attrib': [{'pos': 'ger', 'defs': 'stand, stay, be present'}], 'isLemma': 0, 'parents': ['sTA']},
	{'value': 'sTAtum', 'label': 'sTAtum', 'attrib': [{'pos': 'inf', 'defs': 'stand, stay, be present'}], 'isLemma': 0, 'parents': ['sTA']},
	{'value': 'sTAnIya', 'label': 'sTAnIya', 'attrib': [{'pos': 'ger', 'defs': 'stand, stay, be present'}], 'isLemma': 0, 'parents': ['sTA']},
	{'value': 'sTApayitum', 'label': 'sTApayitum', 'attrib': [{'pos': 'inf', 'defs': 'stand, stay, be present'}], 'isLemma': 0, 'parents': ['sTA']},
	{'value': 'sTAsyat', 'label': 'sTAsyat', 'attrib': [{'pos': 'fap', 'defs': 'stand, stay, be present'}], 'isLemma': 0, 'parents': ['sTA']},
	{'value': 'sTAsyamAna', 'label': 'sTAsyamAna', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'stand, stay, be present'}], 'isLemma': 0, 'parents': ['sTA']},
	{'value': 'sTita', 'label': 'sTita', 'attrib': [{'pos': 'ppp', 'defs': 'stand, stay, be present'}], 'isLemma': 0, 'parents': ['sTA']},
	{'value': 'sTitavat', 'label': 'sTitavat', 'attrib': [{'pos': 'pap', 'defs': 'stand, stay, be present'}], 'isLemma': 0, 'parents': ['sTA']},
	{'value': 'sTitvA', 'label': 'sTitvA', 'attrib': [{'pos': 'abs', 'defs': 'stand, stay, be present'}], 'isLemma': 0, 'parents': ['sTA']},
	{'value': 'sTira', 'label': 'sTira', 'attrib': [{'pos': 'adj', 'defs': 'steady, firm'}], 'isLemma': 1},
	{'value': 'sTIyamAna', 'label': 'sTIyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'stand, stay, be present'}], 'isLemma': 0, 'parents': ['sTA']},
	{'value': 'sTeya', 'label': 'sTeya', 'attrib': [{'pos': 'ger', 'defs': 'stand, stay, be present'}], 'isLemma': 0, 'parents': ['sTA']},
	{'value': 'spanda', 'label': 'spanda', 'attrib': [{'pos': 'm', 'defs': 'vibration, activity'}], 'isLemma': 1},
	{'value': 'syat', 'label': 'syat', 'attrib': [{'pos': 'prap', 'defs': 'end, finish'}], 'isLemma': 0, 'parents': ['so']},
	{'value': 'syamAna', 'label': 'syamAna', 'attrib': [{'pos': 'prmp', 'defs': 'end, finish'}], 'isLemma': 0, 'parents': ['so']},
	{'value': 'srabDa', 'label': 'srabDa', 'attrib': [{'pos': 'ppp', 'defs': 'be confident, relax'}], 'isLemma': 0, 'parents': ['sramB']},
	{'value': 'srabDavat', 'label': 'srabDavat', 'attrib': [{'pos': 'pap', 'defs': 'be confident, relax'}], 'isLemma': 0, 'parents': ['sramB']},
	{'value': 'srabDvA', 'label': 'srabDvA', 'attrib': [{'pos': 'abs', 'defs': 'be confident, relax'}], 'isLemma': 0, 'parents': ['sramB']},
	{'value': 'sraByamARa', 'label': 'sraByamARa', 'attrib': [{'pos': 'prpp', 'defs': 'be confident, relax'}], 'isLemma': 0, 'parents': ['sramB']},
	{'value': 'sramB', 'label': 'sramB', 'attrib': [{'pos': 'vi1m', 'defs': 'be confident, relax'}], 'isLemma': 1},
	{'value': 'sramBaRIya', 'label': 'sramBaRIya', 'attrib': [{'pos': 'ger', 'defs': 'be confident, relax'}], 'isLemma': 0, 'parents': ['sramB']},
	{'value': 'sramBamARa', 'label': 'sramBamARa', 'attrib': [{'pos': 'prmp', 'defs': 'be confident, relax'}], 'isLemma': 0, 'parents': ['sramB']},
	{'value': 'sramBitavya', 'label': 'sramBitavya', 'attrib': [{'pos': 'ger', 'defs': 'be confident, relax'}], 'isLemma': 0, 'parents': ['sramB']},
	{'value': 'sramBitum', 'label': 'sramBitum', 'attrib': [{'pos': 'inf', 'defs': 'be confident, relax'}], 'isLemma': 0, 'parents': ['sramB']},
	{'value': 'sramBitvA', 'label': 'sramBitvA', 'attrib': [{'pos': 'abs', 'defs': 'be confident, relax'}], 'isLemma': 0, 'parents': ['sramB']},
	{'value': 'sramBizyamARa', 'label': 'sramBizyamARa', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'be confident, relax'}], 'isLemma': 0, 'parents': ['sramB']},
	{'value': 'sramBya', 'label': 'sramBya', 'attrib': [{'pos': 'ger', 'defs': 'be confident, relax'}], 'isLemma': 0, 'parents': ['sramB']},
	{'value': 'sva', 'label': 'sva', 'attrib': [{'pos': 'pron, pron_adj', 'defs': "one's own"}], 'isLemma': 1},
	{'value': 'svaka', 'label': 'svaka', 'attrib': [{'pos': 'adj', 'defs': "own, one's own"}], 'isLemma': 1},
	{'value': 'svap', 'label': 'svap', 'attrib': [{'pos': 'vi2a', 'defs': 'sleep'}], 'isLemma': 1},
	{'value': 'svapat', 'label': 'svapat', 'attrib': [{'pos': 'prap', 'defs': 'sleep'}], 'isLemma': 0, 'parents': ['svap']},
	{'value': 'svapanIya', 'label': 'svapanIya', 'attrib': [{'pos': 'ger', 'defs': 'sleep'}], 'isLemma': 0, 'parents': ['svap']},
	{'value': 'svaptavya', 'label': 'svaptavya', 'attrib': [{'pos': 'ger', 'defs': 'sleep'}], 'isLemma': 0, 'parents': ['svap']},
	{'value': 'svaptum', 'label': 'svaptum', 'attrib': [{'pos': 'inf', 'defs': 'sleep'}], 'isLemma': 0, 'parents': ['svap']},
	{'value': 'svapya', 'label': 'svapya', 'attrib': [{'pos': 'ger', 'defs': 'sleep'}], 'isLemma': 0, 'parents': ['svap']},
	{'value': 'svapsyat', 'label': 'svapsyat', 'attrib': [{'pos': 'fap', 'defs': 'sleep'}], 'isLemma': 0, 'parents': ['svap']},
	{'value': 'svapsyamARa', 'label': 'svapsyamARa', 'attrib': [{'pos': 'fpp', 'defs': 'sleep'}], 'isLemma': 0, 'parents': ['svap']},
	{'value': 'svamandira', 'label': 'svamandira', 'attrib': [{'pos': 'n', 'defs': "one's own house, palace"}], 'isLemma': 1},
	{'value': 'svar', 'label': 'svar', 'attrib': [{'pos': 'pcl', 'defs': 'sun, heaven'}], 'isLemma': 1},
	{'value': 'svarga', 'label': 'svarga', 'attrib': [{'pos': 'm', 'defs': 'heaven'}], 'isLemma': 1},
	{'value': 'svargasTa', 'label': 'svargasTa', 'attrib': [{'pos': 'adj', 'defs': 'standing in, present in heaven'}], 'isLemma': 1},
	{'value': 'ha', 'label': 'ha', 'attrib': [{'pos': 'pcl', 'defs': 'indeed'}, {'pos': 'adj', 'defs': 'smiting'}], 'isLemma': 1},
	{'value': 'haMsyat', 'label': 'haMsyat', 'attrib': [{'pos': 'fap', 'defs': 'smite'}], 'isLemma': 0, 'parents': ['han']},
	{'value': 'haMsyamAna', 'label': 'haMsyamAna', 'attrib': [{'pos': 'fpp', 'defs': 'smite'}], 'isLemma': 0, 'parents': ['han']},
	{'value': 'hata', 'label': 'hata', 'attrib': [{'pos': 'ppp', 'defs': 'smite'}], 'isLemma': 0, 'parents': ['han']},
	{'value': 'hatavat', 'label': 'hatavat', 'attrib': [{'pos': 'pap', 'defs': 'smite'}], 'isLemma': 0, 'parents': ['han']},
	{'value': 'hatvA', 'label': 'hatvA', 'attrib': [{'pos': 'abs', 'defs': 'smite'}], 'isLemma': 0, 'parents': ['han']},
	{'value': 'han', 'label': 'han', 'attrib': [{'pos': 'vt2a', 'defs': 'smite'}, {'pos': 'adj', 'defs': 'smiting'}], 'isLemma': 1},
	{'value': 'hananIya', 'label': 'hananIya', 'attrib': [{'pos': 'ger', 'defs': 'smite'}], 'isLemma': 0, 'parents': ['han']},
	{'value': 'hanizyat', 'label': 'hanizyat', 'attrib': [{'pos': 'fap', 'defs': 'smite'}], 'isLemma': 0, 'parents': ['han']},
	{'value': 'hanizyamARa', 'label': 'hanizyamARa', 'attrib': [{'pos': 'fpp', 'defs': 'smite'}], 'isLemma': 0, 'parents': ['han']},
	{'value': 'hantavya', 'label': 'hantavya', 'attrib': [{'pos': 'ger', 'defs': 'smite'}], 'isLemma': 0, 'parents': ['han']},
	{'value': 'hantum', 'label': 'hantum', 'attrib': [{'pos': 'inf', 'defs': 'smite'}], 'isLemma': 0, 'parents': ['han']},
	{'value': 'hanyamAna', 'label': 'hanyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'smite'}], 'isLemma': 0, 'parents': ['han']},
	{'value': 'havanIya', 'label': 'havanIya', 'attrib': [{'pos': 'ger', 'defs': 'offer, worship'}], 'isLemma': 0, 'parents': ['hu']},
	{'value': 'havya', 'label': 'havya', 'attrib': [{'pos': 'n', 'defs': 'oblation'}, {'pos': 'ger', 'defs': 'offer, worship'}], 'isLemma': 1},
	{'value': 'havya', 'label': 'havya', 'attrib': [{'pos': 'n', 'defs': 'oblation'}, {'pos': 'ger', 'defs': 'offer, worship'}], 'isLemma': 0, 'parents': ['hu']},
	{'value': 'havyavAhana', 'label': 'havyavAhana', 'attrib': [{'pos': 'm', 'defs': 'oblation-bearer, fire'}], 'isLemma': 1},
	{'value': 'hasta', 'label': 'hasta', 'attrib': [{'pos': 'm', 'defs': 'hand'}], 'isLemma': 1},
	{'value': 'hastin', 'label': 'hastin', 'attrib': [{'pos': 'adj', 'defs': 'having a hand'}, {'pos': 'm', 'defs': 'elephant'}, {'pos': 'm_pn', 'defs': 'Hastin'}], 'isLemma': 1},
	{'value': 'hAstina', 'label': 'hAstina', 'attrib': [{'pos': 'adj', 'defs': 'founded by Hastin'}], 'isLemma': 1},
	{'value': 'hAstinapura', 'label': 'hAstinapura', 'attrib': [{'pos': 'n_pn', 'defs': 'Hāstinapura'}], 'isLemma': 1},
	{'value': 'hi', 'label': 'hi', 'attrib': [{'pos': 'sub_pcl', 'defs': 'for, because'}], 'isLemma': 1},
	{'value': 'hiqimba', 'label': 'hiqimba', 'attrib': [{'pos': 'm_pn', 'defs': 'Hiḍimba'}], 'isLemma': 1},
	{'value': 'hita', 'label': 'hita', 'attrib': [{'pos': 'ppp', 'defs': 'put'}, {'pos': 'adj', 'defs': 'beneficial, good'}], 'isLemma': 1},
	{'value': 'hita', 'label': 'hita', 'attrib': [{'pos': 'ppp', 'defs': 'put'}, {'pos': 'adj', 'defs': 'beneficial, good'}], 'isLemma': 0, 'parents': ['DA']},
	{'value': 'hitavat', 'label': 'hitavat', 'attrib': [{'pos': 'pap', 'defs': 'put'}], 'isLemma': 0, 'parents': ['DA']},
	{'value': 'hitvA', 'label': 'hitvA', 'attrib': [{'pos': 'abs', 'defs': 'put'}], 'isLemma': 0, 'parents': ['DA']},
	{'value': 'hu', 'label': 'hu', 'attrib': [{'pos': 'vt3a', 'defs': 'offer, worship'}], 'isLemma': 1},
	{'value': 'huta', 'label': 'huta', 'attrib': [{'pos': 'ppp', 'defs': 'offer, worship'}], 'isLemma': 0, 'parents': ['hu']},
	{'value': 'hutavat', 'label': 'hutavat', 'attrib': [{'pos': 'pap', 'defs': 'offer, worship'}], 'isLemma': 0, 'parents': ['hu']},
	{'value': 'hutvA', 'label': 'hutvA', 'attrib': [{'pos': 'abs', 'defs': 'offer, worship'}], 'isLemma': 0, 'parents': ['hu']},
	{'value': 'hUyamAna', 'label': 'hUyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'offer, worship'}], 'isLemma': 0, 'parents': ['hu']},
	{'value': 'hfd', 'label': 'hfd', 'attrib': [{'pos': 'n', 'defs': 'heart'}], 'isLemma': 1},
	{'value': 'hfz', 'label': 'hfz', 'attrib': [{'pos': 'vi4a', 'defs': 'be excited, rejoice'}], 'isLemma': 1},
	{'value': 'hfzIka', 'label': 'hfzIka', 'attrib': [{'pos': 'n', 'defs': 'sense-organ'}], 'isLemma': 1},
	{'value': 'hfzIkeSa', 'label': 'hfzIkeSa', 'attrib': [{'pos': 'm', 'defs': 'lord of the senses'}], 'isLemma': 1},
	{'value': 'he', 'label': 'he', 'attrib': [{'pos': 'pcl', 'defs': 'O, hey'}], 'isLemma': 1},
	{'value': 'hotavya', 'label': 'hotavya', 'attrib': [{'pos': 'ger', 'defs': 'offer, worship'}], 'isLemma': 0, 'parents': ['hu']},
	{'value': 'hotum', 'label': 'hotum', 'attrib': [{'pos': 'inf', 'defs': 'offer, worship'}], 'isLemma': 0, 'parents': ['hu']},
	{'value': 'hozyat', 'label': 'hozyat', 'attrib': [{'pos': 'fap', 'defs': 'offer, worship'}], 'isLemma': 0, 'parents': ['hu']},
	{'value': 'hozyamARa', 'label': 'hozyamARa', 'attrib': [{'pos': 'fpp', 'defs': 'offer, worship'}], 'isLemma': 0, 'parents': ['hu']}
]

export const pos ={
	'aklizwa':['adj'],
	'aklizwakarman':['adj'],
	'akza':['m'],
	'akzaya':['adj'],
	'agni':['m'],
	'aNga':['n', 'm'],
	'aNgadeSa':['m'],
	'acira':['adj'],
	'ajYAta':['adj'],
	'aYc':['v1am'],
	'ati':['preverb'],
	'atiBAra':['m'],
	'aTa':['pcl'],
	'ad':['vt2a'],
	'adat':['prap'],
	'adyamAna':['prpp'],
	'atsyat':['fap'],
	'atsyamAna':['fpp'],
	'Adivas':['pfap'],
	'AdAna':['pfpp'],
	'anna':['ppp', 'n'],
	'annavat':['pap'],
	'attum':['inf'],
	'attvA':['abs'],
	'Adya':['ger'],
	'attavya':['ger'],
	'adanIya':['ger'],
	'aDi':['preverb'],
	'aDi_i':['vt2m'],
	'aDIyAna':['prmp'],
	'aDIyamAna':['prpp'],
	'aDyezyamARa':['fmp', 'fpp'],
	'aDijagAna':['pfmp', 'pfpp'],
	'aDIta':['ppp'],
	'aDItavat':['pap'],
	'aDyetum':['inf'],
	'aDItya':['abs'],
	'aDyeya':['ger'],
	'aDyetavya':['ger'],
	'aDyayanIya':['ger'],
	'aDyAya':['m'],
	'anu':['preverb'],
	'anu_cint':['vt10am'],
	'anucintita':['ppp'],
	'anuja':['adj', 'm'],
	'anu_jan':['vi4m'],
	'anujA':['f'],
	'anu_jYA':['vt9a'],
	'anujAnat':['prap'],
	'anujYAyamAna':['prpp'],
	'anujYAsyat':['fap'],
	'anujYAsyamAna':['fpp'],
	'anujajYivas':['pfap'],
	'anujajYAna':['pfpp'],
	'anujYAta':['ppp'],
	'anujYAtavat':['pap'],
	'anujYAtum':['inf'],
	'anujYAya':['abs'],
	'anujYeya':['ger'],
	'anujYAtavya':['ger'],
	'anujYAnIya':['ger'],
	'anujYApya':['abs'],
	'anya':['pron', 'pron_adj'],
	'api':['pcl'],
	'apramatta':['adj'],
	'apramattotTita':['adj'],
	'amita':['adj'],
	'amitatejas':['adj'],
	'amitOjas':['adj'],
	'ari':['m'],
	'ariMdama':['m'],
	'arjuna':['m_pn'],
	'arTa':['m'],
	'ava':['preverb'],
	'ava_DA':['vt3am'],
	'avahita':['ppp', 'adj'],
	'avaso':['v4a'],
	'as':['vi2a'],
	'sat':['prap', 'adj'],
	'asura':['m'],
	'asmad':['pers_pron'],
	'ahita':['adj', 'n'],
	'A':['preverb'],
	'A_gam':['v1a'],
	'A_car ':['vt1a'],
	'A_ci':['vt5am'],
	'A_dA':['vt3am'],
	'Adadat':['prap'],
	'AdadAna':['prmp', 'pfmp', 'pfpp'],
	'AdIyamAna':['prpp'],
	'AdAsyat':['fap'],
	'AdAsyamAna':['fmp', 'fpp'],
	'Adadivas':['pfap'],
	'Atta':['ppp'],
	'Adattavat':['pap'],
	'AdAtum':['inf'],
	'AdAya':['abs'],
	'Adeya':['ger'],
	'AdAtavya':['ger'],
	'AdAnIya':['ger'],
	'Adi':['m'],
	'Adiparvan':['n'],
	'A_diS':['vt6am'],
	'A_DA':['vt3am'],
	'AdaDat':['prap'],
	'AdaDAna':['prmp', 'pfmp', 'pfpp'],
	'ADIyamAna':['prpp'],
	'ADAsyat':['fap'],
	'ADAsyamAna':['fmp', 'fpp'],
	'AdaDivas':['pfap'],
	'Ahita':['ppp'],
	'Ahitavat':['pap'],
	'ADAtum':['inf'],
	'ADAya':['abs'],
	'ADeya':['ger'],
	'ADAtavya':['ger'],
	'ADAnIya':['ger'],
	'A_DI':['vt2m'],
	'Ap':['vt5a'],
	'Apnuvat':['prap'],
	'ApyamAna':['prpp'],
	'Apsyat':['fap'],
	'ApsyamAna':['fpp'],
	'Apivas':['pfap'],
	'ApAna':['pfpp'],
	'Apta':['ppp'],
	'Aptavat':['pap'],
	'Aptum':['inf'],
	'AptvA':['abs'],
	'Apya':['ger'],
	'Aptavya':['ger'],
	'ApanIya':['ger'],
	'A_BA':['vi2a'],
	'A_raB':['vt1m'],
	'AraBamARa':['prmp'],
	'AraByamARa':['prpp'],
	'ArapsyamAna':['fmp', 'fpp'],
	'AreBARa':['pfmp', 'pfpp'],
	'ArabDa':['ppp'],
	'ArabDavat':['pap'],
	'ArabDum':['inf'],
	'AraBya':['abs', 'ger'],
	'ArabDavya':['ger'],
	'AramBanIya':['ger'],
	'A_vah':['vt1am'],
	'Avaha':['adj'],
	'A_vraj':['vt1a'],
	'ASis':['f'],
	'ASI':['f'],
	'ASIviza':['adj'],
	'i':['vt2a'],
	'yat':['prap'],
	'IyamAna':['prpp'],
	'ezyat':['fap'],
	'ezyamARa':['fpp'],
	'Iyivas':['pfap'],
	'IyAna':['pfpp'],
	'ita':['ppp'],
	'itavat':['pap'],
	'etum':['inf'],
	'itvA':['abs'],
	'itya':['ger'],
	'etavya':['ger'],
	'ayanIya':['ger'],
	'iti':['pcl'],
	'idam':['dem_adj', 'dem_pron'],
	'indra':['m_pn'],
	'iva':['pcl'],
	'iz':['vt6a'],
	'icCat':['prap'],
	'izyamARa':['prpp'],
	'ezizyat':['fap'],
	'ezizyamARa':['fpp'],
	'Izivas':['pfap'],
	'IzARa':['pfpp'],
	'izwa':['ppp'],
	'izwavat':['pap'],
	'ezwum':['inf'],
	'izwvA':['abs'],
	'ezya':['ger'],
	'ezwavya':['ger'],
	'ezaRIya':['ger'],
	'izu':['m', 'f'],
	'izuDi':['m'],
	'IS':['vt2m'],
	'ISa':['m'],
	'uttama':['superlative_adj'],
	'ud':['preverb'],
	'udaYc':['adj'],
	'ud_aYc':['v1am'],
	'udara':['n'],
	'udIcI':['f'],
	'ud_sad':['vi1a', 'vi6a'],
	'utsIdat':['prap'],
	'utsadyamAna':['prpp'],
	'utsatsyat':['fap'],
	'utsatsyamAna':['fpp'],
	'utsedivas':['pfap'],
	'utsedAna':['pfpp'],
	'utsanna':['ppp'],
	'utsannavat':['pap'],
	'utsattum':['inf'],
	'utsadya':['abs'],
	'utsAdya':['abs', 'ger'],
	'utsattavya':['ger'],
	'utsadanIya':['ger'],
	'ud_sah':['vt1m'],
	'ud_sTA':['vi1am'],
	'uttizWat':['prap'],
	'uttizWamAna':['prmp'],
	'utsTIyamAna':['prpp'],
	'utTAsyat':['fap'],
	'utTAsyamAna':['fmp', 'fpp'],
	'uttasTivas':['pfap'],
	'uttasTAna':['pfmp', 'pfpp'],
	'utTita':['ppp'],
	'utTitavat':['pap'],
	'utTAtum':['inf'],
	'utTAya':['abs'],
	'utTeya':['ger'],
	'utTAtavya':['ger'],
	'utTAnIya':['ger'],
	'upa':['preverb'],
	'upa_i':['vt2a'],
	'upayat':['prap'],
	'upeyamAna':['prpp'],
	'upEzyat':['fap'],
	'upEzyamARa':['fpp'],
	'upeyivas':['pfap'],
	'upeyAna':['pfpp'],
	'upeta':['ppp', 'adj'],
	'upetavat':['pap'],
	'upEtum':['inf'],
	'upetya':['abs', 'ger'],
	'upEtavya':['ger'],
	'upAyanIya':['ger'],
	'upAya':['m'],
	'A_i':['vt2a'],
	'Ayat':['prap'],
	'eyamAna':['prpp'],
	'Ezyat':['fap'],
	'EzyamARa':['fpp'],
	'eyivas':['pfap'],
	'eyAna':['pfpp'],
	'eta':['ppp'],
	'etavat':['pap'],
	'Etum':['inf'],
	'etya':['abs', 'ger'],
	'Etavya':['ger'],
	'AyanIya':['ger'],
	'fzaBa':['m'],
	'fzi':['m'],
	'eka':['card_num'],
	'ekacakra':['adj'],
	'ekacakrA':['f_pn'],
	'etad':['dem_adj', 'dem_pron'],
	'enad':['dem_adj', 'dem_pron'],
	'eva':['pcl'],
	'evam':['dem_adv'],
	'ojas':['n'],
	'kaT':['vt10am'],
	'kaTayat':['prap'],
	'kaTayamAna':['prmp'],
	'kaTyamAna':['prpp'],
	'kaTayizyat':['fap'],
	'kaTayizyamARa':['fmp', 'fpp'],
	'kaTayAm':['ppf_actn'],
	'kaTita':['ppp'],
	'kaTitavat':['pap'],
	'kaTayitum':['inf'],
	'kaTayitvA':['abs'],
	'kaTya':['ger'],
	'kaTayitavya':['ger'],
	'kaTanIya':['ger'],
	'kaTam':['int_adv'],
	'kaTA':['f'],
	'kadA':['int_adv'],
	'kanyA':['f'],
	'kapi':['m'],
	'kapilakzaRa':['adj'],
	'karRa':['m', 'm_pn'],
	'karman':['n'],
	'kAraka':['adj', 'n'],
	'kAS':['vi1m', 'vi4m'],
	'kASamAna':['prmp'],
	'kASyamAna':['prpp'],
	'kASizyamARa':['fmp', 'fpp'],
	'kASARa':['pfmp', 'pfpp'],
	'kASita':['ppp'],
	'kASitavat':['pap'],
	'kASitum':['inf'],
	'kASitvA':['abs'],
	'kASya':['ger'],
	'kASitavya':['ger'],
	'kASanIya':['ger'],
	'kim':['int_adj', 'int_pron'],
	'kuntI':['f_pn'],
	'kuru':['m_pn'],
	'kurupARqava':['m'],
	'kf':['vt8am'],
	'kurvat':['prap'],
	'kurvARa':['prmp'],
	'kriyamARa':['prpp'],
	'karizyat':['fap'],
	'karizyamARa':['fmp', 'fpp'],
	'cakfvas':['pfap'],
	'cakrARa':['pfmp', 'pfpp'],
	'kfta':['ppp'],
	'kftavat':['pap'],
	'kartum':['inf'],
	'kftvA':['abs'],
	'kftya':['ger'],
	'kArya':['ger'],
	'kartavya':['ger'],
	'karaRIya':['ger'],
	'kftsna':['adj'],
	'kfzRa':['adj', 'm_pn'],
	'kfzRasarpa':['m'],
	'keSa':['m'],
	'keSava':['adj'],
	'kowi':['f'],
	'kOnteya':['m'],
	'kram':['vt1a'],
	'krama':['m'],
	'kruD':['vi4a'],
	'kruDyat':['prap'],
	'kruDyamAna':['prpp'],
	'krotsyat':['fap'],
	'krotsyamAna':['fpp'],
	'cukruDvas':['pfap'],
	'cukruDAna':['pfpp'],
	'krudDa':['ppp'],
	'krudDavat':['pap'],
	'krodDum':['inf'],
	'krudDvA':['abs'],
	'kroDya':['ger'],
	'krodDavya':['ger'],
	'kroDanIya':['ger'],
	'krUra':['adj'],
	'kliS':['vt9a'],
	'kliSnat':['prap'],
	'kliSyamAna':['prpp'],
	'klekzyat':['fap'],
	'kleSizyat':['fap'],
	'klekzyamARa':['fpp'],
	'kleSizyamARa':['fpp'],
	'cikliSivas':['pfap'],
	'cikliSAna':['pfpp'],
	'klizwa':['ppp'],
	'kliSita':['ppp'],
	'klizwavat':['pap'],
	'kliSitavat':['pap'],
	'klezwum':['inf'],
	'kleSitum':['inf'],
	'klizwvA':['abs'],
	'kliSitvA':['abs'],
	'kleSya':['ger'],
	'klezwavya':['ger'],
	'kleSitavya':['ger'],
	'kleSanIya':['ger'],
	'kzam':['vi4a', 'vi1m'],
	'kzAmyat':['prap'],
	'kzamyamARa':['prpp'],
	'kzaMsyat':['fap'],
	'kzamizyat':['fap'],
	'kzaMsyamAna':['fpp'],
	'kzamizyamARa':['fpp'],
	'kzamivas':['pfap'],
	'kzamARa':['pfpp'],
	'kzAnta':['ppp'],
	'kzAntavat':['pap'],
	'kzantum':['inf'],
	'kzamitum':['inf'],
	'kzantvA':['abs'],
	'kzamitvA':['abs'],
	'kzamya':['ger'],
	'kzantavya':['ger'],
	'kzamitavya':['ger'],
	'kzamaRIya':['ger'],
	'kzaya':['m'],
	'kzi':['vt1a', 'vt6a', 'vt5a'],
	'kziyat':['prap'],
	'kzIyamARa':['prpp'],
	'kzezyat':['fap'],
	'kzezyamARa':['fpp'],
	'cikzivas':['pfap'],
	'cikziyARa':['pfpp'],
	'kzIRa':['ppp'],
	'kzita':['ppp'],
	'kzIRavat':['pap'],
	'kzitavat':['pap'],
	'kzetum':['inf'],
	'kzitvA':['abs'],
	'kzeya':['ger'],
	'kzayya':['ger'],
	'kzetavya':['ger'],
	'kzayanIya':['ger'],
	'kziRvat':['prap'],
	'kzit':['adj'],
	'kzip':['vt4a', 'vt6am'],
	'kzipyat':['prap'],
	'kzipat':['prap'],
	'kzipyamARa':['prmp', 'prpp'],
	'kzipsyat':['fap'],
	'kzepsyat':['fap'],
	'kzipsyamARa':['fpp'],
	'kzepsyamARa':['fmp', 'fpp'],
	'cikzipivas':['pfap'],
	'cikzipARa':['pfpp'],
	'kzipta':['ppp'],
	'kziptavat':['pap'],
	'kziptum':['inf'],
	'kziptvA':['abs'],
	'kzepya':['ger'],
	'kzeptavya':['ger'],
	'kzepaRIya':['ger'],
	'Kan':['vt1am'],
	'Kanitra':['n'],
	'Kanitrin':['m'],
	'KanitrI':['f'],
	'KARqava':['m_pn', 'n_pn'],
	'KARqavaprasTa':['m'],
	'gaNgA':['f_pn'],
	'gaRa':['m'],
	'gatamanyu':['adj'],
	'gatavyaTa':['adj'],
	'gam':['vt1a'],
	'gacCat':['prap'],
	'gamyamAna':['prpp'],
	'gamizyat':['fap'],
	'gamizyamARa':['fpp'],
	'jagmivas':['pfap'],
	'jaganvas':['pfap'],
	'jagmAna':['pfpp'],
	'gata':['ppp'],
	'gatavat':['pap'],
	'gantum':['inf'],
	'gatvA':['abs'],
	'gamya':['ger'],
	'gantavya':['ger'],
	'gamanIya':['ger'],
	'gARqI':['f'],
	'gARqIva':['m_pn', 'n_pn'],
	'guru':['adj', 'm'],
	'gfha':['n'],
	'grah':['vt9a'],
	'Gora':['adj'],
	'ca':['conj_pcl'],
	'cakra':['n'],
	'catur':['card_num'],
	'caturdaSa':['ord_num'],
	'cana':['pcl'],
	'car':['vt1am'],
	'carat':['prap'],
	'caramARa':['prmp'],
	'caryamARa':['prpp'],
	'carizyat':['fap'],
	'carizyamARa':['fmp', 'fpp'],
	'cerivas':['pfap'],
	'cerARa':['pfmp', 'pfpp'],
	'carita':['ppp'],
	'caritavat':['pap'],
	'caritum':['inf'],
	'caritvA':['abs'],
	'carya':['ger'],
	'caritavya':['ger'],
	'caraRIya':['ger'],
	'ci':['vt5am'],
	'cinvat':['prap'],
	'cinvAna':['prmp'],
	'cIyamARa':['prpp'],
	'cezyat':['fap'],
	'cezyamARa':['fmp', 'fpp'],
	'cikivas':['pfap'],
	'cicivas':['pfap'],
	'ciyAna':['pfmp', 'pfpp'],
	'cita':['ppp'],
	'citavat':['pap'],
	'cetum':['inf'],
	'citvA':['abs'],
	'ceya':['ger'],
	'cetavya':['ger'],
	'cayanIya':['ger'],
	'cit':['pcl'],
	'cint':['vt10am'],
	'cintayat':['prap'],
	'cintayamAna':['prmp'],
	'cintyamAna':['prpp'],
	'cintayizyat':['fap'],
	'cintayizyamARa':['fmp', 'fpp'],
	'cintayAm':['ppf_actn'],
	'cintita':['ppp'],
	'cintitavat':['pap'],
	'cintitum':['inf'],
	'cintitvA':['abs'],
	'cintya':['ger'],
	'cintitavya':['ger'],
	'cintanIya':['ger'],
	'cira':['adj', 'n'],
	'Cid':['vt7am'],
	'Cindat':['prap'],
	'CindAna':['prmp'],
	'CidyamAna':['prpp'],
	'Cetsyat':['fap'],
	'CetsyamAna':['fmp', 'fpp'],
	'cicCidvas':['pfap'],
	'cicCidAna':['pfmp', 'pfpp'],
	'Cinna':['ppp'],
	'Cinnavat':['pap'],
	'Cettum':['inf'],
	'CittvA':['abs'],
	'Cedya':['ger'],
	'Cettavya':['ger'],
	'CedanIya':['ger'],
	'ja':['adj'],
	'jatu':['n'],
	'jan':['vi4m'],
	'jana':['m'],
	'janapada':['n'],
	'janapadopeta':['adj'],
	'janamejaya':['m_pn'],
	'jaya':['m'],
	'jAtuza':['adj'],
	'ji':['vt1a'],
	'jayat':['prap'],
	'jIyamAna':['prpp'],
	'jezyat':['fap'],
	'jezyamARa':['fpp'],
	'jigivas':['pfap'],
	'jigyAna':['pfpp'],
	'jita':['ppp'],
	'jitavat':['pap'],
	'jetum':['inf'],
	'jitvA':['abs'],
	'jeya':['ger'],
	'jetavya':['ger'],
	'jayanIya':['ger'],
	'jIv':['vi1a'],
	'jIvat':['prap'],
	'jIvyamAna':['prpp'],
	'jIvizyat':['fap'],
	'jIvizyamARa':['fpp'],
	'jijIvas':['pfap'],
	'jijIvAna':['pfpp'],
	'jIvita':['ppp'],
	'jIvitavat':['pap'],
	'jIvitum':['inf'],
	'jIvitvA':['abs'],
	'jIvya':['ger'],
	'jIvitavya':['ger'],
	'jIvanIya':['ger'],
	'jIva':['m'],
	'jIvaloka':['m'],
	'jF':['vi4a'],
	'jIryat':['prap'],
	'jIryamARa':['prpp'],
	'jarizyat':['fap'],
	'jarizyamARa':['fpp'],
	'jajarvas':['pfap'],
	'jerivas':['pfap'],
	'jajarARa':['pfpp'],
	'jerARa':['pfpp'],
	'jIrRa':['ppp'],
	'jIrRavat':['pap'],
	'jaritum':['inf'],
	'jarItum':['inf'],
	'jaritvA':['abs'],
	'jarItvA':['abs'],
	'jarya':['ger'],
	'jArya':['ger'],
	'jaritavya':['ger'],
	'jarItavya':['ger'],
	'jaraRIya':['ger'],
	'jarayAm':['ppf_actn'],
	'jYA':['vt9am'],
	'jAnat':['prap'],
	'jYAyamAna':['prpp'],
	'jYAsyat':['fap'],
	'jYAsyamAna':['fpp'],
	'jajYivas':['pfap'],
	'jajYAna':['pfpp'],
	'jYAta':['ppp'],
	'jYAtavat':['pap'],
	'jYAtum':['inf'],
	'jYAtvA':['abs'],
	'jYeya':['ger'],
	'jYAtavya':['ger'],
	'jYAnIya':['ger'],
	'jYApya':['abs'],
	'tatas':['dem_adv'],
	'tatra':['dem_adv'],
	'taTA':['dem_adv'],
	'tad':['dem_adj', 'dem_pron'],
	'tadA':['dem_adv'],
	'tan':['vt8am'],
	'tanvat':['prap'],
	'tanvAna':['prmp'],
	'tanyamAna':['prpp'],
	'taMsyat':['fap'],
	'tanizyat':['fap'],
	'taMsyamAna':['fmp', 'fpp'],
	'tanizyamARa':['fmp', 'fpp'],
	'tenivas':['pfap'],
	'tenAna':['pfmp', 'pfpp'],
	'tata':['ppp'],
	'tatavat':['pap'],
	'tanitum':['inf'],
	'tanitvA':['abs'],
	'tAnya':['ger'],
	'tanitavya':['ger'],
	'tananIya':['ger'],
	'tanu':['adj', 'f'],
	'tap':['vi4m', 'vt1a'],
	'tapat':['prap'],
	'tapyamAna':['prpp'],
	'tapsyat':['fap'],
	'tapsyamARa':['fpp'],
	'tepivas':['pfap'],
	'tepAna':['pfpp'],
	'tapta':['ppp'],
	'taptavat':['pap'],
	'taptum':['inf'],
	'taptvA':['abs'],
	'tapya':['ger'],
	'taptavya':['ger'],
	'tapanIya':['ger'],
	'tapa':['adj'],
	'tAta':['m'],
	'tu':['conj_pcl'],
	'tfp':['vi4a'],
	'tfpyat':['prap'],
	'tfpyamARa':['prpp'],
	'tarpsyat':['fap'],
	'trapsyat':['fap'],
	'tarpizyat':['fap'],
	'tatfpivas':['pfap'],
	'tfpta':['ppp'],
	'tfptavat':['pap'],
	'tarptum':['inf'],
	'traptum':['inf'],
	'tarpitum':['inf'],
	'tfptvA':['abs'],
	'tarpitvA':['abs'],
	'tarptavya':['ger'],
	'traptavya':['ger'],
	'tarpitavya':['ger'],
	'tejas':['n'],
	'toya':['n'],
	'trayas':['m'],
	'trayodaSa':['ord_num'],
	'tri':['card_num'],
	'tvad':['pers_pron'],
	'daMS':['vt1a'],
	'daSat':['prap'],
	'daSyamAna':['prpp'],
	'daNkzyat':['fap'],
	'dadaMSivas':['pfap'],
	'dazwa':['ppp'],
	'dazwavat':['pap'],
	'daMzwum':['inf'],
	'dazwvA':['abs'],
	'daMSya':['ger'],
	'daMzwavya':['ger'],
	'daMSanIya':['ger'],
	'dakziRa':['adj', 'pron_adj', 'pron'],
	'dakziRA':['f'],
	'dam':['vt4a'],
	'dama':['adj'],
	'daSan':['card_num'],
	'dah':['vt1a'],
	'dahat':['prap'],
	'dahyamAna':['prpp'],
	'Dakzyat':['fap'],
	'DakzyamARa':['fpp'],
	'dadahvas':['pfap'],
	'dadahAna':['pfpp'],
	'dagDa':['ppp'],
	'dagDavat':['pap'],
	'dagDum':['inf'],
	'dagDvA':['abs'],
	'dAhya':['ger'],
	'dagDavya':['ger'],
	'dahanIya':['ger'],
	'dA':['vt3am'],
	'dadat':['prap'],
	'dadAna':['prmp', 'pfmp', 'pfpp'],
	'dIyamAna':['prpp'],
	'dAsyat':['fap'],
	'dAsyamAna':['fmp', 'fpp'],
	'dadivas':['pfap'],
	'datta':['ppp'],
	'dattavat':['pap'],
	'dAtum':['inf'],
	'dattvA':['abs'],
	'deya':['ger'],
	'dAtavya':['ger'],
	'dAnIya':['ger'],
	'div':['m', 'f'],
	'divya':['adj'],
	'diS':['f', 'vt6am'],
	'diSat':['prap'],
	'diSamAna':['prmp'],
	'diSyamAna':['prpp'],
	'dekzyat':['fap'],
	'dekzyamARa':['fmp', 'fpp'],
	'didiSivas':['pfap'],
	'didiSAna':['pfmp', 'pfpp'],
	'dizwa':['ppp'],
	'dizwavat':['pap'],
	'dezwum':['inf'],
	'dizwvA':['abs'],
	'deSya':['ger'],
	'dezwavya':['ger'],
	'deSanIya':['ger'],
	'duHSAsana':['m_pn'],
	'durmati':['adj'],
	'duryoDana':['m_pn'],
	'dus':['preverb'],
	'dfS':['vt1a'],
	'paSyat':['prap'],
	'dfSyamAna':['prpp'],
	'drakzyat':['fap'],
	'drakzyamARa':['fpp'],
	'dadfSvas':['pfap'],
	'dadfSivas':['pfap'],
	'dadfSAna':['pfpp'],
	'dfzwa':['ppp'],
	'dfzwavat':['pap'],
	'drazwum':['inf'],
	'dfzwvA':['abs'],
	'dfSya':['ger'],
	'drazwavya':['ger'],
	'darSanIya':['ger'],
	'deva':['m'],
	'deSa':['m'],
	'dEva':['n'],
	'dEvaBAvyarTarakzita':['adj'],
	'dyUta':['n'],
	'dyUtasaMBUta':['adj'],
	'dru':['m', 'n', 'vt1a'],
	'dravat':['prap'],
	'drUyamARa':['prpp'],
	'drozyat':['fap'],
	'drozyamARa':['fpp'],
	'dudruvas':['pfap'],
	'dudruvARa':['pfpp'],
	'druta':['ppp', 'adj'],
	'drutavat':['pap'],
	'drotum':['inf'],
	'drutvA':['abs'],
	'dravya':['ger'],
	'drotavya':['ger'],
	'dravaRIya':['ger'],
	'drupada':['m_pn', 'n'],
	'drOpadI':['f'],
	'dvAra':['n'],
	'dvAravat':['adj'],
	'dvAravatI':['f'],
	'dvi':['card_num'],
	'dvija':['adj'],
	'Dana':['n'],
	'DanaMjaya':['m'],
	'Danus':['n'],
	'Dara':['adj'],
	'Darma':['m'],
	'DarmapraDAna':['adj'],
	'DarmarAja':['m'],
	'DA':['vt3am'],
	'daDat':['prap'],
	'daDAna':['prmp', 'pfmp', 'pfpp'],
	'DIyamAna':['prpp'],
	'DAsyat':['fap'],
	'DAsyamAna':['fmp', 'fpp'],
	'daDivas':['pfap'],
	'hita':['ppp', 'adj'],
	'hitavat':['pap'],
	'DAtum':['inf'],
	'hitvA':['abs'],
	'Deya':['ger'],
	'DAtavya':['ger'],
	'DAnIya':['ger'],
	'DI':['f'],
	'DImat':['adj'],
	'Df':['vt1am'],
	'Darat':['prap'],
	'DaramARa':['prmp'],
	'DriyamARa':['prpp'],
	'Darizyat':['fap'],
	'DarizyamARa':['fmp', 'fpp'],
	'daDfvas':['pfap'],
	'daDrARa':['pfmp', 'pfpp'],
	'Dfta':['ppp'],
	'Dftavat':['pap'],
	'Dartum':['inf'],
	'DftvA':['abs'],
	'Dftya':['ger'],
	'Dartavya':['ger'],
	'DaraRIya':['ger'],
	'DftarAzwra':['m_pn'],
	'DftarAzwraja':['adj'],
	'DftarAzwraBIzma':['m'],
	'na':['neg_pcl'],
	'nakula':['m_pn'],
	'nagara':['n'],
	'nacira':['adj'],
	'nadI':['f'],
	'nam':['vt1a'],
	'namat':['prap'],
	'namyamAna':['prpp'],
	'naMsyat':['fap'],
	'naMsyamAna':['fpp'],
	'nemivas':['pfap'],
	'nata':['ppp'],
	'natavat':['pap'],
	'nantum':['inf'],
	'natvA':['abs'],
	'namya':['ger'],
	'nantavya':['ger'],
	'namanIya':['ger'],
	'namas':['n'],
	'namas_kf':['vt8am'],
	'namaskurvat':['prap'],
	'namaskurvARa':['prmp'],
	'namaskriyamARa':['prpp'],
	'namaskarizyat':['fap'],
	'namaskarizyamARa':['fmp', 'fpp'],
	'namaScakfvas':['pfap'],
	'namaScakrARa':['pfmp', 'pfpp'],
	'namaskfta':['ppp'],
	'namaskftavat':['pap'],
	'namaskartum':['inf'],
	'namaskftya':['abs', 'ger'],
	'namaskArya':['ger'],
	'namaskartavya':['ger'],
	'namaskaraRIya':['ger'],
	'naS':['vi4a'],
	'naSyat':['prap'],
	'neSivas':['pfap'],
	'neSvas':['pfap'],
	'nazwa':['ppp'],
	'nazwavat':['ppp'],
	'nazwum':['inf'],
	'nazwvA':['abs'],
	'nASya':['ger'],
	'nazwavya':['ger'],
	'naSanIya':['ger'],
	'nAman':['n'],
	'ni':['preverb'],
	'ni_grah':['vt9a'],
	'nigraha':['m'],
	'nigrahanirvAsa':['m'],
	'nitya':['adj'],
	'nityam':['adv'],
	'nimitta':['n'],
	'nir_vas':['vi1a'],
	'nirvAsa':['m'],
	'ni_vas':['vi1a'],
	'nis':['preverb'],
	'ni_han':['vt2a'],
	'niGnat':['prap'],
	'nihanyamAna':['prpp'],
	'nihaMsyat':['fap'],
	'nihanizyat':['fap'],
	'nihaMsyamAna':['fpp'],
	'nihanizyamARa':['fpp'],
	'nijaGnivas':['pfap'],
	'nijaGanvas':['pfap'],
	'nijaGnAna':['pfpp'],
	'nihata':['ppp'],
	'nihatavat':['pap'],
	'nihantum':['inf'],
	'nihanya':['abs'],
	'niGAtya':['ger'],
	'nihantavya':['ger'],
	'nihananIya':['ger'],
	'nf':['m'],
	'nfpa':['m'],
	'nfpasattama':['m'],
	'paYcan':['card_num'],
	'paYcapaYcASat':['card_num'],
	'paYcapaYcASattama':['ord_num'],
	'paYcAla':['m'],
	'paYcASat':['card_num'],
	'paTin':['m'],
	'pad':['vt4m'],
	'padyamAna':['prmp', 'prpp'],
	'patsyamAna':['fmp', 'fpp'],
	'pedAna':['pfmp', 'pfpp'],
	'panna':['ppp'],
	'pannavat':['pap'],
	'pattum':['inf'],
	'pattvA':['abs'],
	'pAdya':['ger'],
	'pattavya':['ger'],
	'padanIya':['ger'],
	'pada':['n'],
	'para':['pron', 'pron_adj', 'm'],
	'paraMtapa':['adj'],
	'paravIra':['m'],
	'paravIrahan':['adj'],
	'parA':['preverb'],
	'parA_kram':['vt1a'],
	'parAkrama':['m'],
	'parAyaRa':['n'],
	'pari':['preverb'],
	'parisaMvatsara':['m'],
	'parisaMvatsarozita':['adj'],
	'parispanda':['m'],
	'pare':['vt2a'],
	'parvan':['n'],
	'pA':['vt2a', 'vt1a'],
	'pibat':['prap'],
	'pIyamAna':['prpp'],
	'pAsyat':['fap'],
	'pAsyamAna':['fpp'],
	'papivas':['pfap'],
	'papAna':['pfpp'],
	'pIta':['ppp'],
	'pItavat':['pap'],
	'pAtum':['inf'],
	'pItvA':['abs'],
	'peya':['ger'],
	'pAtavya':['ger'],
	'pAnIya':['ger'],
	'pAYcAla':['adj'],
	'pARqava':['m'],
	'pARqu':['adj', 'm_pn'],
	'pAtra':['n'],
	'pApa':['adj', 'n'],
	'pArTa':['m'],
	'pAvaka':['adj'],
	'pitf':['m'],
	'putra':['m'],
	'punar':['adv'],
	'pura':['n'],
	'purA':['adv'],
	'purAvftta':['adj', 'n', 'ck'],
	'pU':['vt9am'],
	'punat':['prap'],
	'punAna':['prmp'],
	'pUyamAna':['prpp'],
	'pavizyat':['fap'],
	'pavizyamARa':['fmp', 'fpp'],
	'pupUvas':['pfap'],
	'pupuvAna':['pfmp', 'pfpp'],
	'pUta':['ppp'],
	'pavita':['ppp'],
	'pUtavat':['pap'],
	'pavitavat':['pap'],
	'pavitum':['inf'],
	'pUtvA':['abs'],
	'pavitvA':['abs'],
	'pavya':['ger'],
	'pavitavya':['ger'],
	'pavanIya':['ger'],
	'pUj':['vt10m'],
	'pUjayamAna':['prmp'],
	'pUjyamAna':['prpp'],
	'pUjayizyamARa':['fmp', 'fpp'],
	'pUjayAm':['ppf_actn'],
	'pUjita':['ppp'],
	'pUjitavat':['pap'],
	'pUjayitum':['inf'],
	'pUjayitvA':['abs'],
	'pUjya':['ger'],
	'pUjitavya':['ger'],
	'pUjanIya':['ger'],
	'pUrRa':['adj'],
	'pfTa':['adj'],
	'pfTA':['f'],
	'pfTivI':['f'],
	'pfTivIkzaya':['m'],
	'pfTivIkzayakAraka':['adj'],
	'pfTu':['adj'],
	'pfTvI':['f'],
	'pF':['vt9a'],
	'pfRat':['prap'],
	'pUryamARa':['prpp'],
	'parizyat':['fap'],
	'parIzyat':['fap'],
	'parizyamARa':['fpp'],
	'parIzyamARa':['fpp'],
	'papFvas':['pfap'],
	'paparvas':['pfap'],
	'paprARa':['pfpp'],
	'paparARa':['pfpp'],
	'pUrta':['ppp'],
	'pUrtavat':['pap'],
	'pUritum':['inf'],
	'pUrtvA':['abs'],
	'pUrya':['ger'],
	'pUritavya':['ger'],
	'pUraRIya':['ger'],
	'pOra':['adj', 'm'],
	'pOrasaMmata':['adj'],
	'pra':['preverb'],
	'pra_Ap':['vt5a'],
	'prApnuvat':['prap'],
	'prApyamAna':['prpp'],
	'prApsyat':['fap'],
	'prApsyamAna':['fpp'],
	'prApivas':['pfap'],
	'prApARa':['pfpp'],
	'prApta':['ppp'],
	'prAptavat':['pap'],
	'prAptum':['inf'],
	'prApya':['abs', 'ger'],
	'prAptavya':['ger'],
	'prApaRIya':['ger'],
	'pra_ud_sah':['vt1m', 'vt10am'],
	'prakAra':['m'],
	'pra_kf':['vt8am'],
	'pra_kzip':['vt4a', 'vt6am'],
	'prakzipyat':['prap'],
	'prakzipat':['prap'],
	'prakzipyamARa':['prmp', 'prpp'],
	'prakzipsyat':['fap'],
	'prakzepsyat':['fap'],
	'prakzipsyamARa':['fpp'],
	'prakzepsyamARa':['fmp', 'fpp'],
	'pracikzipivas':['pfap'],
	'pracikzipARa':['pfpp'],
	'prakzipta':['ppp'],
	'prakziptavat':['pap'],
	'prakzeptum':['inf'],
	'prakzipya':['abs'],
	'prakzepya':['ger'],
	'prakzeptavya':['ger'],
	'prakzepayaRIya':['ger'],
	'pracC':['vt6a'],
	'pfcCat':['prap'],
	'pfcCyamAna':['prpp'],
	'prakzyat':['fap'],
	'prakzyamARa':['fpp'],
	'papracCvas':['pfap'],
	'papracCAna':['pfpp'],
	'pfzwa':['ppp'],
	'pfzwavat':['pap'],
	'prazwum':['inf'],
	'pfzwvA':['abs'],
	'pfcCya':['ger'],
	'prazwavya':['ger'],
	'pracCanIya':['ger'],
	'pra_tap':['vt1a'],
	'pratapat':['prap'],
	'pratapyamAna':['prpp'],
	'pratapsyat':['fap'],
	'pratapsyamARa':['fpp'],
	'pratepivas':['pfap'],
	'pratepAna':['pfpp'],
	'pratapta':['ppp'],
	'prataptavat':['pap'],
	'prataptum':['inf'],
	'pratapya':['abs', 'ger'],
	'prataptavya':['ger'],
	'pratapanIya':['ger'],
	'pratApa':['m'],
	'prati':['preverb', 'pcl'],
	'prati_A_gam':['vt1a'],
	'pratyAgacCat':['prap'],
	'pratyAgamyamAna':['prpp'],
	'pratyAgamizyat':['fap'],
	'pratyAgamizyamARa':['fpp'],
	'pratyAjagmivas':['pfap'],
	'pratyAjaganvas':['pfap'],
	'pratyAjagmAna':['pfpp'],
	'pratyAgata':['ppp'],
	'pratyAgatavat':['pap'],
	'pratyAgantum':['inf'],
	'pratyAgamya':['abs', 'ger'],
	'pratyAgatya':['abs'],
	'pratyAgantavya':['ger'],
	'pratyAgamanIya':['ger'],
	'pratiGAta':['m'],
	'prati_pad':['vt4m'],
	'pratipadyamAna':['prmp', 'prpp'],
	'pratipatsyamAna':['fmp', 'fpp'],
	'pratipedAna':['pfmp', 'pfpp'],
	'pratipanna':['ppp'],
	'pratipannavat':['pap'],
	'pratipattum':['inf'],
	'pratipattvA':['abs'],
	'pratipadya':['abs'],
	'pratipAdya':['ger'],
	'pratipattavya':['ger'],
	'pratipadanIya':['ger'],
	'pratIcI':['f'],
	'pratyaYc':['adj'],
	'praty_A_gam':['vt1a'],
	'praT':['vi1m'],
	'pra_dru':['vt1a'],
	'praDAna':['adj'],
	'pra_buD':['vt1am', 'vt4m'],
	'prabuDyamAna':['prmp', 'prpp'],
	'praBotsyamAna':['fmp', 'fpp'],
	'prabubuDAna':['pfmp', 'pfpp'],
	'prabudDa':['ppp'],
	'prabudDavat':['pap'],
	'prabodDum':['inf'],
	'prabudDvA':['abs'],
	'prabuDya':['abs'],
	'praboDya':['ger'],
	'prabodDavya':['ger'],
	'praboDanIya':['ger'],
	'pra_mad':['vi4a'],
	'pramatta':['ppp'],
	'pramARa':['n'],
	'pramARakowi':['f_pn'],
	'pra_vac':['vt2a'],
	'procyamAna':['prpp'],
	'pravakzyat':['fap'],
	'pravakzyamARa':['fpp'],
	'procivas':['pfap'],
	'procAna':['pfpp'],
	'prokta':['ppp'],
	'proktavat':['pap'],
	'pravaktum':['inf'],
	'proktvA':['abs'],
	'procya':['abs'],
	'pravAkya':['ger'],
	'pravaktavya':['ger'],
	'pravacanIya':['ger'],
	'prasTa':['m', 'n'],
	'pra_sTA':['vt1m'],
	'pratizWamAna':['prmp'],
	'prasTIyamAna':['prpp'],
	'prasTAsyamAna':['fmp', 'fpp'],
	'pratasTAna':['pfmp', 'pfpp'],
	'prasTita':['ppp'],
	'prasTitavat':['pap'],
	'prasTAtum':['inf'],
	'prasTAya':['abs'],
	'prasTeya':['ger'],
	'prasTAtavya':['ger'],
	'prasTAnIya':['ger'],
	'prasTApayAm':['ppf_actn'],
	'prAk':['adv'],
	'prAcI':['f'],
	'prAYc':['adj'],
	'prI':['vt9am', 'vi4m'],
	'prIta':['ppp'],
	'baka':['m', 'm_pn'],
	'banD':['vt9am'],
	'baDnat':['prap'],
	'baDyamAna':['prpp'],
	'Bantsyat':['fap'],
	'BantsyamAna':['fpp'],
	'babanDvas':['pfap'],
	'babanDAna':['pfpp'],
	'badDa':['ppp'],
	'badDavat':['pap'],
	'bandDum':['inf'],
	'badDvA':['abs'],
	'banDya':['ger'],
	'bandDavya':['ger'],
	'banDanIya':['ger'],
	'banDana':['n'],
	'bala':['n'],
	'bahu':['adj'],
	'bARa':['m'],
	'bAD':['vt1'],
	'bIBatsu':['adj'],
	'budDi':['f'],
	'buD':['vt1am', 'vt4m'],
	'boDat':['prap'],
	'boDamAna':['prmp'],
	'buDyamAna':['prpp', 'prmp'],
	'Botsyat':['fap'],
	'BotsyamAna':['fmp', 'fpp'],
	'bubuDvas':['pfap'],
	'bubuDAna':['pfmp', 'pfpp'],
	'buDita':['ppp'],
	'buDitavat':['pap'],
	'boDitum':['inf'],
	'buDitvA':['abs'],
	'boDitvA':['abs'],
	'boDya':['ger'],
	'boDitavya':['ger'],
	'boDanIya':['ger'],
	'budDa':['ppp'],
	'budDavat':['pap'],
	'bodDum':['inf'],
	'budDvA':['abs'],
	'bodDavya':['ger'],
	'brahman':['m'],
	'brahmarUpa':['n'],
	'brahmarUpaDara':['adj'],
	'brAhmaRArTa':['m'],
	'brAhmaRa':['m'],
	'Baj':['vt1am'],
	'Bajat':['prap'],
	'BajamAna':['prmp'],
	'BajyamAna':['prpp'],
	'Bakzyat':['fap'],
	'BakzyamAna':['fmp', 'fpp'],
	'Bejivas':['pfap'],
	'BejAna':['pfmp', 'pfpp'],
	'Bakta':['ppp'],
	'Baktavat':['pap'],
	'Baktum':['inf'],
	'BAktvA':['abs'],
	'Bajya':['ger'],
	'Baktavya':['ger'],
	'BajanIya':['ger'],
	'Badra':['adj', 'n'],
	'BadraBAzin':['adj'],
	'Baya':['n'],
	'Bara':['adj'],
	'Barata':['m_pn'],
	'BaratarzaBa':['m'],
	'BA':['vi2a'],
	'BAt':['prap'],
	'BAyamAna':['prpp'],
	'BAsyat':['fap'],
	'BAsyamAna':['fpp'],
	'baBivas':['pfap'],
	'baBAna':['pfpp'],
	'BAta':['ppp'],
	'BAtavat':['pap'],
	'BAtum':['inf'],
	'BAtavya':['ger'],
	'BAnIya':['ger'],
	'BAra':['m'],
	'BArata':['m', 'adj'],
	'BAryA':['f'],
	'BAvin':['adj'],
	'BAvyarTa':['m'],
	'BAvyarTarakzita':['adj'],
	'BAz':['vt1m'],
	'BAzamARa':['prmp'],
	'BAzyamARa':['prpp'],
	'BAzizyamARa':['fmp', 'fpp'],
	'baBAzARa':['pfmp', 'pfpp'],
	'BAzita':['ppp'],
	'BAzitavat':['pap'],
	'BAzitum':['inf'],
	'BAzitvA':['abs'],
	'BAzya':['ger'],
	'BAzitavya':['ger'],
	'BAzaRIya':['ger'],
	'BAzin':['adj'],
	'Bid':['vt7am'],
	'Bindat':['prap'],
	'BindAna':['prmp'],
	'BidyamAna':['prpp'],
	'Betsyat':['fap'],
	'BetsyamAna':['fmp', 'fpp'],
	'biBidvas':['pfap'],
	'biBidAna':['pfmp', 'pfpp'],
	'Binna':['ppp'],
	'Binnavat':['pap'],
	'Bettum':['inf'],
	'BittvA':['abs'],
	'Bedya':['ger'],
	'Bettavya':['ger'],
	'BedanIya':['ger'],
	'BI':['vi3a'],
	'biByat':['prap'],
	'BIyamAna':['prpp'],
	'Bezyat':['fap'],
	'BezyamARa':['fpp'],
	'biBayAm':['ppf_actn'],
	'biBIvas':['pfap'],
	'biByAna':['pfpp'],
	'BIta':['ppp'],
	'BItavat':['pap'],
	'Betum':['inf'],
	'BItvA':['abs'],
	'Beya':['ger'],
	'Betavya':['ger'],
	'BayanIya':['ger'],
	'BIma':['adj', 'm_pn'],
	'BImaparAkrama':['adj'],
	'BImasena':['adj'],
	'BIzma':['adj', 'm_pn'],
	'BU':['vi1a', 'f'],
	'Bavat':['prap'],
	'BUyamAna':['prpp'],
	'Bavizyat':['fap'],
	'BavizyamARa':['fpp'],
	'baBUvas':['pfap'],
	'BUta':['ppp'],
	'BUtavat':['pap'],
	'Bavitum':['inf'],
	'BUtvA':['abs'],
	'Bavya':['ger'],
	'Bavitavya':['ger'],
	'BavanIya':['ger'],
	'BUyas':['comparative_adj'],
	'BUyizWa':['superlative_adj'],
	'Bf':['vt3am'],
	'biBrat':['prap'],
	'biBrARa':['prmp'],
	'BriyamARa':['prpp'],
	'Barizyat':['fap'],
	'BarizyamARa':['fmp', 'fpp'],
	'biBarAm':['ppf_actn'],
	'baBfvas':['pfap'],
	'baBrARa':['pfmp', 'pfpp'],
	'Bfta':['ppp'],
	'Bftavat':['pap'],
	'Bartum':['inf'],
	'BftvA':['abs'],
	'Bftya':['ger'],
	'Bartavya':['ger'],
	'BaraRIya':['ger'],
	'Beda':['m'],
	'BrAtf':['m'],
	'mata':['n', 'ppp'],
	'mati':['f'],
	'mad':['pers_pron', 'vi4a'],
	'mAdyat':['prap'],
	'madyamAna':['prpp'],
	'madizyat':['fap'],
	'madizyamARa':['fpp'],
	'medivas':['pfap'],
	'medAna':['pfpp'],
	'matta':['ppp'],
	'mattavat':['pap'],
	'maditum':['inf'],
	'maditvA':['abs'],
	'madya':['ger'],
	'maditavya':['ger'],
	'madanIya':['ger'],
	'man':['vt4m', 'vt8m'],
	'manyamAna':['prmp', 'prpp'],
	'manvAna':['prmp'],
	'maMsyamAna':['fmp', 'fpp'],
	'menAna':['pfmp', 'pfpp'],
	'matavat':['pap'],
	'mantum':['inf'],
	'matvA':['abs'],
	'mAnya':['ger'],
	'mantavya':['ger'],
	'mananIya':['ger'],
	'manas':['n'],
	'manobudDisamADi':['m'],
	'mantr':['v10m'],
	'mantrayamAna':['prmp'],
	'mantryamAna':['prpp'],
	'mantrayizyamARa':['fmp', 'fpp'],
	'mantrayAm':['ppf_actn'],
	'mantrita':['ppp'],
	'mantritavat':['pap'],
	'mantrayitum':['inf'],
	'mantrayitvA':['abs'],
	'mantrya':['ger'],
	'mantrayitavya':['ger'],
	'mantraRIya':['ger'],
	'mantra':['m'],
	'manda':['adj'],
	'mandira':['n'],
	'manyu':['m'],
	'maya':['m_pn'],
	'mah':['vi1m', 'vt10a'],
	'mahat':['adj'],
	'maharzi':['m'],
	'mahApaTa':['m'],
	'mahAbala':['adj'],
	'mahABArata':['n'],
	'mahAmati':['adj'],
	'mahArAja':['m'],
	'mahAvana':['n'],
	'mahAsura':['m'],
	'mahI':['f'],
	'mahIkzit':['m'],
	'mahendra':['m'],
	'mA':['vt2a', 'vt4m', 'vt3m'],
	'mimAna':['prmp'],
	'mIyamARa':['prpp'],
	'mAsyamAna':['fmp', 'fpp'],
	'mamARa':['pfpp'],
	'mita':['ppp'],
	'mitavat':['pap'],
	'mAtum':['inf'],
	'mitvA':['abs'],
	'meya':['ger'],
	'mAtavya':['ger'],
	'manIya':['ger'],
	'mAtf':['f'],
	'mAs':['m'],
	'mAsa':['m'],
	'muc':['vt6am'],
	'muYcat':['prap'],
	'muYcamAna':['prmp'],
	'mucyamAna':['prpp'],
	'mokzyat':['fap'],
	'mokzyamARa':['fmp', 'fpp', 'prpp'],
	'mumucvas':['pfap'],
	'mumucAna':['pfmp', 'pfpp'],
	'mokzayAm':['ppf_actn'],
	'mukta':['ppp'],
	'muktavat':['pap'],
	'moktum':['inf'],
	'muktvA':['abs'],
	'mocya':['ger'],
	'moktavya':['ger'],
	'mocanIya':['ger'],
	'mud':['f', 'vi1m'],
	'mf':['vi6am'],
	'mriyamARa':['prmp', 'prpp'],
	'marizyat':['fap'],
	'marizyamARa':['fpp'],
	'mamfvas':['pfap'],
	'mamrARa':['pfpp'],
	'mfta':['ppp'],
	'mftavat':['pap'],
	'martum':['inf'],
	'mftvA':['abs'],
	'mArya':['ger'],
	'martavya':['ger'],
	'maraRIya':['ger'],
	'mfz':['vt4am'],
	'mfzyat':['prap'],
	'mfzyamARa':['prmp', 'prpp'],
	'marzizyat':['fap'],
	'marzizyamARa':['fmp', 'fpp'],
	'mamfzivas':['pfap'],
	'mamfzAna':['pfmp', 'pfpp'],
	'marzita':['ppp'],
	'mfzita':['ppp'],
	'marzitavat':['pap'],
	'mfzitavat':['pap'],
	'marzitum':['inf'],
	'marzitvA':['abs'],
	'mfzitvA':['abs'],
	'mfzya':['ger'],
	'marzitavya':['ger'],
	'marzaRIya':['ger'],
	'mokz':['vt10a'],
	'mokzayat':['prap'],
	'mokzizyat':['fap'],
	'mokzizyamARa':['fmp', 'fpp'],
	'mokzita':['ppp'],
	'mokzitavat':['pap'],
	'mokzitum':['inf'],
	'mokzitvA':['abs'],
	'mokzya':['ger'],
	'mokzitavya':['ger'],
	'mokzaRIya':['ger'],
	'mokzaRa':['n'],
	'yatas':['rel_adv'],
	'yaTA':['rel_adv'],
	'yadA':['rel_adv'],
	'yaSas':['n'],
	'yAc':['vt1am'],
	'yAcat':['prap'],
	'yAcamAna':['prmp'],
	'yAcyamAna':['prpp'],
	'yAcizyat':['fap'],
	'yAcizyamARa':['fmp', 'fpp'],
	'yayAcivas':['pfap'],
	'yayAcAna':['pfmp', 'pfpp'],
	'yAcita':['ppp'],
	'yAcitavat':['pap'],
	'yAcitum':['inf'],
	'yAcitvA':['abs'],
	'yAcya':['ger'],
	'yAcitavya':['ger'],
	'yAcanIya':['ger'],
	'yuj':['vt7am'],
	'yuYjat':['prap'],
	'yuYjAna':['prmp'],
	'yujyamAna':['prpp'],
	'yokzyat':['fap'],
	'yokzyamARa':['fmp', 'fpp'],
	'yuyujvas':['pfap'],
	'yuyujAna':['pfmp', 'pfpp'],
	'yukta':['ppp'],
	'yuktavat':['pap'],
	'yoktum':['inf'],
	'yuktvA':['abs'],
	'yogya':['ger'],
	'yoktavya':['ger'],
	'yojanIya':['ger'],
	'yudDa':['n', 'ppp'],
	'yuD':['f', 'vt4m'],
	'yuDyamAna':['prmp', 'prpp'],
	'yotsyamAna':['fmp', 'fpp'],
	'yuyuDAna':['pfmp', 'pfpp'],
	'yudDavat':['pap'],
	'yodDum':['inf'],
	'yudDvA':['abs'],
	'yoDya':['ger'],
	'yodDavya':['ger'],
	'yoDanIya':['ger'],
	'yuDizWira':['m_pn'],
	'yuzmad':['pers_pron'],
	'yuzmadvAsa':['m'],
	'yoga':['m'],
	'rakz':['vt1a'],
	'rakzat':['prap'],
	'rakzamARa':['prmp'],
	'rakzyamARa':['prpp'],
	'rakzizyat':['fap'],
	'rakzizyamARa':['fmp', 'fpp'],
	'rarakzivas':['pfap'],
	'rarakzARa':['pfmp', 'pfpp'],
	'rakzita':['ppp'],
	'rakzitavat':['pap'],
	'rakzitum':['inf'],
	'rakzitvA':['abs'],
	'rakzya':['ger'],
	'rakzitavya':['ger'],
	'rakzanIya':['ger'],
	'rakzas':['n'],
	'ratna':['n'],
	'raTa':['m'],
	'raB':['vt1m'],
	'raBamARa':['prmp'],
	'raByamARa':['prpp'],
	'rapsyamARa':['fmp', 'fpp'],
	'reBivas':['pfmp', 'pfpp'],
	'rabDa':['ppp'],
	'rabDavat':['pap'],
	'rabDum':['inf'],
	'rabDvA':['abs'],
	'raBya':['ger'],
	'rabDavya':['ger'],
	'ramBaRIya':['ger'],
	'rAkzasa':['m'],
	'rAj':['vt1am'],
	'rAjat':['prap'],
	'rajamAna':['prmp'],
	'rAjyamAna':['prpp'],
	'rAjizyat':['fap'],
	'rAjizyamARa':['fmp', 'fpp'],
	'rarAjivas':['pfap'],
	'rarAjAna':['pfmp', 'pfpp'],
	'rAjita':['ppp'],
	'rAjitavat':['pap'],
	'rAjitum':['inf'],
	'rAjitvA':['abs'],
	'rAjya':['ger', 'n'],
	'rAjitavya':['ger'],
	'rAjanIya':['ger'],
	'rAjan':['m'],
	'rAjIva':['n'],
	'rAjIvalocana':['adj'],
	'rAjyavinASa':['m'],
	'rAjyArTa':['m'],
	'rAzwra':['n'],
	'rUpa':['n'],
	'rUpavIryOjaHsaMpanna':['adj'],
	'rUpavIryOjas':['n'],
	'lakzaRa':['n'],
	'lakzmI':['f', 'f_pn'],
	'laB':['vt1m'],
	'laBamAna':['prmp'],
	'laByamAna':['prpp'],
	'lapsyamAna':['fmp', 'fpp'],
	'leBAna':['pfmp', 'pfpp'],
	'labDa':['ppp'],
	'labDavat':['pap'],
	'labDum':['inf'],
	'labDvA':['abs'],
	'laBya':['ger'],
	'labDavya':['ger'],
	'laBanIya':['ger'],
	'luB':['vi4a'],
	'luByat':['prap'],
	'luByamAna':['prpp'],
	'loBizyat':['fap'],
	'loBizyamARa':['fpp'],
	'luluBAna':['pfpp'],
	'lubDa':['ppp'],
	'lubDavat':['pap'],
	'lobDum':['inf'],
	'loBitum':['inf'],
	'lubDvA':['abs'],
	'luBitvA':['abs'],
	'loBitvA':['abs'],
	'luBya':['ger'],
	'lobDavya':['ger'],
	'loBitavya':['ger'],
	'loBanIya':['ger'],
	'loka':['m'],
	'locana':['n'],
	'loBa':['m'],
	'vac':['vt2a'],
	'ucyamAna':['prpp'],
	'vakzyat':['fap'],
	'vakzyamARa':['fpp', 'fmp'],
	'Ucivas':['pfap'],
	'UcAna':['pfpp'],
	'ukta':['ppp'],
	'uktavat':['pap'],
	'vaktum':['inf'],
	'uktvA':['abs'],
	'vAkya':['ger'],
	'vaktavya':['ger'],
	'vacanIya':['ger'],
	'vacana':['n'],
	'vaYc':['vt10m'],
	'vaYcayamAna':['prmp'],
	'vaYcyamAna':['prpp'],
	'vaYcayizyamARa':['fmp', 'fpp'],
	'vaYcayAm':['ppf_actn'],
	'vakta':['ppp'],
	'vaktavat':['pap'],
	'vaYcayitum':['inf'],
	'vaktvA':['abs'],
	'vacitvA':['abs'],
	'vaYcitvA':['abs'],
	'vaYcayitvA':['abs'],
	'vaYcya':['ger'],
	'vaYcayitavya':['ger'],
	'vaYcanIya':['ger'],
	'vad':['vt1a'],
	'vadat':['prap'],
	'udyamAna':['prpp'],
	'vadizyat':['fap'],
	'vadizyamARa':['fpp'],
	'Udivas':['pfap'],
	'UdAna':['pfpp'],
	'udita':['ppp'],
	'uditavat':['pap'],
	'vaditum':['inf'],
	'uditvA':['abs'],
	'vAdya':['ger'],
	'vaditavya':['ger'],
	'vadanIya':['ger'],
	'vaD':['vta'],
	'vaDa':['m'],
	'vana':['n'],
	'vanavAsa':['m'],
	'vara':['adj', 'm'],
	'varza':['m', 'n'],
	'vaS':['vt2a'],
	'uSat':['prap'],
	'uSyamAna':['prpp'],
	'vaSizyat':['fap'],
	'vaSizyamARa':['fpp'],
	'uSita':['ppp'],
	'uSitavat':['pap'],
	'vaSitum':['inf'],
	'vaSitvA':['abs'],
	'vaSya':['ger'],
	'vaSitavya':['ger'],
	'vaSanIya':['ger'],
	'vaSa':['m'],
	'vas':['vi1a'],
	'vasat':['prap'],
	'uzyamARa':['prpp'],
	'vatsyat':['fap'],
	'vatsyamAna':['fpp'],
	'Uzivas':['pfap'],
	'UzARa':['pfpp'],
	'vAsayAm':['ppf_actn'],
	'uzita':['ppp'],
	'uzitavat':['pap'],
	'vastum':['inf'],
	'uzitvA':['abs'],
	'vAsya':['ger'],
	'vasitavya':['ger'],
	'vasanIya':['ger'],
	'vasu':['adj', 'n'],
	'vasuMDara':['adj'],
	'vasuMDarA':['f'],
	'vasudeva':['m'],
	'vah':['vt1am'],
	'vahat':['prap'],
	'vahamAna':['prmp'],
	'uhyamAna':['prpp'],
	'vahizyat':['fap'],
	'vahizyamARa':['fmp', 'fpp'],
	'Uhivas':['pfap'],
	'UhAna':['pfmp', 'pfpp'],
	'UQa':['ppp'],
	'UQavat':['pap'],
	'voQum':['inf'],
	'UQvA':['abs'],
	'vAhya':['ger'],
	'voQavya':['ger'],
	'vAhanIya':['ger'],
	'vAsa':['m'],
	'vAsudeva':['m'],
	'vAhana':['adj', 'n'],
	'vi':['preverb'],
	'vi_ava_so':['vt4a'],
	'vyavasyat':['prap'],
	'vyavasyamAna':['prmp'],
	'vyavasIyamAna':['prpp'],
	'vyavasAsyat':['fap'],
	'vyavasAsyamAna':['fmp', 'fpp'],
	'vyavasasivas':['pfap'],
	'vyavasasAna':['pfmp', 'pfpp'],
	'vyavasita':['ppp'],
	'vyavasitavat':['pap'],
	'vyavasAtum':['inf'],
	'vyavasAya':['abs', 'm'],
	'vyavaseya':['ger'],
	'vyavasAtavya':['ger'],
	'vyavasAnIya':['ger'],
	'vi_kram':['vt1a'],
	'vikrama':['m'],
	'vi_grah':['vt9a'],
	'vigraha':['m'],
	'vi_ji':['vt1m'],
	'vijayamAna':['prmp'],
	'vijIyamAna':['prpp'],
	'vijezyamARa':['fmp', 'fpp'],
	'vijigyAna':['pfmp', 'pfpp'],
	'vijita':['ppp'],
	'vijitavat':['pap'],
	'vijetum':['inf'],
	'vijitya':['abs'],
	'vijeya':['ger'],
	'vijetavya':['ger'],
	'vijayanIya':['ger'],
	'vid':['vt2a', 'vt6am'],
	'vidvas':['prap'],
	'vidyamAna':['prpp'],
	'vedizyat':['fap'],
	'vedizyamARa':['fpp', 'fmp'],
	'vidAm':['ppf_actn'],
	'vividvas':['pfap'],
	'vividAna':['pfpp', 'pfmp'],
	'vidita':['ppp'],
	'viditavat':['pap'],
	'veditum':['inf'],
	'viditvA':['abs'],
	'vedya':['ger'],
	'veditavya':['ger'],
	'vedanIya':['ger'],
	'vindat':['prap'],
	'vindamAna':['prmp'],
	'vividivas':['pfap'],
	'vitta':['ppp'],
	'vittavat':['pap'],
	'vettum':['inf'],
	'vittvA':['abs'],
	'veditvA':['abs'],
	'vettavya':['ger'],
	'vidura':['adj', 'm_pn'],
	'vi_dru':['vt1a'],
	'vidravat':['prap'],
	'vidrUyamARa':['prpp'],
	'vidrozyat':['fap'],
	'vidrozyamARa':['fpp'],
	'vidudruvas':['pfap'],
	'vidudruvARa':['pfpp'],
	'vidruta':['ppp'],
	'vidrutavat':['pap'],
	'vidrotum':['inf'],
	'vidrutya':['abs'],
	'vidravya':['ger'],
	'vidrotavya':['ger'],
	'vidravaRIya':['ger'],
	'vidrutaBUyizWa':['adj'],
	'vi_DA':['vt3am'],
	'vidaDat':['prap'],
	'vidaDAna':['prmp', 'pfmp', 'pfpp'],
	'viDIyamAna':['prpp'],
	'viDAsyat':['fap'],
	'viDAsyamAna':['fmp', 'fpp'],
	'vidaDivas':['pfap'],
	'vihita':['ppp'],
	'vihitavat':['pap'],
	'viDAtum':['inf'],
	'viDAya':['abs'],
	'viDeya':['ger'],
	'viDAtavya':['ger'],
	'viDAnIya':['ger'],
	'vi_naS':['vi4a'],
	'vinaSyat':['prap'],
	'vineSivas':['pfap'],
	'vineSvas':['pfap'],
	'vinazwa':['ppp'],
	'vinazwavat':['ppp'],
	'vinazwum':['inf'],
	'vinaSya':['abs'],
	'vinASya':['ger'],
	'vinazwavya':['ger'],
	'vinaSanIya':['ger'],
	'vinASa':['m'],
	'vi_ni_han':['vt2a'],
	'viniGnat':['prap'],
	'vinihanyamAna':['prpp'],
	'vinihaMsyat':['fap'],
	'vinihanizyat':['fap'],
	'vinihaMsyamAna':['fpp'],
	'vinihanizyamARa':['fpp'],
	'vinijaGnivas':['pfap'],
	'vinijaGanvas':['pfap'],
	'vinijaGnAna':['pfpp'],
	'vinihata':['ppp'],
	'vinihatavat':['pap'],
	'vinihantum':['inf'],
	'vinihanya':['abs'],
	'viniGAtya':['ger'],
	'vinihantavya':['ger'],
	'vinihananIya':['ger'],
	'viprakAra':['m'],
	'viBakta':['adj', 'ppp'],
	'vi_Baj':['vt1am'],
	'viBajat':['prap'],
	'viBajamAna':['prmp'],
	'viBajyamAna':['prpp'],
	'viBakzyat':['fap'],
	'viBakzyamAna':['fmp', 'fpp'],
	'viBejivas':['pfap'],
	'viBejAna':['pfmp', 'pfpp'],
	'viBaktavat':['pap'],
	'viBaktum':['inf'],
	'viBajya':['ger'],
	'viBaktavya':['ger'],
	'viBajanIya':['ger'],
	'vi_rAj':['vt1am'],
	'virAjat':['prap'],
	'virajamAna':['prmp'],
	'virAjyamAna':['prpp'],
	'virAjizyat':['fap'],
	'virAjizyamARa':['fmp', 'fpp'],
	'virarAjivas':['pfap'],
	'virarAjAna':['pfmp', 'pfpp'],
	'virAjita':['ppp'],
	'virAjitavat':['pap'],
	'virAjitum':['inf'],
	'virAjya':['abs', 'ger'],
	'virAjitavya':['ger'],
	'virAjanIya':['ger'],
	'viviDa':['adj'],
	'viviDopAya':['m'],
	'vi_vf':['vt5am'],
	'vivfRvat':['prap'],
	'vivfRvAna':['prmp'],
	'vivriyamARa':['prpp'],
	'vivarizyat':['fap'],
	'vivarIzyat':['fap'],
	'vivarizyamARa':['fmp', 'fpp'],
	'vivarIzyamARa':['fmp', 'fpp'],
	'vivavfvas':['pfap'],
	'vivavrARa':['pfmp', 'pfpp'],
	'vivfta':['ppp'],
	'vivftavat':['pap'],
	'vivaritum':['inf'],
	'vivarItum':['inf'],
	'vivftvA':['abs'],
	'vi-vftya':['abs'],
	'vivftya':['ger'],
	'vivaritavya':['ger'],
	'vivarItavya':['ger'],
	'vivaraRIya':['ger'],
	'viS':['m'],
	'viSaMpa':['adj'],
	'vi_SramB':['vi1m'],
	'viSramBamARa':['prmp'],
	'viSraByamARa':['prpp'],
	'viSramBizyamARa':['fmp', 'fpp'],
	'viSaSramBARa':['pfmp', 'pfpp'],
	'viSrabDa':['ppp'],
	'viSrabDavat':['pap'],
	'viSramBitum':['inf'],
	'viSraBya':['abs'],
	'viSramBya':['ger'],
	'viSramBitavya':['ger'],
	'viSramBaRIya':['ger'],
	'vi_Sru':['vt1am'],
	'viSfRvat':['prap'],
	'viSrUyamARa':['prpp'],
	'viSruta':['ppp'],
	'viSrutavat':['pap'],
	'viSrotum':['inf'],
	'viSrutya':['abs'],
	'viSravya':['ger'],
	'viSrotavya':['ger'],
	'viSravaRIya':['ger'],
	'viza':['adj', 'n'],
	'vizRu':['m_pn'],
	'vi_sramB':['vi1m'],
	'visramBamARa':['prmp'],
	'visraByamARa':['prpp'],
	'visramBizyamARa':['fmp', 'fpp'],
	'visasramBARa':['pfmp', 'pfpp'],
	'visrabDa':['ppp'],
	'visrabDavat':['pap'],
	'visramBitum':['inf'],
	'visraBya':['abs'],
	'visramBya':['ger'],
	'visramBitavya':['ger'],
	'visramBaRIya':['ger'],
	'vIra':['adj', 'm'],
	'vIrya':['n'],
	'vf':['vt5am', 'vt10am', 'vt9m'],
	'vfRvat':['prap'],
	'vfRvAna':['prmp'],
	'vriyamARa':['prpp'],
	'varizyat':['fap'],
	'varIzyat':['fap'],
	'varizyamARa':['fmp', 'fpp'],
	'varIzyamARa':['fmp', 'fpp'],
	'vavfvas':['pfap'],
	'vavrARa':['pfmp', 'pfpp'],
	'vfta':['ppp'],
	'vftavat':['pap'],
	'varitum':['inf'],
	'varItum':['inf'],
	'vftvA':['abs'],
	'vftya':['ger'],
	'varitavya':['ger'],
	'varItavya':['ger'],
	'varaRIya':['ger'],
	'vArayat':['prap'],
	'vArayamARa':['prmp'],
	'vAryamARa':['prpp'],
	'vArayizyat':['fap'],
	'vArayizyamARa':['fmp', 'fpp'],
	'vArayAm':['ppf_actn'],
	'vArita':['ppp'],
	'vAritavat':['pap'],
	'vArayitum':['inf'],
	'vArayitvA':['abs'],
	'vArya':['ger'],
	'vArayitavya':['ger'],
	'vAraRIya':['ger'],
	'vfRAna':['prmp'],
	'vfka':['m'],
	'vfkodara':['adj'],
	'vft':['vi1m'],
	'vartamAna':['prmp'],
	'vartizyamARa':['fmp', 'fpp'],
	'vavftAna':['pfmp', 'pfpp'],
	'vftta':['ppp', 'n'],
	'vfttavat':['pap'],
	'vartitum':['inf'],
	'vartitvA':['abs'],
	'vartitavya':['ger'],
	'vartanIya':['ger'],
	'vfz':['vi1a'],
	'varzat':['prap'],
	'vfzyamARa':['prpp'],
	'varzizyat':['fap'],
	'varzyamARa':['fpp'],
	'vavfzivas':['pfap'],
	'vavfzAna':['pfpp'],
	'vfzwa':['ppp'],
	'vfzwavat':['pap'],
	'varzitum':['inf'],
	'vfzwvA':['abs'],
	'varzitvA':['abs'],
	'vfzya':['ger'],
	'varzya':['ger'],
	'varzitavya':['ger'],
	'varzaRIya':['ger'],
	'vfzaduHSAsana':['m'],
	'vfzaduHSAsanAdi':['adj'],
	'vfzan':['m'],
	'veda':['m'],
	'vE':['pcl'],
	'vESaMpAyana':['m_pn'],
	'vyaT':['vi1m'],
	'vyaTamAna':['prmp'],
	'vyaTyamAna':['prpp'],
	'vyaTizyamARa':['fmp', 'fpp'],
	'vivyaTivas':['pfap'],
	'vivyaTAna':['pfpp'],
	'vyaTita':['ppp'],
	'vyaTitavat':['pap'],
	'vyaTitum':['inf'],
	'vyaTitvA':['abs'],
	'vyaTya':['ger'],
	'vyaTitavya':['ger'],
	'vyaTanIya':['ger'],
	'vyaTA':['f'],
	'vyavasAyasahAya':['adj'],
	'vyAsa':['m_pn'],
	'vraj':['vt1a'],
	'vrajat':['prap'],
	'vrajyamAna':['prpp'],
	'vrajizyat':['fap'],
	'vrajizyamARa':['fpp'],
	'vavrajivas':['pfap'],
	'vavrajAna':['pfmp', 'pfpp'],
	'vrajita':['ppp'],
	'vrajitavat':['pap'],
	'vrajitum':['inf'],
	'vrajitvA':['abs'],
	'vrAjya':['ger'],
	'vrajitavya':['ger'],
	'vrajanIya':['ger'],
	'vrata':['n'],
	'SaMtanu':['m'],
	'Sak':['vi5a'],
	'Saknuvat':['prap'],
	'SakyamAna':['prpp'],
	'Sakta':['ppp'],
	'Saktavat':['pap'],
	'Saktum':['inf'],
	'SaktvA':['abs'],
	'Sakya':['ger'],
	'Saktavya':['ger'],
	'SakanIya':['ger'],
	'Sakuni':['m_pn'],
	'Sakra':['adj', 'm'],
	'SacI':['f', 'f_pn'],
	'Satru':['m'],
	'SatruvaDa':['m'],
	'Satruhan':['m'],
	'Sam':['n', 'pcl'],
	'Sastra':['n'],
	'SastrapratApa':['m'],
	'SAMtanava':['m'],
	'SAlA':['f'],
	'SAs':['vt2a'],
	'SAsat':['prap'],
	'SizyamARa':['prpp'],
	'SAsizyat':['fap'],
	'SAsizyamARa':['fpp'],
	'SaSAsvas':['pfap'],
	'SaSAsAna':['pfpp'],
	'Sizwa':['ppp'],
	'Sizwavat':['pap'],
	'SAsitum':['inf'],
	'SizwvA':['abs'],
	'SAsitvA':['abs'],
	'Sizya':['ger'],
	'SAsitavya':['ger'],
	'SAsanIya':['ger'],
	'SramB':['vi1m'],
	'SramBamARa':['prmp'],
	'SraByamARa':['prpp'],
	'SramBizyamARa':['fmp', 'fpp'],
	'SaSramBARa':['pfmp', 'pfpp'],
	'SrabDa':['ppp'],
	'SrabDavat':['pap'],
	'SramBitum':['inf'],
	'SrabDvA':['abs'],
	'SramBitvA':['abs'],
	'SramBya':['ger'],
	'SramBitavya':['ger'],
	'SramBaRIya':['ger'],
	'SrI':['f'],
	'SrImat':['adj'],
	'SrImanmahABArata':['n'],
	'SrIyaSas':['n'],
	'SrIyaSoBft':['adj'],
	'Sru':['vt1am'],
	'SfRvat':['prap'],
	'SrUyamARa':['prpp'],
	'Sruta':['ppp'],
	'Srutavat':['pap'],
	'Srotum':['inf'],
	'SrutvA':['abs'],
	'Sravya':['ger'],
	'Srotavya':['ger'],
	'SravaRIya':['ger'],
	'zawsUrya':['adj'],
	'zaz':['card_num'],
	'saMkASa':['m'],
	'saMpanna':['adj', 'ppp'],
	'saMvatsara':['m'],
	'saMvatsaragaRa':['m'],
	'sac':['vt1am'],
	'saciva':['m'],
	'sattama':['adj'],
	'satya':['adj', 'n'],
	'satyavikrama':['adj'],
	'satyavrata':['n'],
	'satyavrataparAyaRa':['adj'],
	'sad':['vi1a', 'vi6a'],
	'sIdat':['prap'],
	'sadyamAna':['prpp'],
	'satsyat':['fap'],
	'satsyamAna':['fpp'],
	'sedivas':['pfap'],
	'sedAna':['pfpp'],
	'sanna':['ppp'],
	'sannavat':['pap'],
	'sattum':['inf'],
	'sattvA':['abs'],
	'sAdya':['ger'],
	'sattavya':['ger'],
	'sadanIya':['ger'],
	'saptan':['card_num'],
	'saBA':['f'],
	'sam':['preverb'],
	'sam_A_car':['vt1am'],
	'samAcarat':['prap'],
	'samAcaramARa':['prmp'],
	'samAcaryamARa':['prpp'],
	'samAcarizyat':['fap'],
	'samAcarizyamARa':['fmp', 'fpp'],
	'samAcerivas':['pfap'],
	'samAcerARa':['pfmp', 'pfpp'],
	'samAcarita':['ppp'],
	'samAcaritavat':['pap'],
	'samAcaritum':['inf'],
	'samAcarya':['abs', 'ger'],
	'samAcaritavya':['ger'],
	'samAcaraRIya':['ger'],
	'sam_A_ci':['vt5am'],
	'samAcinvat':['prap'],
	'samAcinvAna':['prmp'],
	'samAcIyamARa':['prpp'],
	'samAcezyat':['fap'],
	'samAcezyamARa':['fmp', 'fpp'],
	'samAcikivas':['pfap'],
	'samAcicivas':['pfap'],
	'samAciyAna':['pfmp', 'pfpp'],
	'samAcita':['ppp'],
	'samAcitavat':['pap'],
	'samAcetum':['inf'],
	'samAcitya':['abs'],
	'samAceya':['ger'],
	'samAcetavya':['ger'],
	'samAcayanIya':['ger'],
	'sam_A_DA':['vt3am'],
	'samAdaDat':['prap'],
	'samAdaDAna':['prmp', 'pfmp', 'pfpp'],
	'samADIyamAna':['prpp'],
	'samADAsyat':['fap'],
	'samADAsyamAna':['fmp', 'fpp'],
	'samAdaDivas':['pfap'],
	'samAhita':['ppp'],
	'samAhitavat':['pap'],
	'samADAtum':['inf'],
	'samADAya':['abs'],
	'samADeya':['ger'],
	'samADAtavya':['ger'],
	'samADAnIya':['ger'],
	'samADi':['m'],
	'sam_Ap':['vt5a'],
	'samApnuvat':['prap'],
	'samApyamAna':['prpp'],
	'samApsyat':['fap'],
	'samApsyamAna':['fpp'],
	'samApivas':['pfap'],
	'samApAna':['pfpp'],
	'samApta':['ppp'],
	'samAptavat':['pap'],
	'samAptum':['inf'],
	'samApya':['abs', 'ger'],
	'samAptavya':['ger'],
	'samApanIya':['ger'],
	'sam_ud_sah':['vt1m'],
	'sam_kAS':['vt1m', 'vt4m'],
	'sam_gam':['vi1m'],
	'saMgacCamAna':['prmp'],
	'saMgamyamAna':['prpp'],
	'saMgamizyamARa':['fmp', 'fpp'],
	'saMjagmAna':['pfmp', 'pfpp'],
	'saMgata':['ppp'],
	'saMgatavat':['pap'],
	'saMgantum':['inf'],
	'saMgamya':['abs', 'ger'],
	'saMgatya':['abs'],
	'saMgantavya':['ger'],
	'saMgamanIya':['ger'],
	'sam_Cid':['vt7am'],
	'saMCindat':['prap'],
	'saMCindAna':['prmp'],
	'saMCidyamAna':['prpp'],
	'saMCetsyat':['fap'],
	'saMCetsyamAna':['fmp', 'fpp'],
	'saMcicCidvas':['pfap'],
	'saMcicCidAna':['pfmp', 'pfpp'],
	'saMCinna':['ppp'],
	'saMCinnavat':['pap'],
	'saMCettum':['inf'],
	'saMCidya':['abs'],
	'saMCedya':['ger'],
	'saMCettavya':['ger'],
	'saMCedanIya':['ger'],
	'sam_DA':['vt3am'],
	'saMdaDat':['prap'],
	'saMdaDAna':['prmp', 'pfmp', 'pfpp'],
	'saMDIyamAna':['prpp'],
	'saMDAsyat':['fap'],
	'saMDAsyamAna':['fmp', 'fpp'],
	'saMdaDivas':['pfap'],
	'saMhita':['ppp'],
	'saMhitavat':['pap'],
	'saMDAtum':['inf'],
	'saMDAya':['abs'],
	'saMDeya':['ger'],
	'saMDAtavya':['ger'],
	'saMDAnIya':['ger'],
	'sam_pad':['vt4m'],
	'saMpadyamAna':['prmp', 'prpp'],
	'saMpatsyamAna':['fmp', 'fpp'],
	'saMpedAna':['pfmp', 'pfpp'],
	'saMpannavat':['pap'],
	'saMpattum':['inf'],
	'saMpadya':['abs'],
	'saMpAdya':['ger'],
	'saMpattavya':['ger'],
	'saMpadanIya':['ger'],
	'sam_pUj':['vt10m'],
	'saMpUjayamAna':['prmp'],
	'saMpUjyamAna':['prpp'],
	'saMpUjayizyamARa':['fmp', 'fpp'],
	'saMpUjayAm':['ppf_actn'],
	'saMpUjita':['ppp'],
	'saMpUjitavat':['pap'],
	'saMpUjayitum':['inf'],
	'saMpUjya':['abs', 'ger'],
	'saMpUjitavya':['ger'],
	'saMpUjanIya':['ger'],
	'sam_pra_vac':['vt2a'],
	'saMprocyamAna':['prpp'],
	'saMpravakzyat':['fap'],
	'saMpravakzyamARa':['fpp'],
	'saMprocivas':['pfap'],
	'saMprocAna':['pfpp'],
	'saMprokta':['ppp'],
	'saMproktavat':['pap'],
	'saMpravaktum':['inf'],
	'saMproktvA':['abs'],
	'saMprocya':['abs'],
	'saMpravAkya':['ger'],
	'saMpravaktavya':['ger'],
	'saMpravacanIya':['ger'],
	'sam_BU':['vi1a'],
	'saMBavat':['prap'],
	'saMBUyamAna':['prpp'],
	'saMBavizyat':['fap'],
	'saMBavizyamARa':['fpp'],
	'saMbaBUvas':['pfap'],
	'saMBUta':['ppp'],
	'saMBUtavat':['pap'],
	'saMBavitum':['inf'],
	'saMBUya':['abs'],
	'saMBavya':['ger'],
	'saMBavitavya':['ger'],
	'saMBavanIya':['ger'],
	'sam_man':['vt4m', 'vt8m'],
	'saMmanyamAna':['prmp', 'prpp'],
	'saMmanvAna':['prmp'],
	'saMmaMsyamAna':['fmp', 'fpp'],
	'saMmenAna':['pfmp', 'pfpp'],
	'saMmata':['ppp'],
	'saMmatavat':['pap'],
	'saMmantum':['inf'],
	'saMmanya':['abs'],
	'saMmAnya':['ger'],
	'saMmantavya':['ger'],
	'saMmananIya':['ger'],
	'sam_mantr':['vt10m'],
	'saMmantrayamAna':['prmp'],
	'saMmantryamAna':['prpp'],
	'saMmantrayizyamARa':['fmp', 'fpp'],
	'saMmantrayAm':['ppf_actn'],
	'saMmantrita':['ppp'],
	'saMmantritavat':['pap'],
	'saMmantrayitum':['inf'],
	'saMmantrya':['abs', 'ger'],
	'saMmantrayitavya':['ger'],
	'saMmantraRIya':['ger'],
	'sam_vf':['vt5am'],
	'saMvfRvat':['prap'],
	'saMvfRvAna':['prmp'],
	'saMvriyamARa':['prpp'],
	'saMvarizyat':['fap'],
	'saMvarIzyat':['fap'],
	'saMvarizyamARa':['fmp', 'fpp'],
	'saMvarIzyamARa':['fmp', 'fpp'],
	'saMvavfvas':['pfap'],
	'saMvavrARa':['pfmp', 'pfpp'],
	'saMvfta':['ppp'],
	'saMvftavat':['pap'],
	'saMvaritum':['inf'],
	'saMvarItum':['inf'],
	'saMvftya':['abs', 'ger'],
	'saMvaritavya':['ger'],
	'saMvarItavya':['ger'],
	'saMvaraRIya':['ger'],
	'sam_svap':['vi2a'],
	'saMsvapat':['prap'],
	'saMsupyamARa':['prpp'],
	'saMsvapsyat':['fap'],
	'saMsvapsyamARa':['fpp'],
	'saMsuzupivas':['pfap'],
	'saMsuzupAna':['pfpp'],
	'saMsupta':['ppp'],
	'saMsuptavat':['pap'],
	'saMsvaptum':['inf'],
	'saMsupya':['abs'],
	'saMsvapya':['ger'],
	'saMsvaptavya':['ger'],
	'saMsvapanIya':['ger'],
	'sarpa':['m'],
	'sarva':['pron_adj', 'pron'],
	'sarvaratna':['n'],
	'sarvaratnasamAcita':['adj'],
	'sarvaloka':['m'],
	'sarvaSas':['adv'],
	'sah':['vt1m'],
	'sahamAna':['prmp'],
	'sahyamAna':['prpp'],
	'sahizyamARa':['fmp', 'fpp'],
	'sehAna':['pfmp'],
	'soQa':['ppp'],
	'soQavat':['pap'],
	'soQum':['inf'],
	'sahitum':['inf'],
	'soQvA':['abs'],
	'sahitvA':['abs'],
	'sahya':['ger'],
	'soQavya':['ger'],
	'sahitavya':['ger'],
	'sahanIya':['ger'],
	'saha':['pcl'],
	'sahadeva':['m_pn'],
	'sahasObala':['adj'],
	'sahAya':['m'],
	'sahita':['adj'],
	'su':['preverb'],
	'suKa':['adj', 'n'],
	'suKAvaha':['adj'],
	'sudurmati':['adj'],
	'subala':['m_pn', 'adj'],
	'suBadra':['adj'],
	'suBadrA':['f_pn'],
	'suviBakta':['adj'],
	'suviBaktamahApaTa':['adj'],
	'suhfjjana':['m'],
	'suhfd':['m', 'adj'],
	'sUrya':['m'],
	'sUryasaMkASa':['adj'],
	'senA':['f'],
	'so':['vt4a'],
	'syat':['prap'],
	'syamAna':['prmp'],
	'sIyamAna':['prpp'],
	'sAsyat':['fap'],
	'sAsyamAna':['fmp', 'fpp'],
	'sasivas':['pfap'],
	'sasAna':['pfmp', 'pfpp'],
	'sita':['ppp'],
	'sitavat':['pap'],
	'sAtum':['inf'],
	'sitvA':['abs'],
	'seya':['ger'],
	'sAtavya':['ger'],
	'sAnIya':['ger'],
	'sObala':['m'],
	'sTa':['adj'],
	'sTA':['vi1am'],
	'tizWat':['prap'],
	'tizWamAna':['prmp'],
	'sTIyamAna':['prpp'],
	'sTAsyat':['fap'],
	'sTAsyamAna':['fmp', 'fpp'],
	'tasTivas':['pfap'],
	'tasTAna':['pfmp', 'pfpp'],
	'sTita':['ppp'],
	'sTitavat':['pap'],
	'sTAtum':['inf'],
	'sTitvA':['abs'],
	'sTeya':['ger'],
	'sTAtavya':['ger'],
	'sTAnIya':['ger'],
	'sTApayitum':['inf'],
	'sTira':['adj'],
	'spanda':['m'],
	'sramB':['vi1m'],
	'sramBamARa':['prmp'],
	'sraByamARa':['prpp'],
	'sramBizyamARa':['fmp', 'fpp'],
	'sasramBARa':['pfmp', 'pfpp'],
	'srabDa':['ppp'],
	'srabDavat':['pap'],
	'sramBitum':['inf'],
	'srabDvA':['abs'],
	'sramBitvA':['abs'],
	'sramBya':['ger'],
	'sramBitavya':['ger'],
	'sramBaRIya':['ger'],
	'sva':['pron', 'pron_adj'],
	'svaka':['adj'],
	'svap':['vi2a'],
	'svapat':['prap'],
	'supyamARa':['prpp'],
	'svapsyat':['fap'],
	'svapsyamARa':['fpp'],
	'suzupivas':['pfap'],
	'suzupAna':['pfpp'],
	'supta':['ppp'],
	'suptavat':['pap'],
	'svaptum':['inf'],
	'suptvA':['abs'],
	'svapya':['ger'],
	'svaptavya':['ger'],
	'svapanIya':['ger'],
	'svamandira':['n'],
	'svar':['pcl'],
	'svarga':['m'],
	'svargasTa':['adj'],
	'ha':['pcl', 'adj'],
	'han':['vt2a', 'adj'],
	'Gnat':['prap'],
	'hanyamAna':['prpp'],
	'haMsyat':['fap'],
	'hanizyat':['fap'],
	'haMsyamAna':['fpp'],
	'hanizyamARa':['fpp'],
	'jaGnivas':['pfap'],
	'jaGanvas':['pfap'],
	'jaGnAna':['pfpp'],
	'hata':['ppp'],
	'hatavat':['pap'],
	'hantum':['inf'],
	'hatvA':['abs'],
	'GAtya':['ger'],
	'hantavya':['ger'],
	'hananIya':['ger'],
	'havya':['n', 'ger'],
	'havyavAhana':['m'],
	'hasta':['m'],
	'hastin':['adj', 'm', 'm_pn'],
	'hAstina':['adj'],
	'hAstinapura':['n_pn'],
	'hi':['sub_pcl'],
	'hiqimba':['m_pn'],
	'hu':['vt3a'],
	'juhvat':['prap'],
	'hUyamAna':['prpp'],
	'hozyat':['fap'],
	'hozyamARa':['fpp'],
	'juhavAm':['ppf_actn'],
	'huta':['ppp'],
	'hutavat':['pap'],
	'hotum':['inf'],
	'hutvA':['abs'],
	'hotavya':['ger'],
	'havanIya':['ger'],
	'hfd':['n'],
	'hfz':['vi4a'],
	'hfzIka':['n'],
	'hfzIkeSa':['m'],
	'he':['pcl']
}

export const defs ={
	'aklizwa':['unafflicted, untarnished'],
	'aklizwakarman':['of untarnished deeds'],
	'akza':['a die for gambling'],
	'akzaya':['imperishable, inexhaustible'],
	'agni':['fire'],
	'aNga':['limb, body', 'Bengal'],
	'aNgadeSa':['place on the body'],
	'acira':['not a long time'],
	'ajYAta':['unknown'],
	'aYc':['bend'],
	'ati':['beyond'],
	'atiBAra':['an excessive burden'],
	'aTa':['now, then'],
	'ad':['eat'],
	'adat':['eat'],
	'adyamAna':['eat'],
	'atsyat':['eat'],
	'atsyamAna':['eat'],
	'Adivas':['eat'],
	'AdAna':['eat'],
	'anna':['eat', 'food'],
	'annavat':['eat'],
	'attum':['eat'],
	'attvA':['eat'],
	'Adya':['eat'],
	'attavya':['eat'],
	'adanIya':['eat'],
	'aDi':['over'],
	'aDi_i':['go over, study, read, recite'],
	'aDIyAna':['go over, study, read, recite'],
	'aDIyamAna':['go over, study, read, recite'],
	'aDyezyamARa':['go over, study, read, recite'],
	'aDijagAna':['go over, study, read, recite'],
	'aDIta':['go over, study, read, recite'],
	'aDItavat':['go over, study, read, recite'],
	'aDyetum':['go over, study, read, recite'],
	'aDItya':['go over, study, read, recite'],
	'aDyeya':['go over, study, read, recite'],
	'aDyetavya':['go over, study, read, recite'],
	'aDyayanIya':['go over, study, read, recite'],
	'aDyAya':['chapter'],
	'anu':['after'],
	'anu_cint':['consider'],
	'anucintita':['consider'],
	'anuja':['born after', 'younger brother'],
	'anu_jan':['be born after'],
	'anujA':['younger sister'],
	'anu_jYA':['consent, permit'],
	'anujAnat':['consent, permit'],
	'anujYAyamAna':['consent, permit'],
	'anujYAsyat':['consent, permit'],
	'anujYAsyamAna':['consent, permit'],
	'anujajYivas':['consent, permit'],
	'anujajYAna':['consent, permit'],
	'anujYAta':['consent, permit'],
	'anujYAtavat':['consent, permit'],
	'anujYAtum':['consent, permit'],
	'anujYAya':['consent, permit'],
	'anujYeya':['consent, permit'],
	'anujYAtavya':['consent, permit'],
	'anujYAnIya':['consent, permit'],
	'anujYApya':['consent, permit'],
	'anya':['other'],
	'api':['even'],
	'apramatta':['not careless, careful'],
	'apramattotTita':['risen carefully'],
	'amita':['unmeasured, infinite'],
	'amitatejas':['infinitely brilliant'],
	'amitOjas':['infinitely vigorous'],
	'ari':['enemy'],
	'ariMdama':['enemy tamer'],
	'arjuna':['Arjuna'],
	'arTa':['aim, object'],
	'ava':['down'],
	'ava_DA':['put down, deposit, apply'],
	'avahita':['put down, deposit, apply', 'attentive'],
	'avaso':['end, finish'],
	'as':['be'],
	'sat':['be', 'good'],
	'asura':['demon'],
	'asmad':['we, us'],
	'ahita':['not good', 'enemy'],
	'A':['unto'],
	'A_gam':['come'],
	'A_car ':['move, do'],
	'A_ci':['accumulate'],
	'A_dA':['receive, take'],
	'Adadat':['receive, take'],
	'AdadAna':['receive, take'],
	'AdIyamAna':['receive, take'],
	'AdAsyat':['receive, take'],
	'AdAsyamAna':['receive, take'],
	'Adadivas':['receive, take'],
	'Atta':['receive, take'],
	'Adattavat':['receive, take'],
	'AdAtum':['receive, take'],
	'AdAya':['receive, take'],
	'Adeya':['receive, take'],
	'AdAtavya':['receive, take'],
	'AdAnIya':['receive, take'],
	'Adi':['beginning'],
	'Adiparvan':['first book'],
	'A_diS':['point out, assign, order'],
	'A_DA':['put on, apply'],
	'AdaDat':['put on, apply'],
	'AdaDAna':['put on, apply'],
	'ADIyamAna':['put on, apply'],
	'ADAsyat':['put on, apply'],
	'ADAsyamAna':['put on, apply'],
	'AdaDivas':['put on, apply'],
	'Ahita':['put on, apply'],
	'Ahitavat':['put on, apply'],
	'ADAtum':['put on, apply'],
	'ADAya':['put on, apply'],
	'ADeya':['put on, apply'],
	'ADAtavya':['put on, apply'],
	'ADAnIya':['put on, apply'],
	'A_DI':['recite, read, study'],
	'Ap':['attain'],
	'Apnuvat':['attain'],
	'ApyamAna':['attain'],
	'Apsyat':['attain'],
	'ApsyamAna':['attain'],
	'Apivas':['attain'],
	'ApAna':['attain'],
	'Apta':['attain'],
	'Aptavat':['attain'],
	'Aptum':['attain'],
	'AptvA':['attain'],
	'Apya':['attain'],
	'Aptavya':['attain'],
	'ApanIya':['attain'],
	'A_BA':['shine, appear'],
	'A_raB':['begin'],
	'AraBamARa':['begin'],
	'AraByamARa':['begin'],
	'ArapsyamAna':['begin'],
	'AreBARa':['begin'],
	'ArabDa':['begin'],
	'ArabDavat':['begin'],
	'ArabDum':['begin'],
	'AraBya':['begin'],
	'ArabDavya':['begin'],
	'AramBanIya':['begin'],
	'A_vah':['carry towards, bring'],
	'Avaha':['bringing'],
	'A_vraj':['walk to'],
	'ASis':["serpent's fang"],
	'ASI':["serpent's fang"],
	'ASIviza':['venom-fanged'],
	'i':['go'],
	'yat':['go'],
	'IyamAna':['go'],
	'ezyat':['go'],
	'ezyamARa':['go'],
	'Iyivas':['go'],
	'IyAna':['go'],
	'ita':['go'],
	'itavat':['go'],
	'etum':['go'],
	'itvA':['go'],
	'itya':['go'],
	'etavya':['go'],
	'ayanIya':['go'],
	'iti':['thus, close quote'],
	'idam':['this'],
	'indra':['Indra'],
	'iva':['like, as, as if'],
	'iz':['desire'],
	'icCat':['desire'],
	'izyamARa':['desire'],
	'ezizyat':['desire'],
	'ezizyamARa':['desire'],
	'Izivas':['desire'],
	'IzARa':['desire'],
	'izwa':['desire'],
	'izwavat':['desire'],
	'ezwum':['desire'],
	'izwvA':['desire'],
	'ezya':['desire'],
	'ezwavya':['desire'],
	'ezaRIya':['desire'],
	'izu':['arrow'],
	'izuDi':['quiver'],
	'IS':['rule'],
	'ISa':['lord'],
	'uttama':['highest, supreme'],
	'ud':['up'],
	'udaYc':['upward, northern'],
	'ud_aYc':['bend up, rise'],
	'udara':['uterus, womb, belly'],
	'udIcI':['the north'],
	'ud_sad':['sit up, withdraw, disappear, be ruined; caus.: ruin, destroy'],
	'utsIdat':['sit up, withdraw, disappear, be ruined; caus.: ruin, destroy'],
	'utsadyamAna':['sit up, withdraw, disappear, be ruined; caus.: ruin, destroy'],
	'utsatsyat':['sit up, withdraw, disappear, be ruined; caus.: ruin, destroy'],
	'utsatsyamAna':['sit up, withdraw, disappear, be ruined; caus.: ruin, destroy'],
	'utsedivas':['sit up, withdraw, disappear, be ruined; caus.: ruin, destroy'],
	'utsedAna':['sit up, withdraw, disappear, be ruined; caus.: ruin, destroy'],
	'utsanna':['sit up, withdraw, disappear, be ruined; caus.: ruin, destroy'],
	'utsannavat':['sit up, withdraw, disappear, be ruined; caus.: ruin, destroy'],
	'utsattum':['sit up, withdraw, disappear, be ruined; caus.: ruin, destroy'],
	'utsadya':['sit up, withdraw, disappear, be ruined; caus.: ruin, destroy'],
	'utsAdya':['sit up, withdraw, disappear, be ruined; caus.: ruin, destroy'],
	'utsattavya':['sit up, withdraw, disappear, be ruined; caus.: ruin, destroy'],
	'utsadanIya':['sit up, withdraw, disappear, be ruined; caus.: ruin, destroy'],
	'ud_sah':['be eager; caus.: encourage, inspire'],
	'ud_sTA':['stand up, arise'],
	'uttizWat':['stand up, arise'],
	'uttizWamAna':['stand up, arise'],
	'utsTIyamAna':['stand up, arise'],
	'utTAsyat':['stand up, arise'],
	'utTAsyamAna':['stand up, arise'],
	'uttasTivas':['stand up, arise'],
	'uttasTAna':['stand up, arise'],
	'utTita':['stand up, arise'],
	'utTitavat':['stand up, arise'],
	'utTAtum':['stand up, arise'],
	'utTAya':['stand up, arise'],
	'utTeya':['stand up, arise'],
	'utTAtavya':['stand up, arise'],
	'utTAnIya':['stand up, arise'],
	'upa':['under, near'],
	'upa_i':['approach'],
	'upayat':['approach'],
	'upeyamAna':['approach'],
	'upEzyat':['approach'],
	'upEzyamARa':['approach'],
	'upeyivas':['approach'],
	'upeyAna':['approach'],
	'upeta':['approach', 'endowed with'],
	'upetavat':['approach'],
	'upEtum':['approach'],
	'upetya':['approach'],
	'upEtavya':['approach'],
	'upAyanIya':['approach'],
	'upAya':['approach, means'],
	'A_i':['come'],
	'Ayat':['come'],
	'eyamAna':['come'],
	'Ezyat':['come'],
	'EzyamARa':['come'],
	'eyivas':['come'],
	'eyAna':['come'],
	'eta':['come'],
	'etavat':['come'],
	'Etum':['come'],
	'etya':['come'],
	'Etavya':['come'],
	'AyanIya':['come'],
	'fzaBa':['bull'],
	'fzi':['Vedic seer'],
	'eka':['one'],
	'ekacakra':["one-wheeled, epithet of the sun's chariot, one-kinged, one-armied"],
	'ekacakrA':['Ekacakrā'],
	'etad':['this'],
	'enad':['this, it'],
	'eva':['only'],
	'evam':['in this way, thus'],
	'ojas':['vigour, energy'],
	'kaT':['tell, narrate'],
	'kaTayat':['tell, narrate'],
	'kaTayamAna':['tell, narrate'],
	'kaTyamAna':['tell, narrate'],
	'kaTayizyat':['tell, narrate'],
	'kaTayizyamARa':['tell, narrate'],
	'kaTayAm':['tell, narrate'],
	'kaTita':['tell, narrate'],
	'kaTitavat':['tell, narrate'],
	'kaTayitum':['tell, narrate'],
	'kaTayitvA':['tell, narrate'],
	'kaTya':['tell, narrate'],
	'kaTayitavya':['tell, narrate'],
	'kaTanIya':['tell, narrate'],
	'kaTam':['how'],
	'kaTA':['story, narration'],
	'kadA':['when'],
	'kanyA':['girl'],
	'kapi':['monkey'],
	'kapilakzaRa':['monkey-bannered'],
	'karRa':['ear', 'Karṇa'],
	'karman':['action, deed'],
	'kAraka':['making, causing', 'cause'],
	'kAS':['shine'],
	'kASamAna':['shine'],
	'kASyamAna':['shine'],
	'kASizyamARa':['shine'],
	'kASARa':['shine'],
	'kASita':['shine'],
	'kASitavat':['shine'],
	'kASitum':['shine'],
	'kASitvA':['shine'],
	'kASya':['shine'],
	'kASitavya':['shine'],
	'kASanIya':['shine'],
	'kim':['what'],
	'kuntI':['Kuntī'],
	'kuru':['Kuru ; pl. his descendants'],
	'kurupARqava':['the descendants of Kuru and the sons of Pāṇḍu'],
	'kf':['do, make'],
	'kurvat':['do, make'],
	'kurvARa':['do, make'],
	'kriyamARa':['do, make'],
	'karizyat':['do, make'],
	'karizyamARa':['do, make'],
	'cakfvas':['do, make'],
	'cakrARa':['do, make'],
	'kfta':['do, make'],
	'kftavat':['do, make'],
	'kartum':['do, make'],
	'kftvA':['do, make'],
	'kftya':['do, make'],
	'kArya':['do, make'],
	'kartavya':['do, make'],
	'karaRIya':['do, make'],
	'kftsna':['whole, entire'],
	'kfzRa':['dark, black', 'Kṛṣṇa'],
	'kfzRasarpa':['black snake, cobra'],
	'keSa':['hair (of the head), mane'],
	'keSava':['having hair'],
	'kowi':['end, top'],
	'kOnteya':['son of Kuntī'],
	'kram':['step'],
	'krama':['step, succession'],
	'kruD':['be angry'],
	'kruDyat':['be angry'],
	'kruDyamAna':['be angry'],
	'krotsyat':['be angry'],
	'krotsyamAna':['be angry'],
	'cukruDvas':['be angry'],
	'cukruDAna':['be angry'],
	'krudDa':['be angry'],
	'krudDavat':['be angry'],
	'krodDum':['be angry'],
	'krudDvA':['be angry'],
	'kroDya':['be angry'],
	'krodDavya':['be angry'],
	'kroDanIya':['be angry'],
	'krUra':['cruel'],
	'kliS':['torment, afflict'],
	'kliSnat':['torment, afflict'],
	'kliSyamAna':['torment, afflict'],
	'klekzyat':['torment, afflict'],
	'kleSizyat':['torment, afflict'],
	'klekzyamARa':['torment, afflict'],
	'kleSizyamARa':['torment, afflict'],
	'cikliSivas':['torment, afflict'],
	'cikliSAna':['torment, afflict'],
	'klizwa':['torment, afflict'],
	'kliSita':['torment, afflict'],
	'klizwavat':['torment, afflict'],
	'kliSitavat':['torment, afflict'],
	'klezwum':['torment, afflict'],
	'kleSitum':['torment, afflict'],
	'klizwvA':['torment, afflict'],
	'kliSitvA':['torment, afflict'],
	'kleSya':['torment, afflict'],
	'klezwavya':['torment, afflict'],
	'kleSitavya':['torment, afflict'],
	'kleSanIya':['torment, afflict'],
	'kzam':['tolerate, forgive'],
	'kzAmyat':['tolerate, forgive'],
	'kzamyamARa':['tolerate, forgive'],
	'kzaMsyat':['tolerate, forgive'],
	'kzamizyat':['tolerate, forgive'],
	'kzaMsyamAna':['tolerate, forgive'],
	'kzamizyamARa':['tolerate, forgive'],
	'kzamivas':['tolerate, forgive'],
	'kzamARa':['tolerate, forgive'],
	'kzAnta':['tolerate, forgive'],
	'kzAntavat':['tolerate, forgive'],
	'kzantum':['tolerate, forgive'],
	'kzamitum':['tolerate, forgive'],
	'kzantvA':['tolerate, forgive'],
	'kzamitvA':['tolerate, forgive'],
	'kzamya':['tolerate, forgive'],
	'kzantavya':['tolerate, forgive'],
	'kzamitavya':['tolerate, forgive'],
	'kzamaRIya':['tolerate, forgive'],
	'kzaya':['destruction'],
	'kzi':['perish, destroy', 'possess, rule', 'destroy'],
	'kziyat':['perish, destroy', 'possess, rule'],
	'kzIyamARa':['perish, destroy', 'possess, rule', 'destroy'],
	'kzezyat':['perish, destroy', 'possess, rule', 'destroy'],
	'kzezyamARa':['perish, destroy', 'possess, rule', 'destroy'],
	'cikzivas':['perish, destroy', 'possess, rule', 'destroy'],
	'cikziyARa':['perish, destroy', 'possess, rule', 'destroy'],
	'kzIRa':['perish, destroy', 'possess, rule', 'destroy'],
	'kzita':['perish, destroy', 'possess, rule', 'destroy'],
	'kzIRavat':['perish, destroy', 'possess, rule', 'destroy'],
	'kzitavat':['perish, destroy', 'possess, rule', 'destroy'],
	'kzetum':['perish, destroy', 'possess, rule', 'destroy'],
	'kzitvA':['perish, destroy', 'possess, rule', 'destroy'],
	'kzeya':['perish, destroy', 'possess, rule', 'destroy'],
	'kzayya':['perish, destroy'],
	'kzetavya':['perish, destroy', 'possess, rule', 'destroy'],
	'kzayanIya':['perish, destroy', 'possess, rule', 'destroy'],
	'kziRvat':['destroy'],
	'kzit':['ruling'],
	'kzip':['throw'],
	'kzipyat':['throw'],
	'kzipat':['throw'],
	'kzipyamARa':['throw'],
	'kzipsyat':['throw'],
	'kzepsyat':['throw'],
	'kzipsyamARa':['throw'],
	'kzepsyamARa':['throw'],
	'cikzipivas':['throw'],
	'cikzipARa':['throw'],
	'kzipta':['throw'],
	'kziptavat':['throw'],
	'kziptum':['throw'],
	'kziptvA':['throw'],
	'kzepya':['throw'],
	'kzeptavya':['throw'],
	'kzepaRIya':['throw'],
	'Kan':['dig'],
	'Kanitra':['instrument for digging, shovel'],
	'Kanitrin':['an excavator'],
	'KanitrI':['shovel'],
	'KARqava':['Khāṇḍava'],
	'KARqavaprasTa':['the Khāṇḍava plain'],
	'gaNgA':['the river Ganges'],
	'gaRa':['group, multitude'],
	'gatamanyu':['free of anger'],
	'gatavyaTa':['fearless, free of trembling'],
	'gam':['go'],
	'gacCat':['go'],
	'gamyamAna':['go'],
	'gamizyat':['go'],
	'gamizyamARa':['go'],
	'jagmivas':['go'],
	'jaganvas':['go'],
	'jagmAna':['go'],
	'gata':['go'],
	'gatavat':['go'],
	'gantum':['go'],
	'gatvA':['go'],
	'gamya':['go'],
	'gantavya':['go'],
	'gamanIya':['go'],
	'gARqI':['rhinoceros'],
	'gARqIva':['Gāṇḍīva'],
	'guru':['heavy', 'teacher, elder'],
	'gfha':['room, house'],
	'grah':['grab'],
	'Gora':['awful'],
	'ca':['and'],
	'cakra':['wheel, discus, circle'],
	'catur':['four'],
	'caturdaSa':['fourteenth'],
	'cana':['/'],
	'car':['move, do'],
	'carat':['move, do'],
	'caramARa':['move, do'],
	'caryamARa':['move, do'],
	'carizyat':['move, do'],
	'carizyamARa':['move, do'],
	'cerivas':['move, do'],
	'cerARa':['move, do'],
	'carita':['move, do'],
	'caritavat':['move, do'],
	'caritum':['move, do'],
	'caritvA':['move, do'],
	'carya':['move, do'],
	'caritavya':['move, do'],
	'caraRIya':['move, do'],
	'ci':['pile up'],
	'cinvat':['pile up'],
	'cinvAna':['pile up'],
	'cIyamARa':['pile up'],
	'cezyat':['pile up'],
	'cezyamARa':['pile up'],
	'cikivas':['pile up'],
	'cicivas':['pile up'],
	'ciyAna':['pile up'],
	'cita':['pile up'],
	'citavat':['pile up'],
	'cetum':['pile up'],
	'citvA':['pile up'],
	'ceya':['pile up'],
	'cetavya':['pile up'],
	'cayanIya':['pile up'],
	'cit':['/'],
	'cint':['consider'],
	'cintayat':['consider'],
	'cintayamAna':['consider'],
	'cintyamAna':['consider'],
	'cintayizyat':['consider'],
	'cintayizyamARa':['consider'],
	'cintayAm':['consider'],
	'cintita':['consider'],
	'cintitavat':['consider'],
	'cintitum':['consider'],
	'cintitvA':['consider'],
	'cintya':['consider'],
	'cintitavya':['consider'],
	'cintanIya':['consider'],
	'cira':['long', 'delay'],
	'Cid':['split, cut'],
	'Cindat':['split, cut'],
	'CindAna':['split, cut'],
	'CidyamAna':['split, cut'],
	'Cetsyat':['split, cut'],
	'CetsyamAna':['split, cut'],
	'cicCidvas':['split, cut'],
	'cicCidAna':['split, cut'],
	'Cinna':['split, cut'],
	'Cinnavat':['split, cut'],
	'Cettum':['split, cut'],
	'CittvA':['split, cut'],
	'Cedya':['split, cut'],
	'Cettavya':['split, cut'],
	'CedanIya':['split, cut'],
	'ja':['born'],
	'jatu':['lac'],
	'jan':['be born'],
	'jana':['people'],
	'janapada':['district'],
	'janapadopeta':['endowed with a district'],
	'janamejaya':['Janamejaya'],
	'jaya':['victory'],
	'jAtuza':['made of lac'],
	'ji':['conquer'],
	'jayat':['conquer'],
	'jIyamAna':['conquer'],
	'jezyat':['conquer'],
	'jezyamARa':['conquer'],
	'jigivas':['conquer'],
	'jigyAna':['conquer'],
	'jita':['conquer'],
	'jitavat':['conquer'],
	'jetum':['conquer'],
	'jitvA':['conquer'],
	'jeya':['conquer'],
	'jetavya':['conquer'],
	'jayanIya':['conquer'],
	'jIv':['live'],
	'jIvat':['live'],
	'jIvyamAna':['live'],
	'jIvizyat':['live'],
	'jIvizyamARa':['live'],
	'jijIvas':['live'],
	'jijIvAna':['live'],
	'jIvita':['live'],
	'jIvitavat':['live'],
	'jIvitum':['live'],
	'jIvitvA':['live'],
	'jIvya':['live'],
	'jIvitavya':['live'],
	'jIvanIya':['live'],
	'jIva':['a living being, individual soul'],
	'jIvaloka':['the world of living beings'],
	'jF':['wither, age, grow old; caus.: digest'],
	'jIryat':['wither, age, grow old; caus.: digest'],
	'jIryamARa':['wither, age, grow old; caus.: digest'],
	'jarizyat':['wither, age, grow old; caus.: digest'],
	'jarizyamARa':['wither, age, grow old; caus.: digest'],
	'jajarvas':['wither, age, grow old; caus.: digest'],
	'jerivas':['wither, age, grow old; caus.: digest'],
	'jajarARa':['wither, age, grow old; caus.: digest'],
	'jerARa':['wither, age, grow old; caus.: digest'],
	'jIrRa':['wither, age, grow old; caus.: digest'],
	'jIrRavat':['wither, age, grow old; caus.: digest'],
	'jaritum':['wither, age, grow old; caus.: digest'],
	'jarItum':['wither, age, grow old; caus.: digest'],
	'jaritvA':['wither, age, grow old; caus.: digest'],
	'jarItvA':['wither, age, grow old; caus.: digest'],
	'jarya':['wither, age, grow old; caus.: digest'],
	'jArya':['wither, age, grow old; caus.: digest'],
	'jaritavya':['wither, age, grow old; caus.: digest'],
	'jarItavya':['wither, age, grow old; caus.: digest'],
	'jaraRIya':['wither, age, grow old; caus.: digest'],
	'jarayAm':['wither, age, grow old; caus.: digest'],
	'jYA':['know'],
	'jAnat':['know'],
	'jYAyamAna':['know'],
	'jYAsyat':['know'],
	'jYAsyamAna':['know'],
	'jajYivas':['know'],
	'jajYAna':['know'],
	'jYAta':['know'],
	'jYAtavat':['know'],
	'jYAtum':['know'],
	'jYAtvA':['know'],
	'jYeya':['know'],
	'jYAtavya':['know'],
	'jYAnIya':['know'],
	'jYApya':['know'],
	'tatas':['after that, therefore, then'],
	'tatra':['in that, there'],
	'taTA':['thus'],
	'tad':['that'],
	'tadA':['at that time, then'],
	'tan':['extend'],
	'tanvat':['extend'],
	'tanvAna':['extend'],
	'tanyamAna':['extend'],
	'taMsyat':['extend'],
	'tanizyat':['extend'],
	'taMsyamAna':['extend'],
	'tanizyamARa':['extend'],
	'tenivas':['extend'],
	'tenAna':['extend'],
	'tata':['extend'],
	'tatavat':['extend'],
	'tanitum':['extend'],
	'tanitvA':['extend'],
	'tAnya':['extend'],
	'tanitavya':['extend'],
	'tananIya':['extend'],
	'tanu':['thin', 'body, self'],
	'tap':['burn, feel pain', 'heat, scorch'],
	'tapat':['heat, scorch'],
	'tapyamAna':['heat, scorch'],
	'tapsyat':['heat, scorch'],
	'tapsyamARa':['heat, scorch'],
	'tepivas':['heat, scorch'],
	'tepAna':['heat, scorch'],
	'tapta':['heat, scorch'],
	'taptavat':['heat, scorch'],
	'taptum':['heat, scorch'],
	'taptvA':['heat, scorch'],
	'tapya':['heat, scorch'],
	'taptavya':['heat, scorch'],
	'tapanIya':['heat, scorch'],
	'tapa':['scorching'],
	'tAta':['dad, son'],
	'tu':['but'],
	'tfp':['become satisfied, be pleased; caus. satiate, satisfy, please'],
	'tfpyat':['become satisfied, be pleased; caus. satiate, satisfy, please'],
	'tfpyamARa':['become satisfied, be pleased; caus. satiate, satisfy, please'],
	'tarpsyat':['become satisfied, be pleased; caus. satiate, satisfy, please'],
	'trapsyat':['become satisfied, be pleased; caus. satiate, satisfy, please'],
	'tarpizyat':['become satisfied, be pleased; caus. satiate, satisfy, please'],
	'tatfpivas':['become satisfied, be pleased; caus. satiate, satisfy, please'],
	'tfpta':['become satisfied, be pleased; caus. satiate, satisfy, please'],
	'tfptavat':['become satisfied, be pleased; caus. satiate, satisfy, please'],
	'tarptum':['become satisfied, be pleased; caus. satiate, satisfy, please'],
	'traptum':['become satisfied, be pleased; caus. satiate, satisfy, please'],
	'tarpitum':['become satisfied, be pleased; caus. satiate, satisfy, please'],
	'tfptvA':['become satisfied, be pleased; caus. satiate, satisfy, please'],
	'tarpitvA':['become satisfied, be pleased; caus. satiate, satisfy, please'],
	'tarptavya':['become satisfied, be pleased; caus. satiate, satisfy, please'],
	'traptavya':['become satisfied, be pleased; caus. satiate, satisfy, please'],
	'tarpitavya':['become satisfied, be pleased; caus. satiate, satisfy, please'],
	'tejas':['brilliance'],
	'toya':['water'],
	'trayas':['three'],
	'trayodaSa':['thirteenth'],
	'tri':['three'],
	'tvad':['you'],
	'daMS':['bite'],
	'daSat':['bite'],
	'daSyamAna':['bite'],
	'daNkzyat':['bite'],
	'dadaMSivas':['bite'],
	'dazwa':['bite'],
	'dazwavat':['bite'],
	'daMzwum':['bite'],
	'dazwvA':['bite'],
	'daMSya':['bite'],
	'daMzwavya':['bite'],
	'daMSanIya':['bite'],
	'dakziRa':['skilful', 'right, southern'],
	'dakziRA':["the south, officiant's fee"],
	'dam':['tame'],
	'dama':['taming'],
	'daSan':['ten'],
	'dah':['burn'],
	'dahat':['burn'],
	'dahyamAna':['burn'],
	'Dakzyat':['burn'],
	'DakzyamARa':['burn'],
	'dadahvas':['burn'],
	'dadahAna':['burn'],
	'dagDa':['burn'],
	'dagDavat':['burn'],
	'dagDum':['burn'],
	'dagDvA':['burn'],
	'dAhya':['burn'],
	'dagDavya':['burn'],
	'dahanIya':['burn'],
	'dA':['give'],
	'dadat':['give'],
	'dadAna':['give'],
	'dIyamAna':['give'],
	'dAsyat':['give'],
	'dAsyamAna':['give'],
	'dadivas':['give'],
	'datta':['give'],
	'dattavat':['give'],
	'dAtum':['give'],
	'dattvA':['give'],
	'deya':['give'],
	'dAtavya':['give'],
	'dAnIya':['give'],
	'div':['sky, the vault of heaven, day'],
	'divya':['divine'],
	'diS':['direction', 'point'],
	'diSat':['point'],
	'diSamAna':['point'],
	'diSyamAna':['point'],
	'dekzyat':['point'],
	'dekzyamARa':['point'],
	'didiSivas':['point'],
	'didiSAna':['point'],
	'dizwa':['point'],
	'dizwavat':['point'],
	'dezwum':['point'],
	'dizwvA':['point'],
	'deSya':['point'],
	'dezwavya':['point'],
	'deSanIya':['point'],
	'duHSAsana':['Duḥśāsana'],
	'durmati':['ill-minded, weak-minded, evil-minded'],
	'duryoDana':['Duryodhana'],
	'dus':['ill'],
	'dfS':['see, watch'],
	'paSyat':['see, watch'],
	'dfSyamAna':['see, watch'],
	'drakzyat':['see, watch'],
	'drakzyamARa':['see, watch'],
	'dadfSvas':['see, watch'],
	'dadfSivas':['see, watch'],
	'dadfSAna':['see, watch'],
	'dfzwa':['see, watch'],
	'dfzwavat':['see, watch'],
	'drazwum':['see, watch'],
	'dfzwvA':['see, watch'],
	'dfSya':['see, watch'],
	'drazwavya':['see, watch'],
	'darSanIya':['see, watch'],
	'deva':['luminous being, god'],
	'deSa':['place'],
	'dEva':['caused by the gods (deva), fate'],
	'dEvaBAvyarTarakzita':['protected for future purposes by divine cause'],
	'dyUta':['playing with dice, gambling'],
	'dyUtasaMBUta':['arisen from the gambling match'],
	'dru':['wood, wooden implement', 'run'],
	'dravat':['run'],
	'drUyamARa':['run'],
	'drozyat':['run'],
	'drozyamARa':['run'],
	'dudruvas':['run'],
	'dudruvARa':['run'],
	'druta':['run', 'swift, fluid'],
	'drutavat':['run'],
	'drotum':['run'],
	'drutvA':['run'],
	'dravya':['run'],
	'drotavya':['run'],
	'dravaRIya':['run'],
	'drupada':['Drupada', 'wooden pillar'],
	'drOpadI':['daughter of Drupada'],
	'dvAra':['door, gate'],
	'dvAravat':['having doors, many-gated'],
	'dvAravatI':['Dvāraka'],
	'dvi':['two'],
	'dvija':['twice-born'],
	'Dana':['wealth'],
	'DanaMjaya':['winner of wealth'],
	'Danus':['bow'],
	'Dara':['holding'],
	'Darma':['that which upholds, cosmic order, truth, proper function, right action, duty'],
	'DarmapraDAna':['devoted to dharma'],
	'DarmarAja':['king dharma'],
	'DA':['put'],
	'daDat':['put'],
	'daDAna':['put'],
	'DIyamAna':['put'],
	'DAsyat':['put'],
	'DAsyamAna':['put'],
	'daDivas':['put'],
	'hita':['put', 'beneficial, good'],
	'hitavat':['put'],
	'DAtum':['put'],
	'hitvA':['put'],
	'Deya':['put'],
	'DAtavya':['put'],
	'DAnIya':['put'],
	'DI':['intelligence'],
	'DImat':['intelligent, wise'],
	'Df':['hold'],
	'Darat':['hold'],
	'DaramARa':['hold'],
	'DriyamARa':['hold'],
	'Darizyat':['hold'],
	'DarizyamARa':['hold'],
	'daDfvas':['hold'],
	'daDrARa':['hold'],
	'Dfta':['hold'],
	'Dftavat':['hold'],
	'Dartum':['hold'],
	'DftvA':['hold'],
	'Dftya':['hold'],
	'Dartavya':['hold'],
	'DaraRIya':['hold'],
	'DftarAzwra':['Dhṛtarāṣṭra'],
	'DftarAzwraja':['born of Dhṛtarāṣṭra'],
	'DftarAzwraBIzma':['Dhṛtarāṣṭra and Bhīṣma'],
	'na':['not'],
	'nakula':['Nakula'],
	'nagara':['city'],
	'nacira':['not long'],
	'nadI':['river'],
	'nam':['bow'],
	'namat':['bow'],
	'namyamAna':['bow'],
	'naMsyat':['bow'],
	'naMsyamAna':['bow'],
	'nemivas':['bow'],
	'nata':['bow'],
	'natavat':['bow'],
	'nantum':['bow'],
	'natvA':['bow'],
	'namya':['bow'],
	'nantavya':['bow'],
	'namanIya':['bow'],
	'namas':['obeisance, salutation'],
	'namas_kf':['do obeisance, make salutation, bow to'],
	'namaskurvat':['do obeisance, make salutation, bow to'],
	'namaskurvARa':['do obeisance, make salutation, bow to'],
	'namaskriyamARa':['do obeisance, make salutation, bow to'],
	'namaskarizyat':['do obeisance, make salutation, bow to'],
	'namaskarizyamARa':['do obeisance, make salutation, bow to'],
	'namaScakfvas':['do obeisance, make salutation, bow to'],
	'namaScakrARa':['do obeisance, make salutation, bow to'],
	'namaskfta':['do obeisance, make salutation, bow to'],
	'namaskftavat':['do obeisance, make salutation, bow to'],
	'namaskartum':['do obeisance, make salutation, bow to'],
	'namaskftya':['do obeisance, make salutation, bow to'],
	'namaskArya':['do obeisance, make salutation, bow to'],
	'namaskartavya':['do obeisance, make salutation, bow to'],
	'namaskaraRIya':['do obeisance, make salutation, bow to'],
	'naS':['perish'],
	'naSyat':['perish'],
	'neSivas':['perish'],
	'neSvas':['perish'],
	'nazwa':['perish'],
	'nazwavat':['perish'],
	'nazwum':['perish'],
	'nazwvA':['perish'],
	'nASya':['perish'],
	'nazwavya':['perish'],
	'naSanIya':['perish'],
	'nAman':['name'],
	'ni':['in'],
	'ni_grah':['hold down, suppress'],
	'nigraha':['restraint, suppression, defeat'],
	'nigrahanirvAsa':['defeat and exile'],
	'nitya':['innate, eternal, constant'],
	'nityam':['always'],
	'nimitta':['condition, cause, reason'],
	'nir_vas':['dwell abroad'],
	'nirvAsa':['exile, banishment'],
	'ni_vas':['dwell, live'],
	'nis':['out'],
	'ni_han':['smite'],
	'niGnat':['smite'],
	'nihanyamAna':['smite'],
	'nihaMsyat':['smite'],
	'nihanizyat':['smite'],
	'nihaMsyamAna':['smite'],
	'nihanizyamARa':['smite'],
	'nijaGnivas':['smite'],
	'nijaGanvas':['smite'],
	'nijaGnAna':['smite'],
	'nihata':['smite'],
	'nihatavat':['smite'],
	'nihantum':['smite'],
	'nihanya':['smite'],
	'niGAtya':['smite'],
	'nihantavya':['smite'],
	'nihananIya':['smite'],
	'nf':['man'],
	'nfpa':['king'],
	'nfpasattama':['most excellent king'],
	'paYcan':['five'],
	'paYcapaYcASat':['fifty-five'],
	'paYcapaYcASattama':['fifty-fifth'],
	'paYcAla':['the people of the region between the Yamuna and Gomati rivers in north India, the region itself'],
	'paYcASat':['fifty'],
	'paTin':['path, road'],
	'pad':['step'],
	'padyamAna':['step'],
	'patsyamAna':['step'],
	'pedAna':['step'],
	'panna':['step'],
	'pannavat':['step'],
	'pattum':['step'],
	'pattvA':['step'],
	'pAdya':['step'],
	'pattavya':['step'],
	'padanIya':['step'],
	'pada':['step, foot'],
	'para':['farther, other', 'enemy'],
	'paraMtapa':['enemy-scorching'],
	'paravIra':['enemy hero'],
	'paravIrahan':['slaying enemy heroes'],
	'parA':['far'],
	'parA_kram':['step beyond, be bold'],
	'parAkrama':['boldness'],
	'parAyaRa':['final aim'],
	'pari':['around'],
	'parisaMvatsara':['a full year'],
	'parisaMvatsarozita':['dwelt a full year'],
	'parispanda':['stirring, retinue'],
	'pare':['go away, flee, pass away'],
	'parvan':['joint, section'],
	'pA':['protect', 'drink'],
	'pibat':['drink'],
	'pIyamAna':['drink'],
	'pAsyat':['drink'],
	'pAsyamAna':['drink'],
	'papivas':['drink'],
	'papAna':['drink'],
	'pIta':['drink'],
	'pItavat':['drink'],
	'pAtum':['drink'],
	'pItvA':['drink'],
	'peya':['drink'],
	'pAtavya':['drink'],
	'pAnIya':['drink'],
	'pAYcAla':['of Pañcāla'],
	'pARqava':['son of Pāṇḍu'],
	'pARqu':['pale', 'Pāṇḍu'],
	'pAtra':['drinking-vessel, cup, begging bowl, worthy person'],
	'pApa':['evil'],
	'pArTa':['son of Pṛthā'],
	'pAvaka':['purifier, epithet of fire'],
	'pitf':['father'],
	'putra':['son, child'],
	'punar':['again'],
	'pura':['city'],
	'purA':['before, formerly'],
	'purAvftta':['occurred in former times', 'event or account of the past'],
	'pU':['purify'],
	'punat':['purify'],
	'punAna':['purify'],
	'pUyamAna':['purify'],
	'pavizyat':['purify'],
	'pavizyamARa':['purify'],
	'pupUvas':['purify'],
	'pupuvAna':['purify'],
	'pUta':['purify'],
	'pavita':['purify'],
	'pUtavat':['purify'],
	'pavitavat':['purify'],
	'pavitum':['purify'],
	'pUtvA':['purify'],
	'pavitvA':['purify'],
	'pavya':['purify'],
	'pavitavya':['purify'],
	'pavanIya':['purify'],
	'pUj':['honor, perform a pūjā'],
	'pUjayamAna':['honor, perform a pūjā'],
	'pUjyamAna':['honor, perform a pūjā'],
	'pUjayizyamARa':['honor, perform a pūjā'],
	'pUjayAm':['honor, perform a pūjā'],
	'pUjita':['honor, perform a pūjā'],
	'pUjitavat':['honor, perform a pūjā'],
	'pUjayitum':['honor, perform a pūjā'],
	'pUjayitvA':['honor, perform a pūjā'],
	'pUjya':['honor, perform a pūjā'],
	'pUjitavya':['honor, perform a pūjā'],
	'pUjanIya':['honor, perform a pūjā'],
	'pUrRa':['full'],
	'pfTa':['spread out, broad'],
	'pfTA':['Kuntī'],
	'pfTivI':['earth'],
	'pfTivIkzaya':['destruction of the earth'],
	'pfTivIkzayakAraka':['causing the destruction of the earth'],
	'pfTu':['broad'],
	'pfTvI':['the earth'],
	'pF':['fill'],
	'pfRat':['fill'],
	'pUryamARa':['fill'],
	'parizyat':['fill'],
	'parIzyat':['fill'],
	'parizyamARa':['fill'],
	'parIzyamARa':['fill'],
	'papFvas':['fill'],
	'paparvas':['fill'],
	'paprARa':['fill'],
	'paparARa':['fill'],
	'pUrta':['fill'],
	'pUrtavat':['fill'],
	'pUritum':['fill'],
	'pUrtvA':['fill'],
	'pUrya':['fill'],
	'pUritavya':['fill'],
	'pUraRIya':['fill'],
	'pOra':['urban', 'citizen'],
	'pOrasaMmata':['approved of by the citizens'],
	'pra':['before'],
	'pra_Ap':['attain, obtain'],
	'prApnuvat':['attain, obtain'],
	'prApyamAna':['attain, obtain'],
	'prApsyat':['attain, obtain'],
	'prApsyamAna':['attain, obtain'],
	'prApivas':['attain, obtain'],
	'prApARa':['attain, obtain'],
	'prApta':['attain, obtain'],
	'prAptavat':['attain, obtain'],
	'prAptum':['attain, obtain'],
	'prApya':['attain, obtain'],
	'prAptavya':['attain, obtain'],
	'prApaRIya':['attain, obtain'],
	'pra_ud_sah':['be eager'],
	'prakAra':['doing, kind, manner'],
	'pra_kf':['make, produce'],
	'pra_kzip':['throw, project'],
	'prakzipyat':['throw, project'],
	'prakzipat':['throw, project'],
	'prakzipyamARa':['throw, project'],
	'prakzipsyat':['throw, project'],
	'prakzepsyat':['throw, project'],
	'prakzipsyamARa':['throw, project'],
	'prakzepsyamARa':['throw, project'],
	'pracikzipivas':['throw, project'],
	'pracikzipARa':['throw, project'],
	'prakzipta':['throw, project'],
	'prakziptavat':['throw, project'],
	'prakzeptum':['throw, project'],
	'prakzipya':['throw, project'],
	'prakzepya':['throw, project'],
	'prakzeptavya':['throw, project'],
	'prakzepayaRIya':['throw, project'],
	'pracC':['ask'],
	'pfcCat':['ask'],
	'pfcCyamAna':['ask'],
	'prakzyat':['ask'],
	'prakzyamARa':['ask'],
	'papracCvas':['ask'],
	'papracCAna':['ask'],
	'pfzwa':['ask'],
	'pfzwavat':['ask'],
	'prazwum':['ask'],
	'pfzwvA':['ask'],
	'pfcCya':['ask'],
	'prazwavya':['ask'],
	'pracCanIya':['ask'],
	'pra_tap':['heat, burn, shine forth'],
	'pratapat':['heat, burn, shine forth'],
	'pratapyamAna':['heat, burn, shine forth'],
	'pratapsyat':['heat, burn, shine forth'],
	'pratapsyamARa':['heat, burn, shine forth'],
	'pratepivas':['heat, burn, shine forth'],
	'pratepAna':['heat, burn, shine forth'],
	'pratapta':['heat, burn, shine forth'],
	'prataptavat':['heat, burn, shine forth'],
	'prataptum':['heat, burn, shine forth'],
	'pratapya':['heat, burn, shine forth'],
	'prataptavya':['heat, burn, shine forth'],
	'pratapanIya':['heat, burn, shine forth'],
	'pratApa':['blazing'],
	'prati':['toward, against'],
	'prati_A_gam':['come back, return'],
	'pratyAgacCat':['come back, return'],
	'pratyAgamyamAna':['come back, return'],
	'pratyAgamizyat':['come back, return'],
	'pratyAgamizyamARa':['come back, return'],
	'pratyAjagmivas':['come back, return'],
	'pratyAjaganvas':['come back, return'],
	'pratyAjagmAna':['come back, return'],
	'pratyAgata':['come back, return'],
	'pratyAgatavat':['come back, return'],
	'pratyAgantum':['come back, return'],
	'pratyAgamya':['come back, return'],
	'pratyAgatya':['come back, return'],
	'pratyAgantavya':['come back, return'],
	'pratyAgamanIya':['come back, return'],
	'pratiGAta':['resistance, opposition, defense'],
	'prati_pad':['step back, return to'],
	'pratipadyamAna':['step back, return to'],
	'pratipatsyamAna':['step back, return to'],
	'pratipedAna':['step back, return to'],
	'pratipanna':['step back, return to'],
	'pratipannavat':['step back, return to'],
	'pratipattum':['step back, return to'],
	'pratipattvA':['step back, return to'],
	'pratipadya':['step back, return to'],
	'pratipAdya':['step back, return to'],
	'pratipattavya':['step back, return to'],
	'pratipadanIya':['step back, return to'],
	'pratIcI':['the west'],
	'pratyaYc':['turned back or against, western'],
	'praty_A_gam':['come back, return'],
	'praT':['extend, spread'],
	'pra_dru':['run forth, escape, flee'],
	'praDAna':['principal'],
	'pra_buD':['know', 'awake, be aware, know'],
	'prabuDyamAna':['awake, be aware, know'],
	'praBotsyamAna':['awake, be aware, know'],
	'prabubuDAna':['awake, be aware, know'],
	'prabudDa':['awake, be aware, know'],
	'prabudDavat':['awake, be aware, know'],
	'prabodDum':['awake, be aware, know'],
	'prabudDvA':['awake, be aware, know'],
	'prabuDya':['awake, be aware, know'],
	'praboDya':['awake, be aware, know'],
	'prabodDavya':['awake, be aware, know'],
	'praboDanIya':['awake, be aware, know'],
	'pra_mad':['err, be careless'],
	'pramatta':['err, be careless'],
	'pramARa':['means of knowledge, measure, standard, authority'],
	'pramARakowi':['Pramāṇakoṭi'],
	'pra_vac':['proclaim, declare, tell'],
	'procyamAna':['proclaim, declare, tell'],
	'pravakzyat':['proclaim, declare, tell'],
	'pravakzyamARa':['proclaim, declare, tell'],
	'procivas':['proclaim, declare, tell'],
	'procAna':['proclaim, declare, tell'],
	'prokta':['proclaim, declare, tell'],
	'proktavat':['proclaim, declare, tell'],
	'pravaktum':['proclaim, declare, tell'],
	'proktvA':['proclaim, declare, tell'],
	'procya':['proclaim, declare, tell'],
	'pravAkya':['proclaim, declare, tell'],
	'pravaktavya':['proclaim, declare, tell'],
	'pravacanIya':['proclaim, declare, tell'],
	'prasTa':['plateau, plain'],
	'pra_sTA':['set out'],
	'pratizWamAna':['set out'],
	'prasTIyamAna':['set out'],
	'prasTAsyamAna':['set out'],
	'pratasTAna':['set out'],
	'prasTita':['set out'],
	'prasTitavat':['set out'],
	'prasTAtum':['set out'],
	'prasTAya':['set out'],
	'prasTeya':['set out'],
	'prasTAtavya':['set out'],
	'prasTAnIya':['set out'],
	'prasTApayAm':['set out'],
	'prAk':['before, first'],
	'prAcI':['the east'],
	'prAYc':['turned or being forwards, front, eastward, eastern, before'],
	'prI':['please', 'be pleased'],
	'prIta':['be pleased'],
	'baka':['heron, a cheat', 'Baka'],
	'banD':['bind'],
	'baDnat':['bind'],
	'baDyamAna':['bind'],
	'Bantsyat':['bind'],
	'BantsyamAna':['bind'],
	'babanDvas':['bind'],
	'babanDAna':['bind'],
	'badDa':['bind'],
	'badDavat':['bind'],
	'bandDum':['bind'],
	'badDvA':['bind'],
	'banDya':['bind'],
	'bandDavya':['bind'],
	'banDanIya':['bind'],
	'banDana':['binding, bond'],
	'bala':['strength'],
	'bahu':['much, many'],
	'bARa':['reed, arrow'],
	'bAD':['press, harass'],
	'bIBatsu':['despising, loathsome, reserved, coy'],
	'budDi':['intelligence'],
	'buD':['know', 'be aware, know'],
	'boDat':['know'],
	'boDamAna':['know'],
	'buDyamAna':['know', 'be aware, know'],
	'Botsyat':['know'],
	'BotsyamAna':['know', 'be aware, know'],
	'bubuDvas':['know'],
	'bubuDAna':['know', 'be aware, know'],
	'buDita':['know'],
	'buDitavat':['know'],
	'boDitum':['know'],
	'buDitvA':['know'],
	'boDitvA':['know'],
	'boDya':['know', 'be aware, know'],
	'boDitavya':['know'],
	'boDanIya':['know', 'be aware, know'],
	'budDa':['be aware, know'],
	'budDavat':['be aware, know'],
	'bodDum':['be aware, know'],
	'budDvA':['be aware, know'],
	'bodDavya':['be aware, know'],
	'brahman':['a brāhmaṇa'],
	'brahmarUpa':['appearance of a brahmin'],
	'brahmarUpaDara':['bearing the appearance of a brahmin'],
	'brAhmaRArTa':['the purpose of a brāhmaṇa'],
	'brAhmaRa':['a brahmin'],
	'Baj':['partake of, share, love, serve'],
	'Bajat':['partake of, share, love, serve'],
	'BajamAna':['partake of, share, love, serve'],
	'BajyamAna':['partake of, share, love, serve'],
	'Bakzyat':['partake of, share, love, serve'],
	'BakzyamAna':['partake of, share, love, serve'],
	'Bejivas':['partake of, share, love, serve'],
	'BejAna':['partake of, share, love, serve'],
	'Bakta':['partake of, share, love, serve'],
	'Baktavat':['partake of, share, love, serve'],
	'Baktum':['partake of, share, love, serve'],
	'BAktvA':['partake of, share, love, serve'],
	'Bajya':['partake of, share, love, serve'],
	'Baktavya':['partake of, share, love, serve'],
	'BajanIya':['partake of, share, love, serve'],
	'Badra':['blessed, good', 'welfare'],
	'BadraBAzin':['speaking what is auspicious'],
	'Baya':['fear'],
	'Bara':['bearing'],
	'Barata':['Bharata'],
	'BaratarzaBa':['bull-like descendant of Bharata'],
	'BA':['shine, appear'],
	'BAt':['shine, appear'],
	'BAyamAna':['shine, appear'],
	'BAsyat':['shine, appear'],
	'BAsyamAna':['shine, appear'],
	'baBivas':['shine, appear'],
	'baBAna':['shine, appear'],
	'BAta':['shine, appear'],
	'BAtavat':['shine, appear'],
	'BAtum':['shine, appear'],
	'BAtavya':['shine, appear'],
	'BAnIya':['shine, appear'],
	'BAra':['burden'],
	'BArata':['descendant of Bharata', 'the war between the descendants of Bharata'],
	'BAryA':['wife'],
	'BAvin':['future'],
	'BAvyarTa':['future purpose'],
	'BAvyarTarakzita':['protected for future purposes'],
	'BAz':['speak, say'],
	'BAzamARa':['speak, say'],
	'BAzyamARa':['speak, say'],
	'BAzizyamARa':['speak, say'],
	'baBAzARa':['speak, say'],
	'BAzita':['speak, say'],
	'BAzitavat':['speak, say'],
	'BAzitum':['speak, say'],
	'BAzitvA':['speak, say'],
	'BAzya':['speak, say'],
	'BAzitavya':['speak, say'],
	'BAzaRIya':['speak, say'],
	'BAzin':['speaking'],
	'Bid':['split'],
	'Bindat':['split'],
	'BindAna':['split'],
	'BidyamAna':['split'],
	'Betsyat':['split'],
	'BetsyamAna':['split'],
	'biBidvas':['split'],
	'biBidAna':['split'],
	'Binna':['split'],
	'Binnavat':['split'],
	'Bettum':['split'],
	'BittvA':['split'],
	'Bedya':['split'],
	'Bettavya':['split'],
	'BedanIya':['split'],
	'BI':['fear'],
	'biByat':['fear'],
	'BIyamAna':['fear'],
	'Bezyat':['fear'],
	'BezyamARa':['fear'],
	'biBayAm':['fear'],
	'biBIvas':['fear'],
	'biByAna':['fear'],
	'BIta':['fear'],
	'BItavat':['fear'],
	'Betum':['fear'],
	'BItvA':['fear'],
	'Beya':['fear'],
	'Betavya':['fear'],
	'BayanIya':['fear'],
	'BIma':['frightful, terrifying', 'Bhīma'],
	'BImaparAkrama':['terrifyingly bold'],
	'BImasena':['having a terrifying army'],
	'BIzma':['terrifying', 'Bhīṣma'],
	'BU':['be, become', 'earth'],
	'Bavat':['be, become'],
	'BUyamAna':['be, become'],
	'Bavizyat':['be, become'],
	'BavizyamARa':['be, become'],
	'baBUvas':['be, become'],
	'BUta':['be, become'],
	'BUtavat':['be, become'],
	'Bavitum':['be, become'],
	'BUtvA':['be, become'],
	'Bavya':['be, become'],
	'Bavitavya':['be, become'],
	'BavanIya':['be, become'],
	'BUyas':['more'],
	'BUyizWa':['most'],
	'Bf':['bear'],
	'biBrat':['bear'],
	'biBrARa':['bear'],
	'BriyamARa':['bear'],
	'Barizyat':['bear'],
	'BarizyamARa':['bear'],
	'biBarAm':['bear'],
	'baBfvas':['bear'],
	'baBrARa':['bear'],
	'Bfta':['bear'],
	'Bftavat':['bear'],
	'Bartum':['bear'],
	'BftvA':['bear'],
	'Bftya':['bear'],
	'Bartavya':['bear'],
	'BaraRIya':['bear'],
	'Beda':['split, division, breach'],
	'BrAtf':['brother'],
	'mata':['thought', 'think'],
	'mati':['thought'],
	'mad':['I, me', 'rejoice'],
	'mAdyat':['rejoice'],
	'madyamAna':['rejoice'],
	'madizyat':['rejoice'],
	'madizyamARa':['rejoice'],
	'medivas':['rejoice'],
	'medAna':['rejoice'],
	'matta':['rejoice'],
	'mattavat':['rejoice'],
	'maditum':['rejoice'],
	'maditvA':['rejoice'],
	'madya':['rejoice'],
	'maditavya':['rejoice'],
	'madanIya':['rejoice'],
	'man':['think'],
	'manyamAna':['think'],
	'manvAna':['think'],
	'maMsyamAna':['think'],
	'menAna':['think'],
	'matavat':['think'],
	'mantum':['think'],
	'matvA':['think'],
	'mAnya':['think'],
	'mantavya':['think'],
	'mananIya':['think'],
	'manas':['mind'],
	'manobudDisamADi':['mind, intellect and settled awareness'],
	'mantr':['consult'],
	'mantrayamAna':['consult'],
	'mantryamAna':['consult'],
	'mantrayizyamARa':['consult'],
	'mantrayAm':['consult'],
	'mantrita':['consult'],
	'mantritavat':['consult'],
	'mantrayitum':['consult'],
	'mantrayitvA':['consult'],
	'mantrya':['consult'],
	'mantrayitavya':['consult'],
	'mantraRIya':['consult'],
	'mantra':['instrument of thought, sacred text, counsel'],
	'manda':['slow, stupid'],
	'mandira':['house, palace, temple'],
	'manyu':['spirit, fury, anger'],
	'maya':['Maya'],
	'mah':['increase', 'magnify, exalt'],
	'mahat':['great'],
	'maharzi':['great sage'],
	'mahApaTa':['great path, road'],
	'mahAbala':['very strong'],
	'mahABArata':['the great war between the descendants of Bharata'],
	'mahAmati':['great-minded'],
	'mahArAja':['great king'],
	'mahAvana':['great forest'],
	'mahAsura':['great demon'],
	'mahI':['earth'],
	'mahIkzit':['ruling the earth, king'],
	'mahendra':['the great Indra'],
	'mA':['measure, make'],
	'mimAna':['measure, make'],
	'mIyamARa':['measure, make'],
	'mAsyamAna':['measure, make'],
	'mamARa':['measure, make'],
	'mita':['measure, make'],
	'mitavat':['measure, make'],
	'mAtum':['measure, make'],
	'mitvA':['measure, make'],
	'meya':['measure, make'],
	'mAtavya':['measure, make'],
	'manIya':['measure, make'],
	'mAtf':['mother'],
	'mAs':['the moon, a month'],
	'mAsa':['the moon, a month'],
	'muc':['release'],
	'muYcat':['release'],
	'muYcamAna':['release'],
	'mucyamAna':['release'],
	'mokzyat':['release'],
	'mokzyamARa':['release', 'liberate'],
	'mumucvas':['release'],
	'mumucAna':['release'],
	'mokzayAm':['release'],
	'mukta':['release'],
	'muktavat':['release'],
	'moktum':['release'],
	'muktvA':['release'],
	'mocya':['release'],
	'moktavya':['release'],
	'mocanIya':['release'],
	'mud':['joy', 'rejoice'],
	'mf':['die'],
	'mriyamARa':['die'],
	'marizyat':['die'],
	'marizyamARa':['die'],
	'mamfvas':['die'],
	'mamrARa':['die'],
	'mfta':['die'],
	'mftavat':['die'],
	'martum':['die'],
	'mftvA':['die'],
	'mArya':['die'],
	'martavya':['die'],
	'maraRIya':['die'],
	'mfz':['forget, tolerate'],
	'mfzyat':['forget, tolerate'],
	'mfzyamARa':['forget, tolerate'],
	'marzizyat':['forget, tolerate'],
	'marzizyamARa':['forget, tolerate'],
	'mamfzivas':['forget, tolerate'],
	'mamfzAna':['forget, tolerate'],
	'marzita':['forget, tolerate'],
	'mfzita':['forget, tolerate'],
	'marzitavat':['forget, tolerate'],
	'mfzitavat':['forget, tolerate'],
	'marzitum':['forget, tolerate'],
	'marzitvA':['forget, tolerate'],
	'mfzitvA':['forget, tolerate'],
	'mfzya':['forget, tolerate'],
	'marzitavya':['forget, tolerate'],
	'marzaRIya':['forget, tolerate'],
	'mokz':['liberate'],
	'mokzayat':['liberate'],
	'mokzizyat':['liberate'],
	'mokzizyamARa':['liberate'],
	'mokzita':['liberate'],
	'mokzitavat':['liberate'],
	'mokzitum':['liberate'],
	'mokzitvA':['liberate'],
	'mokzya':['liberate'],
	'mokzitavya':['liberate'],
	'mokzaRIya':['liberate'],
	'mokzaRa':['freeing, liberation'],
	'yatas':['whence, because'],
	'yaTA':['in which manner, as'],
	'yadA':['at which time, when'],
	'yaSas':['glory, fame'],
	'yAc':['ask'],
	'yAcat':['ask'],
	'yAcamAna':['ask'],
	'yAcyamAna':['ask'],
	'yAcizyat':['ask'],
	'yAcizyamARa':['ask'],
	'yayAcivas':['ask'],
	'yayAcAna':['ask'],
	'yAcita':['ask'],
	'yAcitavat':['ask'],
	'yAcitum':['ask'],
	'yAcitvA':['ask'],
	'yAcya':['ask'],
	'yAcitavya':['ask'],
	'yAcanIya':['ask'],
	'yuj':['yoke, join, unite'],
	'yuYjat':['yoke, join, unite'],
	'yuYjAna':['yoke, join, unite'],
	'yujyamAna':['yoke, join, unite'],
	'yokzyat':['yoke, join, unite'],
	'yokzyamARa':['yoke, join, unite'],
	'yuyujvas':['yoke, join, unite'],
	'yuyujAna':['yoke, join, unite'],
	'yukta':['yoke, join, unite'],
	'yuktavat':['yoke, join, unite'],
	'yoktum':['yoke, join, unite'],
	'yuktvA':['yoke, join, unite'],
	'yogya':['yoke, join, unite'],
	'yoktavya':['yoke, join, unite'],
	'yojanIya':['yoke, join, unite'],
	'yudDa':['battle, war', 'fight'],
	'yuD':['battle, war', 'fight'],
	'yuDyamAna':['fight'],
	'yotsyamAna':['fight'],
	'yuyuDAna':['fight'],
	'yudDavat':['fight'],
	'yodDum':['fight'],
	'yudDvA':['fight'],
	'yoDya':['fight'],
	'yodDavya':['fight'],
	'yoDanIya':['fight'],
	'yuDizWira':['Yudhiṣṭhira'],
	'yuzmad':['you'],
	'yuzmadvAsa':['your dwelling'],
	'yoga':['union, conjunction, use, work, means, stratagem'],
	'rakz':['keep, protect'],
	'rakzat':['keep, protect'],
	'rakzamARa':['keep, protect'],
	'rakzyamARa':['keep, protect'],
	'rakzizyat':['keep, protect'],
	'rakzizyamARa':['keep, protect'],
	'rarakzivas':['keep, protect'],
	'rarakzARa':['keep, protect'],
	'rakzita':['keep, protect'],
	'rakzitavat':['keep, protect'],
	'rakzitum':['keep, protect'],
	'rakzitvA':['keep, protect'],
	'rakzya':['keep, protect'],
	'rakzitavya':['keep, protect'],
	'rakzanIya':['keep, protect'],
	'rakzas':['demon'],
	'ratna':['gift, wealth, gem'],
	'raTa':['chariot, car'],
	'raB':['begin'],
	'raBamARa':['begin'],
	'raByamARa':['begin'],
	'rapsyamARa':['begin'],
	'reBivas':['begin'],
	'rabDa':['begin'],
	'rabDavat':['begin'],
	'rabDum':['begin'],
	'rabDvA':['begin'],
	'raBya':['begin'],
	'rabDavya':['begin'],
	'ramBaRIya':['begin'],
	'rAkzasa':['demon'],
	'rAj':['shine, rule'],
	'rAjat':['shine, rule'],
	'rajamAna':['shine, rule'],
	'rAjyamAna':['shine, rule'],
	'rAjizyat':['shine, rule'],
	'rAjizyamARa':['shine, rule'],
	'rarAjivas':['shine, rule'],
	'rarAjAna':['shine, rule'],
	'rAjita':['shine, rule'],
	'rAjitavat':['shine, rule'],
	'rAjitum':['shine, rule'],
	'rAjitvA':['shine, rule'],
	'rAjya':['shine, rule', 'kingdom'],
	'rAjitavya':['shine, rule'],
	'rAjanIya':['shine, rule'],
	'rAjan':['king'],
	'rAjIva':['blue lotus'],
	'rAjIvalocana':['lotus-eyed'],
	'rAjyavinASa':['destruction of a kingdom'],
	'rAjyArTa':['purpose of a kingdom'],
	'rAzwra':['kingdom'],
	'rUpa':['appearance, form, beauty'],
	'rUpavIryOjaHsaMpanna':['endowed with beauty, power and energy'],
	'rUpavIryOjas':['beauty, power and energy'],
	'lakzaRa':['characteristic, sign'],
	'lakzmI':['sign, good fortune', 'Lakṣmī'],
	'laB':['obtain'],
	'laBamAna':['obtain'],
	'laByamAna':['obtain'],
	'lapsyamAna':['obtain'],
	'leBAna':['obtain'],
	'labDa':['obtain'],
	'labDavat':['obtain'],
	'labDum':['obtain'],
	'labDvA':['obtain'],
	'laBya':['obtain'],
	'labDavya':['obtain'],
	'laBanIya':['obtain'],
	'luB':['lust'],
	'luByat':['lust'],
	'luByamAna':['lust'],
	'loBizyat':['lust'],
	'loBizyamARa':['lust'],
	'luluBAna':['lust'],
	'lubDa':['lust'],
	'lubDavat':['lust'],
	'lobDum':['lust'],
	'loBitum':['lust'],
	'lubDvA':['lust'],
	'luBitvA':['lust'],
	'loBitvA':['lust'],
	'luBya':['lust'],
	'lobDavya':['lust'],
	'loBitavya':['lust'],
	'loBanIya':['lust'],
	'loka':['region'],
	'locana':['eye'],
	'loBa':['love, desire'],
	'vac':['say, speak'],
	'ucyamAna':['say, speak'],
	'vakzyat':['say, speak', 'carry, flow, wed'],
	'vakzyamARa':['say, speak', 'carry, flow, wed'],
	'Ucivas':['say, speak'],
	'UcAna':['say, speak'],
	'ukta':['say, speak'],
	'uktavat':['say, speak'],
	'vaktum':['say, speak'],
	'uktvA':['say, speak'],
	'vAkya':['say, speak'],
	'vaktavya':['say, speak'],
	'vacanIya':['say, speak'],
	'vacana':['speech'],
	'vaYc':['deceive'],
	'vaYcayamAna':['deceive'],
	'vaYcyamAna':['deceive'],
	'vaYcayizyamARa':['deceive'],
	'vaYcayAm':['deceive'],
	'vakta':['deceive'],
	'vaktavat':['deceive'],
	'vaYcayitum':['deceive'],
	'vaktvA':['deceive'],
	'vacitvA':['deceive'],
	'vaYcitvA':['deceive'],
	'vaYcayitvA':['deceive'],
	'vaYcya':['deceive'],
	'vaYcayitavya':['deceive'],
	'vaYcanIya':['deceive'],
	'vad':['tell'],
	'vadat':['tell'],
	'udyamAna':['tell'],
	'vadizyat':['tell'],
	'vadizyamARa':['tell'],
	'Udivas':['tell'],
	'UdAna':['tell'],
	'udita':['tell'],
	'uditavat':['tell'],
	'vaditum':['tell'],
	'uditvA':['tell'],
	'vAdya':['tell'],
	'vaditavya':['tell'],
	'vadanIya':['tell'],
	'vaD':['smite, slay'],
	'vaDa':['killing, slaughter'],
	'vana':['forest'],
	'vanavAsa':['forest-dwelling'],
	'vara':['elect, excellent', 'choice, boon'],
	'varza':['rain, year'],
	'vaS':['wish'],
	'uSat':['wish'],
	'uSyamAna':['wish'],
	'vaSizyat':['wish'],
	'vaSizyamARa':['wish'],
	'uSita':['wish'],
	'uSitavat':['wish'],
	'vaSitum':['wish'],
	'vaSitvA':['wish'],
	'vaSya':['wish'],
	'vaSitavya':['wish'],
	'vaSanIya':['wish'],
	'vaSa':['will, wish, desire, control'],
	'vas':['dwell, live'],
	'vasat':['dwell, live'],
	'uzyamARa':['dwell, live'],
	'vatsyat':['dwell, live'],
	'vatsyamAna':['dwell, live'],
	'Uzivas':['dwell, live'],
	'UzARa':['dwell, live'],
	'vAsayAm':['dwell, live'],
	'uzita':['dwell, live'],
	'uzitavat':['dwell, live'],
	'vastum':['dwell, live'],
	'uzitvA':['dwell, live'],
	'vAsya':['dwell, live'],
	'vasitavya':['dwell, live'],
	'vasanIya':['dwell, live'],
	'vasu':['excellent, good, beneficent', 'goods, property'],
	'vasuMDara':['holding wealth'],
	'vasuMDarA':['the earth'],
	'vasudeva':['Vasudeva'],
	'vah':['carry, flow, wed'],
	'vahat':['carry, flow, wed'],
	'vahamAna':['carry, flow, wed'],
	'uhyamAna':['carry, flow, wed'],
	'vahizyat':['carry, flow, wed'],
	'vahizyamARa':['carry, flow, wed'],
	'Uhivas':['carry, flow, wed'],
	'UhAna':['carry, flow, wed'],
	'UQa':['carry, flow, wed'],
	'UQavat':['carry, flow, wed'],
	'voQum':['carry, flow, wed'],
	'UQvA':['carry, flow, wed'],
	'vAhya':['carry, flow, wed'],
	'voQavya':['carry, flow, wed'],
	'vAhanIya':['carry, flow, wed'],
	'vAsa':['dwelling, living'],
	'vAsudeva':['son of Vasudeva'],
	'vAhana':['carrying', 'vehicle'],
	'vi':['apart'],
	'vi_ava_so':['conclude, determine'],
	'vyavasyat':['conclude, determine'],
	'vyavasyamAna':['conclude, determine'],
	'vyavasIyamAna':['conclude, determine'],
	'vyavasAsyat':['conclude, determine'],
	'vyavasAsyamAna':['conclude, determine'],
	'vyavasasivas':['conclude, determine'],
	'vyavasasAna':['conclude, determine'],
	'vyavasita':['conclude, determine'],
	'vyavasitavat':['conclude, determine'],
	'vyavasAtum':['conclude, determine'],
	'vyavasAya':['conclude, determine', 'resolution, determination'],
	'vyavaseya':['conclude, determine'],
	'vyavasAtavya':['conclude, determine'],
	'vyavasAnIya':['conclude, determine'],
	'vi_kram':['step wide, be bold, advance'],
	'vikrama':['wide step, valor'],
	'vi_grah':['take apart, analyse, wage war'],
	'vigraha':['division, discord, war'],
	'vi_ji':['conquer'],
	'vijayamAna':['conquer'],
	'vijIyamAna':['conquer'],
	'vijezyamARa':['conquer'],
	'vijigyAna':['conquer'],
	'vijita':['conquer'],
	'vijitavat':['conquer'],
	'vijetum':['conquer'],
	'vijitya':['conquer'],
	'vijeya':['conquer'],
	'vijetavya':['conquer'],
	'vijayanIya':['conquer'],
	'vid':['know', 'find, get'],
	'vidvas':['know'],
	'vidyamAna':['know', 'find, get'],
	'vedizyat':['know', 'find, get'],
	'vedizyamARa':['know', 'find, get'],
	'vidAm':['know'],
	'vividvas':['know', 'find, get'],
	'vividAna':['know', 'find, get'],
	'vidita':['know'],
	'viditavat':['know'],
	'veditum':['know'],
	'viditvA':['know', 'find, get'],
	'vedya':['know', 'find, get'],
	'veditavya':['know'],
	'vedanIya':['know', 'find, get'],
	'vindat':['find, get'],
	'vindamAna':['find, get'],
	'vividivas':['find, get'],
	'vitta':['find, get'],
	'vittavat':['find, get'],
	'vettum':['find, get'],
	'vittvA':['find, get'],
	'veditvA':['find, get'],
	'vettavya':['find, get'],
	'vidura':['knowledgeable, wise', 'Vidura'],
	'vi_dru':['run apart, disperse'],
	'vidravat':['run apart, disperse'],
	'vidrUyamARa':['run apart, disperse'],
	'vidrozyat':['run apart, disperse'],
	'vidrozyamARa':['run apart, disperse'],
	'vidudruvas':['run apart, disperse'],
	'vidudruvARa':['run apart, disperse'],
	'vidruta':['run apart, disperse'],
	'vidrutavat':['run apart, disperse'],
	'vidrotum':['run apart, disperse'],
	'vidrutya':['run apart, disperse'],
	'vidravya':['run apart, disperse'],
	'vidrotavya':['run apart, disperse'],
	'vidravaRIya':['run apart, disperse'],
	'vidrutaBUyizWa':['mostly destroyed'],
	'vi_DA':['bestow, supply, ordain'],
	'vidaDat':['bestow, supply, ordain'],
	'vidaDAna':['bestow, supply, ordain'],
	'viDIyamAna':['bestow, supply, ordain'],
	'viDAsyat':['bestow, supply, ordain'],
	'viDAsyamAna':['bestow, supply, ordain'],
	'vidaDivas':['bestow, supply, ordain'],
	'vihita':['bestow, supply, ordain'],
	'vihitavat':['bestow, supply, ordain'],
	'viDAtum':['bestow, supply, ordain'],
	'viDAya':['bestow, supply, ordain'],
	'viDeya':['bestow, supply, ordain'],
	'viDAtavya':['bestow, supply, ordain'],
	'viDAnIya':['bestow, supply, ordain'],
	'vi_naS':['perish'],
	'vinaSyat':['perish'],
	'vineSivas':['perish'],
	'vineSvas':['perish'],
	'vinazwa':['perish'],
	'vinazwavat':['perish'],
	'vinazwum':['perish'],
	'vinaSya':['perish'],
	'vinASya':['perish'],
	'vinazwavya':['perish'],
	'vinaSanIya':['perish'],
	'vinASa':['destruction'],
	'vi_ni_han':['strike down, destroy'],
	'viniGnat':['strike down, destroy'],
	'vinihanyamAna':['strike down, destroy'],
	'vinihaMsyat':['strike down, destroy'],
	'vinihanizyat':['strike down, destroy'],
	'vinihaMsyamAna':['strike down, destroy'],
	'vinihanizyamARa':['strike down, destroy'],
	'vinijaGnivas':['strike down, destroy'],
	'vinijaGanvas':['strike down, destroy'],
	'vinijaGnAna':['strike down, destroy'],
	'vinihata':['strike down, destroy'],
	'vinihatavat':['strike down, destroy'],
	'vinihantum':['strike down, destroy'],
	'vinihanya':['strike down, destroy'],
	'viniGAtya':['strike down, destroy'],
	'vinihantavya':['strike down, destroy'],
	'vinihananIya':['strike down, destroy'],
	'viprakAra':['misdeed, offense'],
	'viBakta':['divided, distributed', 'divide, distribute, apportion'],
	'vi_Baj':['divide, distribute, apportion'],
	'viBajat':['divide, distribute, apportion'],
	'viBajamAna':['divide, distribute, apportion'],
	'viBajyamAna':['divide, distribute, apportion'],
	'viBakzyat':['divide, distribute, apportion'],
	'viBakzyamAna':['divide, distribute, apportion'],
	'viBejivas':['divide, distribute, apportion'],
	'viBejAna':['divide, distribute, apportion'],
	'viBaktavat':['divide, distribute, apportion'],
	'viBaktum':['divide, distribute, apportion'],
	'viBajya':['divide, distribute, apportion'],
	'viBaktavya':['divide, distribute, apportion'],
	'viBajanIya':['divide, distribute, apportion'],
	'vi_rAj':['shine, rule'],
	'virAjat':['shine, rule'],
	'virajamAna':['shine, rule'],
	'virAjyamAna':['shine, rule'],
	'virAjizyat':['shine, rule'],
	'virAjizyamARa':['shine, rule'],
	'virarAjivas':['shine, rule'],
	'virarAjAna':['shine, rule'],
	'virAjita':['shine, rule'],
	'virAjitavat':['shine, rule'],
	'virAjitum':['shine, rule'],
	'virAjya':['shine, rule'],
	'virAjitavya':['shine, rule'],
	'virAjanIya':['shine, rule'],
	'viviDa':['various'],
	'viviDopAya':['various means'],
	'vi_vf':['uncover, reveal, explain'],
	'vivfRvat':['uncover, reveal, explain'],
	'vivfRvAna':['uncover, reveal, explain'],
	'vivriyamARa':['uncover, reveal, explain'],
	'vivarizyat':['uncover, reveal, explain'],
	'vivarIzyat':['uncover, reveal, explain'],
	'vivarizyamARa':['uncover, reveal, explain'],
	'vivarIzyamARa':['uncover, reveal, explain'],
	'vivavfvas':['uncover, reveal, explain'],
	'vivavrARa':['uncover, reveal, explain'],
	'vivfta':['uncover, reveal, explain'],
	'vivftavat':['uncover, reveal, explain'],
	'vivaritum':['uncover, reveal, explain'],
	'vivarItum':['uncover, reveal, explain'],
	'vivftvA':['uncover, reveal, explain'],
	'vi-vftya':['uncover, reveal, explain'],
	'vivftya':['uncover, reveal, explain'],
	'vivaritavya':['uncover, reveal, explain'],
	'vivarItavya':['uncover, reveal, explain'],
	'vivaraRIya':['uncover, reveal, explain'],
	'viS':['settler, people'],
	'viSaMpa':['people-protecting'],
	'vi_SramB':['be careless, negligent'],
	'viSramBamARa':['be careless, negligent'],
	'viSraByamARa':['be careless, negligent'],
	'viSramBizyamARa':['be careless, negligent'],
	'viSaSramBARa':['be careless, negligent'],
	'viSrabDa':['be careless, negligent'],
	'viSrabDavat':['be careless, negligent'],
	'viSramBitum':['be careless, negligent'],
	'viSraBya':['be careless, negligent'],
	'viSramBya':['be careless, negligent'],
	'viSramBitavya':['be careless, negligent'],
	'viSramBaRIya':['be careless, negligent'],
	'vi_Sru':['hear widely'],
	'viSfRvat':['hear widely'],
	'viSrUyamARa':['hear widely'],
	'viSruta':['hear widely'],
	'viSrutavat':['hear widely'],
	'viSrotum':['hear widely'],
	'viSrutya':['hear widely'],
	'viSravya':['hear widely'],
	'viSrotavya':['hear widely'],
	'viSravaRIya':['hear widely'],
	'viza':['active, poisonous', 'poison'],
	'vizRu':['Viṣṇu'],
	'vi_sramB':['be confident, trust, relax'],
	'visramBamARa':['be confident, trust, relax'],
	'visraByamARa':['be confident, trust, relax'],
	'visramBizyamARa':['be confident, trust, relax'],
	'visasramBARa':['be confident, trust, relax'],
	'visrabDa':['be confident, trust, relax'],
	'visrabDavat':['be confident, trust, relax'],
	'visramBitum':['be confident, trust, relax'],
	'visraBya':['be confident, trust, relax'],
	'visramBya':['be confident, trust, relax'],
	'visramBitavya':['be confident, trust, relax'],
	'visramBaRIya':['be confident, trust, relax'],
	'vIra':['heroic', 'hero'],
	'vIrya':['virility, power'],
	'vf':['cover', 'block', 'choose'],
	'vfRvat':['cover'],
	'vfRvAna':['cover'],
	'vriyamARa':['cover', 'choose'],
	'varizyat':['cover'],
	'varIzyat':['cover'],
	'varizyamARa':['cover', 'choose'],
	'varIzyamARa':['cover', 'choose'],
	'vavfvas':['cover'],
	'vavrARa':['cover', 'choose'],
	'vfta':['cover', 'choose'],
	'vftavat':['cover', 'choose'],
	'varitum':['cover', 'choose'],
	'varItum':['cover', 'choose'],
	'vftvA':['cover', 'choose'],
	'vftya':['cover', 'occur, be present'],
	'varitavya':['cover', 'choose'],
	'varItavya':['cover'],
	'varaRIya':['cover', 'choose'],
	'vArayat':['block'],
	'vArayamARa':['block'],
	'vAryamARa':['block'],
	'vArayizyat':['block'],
	'vArayizyamARa':['block'],
	'vArayAm':['block'],
	'vArita':['block'],
	'vAritavat':['block'],
	'vArayitum':['block'],
	'vArayitvA':['block'],
	'vArya':['block', 'choose'],
	'vArayitavya':['block'],
	'vAraRIya':['block'],
	'vfRAna':['choose'],
	'vfka':['wolf'],
	'vfkodara':['wolf-bellied'],
	'vft':['occur, be present'],
	'vartamAna':['occur, be present'],
	'vartizyamARa':['occur, be present'],
	'vavftAna':['occur, be present'],
	'vftta':['occur, be present', 'occurrence, event'],
	'vfttavat':['occur, be present'],
	'vartitum':['occur, be present'],
	'vartitvA':['occur, be present'],
	'vartitavya':['occur, be present'],
	'vartanIya':['occur, be present'],
	'vfz':['rain'],
	'varzat':['rain'],
	'vfzyamARa':['rain'],
	'varzizyat':['rain'],
	'varzyamARa':['rain'],
	'vavfzivas':['rain'],
	'vavfzAna':['rain'],
	'vfzwa':['rain'],
	'vfzwavat':['rain'],
	'varzitum':['rain'],
	'vfzwvA':['rain'],
	'varzitvA':['rain'],
	'vfzya':['rain'],
	'varzya':['rain'],
	'varzitavya':['rain'],
	'varzaRIya':['rain'],
	'vfzaduHSAsana':['Karṇa and Duḥśāsana'],
	'vfzaduHSAsanAdi':['beginning with Karṇa and Duḥśāsana'],
	'vfzan':['bull'],
	'veda':['knowledge, Vedic texts'],
	'vE':['verily'],
	'vESaMpAyana':['Vaiśaṁpāyana'],
	'vyaT':['tremble, fear'],
	'vyaTamAna':['tremble, fear'],
	'vyaTyamAna':['tremble, fear'],
	'vyaTizyamARa':['tremble, fear'],
	'vivyaTivas':['tremble, fear'],
	'vivyaTAna':['tremble, fear'],
	'vyaTita':['tremble, fear'],
	'vyaTitavat':['tremble, fear'],
	'vyaTitum':['tremble, fear'],
	'vyaTitvA':['tremble, fear'],
	'vyaTya':['tremble, fear'],
	'vyaTitavya':['tremble, fear'],
	'vyaTanIya':['tremble, fear'],
	'vyaTA':['trembling, fear'],
	'vyavasAyasahAya':['resolute'],
	'vyAsa':['Vyāsa'],
	'vraj':['walk'],
	'vrajat':['walk'],
	'vrajyamAna':['walk'],
	'vrajizyat':['walk'],
	'vrajizyamARa':['walk'],
	'vavrajivas':['walk'],
	'vavrajAna':['walk'],
	'vrajita':['walk'],
	'vrajitavat':['walk'],
	'vrajitum':['walk'],
	'vrajitvA':['walk'],
	'vrAjya':['walk'],
	'vrajitavya':['walk'],
	'vrajanIya':['walk'],
	'vrata':['vow'],
	'SaMtanu':['Śaṁtanu'],
	'Sak':['be able'],
	'Saknuvat':['be able'],
	'SakyamAna':['be able'],
	'Sakta':['be able'],
	'Saktavat':['be able'],
	'Saktum':['be able'],
	'SaktvA':['be able'],
	'Sakya':['be able'],
	'Saktavya':['be able'],
	'SakanIya':['be able'],
	'Sakuni':['Śakuni'],
	'Sakra':['able, capable, powerful', 'epithet of Indra'],
	'SacI':['strength', 'Śacī'],
	'Satru':['feller, enemy'],
	'SatruvaDa':['slaughter of enemies'],
	'Satruhan':['enemy-slayer'],
	'Sam':['peace, happiness', 'auspiciously, happily'],
	'Sastra':['sword'],
	'SastrapratApa':['blazing of swords'],
	'SAMtanava':['son of Śaṁtanu'],
	'SAlA':['hall'],
	'SAs':['rule, govern, teach'],
	'SAsat':['rule, govern, teach'],
	'SizyamARa':['rule, govern, teach'],
	'SAsizyat':['rule, govern, teach'],
	'SAsizyamARa':['rule, govern, teach'],
	'SaSAsvas':['rule, govern, teach'],
	'SaSAsAna':['rule, govern, teach'],
	'Sizwa':['rule, govern, teach'],
	'Sizwavat':['rule, govern, teach'],
	'SAsitum':['rule, govern, teach'],
	'SizwvA':['rule, govern, teach'],
	'SAsitvA':['rule, govern, teach'],
	'Sizya':['rule, govern, teach'],
	'SAsitavya':['rule, govern, teach'],
	'SAsanIya':['rule, govern, teach'],
	'SramB':['be careless, negligent'],
	'SramBamARa':['be careless, negligent'],
	'SraByamARa':['be careless, negligent'],
	'SramBizyamARa':['be careless, negligent'],
	'SaSramBARa':['be careless, negligent'],
	'SrabDa':['be careless, negligent'],
	'SrabDavat':['be careless, negligent'],
	'SramBitum':['be careless, negligent'],
	'SrabDvA':['be careless, negligent'],
	'SramBitvA':['be careless, negligent'],
	'SramBya':['be careless, negligent'],
	'SramBitavya':['be careless, negligent'],
	'SramBaRIya':['be careless, negligent'],
	'SrI':['radiance, glory, wealth'],
	'SrImat':['radiant, glorious'],
	'SrImanmahABArata':['the glorious epic <i>Mahābhārata</i>'],
	'SrIyaSas':['glory and fame'],
	'SrIyaSoBft':['bearing glory and fame'],
	'Sru':['hear, listen'],
	'SfRvat':['hear, listen'],
	'SrUyamARa':['hear, listen'],
	'Sruta':['hear, listen'],
	'Srutavat':['hear, listen'],
	'Srotum':['hear, listen'],
	'SrutvA':['hear, listen'],
	'Sravya':['hear, listen'],
	'Srotavya':['hear, listen'],
	'SravaRIya':['hear, listen'],
	'zawsUrya':['six-sunned'],
	'zaz':['six'],
	'saMkASa':['appearance'],
	'saMpanna':['endowed with', 'succeed, arise'],
	'saMvatsara':['year'],
	'saMvatsaragaRa':['many years'],
	'sac':['be associated with'],
	'saciva':['associate, counsellor, minister'],
	'sattama':['most excellent'],
	'satya':['true, honest', 'truth'],
	'satyavikrama':['truly powerful'],
	'satyavrata':['truth and vows'],
	'satyavrataparAyaRa':['devoted to truth and vows'],
	'sad':['sit, be sad'],
	'sIdat':['sit, be sad'],
	'sadyamAna':['sit, be sad'],
	'satsyat':['sit, be sad'],
	'satsyamAna':['sit, be sad'],
	'sedivas':['sit, be sad'],
	'sedAna':['sit, be sad'],
	'sanna':['sit, be sad'],
	'sannavat':['sit, be sad'],
	'sattum':['sit, be sad'],
	'sattvA':['sit, be sad'],
	'sAdya':['sit, be sad'],
	'sattavya':['sit, be sad'],
	'sadanIya':['sit, be sad'],
	'saptan':['seven'],
	'saBA':['hall'],
	'sam':['together'],
	'sam_A_car':['do, practice'],
	'samAcarat':['do, practice'],
	'samAcaramARa':['do, practice'],
	'samAcaryamARa':['do, practice'],
	'samAcarizyat':['do, practice'],
	'samAcarizyamARa':['do, practice'],
	'samAcerivas':['do, practice'],
	'samAcerARa':['do, practice'],
	'samAcarita':['do, practice'],
	'samAcaritavat':['do, practice'],
	'samAcaritum':['do, practice'],
	'samAcarya':['do, practice'],
	'samAcaritavya':['do, practice'],
	'samAcaraRIya':['do, practice'],
	'sam_A_ci':['cover or fill with'],
	'samAcinvat':['cover or fill with'],
	'samAcinvAna':['cover or fill with'],
	'samAcIyamARa':['cover or fill with'],
	'samAcezyat':['cover or fill with'],
	'samAcezyamARa':['cover or fill with'],
	'samAcikivas':['cover or fill with'],
	'samAcicivas':['cover or fill with'],
	'samAciyAna':['cover or fill with'],
	'samAcita':['cover or fill with'],
	'samAcitavat':['cover or fill with'],
	'samAcetum':['cover or fill with'],
	'samAcitya':['cover or fill with'],
	'samAceya':['cover or fill with'],
	'samAcetavya':['cover or fill with'],
	'samAcayanIya':['cover or fill with'],
	'sam_A_DA':['place together, compose, establish'],
	'samAdaDat':['place together, compose, establish'],
	'samAdaDAna':['place together, compose, establish'],
	'samADIyamAna':['place together, compose, establish'],
	'samADAsyat':['place together, compose, establish'],
	'samADAsyamAna':['place together, compose, establish'],
	'samAdaDivas':['place together, compose, establish'],
	'samAhita':['place together, compose, establish'],
	'samAhitavat':['place together, compose, establish'],
	'samADAtum':['place together, compose, establish'],
	'samADAya':['place together, compose, establish'],
	'samADeya':['place together, compose, establish'],
	'samADAtavya':['place together, compose, establish'],
	'samADAnIya':['place together, compose, establish'],
	'samADi':['putting together, completion, concentration, settled awareness'],
	'sam_Ap':['accomplish, complete'],
	'samApnuvat':['accomplish, complete'],
	'samApyamAna':['accomplish, complete'],
	'samApsyat':['accomplish, complete'],
	'samApsyamAna':['accomplish, complete'],
	'samApivas':['accomplish, complete'],
	'samApAna':['accomplish, complete'],
	'samApta':['accomplish, complete'],
	'samAptavat':['accomplish, complete'],
	'samAptum':['accomplish, complete'],
	'samApya':['accomplish, complete'],
	'samAptavya':['accomplish, complete'],
	'samApanIya':['accomplish, complete'],
	'sam_ud_sah':['be encouraged, endure, overcome; caus.: encourage, inspire'],
	'sam_kAS':['shine'],
	'sam_gam':['go together, unite, meet'],
	'saMgacCamAna':['go together, unite, meet'],
	'saMgamyamAna':['go together, unite, meet'],
	'saMgamizyamARa':['go together, unite, meet'],
	'saMjagmAna':['go together, unite, meet'],
	'saMgata':['go together, unite, meet'],
	'saMgatavat':['go together, unite, meet'],
	'saMgantum':['go together, unite, meet'],
	'saMgamya':['go together, unite, meet'],
	'saMgatya':['go together, unite, meet'],
	'saMgantavya':['go together, unite, meet'],
	'saMgamanIya':['go together, unite, meet'],
	'sam_Cid':['split, cut'],
	'saMCindat':['split, cut'],
	'saMCindAna':['split, cut'],
	'saMCidyamAna':['split, cut'],
	'saMCetsyat':['split, cut'],
	'saMCetsyamAna':['split, cut'],
	'saMcicCidvas':['split, cut'],
	'saMcicCidAna':['split, cut'],
	'saMCinna':['split, cut'],
	'saMCinnavat':['split, cut'],
	'saMCettum':['split, cut'],
	'saMCidya':['split, cut'],
	'saMCedya':['split, cut'],
	'saMCettavya':['split, cut'],
	'saMCedanIya':['split, cut'],
	'sam_DA':['put together'],
	'saMdaDat':['put together'],
	'saMdaDAna':['put together'],
	'saMDIyamAna':['put together'],
	'saMDAsyat':['put together'],
	'saMDAsyamAna':['put together'],
	'saMdaDivas':['put together'],
	'saMhita':['put together'],
	'saMhitavat':['put together'],
	'saMDAtum':['put together'],
	'saMDAya':['put together'],
	'saMDeya':['put together'],
	'saMDAtavya':['put together'],
	'saMDAnIya':['put together'],
	'sam_pad':['succeed, arise'],
	'saMpadyamAna':['succeed, arise'],
	'saMpatsyamAna':['succeed, arise'],
	'saMpedAna':['succeed, arise'],
	'saMpannavat':['succeed, arise'],
	'saMpattum':['succeed, arise'],
	'saMpadya':['succeed, arise'],
	'saMpAdya':['succeed, arise'],
	'saMpattavya':['succeed, arise'],
	'saMpadanIya':['succeed, arise'],
	'sam_pUj':['honor'],
	'saMpUjayamAna':['honor'],
	'saMpUjyamAna':['honor'],
	'saMpUjayizyamARa':['honor'],
	'saMpUjayAm':['honor'],
	'saMpUjita':['honor'],
	'saMpUjitavat':['honor'],
	'saMpUjayitum':['honor'],
	'saMpUjya':['honor'],
	'saMpUjitavya':['honor'],
	'saMpUjanIya':['honor'],
	'sam_pra_vac':['communicate, declare, relate in full'],
	'saMprocyamAna':['communicate, declare, relate in full'],
	'saMpravakzyat':['communicate, declare, relate in full'],
	'saMpravakzyamARa':['communicate, declare, relate in full'],
	'saMprocivas':['communicate, declare, relate in full'],
	'saMprocAna':['communicate, declare, relate in full'],
	'saMprokta':['communicate, declare, relate in full'],
	'saMproktavat':['communicate, declare, relate in full'],
	'saMpravaktum':['communicate, declare, relate in full'],
	'saMproktvA':['communicate, declare, relate in full'],
	'saMprocya':['communicate, declare, relate in full'],
	'saMpravAkya':['communicate, declare, relate in full'],
	'saMpravaktavya':['communicate, declare, relate in full'],
	'saMpravacanIya':['communicate, declare, relate in full'],
	'sam_BU':['come to be, arise, be possible'],
	'saMBavat':['come to be, arise, be possible'],
	'saMBUyamAna':['come to be, arise, be possible'],
	'saMBavizyat':['come to be, arise, be possible'],
	'saMBavizyamARa':['come to be, arise, be possible'],
	'saMbaBUvas':['come to be, arise, be possible'],
	'saMBUta':['come to be, arise, be possible'],
	'saMBUtavat':['come to be, arise, be possible'],
	'saMBavitum':['come to be, arise, be possible'],
	'saMBUya':['come to be, arise, be possible'],
	'saMBavya':['come to be, arise, be possible'],
	'saMBavitavya':['come to be, arise, be possible'],
	'saMBavanIya':['come to be, arise, be possible'],
	'sam_man':['agree, approve, esteem, respect'],
	'saMmanyamAna':['agree, approve, esteem, respect'],
	'saMmanvAna':['agree, approve, esteem, respect'],
	'saMmaMsyamAna':['agree, approve, esteem, respect'],
	'saMmenAna':['agree, approve, esteem, respect'],
	'saMmata':['agree, approve, esteem, respect'],
	'saMmatavat':['agree, approve, esteem, respect'],
	'saMmantum':['agree, approve, esteem, respect'],
	'saMmanya':['agree, approve, esteem, respect'],
	'saMmAnya':['agree, approve, esteem, respect'],
	'saMmantavya':['agree, approve, esteem, respect'],
	'saMmananIya':['agree, approve, esteem, respect'],
	'sam_mantr':['consult'],
	'saMmantrayamAna':['consult'],
	'saMmantryamAna':['consult'],
	'saMmantrayizyamARa':['consult'],
	'saMmantrayAm':['consult'],
	'saMmantrita':['consult'],
	'saMmantritavat':['consult'],
	'saMmantrayitum':['consult'],
	'saMmantrya':['consult'],
	'saMmantrayitavya':['consult'],
	'saMmantraRIya':['consult'],
	'sam_vf':['cover, conceal'],
	'saMvfRvat':['cover, conceal'],
	'saMvfRvAna':['cover, conceal'],
	'saMvriyamARa':['cover, conceal'],
	'saMvarizyat':['cover, conceal'],
	'saMvarIzyat':['cover, conceal'],
	'saMvarizyamARa':['cover, conceal'],
	'saMvarIzyamARa':['cover, conceal'],
	'saMvavfvas':['cover, conceal'],
	'saMvavrARa':['cover, conceal'],
	'saMvfta':['cover, conceal'],
	'saMvftavat':['cover, conceal'],
	'saMvaritum':['cover, conceal'],
	'saMvarItum':['cover, conceal'],
	'saMvftya':['cover, conceal'],
	'saMvaritavya':['cover, conceal'],
	'saMvarItavya':['cover, conceal'],
	'saMvaraRIya':['cover, conceal'],
	'sam_svap':['sleep'],
	'saMsvapat':['sleep'],
	'saMsupyamARa':['sleep'],
	'saMsvapsyat':['sleep'],
	'saMsvapsyamARa':['sleep'],
	'saMsuzupivas':['sleep'],
	'saMsuzupAna':['sleep'],
	'saMsupta':['sleep'],
	'saMsuptavat':['sleep'],
	'saMsvaptum':['sleep'],
	'saMsupya':['sleep'],
	'saMsvapya':['sleep'],
	'saMsvaptavya':['sleep'],
	'saMsvapanIya':['sleep'],
	'sarpa':['serpent, snake'],
	'sarva':['all, whole'],
	'sarvaratna':['all jewels'],
	'sarvaratnasamAcita':['covered with all jewels, filled with all wealth'],
	'sarvaloka':['all regions'],
	'sarvaSas':['wholly, in every way, everywhere'],
	'sah':['bear, endure, overcome'],
	'sahamAna':['bear, endure, overcome'],
	'sahyamAna':['bear, endure, overcome'],
	'sahizyamARa':['bear, endure, overcome'],
	'sehAna':['bear, endure, overcome'],
	'soQa':['bear, endure, overcome'],
	'soQavat':['bear, endure, overcome'],
	'soQum':['bear, endure, overcome'],
	'sahitum':['bear, endure, overcome'],
	'soQvA':['bear, endure, overcome'],
	'sahitvA':['bear, endure, overcome'],
	'sahya':['bear, endure, overcome'],
	'soQavya':['bear, endure, overcome'],
	'sahitavya':['bear, endure, overcome'],
	'sahanIya':['bear, endure, overcome'],
	'saha':['with'],
	'sahadeva':['Sahadeva'],
	'sahasObala':['with the son of Subala'],
	'sahAya':['companion'],
	'sahita':['accompanied by'],
	'su':['well'],
	'suKa':['happy', 'happiness'],
	'suKAvaha':['bringing happiness'],
	'sudurmati':['very weak-minded, very evil-minded'],
	'subala':['Subala', 'very powerful'],
	'suBadra':['very auspicious'],
	'suBadrA':['Subhadrā'],
	'suviBakta':['well distributed'],
	'suviBaktamahApaTa':['whose great roads are well-distributed'],
	'suhfjjana':['friendly people'],
	'suhfd':['friend', 'good-hearted, well-wishing'],
	'sUrya':['sun'],
	'sUryasaMkASa':['whose appearance is like the sun'],
	'senA':['army'],
	'so':['end, finish'],
	'syat':['end, finish'],
	'syamAna':['end, finish'],
	'sIyamAna':['end, finish'],
	'sAsyat':['end, finish'],
	'sAsyamAna':['end, finish'],
	'sasivas':['end, finish'],
	'sasAna':['end, finish'],
	'sita':['end, finish'],
	'sitavat':['end, finish'],
	'sAtum':['end, finish'],
	'sitvA':['end, finish'],
	'seya':['end, finish'],
	'sAtavya':['end, finish'],
	'sAnIya':['end, finish'],
	'sObala':['son of Subala'],
	'sTa':['standing'],
	'sTA':['stand, stay, be present'],
	'tizWat':['stand, stay, be present'],
	'tizWamAna':['stand, stay, be present'],
	'sTIyamAna':['stand, stay, be present'],
	'sTAsyat':['stand, stay, be present'],
	'sTAsyamAna':['stand, stay, be present'],
	'tasTivas':['stand, stay, be present'],
	'tasTAna':['stand, stay, be present'],
	'sTita':['stand, stay, be present'],
	'sTitavat':['stand, stay, be present'],
	'sTAtum':['stand, stay, be present'],
	'sTitvA':['stand, stay, be present'],
	'sTeya':['stand, stay, be present'],
	'sTAtavya':['stand, stay, be present'],
	'sTAnIya':['stand, stay, be present'],
	'sTApayitum':['stand, stay, be present'],
	'sTira':['steady, firm'],
	'spanda':['vibration, activity'],
	'sramB':['be confident, relax'],
	'sramBamARa':['be confident, relax'],
	'sraByamARa':['be confident, relax'],
	'sramBizyamARa':['be confident, relax'],
	'sasramBARa':['be confident, relax'],
	'srabDa':['be confident, relax'],
	'srabDavat':['be confident, relax'],
	'sramBitum':['be confident, relax'],
	'srabDvA':['be confident, relax'],
	'sramBitvA':['be confident, relax'],
	'sramBya':['be confident, relax'],
	'sramBitavya':['be confident, relax'],
	'sramBaRIya':['be confident, relax'],
	'sva':["one's own"],
	'svaka':["own, one's own"],
	'svap':['sleep'],
	'svapat':['sleep'],
	'supyamARa':['sleep'],
	'svapsyat':['sleep'],
	'svapsyamARa':['sleep'],
	'suzupivas':['sleep'],
	'suzupAna':['sleep'],
	'supta':['sleep'],
	'suptavat':['sleep'],
	'svaptum':['sleep'],
	'suptvA':['sleep'],
	'svapya':['sleep'],
	'svaptavya':['sleep'],
	'svapanIya':['sleep'],
	'svamandira':["one's own house, palace"],
	'svar':['sun, heaven'],
	'svarga':['heaven'],
	'svargasTa':['standing in, present in heaven'],
	'ha':['indeed', 'smiting'],
	'han':['smite', 'smiting'],
	'Gnat':['smite'],
	'hanyamAna':['smite'],
	'haMsyat':['smite'],
	'hanizyat':['smite'],
	'haMsyamAna':['smite'],
	'hanizyamARa':['smite'],
	'jaGnivas':['smite'],
	'jaGanvas':['smite'],
	'jaGnAna':['smite'],
	'hata':['smite'],
	'hatavat':['smite'],
	'hantum':['smite'],
	'hatvA':['smite'],
	'GAtya':['smite'],
	'hantavya':['smite'],
	'hananIya':['smite'],
	'havya':['oblation', 'offer', 'worship'],
	'havyavAhana':['oblation-bearer, fire'],
	'hasta':['hand'],
	'hastin':['having a hand', 'elephant', 'Hastin'],
	'hAstina':['founded by Hastin'],
	'hAstinapura':['Hāstinapura'],
	'hi':['for, because'],
	'hiqimba':['Hiḍimba'],
	'hu':['offer', 'worship'],
	'juhvat':['offer', 'worship'],
	'hUyamAna':['offer', 'worship'],
	'hozyat':['offer', 'worship'],
	'hozyamARa':['offer', 'worship'],
	'juhavAm':['offer', 'worship'],
	'huta':['offer', 'worship'],
	'hutavat':['offer', 'worship'],
	'hotum':['offer', 'worship'],
	'hutvA':['offer', 'worship'],
	'hotavya':['offer', 'worship'],
	'havanIya':['offer', 'worship'],
	'hfd':['heart'],
	'hfz':['be excited, rejoice'],
	'hfzIka':['sense-organ'],
	'hfzIkeSa':['lord of the senses'],
	'he':['O, hey']
}

