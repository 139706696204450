import React from 'react';

import Vigraha from './compounds/Vigraha';
import CompoundIdentification from './compounds/CompoundIdentification';
import CompoundSandhiAnalysis from './compounds/CompoundSandhiAnalysis';
import CompoundConstituents from './compounds/compoundConstituents';

import '../css/Components.css';

const CompoundQnA =({curQ, cxmlid, curPi,  
								setCurQComplete, setPartInst, type,
								// states required to implement autosave on next and previous buttons
								setAnsSaved ,btnClicked, setCompounds
						}) =>{
	
	const compoundPart =(curPart, takeInput) =>{
		switch(curPart){
			case "compound-identification":
				return <CompoundIdentification curQ={curQ} cxmlid={cxmlid} setPartInst={setPartInst} curPi={curPi}
							setCurQComplete={setCurQComplete} btnClicked={btnClicked} setAnsSaved={setAnsSaved}
							setCompounds={setCompounds} type={type}/>;
			case "compound-sandhi-analysis":
				return <CompoundSandhiAnalysis curQ={curQ} cxmlid={cxmlid} setPartInst={setPartInst} curPi={curPi}
							setCurQComplete={setCurQComplete} btnClicked={btnClicked} setAnsSaved={setAnsSaved}
							setCompounds={setCompounds} type={type}/>;
			case "compound-constituents":
				return <CompoundConstituents curQ={curQ} cxmlid={cxmlid} setPartInst={setPartInst} curPi={curPi}
							setCurQComplete={setCurQComplete} btnClicked={btnClicked} setAnsSaved={setAnsSaved}
							setCompounds={setCompounds} type={type}/>;
			case "vigraha":
				return <Vigraha curQ={curQ} cxmlid={cxmlid} setPartInst={setPartInst} curPi={curPi} 
							setCurQComplete={setCurQComplete} btnClicked={btnClicked} setAnsSaved={setAnsSaved}
							setCompounds={setCompounds} type={type}/>;
			default:
				if (takeInput)
					return "Work in progress ! Please come back later !";
				else
					return '';
		}
	}

	return (
		<div>
			<div className="QnA compoundVW">
				{compoundPart(curQ.curPart, curQ.takeInput)}
			</div>
		</div>
	);
};

export default CompoundQnA;