import React, {useState, useRef, useEffect, useContext, Fragment} from 'react';

import ImageFadeIn from "react-image-fade-in";
import {Button, Tooltip, Modal, OverlayTrigger} from "react-bootstrap";

import { Box, ShowSubCompound } from "../helpers/blocks";
import { UserContext } from "../../../providers/UserProvider";

import {vocab, pos} from '../helpers/data';
import CompoundTypeInterface from '../interfaces/CompoundTypeInterface';
import MorphologicalInterface from '../interfaces/MorphologicalInterface';
import LexCatInterface from '../interfaces/LexCatInterface';

import cross from '../helpers/cross.png';
import LoadingPage from "../helpers/LoadingPage";
import question from '../helpers/questionOrange.png';

import '../helpers/blocks.css';
import '../../css/Components.css';

const CompoundIdentification =({curQ, cxmlid, setPartInst, curPi, setCurQComplete,
												btnClicked, setAnsSaved, setCompounds, type}) =>{
	
	// To autosave when next or previous buttons are clicked
	useEffect(()=>{
		if (btnClicked===true){
			check(0, 'All');
			setAnsSaved(true);
		};
	}, [btnClicked]);

	const [loading, setLoading] =useState(false);

	const {currentUser} = useContext(UserContext);
	const [qText, setqText] =useState(curQ.text.question);
	// This is constructed in the backend (python) and passed as part of the curQ
	const [ansState, setAnsState] = useState(curQ.uans); 

	const [interfaceTitle, setInterfaceTitle] =useState('');
	const [interfaceIndex, setInterfaceIndex] =useState(0);
	
	// Interface control
	const [showInterface, setShowInterface] =useState(false);
	const [showCtypeInterface, setShowCtypeInterface] =useState(false);
	const [showLexCatInterface, setShowLexCatInterface] =useState(false);

	const interfaceOpen =useRef(null);

	// Show sub compound control
	const [show, setShow] =useState(false);
	const [tempCompounds, setTempCompounds] =useState({});

	useEffect(()=>{
		// console.log(showInterface, showLexCatInterface, showCtypeInterface);
		interfaceOpen.current =(showInterface||showLexCatInterface||showCtypeInterface);
	}, [showInterface, showLexCatInterface, showCtypeInterface]);

	const getBorderColor =(error, correct)=>{
		if (error)
			return 'red';
		if (correct)
			return 'green';
		return 'transparent';
	};
	
	const handleInputChange = (index, event, word) => {
		let values = [...ansState];
		switch(event.target.name){
			// Inflection
			case "inflection":
				values[index].inflection = event.target.value;
				if (values[index].inflection==='?' || values[index].inflection==='/'){
					setInterfaceTitle(word);
					setInterfaceIndex(index);
					setShowInterface(true);
				}
				break;
			case "lemma":
				values[index].lemma = event.target.value;
				break;
			// Identification
			case "pos":
				values[index].pos = event.target.value;
				if (values[index].pos==='?' || values[index].pos==='/'){
					setInterfaceTitle(word);
					setInterfaceIndex(index);
					setShowLexCatInterface(true);
				}
				break;
			case "cType":
				values[index].cType = event.target.value;
				if (values[index].cType==='?' || values[index].cType==='/'){
					setInterfaceTitle(word);
					setInterfaceIndex(index);
					setShowCtypeInterface(true);
				}
				break;
			default:
				break;
		}
		setAnsState(values);
	};

	// Checking the user's answers
	//checking box or all depending on checkType
	const check =(index, checkType) =>{	
		console.log("checking", checkType);
		console.log("values@", ansState[index]);

		const postObj = {
			method: 'POST',
			cache: 'no-cache',
			headers: {'Content-Type': 'application/json'},
			body: JSON.stringify({	uans: ansState, pi: curPi, email: currentUser.email, checkType: checkType,
											curPart: 'compound-identification',
											curQ: {'p':curQ.p, 'a':curQ.a, 'type':curQ.type, 'n':curQ.n, 's': curQ.s, 'ai':index,
													'parts': curQ.parts, 'text': curQ.text, 'xmlid': cxmlid, 'total':curQ.total}
										})
		}
	
		const getFeedback =async () => {
			// console.log('gettingFeedback()');
			setLoading(true);

			const response = await fetch(process.env.REACT_APP_FLASK_API+'check_uans', postObj);
			const data = await response.json();
			// console.log("data:", data.analysis.allComplete);

			setPartInst(data.analysis.inst);
			setqText(data.analysis.afterState[0]);
			setAnsState(data.analysis.afterState[1]);
			setCurQComplete(data.completed);

			setTempCompounds(data.analysis.compounds);

			console.log(data.analysis.newCompounds)
			if (data.analysis.newCompounds===true) {
				setShow(true);
			}

			setLoading(false);
		};
		getFeedback();
	};

	const [showPdf, setShowPdf] =useState(false);
	const [pdfFile, setPDFfile]=useState('agni.pdf');
	const pdfHelp =(word, message, pdf, part='input', blank=false) => {
		console.log(part, message, pdf)
		if (message.length===0)
			message ="Your "+part+" is incorrect."

		if (blank)
			return <OverlayTrigger
						placement="top"
						overlay={<Tooltip>Your {part} is blank.</Tooltip>}
					>
						<ImageFadeIn opacityTransition={3} style={{float:'right'}} 
							width={25} height={25} src={question} alt='empty'/>
					</OverlayTrigger>
		else if (pdf.length===0) {
			return <OverlayTrigger
						placement="top"
						overlay={<Tooltip>{message}</Tooltip>}
					>
						<ImageFadeIn opacityTransition={3} style={{float:'right'}} 
							width={25} height={25} src={cross} alt='wrong'/>
					</OverlayTrigger>
		}
		else {
			return (
				<>
					<Modal 
							show={showPdf} 
							onHide={()=>setShowPdf(false)} 
							dialogClassName="pdfmodal-90w"
					>
						<Modal.Header closeButton> 
							<div style={{display:'flex', justifyContent:'center', width:'100%'}}>
								<span style={{fontSize:'24pt', fontFamily:'Praja'}}>{word}</span>
							</div>
						</Modal.Header>
						<embed
							title="helpPdf"
							src={require("../../../docs/"+pdfFile)+"#zoom=150"}
							frameBorder="0"
							scrolling="auto"
							height="100%"
							width="100%"
						></embed>
					</Modal>

					<OverlayTrigger
						placement="top"
						overlay={<Tooltip>{message}</Tooltip>}
					>
						<ImageFadeIn opacityTransition={3} 
							style={{float:'right', cursor:'pointer'}} width={25} height={25}
							// setShowPdf to true
							onClick={()=>{setPDFfile(pdf);setShowPdf(true)}} src={cross} alt='wrong'/>
					</OverlayTrigger>
				</>
			);
		}
	}

	//Enter = Submit
	const [btn, setBtn]=useState(null)

	const handleEnter=(event)=>{
		if(event.key==='Enter' & !interfaceOpen.current){
			event.preventDefault();
			btn.click();
		}
	};
	useEffect(()=>{
		if (btn){
			// create an eventListener
			window.addEventListener('keydown', e=>handleEnter(e));
		}
	}, [btn])
	useEffect(() => {
		// Cleanup for evenListener upon unmounting
		return () => {
			window.removeEventListener('keydown', e=>handleEnter(e));
		};
	}, []);
	useEffect(()=>{
		console.log('changed: show=', show);
	}, [show])

	const getPlaceholder =(selected, temp, options=[])=>{
		// console.log(selected, temp, options);
		if (selected.length>0)
			return selected
		else if (options.length>1)
			return options[0].value+'?';
		else
			return temp;
	};

	return (
		<div>
			<LoadingPage loading={loading} />
			
			<ShowSubCompound show={show} setShow={setShow} 
				compounds={tempCompounds} setCompounds={setCompounds} />

			<MorphologicalInterface
				show={showInterface}
				onHide={()=>setShowInterface(false)}
				title={interfaceTitle}
				index={interfaceIndex}
				ansstate={ansState}
				setans={(newAns)=>setAnsState(newAns)}
			/>
			<LexCatInterface
				show={showLexCatInterface}
				onHide={()=>setShowLexCatInterface(false)}
				title={interfaceTitle}
				index={interfaceIndex}
				ansstate={ansState}
				setans={(newAns)=>setAnsState(newAns)}
			/>
			<CompoundTypeInterface 
				show={showCtypeInterface}
				onHide={()=>setShowCtypeInterface(false)}
				title={interfaceTitle}
				index={interfaceIndex}
				ansstate={ansState}
				setans={(newAns)=>setAnsState(newAns)}
			/>
			{curQ.takeInput &&
			<>
				<h5>
					<span className="blueText">Q:</span>
					<span className="praja32px"
						dangerouslySetInnerHTML={{__html: qText}} />
				</h5>
	
				<div className="FlexBox">
					<h5><span className="blueText">A:</span></h5>
					<div className="containerBox">
						{ansState.map((cur, index)=>(
							
							<Fragment key={`${cur.title}${index}`}>
								<Box borderColor={getBorderColor(cur.errorFound, cur.allCorrect)}>

								<div className='boxTitle' style={{fontFamily:'Praja', fontSize:'32px'}}>
									{cur.title}
									<div className="line" />
								</div>

								<div className="form-group">
									<label htmlFor={`"pos"${index}`} className="smText">Lexical Category
										{cur.posError===true&&
											<ImageFadeIn opacityTransition={3} style={{float:'right'}} 
											width={25} height={25} src={cross} alt='wrong'/>
										}
										{cur.posError==='?'&&
											<ImageFadeIn opacityTransition={3} style={{float:'right'}} 
											width={25} height={25} src={question} alt='blank'/>
										}
									</label>
									<input
										autoFocus={index===0}
										onFocus={e => {
											var val = e.target.value;
											e.target.value = '';
											e.target.value = val;
										}}
										
										type="text"
										autoComplete="off"
										className="form-control"

										name="pos"
										id={`"pos"${index}`}
										aria-describedby={`"posError"${index}`}
										
										placeholder="Second"
										value={cur.pos}
										onChange={(event) => handleInputChange(index, event, cur.title)}
									/>
								</div>

								<div className="form-group">
									<label htmlFor={`"cType"${index}`} className="smText">Compound Type
										{cur.cTypeError===true&&
											pdfHelp(cur.title, cur.help.cType.message, cur.help.cType.pdf, 'compound type')
										}
										{cur.cTypeError==='?'&&
											pdfHelp(cur.title, cur.help.cType.message, cur.help.cType.pdf, 'compound type', true)
										}
									</label>
									<input
										type="text"
										autoComplete="off"
										className="form-control"

										name="cType"
										id={`"cType"${index}`}
										aria-describedby={`"cTypeError"${index}`}
										
										placeholder="First"
										value={cur.cType}
										onChange={(event) => handleInputChange(index, event, cur.title)}
									/>
								</div>

								<Button variant='outline-primary' size='sm' style={{'float':'right', 'marginTop':'5px'}}
									onClick={()=>check(index, 'Box')}>Check
								</Button>

								</Box>
							</Fragment>
						))}
					</div>
				</div>
				{type !='lElement' && 
					<div className="KeepRight">
						<Button variant='primary' 
							onClick={()=>check(0, 'All')} 
							ref={node=>setBtn(node)}
						>
							Check all
						</Button>
					</div>
				}
				{type ==='lElement' && 
					<div className="KeepRight" style={{visibility:'hidden'}}>
						<Button variant='primary' 
							onClick={()=>check(0, 'All')} 
							ref={node=>setBtn(node)}
						>
							Check all
						</Button>
					</div>
				}

			</>
			}
		</div>
	);
};

export default CompoundIdentification;