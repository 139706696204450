import React, {useState} from 'react';
import {BrowserRouter as Router, Switch } from 'react-router-dom';

import Error from './components/Error';
import Home from './components/Home';
import Bhatti from './components/Bhatti';
import NavBar from './components/NavBar';
import Sidebar from './components/Sidebar';
import Profile from './components/Profile/Profile';

import Chapters from './components/lists/Chapters';
import Exercises from './components/lists/Exercises';
import Questions from './components/lists/Questions';
import OneQuestion from './components/OneQuestion';

// Students
import Students from './components/Students/Students';
import StudentChProgress from './components/Students/StudentChProgress';
import StudentExProgress from './components/Students/StudentExProgress';

import Login from './fireAuth/Login';
import ProtectedRoute from "./ProtectedRoute";
import ForgotPassword from './fireAuth/ForgotPassword';
// import  {UserContext} from "./providers/UserProvider";

import Alert from 'react-bootstrap/Alert';
import './App.css';

//To Do -
// index.js:1 Warning: Can't perform a React state update on an unmounted component. This is a no-op, but it indicates a memory leak in your application. To fix, cancel all subscriptions and asynchronous tasks in a useEffect cleanup function.
//     in Chapters (at ProtectedRoute.js:12)
//     in Route (at ProtectedRoute.js:9)
//     in ProtectedRoute (at App.js:73)
//     in Switch (at App.js:67)
//     in Router (created by BrowserRouter)
//     in BrowserRouter (at App.js:67)

const App = () => {
	// const {currentUser} = useContext(UserContext);
	const [show2, setShow2] = useState(false);
	
	window.addEventListener("onload", (e)=>{ 
		console.log('onload event=', e);
		window.querySelector("input").forEach(input => {
			input.setAttribute("autoComplete", "off");
		});
	});

	return (
		<div className="App">
			<NavBar />
			<Sidebar />

			{show2 &&
				<Alert className="alertMain" variant="success" onClose={() => setShow2(false)} dismissible>
					You are loggedIn !
				</Alert>}

			<div className="main">

				<Router><Switch>
					<Home exact path="/" component={Home} />
					<Bhatti exact path="/bhatti" component={Bhatti} />
					<Login exact path="/login" setShow2={setShow2} component ={Login} />
					<ForgotPassword exact path="/forgotPassword" component={ForgotPassword}/>
					
					<ProtectedRoute exact path="/profile" component={Profile} />
					<ProtectedRoute exact path="/parvans"  component={Chapters} />
					<ProtectedRoute exact path="/p=:p/a=:a"  component={Exercises} />
					<ProtectedRoute exact path="/p=:p/a=:a/:type/:n"  component={Questions} />
					<ProtectedRoute exact path="/p=:p/a=:a/:type/:n/:s"  component={OneQuestion} />

					{/* Students progress */}
					<ProtectedRoute exact path="/students" component={Students} />
					<ProtectedRoute exact path="/student=:email" component={StudentChProgress} />
					<ProtectedRoute exact path="/student=:email/ch=:ch" component={StudentExProgress} />

					<Error path="*" message="No such page"/>
				</Switch></Router>

			</div>
		</div>
	); 
	
}

export default App;