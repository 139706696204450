import React, { useState, useEffect, useContext } from 'react';

import Tooltip from 'react-bootstrap/Tooltip';
import Button from 'react-bootstrap/Button';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';

import Pagination from './Pagination';
import {UserContext} from '../../providers/UserProvider';

import '../css/Components.css';

const Questions = ({match}) =>{

	const {currentUser} =useContext(UserContext);

	const [done, setDone] = useState(false);
	const [restricted, setRestricted] = useState(false);
	const [msg, setMsg] =useState("Content Restricted");

	const [curEx, setCurrentExercise] = useState("");
	const [allQuestions, setQuestions] = useState([]);
	
	useEffect(() => {
		var postObj = {
			method: 'POST',
			cache: 'no-cache',
			headers: {'Content-Type': 'application/json'},
			body: JSON.stringify({p:match.params.p, a: match.params.a, 
											type: match.params.type, n: match.params.n, email:currentUser.email})
		}
		const getQuestions =async () => {
			const response = await fetch(process.env.REACT_APP_FLASK_API+'get_questionsList', postObj);
			const data = await response.json();
			setRestricted(data.restricted);
			setMsg(data.msg);

			setQuestions(data.list);
			setCurrentExercise(data.curEx);

			setDone(true);
		};
		getQuestions();
   }, [match.params.ch, match.params.i, currentUser.email]);

   	// Pagination
	const [currentPage, setCurrentPage] = useState(1);
	const objsPerPage = 10;
	// Get current list of quesitons
	const indexOfLastObj = currentPage * objsPerPage;
	const indexOfFirstObj = indexOfLastObj - objsPerPage;
	const currentQues = allQuestions.slice(indexOfFirstObj, indexOfLastObj);
	// Change Page
	const paginate =pageNumber => setCurrentPage(pageNumber);

	if (restricted){
		return(<h1>{msg}</h1>)
	}
   	if(done && allQuestions.length===0){
		return(<h1>No such exercise</h1>)
	}

	const explainColor =(ques) =>{
		return (
			<Tooltip style={{fontWeight:'bold'}}>
				{ques.variant==='primary' &&
					"Unattempted"
				}
				{ques.variant==='warning' &&
					"Incorrect or incomplete"
				}
				{ques.variant==='success' &&
					"Answered correctly"
				}
			</Tooltip>
		);
	};
	
	return (
		<div>
			<Breadcrumb className='breadcrumbs'>
 				<Breadcrumb.Item href={ `/p=${match.params.p}/a=${match.params.a}`}><span style={{fontFamily:'praja', fontSize:'22pt'}}>अध्याय </span>{`${match.params.a}`}</Breadcrumb.Item>
 				<Breadcrumb.Item style={{paddingTop:'8px'}}  href="#" active>{curEx}</Breadcrumb.Item>
 			</Breadcrumb>

			<div className="widthVW d-grid gap-2 mb-3">
				{currentQues.map(ques => {
					
					return (
						<OverlayTrigger
							placement='top'
							key={ques.index}
							overlay={explainColor(ques)}
						>
							<Button style={{textAlign: "left"}} size="lg"
									variant={ques.variant} disabled={ques.disabled}
									// /p=:p/a=:a/:type/:n/:i
									
									href={`/p=${match.params.p}/a=${match.params.a}/${ques.type}/${ques.n}/${ques.s}`}>
								
								<span>{ques.title}</span>
							</Button>
						</OverlayTrigger>
				)})}
			</div>

			<Pagination objsPerPage={objsPerPage} totalObjs={allQuestions.length} paginate={paginate}/>
		
		</div>
	);
};

export default Questions;