import firebase from "firebase/app";
import "firebase/auth";
// import "firebase/firestore";

const firebaseConfig = {
	apiKey: "AIzaSyBCFBSM5L3qqhmxgBcdDlXk8nnefLN4eAc",
	authDomain: "mahabharata-b2796.firebaseapp.com",
	projectId: "mahabharata-b2796",
	storageBucket: "mahabharata-b2796.appspot.com",
	messagingSenderId: "449519622468",
	appId: "1:449519622468:web:a5838839e4e576f8e85f11",
	measurementId: "G-D1WWS1HGX2"
};
firebase.initializeApp(firebaseConfig);

export const auth = firebase.auth();
// export const firestore = firebase.firestore();

// Second app instance to create new users without signing them in
export const appImage =firebase.initializeApp(firebaseConfig, 'Secondary').auth();