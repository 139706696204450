import React, { useState } from 'react';
import {Button, Toast, Modal} from 'react-bootstrap';

import chroma from "chroma-js";
import styled from 'styled-components';

import '../../css/Components.css';

const Box =styled.div`
	align-self: flex-start;
	min-width: var(--boxWidth);
	max-width: var(--boxWidth);
	// min-height: 40vh;
	// overflow-y: scroll;
	padding: 0.25em;
	margin-right: 0.5em;
	margin-bottom: 0.5em;
	background: white;
	border: solid ${props=>props.borderColor};
`;

const RowBox =styled.div`
	// min-width: 300px;
	// max-width: 300px;
	// min-height: 40vh;
	// overflow-y: scroll;
	padding: 0.25em;
	margin-right: 0.5em;
	margin-bottom: 0.5em;
	background: white;
	border: solid ${props=>props.borderColor};

	display: flex;
	flex-flow: row wrap;
`;

const SingleInflection=styled.div`
	min-width: 110px;
	max-width: 110px;
	border: solid 1px ${props=>props.borderColor};
	margin: 0px 5px 4px 0px;	
`;

const colorStyles = {
	control: (styles) => ({ ...styles, backgroundColor: "white" }),
	option: (styles, { data, isFocused, isSelected }) => {
		const color = data.isLemma ?chroma('#007BFF') : chroma('#FF8400');
		return {
			...styles,

			backgroundColor: isSelected
			? color.alpha(0.8).css()
			: isFocused
			? color.alpha(0.5).css()
			: color.alpha(0.1).css(),

			color: isSelected
			? 'white'
			: 'black',

			":active": {
				...styles[":active"],

				backgroundColor:
					isSelected ? color.alpha(0.9).css() : color.alpha(0.3).css()
			}
		};
	},
	menu: base => ({
		...base,
		// override border radius to match the box
		borderRadius: 0,
		// beautify the word cut by adding a dash see https://caniuse.com/#search=hyphens for the compatibility
		hyphens: "auto",
		// kill the gap
		marginTop: 0,
		textAlign: "left",
		// prevent menu to scroll y
		wordWrap: "break-word", 
		//z-index
		zIndex: 100,
		position: "absolute"
	}),
	menuList: base => ({
		...base,
		// kill the white space on first and last option
		padding: 0
	}),
	input: styles => ({ ...styles, minWidth: "200px", maxWidth:"300px" }),
 	placeholder: styles => ({ ...styles }),
	singleValue: (styles, { data }) => ({ ...styles })
	// menuPortal: (base) => ({ ...base, zIndex: 9999 }) 
};

const getBorderColor =(error, correct)=>{
	if (error)
		return 'red';
	
	if (correct)
		return 'green';

	return 'transparent';
};

//Enter = Submit
const keyListener=(e, btn)=>{
	// console.log('key=', e.key);
	if(e.key==='Enter'){
		e.preventDefault();
		// console.log('enter pressing', btn);
		btn.click();
	};
};

//delay
const delay =(ms) => new Promise(
	resolve => setTimeout(resolve, ms)
);

// Toast for interface (verb and morphological) errors
const InterfaceError =({showInterfaceError, setShowInterfaceError}) =>(
	<div className='toastContainer' hidden={!showInterfaceError}>
		<Toast show={showInterfaceError} bg='primary' className='toast'
				onClose={()=>setShowInterfaceError(false)}
		>
			<Toast.Header closeButton={true}>
				<p className='m-auto'>Incomplete input</p>
			</Toast.Header>
			<Toast.Body className='toastBody'>Select an option for each category</Toast.Body>
		</Toast>
	</div>
);

// Show syntax tree
const ShowTree =({xmlid, completed=''}) =>{
	const [show, setShow] =useState(false);
	let treesvg ='';
	if (completed==='all')
		treesvg =`${process.env.PUBLIC_URL}/trees/full/${xmlid}.svg`
	else
		treesvg=`${process.env.PUBLIC_URL}/trees/primary/${xmlid}.svg`

	return (
		<>
			<Modal show={show} onHide={()=>setShow(false)}
						centered ='true'
						dialogClassName ='treeDialog'
						contentClassName ='treeContent' 
			>
				
				<img src={treesvg} alt='tree' />
			</Modal>
			
			<Button 
				variant={completed===''?"outline-secondary":"outline-primary"}
				style={{
					position:'absolute', top:'10px', right:'10px'
				}}
				disabled ={completed===''}
				onClick={()=>setShow(true)}
			>Syntax tree</Button>
		</>
	);
};

// Compound reload confirmation box
const ShowSubCompound =({show, setShow, compounds, setCompounds}) =>{
	return (
		<Modal show={show} onHide={()=>{setShow(false)}}>
			<Modal.Header closeButton>
				<Modal.Title>साधु</Modal.Title>
			</Modal.Header>
			<Modal.Body>Click the show button to see sub-compounds.</Modal.Body>
			<Modal.Footer>
				<Button variant="primary" onClick={()=>{setCompounds(compounds); console.log('clicked')}}>
					Show
				</Button>
			</Modal.Footer>
      </Modal>
	);
};

export {
			RowBox, Box, SingleInflection, colorStyles,
			getBorderColor, keyListener, delay, InterfaceError,
			ShowTree, ShowSubCompound
		};