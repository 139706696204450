import React, {useState, useEffect, useContext} from 'react';

import Title from './Title';
import Error from '../Error';
// import Pagination from './Pagination';
import {UserContext} from '../../providers/UserProvider';

const Exercises=({ match }) => {

	const {currentUser} =useContext(UserContext);

	const [done, setDone] = useState(false);
	const [restricted, setRestricted] = useState(false);
	const [msg, setMsg] =useState("Content Restricted");

	const [allExercises, setExercises] = useState([]);
	
	useEffect(() => {
		const postObj = {
			method: 'POST',
			cache: 'no-cache',
			headers: {'Content-Type': 'application/json'},
			body: JSON.stringify({p:match.params.p, a: match.params.a, email: currentUser.email})
		}
		const getExercises =async () => {
			const response = await fetch(process.env.REACT_APP_FLASK_API+"get_exercisesList", postObj);
			const data = await response.json();
			setRestricted(data.restricted);
			setMsg(data.msg);

			setExercises(data.list);
			setDone(true);
		};

		getExercises();
   }, [match.params.a, currentUser.email]);

   	// // Pagination
	// const [currentPage, setCurrentPage] = useState(1);
	// const objsPerPage = 5;

	// // Get current Title
	// const indexOfLastObj = currentPage * objsPerPage;
	// const indexOfFirstObj = indexOfLastObj - objsPerPage;
	// const currentObjs = allExercises.slice(indexOfFirstObj, indexOfLastObj);

	// // Change Page
	// const paginate =pageNumber => setCurrentPage(pageNumber);

	if (restricted){
		return <Error message={msg}/>
	}

	if (done && allExercises.length===0){
		return <Error message={`No Chapter ${match.params.a}`}/>
   }
  
	return (
		<div>
			<div style={{textAlign:'center', width:'100%'}}>
				<h4><span style={{fontFamily:'praja', fontSize:'30pt'}}>अध्याय </span>{`${match.params.a}`}</h4>
			</div>

			<div className="titleContainer chScroll">
				{allExercises.map((exercise, index) => {
					var goto =  `/p=${match.params.p}/a=${match.params.a}/${exercise.type}/${exercise.n}`

					if (['head', 'trailer', 'speaker'].includes(exercise.type)) {
						goto =  `/p=${match.params.p}/a=${match.params.a}/${exercise.type}/${exercise.n}/1`
					}
					return (
						<span key={index}>
							<Title title={exercise.title} progress={exercise.progress} to={goto}/>
						</span>
					)
				})}
			</div>

			{/* <Pagination objsPerPage={objsPerPage} totalObjs={allExercises.length} paginate={paginate}/> */}
		</div>
	);
};

export default Exercises;