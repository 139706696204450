export const vocab_roman = [
	{'value': 'akliṣṭa', 'label': 'aklizwa', 'attrib': [{'pos': 'adj', 'defs': 'unafflicted, untarnished'}], 'isLemma': 1},
	{'value': 'akliṣṭakarman', 'label': 'aklizwakarman', 'attrib': [{'pos': 'adj', 'defs': 'of untarnished deeds'}], 'isLemma': 1},
	{'value': 'akṣa', 'label': 'akza', 'attrib': [{'pos': 'm', 'defs': 'a die for gambling'}], 'isLemma': 1},
	{'value': 'akṣaya', 'label': 'akzaya', 'attrib': [{'pos': 'adj', 'defs': 'imperishable, inexhaustible'}], 'isLemma': 1},
	{'value': 'agni', 'label': 'agni', 'attrib': [{'pos': 'm', 'defs': 'fire'}], 'isLemma': 1},
	{'value': 'aṅga', 'label': 'aNga', 'attrib': [{'pos': 'n', 'defs': 'limb, body'}, {'pos': 'm', 'defs': 'Bengal'}], 'isLemma': 1},
	{'value': 'aṅgadeśa', 'label': 'aNgadeSa', 'attrib': [{'pos': 'm', 'defs': 'place on the body'}], 'isLemma': 1},
	{'value': 'acira', 'label': 'acira', 'attrib': [{'pos': 'adj', 'defs': 'not a long time'}], 'isLemma': 1},
	{'value': 'ajñāta', 'label': 'ajYAta', 'attrib': [{'pos': 'adj', 'defs': 'unknown'}], 'isLemma': 1},
	{'value': 'añc', 'label': 'aYc', 'attrib': [{'pos': 'v1am', 'defs': 'bend'}], 'isLemma': 1},
	{'value': 'ati', 'label': 'ati', 'attrib': [{'pos': 'preverb', 'defs': 'beyond'}], 'isLemma': 1},
	{'value': 'atibhāra', 'label': 'atiBAra', 'attrib': [{'pos': 'm', 'defs': 'an excessive burden'}], 'isLemma': 1},
	{'value': 'attavya', 'label': 'attavya', 'attrib': [{'pos': 'ger', 'defs': 'eat'}], 'isLemma': 0, 'parents': ['ad']},
	{'value': 'attum', 'label': 'attum', 'attrib': [{'pos': 'inf', 'defs': 'eat'}], 'isLemma': 0, 'parents': ['ad']},
	{'value': 'attvā', 'label': 'attvA', 'attrib': [{'pos': 'abs', 'defs': 'eat'}], 'isLemma': 0, 'parents': ['ad']},
	{'value': 'atsyat', 'label': 'atsyat', 'attrib': [{'pos': 'fap', 'defs': 'eat'}], 'isLemma': 0, 'parents': ['ad']},
	{'value': 'atsyamāna', 'label': 'atsyamAna', 'attrib': [{'pos': 'fpp', 'defs': 'eat'}], 'isLemma': 0, 'parents': ['ad']},
	{'value': 'atha', 'label': 'aTa', 'attrib': [{'pos': 'pcl', 'defs': 'now, then'}], 'isLemma': 1},
	{'value': 'ad', 'label': 'ad', 'attrib': [{'pos': 'vt2a', 'defs': 'eat'}], 'isLemma': 1},
	{'value': 'adat', 'label': 'adat', 'attrib': [{'pos': 'prap', 'defs': 'eat'}], 'isLemma': 0, 'parents': ['ad']},
	{'value': 'adanīya', 'label': 'adanIya', 'attrib': [{'pos': 'ger', 'defs': 'eat'}], 'isLemma': 0, 'parents': ['ad']},
	{'value': 'adyamāna', 'label': 'adyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'eat'}], 'isLemma': 0, 'parents': ['ad']},
	{'value': 'adhi', 'label': 'aDi', 'attrib': [{'pos': 'preverb', 'defs': 'over'}], 'isLemma': 1},
	{'value': 'adhijagāna', 'label': 'aDijagAna', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'go over, study, read, recite'}], 'isLemma': 0, 'parents': ['aDi_i']},
	{'value': 'adhi_i', 'label': 'aDi_i', 'attrib': [{'pos': 'vt2m', 'defs': 'go over, study, read, recite'}], 'isLemma': 1},
	{'value': 'adhīta', 'label': 'aDIta', 'attrib': [{'pos': 'ppp', 'defs': 'go over, study, read, recite'}], 'isLemma': 0, 'parents': ['aDi_i']},
	{'value': 'adhītavat', 'label': 'aDItavat', 'attrib': [{'pos': 'pap', 'defs': 'go over, study, read, recite'}], 'isLemma': 0, 'parents': ['aDi_i']},
	{'value': 'adhītya', 'label': 'aDItya', 'attrib': [{'pos': 'abs', 'defs': 'go over, study, read, recite'}], 'isLemma': 0, 'parents': ['aDi_i']},
	{'value': 'adhīyamāna', 'label': 'aDIyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'go over, study, read, recite'}], 'isLemma': 0, 'parents': ['aDi_i']},
	{'value': 'adhīyāna', 'label': 'aDIyAna', 'attrib': [{'pos': 'prmp', 'defs': 'go over, study, read, recite'}], 'isLemma': 0, 'parents': ['aDi_i']},
	{'value': 'adhyayanīya', 'label': 'aDyayanIya', 'attrib': [{'pos': 'ger', 'defs': 'go over, study, read, recite'}], 'isLemma': 0, 'parents': ['aDi_i']},
	{'value': 'adhyāya', 'label': 'aDyAya', 'attrib': [{'pos': 'm', 'defs': 'chapter'}], 'isLemma': 1},
	{'value': 'adhyetavya', 'label': 'aDyetavya', 'attrib': [{'pos': 'ger', 'defs': 'go over, study, read, recite'}], 'isLemma': 0, 'parents': ['aDi_i']},
	{'value': 'adhyetum', 'label': 'aDyetum', 'attrib': [{'pos': 'inf', 'defs': 'go over, study, read, recite'}], 'isLemma': 0, 'parents': ['aDi_i']},
	{'value': 'adhyeya', 'label': 'aDyeya', 'attrib': [{'pos': 'ger', 'defs': 'go over, study, read, recite'}], 'isLemma': 0, 'parents': ['aDi_i']},
	{'value': 'adhyeṣyamāṇa', 'label': 'aDyezyamARa', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'go over, study, read, recite'}], 'isLemma': 0, 'parents': ['aDi_i']},
	{'value': 'anu', 'label': 'anu', 'attrib': [{'pos': 'preverb', 'defs': 'after'}], 'isLemma': 1},
	{'value': 'anucintita', 'label': 'anucintita', 'attrib': [{'pos': 'ppp', 'defs': 'consider'}], 'isLemma': 0, 'parents': ['anu_cint']},
	{'value': 'anuja', 'label': 'anuja', 'attrib': [{'pos': 'adj', 'defs': 'born after'}, {'pos': 'm', 'defs': 'younger brother'}], 'isLemma': 1},
	{'value': 'anujajñāna', 'label': 'anujajYAna', 'attrib': [{'pos': 'pfpp', 'defs': 'consent, permit'}], 'isLemma': 0, 'parents': ['anu_jYA']},
	{'value': 'anujajñivas', 'label': 'anujajYivas', 'attrib': [{'pos': 'pfap', 'defs': 'consent, permit'}], 'isLemma': 0, 'parents': ['anu_jYA']},
	{'value': 'anujā', 'label': 'anujA', 'attrib': [{'pos': 'f', 'defs': 'younger sister'}], 'isLemma': 1},
	{'value': 'anujānat', 'label': 'anujAnat', 'attrib': [{'pos': 'prap', 'defs': 'consent, permit'}], 'isLemma': 0, 'parents': ['anu_jYA']},
	{'value': 'anujñāta', 'label': 'anujYAta', 'attrib': [{'pos': 'ppp', 'defs': 'consent, permit'}], 'isLemma': 0, 'parents': ['anu_jYA']},
	{'value': 'anujñātavat', 'label': 'anujYAtavat', 'attrib': [{'pos': 'pap', 'defs': 'consent, permit'}], 'isLemma': 0, 'parents': ['anu_jYA']},
	{'value': 'anujñātavya', 'label': 'anujYAtavya', 'attrib': [{'pos': 'ger', 'defs': 'consent, permit'}], 'isLemma': 0, 'parents': ['anu_jYA']},
	{'value': 'anujñātum', 'label': 'anujYAtum', 'attrib': [{'pos': 'inf', 'defs': 'consent, permit'}], 'isLemma': 0, 'parents': ['anu_jYA']},
	{'value': 'anujñānīya', 'label': 'anujYAnIya', 'attrib': [{'pos': 'ger', 'defs': 'consent, permit'}], 'isLemma': 0, 'parents': ['anu_jYA']},
	{'value': 'anujñāpya', 'label': 'anujYApya', 'attrib': [{'pos': 'abs', 'defs': 'consent, permit'}], 'isLemma': 0, 'parents': ['anu_jYA']},
	{'value': 'anujñāya', 'label': 'anujYAya', 'attrib': [{'pos': 'abs', 'defs': 'consent, permit'}], 'isLemma': 0, 'parents': ['anu_jYA']},
	{'value': 'anujñāyamāna', 'label': 'anujYAyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'consent, permit'}], 'isLemma': 0, 'parents': ['anu_jYA']},
	{'value': 'anujñāsyat', 'label': 'anujYAsyat', 'attrib': [{'pos': 'fap', 'defs': 'consent, permit'}], 'isLemma': 0, 'parents': ['anu_jYA']},
	{'value': 'anujñāsyamāna', 'label': 'anujYAsyamAna', 'attrib': [{'pos': 'fpp', 'defs': 'consent, permit'}], 'isLemma': 0, 'parents': ['anu_jYA']},
	{'value': 'anujñeya', 'label': 'anujYeya', 'attrib': [{'pos': 'ger', 'defs': 'consent, permit'}], 'isLemma': 0, 'parents': ['anu_jYA']},
	{'value': 'anu_cint', 'label': 'anu_cint', 'attrib': [{'pos': 'vt10am', 'defs': 'consider'}], 'isLemma': 1},
	{'value': 'anu_jan', 'label': 'anu_jan', 'attrib': [{'pos': 'vi4m', 'defs': 'be born after'}], 'isLemma': 1},
	{'value': 'anu_jñā', 'label': 'anu_jYA', 'attrib': [{'pos': 'vt9a', 'defs': 'consent, permit'}], 'isLemma': 1},
	{'value': 'anna', 'label': 'anna', 'attrib': [{'pos': 'ppp', 'defs': 'eat'}, {'pos': 'n', 'defs': 'food'}], 'isLemma': 1},
	{'value': 'anna', 'label': 'anna', 'attrib': [{'pos': 'ppp', 'defs': 'eat'}, {'pos': 'n', 'defs': 'food'}], 'isLemma': 0, 'parents': ['ad']},
	{'value': 'annavat', 'label': 'annavat', 'attrib': [{'pos': 'pap', 'defs': 'eat'}], 'isLemma': 0, 'parents': ['ad']},
	{'value': 'anya', 'label': 'anya', 'attrib': [{'pos': 'pron, pron_adj', 'defs': 'other'}], 'isLemma': 1},
	{'value': 'api', 'label': 'api', 'attrib': [{'pos': 'pcl', 'defs': 'even'}], 'isLemma': 1},
	{'value': 'apramatta', 'label': 'apramatta', 'attrib': [{'pos': 'adj', 'defs': 'not careless, careful'}], 'isLemma': 1},
	{'value': 'apramattotthita', 'label': 'apramattotTita', 'attrib': [{'pos': 'adj', 'defs': 'risen carefully'}], 'isLemma': 1},
	{'value': 'amita', 'label': 'amita', 'attrib': [{'pos': 'adj', 'defs': 'unmeasured, infinite'}], 'isLemma': 1},
	{'value': 'amitatejas', 'label': 'amitatejas', 'attrib': [{'pos': 'adj', 'defs': 'infinitely brilliant'}], 'isLemma': 1},
	{'value': 'amitaujas', 'label': 'amitOjas', 'attrib': [{'pos': 'adj', 'defs': 'infinitely vigorous'}], 'isLemma': 1},
	{'value': 'ayanīya', 'label': 'ayanIya', 'attrib': [{'pos': 'ger', 'defs': 'go'}], 'isLemma': 0, 'parents': ['i']},
	{'value': 'ari', 'label': 'ari', 'attrib': [{'pos': 'm', 'defs': 'enemy'}], 'isLemma': 1},
	{'value': 'ariṁdama', 'label': 'ariMdama', 'attrib': [{'pos': 'm', 'defs': 'enemy tamer'}], 'isLemma': 1},
	{'value': 'arjuna', 'label': 'arjuna', 'attrib': [{'pos': 'm_pn', 'defs': 'Arjuna'}], 'isLemma': 1},
	{'value': 'artha', 'label': 'arTa', 'attrib': [{'pos': 'm', 'defs': 'aim, object'}], 'isLemma': 1},
	{'value': 'ava', 'label': 'ava', 'attrib': [{'pos': 'preverb', 'defs': 'down'}], 'isLemma': 1},
	{'value': 'ava_dhā', 'label': 'ava_DA', 'attrib': [{'pos': 'vt3am', 'defs': 'put down, deposit, apply'}], 'isLemma': 1},
	{'value': 'avaso', 'label': 'avaso', 'attrib': [{'pos': 'v4a', 'defs': 'end, finish'}], 'isLemma': 1},
	{'value': 'avahita', 'label': 'avahita', 'attrib': [{'pos': 'ppp', 'defs': 'put down, deposit, apply'}, {'pos': 'adj', 'defs': 'attentive'}], 'isLemma': 1},
	{'value': 'avahita', 'label': 'avahita', 'attrib': [{'pos': 'ppp', 'defs': 'put down, deposit, apply'}, {'pos': 'adj', 'defs': 'attentive'}], 'isLemma': 0, 'parents': ['ava_DA']},
	{'value': 'as', 'label': 'as', 'attrib': [{'pos': 'vi2a', 'defs': 'be'}], 'isLemma': 1},
	{'value': 'asura', 'label': 'asura', 'attrib': [{'pos': 'm', 'defs': 'demon'}], 'isLemma': 1},
	{'value': 'asmad', 'label': 'asmad', 'attrib': [{'pos': 'pers_pron', 'defs': 'we, us'}], 'isLemma': 1},
	{'value': 'ahita', 'label': 'ahita', 'attrib': [{'pos': 'adj', 'defs': 'not good'}, {'pos': 'n', 'defs': 'enemy'}], 'isLemma': 1},
	{'value': 'ā', 'label': 'A', 'attrib': [{'pos': 'preverb', 'defs': 'unto'}], 'isLemma': 1},
	{'value': 'ā_i', 'label': 'A_i', 'attrib': [{'pos': 'vt2a', 'defs': 'come'}], 'isLemma': 1},
	{'value': 'ā_gam', 'label': 'A_gam', 'attrib': [{'pos': 'v1a', 'defs': 'come'}], 'isLemma': 1},
	{'value': 'ā_car ', 'label': 'A_car ', 'attrib': [{'pos': 'vt1a', 'defs': 'move, do'}], 'isLemma': 1},
	{'value': 'ā_ci', 'label': 'A_ci', 'attrib': [{'pos': 'vt5am', 'defs': 'accumulate'}], 'isLemma': 1},
	{'value': 'ā_dā', 'label': 'A_dA', 'attrib': [{'pos': 'vt3am', 'defs': 'receive, take'}], 'isLemma': 1},
	{'value': 'ā_diś', 'label': 'A_diS', 'attrib': [{'pos': 'vt6am', 'defs': 'point out, assign, order'}], 'isLemma': 1},
	{'value': 'ā_dhā', 'label': 'A_DA', 'attrib': [{'pos': 'vt3am', 'defs': 'put on, apply'}], 'isLemma': 1},
	{'value': 'ā_dhī', 'label': 'A_DI', 'attrib': [{'pos': 'vt2m', 'defs': 'recite, read, study'}], 'isLemma': 1},
	{'value': 'ā_bhā', 'label': 'A_BA', 'attrib': [{'pos': 'vi2a', 'defs': 'shine, appear'}], 'isLemma': 1},
	{'value': 'ā_rabh', 'label': 'A_raB', 'attrib': [{'pos': 'vt1m', 'defs': 'begin'}], 'isLemma': 1},
	{'value': 'ā_vah', 'label': 'A_vah', 'attrib': [{'pos': 'vt1am', 'defs': 'carry towards, bring'}], 'isLemma': 1},
	{'value': 'ā_vraj', 'label': 'A_vraj', 'attrib': [{'pos': 'vt1a', 'defs': 'walk to'}], 'isLemma': 1},
	{'value': 'ātta', 'label': 'Atta', 'attrib': [{'pos': 'ppp', 'defs': 'receive, take'}], 'isLemma': 0, 'parents': ['A_dA']},
	{'value': 'ādattavat', 'label': 'Adattavat', 'attrib': [{'pos': 'pap', 'defs': 'receive, take'}], 'isLemma': 0, 'parents': ['A_dA']},
	{'value': 'ādadat', 'label': 'Adadat', 'attrib': [{'pos': 'prap', 'defs': 'receive, take'}], 'isLemma': 0, 'parents': ['A_dA']},
	{'value': 'ādadāna', 'label': 'AdadAna', 'attrib': [{'pos': 'prmp', 'defs': 'receive, take'}, {'pos': 'pfmp, pfpp', 'defs': 'receive, take'}], 'isLemma': 0, 'parents': ['A_dA']},
	{'value': 'ādadivas', 'label': 'Adadivas', 'attrib': [{'pos': 'pfap', 'defs': 'receive, take'}], 'isLemma': 0, 'parents': ['A_dA']},
	{'value': 'ādadhat', 'label': 'AdaDat', 'attrib': [{'pos': 'prap', 'defs': 'put on, apply'}], 'isLemma': 0, 'parents': ['A_DA']},
	{'value': 'ādadhāna', 'label': 'AdaDAna', 'attrib': [{'pos': 'prmp', 'defs': 'put on, apply'}, {'pos': 'pfmp, pfpp', 'defs': 'put on, apply'}], 'isLemma': 0, 'parents': ['A_DA']},
	{'value': 'ādadhivas', 'label': 'AdaDivas', 'attrib': [{'pos': 'pfap', 'defs': 'put on, apply'}], 'isLemma': 0, 'parents': ['A_DA']},
	{'value': 'ādātavya', 'label': 'AdAtavya', 'attrib': [{'pos': 'ger', 'defs': 'receive, take'}], 'isLemma': 0, 'parents': ['A_dA']},
	{'value': 'ādātum', 'label': 'AdAtum', 'attrib': [{'pos': 'inf', 'defs': 'receive, take'}], 'isLemma': 0, 'parents': ['A_dA']},
	{'value': 'ādāna', 'label': 'AdAna', 'attrib': [{'pos': 'pfpp', 'defs': 'eat'}], 'isLemma': 0, 'parents': ['ad']},
	{'value': 'ādānīya', 'label': 'AdAnIya', 'attrib': [{'pos': 'ger', 'defs': 'receive, take'}], 'isLemma': 0, 'parents': ['A_dA']},
	{'value': 'ādāya', 'label': 'AdAya', 'attrib': [{'pos': 'abs', 'defs': 'receive, take'}], 'isLemma': 0, 'parents': ['A_dA']},
	{'value': 'ādāsyat', 'label': 'AdAsyat', 'attrib': [{'pos': 'fap', 'defs': 'receive, take'}], 'isLemma': 0, 'parents': ['A_dA']},
	{'value': 'ādāsyamāna', 'label': 'AdAsyamAna', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'receive, take'}], 'isLemma': 0, 'parents': ['A_dA']},
	{'value': 'ādi', 'label': 'Adi', 'attrib': [{'pos': 'm', 'defs': 'beginning'}], 'isLemma': 1},
	{'value': 'ādiparvan', 'label': 'Adiparvan', 'attrib': [{'pos': 'n', 'defs': 'first book'}], 'isLemma': 1},
	{'value': 'ādivas', 'label': 'Adivas', 'attrib': [{'pos': 'pfap', 'defs': 'eat'}], 'isLemma': 0, 'parents': ['ad']},
	{'value': 'ādīyamāna', 'label': 'AdIyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'receive, take'}], 'isLemma': 0, 'parents': ['A_dA']},
	{'value': 'ādeya', 'label': 'Adeya', 'attrib': [{'pos': 'ger', 'defs': 'receive, take'}], 'isLemma': 0, 'parents': ['A_dA']},
	{'value': 'ādya', 'label': 'Adya', 'attrib': [{'pos': 'ger', 'defs': 'eat'}], 'isLemma': 0, 'parents': ['ad']},
	{'value': 'ādhātavya', 'label': 'ADAtavya', 'attrib': [{'pos': 'ger', 'defs': 'put on, apply'}], 'isLemma': 0, 'parents': ['A_DA']},
	{'value': 'ādhātum', 'label': 'ADAtum', 'attrib': [{'pos': 'inf', 'defs': 'put on, apply'}], 'isLemma': 0, 'parents': ['A_DA']},
	{'value': 'ādhānīya', 'label': 'ADAnIya', 'attrib': [{'pos': 'ger', 'defs': 'put on, apply'}], 'isLemma': 0, 'parents': ['A_DA']},
	{'value': 'ādhāya', 'label': 'ADAya', 'attrib': [{'pos': 'abs', 'defs': 'put on, apply'}], 'isLemma': 0, 'parents': ['A_DA']},
	{'value': 'ādhāsyat', 'label': 'ADAsyat', 'attrib': [{'pos': 'fap', 'defs': 'put on, apply'}], 'isLemma': 0, 'parents': ['A_DA']},
	{'value': 'ādhāsyamāna', 'label': 'ADAsyamAna', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'put on, apply'}], 'isLemma': 0, 'parents': ['A_DA']},
	{'value': 'ādhīyamāna', 'label': 'ADIyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'put on, apply'}], 'isLemma': 0, 'parents': ['A_DA']},
	{'value': 'ādheya', 'label': 'ADeya', 'attrib': [{'pos': 'ger', 'defs': 'put on, apply'}], 'isLemma': 0, 'parents': ['A_DA']},
	{'value': 'āp', 'label': 'Ap', 'attrib': [{'pos': 'vt5a', 'defs': 'attain'}], 'isLemma': 1},
	{'value': 'āpanīya', 'label': 'ApanIya', 'attrib': [{'pos': 'ger', 'defs': 'attain'}], 'isLemma': 0, 'parents': ['Ap']},
	{'value': 'āpāna', 'label': 'ApAna', 'attrib': [{'pos': 'pfpp', 'defs': 'attain'}], 'isLemma': 0, 'parents': ['Ap']},
	{'value': 'āpivas', 'label': 'Apivas', 'attrib': [{'pos': 'pfap', 'defs': 'attain'}], 'isLemma': 0, 'parents': ['Ap']},
	{'value': 'āpta', 'label': 'Apta', 'attrib': [{'pos': 'ppp', 'defs': 'attain'}], 'isLemma': 0, 'parents': ['Ap']},
	{'value': 'āptavat', 'label': 'Aptavat', 'attrib': [{'pos': 'pap', 'defs': 'attain'}], 'isLemma': 0, 'parents': ['Ap']},
	{'value': 'āptavya', 'label': 'Aptavya', 'attrib': [{'pos': 'ger', 'defs': 'attain'}], 'isLemma': 0, 'parents': ['Ap']},
	{'value': 'āptum', 'label': 'Aptum', 'attrib': [{'pos': 'inf', 'defs': 'attain'}], 'isLemma': 0, 'parents': ['Ap']},
	{'value': 'āptvā', 'label': 'AptvA', 'attrib': [{'pos': 'abs', 'defs': 'attain'}], 'isLemma': 0, 'parents': ['Ap']},
	{'value': 'āpnuvat', 'label': 'Apnuvat', 'attrib': [{'pos': 'prap', 'defs': 'attain'}], 'isLemma': 0, 'parents': ['Ap']},
	{'value': 'āpya', 'label': 'Apya', 'attrib': [{'pos': 'ger', 'defs': 'attain'}], 'isLemma': 0, 'parents': ['Ap']},
	{'value': 'āpyamāna', 'label': 'ApyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'attain'}], 'isLemma': 0, 'parents': ['Ap']},
	{'value': 'āpsyat', 'label': 'Apsyat', 'attrib': [{'pos': 'fap', 'defs': 'attain'}], 'isLemma': 0, 'parents': ['Ap']},
	{'value': 'āpsyamāna', 'label': 'ApsyamAna', 'attrib': [{'pos': 'fpp', 'defs': 'attain'}], 'isLemma': 0, 'parents': ['Ap']},
	{'value': 'āyat', 'label': 'Ayat', 'attrib': [{'pos': 'prap', 'defs': 'come'}], 'isLemma': 0, 'parents': ['A_i']},
	{'value': 'āyanīya', 'label': 'AyanIya', 'attrib': [{'pos': 'ger', 'defs': 'come'}], 'isLemma': 0, 'parents': ['A_i']},
	{'value': 'ārapsyamāna', 'label': 'ArapsyamAna', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'begin'}], 'isLemma': 0, 'parents': ['A_raB']},
	{'value': 'ārabdha', 'label': 'ArabDa', 'attrib': [{'pos': 'ppp', 'defs': 'begin'}], 'isLemma': 0, 'parents': ['A_raB']},
	{'value': 'ārabdhavat', 'label': 'ArabDavat', 'attrib': [{'pos': 'pap', 'defs': 'begin'}], 'isLemma': 0, 'parents': ['A_raB']},
	{'value': 'ārabdhavya', 'label': 'ArabDavya', 'attrib': [{'pos': 'ger', 'defs': 'begin'}], 'isLemma': 0, 'parents': ['A_raB']},
	{'value': 'ārabdhum', 'label': 'ArabDum', 'attrib': [{'pos': 'inf', 'defs': 'begin'}], 'isLemma': 0, 'parents': ['A_raB']},
	{'value': 'ārabhamāṇa', 'label': 'AraBamARa', 'attrib': [{'pos': 'prmp', 'defs': 'begin'}], 'isLemma': 0, 'parents': ['A_raB']},
	{'value': 'ārabhya', 'label': 'AraBya', 'attrib': [{'pos': 'abs', 'defs': 'begin'}, {'pos': 'ger', 'defs': 'begin'}], 'isLemma': 0, 'parents': ['A_raB']},
	{'value': 'ārabhyamāṇa', 'label': 'AraByamARa', 'attrib': [{'pos': 'prpp', 'defs': 'begin'}], 'isLemma': 0, 'parents': ['A_raB']},
	{'value': 'ārambhanīya', 'label': 'AramBanIya', 'attrib': [{'pos': 'ger', 'defs': 'begin'}], 'isLemma': 0, 'parents': ['A_raB']},
	{'value': 'ārebhāṇa', 'label': 'AreBARa', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'begin'}], 'isLemma': 0, 'parents': ['A_raB']},
	{'value': 'āvaha', 'label': 'Avaha', 'attrib': [{'pos': 'adj', 'defs': 'bringing'}], 'isLemma': 1},
	{'value': 'āśis', 'label': 'ASis', 'attrib': [{'pos': 'f', 'defs': "serpent's fang"}], 'isLemma': 1},
	{'value': 'āśī', 'label': 'ASI', 'attrib': [{'pos': 'f', 'defs': "serpent's fang"}], 'isLemma': 1},
	{'value': 'āśīviṣa', 'label': 'ASIviza', 'attrib': [{'pos': 'adj', 'defs': 'venom-fanged'}], 'isLemma': 1},
	{'value': 'āhita', 'label': 'Ahita', 'attrib': [{'pos': 'ppp', 'defs': 'put on, apply'}], 'isLemma': 0, 'parents': ['A_DA']},
	{'value': 'āhitavat', 'label': 'Ahitavat', 'attrib': [{'pos': 'pap', 'defs': 'put on, apply'}], 'isLemma': 0, 'parents': ['A_DA']},
	{'value': 'i', 'label': 'i', 'attrib': [{'pos': 'vt2a', 'defs': 'go'}], 'isLemma': 1},
	{'value': 'icchat', 'label': 'icCat', 'attrib': [{'pos': 'prap', 'defs': 'desire'}], 'isLemma': 0, 'parents': ['iz']},
	{'value': 'ita', 'label': 'ita', 'attrib': [{'pos': 'ppp', 'defs': 'go'}], 'isLemma': 0, 'parents': ['i']},
	{'value': 'itavat', 'label': 'itavat', 'attrib': [{'pos': 'pap', 'defs': 'go'}], 'isLemma': 0, 'parents': ['i']},
	{'value': 'iti', 'label': 'iti', 'attrib': [{'pos': 'pcl', 'defs': 'thus, close quote'}], 'isLemma': 1},
	{'value': 'itya', 'label': 'itya', 'attrib': [{'pos': 'ger', 'defs': 'go'}], 'isLemma': 0, 'parents': ['i']},
	{'value': 'itvā', 'label': 'itvA', 'attrib': [{'pos': 'abs', 'defs': 'go'}], 'isLemma': 0, 'parents': ['i']},
	{'value': 'idam', 'label': 'idam', 'attrib': [{'pos': 'dem_adj, dem_pron', 'defs': 'this'}], 'isLemma': 1},
	{'value': 'indra', 'label': 'indra', 'attrib': [{'pos': 'm_pn', 'defs': 'Indra'}], 'isLemma': 1},
	{'value': 'iva', 'label': 'iva', 'attrib': [{'pos': 'pcl', 'defs': 'like, as, as if'}], 'isLemma': 1},
	{'value': 'iṣ', 'label': 'iz', 'attrib': [{'pos': 'vt6a', 'defs': 'desire'}], 'isLemma': 1},
	{'value': 'iṣu', 'label': 'izu', 'attrib': [{'pos': 'm, f', 'defs': 'arrow'}], 'isLemma': 1},
	{'value': 'iṣudhi', 'label': 'izuDi', 'attrib': [{'pos': 'm', 'defs': 'quiver'}], 'isLemma': 1},
	{'value': 'iṣṭa', 'label': 'izwa', 'attrib': [{'pos': 'ppp', 'defs': 'desire'}], 'isLemma': 0, 'parents': ['iz']},
	{'value': 'iṣṭavat', 'label': 'izwavat', 'attrib': [{'pos': 'pap', 'defs': 'desire'}], 'isLemma': 0, 'parents': ['iz']},
	{'value': 'iṣṭvā', 'label': 'izwvA', 'attrib': [{'pos': 'abs', 'defs': 'desire'}], 'isLemma': 0, 'parents': ['iz']},
	{'value': 'iṣyamāṇa', 'label': 'izyamARa', 'attrib': [{'pos': 'prpp', 'defs': 'desire'}], 'isLemma': 0, 'parents': ['iz']},
	{'value': 'īyamāna', 'label': 'IyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'go'}], 'isLemma': 0, 'parents': ['i']},
	{'value': 'īyāna', 'label': 'IyAna', 'attrib': [{'pos': 'pfpp', 'defs': 'go'}], 'isLemma': 0, 'parents': ['i']},
	{'value': 'īyivas', 'label': 'Iyivas', 'attrib': [{'pos': 'pfap', 'defs': 'go'}], 'isLemma': 0, 'parents': ['i']},
	{'value': 'īś', 'label': 'IS', 'attrib': [{'pos': 'vt2m', 'defs': 'rule'}], 'isLemma': 1},
	{'value': 'īśa', 'label': 'ISa', 'attrib': [{'pos': 'm', 'defs': 'lord'}], 'isLemma': 1},
	{'value': 'īṣāṇa', 'label': 'IzARa', 'attrib': [{'pos': 'pfpp', 'defs': 'desire'}], 'isLemma': 0, 'parents': ['iz']},
	{'value': 'īṣivas', 'label': 'Izivas', 'attrib': [{'pos': 'pfap', 'defs': 'desire'}], 'isLemma': 0, 'parents': ['iz']},
	{'value': 'ukta', 'label': 'ukta', 'attrib': [{'pos': 'ppp', 'defs': 'say, speak'}], 'isLemma': 0, 'parents': ['vac']},
	{'value': 'uktavat', 'label': 'uktavat', 'attrib': [{'pos': 'pap', 'defs': 'say, speak'}], 'isLemma': 0, 'parents': ['vac']},
	{'value': 'uktvā', 'label': 'uktvA', 'attrib': [{'pos': 'abs', 'defs': 'say, speak'}], 'isLemma': 0, 'parents': ['vac']},
	{'value': 'ucyamāna', 'label': 'ucyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'say, speak'}], 'isLemma': 0, 'parents': ['vac']},
	{'value': 'uttama', 'label': 'uttama', 'attrib': [{'pos': 'superlative_adj', 'defs': 'highest, supreme'}], 'isLemma': 1},
	{'value': 'uttasthāna', 'label': 'uttasTAna', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'stand up, arise'}], 'isLemma': 0, 'parents': ['ud_sTA']},
	{'value': 'uttasthivas', 'label': 'uttasTivas', 'attrib': [{'pos': 'pfap', 'defs': 'stand up, arise'}], 'isLemma': 0, 'parents': ['ud_sTA']},
	{'value': 'uttiṣṭhat', 'label': 'uttizWat', 'attrib': [{'pos': 'prap', 'defs': 'stand up, arise'}], 'isLemma': 0, 'parents': ['ud_sTA']},
	{'value': 'uttiṣṭhamāna', 'label': 'uttizWamAna', 'attrib': [{'pos': 'prmp', 'defs': 'stand up, arise'}], 'isLemma': 0, 'parents': ['ud_sTA']},
	{'value': 'utthātavya', 'label': 'utTAtavya', 'attrib': [{'pos': 'ger', 'defs': 'stand up, arise'}], 'isLemma': 0, 'parents': ['ud_sTA']},
	{'value': 'utthātum', 'label': 'utTAtum', 'attrib': [{'pos': 'inf', 'defs': 'stand up, arise'}], 'isLemma': 0, 'parents': ['ud_sTA']},
	{'value': 'utthānīya', 'label': 'utTAnIya', 'attrib': [{'pos': 'ger', 'defs': 'stand up, arise'}], 'isLemma': 0, 'parents': ['ud_sTA']},
	{'value': 'utthāya', 'label': 'utTAya', 'attrib': [{'pos': 'abs', 'defs': 'stand up, arise'}], 'isLemma': 0, 'parents': ['ud_sTA']},
	{'value': 'utthāsyat', 'label': 'utTAsyat', 'attrib': [{'pos': 'fap', 'defs': 'stand up, arise'}], 'isLemma': 0, 'parents': ['ud_sTA']},
	{'value': 'utthāsyamāna', 'label': 'utTAsyamAna', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'stand up, arise'}], 'isLemma': 0, 'parents': ['ud_sTA']},
	{'value': 'utthita', 'label': 'utTita', 'attrib': [{'pos': 'ppp', 'defs': 'stand up, arise'}], 'isLemma': 0, 'parents': ['ud_sTA']},
	{'value': 'utthitavat', 'label': 'utTitavat', 'attrib': [{'pos': 'pap', 'defs': 'stand up, arise'}], 'isLemma': 0, 'parents': ['ud_sTA']},
	{'value': 'uttheya', 'label': 'utTeya', 'attrib': [{'pos': 'ger', 'defs': 'stand up, arise'}], 'isLemma': 0, 'parents': ['ud_sTA']},
	{'value': 'utsattavya', 'label': 'utsattavya', 'attrib': [{'pos': 'ger', 'defs': 'sit up, withdraw, disappear, be ruined; caus.: ruin, destroy'}], 'isLemma': 0, 'parents': ['ud_sad']},
	{'value': 'utsattum', 'label': 'utsattum', 'attrib': [{'pos': 'inf', 'defs': 'sit up, withdraw, disappear, be ruined; caus.: ruin, destroy'}], 'isLemma': 0, 'parents': ['ud_sad']},
	{'value': 'utsatsyat', 'label': 'utsatsyat', 'attrib': [{'pos': 'fap', 'defs': 'sit up, withdraw, disappear, be ruined; caus.: ruin, destroy'}], 'isLemma': 0, 'parents': ['ud_sad']},
	{'value': 'utsatsyamāna', 'label': 'utsatsyamAna', 'attrib': [{'pos': 'fpp', 'defs': 'sit up, withdraw, disappear, be ruined; caus.: ruin, destroy'}], 'isLemma': 0, 'parents': ['ud_sad']},
	{'value': 'utsadanīya', 'label': 'utsadanIya', 'attrib': [{'pos': 'ger', 'defs': 'sit up, withdraw, disappear, be ruined; caus.: ruin, destroy'}], 'isLemma': 0, 'parents': ['ud_sad']},
	{'value': 'utsadya', 'label': 'utsadya', 'attrib': [{'pos': 'abs', 'defs': 'sit up, withdraw, disappear, be ruined; caus.: ruin, destroy'}], 'isLemma': 0, 'parents': ['ud_sad']},
	{'value': 'utsadyamāna', 'label': 'utsadyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'sit up, withdraw, disappear, be ruined; caus.: ruin, destroy'}], 'isLemma': 0, 'parents': ['ud_sad']},
	{'value': 'utsanna', 'label': 'utsanna', 'attrib': [{'pos': 'ppp', 'defs': 'sit up, withdraw, disappear, be ruined; caus.: ruin, destroy'}], 'isLemma': 0, 'parents': ['ud_sad']},
	{'value': 'utsannavat', 'label': 'utsannavat', 'attrib': [{'pos': 'pap', 'defs': 'sit up, withdraw, disappear, be ruined; caus.: ruin, destroy'}], 'isLemma': 0, 'parents': ['ud_sad']},
	{'value': 'utsādya', 'label': 'utsAdya', 'attrib': [{'pos': 'abs', 'defs': 'sit up, withdraw, disappear, be ruined; caus.: ruin, destroy'}, {'pos': 'ger', 'defs': 'sit up, withdraw, disappear, be ruined; caus.: ruin, destroy'}], 'isLemma': 0, 'parents': ['ud_sad']},
	{'value': 'utsīdat', 'label': 'utsIdat', 'attrib': [{'pos': 'prap', 'defs': 'sit up, withdraw, disappear, be ruined; caus.: ruin, destroy'}], 'isLemma': 0, 'parents': ['ud_sad']},
	{'value': 'utsedāna', 'label': 'utsedAna', 'attrib': [{'pos': 'pfpp', 'defs': 'sit up, withdraw, disappear, be ruined; caus.: ruin, destroy'}], 'isLemma': 0, 'parents': ['ud_sad']},
	{'value': 'utsedivas', 'label': 'utsedivas', 'attrib': [{'pos': 'pfap', 'defs': 'sit up, withdraw, disappear, be ruined; caus.: ruin, destroy'}], 'isLemma': 0, 'parents': ['ud_sad']},
	{'value': 'utsthīyamāna', 'label': 'utsTIyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'stand up, arise'}], 'isLemma': 0, 'parents': ['ud_sTA']},
	{'value': 'ud', 'label': 'ud', 'attrib': [{'pos': 'preverb', 'defs': 'up'}], 'isLemma': 1},
	{'value': 'udañc', 'label': 'udaYc', 'attrib': [{'pos': 'adj', 'defs': 'upward, northern'}], 'isLemma': 1},
	{'value': 'udara', 'label': 'udara', 'attrib': [{'pos': 'n', 'defs': 'uterus, womb, belly'}], 'isLemma': 1},
	{'value': 'udita', 'label': 'udita', 'attrib': [{'pos': 'ppp', 'defs': 'tell'}], 'isLemma': 0, 'parents': ['vad']},
	{'value': 'uditavat', 'label': 'uditavat', 'attrib': [{'pos': 'pap', 'defs': 'tell'}], 'isLemma': 0, 'parents': ['vad']},
	{'value': 'uditvā', 'label': 'uditvA', 'attrib': [{'pos': 'abs', 'defs': 'tell'}], 'isLemma': 0, 'parents': ['vad']},
	{'value': 'udīcī', 'label': 'udIcI', 'attrib': [{'pos': 'f', 'defs': 'the north'}], 'isLemma': 1},
	{'value': 'ud_añc', 'label': 'ud_aYc', 'attrib': [{'pos': 'v1am', 'defs': 'bend up, rise'}], 'isLemma': 1},
	{'value': 'ud_sad', 'label': 'ud_sad', 'attrib': [{'pos': 'vi1a, vi6a', 'defs': 'sit up, withdraw, disappear, be ruined; caus.: ruin, destroy'}], 'isLemma': 1},
	{'value': 'ud_sah', 'label': 'ud_sah', 'attrib': [{'pos': 'vt1m', 'defs': 'be eager; caus.: encourage, inspire'}], 'isLemma': 1},
	{'value': 'ud_sthā', 'label': 'ud_sTA', 'attrib': [{'pos': 'vi1am', 'defs': 'stand up, arise'}], 'isLemma': 1},
	{'value': 'udyamāna', 'label': 'udyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'tell'}], 'isLemma': 0, 'parents': ['vad']},
	{'value': 'upa', 'label': 'upa', 'attrib': [{'pos': 'preverb', 'defs': 'under, near'}], 'isLemma': 1},
	{'value': 'upa_i', 'label': 'upa_i', 'attrib': [{'pos': 'vt2a', 'defs': 'approach'}], 'isLemma': 1},
	{'value': 'upayat', 'label': 'upayat', 'attrib': [{'pos': 'prap', 'defs': 'approach'}], 'isLemma': 0, 'parents': ['upa_i']},
	{'value': 'upāya', 'label': 'upAya', 'attrib': [{'pos': 'm', 'defs': 'approach, means'}], 'isLemma': 1},
	{'value': 'upāyanīya', 'label': 'upAyanIya', 'attrib': [{'pos': 'ger', 'defs': 'approach'}], 'isLemma': 0, 'parents': ['upa_i']},
	{'value': 'upeta', 'label': 'upeta', 'attrib': [{'pos': 'ppp', 'defs': 'approach'}, {'pos': 'adj', 'defs': 'endowed with'}], 'isLemma': 1},
	{'value': 'upeta', 'label': 'upeta', 'attrib': [{'pos': 'ppp', 'defs': 'approach'}, {'pos': 'adj', 'defs': 'endowed with'}], 'isLemma': 0, 'parents': ['upa_i']},
	{'value': 'upetavat', 'label': 'upetavat', 'attrib': [{'pos': 'pap', 'defs': 'approach'}], 'isLemma': 0, 'parents': ['upa_i']},
	{'value': 'upetya', 'label': 'upetya', 'attrib': [{'pos': 'abs', 'defs': 'approach'}, {'pos': 'ger', 'defs': 'approach'}], 'isLemma': 0, 'parents': ['upa_i']},
	{'value': 'upeyamāna', 'label': 'upeyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'approach'}], 'isLemma': 0, 'parents': ['upa_i']},
	{'value': 'upeyāna', 'label': 'upeyAna', 'attrib': [{'pos': 'pfpp', 'defs': 'approach'}], 'isLemma': 0, 'parents': ['upa_i']},
	{'value': 'upeyivas', 'label': 'upeyivas', 'attrib': [{'pos': 'pfap', 'defs': 'approach'}], 'isLemma': 0, 'parents': ['upa_i']},
	{'value': 'upaitavya', 'label': 'upEtavya', 'attrib': [{'pos': 'ger', 'defs': 'approach'}], 'isLemma': 0, 'parents': ['upa_i']},
	{'value': 'upaitum', 'label': 'upEtum', 'attrib': [{'pos': 'inf', 'defs': 'approach'}], 'isLemma': 0, 'parents': ['upa_i']},
	{'value': 'upaiṣyat', 'label': 'upEzyat', 'attrib': [{'pos': 'fap', 'defs': 'approach'}], 'isLemma': 0, 'parents': ['upa_i']},
	{'value': 'upaiṣyamāṇa', 'label': 'upEzyamARa', 'attrib': [{'pos': 'fpp', 'defs': 'approach'}], 'isLemma': 0, 'parents': ['upa_i']},
	{'value': 'uśat', 'label': 'uSat', 'attrib': [{'pos': 'prap', 'defs': 'wish'}], 'isLemma': 0, 'parents': ['vaS']},
	{'value': 'uśita', 'label': 'uSita', 'attrib': [{'pos': 'ppp', 'defs': 'wish'}], 'isLemma': 0, 'parents': ['vaS']},
	{'value': 'uśitavat', 'label': 'uSitavat', 'attrib': [{'pos': 'pap', 'defs': 'wish'}], 'isLemma': 0, 'parents': ['vaS']},
	{'value': 'uśyamāna', 'label': 'uSyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'wish'}], 'isLemma': 0, 'parents': ['vaS']},
	{'value': 'uṣita', 'label': 'uzita', 'attrib': [{'pos': 'ppp', 'defs': 'dwell, live'}], 'isLemma': 0, 'parents': ['vas']},
	{'value': 'uṣitavat', 'label': 'uzitavat', 'attrib': [{'pos': 'pap', 'defs': 'dwell, live'}], 'isLemma': 0, 'parents': ['vas']},
	{'value': 'uṣitvā', 'label': 'uzitvA', 'attrib': [{'pos': 'abs', 'defs': 'dwell, live'}], 'isLemma': 0, 'parents': ['vas']},
	{'value': 'uṣyamāṇa', 'label': 'uzyamARa', 'attrib': [{'pos': 'prpp', 'defs': 'dwell, live'}], 'isLemma': 0, 'parents': ['vas']},
	{'value': 'uhyamāna', 'label': 'uhyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'carry, flow, wed'}], 'isLemma': 0, 'parents': ['vah']},
	{'value': 'ūcāna', 'label': 'UcAna', 'attrib': [{'pos': 'pfpp', 'defs': 'say, speak'}], 'isLemma': 0, 'parents': ['vac']},
	{'value': 'ūcivas', 'label': 'Ucivas', 'attrib': [{'pos': 'pfap', 'defs': 'say, speak'}], 'isLemma': 0, 'parents': ['vac']},
	{'value': 'ūḍha', 'label': 'UQa', 'attrib': [{'pos': 'ppp', 'defs': 'carry, flow, wed'}], 'isLemma': 0, 'parents': ['vah']},
	{'value': 'ūḍhavat', 'label': 'UQavat', 'attrib': [{'pos': 'pap', 'defs': 'carry, flow, wed'}], 'isLemma': 0, 'parents': ['vah']},
	{'value': 'ūḍhvā', 'label': 'UQvA', 'attrib': [{'pos': 'abs', 'defs': 'carry, flow, wed'}], 'isLemma': 0, 'parents': ['vah']},
	{'value': 'ūdāna', 'label': 'UdAna', 'attrib': [{'pos': 'pfpp', 'defs': 'tell'}], 'isLemma': 0, 'parents': ['vad']},
	{'value': 'ūdivas', 'label': 'Udivas', 'attrib': [{'pos': 'pfap', 'defs': 'tell'}], 'isLemma': 0, 'parents': ['vad']},
	{'value': 'ūṣāṇa', 'label': 'UzARa', 'attrib': [{'pos': 'pfpp', 'defs': 'dwell, live'}], 'isLemma': 0, 'parents': ['vas']},
	{'value': 'ūṣivas', 'label': 'Uzivas', 'attrib': [{'pos': 'pfap', 'defs': 'dwell, live'}], 'isLemma': 0, 'parents': ['vas']},
	{'value': 'ūhāna', 'label': 'UhAna', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'carry, flow, wed'}], 'isLemma': 0, 'parents': ['vah']},
	{'value': 'ūhivas', 'label': 'Uhivas', 'attrib': [{'pos': 'pfap', 'defs': 'carry, flow, wed'}], 'isLemma': 0, 'parents': ['vah']},
	{'value': 'ṛṣabha', 'label': 'fzaBa', 'attrib': [{'pos': 'm', 'defs': 'bull'}], 'isLemma': 1},
	{'value': 'ṛṣi', 'label': 'fzi', 'attrib': [{'pos': 'm', 'defs': 'Vedic seer'}], 'isLemma': 1},
	{'value': 'eka', 'label': 'eka', 'attrib': [{'pos': 'card_num', 'defs': 'one'}], 'isLemma': 1},
	{'value': 'ekacakra', 'label': 'ekacakra', 'attrib': [{'pos': 'adj', 'defs': "one-wheeled, epithet of the sun's chariot, one-kinged, one-armied"}], 'isLemma': 1},
	{'value': 'ekacakrā', 'label': 'ekacakrA', 'attrib': [{'pos': 'f_pn', 'defs': 'Ekacakrā'}], 'isLemma': 1},
	{'value': 'eta', 'label': 'eta', 'attrib': [{'pos': 'ppp', 'defs': 'come'}], 'isLemma': 0, 'parents': ['A_i']},
	{'value': 'etad', 'label': 'etad', 'attrib': [{'pos': 'dem_adj, dem_pron', 'defs': 'this'}], 'isLemma': 1},
	{'value': 'etavat', 'label': 'etavat', 'attrib': [{'pos': 'pap', 'defs': 'come'}], 'isLemma': 0, 'parents': ['A_i']},
	{'value': 'etavya', 'label': 'etavya', 'attrib': [{'pos': 'ger', 'defs': 'go'}], 'isLemma': 0, 'parents': ['i']},
	{'value': 'etum', 'label': 'etum', 'attrib': [{'pos': 'inf', 'defs': 'go'}], 'isLemma': 0, 'parents': ['i']},
	{'value': 'etya', 'label': 'etya', 'attrib': [{'pos': 'abs', 'defs': 'come'}, {'pos': 'ger', 'defs': 'come'}], 'isLemma': 0, 'parents': ['A_i']},
	{'value': 'enad', 'label': 'enad', 'attrib': [{'pos': 'dem_adj, dem_pron', 'defs': 'this, it'}], 'isLemma': 1},
	{'value': 'eyamāna', 'label': 'eyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'come'}], 'isLemma': 0, 'parents': ['A_i']},
	{'value': 'eyāna', 'label': 'eyAna', 'attrib': [{'pos': 'pfpp', 'defs': 'come'}], 'isLemma': 0, 'parents': ['A_i']},
	{'value': 'eyivas', 'label': 'eyivas', 'attrib': [{'pos': 'pfap', 'defs': 'come'}], 'isLemma': 0, 'parents': ['A_i']},
	{'value': 'eva', 'label': 'eva', 'attrib': [{'pos': 'pcl', 'defs': 'only'}], 'isLemma': 1},
	{'value': 'evam', 'label': 'evam', 'attrib': [{'pos': 'dem_adv', 'defs': 'in this way, thus'}], 'isLemma': 1},
	{'value': 'eṣaṇīya', 'label': 'ezaRIya', 'attrib': [{'pos': 'ger', 'defs': 'desire'}], 'isLemma': 0, 'parents': ['iz']},
	{'value': 'eṣiṣyat', 'label': 'ezizyat', 'attrib': [{'pos': 'fap', 'defs': 'desire'}], 'isLemma': 0, 'parents': ['iz']},
	{'value': 'eṣiṣyamāṇa', 'label': 'ezizyamARa', 'attrib': [{'pos': 'fpp', 'defs': 'desire'}], 'isLemma': 0, 'parents': ['iz']},
	{'value': 'eṣṭavya', 'label': 'ezwavya', 'attrib': [{'pos': 'ger', 'defs': 'desire'}], 'isLemma': 0, 'parents': ['iz']},
	{'value': 'eṣṭum', 'label': 'ezwum', 'attrib': [{'pos': 'inf', 'defs': 'desire'}], 'isLemma': 0, 'parents': ['iz']},
	{'value': 'eṣya', 'label': 'ezya', 'attrib': [{'pos': 'ger', 'defs': 'desire'}], 'isLemma': 0, 'parents': ['iz']},
	{'value': 'eṣyat', 'label': 'ezyat', 'attrib': [{'pos': 'fap', 'defs': 'go'}], 'isLemma': 0, 'parents': ['i']},
	{'value': 'eṣyamāṇa', 'label': 'ezyamARa', 'attrib': [{'pos': 'fpp', 'defs': 'go'}], 'isLemma': 0, 'parents': ['i']},
	{'value': 'aitavya', 'label': 'Etavya', 'attrib': [{'pos': 'ger', 'defs': 'come'}], 'isLemma': 0, 'parents': ['A_i']},
	{'value': 'aitum', 'label': 'Etum', 'attrib': [{'pos': 'inf', 'defs': 'come'}], 'isLemma': 0, 'parents': ['A_i']},
	{'value': 'aiṣyat', 'label': 'Ezyat', 'attrib': [{'pos': 'fap', 'defs': 'come'}], 'isLemma': 0, 'parents': ['A_i']},
	{'value': 'aiṣyamāṇa', 'label': 'EzyamARa', 'attrib': [{'pos': 'fpp', 'defs': 'come'}], 'isLemma': 0, 'parents': ['A_i']},
	{'value': 'ojas', 'label': 'ojas', 'attrib': [{'pos': 'n', 'defs': 'vigour, energy'}], 'isLemma': 1},
	{'value': 'kath', 'label': 'kaT', 'attrib': [{'pos': 'vt10am', 'defs': 'tell, narrate'}], 'isLemma': 1},
	{'value': 'kathanīya', 'label': 'kaTanIya', 'attrib': [{'pos': 'ger', 'defs': 'tell, narrate'}], 'isLemma': 0, 'parents': ['kaT']},
	{'value': 'katham', 'label': 'kaTam', 'attrib': [{'pos': 'int_adv', 'defs': 'how'}], 'isLemma': 1},
	{'value': 'kathayat', 'label': 'kaTayat', 'attrib': [{'pos': 'prap', 'defs': 'tell, narrate'}], 'isLemma': 0, 'parents': ['kaT']},
	{'value': 'kathayamāna', 'label': 'kaTayamAna', 'attrib': [{'pos': 'prmp', 'defs': 'tell, narrate'}], 'isLemma': 0, 'parents': ['kaT']},
	{'value': 'kathayām', 'label': 'kaTayAm', 'attrib': [{'pos': 'ppf_actn', 'defs': 'tell, narrate'}], 'isLemma': 0, 'parents': ['kaT']},
	{'value': 'kathayitavya', 'label': 'kaTayitavya', 'attrib': [{'pos': 'ger', 'defs': 'tell, narrate'}], 'isLemma': 0, 'parents': ['kaT']},
	{'value': 'kathayitum', 'label': 'kaTayitum', 'attrib': [{'pos': 'inf', 'defs': 'tell, narrate'}], 'isLemma': 0, 'parents': ['kaT']},
	{'value': 'kathayitvā', 'label': 'kaTayitvA', 'attrib': [{'pos': 'abs', 'defs': 'tell, narrate'}], 'isLemma': 0, 'parents': ['kaT']},
	{'value': 'kathayiṣyat', 'label': 'kaTayizyat', 'attrib': [{'pos': 'fap', 'defs': 'tell, narrate'}], 'isLemma': 0, 'parents': ['kaT']},
	{'value': 'kathayiṣyamāṇa', 'label': 'kaTayizyamARa', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'tell, narrate'}], 'isLemma': 0, 'parents': ['kaT']},
	{'value': 'kathā', 'label': 'kaTA', 'attrib': [{'pos': 'f', 'defs': 'story, narration'}], 'isLemma': 1},
	{'value': 'kathita', 'label': 'kaTita', 'attrib': [{'pos': 'ppp', 'defs': 'tell, narrate'}], 'isLemma': 0, 'parents': ['kaT']},
	{'value': 'kathitavat', 'label': 'kaTitavat', 'attrib': [{'pos': 'pap', 'defs': 'tell, narrate'}], 'isLemma': 0, 'parents': ['kaT']},
	{'value': 'kathya', 'label': 'kaTya', 'attrib': [{'pos': 'ger', 'defs': 'tell, narrate'}], 'isLemma': 0, 'parents': ['kaT']},
	{'value': 'kathyamāna', 'label': 'kaTyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'tell, narrate'}], 'isLemma': 0, 'parents': ['kaT']},
	{'value': 'kadā', 'label': 'kadA', 'attrib': [{'pos': 'int_adv', 'defs': 'when'}], 'isLemma': 1},
	{'value': 'kanyā', 'label': 'kanyA', 'attrib': [{'pos': 'f', 'defs': 'girl'}], 'isLemma': 1},
	{'value': 'kapi', 'label': 'kapi', 'attrib': [{'pos': 'm', 'defs': 'monkey'}], 'isLemma': 1},
	{'value': 'kapilakṣaṇa', 'label': 'kapilakzaRa', 'attrib': [{'pos': 'adj', 'defs': 'monkey-bannered'}], 'isLemma': 1},
	{'value': 'karaṇīya', 'label': 'karaRIya', 'attrib': [{'pos': 'ger', 'defs': 'do, make'}], 'isLemma': 0, 'parents': ['kf']},
	{'value': 'kariṣyat', 'label': 'karizyat', 'attrib': [{'pos': 'fap', 'defs': 'do, make'}], 'isLemma': 0, 'parents': ['kf']},
	{'value': 'kariṣyamāṇa', 'label': 'karizyamARa', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'do, make'}], 'isLemma': 0, 'parents': ['kf']},
	{'value': 'karṇa', 'label': 'karRa', 'attrib': [{'pos': 'm', 'defs': 'ear'}, {'pos': 'm_pn', 'defs': 'Karṇa'}], 'isLemma': 1},
	{'value': 'kartavya', 'label': 'kartavya', 'attrib': [{'pos': 'ger', 'defs': 'do, make'}], 'isLemma': 0, 'parents': ['kf']},
	{'value': 'kartum', 'label': 'kartum', 'attrib': [{'pos': 'inf', 'defs': 'do, make'}], 'isLemma': 0, 'parents': ['kf']},
	{'value': 'karman', 'label': 'karman', 'attrib': [{'pos': 'n', 'defs': 'action, deed'}], 'isLemma': 1},
	{'value': 'kāraka', 'label': 'kAraka', 'attrib': [{'pos': 'adj', 'defs': 'making, causing'}, {'pos': 'n', 'defs': 'cause'}], 'isLemma': 1},
	{'value': 'kārya', 'label': 'kArya', 'attrib': [{'pos': 'ger', 'defs': 'do, make'}], 'isLemma': 0, 'parents': ['kf']},
	{'value': 'kāś', 'label': 'kAS', 'attrib': [{'pos': 'vi1m, vi4m', 'defs': 'shine'}], 'isLemma': 1},
	{'value': 'kāśanīya', 'label': 'kASanIya', 'attrib': [{'pos': 'ger', 'defs': 'shine'}], 'isLemma': 0, 'parents': ['kAS']},
	{'value': 'kāśamāna', 'label': 'kASamAna', 'attrib': [{'pos': 'prmp', 'defs': 'shine'}], 'isLemma': 0, 'parents': ['kAS']},
	{'value': 'kāśāṇa', 'label': 'kASARa', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'shine'}], 'isLemma': 0, 'parents': ['kAS']},
	{'value': 'kāśita', 'label': 'kASita', 'attrib': [{'pos': 'ppp', 'defs': 'shine'}], 'isLemma': 0, 'parents': ['kAS']},
	{'value': 'kāśitavat', 'label': 'kASitavat', 'attrib': [{'pos': 'pap', 'defs': 'shine'}], 'isLemma': 0, 'parents': ['kAS']},
	{'value': 'kāśitavya', 'label': 'kASitavya', 'attrib': [{'pos': 'ger', 'defs': 'shine'}], 'isLemma': 0, 'parents': ['kAS']},
	{'value': 'kāśitum', 'label': 'kASitum', 'attrib': [{'pos': 'inf', 'defs': 'shine'}], 'isLemma': 0, 'parents': ['kAS']},
	{'value': 'kāśitvā', 'label': 'kASitvA', 'attrib': [{'pos': 'abs', 'defs': 'shine'}], 'isLemma': 0, 'parents': ['kAS']},
	{'value': 'kāśiṣyamāṇa', 'label': 'kASizyamARa', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'shine'}], 'isLemma': 0, 'parents': ['kAS']},
	{'value': 'kāśya', 'label': 'kASya', 'attrib': [{'pos': 'ger', 'defs': 'shine'}], 'isLemma': 0, 'parents': ['kAS']},
	{'value': 'kāśyamāna', 'label': 'kASyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'shine'}], 'isLemma': 0, 'parents': ['kAS']},
	{'value': 'kim', 'label': 'kim', 'attrib': [{'pos': 'int_adj, int_pron', 'defs': 'what'}], 'isLemma': 1},
	{'value': 'kuntī', 'label': 'kuntI', 'attrib': [{'pos': 'f_pn', 'defs': 'Kuntī'}], 'isLemma': 1},
	{'value': 'kuru', 'label': 'kuru', 'attrib': [{'pos': 'm_pn', 'defs': 'Kuru ; pl. his descendants'}], 'isLemma': 1},
	{'value': 'kurupāṇḍava', 'label': 'kurupARqava', 'attrib': [{'pos': 'm', 'defs': 'the descendants of Kuru and the sons of Pāṇḍu'}], 'isLemma': 1},
	{'value': 'kurvat', 'label': 'kurvat', 'attrib': [{'pos': 'prap', 'defs': 'do, make'}], 'isLemma': 0, 'parents': ['kf']},
	{'value': 'kurvāṇa', 'label': 'kurvARa', 'attrib': [{'pos': 'prmp', 'defs': 'do, make'}], 'isLemma': 0, 'parents': ['kf']},
	{'value': 'kṛ', 'label': 'kf', 'attrib': [{'pos': 'vt8am', 'defs': 'do, make'}], 'isLemma': 1},
	{'value': 'kṛta', 'label': 'kfta', 'attrib': [{'pos': 'ppp', 'defs': 'do, make'}], 'isLemma': 0, 'parents': ['kf']},
	{'value': 'kṛtavat', 'label': 'kftavat', 'attrib': [{'pos': 'pap', 'defs': 'do, make'}], 'isLemma': 0, 'parents': ['kf']},
	{'value': 'kṛtya', 'label': 'kftya', 'attrib': [{'pos': 'ger', 'defs': 'do, make'}], 'isLemma': 0, 'parents': ['kf']},
	{'value': 'kṛtvā', 'label': 'kftvA', 'attrib': [{'pos': 'abs', 'defs': 'do, make'}], 'isLemma': 0, 'parents': ['kf']},
	{'value': 'kṛtsna', 'label': 'kftsna', 'attrib': [{'pos': 'adj', 'defs': 'whole, entire'}], 'isLemma': 1},
	{'value': 'kṛṣṇa', 'label': 'kfzRa', 'attrib': [{'pos': 'adj', 'defs': 'dark, black'}, {'pos': 'm_pn', 'defs': 'Kṛṣṇa'}], 'isLemma': 1},
	{'value': 'kṛṣṇasarpa', 'label': 'kfzRasarpa', 'attrib': [{'pos': 'm', 'defs': 'black snake, cobra'}], 'isLemma': 1},
	{'value': 'keśa', 'label': 'keSa', 'attrib': [{'pos': 'm', 'defs': 'hair (of the head), mane'}], 'isLemma': 1},
	{'value': 'keśava', 'label': 'keSava', 'attrib': [{'pos': 'adj', 'defs': 'having hair'}], 'isLemma': 1},
	{'value': 'koṭi', 'label': 'kowi', 'attrib': [{'pos': 'f', 'defs': 'end, top'}], 'isLemma': 1},
	{'value': 'kaunteya', 'label': 'kOnteya', 'attrib': [{'pos': 'm', 'defs': 'son of Kuntī'}], 'isLemma': 1},
	{'value': 'kram', 'label': 'kram', 'attrib': [{'pos': 'vt1a', 'defs': 'step'}], 'isLemma': 1},
	{'value': 'krama', 'label': 'krama', 'attrib': [{'pos': 'm', 'defs': 'step, succession'}], 'isLemma': 1},
	{'value': 'kriyamāṇa', 'label': 'kriyamARa', 'attrib': [{'pos': 'prpp', 'defs': 'do, make'}], 'isLemma': 0, 'parents': ['kf']},
	{'value': 'kruddha', 'label': 'krudDa', 'attrib': [{'pos': 'ppp', 'defs': 'be angry'}], 'isLemma': 0, 'parents': ['kruD']},
	{'value': 'kruddhavat', 'label': 'krudDavat', 'attrib': [{'pos': 'pap', 'defs': 'be angry'}], 'isLemma': 0, 'parents': ['kruD']},
	{'value': 'kruddhvā', 'label': 'krudDvA', 'attrib': [{'pos': 'abs', 'defs': 'be angry'}], 'isLemma': 0, 'parents': ['kruD']},
	{'value': 'krudh', 'label': 'kruD', 'attrib': [{'pos': 'vi4a', 'defs': 'be angry'}], 'isLemma': 1},
	{'value': 'krudhyat', 'label': 'kruDyat', 'attrib': [{'pos': 'prap', 'defs': 'be angry'}], 'isLemma': 0, 'parents': ['kruD']},
	{'value': 'krudhyamāna', 'label': 'kruDyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'be angry'}], 'isLemma': 0, 'parents': ['kruD']},
	{'value': 'krūra', 'label': 'krUra', 'attrib': [{'pos': 'adj', 'defs': 'cruel'}], 'isLemma': 1},
	{'value': 'krotsyat', 'label': 'krotsyat', 'attrib': [{'pos': 'fap', 'defs': 'be angry'}], 'isLemma': 0, 'parents': ['kruD']},
	{'value': 'krotsyamāna', 'label': 'krotsyamAna', 'attrib': [{'pos': 'fpp', 'defs': 'be angry'}], 'isLemma': 0, 'parents': ['kruD']},
	{'value': 'kroddhavya', 'label': 'krodDavya', 'attrib': [{'pos': 'ger', 'defs': 'be angry'}], 'isLemma': 0, 'parents': ['kruD']},
	{'value': 'kroddhum', 'label': 'krodDum', 'attrib': [{'pos': 'inf', 'defs': 'be angry'}], 'isLemma': 0, 'parents': ['kruD']},
	{'value': 'krodhanīya', 'label': 'kroDanIya', 'attrib': [{'pos': 'ger', 'defs': 'be angry'}], 'isLemma': 0, 'parents': ['kruD']},
	{'value': 'krodhya', 'label': 'kroDya', 'attrib': [{'pos': 'ger', 'defs': 'be angry'}], 'isLemma': 0, 'parents': ['kruD']},
	{'value': 'kliś', 'label': 'kliS', 'attrib': [{'pos': 'vt9a', 'defs': 'torment, afflict'}], 'isLemma': 1},
	{'value': 'kliśita', 'label': 'kliSita', 'attrib': [{'pos': 'ppp', 'defs': 'torment, afflict'}], 'isLemma': 0, 'parents': ['kliS']},
	{'value': 'kliśitavat', 'label': 'kliSitavat', 'attrib': [{'pos': 'pap', 'defs': 'torment, afflict'}], 'isLemma': 0, 'parents': ['kliS']},
	{'value': 'kliśitvā', 'label': 'kliSitvA', 'attrib': [{'pos': 'abs', 'defs': 'torment, afflict'}], 'isLemma': 0, 'parents': ['kliS']},
	{'value': 'kliśnat', 'label': 'kliSnat', 'attrib': [{'pos': 'prap', 'defs': 'torment, afflict'}], 'isLemma': 0, 'parents': ['kliS']},
	{'value': 'kliśyamāna', 'label': 'kliSyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'torment, afflict'}], 'isLemma': 0, 'parents': ['kliS']},
	{'value': 'kliṣṭa', 'label': 'klizwa', 'attrib': [{'pos': 'ppp', 'defs': 'torment, afflict'}], 'isLemma': 0, 'parents': ['kliS']},
	{'value': 'kliṣṭavat', 'label': 'klizwavat', 'attrib': [{'pos': 'pap', 'defs': 'torment, afflict'}], 'isLemma': 0, 'parents': ['kliS']},
	{'value': 'kliṣṭvā', 'label': 'klizwvA', 'attrib': [{'pos': 'abs', 'defs': 'torment, afflict'}], 'isLemma': 0, 'parents': ['kliS']},
	{'value': 'klekṣyat', 'label': 'klekzyat', 'attrib': [{'pos': 'fap', 'defs': 'torment, afflict'}], 'isLemma': 0, 'parents': ['kliS']},
	{'value': 'klekṣyamāṇa', 'label': 'klekzyamARa', 'attrib': [{'pos': 'fpp', 'defs': 'torment, afflict'}], 'isLemma': 0, 'parents': ['kliS']},
	{'value': 'kleśanīya', 'label': 'kleSanIya', 'attrib': [{'pos': 'ger', 'defs': 'torment, afflict'}], 'isLemma': 0, 'parents': ['kliS']},
	{'value': 'kleśitavya', 'label': 'kleSitavya', 'attrib': [{'pos': 'ger', 'defs': 'torment, afflict'}], 'isLemma': 0, 'parents': ['kliS']},
	{'value': 'kleśitum', 'label': 'kleSitum', 'attrib': [{'pos': 'inf', 'defs': 'torment, afflict'}], 'isLemma': 0, 'parents': ['kliS']},
	{'value': 'kleśiṣyat', 'label': 'kleSizyat', 'attrib': [{'pos': 'fap', 'defs': 'torment, afflict'}], 'isLemma': 0, 'parents': ['kliS']},
	{'value': 'kleśiṣyamāṇa', 'label': 'kleSizyamARa', 'attrib': [{'pos': 'fpp', 'defs': 'torment, afflict'}], 'isLemma': 0, 'parents': ['kliS']},
	{'value': 'kleśya', 'label': 'kleSya', 'attrib': [{'pos': 'ger', 'defs': 'torment, afflict'}], 'isLemma': 0, 'parents': ['kliS']},
	{'value': 'kleṣṭavya', 'label': 'klezwavya', 'attrib': [{'pos': 'ger', 'defs': 'torment, afflict'}], 'isLemma': 0, 'parents': ['kliS']},
	{'value': 'kleṣṭum', 'label': 'klezwum', 'attrib': [{'pos': 'inf', 'defs': 'torment, afflict'}], 'isLemma': 0, 'parents': ['kliS']},
	{'value': 'kṣaṁsyat', 'label': 'kzaMsyat', 'attrib': [{'pos': 'fap', 'defs': 'tolerate, forgive'}], 'isLemma': 0, 'parents': ['kzam']},
	{'value': 'kṣaṁsyamāna', 'label': 'kzaMsyamAna', 'attrib': [{'pos': 'fpp', 'defs': 'tolerate, forgive'}], 'isLemma': 0, 'parents': ['kzam']},
	{'value': 'kṣantavya', 'label': 'kzantavya', 'attrib': [{'pos': 'ger', 'defs': 'tolerate, forgive'}], 'isLemma': 0, 'parents': ['kzam']},
	{'value': 'kṣantum', 'label': 'kzantum', 'attrib': [{'pos': 'inf', 'defs': 'tolerate, forgive'}], 'isLemma': 0, 'parents': ['kzam']},
	{'value': 'kṣantvā', 'label': 'kzantvA', 'attrib': [{'pos': 'abs', 'defs': 'tolerate, forgive'}], 'isLemma': 0, 'parents': ['kzam']},
	{'value': 'kṣam', 'label': 'kzam', 'attrib': [{'pos': 'vi4a, vi1m', 'defs': 'tolerate, forgive'}], 'isLemma': 1},
	{'value': 'kṣamaṇīya', 'label': 'kzamaRIya', 'attrib': [{'pos': 'ger', 'defs': 'tolerate, forgive'}], 'isLemma': 0, 'parents': ['kzam']},
	{'value': 'kṣamāṇa', 'label': 'kzamARa', 'attrib': [{'pos': 'pfpp', 'defs': 'tolerate, forgive'}], 'isLemma': 0, 'parents': ['kzam']},
	{'value': 'kṣamitavya', 'label': 'kzamitavya', 'attrib': [{'pos': 'ger', 'defs': 'tolerate, forgive'}], 'isLemma': 0, 'parents': ['kzam']},
	{'value': 'kṣamitum', 'label': 'kzamitum', 'attrib': [{'pos': 'inf', 'defs': 'tolerate, forgive'}], 'isLemma': 0, 'parents': ['kzam']},
	{'value': 'kṣamitvā', 'label': 'kzamitvA', 'attrib': [{'pos': 'abs', 'defs': 'tolerate, forgive'}], 'isLemma': 0, 'parents': ['kzam']},
	{'value': 'kṣamivas', 'label': 'kzamivas', 'attrib': [{'pos': 'pfap', 'defs': 'tolerate, forgive'}], 'isLemma': 0, 'parents': ['kzam']},
	{'value': 'kṣamiṣyat', 'label': 'kzamizyat', 'attrib': [{'pos': 'fap', 'defs': 'tolerate, forgive'}], 'isLemma': 0, 'parents': ['kzam']},
	{'value': 'kṣamiṣyamāṇa', 'label': 'kzamizyamARa', 'attrib': [{'pos': 'fpp', 'defs': 'tolerate, forgive'}], 'isLemma': 0, 'parents': ['kzam']},
	{'value': 'kṣamya', 'label': 'kzamya', 'attrib': [{'pos': 'ger', 'defs': 'tolerate, forgive'}], 'isLemma': 0, 'parents': ['kzam']},
	{'value': 'kṣamyamāṇa', 'label': 'kzamyamARa', 'attrib': [{'pos': 'prpp', 'defs': 'tolerate, forgive'}], 'isLemma': 0, 'parents': ['kzam']},
	{'value': 'kṣaya', 'label': 'kzaya', 'attrib': [{'pos': 'm', 'defs': 'destruction'}], 'isLemma': 1},
	{'value': 'kṣayanīya', 'label': 'kzayanIya', 'attrib': [{'pos': 'ger', 'defs': 'perish, destroy'}, {'pos': 'ger', 'defs': 'possess, rule'}, {'pos': 'ger', 'defs': 'destroy'}], 'isLemma': 0, 'parents': ['kzi']},
	{'value': 'kṣayya', 'label': 'kzayya', 'attrib': [{'pos': 'ger', 'defs': 'perish, destroy'}], 'isLemma': 0, 'parents': ['kzi']},
	{'value': 'kṣānta', 'label': 'kzAnta', 'attrib': [{'pos': 'ppp', 'defs': 'tolerate, forgive'}], 'isLemma': 0, 'parents': ['kzam']},
	{'value': 'kṣāntavat', 'label': 'kzAntavat', 'attrib': [{'pos': 'pap', 'defs': 'tolerate, forgive'}], 'isLemma': 0, 'parents': ['kzam']},
	{'value': 'kṣāmyat', 'label': 'kzAmyat', 'attrib': [{'pos': 'prap', 'defs': 'tolerate, forgive'}], 'isLemma': 0, 'parents': ['kzam']},
	{'value': 'kṣi', 'label': 'kzi', 'attrib': [{'pos': 'vt1a', 'defs': 'perish, destroy'}, {'pos': 'vt6a', 'defs': 'possess, rule'}, {'pos': 'vt5a', 'defs': 'destroy'}], 'isLemma': 1},
	{'value': 'kṣiṇvat', 'label': 'kziRvat', 'attrib': [{'pos': 'prap', 'defs': 'destroy'}], 'isLemma': 0, 'parents': ['kzi']},
	{'value': 'kṣit', 'label': 'kzit', 'attrib': [{'pos': 'adj', 'defs': 'ruling'}], 'isLemma': 1},
	{'value': 'kṣita', 'label': 'kzita', 'attrib': [{'pos': 'ppp', 'defs': 'perish, destroy'}, {'pos': 'ppp', 'defs': 'possess, rule'}, {'pos': 'ppp', 'defs': 'destroy'}], 'isLemma': 0, 'parents': ['kzi']},
	{'value': 'kṣitavat', 'label': 'kzitavat', 'attrib': [{'pos': 'pap', 'defs': 'perish, destroy'}, {'pos': 'pap', 'defs': 'possess, rule'}, {'pos': 'pap', 'defs': 'destroy'}], 'isLemma': 0, 'parents': ['kzi']},
	{'value': 'kṣitvā', 'label': 'kzitvA', 'attrib': [{'pos': 'abs', 'defs': 'perish, destroy'}, {'pos': 'abs', 'defs': 'possess, rule'}, {'pos': 'abs', 'defs': 'destroy'}], 'isLemma': 0, 'parents': ['kzi']},
	{'value': 'kṣip', 'label': 'kzip', 'attrib': [{'pos': 'vt4a, vt6am', 'defs': 'throw'}], 'isLemma': 1},
	{'value': 'kṣipat', 'label': 'kzipat', 'attrib': [{'pos': 'prap', 'defs': 'throw'}], 'isLemma': 0, 'parents': ['kzip']},
	{'value': 'kṣipta', 'label': 'kzipta', 'attrib': [{'pos': 'ppp', 'defs': 'throw'}], 'isLemma': 0, 'parents': ['kzip']},
	{'value': 'kṣiptavat', 'label': 'kziptavat', 'attrib': [{'pos': 'pap', 'defs': 'throw'}], 'isLemma': 0, 'parents': ['kzip']},
	{'value': 'kṣiptum', 'label': 'kziptum', 'attrib': [{'pos': 'inf', 'defs': 'throw'}], 'isLemma': 0, 'parents': ['kzip']},
	{'value': 'kṣiptvā', 'label': 'kziptvA', 'attrib': [{'pos': 'abs', 'defs': 'throw'}], 'isLemma': 0, 'parents': ['kzip']},
	{'value': 'kṣipyat', 'label': 'kzipyat', 'attrib': [{'pos': 'prap', 'defs': 'throw'}], 'isLemma': 0, 'parents': ['kzip']},
	{'value': 'kṣipyamāṇa', 'label': 'kzipyamARa', 'attrib': [{'pos': 'prmp, prpp', 'defs': 'throw'}], 'isLemma': 0, 'parents': ['kzip']},
	{'value': 'kṣipsyat', 'label': 'kzipsyat', 'attrib': [{'pos': 'fap', 'defs': 'throw'}], 'isLemma': 0, 'parents': ['kzip']},
	{'value': 'kṣipsyamāṇa', 'label': 'kzipsyamARa', 'attrib': [{'pos': 'fpp', 'defs': 'throw'}], 'isLemma': 0, 'parents': ['kzip']},
	{'value': 'kṣiyat', 'label': 'kziyat', 'attrib': [{'pos': 'prap', 'defs': 'perish, destroy'}, {'pos': 'prap', 'defs': 'possess, rule'}], 'isLemma': 0, 'parents': ['kzi']},
	{'value': 'kṣīṇa', 'label': 'kzIRa', 'attrib': [{'pos': 'ppp', 'defs': 'perish, destroy'}, {'pos': 'ppp', 'defs': 'possess, rule'}, {'pos': 'ppp', 'defs': 'destroy'}], 'isLemma': 0, 'parents': ['kzi']},
	{'value': 'kṣīṇavat', 'label': 'kzIRavat', 'attrib': [{'pos': 'pap', 'defs': 'perish, destroy'}, {'pos': 'pap', 'defs': 'possess, rule'}, {'pos': 'pap', 'defs': 'destroy'}], 'isLemma': 0, 'parents': ['kzi']},
	{'value': 'kṣīyamāṇa', 'label': 'kzIyamARa', 'attrib': [{'pos': 'prpp', 'defs': 'perish, destroy'}, {'pos': 'prpp', 'defs': 'possess, rule'}, {'pos': 'prpp', 'defs': 'destroy'}], 'isLemma': 0, 'parents': ['kzi']},
	{'value': 'kṣetavya', 'label': 'kzetavya', 'attrib': [{'pos': 'ger', 'defs': 'perish, destroy'}, {'pos': 'ger', 'defs': 'possess, rule'}, {'pos': 'ger', 'defs': 'destroy'}], 'isLemma': 0, 'parents': ['kzi']},
	{'value': 'kṣetum', 'label': 'kzetum', 'attrib': [{'pos': 'inf', 'defs': 'perish, destroy'}, {'pos': 'inf', 'defs': 'possess, rule'}, {'pos': 'inf', 'defs': 'destroy'}], 'isLemma': 0, 'parents': ['kzi']},
	{'value': 'kṣepaṇīya', 'label': 'kzepaRIya', 'attrib': [{'pos': 'ger', 'defs': 'throw'}], 'isLemma': 0, 'parents': ['kzip']},
	{'value': 'kṣeptavya', 'label': 'kzeptavya', 'attrib': [{'pos': 'ger', 'defs': 'throw'}], 'isLemma': 0, 'parents': ['kzip']},
	{'value': 'kṣepya', 'label': 'kzepya', 'attrib': [{'pos': 'ger', 'defs': 'throw'}], 'isLemma': 0, 'parents': ['kzip']},
	{'value': 'kṣepsyat', 'label': 'kzepsyat', 'attrib': [{'pos': 'fap', 'defs': 'throw'}], 'isLemma': 0, 'parents': ['kzip']},
	{'value': 'kṣepsyamāṇa', 'label': 'kzepsyamARa', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'throw'}], 'isLemma': 0, 'parents': ['kzip']},
	{'value': 'kṣeya', 'label': 'kzeya', 'attrib': [{'pos': 'ger', 'defs': 'perish, destroy'}, {'pos': 'ger', 'defs': 'possess, rule'}, {'pos': 'ger', 'defs': 'destroy'}], 'isLemma': 0, 'parents': ['kzi']},
	{'value': 'kṣeṣyat', 'label': 'kzezyat', 'attrib': [{'pos': 'fap', 'defs': 'perish, destroy'}, {'pos': 'fap', 'defs': 'possess, rule'}, {'pos': 'fap', 'defs': 'destroy'}], 'isLemma': 0, 'parents': ['kzi']},
	{'value': 'kṣeṣyamāṇa', 'label': 'kzezyamARa', 'attrib': [{'pos': 'fpp', 'defs': 'perish, destroy'}, {'pos': 'fpp', 'defs': 'possess, rule'}, {'pos': 'fpp', 'defs': 'destroy'}], 'isLemma': 0, 'parents': ['kzi']},
	{'value': 'khan', 'label': 'Kan', 'attrib': [{'pos': 'vt1am', 'defs': 'dig'}], 'isLemma': 1},
	{'value': 'khanitra', 'label': 'Kanitra', 'attrib': [{'pos': 'n', 'defs': 'instrument for digging, shovel'}], 'isLemma': 1},
	{'value': 'khanitrin', 'label': 'Kanitrin', 'attrib': [{'pos': 'm', 'defs': 'an excavator'}], 'isLemma': 1},
	{'value': 'khanitrī', 'label': 'KanitrI', 'attrib': [{'pos': 'f', 'defs': 'shovel'}], 'isLemma': 1},
	{'value': 'khāṇḍava', 'label': 'KARqava', 'attrib': [{'pos': 'm_pn, n_pn', 'defs': 'Khāṇḍava'}], 'isLemma': 1},
	{'value': 'khāṇḍavaprastha', 'label': 'KARqavaprasTa', 'attrib': [{'pos': 'm', 'defs': 'the Khāṇḍava plain'}], 'isLemma': 1},
	{'value': 'gaṅgā', 'label': 'gaNgA', 'attrib': [{'pos': 'f_pn', 'defs': 'the river Ganges'}], 'isLemma': 1},
	{'value': 'gacchat', 'label': 'gacCat', 'attrib': [{'pos': 'prap', 'defs': 'go'}], 'isLemma': 0, 'parents': ['gam']},
	{'value': 'gaṇa', 'label': 'gaRa', 'attrib': [{'pos': 'm', 'defs': 'group, multitude'}], 'isLemma': 1},
	{'value': 'gata', 'label': 'gata', 'attrib': [{'pos': 'ppp', 'defs': 'go'}], 'isLemma': 0, 'parents': ['gam']},
	{'value': 'gatamanyu', 'label': 'gatamanyu', 'attrib': [{'pos': 'adj', 'defs': 'free of anger'}], 'isLemma': 1},
	{'value': 'gatavat', 'label': 'gatavat', 'attrib': [{'pos': 'pap', 'defs': 'go'}], 'isLemma': 0, 'parents': ['gam']},
	{'value': 'gatavyatha', 'label': 'gatavyaTa', 'attrib': [{'pos': 'adj', 'defs': 'fearless, free of trembling'}], 'isLemma': 1},
	{'value': 'gatvā', 'label': 'gatvA', 'attrib': [{'pos': 'abs', 'defs': 'go'}], 'isLemma': 0, 'parents': ['gam']},
	{'value': 'gantavya', 'label': 'gantavya', 'attrib': [{'pos': 'ger', 'defs': 'go'}], 'isLemma': 0, 'parents': ['gam']},
	{'value': 'gantum', 'label': 'gantum', 'attrib': [{'pos': 'inf', 'defs': 'go'}], 'isLemma': 0, 'parents': ['gam']},
	{'value': 'gam', 'label': 'gam', 'attrib': [{'pos': 'vt1a', 'defs': 'go'}], 'isLemma': 1},
	{'value': 'gamanīya', 'label': 'gamanIya', 'attrib': [{'pos': 'ger', 'defs': 'go'}], 'isLemma': 0, 'parents': ['gam']},
	{'value': 'gamiṣyat', 'label': 'gamizyat', 'attrib': [{'pos': 'fap', 'defs': 'go'}], 'isLemma': 0, 'parents': ['gam']},
	{'value': 'gamiṣyamāṇa', 'label': 'gamizyamARa', 'attrib': [{'pos': 'fpp', 'defs': 'go'}], 'isLemma': 0, 'parents': ['gam']},
	{'value': 'gamya', 'label': 'gamya', 'attrib': [{'pos': 'ger', 'defs': 'go'}], 'isLemma': 0, 'parents': ['gam']},
	{'value': 'gamyamāna', 'label': 'gamyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'go'}], 'isLemma': 0, 'parents': ['gam']},
	{'value': 'gāṇḍī', 'label': 'gARqI', 'attrib': [{'pos': 'f', 'defs': 'rhinoceros'}], 'isLemma': 1},
	{'value': 'gāṇḍīva', 'label': 'gARqIva', 'attrib': [{'pos': 'm_pn, n_pn', 'defs': 'Gāṇḍīva'}], 'isLemma': 1},
	{'value': 'guru', 'label': 'guru', 'attrib': [{'pos': 'adj', 'defs': 'heavy'}, {'pos': 'm', 'defs': 'teacher, elder'}], 'isLemma': 1},
	{'value': 'gṛha', 'label': 'gfha', 'attrib': [{'pos': 'n', 'defs': 'room, house'}], 'isLemma': 1},
	{'value': 'grah', 'label': 'grah', 'attrib': [{'pos': 'vt9a', 'defs': 'grab'}], 'isLemma': 1},
	{'value': 'ghātya', 'label': 'GAtya', 'attrib': [{'pos': 'ger', 'defs': 'smite'}], 'isLemma': 0, 'parents': ['han']},
	{'value': 'ghora', 'label': 'Gora', 'attrib': [{'pos': 'adj', 'defs': 'awful'}], 'isLemma': 1},
	{'value': 'ghnat', 'label': 'Gnat', 'attrib': [{'pos': 'prap', 'defs': 'smite'}], 'isLemma': 0, 'parents': ['han']},
	{'value': 'ca', 'label': 'ca', 'attrib': [{'pos': 'conj_pcl', 'defs': 'and'}], 'isLemma': 1},
	{'value': 'cakṛvas', 'label': 'cakfvas', 'attrib': [{'pos': 'pfap', 'defs': 'do, make'}], 'isLemma': 0, 'parents': ['kf']},
	{'value': 'cakra', 'label': 'cakra', 'attrib': [{'pos': 'n', 'defs': 'wheel, discus, circle'}], 'isLemma': 1},
	{'value': 'cakrāṇa', 'label': 'cakrARa', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'do, make'}], 'isLemma': 0, 'parents': ['kf']},
	{'value': 'catur', 'label': 'catur', 'attrib': [{'pos': 'card_num', 'defs': 'four'}], 'isLemma': 1},
	{'value': 'caturdaśa', 'label': 'caturdaSa', 'attrib': [{'pos': 'ord_num', 'defs': 'fourteenth'}], 'isLemma': 1},
	{'value': 'cana', 'label': 'cana', 'attrib': [{'pos': 'pcl', 'defs': '/'}], 'isLemma': 1},
	{'value': 'cayanīya', 'label': 'cayanIya', 'attrib': [{'pos': 'ger', 'defs': 'pile up'}], 'isLemma': 0, 'parents': ['ci']},
	{'value': 'car', 'label': 'car', 'attrib': [{'pos': 'vt1am', 'defs': 'move, do'}], 'isLemma': 1},
	{'value': 'caraṇīya', 'label': 'caraRIya', 'attrib': [{'pos': 'ger', 'defs': 'move, do'}], 'isLemma': 0, 'parents': ['car']},
	{'value': 'carat', 'label': 'carat', 'attrib': [{'pos': 'prap', 'defs': 'move, do'}], 'isLemma': 0, 'parents': ['car']},
	{'value': 'caramāṇa', 'label': 'caramARa', 'attrib': [{'pos': 'prmp', 'defs': 'move, do'}], 'isLemma': 0, 'parents': ['car']},
	{'value': 'carita', 'label': 'carita', 'attrib': [{'pos': 'ppp', 'defs': 'move, do'}], 'isLemma': 0, 'parents': ['car']},
	{'value': 'caritavat', 'label': 'caritavat', 'attrib': [{'pos': 'pap', 'defs': 'move, do'}], 'isLemma': 0, 'parents': ['car']},
	{'value': 'caritavya', 'label': 'caritavya', 'attrib': [{'pos': 'ger', 'defs': 'move, do'}], 'isLemma': 0, 'parents': ['car']},
	{'value': 'caritum', 'label': 'caritum', 'attrib': [{'pos': 'inf', 'defs': 'move, do'}], 'isLemma': 0, 'parents': ['car']},
	{'value': 'caritvā', 'label': 'caritvA', 'attrib': [{'pos': 'abs', 'defs': 'move, do'}], 'isLemma': 0, 'parents': ['car']},
	{'value': 'cariṣyat', 'label': 'carizyat', 'attrib': [{'pos': 'fap', 'defs': 'move, do'}], 'isLemma': 0, 'parents': ['car']},
	{'value': 'cariṣyamāṇa', 'label': 'carizyamARa', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'move, do'}], 'isLemma': 0, 'parents': ['car']},
	{'value': 'carya', 'label': 'carya', 'attrib': [{'pos': 'ger', 'defs': 'move, do'}], 'isLemma': 0, 'parents': ['car']},
	{'value': 'caryamāṇa', 'label': 'caryamARa', 'attrib': [{'pos': 'prpp', 'defs': 'move, do'}], 'isLemma': 0, 'parents': ['car']},
	{'value': 'ci', 'label': 'ci', 'attrib': [{'pos': 'vt5am', 'defs': 'pile up'}], 'isLemma': 1},
	{'value': 'cikivas', 'label': 'cikivas', 'attrib': [{'pos': 'pfap', 'defs': 'pile up'}], 'isLemma': 0, 'parents': ['ci']},
	{'value': 'cikliśāna', 'label': 'cikliSAna', 'attrib': [{'pos': 'pfpp', 'defs': 'torment, afflict'}], 'isLemma': 0, 'parents': ['kliS']},
	{'value': 'cikliśivas', 'label': 'cikliSivas', 'attrib': [{'pos': 'pfap', 'defs': 'torment, afflict'}], 'isLemma': 0, 'parents': ['kliS']},
	{'value': 'cikṣipāṇa', 'label': 'cikzipARa', 'attrib': [{'pos': 'pfpp', 'defs': 'throw'}], 'isLemma': 0, 'parents': ['kzip']},
	{'value': 'cikṣipivas', 'label': 'cikzipivas', 'attrib': [{'pos': 'pfap', 'defs': 'throw'}], 'isLemma': 0, 'parents': ['kzip']},
	{'value': 'cikṣiyāṇa', 'label': 'cikziyARa', 'attrib': [{'pos': 'pfpp', 'defs': 'perish, destroy'}, {'pos': 'pfpp', 'defs': 'possess, rule'}, {'pos': 'pfpp', 'defs': 'destroy'}], 'isLemma': 0, 'parents': ['kzi']},
	{'value': 'cikṣivas', 'label': 'cikzivas', 'attrib': [{'pos': 'pfap', 'defs': 'perish, destroy'}, {'pos': 'pfap', 'defs': 'possess, rule'}, {'pos': 'pfap', 'defs': 'destroy'}], 'isLemma': 0, 'parents': ['kzi']},
	{'value': 'cicivas', 'label': 'cicivas', 'attrib': [{'pos': 'pfap', 'defs': 'pile up'}], 'isLemma': 0, 'parents': ['ci']},
	{'value': 'cicchidāna', 'label': 'cicCidAna', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'split, cut'}], 'isLemma': 0, 'parents': ['Cid']},
	{'value': 'cicchidvas', 'label': 'cicCidvas', 'attrib': [{'pos': 'pfap', 'defs': 'split, cut'}], 'isLemma': 0, 'parents': ['Cid']},
	{'value': 'cit', 'label': 'cit', 'attrib': [{'pos': 'pcl', 'defs': '/'}], 'isLemma': 1},
	{'value': 'cita', 'label': 'cita', 'attrib': [{'pos': 'ppp', 'defs': 'pile up'}], 'isLemma': 0, 'parents': ['ci']},
	{'value': 'citavat', 'label': 'citavat', 'attrib': [{'pos': 'pap', 'defs': 'pile up'}], 'isLemma': 0, 'parents': ['ci']},
	{'value': 'citvā', 'label': 'citvA', 'attrib': [{'pos': 'abs', 'defs': 'pile up'}], 'isLemma': 0, 'parents': ['ci']},
	{'value': 'cint', 'label': 'cint', 'attrib': [{'pos': 'vt10am', 'defs': 'consider'}], 'isLemma': 1},
	{'value': 'cintanīya', 'label': 'cintanIya', 'attrib': [{'pos': 'ger', 'defs': 'consider'}], 'isLemma': 0, 'parents': ['cint']},
	{'value': 'cintayat', 'label': 'cintayat', 'attrib': [{'pos': 'prap', 'defs': 'consider'}], 'isLemma': 0, 'parents': ['cint']},
	{'value': 'cintayamāna', 'label': 'cintayamAna', 'attrib': [{'pos': 'prmp', 'defs': 'consider'}], 'isLemma': 0, 'parents': ['cint']},
	{'value': 'cintayām', 'label': 'cintayAm', 'attrib': [{'pos': 'ppf_actn', 'defs': 'consider'}], 'isLemma': 0, 'parents': ['cint']},
	{'value': 'cintayiṣyat', 'label': 'cintayizyat', 'attrib': [{'pos': 'fap', 'defs': 'consider'}], 'isLemma': 0, 'parents': ['cint']},
	{'value': 'cintayiṣyamāṇa', 'label': 'cintayizyamARa', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'consider'}], 'isLemma': 0, 'parents': ['cint']},
	{'value': 'cintita', 'label': 'cintita', 'attrib': [{'pos': 'ppp', 'defs': 'consider'}], 'isLemma': 0, 'parents': ['cint']},
	{'value': 'cintitavat', 'label': 'cintitavat', 'attrib': [{'pos': 'pap', 'defs': 'consider'}], 'isLemma': 0, 'parents': ['cint']},
	{'value': 'cintitavya', 'label': 'cintitavya', 'attrib': [{'pos': 'ger', 'defs': 'consider'}], 'isLemma': 0, 'parents': ['cint']},
	{'value': 'cintitum', 'label': 'cintitum', 'attrib': [{'pos': 'inf', 'defs': 'consider'}], 'isLemma': 0, 'parents': ['cint']},
	{'value': 'cintitvā', 'label': 'cintitvA', 'attrib': [{'pos': 'abs', 'defs': 'consider'}], 'isLemma': 0, 'parents': ['cint']},
	{'value': 'cintya', 'label': 'cintya', 'attrib': [{'pos': 'ger', 'defs': 'consider'}], 'isLemma': 0, 'parents': ['cint']},
	{'value': 'cintyamāna', 'label': 'cintyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'consider'}], 'isLemma': 0, 'parents': ['cint']},
	{'value': 'cinvat', 'label': 'cinvat', 'attrib': [{'pos': 'prap', 'defs': 'pile up'}], 'isLemma': 0, 'parents': ['ci']},
	{'value': 'cinvāna', 'label': 'cinvAna', 'attrib': [{'pos': 'prmp', 'defs': 'pile up'}], 'isLemma': 0, 'parents': ['ci']},
	{'value': 'ciyāna', 'label': 'ciyAna', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'pile up'}], 'isLemma': 0, 'parents': ['ci']},
	{'value': 'cira', 'label': 'cira', 'attrib': [{'pos': 'adj', 'defs': 'long'}, {'pos': 'n', 'defs': 'delay'}], 'isLemma': 1},
	{'value': 'cīyamāṇa', 'label': 'cIyamARa', 'attrib': [{'pos': 'prpp', 'defs': 'pile up'}], 'isLemma': 0, 'parents': ['ci']},
	{'value': 'cukrudhāna', 'label': 'cukruDAna', 'attrib': [{'pos': 'pfpp', 'defs': 'be angry'}], 'isLemma': 0, 'parents': ['kruD']},
	{'value': 'cukrudhvas', 'label': 'cukruDvas', 'attrib': [{'pos': 'pfap', 'defs': 'be angry'}], 'isLemma': 0, 'parents': ['kruD']},
	{'value': 'cetavya', 'label': 'cetavya', 'attrib': [{'pos': 'ger', 'defs': 'pile up'}], 'isLemma': 0, 'parents': ['ci']},
	{'value': 'cetum', 'label': 'cetum', 'attrib': [{'pos': 'inf', 'defs': 'pile up'}], 'isLemma': 0, 'parents': ['ci']},
	{'value': 'ceya', 'label': 'ceya', 'attrib': [{'pos': 'ger', 'defs': 'pile up'}], 'isLemma': 0, 'parents': ['ci']},
	{'value': 'cerāṇa', 'label': 'cerARa', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'move, do'}], 'isLemma': 0, 'parents': ['car']},
	{'value': 'cerivas', 'label': 'cerivas', 'attrib': [{'pos': 'pfap', 'defs': 'move, do'}], 'isLemma': 0, 'parents': ['car']},
	{'value': 'ceṣyat', 'label': 'cezyat', 'attrib': [{'pos': 'fap', 'defs': 'pile up'}], 'isLemma': 0, 'parents': ['ci']},
	{'value': 'ceṣyamāṇa', 'label': 'cezyamARa', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'pile up'}], 'isLemma': 0, 'parents': ['ci']},
	{'value': 'chittvā', 'label': 'CittvA', 'attrib': [{'pos': 'abs', 'defs': 'split, cut'}], 'isLemma': 0, 'parents': ['Cid']},
	{'value': 'chid', 'label': 'Cid', 'attrib': [{'pos': 'vt7am', 'defs': 'split, cut'}], 'isLemma': 1},
	{'value': 'chidyamāna', 'label': 'CidyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'split, cut'}], 'isLemma': 0, 'parents': ['Cid']},
	{'value': 'chindat', 'label': 'Cindat', 'attrib': [{'pos': 'prap', 'defs': 'split, cut'}], 'isLemma': 0, 'parents': ['Cid']},
	{'value': 'chindāna', 'label': 'CindAna', 'attrib': [{'pos': 'prmp', 'defs': 'split, cut'}], 'isLemma': 0, 'parents': ['Cid']},
	{'value': 'chinna', 'label': 'Cinna', 'attrib': [{'pos': 'ppp', 'defs': 'split, cut'}], 'isLemma': 0, 'parents': ['Cid']},
	{'value': 'chinnavat', 'label': 'Cinnavat', 'attrib': [{'pos': 'pap', 'defs': 'split, cut'}], 'isLemma': 0, 'parents': ['Cid']},
	{'value': 'chettavya', 'label': 'Cettavya', 'attrib': [{'pos': 'ger', 'defs': 'split, cut'}], 'isLemma': 0, 'parents': ['Cid']},
	{'value': 'chettum', 'label': 'Cettum', 'attrib': [{'pos': 'inf', 'defs': 'split, cut'}], 'isLemma': 0, 'parents': ['Cid']},
	{'value': 'chetsyat', 'label': 'Cetsyat', 'attrib': [{'pos': 'fap', 'defs': 'split, cut'}], 'isLemma': 0, 'parents': ['Cid']},
	{'value': 'chetsyamāna', 'label': 'CetsyamAna', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'split, cut'}], 'isLemma': 0, 'parents': ['Cid']},
	{'value': 'chedanīya', 'label': 'CedanIya', 'attrib': [{'pos': 'ger', 'defs': 'split, cut'}], 'isLemma': 0, 'parents': ['Cid']},
	{'value': 'chedya', 'label': 'Cedya', 'attrib': [{'pos': 'ger', 'defs': 'split, cut'}], 'isLemma': 0, 'parents': ['Cid']},
	{'value': 'ja', 'label': 'ja', 'attrib': [{'pos': 'adj', 'defs': 'born'}], 'isLemma': 1},
	{'value': 'jaganvas', 'label': 'jaganvas', 'attrib': [{'pos': 'pfap', 'defs': 'go'}], 'isLemma': 0, 'parents': ['gam']},
	{'value': 'jagmāna', 'label': 'jagmAna', 'attrib': [{'pos': 'pfpp', 'defs': 'go'}], 'isLemma': 0, 'parents': ['gam']},
	{'value': 'jagmivas', 'label': 'jagmivas', 'attrib': [{'pos': 'pfap', 'defs': 'go'}], 'isLemma': 0, 'parents': ['gam']},
	{'value': 'jaghanvas', 'label': 'jaGanvas', 'attrib': [{'pos': 'pfap', 'defs': 'smite'}], 'isLemma': 0, 'parents': ['han']},
	{'value': 'jaghnāna', 'label': 'jaGnAna', 'attrib': [{'pos': 'pfpp', 'defs': 'smite'}], 'isLemma': 0, 'parents': ['han']},
	{'value': 'jaghnivas', 'label': 'jaGnivas', 'attrib': [{'pos': 'pfap', 'defs': 'smite'}], 'isLemma': 0, 'parents': ['han']},
	{'value': 'jajarāṇa', 'label': 'jajarARa', 'attrib': [{'pos': 'pfpp', 'defs': 'wither, age, grow old; caus.: digest'}], 'isLemma': 0, 'parents': ['jF']},
	{'value': 'jajarvas', 'label': 'jajarvas', 'attrib': [{'pos': 'pfap', 'defs': 'wither, age, grow old; caus.: digest'}], 'isLemma': 0, 'parents': ['jF']},
	{'value': 'jajñāna', 'label': 'jajYAna', 'attrib': [{'pos': 'pfpp', 'defs': 'know'}], 'isLemma': 0, 'parents': ['jYA']},
	{'value': 'jajñivas', 'label': 'jajYivas', 'attrib': [{'pos': 'pfap', 'defs': 'know'}], 'isLemma': 0, 'parents': ['jYA']},
	{'value': 'jatu', 'label': 'jatu', 'attrib': [{'pos': 'n', 'defs': 'lac'}], 'isLemma': 1},
	{'value': 'jan', 'label': 'jan', 'attrib': [{'pos': 'vi4m', 'defs': 'be born'}], 'isLemma': 1},
	{'value': 'jana', 'label': 'jana', 'attrib': [{'pos': 'm', 'defs': 'people'}], 'isLemma': 1},
	{'value': 'janapada', 'label': 'janapada', 'attrib': [{'pos': 'n', 'defs': 'district'}], 'isLemma': 1},
	{'value': 'janapadopeta', 'label': 'janapadopeta', 'attrib': [{'pos': 'adj', 'defs': 'endowed with a district'}], 'isLemma': 1},
	{'value': 'janamejaya', 'label': 'janamejaya', 'attrib': [{'pos': 'm_pn', 'defs': 'Janamejaya'}], 'isLemma': 1},
	{'value': 'jaya', 'label': 'jaya', 'attrib': [{'pos': 'm', 'defs': 'victory'}], 'isLemma': 1},
	{'value': 'jayat', 'label': 'jayat', 'attrib': [{'pos': 'prap', 'defs': 'conquer'}], 'isLemma': 0, 'parents': ['ji']},
	{'value': 'jayanīya', 'label': 'jayanIya', 'attrib': [{'pos': 'ger', 'defs': 'conquer'}], 'isLemma': 0, 'parents': ['ji']},
	{'value': 'jaraṇīya', 'label': 'jaraRIya', 'attrib': [{'pos': 'ger', 'defs': 'wither, age, grow old; caus.: digest'}], 'isLemma': 0, 'parents': ['jF']},
	{'value': 'jarayām', 'label': 'jarayAm', 'attrib': [{'pos': 'ppf_actn', 'defs': 'wither, age, grow old; caus.: digest'}], 'isLemma': 0, 'parents': ['jF']},
	{'value': 'jaritavya', 'label': 'jaritavya', 'attrib': [{'pos': 'ger', 'defs': 'wither, age, grow old; caus.: digest'}], 'isLemma': 0, 'parents': ['jF']},
	{'value': 'jaritum', 'label': 'jaritum', 'attrib': [{'pos': 'inf', 'defs': 'wither, age, grow old; caus.: digest'}], 'isLemma': 0, 'parents': ['jF']},
	{'value': 'jaritvā', 'label': 'jaritvA', 'attrib': [{'pos': 'abs', 'defs': 'wither, age, grow old; caus.: digest'}], 'isLemma': 0, 'parents': ['jF']},
	{'value': 'jariṣyat', 'label': 'jarizyat', 'attrib': [{'pos': 'fap', 'defs': 'wither, age, grow old; caus.: digest'}], 'isLemma': 0, 'parents': ['jF']},
	{'value': 'jariṣyamāṇa', 'label': 'jarizyamARa', 'attrib': [{'pos': 'fpp', 'defs': 'wither, age, grow old; caus.: digest'}], 'isLemma': 0, 'parents': ['jF']},
	{'value': 'jarītavya', 'label': 'jarItavya', 'attrib': [{'pos': 'ger', 'defs': 'wither, age, grow old; caus.: digest'}], 'isLemma': 0, 'parents': ['jF']},
	{'value': 'jarītum', 'label': 'jarItum', 'attrib': [{'pos': 'inf', 'defs': 'wither, age, grow old; caus.: digest'}], 'isLemma': 0, 'parents': ['jF']},
	{'value': 'jarītvā', 'label': 'jarItvA', 'attrib': [{'pos': 'abs', 'defs': 'wither, age, grow old; caus.: digest'}], 'isLemma': 0, 'parents': ['jF']},
	{'value': 'jarya', 'label': 'jarya', 'attrib': [{'pos': 'ger', 'defs': 'wither, age, grow old; caus.: digest'}], 'isLemma': 0, 'parents': ['jF']},
	{'value': 'jātuṣa', 'label': 'jAtuza', 'attrib': [{'pos': 'adj', 'defs': 'made of lac'}], 'isLemma': 1},
	{'value': 'jānat', 'label': 'jAnat', 'attrib': [{'pos': 'prap', 'defs': 'know'}], 'isLemma': 0, 'parents': ['jYA']},
	{'value': 'jārya', 'label': 'jArya', 'attrib': [{'pos': 'ger', 'defs': 'wither, age, grow old; caus.: digest'}], 'isLemma': 0, 'parents': ['jF']},
	{'value': 'ji', 'label': 'ji', 'attrib': [{'pos': 'vt1a', 'defs': 'conquer'}], 'isLemma': 1},
	{'value': 'jigivas', 'label': 'jigivas', 'attrib': [{'pos': 'pfap', 'defs': 'conquer'}], 'isLemma': 0, 'parents': ['ji']},
	{'value': 'jigyāna', 'label': 'jigyAna', 'attrib': [{'pos': 'pfpp', 'defs': 'conquer'}], 'isLemma': 0, 'parents': ['ji']},
	{'value': 'jijīvas', 'label': 'jijIvas', 'attrib': [{'pos': 'pfap', 'defs': 'live'}], 'isLemma': 0, 'parents': ['jIv']},
	{'value': 'jijīvāna', 'label': 'jijIvAna', 'attrib': [{'pos': 'pfpp', 'defs': 'live'}], 'isLemma': 0, 'parents': ['jIv']},
	{'value': 'jita', 'label': 'jita', 'attrib': [{'pos': 'ppp', 'defs': 'conquer'}], 'isLemma': 0, 'parents': ['ji']},
	{'value': 'jitavat', 'label': 'jitavat', 'attrib': [{'pos': 'pap', 'defs': 'conquer'}], 'isLemma': 0, 'parents': ['ji']},
	{'value': 'jitvā', 'label': 'jitvA', 'attrib': [{'pos': 'abs', 'defs': 'conquer'}], 'isLemma': 0, 'parents': ['ji']},
	{'value': 'jīyamāna', 'label': 'jIyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'conquer'}], 'isLemma': 0, 'parents': ['ji']},
	{'value': 'jīrṇa', 'label': 'jIrRa', 'attrib': [{'pos': 'ppp', 'defs': 'wither, age, grow old; caus.: digest'}], 'isLemma': 0, 'parents': ['jF']},
	{'value': 'jīrṇavat', 'label': 'jIrRavat', 'attrib': [{'pos': 'pap', 'defs': 'wither, age, grow old; caus.: digest'}], 'isLemma': 0, 'parents': ['jF']},
	{'value': 'jīryat', 'label': 'jIryat', 'attrib': [{'pos': 'prap', 'defs': 'wither, age, grow old; caus.: digest'}], 'isLemma': 0, 'parents': ['jF']},
	{'value': 'jīryamāṇa', 'label': 'jIryamARa', 'attrib': [{'pos': 'prpp', 'defs': 'wither, age, grow old; caus.: digest'}], 'isLemma': 0, 'parents': ['jF']},
	{'value': 'jīv', 'label': 'jIv', 'attrib': [{'pos': 'vi1a', 'defs': 'live'}], 'isLemma': 1},
	{'value': 'jīva', 'label': 'jIva', 'attrib': [{'pos': 'm', 'defs': 'a living being, individual soul'}], 'isLemma': 1},
	{'value': 'jīvat', 'label': 'jIvat', 'attrib': [{'pos': 'prap', 'defs': 'live'}], 'isLemma': 0, 'parents': ['jIv']},
	{'value': 'jīvanīya', 'label': 'jIvanIya', 'attrib': [{'pos': 'ger', 'defs': 'live'}], 'isLemma': 0, 'parents': ['jIv']},
	{'value': 'jīvaloka', 'label': 'jIvaloka', 'attrib': [{'pos': 'm', 'defs': 'the world of living beings'}], 'isLemma': 1},
	{'value': 'jīvita', 'label': 'jIvita', 'attrib': [{'pos': 'ppp', 'defs': 'live'}], 'isLemma': 0, 'parents': ['jIv']},
	{'value': 'jīvitavat', 'label': 'jIvitavat', 'attrib': [{'pos': 'pap', 'defs': 'live'}], 'isLemma': 0, 'parents': ['jIv']},
	{'value': 'jīvitavya', 'label': 'jIvitavya', 'attrib': [{'pos': 'ger', 'defs': 'live'}], 'isLemma': 0, 'parents': ['jIv']},
	{'value': 'jīvitum', 'label': 'jIvitum', 'attrib': [{'pos': 'inf', 'defs': 'live'}], 'isLemma': 0, 'parents': ['jIv']},
	{'value': 'jīvitvā', 'label': 'jIvitvA', 'attrib': [{'pos': 'abs', 'defs': 'live'}], 'isLemma': 0, 'parents': ['jIv']},
	{'value': 'jīviṣyat', 'label': 'jIvizyat', 'attrib': [{'pos': 'fap', 'defs': 'live'}], 'isLemma': 0, 'parents': ['jIv']},
	{'value': 'jīviṣyamāṇa', 'label': 'jIvizyamARa', 'attrib': [{'pos': 'fpp', 'defs': 'live'}], 'isLemma': 0, 'parents': ['jIv']},
	{'value': 'jīvya', 'label': 'jIvya', 'attrib': [{'pos': 'ger', 'defs': 'live'}], 'isLemma': 0, 'parents': ['jIv']},
	{'value': 'jīvyamāna', 'label': 'jIvyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'live'}], 'isLemma': 0, 'parents': ['jIv']},
	{'value': 'juhavām', 'label': 'juhavAm', 'attrib': [{'pos': 'ppf_actn', 'defs': 'offer, worship'}], 'isLemma': 0, 'parents': ['hu']},
	{'value': 'juhvat', 'label': 'juhvat', 'attrib': [{'pos': 'prap', 'defs': 'offer, worship'}], 'isLemma': 0, 'parents': ['hu']},
	{'value': 'jṝ', 'label': 'jF', 'attrib': [{'pos': 'vi4a', 'defs': 'wither, age, grow old; caus.: digest'}], 'isLemma': 1},
	{'value': 'jetavya', 'label': 'jetavya', 'attrib': [{'pos': 'ger', 'defs': 'conquer'}], 'isLemma': 0, 'parents': ['ji']},
	{'value': 'jetum', 'label': 'jetum', 'attrib': [{'pos': 'inf', 'defs': 'conquer'}], 'isLemma': 0, 'parents': ['ji']},
	{'value': 'jeya', 'label': 'jeya', 'attrib': [{'pos': 'ger', 'defs': 'conquer'}], 'isLemma': 0, 'parents': ['ji']},
	{'value': 'jerāṇa', 'label': 'jerARa', 'attrib': [{'pos': 'pfpp', 'defs': 'wither, age, grow old; caus.: digest'}], 'isLemma': 0, 'parents': ['jF']},
	{'value': 'jerivas', 'label': 'jerivas', 'attrib': [{'pos': 'pfap', 'defs': 'wither, age, grow old; caus.: digest'}], 'isLemma': 0, 'parents': ['jF']},
	{'value': 'jeṣyat', 'label': 'jezyat', 'attrib': [{'pos': 'fap', 'defs': 'conquer'}], 'isLemma': 0, 'parents': ['ji']},
	{'value': 'jeṣyamāṇa', 'label': 'jezyamARa', 'attrib': [{'pos': 'fpp', 'defs': 'conquer'}], 'isLemma': 0, 'parents': ['ji']},
	{'value': 'jñā', 'label': 'jYA', 'attrib': [{'pos': 'vt9am', 'defs': 'know'}], 'isLemma': 1},
	{'value': 'jñāta', 'label': 'jYAta', 'attrib': [{'pos': 'ppp', 'defs': 'know'}], 'isLemma': 0, 'parents': ['jYA']},
	{'value': 'jñātavat', 'label': 'jYAtavat', 'attrib': [{'pos': 'pap', 'defs': 'know'}], 'isLemma': 0, 'parents': ['jYA']},
	{'value': 'jñātavya', 'label': 'jYAtavya', 'attrib': [{'pos': 'ger', 'defs': 'know'}], 'isLemma': 0, 'parents': ['jYA']},
	{'value': 'jñātum', 'label': 'jYAtum', 'attrib': [{'pos': 'inf', 'defs': 'know'}], 'isLemma': 0, 'parents': ['jYA']},
	{'value': 'jñātvā', 'label': 'jYAtvA', 'attrib': [{'pos': 'abs', 'defs': 'know'}], 'isLemma': 0, 'parents': ['jYA']},
	{'value': 'jñānīya', 'label': 'jYAnIya', 'attrib': [{'pos': 'ger', 'defs': 'know'}], 'isLemma': 0, 'parents': ['jYA']},
	{'value': 'jñāpya', 'label': 'jYApya', 'attrib': [{'pos': 'abs', 'defs': 'know'}], 'isLemma': 0, 'parents': ['jYA']},
	{'value': 'jñāyamāna', 'label': 'jYAyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'know'}], 'isLemma': 0, 'parents': ['jYA']},
	{'value': 'jñāsyat', 'label': 'jYAsyat', 'attrib': [{'pos': 'fap', 'defs': 'know'}], 'isLemma': 0, 'parents': ['jYA']},
	{'value': 'jñāsyamāna', 'label': 'jYAsyamAna', 'attrib': [{'pos': 'fpp', 'defs': 'know'}], 'isLemma': 0, 'parents': ['jYA']},
	{'value': 'jñeya', 'label': 'jYeya', 'attrib': [{'pos': 'ger', 'defs': 'know'}], 'isLemma': 0, 'parents': ['jYA']},
	{'value': 'taṁsyat', 'label': 'taMsyat', 'attrib': [{'pos': 'fap', 'defs': 'extend'}], 'isLemma': 0, 'parents': ['tan']},
	{'value': 'taṁsyamāna', 'label': 'taMsyamAna', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'extend'}], 'isLemma': 0, 'parents': ['tan']},
	{'value': 'tata', 'label': 'tata', 'attrib': [{'pos': 'ppp', 'defs': 'extend'}], 'isLemma': 0, 'parents': ['tan']},
	{'value': 'tatavat', 'label': 'tatavat', 'attrib': [{'pos': 'pap', 'defs': 'extend'}], 'isLemma': 0, 'parents': ['tan']},
	{'value': 'tatas', 'label': 'tatas', 'attrib': [{'pos': 'dem_adv', 'defs': 'after that, therefore, then'}], 'isLemma': 1},
	{'value': 'tatṛpivas', 'label': 'tatfpivas', 'attrib': [{'pos': 'pfap', 'defs': 'become satisfied, be pleased; caus. satiate, satisfy, please'}], 'isLemma': 0, 'parents': ['tfp']},
	{'value': 'tatra', 'label': 'tatra', 'attrib': [{'pos': 'dem_adv', 'defs': 'in that, there'}], 'isLemma': 1},
	{'value': 'tathā', 'label': 'taTA', 'attrib': [{'pos': 'dem_adv', 'defs': 'thus'}], 'isLemma': 1},
	{'value': 'tad', 'label': 'tad', 'attrib': [{'pos': 'dem_adj, dem_pron', 'defs': 'that'}], 'isLemma': 1},
	{'value': 'tadā', 'label': 'tadA', 'attrib': [{'pos': 'dem_adv', 'defs': 'at that time, then'}], 'isLemma': 1},
	{'value': 'tan', 'label': 'tan', 'attrib': [{'pos': 'vt8am', 'defs': 'extend'}], 'isLemma': 1},
	{'value': 'tananīya', 'label': 'tananIya', 'attrib': [{'pos': 'ger', 'defs': 'extend'}], 'isLemma': 0, 'parents': ['tan']},
	{'value': 'tanitavya', 'label': 'tanitavya', 'attrib': [{'pos': 'ger', 'defs': 'extend'}], 'isLemma': 0, 'parents': ['tan']},
	{'value': 'tanitum', 'label': 'tanitum', 'attrib': [{'pos': 'inf', 'defs': 'extend'}], 'isLemma': 0, 'parents': ['tan']},
	{'value': 'tanitvā', 'label': 'tanitvA', 'attrib': [{'pos': 'abs', 'defs': 'extend'}], 'isLemma': 0, 'parents': ['tan']},
	{'value': 'taniṣyat', 'label': 'tanizyat', 'attrib': [{'pos': 'fap', 'defs': 'extend'}], 'isLemma': 0, 'parents': ['tan']},
	{'value': 'taniṣyamāṇa', 'label': 'tanizyamARa', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'extend'}], 'isLemma': 0, 'parents': ['tan']},
	{'value': 'tanu', 'label': 'tanu', 'attrib': [{'pos': 'adj', 'defs': 'thin'}, {'pos': 'f', 'defs': 'body, self'}], 'isLemma': 1},
	{'value': 'tanyamāna', 'label': 'tanyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'extend'}], 'isLemma': 0, 'parents': ['tan']},
	{'value': 'tanvat', 'label': 'tanvat', 'attrib': [{'pos': 'prap', 'defs': 'extend'}], 'isLemma': 0, 'parents': ['tan']},
	{'value': 'tanvāna', 'label': 'tanvAna', 'attrib': [{'pos': 'prmp', 'defs': 'extend'}], 'isLemma': 0, 'parents': ['tan']},
	{'value': 'tap', 'label': 'tap', 'attrib': [{'pos': 'vi4m', 'defs': 'burn, feel pain'}, {'pos': 'vt1a', 'defs': 'heat, scorch'}], 'isLemma': 1},
	{'value': 'tapa', 'label': 'tapa', 'attrib': [{'pos': 'adj', 'defs': 'scorching'}], 'isLemma': 1},
	{'value': 'tapat', 'label': 'tapat', 'attrib': [{'pos': 'prap', 'defs': 'heat, scorch'}], 'isLemma': 0, 'parents': ['tap']},
	{'value': 'tapanīya', 'label': 'tapanIya', 'attrib': [{'pos': 'ger', 'defs': 'heat, scorch'}], 'isLemma': 0, 'parents': ['tap']},
	{'value': 'tapta', 'label': 'tapta', 'attrib': [{'pos': 'ppp', 'defs': 'heat, scorch'}], 'isLemma': 0, 'parents': ['tap']},
	{'value': 'taptavat', 'label': 'taptavat', 'attrib': [{'pos': 'pap', 'defs': 'heat, scorch'}], 'isLemma': 0, 'parents': ['tap']},
	{'value': 'taptavya', 'label': 'taptavya', 'attrib': [{'pos': 'ger', 'defs': 'heat, scorch'}], 'isLemma': 0, 'parents': ['tap']},
	{'value': 'taptum', 'label': 'taptum', 'attrib': [{'pos': 'inf', 'defs': 'heat, scorch'}], 'isLemma': 0, 'parents': ['tap']},
	{'value': 'taptvā', 'label': 'taptvA', 'attrib': [{'pos': 'abs', 'defs': 'heat, scorch'}], 'isLemma': 0, 'parents': ['tap']},
	{'value': 'tapya', 'label': 'tapya', 'attrib': [{'pos': 'ger', 'defs': 'heat, scorch'}], 'isLemma': 0, 'parents': ['tap']},
	{'value': 'tapyamāna', 'label': 'tapyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'heat, scorch'}], 'isLemma': 0, 'parents': ['tap']},
	{'value': 'tapsyat', 'label': 'tapsyat', 'attrib': [{'pos': 'fap', 'defs': 'heat, scorch'}], 'isLemma': 0, 'parents': ['tap']},
	{'value': 'tapsyamāṇa', 'label': 'tapsyamARa', 'attrib': [{'pos': 'fpp', 'defs': 'heat, scorch'}], 'isLemma': 0, 'parents': ['tap']},
	{'value': 'tarpitavya', 'label': 'tarpitavya', 'attrib': [{'pos': 'ger', 'defs': 'become satisfied, be pleased; caus. satiate, satisfy, please'}], 'isLemma': 0, 'parents': ['tfp']},
	{'value': 'tarpitum', 'label': 'tarpitum', 'attrib': [{'pos': 'inf', 'defs': 'become satisfied, be pleased; caus. satiate, satisfy, please'}], 'isLemma': 0, 'parents': ['tfp']},
	{'value': 'tarpitvā', 'label': 'tarpitvA', 'attrib': [{'pos': 'abs', 'defs': 'become satisfied, be pleased; caus. satiate, satisfy, please'}], 'isLemma': 0, 'parents': ['tfp']},
	{'value': 'tarpiṣyat', 'label': 'tarpizyat', 'attrib': [{'pos': 'fap', 'defs': 'become satisfied, be pleased; caus. satiate, satisfy, please'}], 'isLemma': 0, 'parents': ['tfp']},
	{'value': 'tarptavya', 'label': 'tarptavya', 'attrib': [{'pos': 'ger', 'defs': 'become satisfied, be pleased; caus. satiate, satisfy, please'}], 'isLemma': 0, 'parents': ['tfp']},
	{'value': 'tarptum', 'label': 'tarptum', 'attrib': [{'pos': 'inf', 'defs': 'become satisfied, be pleased; caus. satiate, satisfy, please'}], 'isLemma': 0, 'parents': ['tfp']},
	{'value': 'tarpsyat', 'label': 'tarpsyat', 'attrib': [{'pos': 'fap', 'defs': 'become satisfied, be pleased; caus. satiate, satisfy, please'}], 'isLemma': 0, 'parents': ['tfp']},
	{'value': 'tasthāna', 'label': 'tasTAna', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'stand, stay, be present'}], 'isLemma': 0, 'parents': ['sTA']},
	{'value': 'tasthivas', 'label': 'tasTivas', 'attrib': [{'pos': 'pfap', 'defs': 'stand, stay, be present'}], 'isLemma': 0, 'parents': ['sTA']},
	{'value': 'tāta', 'label': 'tAta', 'attrib': [{'pos': 'm', 'defs': 'dad, son'}], 'isLemma': 1},
	{'value': 'tānya', 'label': 'tAnya', 'attrib': [{'pos': 'ger', 'defs': 'extend'}], 'isLemma': 0, 'parents': ['tan']},
	{'value': 'tiṣṭhat', 'label': 'tizWat', 'attrib': [{'pos': 'prap', 'defs': 'stand, stay, be present'}], 'isLemma': 0, 'parents': ['sTA']},
	{'value': 'tiṣṭhamāna', 'label': 'tizWamAna', 'attrib': [{'pos': 'prmp', 'defs': 'stand, stay, be present'}], 'isLemma': 0, 'parents': ['sTA']},
	{'value': 'tu', 'label': 'tu', 'attrib': [{'pos': 'conj_pcl', 'defs': 'but'}], 'isLemma': 1},
	{'value': 'tṛp', 'label': 'tfp', 'attrib': [{'pos': 'vi4a', 'defs': 'become satisfied, be pleased; caus. satiate, satisfy, please'}], 'isLemma': 1},
	{'value': 'tṛpta', 'label': 'tfpta', 'attrib': [{'pos': 'ppp', 'defs': 'become satisfied, be pleased; caus. satiate, satisfy, please'}], 'isLemma': 0, 'parents': ['tfp']},
	{'value': 'tṛptavat', 'label': 'tfptavat', 'attrib': [{'pos': 'pap', 'defs': 'become satisfied, be pleased; caus. satiate, satisfy, please'}], 'isLemma': 0, 'parents': ['tfp']},
	{'value': 'tṛptvā', 'label': 'tfptvA', 'attrib': [{'pos': 'abs', 'defs': 'become satisfied, be pleased; caus. satiate, satisfy, please'}], 'isLemma': 0, 'parents': ['tfp']},
	{'value': 'tṛpyat', 'label': 'tfpyat', 'attrib': [{'pos': 'prap', 'defs': 'become satisfied, be pleased; caus. satiate, satisfy, please'}], 'isLemma': 0, 'parents': ['tfp']},
	{'value': 'tṛpyamāṇa', 'label': 'tfpyamARa', 'attrib': [{'pos': 'prpp', 'defs': 'become satisfied, be pleased; caus. satiate, satisfy, please'}], 'isLemma': 0, 'parents': ['tfp']},
	{'value': 'tejas', 'label': 'tejas', 'attrib': [{'pos': 'n', 'defs': 'brilliance'}], 'isLemma': 1},
	{'value': 'tenāna', 'label': 'tenAna', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'extend'}], 'isLemma': 0, 'parents': ['tan']},
	{'value': 'tenivas', 'label': 'tenivas', 'attrib': [{'pos': 'pfap', 'defs': 'extend'}], 'isLemma': 0, 'parents': ['tan']},
	{'value': 'tepāna', 'label': 'tepAna', 'attrib': [{'pos': 'pfpp', 'defs': 'heat, scorch'}], 'isLemma': 0, 'parents': ['tap']},
	{'value': 'tepivas', 'label': 'tepivas', 'attrib': [{'pos': 'pfap', 'defs': 'heat, scorch'}], 'isLemma': 0, 'parents': ['tap']},
	{'value': 'toya', 'label': 'toya', 'attrib': [{'pos': 'n', 'defs': 'water'}], 'isLemma': 1},
	{'value': 'traptavya', 'label': 'traptavya', 'attrib': [{'pos': 'ger', 'defs': 'become satisfied, be pleased; caus. satiate, satisfy, please'}], 'isLemma': 0, 'parents': ['tfp']},
	{'value': 'traptum', 'label': 'traptum', 'attrib': [{'pos': 'inf', 'defs': 'become satisfied, be pleased; caus. satiate, satisfy, please'}], 'isLemma': 0, 'parents': ['tfp']},
	{'value': 'trapsyat', 'label': 'trapsyat', 'attrib': [{'pos': 'fap', 'defs': 'become satisfied, be pleased; caus. satiate, satisfy, please'}], 'isLemma': 0, 'parents': ['tfp']},
	{'value': 'trayas', 'label': 'trayas', 'attrib': [{'pos': 'm', 'defs': 'three'}], 'isLemma': 1},
	{'value': 'trayodaśa', 'label': 'trayodaSa', 'attrib': [{'pos': 'ord_num', 'defs': 'thirteenth'}], 'isLemma': 1},
	{'value': 'tri', 'label': 'tri', 'attrib': [{'pos': 'card_num', 'defs': 'three'}], 'isLemma': 1},
	{'value': 'tvad', 'label': 'tvad', 'attrib': [{'pos': 'pers_pron', 'defs': 'you'}], 'isLemma': 1},
	{'value': 'daṁś', 'label': 'daMS', 'attrib': [{'pos': 'vt1a', 'defs': 'bite'}], 'isLemma': 1},
	{'value': 'daṁśanīya', 'label': 'daMSanIya', 'attrib': [{'pos': 'ger', 'defs': 'bite'}], 'isLemma': 0, 'parents': ['daMS']},
	{'value': 'daṁśya', 'label': 'daMSya', 'attrib': [{'pos': 'ger', 'defs': 'bite'}], 'isLemma': 0, 'parents': ['daMS']},
	{'value': 'daṁṣṭavya', 'label': 'daMzwavya', 'attrib': [{'pos': 'ger', 'defs': 'bite'}], 'isLemma': 0, 'parents': ['daMS']},
	{'value': 'daṁṣṭum', 'label': 'daMzwum', 'attrib': [{'pos': 'inf', 'defs': 'bite'}], 'isLemma': 0, 'parents': ['daMS']},
	{'value': 'dakṣiṇa', 'label': 'dakziRa', 'attrib': [{'pos': 'adj', 'defs': 'skilful'}, {'pos': 'pron_adj, pron', 'defs': 'right, southern'}], 'isLemma': 1},
	{'value': 'dakṣiṇā', 'label': 'dakziRA', 'attrib': [{'pos': 'f', 'defs': "the south, officiant's fee"}], 'isLemma': 1},
	{'value': 'dagdha', 'label': 'dagDa', 'attrib': [{'pos': 'ppp', 'defs': 'burn'}], 'isLemma': 0, 'parents': ['dah']},
	{'value': 'dagdhavat', 'label': 'dagDavat', 'attrib': [{'pos': 'pap', 'defs': 'burn'}], 'isLemma': 0, 'parents': ['dah']},
	{'value': 'dagdhavya', 'label': 'dagDavya', 'attrib': [{'pos': 'ger', 'defs': 'burn'}], 'isLemma': 0, 'parents': ['dah']},
	{'value': 'dagdhum', 'label': 'dagDum', 'attrib': [{'pos': 'inf', 'defs': 'burn'}], 'isLemma': 0, 'parents': ['dah']},
	{'value': 'dagdhvā', 'label': 'dagDvA', 'attrib': [{'pos': 'abs', 'defs': 'burn'}], 'isLemma': 0, 'parents': ['dah']},
	{'value': 'daṅkṣyat', 'label': 'daNkzyat', 'attrib': [{'pos': 'fap', 'defs': 'bite'}], 'isLemma': 0, 'parents': ['daMS']},
	{'value': 'datta', 'label': 'datta', 'attrib': [{'pos': 'ppp', 'defs': 'give'}], 'isLemma': 0, 'parents': ['dA']},
	{'value': 'dattavat', 'label': 'dattavat', 'attrib': [{'pos': 'pap', 'defs': 'give'}], 'isLemma': 0, 'parents': ['dA']},
	{'value': 'dattvā', 'label': 'dattvA', 'attrib': [{'pos': 'abs', 'defs': 'give'}], 'isLemma': 0, 'parents': ['dA']},
	{'value': 'dadaṁśivas', 'label': 'dadaMSivas', 'attrib': [{'pos': 'pfap', 'defs': 'bite'}], 'isLemma': 0, 'parents': ['daMS']},
	{'value': 'dadat', 'label': 'dadat', 'attrib': [{'pos': 'prap', 'defs': 'give'}], 'isLemma': 0, 'parents': ['dA']},
	{'value': 'dadahāna', 'label': 'dadahAna', 'attrib': [{'pos': 'pfpp', 'defs': 'burn'}], 'isLemma': 0, 'parents': ['dah']},
	{'value': 'dadahvas', 'label': 'dadahvas', 'attrib': [{'pos': 'pfap', 'defs': 'burn'}], 'isLemma': 0, 'parents': ['dah']},
	{'value': 'dadāna', 'label': 'dadAna', 'attrib': [{'pos': 'prmp', 'defs': 'give'}, {'pos': 'pfmp, pfpp', 'defs': 'give'}], 'isLemma': 0, 'parents': ['dA']},
	{'value': 'dadivas', 'label': 'dadivas', 'attrib': [{'pos': 'pfap', 'defs': 'give'}], 'isLemma': 0, 'parents': ['dA']},
	{'value': 'dadṛśāna', 'label': 'dadfSAna', 'attrib': [{'pos': 'pfpp', 'defs': 'see, watch'}], 'isLemma': 0, 'parents': ['dfS']},
	{'value': 'dadṛśivas', 'label': 'dadfSivas', 'attrib': [{'pos': 'pfap', 'defs': 'see, watch'}], 'isLemma': 0, 'parents': ['dfS']},
	{'value': 'dadṛśvas', 'label': 'dadfSvas', 'attrib': [{'pos': 'pfap', 'defs': 'see, watch'}], 'isLemma': 0, 'parents': ['dfS']},
	{'value': 'dadhat', 'label': 'daDat', 'attrib': [{'pos': 'prap', 'defs': 'put'}], 'isLemma': 0, 'parents': ['DA']},
	{'value': 'dadhāna', 'label': 'daDAna', 'attrib': [{'pos': 'prmp', 'defs': 'put'}, {'pos': 'pfmp, pfpp', 'defs': 'put'}], 'isLemma': 0, 'parents': ['DA']},
	{'value': 'dadhivas', 'label': 'daDivas', 'attrib': [{'pos': 'pfap', 'defs': 'put'}], 'isLemma': 0, 'parents': ['DA']},
	{'value': 'dadhṛvas', 'label': 'daDfvas', 'attrib': [{'pos': 'pfap', 'defs': 'hold'}], 'isLemma': 0, 'parents': ['Df']},
	{'value': 'dadhrāṇa', 'label': 'daDrARa', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'hold'}], 'isLemma': 0, 'parents': ['Df']},
	{'value': 'dam', 'label': 'dam', 'attrib': [{'pos': 'vt4a', 'defs': 'tame'}], 'isLemma': 1},
	{'value': 'dama', 'label': 'dama', 'attrib': [{'pos': 'adj', 'defs': 'taming'}], 'isLemma': 1},
	{'value': 'darśanīya', 'label': 'darSanIya', 'attrib': [{'pos': 'ger', 'defs': 'see, watch'}], 'isLemma': 0, 'parents': ['dfS']},
	{'value': 'daśat', 'label': 'daSat', 'attrib': [{'pos': 'prap', 'defs': 'bite'}], 'isLemma': 0, 'parents': ['daMS']},
	{'value': 'daśan', 'label': 'daSan', 'attrib': [{'pos': 'card_num', 'defs': 'ten'}], 'isLemma': 1},
	{'value': 'daśyamāna', 'label': 'daSyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'bite'}], 'isLemma': 0, 'parents': ['daMS']},
	{'value': 'daṣṭa', 'label': 'dazwa', 'attrib': [{'pos': 'ppp', 'defs': 'bite'}], 'isLemma': 0, 'parents': ['daMS']},
	{'value': 'daṣṭavat', 'label': 'dazwavat', 'attrib': [{'pos': 'pap', 'defs': 'bite'}], 'isLemma': 0, 'parents': ['daMS']},
	{'value': 'daṣṭvā', 'label': 'dazwvA', 'attrib': [{'pos': 'abs', 'defs': 'bite'}], 'isLemma': 0, 'parents': ['daMS']},
	{'value': 'dah', 'label': 'dah', 'attrib': [{'pos': 'vt1a', 'defs': 'burn'}], 'isLemma': 1},
	{'value': 'dahat', 'label': 'dahat', 'attrib': [{'pos': 'prap', 'defs': 'burn'}], 'isLemma': 0, 'parents': ['dah']},
	{'value': 'dahanīya', 'label': 'dahanIya', 'attrib': [{'pos': 'ger', 'defs': 'burn'}], 'isLemma': 0, 'parents': ['dah']},
	{'value': 'dahyamāna', 'label': 'dahyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'burn'}], 'isLemma': 0, 'parents': ['dah']},
	{'value': 'dā', 'label': 'dA', 'attrib': [{'pos': 'vt3am', 'defs': 'give'}], 'isLemma': 1},
	{'value': 'dātavya', 'label': 'dAtavya', 'attrib': [{'pos': 'ger', 'defs': 'give'}], 'isLemma': 0, 'parents': ['dA']},
	{'value': 'dātum', 'label': 'dAtum', 'attrib': [{'pos': 'inf', 'defs': 'give'}], 'isLemma': 0, 'parents': ['dA']},
	{'value': 'dānīya', 'label': 'dAnIya', 'attrib': [{'pos': 'ger', 'defs': 'give'}], 'isLemma': 0, 'parents': ['dA']},
	{'value': 'dāsyat', 'label': 'dAsyat', 'attrib': [{'pos': 'fap', 'defs': 'give'}], 'isLemma': 0, 'parents': ['dA']},
	{'value': 'dāsyamāna', 'label': 'dAsyamAna', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'give'}], 'isLemma': 0, 'parents': ['dA']},
	{'value': 'dāhya', 'label': 'dAhya', 'attrib': [{'pos': 'ger', 'defs': 'burn'}], 'isLemma': 0, 'parents': ['dah']},
	{'value': 'didiśāna', 'label': 'didiSAna', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'point'}], 'isLemma': 0, 'parents': ['diS']},
	{'value': 'didiśivas', 'label': 'didiSivas', 'attrib': [{'pos': 'pfap', 'defs': 'point'}], 'isLemma': 0, 'parents': ['diS']},
	{'value': 'div', 'label': 'div', 'attrib': [{'pos': 'm, f', 'defs': 'sky, the vault of heaven, day'}], 'isLemma': 1},
	{'value': 'divya', 'label': 'divya', 'attrib': [{'pos': 'adj', 'defs': 'divine'}], 'isLemma': 1},
	{'value': 'diś', 'label': 'diS', 'attrib': [{'pos': 'f', 'defs': 'direction'}, {'pos': 'vt6am', 'defs': 'point'}], 'isLemma': 1},
	{'value': 'diśat', 'label': 'diSat', 'attrib': [{'pos': 'prap', 'defs': 'point'}], 'isLemma': 0, 'parents': ['diS']},
	{'value': 'diśamāna', 'label': 'diSamAna', 'attrib': [{'pos': 'prmp', 'defs': 'point'}], 'isLemma': 0, 'parents': ['diS']},
	{'value': 'diśyamāna', 'label': 'diSyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'point'}], 'isLemma': 0, 'parents': ['diS']},
	{'value': 'diṣṭa', 'label': 'dizwa', 'attrib': [{'pos': 'ppp', 'defs': 'point'}], 'isLemma': 0, 'parents': ['diS']},
	{'value': 'diṣṭavat', 'label': 'dizwavat', 'attrib': [{'pos': 'pap', 'defs': 'point'}], 'isLemma': 0, 'parents': ['diS']},
	{'value': 'diṣṭvā', 'label': 'dizwvA', 'attrib': [{'pos': 'abs', 'defs': 'point'}], 'isLemma': 0, 'parents': ['diS']},
	{'value': 'dīyamāna', 'label': 'dIyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'give'}], 'isLemma': 0, 'parents': ['dA']},
	{'value': 'duḥśāsana', 'label': 'duHSAsana', 'attrib': [{'pos': 'm_pn', 'defs': 'Duḥśāsana'}], 'isLemma': 1},
	{'value': 'dudruvas', 'label': 'dudruvas', 'attrib': [{'pos': 'pfap', 'defs': 'run'}], 'isLemma': 0, 'parents': ['dru']},
	{'value': 'dudruvāṇa', 'label': 'dudruvARa', 'attrib': [{'pos': 'pfpp', 'defs': 'run'}], 'isLemma': 0, 'parents': ['dru']},
	{'value': 'durmati', 'label': 'durmati', 'attrib': [{'pos': 'adj', 'defs': 'ill-minded, weak-minded, evil-minded'}], 'isLemma': 1},
	{'value': 'duryodhana', 'label': 'duryoDana', 'attrib': [{'pos': 'm_pn', 'defs': 'Duryodhana'}], 'isLemma': 1},
	{'value': 'dus', 'label': 'dus', 'attrib': [{'pos': 'preverb', 'defs': 'ill'}], 'isLemma': 1},
	{'value': 'dṛś', 'label': 'dfS', 'attrib': [{'pos': 'vt1a', 'defs': 'see, watch'}], 'isLemma': 1},
	{'value': 'dṛśya', 'label': 'dfSya', 'attrib': [{'pos': 'ger', 'defs': 'see, watch'}], 'isLemma': 0, 'parents': ['dfS']},
	{'value': 'dṛśyamāna', 'label': 'dfSyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'see, watch'}], 'isLemma': 0, 'parents': ['dfS']},
	{'value': 'dṛṣṭa', 'label': 'dfzwa', 'attrib': [{'pos': 'ppp', 'defs': 'see, watch'}], 'isLemma': 0, 'parents': ['dfS']},
	{'value': 'dṛṣṭavat', 'label': 'dfzwavat', 'attrib': [{'pos': 'pap', 'defs': 'see, watch'}], 'isLemma': 0, 'parents': ['dfS']},
	{'value': 'dṛṣṭvā', 'label': 'dfzwvA', 'attrib': [{'pos': 'abs', 'defs': 'see, watch'}], 'isLemma': 0, 'parents': ['dfS']},
	{'value': 'dekṣyat', 'label': 'dekzyat', 'attrib': [{'pos': 'fap', 'defs': 'point'}], 'isLemma': 0, 'parents': ['diS']},
	{'value': 'dekṣyamāṇa', 'label': 'dekzyamARa', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'point'}], 'isLemma': 0, 'parents': ['diS']},
	{'value': 'deya', 'label': 'deya', 'attrib': [{'pos': 'ger', 'defs': 'give'}], 'isLemma': 0, 'parents': ['dA']},
	{'value': 'deva', 'label': 'deva', 'attrib': [{'pos': 'm', 'defs': 'luminous being, god'}], 'isLemma': 1},
	{'value': 'deśa', 'label': 'deSa', 'attrib': [{'pos': 'm', 'defs': 'place'}], 'isLemma': 1},
	{'value': 'deśanīya', 'label': 'deSanIya', 'attrib': [{'pos': 'ger', 'defs': 'point'}], 'isLemma': 0, 'parents': ['diS']},
	{'value': 'deśya', 'label': 'deSya', 'attrib': [{'pos': 'ger', 'defs': 'point'}], 'isLemma': 0, 'parents': ['diS']},
	{'value': 'deṣṭavya', 'label': 'dezwavya', 'attrib': [{'pos': 'ger', 'defs': 'point'}], 'isLemma': 0, 'parents': ['diS']},
	{'value': 'deṣṭum', 'label': 'dezwum', 'attrib': [{'pos': 'inf', 'defs': 'point'}], 'isLemma': 0, 'parents': ['diS']},
	{'value': 'daiva', 'label': 'dEva', 'attrib': [{'pos': 'n', 'defs': 'caused by the gods (deva), fate'}], 'isLemma': 1},
	{'value': 'daivabhāvyartharakṣita', 'label': 'dEvaBAvyarTarakzita', 'attrib': [{'pos': 'adj', 'defs': 'protected for future purposes by divine cause'}], 'isLemma': 1},
	{'value': 'dyūta', 'label': 'dyUta', 'attrib': [{'pos': 'n', 'defs': 'playing with dice, gambling'}], 'isLemma': 1},
	{'value': 'dyūtasaṁbhūta', 'label': 'dyUtasaMBUta', 'attrib': [{'pos': 'adj', 'defs': 'arisen from the gambling match'}], 'isLemma': 1},
	{'value': 'drakṣyat', 'label': 'drakzyat', 'attrib': [{'pos': 'fap', 'defs': 'see, watch'}], 'isLemma': 0, 'parents': ['dfS']},
	{'value': 'drakṣyamāṇa', 'label': 'drakzyamARa', 'attrib': [{'pos': 'fpp', 'defs': 'see, watch'}], 'isLemma': 0, 'parents': ['dfS']},
	{'value': 'dravaṇīya', 'label': 'dravaRIya', 'attrib': [{'pos': 'ger', 'defs': 'run'}], 'isLemma': 0, 'parents': ['dru']},
	{'value': 'dravat', 'label': 'dravat', 'attrib': [{'pos': 'prap', 'defs': 'run'}], 'isLemma': 0, 'parents': ['dru']},
	{'value': 'dravya', 'label': 'dravya', 'attrib': [{'pos': 'ger', 'defs': 'run'}], 'isLemma': 0, 'parents': ['dru']},
	{'value': 'draṣṭavya', 'label': 'drazwavya', 'attrib': [{'pos': 'ger', 'defs': 'see, watch'}], 'isLemma': 0, 'parents': ['dfS']},
	{'value': 'draṣṭum', 'label': 'drazwum', 'attrib': [{'pos': 'inf', 'defs': 'see, watch'}], 'isLemma': 0, 'parents': ['dfS']},
	{'value': 'dru', 'label': 'dru', 'attrib': [{'pos': 'm, n', 'defs': 'wood, wooden implement'}, {'pos': 'vt1a', 'defs': 'run'}], 'isLemma': 1},
	{'value': 'druta', 'label': 'druta', 'attrib': [{'pos': 'ppp', 'defs': 'run'}, {'pos': 'adj', 'defs': 'swift, fluid'}], 'isLemma': 1},
	{'value': 'druta', 'label': 'druta', 'attrib': [{'pos': 'ppp', 'defs': 'run'}, {'pos': 'adj', 'defs': 'swift, fluid'}], 'isLemma': 0, 'parents': ['dru']},
	{'value': 'drutavat', 'label': 'drutavat', 'attrib': [{'pos': 'pap', 'defs': 'run'}], 'isLemma': 0, 'parents': ['dru']},
	{'value': 'drutvā', 'label': 'drutvA', 'attrib': [{'pos': 'abs', 'defs': 'run'}], 'isLemma': 0, 'parents': ['dru']},
	{'value': 'drupada', 'label': 'drupada', 'attrib': [{'pos': 'm_pn', 'defs': 'Drupada'}, {'pos': 'n', 'defs': 'wooden pillar'}], 'isLemma': 1},
	{'value': 'drūyamāṇa', 'label': 'drUyamARa', 'attrib': [{'pos': 'prpp', 'defs': 'run'}], 'isLemma': 0, 'parents': ['dru']},
	{'value': 'drotavya', 'label': 'drotavya', 'attrib': [{'pos': 'ger', 'defs': 'run'}], 'isLemma': 0, 'parents': ['dru']},
	{'value': 'drotum', 'label': 'drotum', 'attrib': [{'pos': 'inf', 'defs': 'run'}], 'isLemma': 0, 'parents': ['dru']},
	{'value': 'droṣyat', 'label': 'drozyat', 'attrib': [{'pos': 'fap', 'defs': 'run'}], 'isLemma': 0, 'parents': ['dru']},
	{'value': 'droṣyamāṇa', 'label': 'drozyamARa', 'attrib': [{'pos': 'fpp', 'defs': 'run'}], 'isLemma': 0, 'parents': ['dru']},
	{'value': 'draupadī', 'label': 'drOpadI', 'attrib': [{'pos': 'f', 'defs': 'daughter of Drupada'}], 'isLemma': 1},
	{'value': 'dvāra', 'label': 'dvAra', 'attrib': [{'pos': 'n', 'defs': 'door, gate'}], 'isLemma': 1},
	{'value': 'dvāravat', 'label': 'dvAravat', 'attrib': [{'pos': 'adj', 'defs': 'having doors, many-gated'}], 'isLemma': 1},
	{'value': 'dvāravatī', 'label': 'dvAravatI', 'attrib': [{'pos': 'f', 'defs': 'Dvāraka'}], 'isLemma': 1},
	{'value': 'dvi', 'label': 'dvi', 'attrib': [{'pos': 'card_num', 'defs': 'two'}], 'isLemma': 1},
	{'value': 'dvija', 'label': 'dvija', 'attrib': [{'pos': 'adj', 'defs': 'twice-born'}], 'isLemma': 1},
	{'value': 'dhakṣyat', 'label': 'Dakzyat', 'attrib': [{'pos': 'fap', 'defs': 'burn'}], 'isLemma': 0, 'parents': ['dah']},
	{'value': 'dhakṣyamāṇa', 'label': 'DakzyamARa', 'attrib': [{'pos': 'fpp', 'defs': 'burn'}], 'isLemma': 0, 'parents': ['dah']},
	{'value': 'dhana', 'label': 'Dana', 'attrib': [{'pos': 'n', 'defs': 'wealth'}], 'isLemma': 1},
	{'value': 'dhanaṁjaya', 'label': 'DanaMjaya', 'attrib': [{'pos': 'm', 'defs': 'winner of wealth'}], 'isLemma': 1},
	{'value': 'dhanus', 'label': 'Danus', 'attrib': [{'pos': 'n', 'defs': 'bow'}], 'isLemma': 1},
	{'value': 'dhara', 'label': 'Dara', 'attrib': [{'pos': 'adj', 'defs': 'holding'}], 'isLemma': 1},
	{'value': 'dharaṇīya', 'label': 'DaraRIya', 'attrib': [{'pos': 'ger', 'defs': 'hold'}], 'isLemma': 0, 'parents': ['Df']},
	{'value': 'dharat', 'label': 'Darat', 'attrib': [{'pos': 'prap', 'defs': 'hold'}], 'isLemma': 0, 'parents': ['Df']},
	{'value': 'dharamāṇa', 'label': 'DaramARa', 'attrib': [{'pos': 'prmp', 'defs': 'hold'}], 'isLemma': 0, 'parents': ['Df']},
	{'value': 'dhariṣyat', 'label': 'Darizyat', 'attrib': [{'pos': 'fap', 'defs': 'hold'}], 'isLemma': 0, 'parents': ['Df']},
	{'value': 'dhariṣyamāṇa', 'label': 'DarizyamARa', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'hold'}], 'isLemma': 0, 'parents': ['Df']},
	{'value': 'dhartavya', 'label': 'Dartavya', 'attrib': [{'pos': 'ger', 'defs': 'hold'}], 'isLemma': 0, 'parents': ['Df']},
	{'value': 'dhartum', 'label': 'Dartum', 'attrib': [{'pos': 'inf', 'defs': 'hold'}], 'isLemma': 0, 'parents': ['Df']},
	{'value': 'dharma', 'label': 'Darma', 'attrib': [{'pos': 'm', 'defs': 'that which upholds, cosmic order, truth, proper function, right action, duty'}], 'isLemma': 1},
	{'value': 'dharmapradhāna', 'label': 'DarmapraDAna', 'attrib': [{'pos': 'adj', 'defs': 'devoted to dharma'}], 'isLemma': 1},
	{'value': 'dharmarāja', 'label': 'DarmarAja', 'attrib': [{'pos': 'm', 'defs': 'king dharma'}], 'isLemma': 1},
	{'value': 'dhā', 'label': 'DA', 'attrib': [{'pos': 'vt3am', 'defs': 'put'}], 'isLemma': 1},
	{'value': 'dhātavya', 'label': 'DAtavya', 'attrib': [{'pos': 'ger', 'defs': 'put'}], 'isLemma': 0, 'parents': ['DA']},
	{'value': 'dhātum', 'label': 'DAtum', 'attrib': [{'pos': 'inf', 'defs': 'put'}], 'isLemma': 0, 'parents': ['DA']},
	{'value': 'dhānīya', 'label': 'DAnIya', 'attrib': [{'pos': 'ger', 'defs': 'put'}], 'isLemma': 0, 'parents': ['DA']},
	{'value': 'dhāsyat', 'label': 'DAsyat', 'attrib': [{'pos': 'fap', 'defs': 'put'}], 'isLemma': 0, 'parents': ['DA']},
	{'value': 'dhāsyamāna', 'label': 'DAsyamAna', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'put'}], 'isLemma': 0, 'parents': ['DA']},
	{'value': 'dhī', 'label': 'DI', 'attrib': [{'pos': 'f', 'defs': 'intelligence'}], 'isLemma': 1},
	{'value': 'dhīmat', 'label': 'DImat', 'attrib': [{'pos': 'adj', 'defs': 'intelligent, wise'}], 'isLemma': 1},
	{'value': 'dhīyamāna', 'label': 'DIyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'put'}], 'isLemma': 0, 'parents': ['DA']},
	{'value': 'dhṛ', 'label': 'Df', 'attrib': [{'pos': 'vt1am', 'defs': 'hold'}], 'isLemma': 1},
	{'value': 'dhṛta', 'label': 'Dfta', 'attrib': [{'pos': 'ppp', 'defs': 'hold'}], 'isLemma': 0, 'parents': ['Df']},
	{'value': 'dhṛtarāṣṭra', 'label': 'DftarAzwra', 'attrib': [{'pos': 'm_pn', 'defs': 'Dhṛtarāṣṭra'}], 'isLemma': 1},
	{'value': 'dhṛtarāṣṭraja', 'label': 'DftarAzwraja', 'attrib': [{'pos': 'adj', 'defs': 'born of Dhṛtarāṣṭra'}], 'isLemma': 1},
	{'value': 'dhṛtarāṣṭrabhīṣma', 'label': 'DftarAzwraBIzma', 'attrib': [{'pos': 'm', 'defs': 'Dhṛtarāṣṭra and Bhīṣma'}], 'isLemma': 1},
	{'value': 'dhṛtavat', 'label': 'Dftavat', 'attrib': [{'pos': 'pap', 'defs': 'hold'}], 'isLemma': 0, 'parents': ['Df']},
	{'value': 'dhṛtya', 'label': 'Dftya', 'attrib': [{'pos': 'ger', 'defs': 'hold'}], 'isLemma': 0, 'parents': ['Df']},
	{'value': 'dhṛtvā', 'label': 'DftvA', 'attrib': [{'pos': 'abs', 'defs': 'hold'}], 'isLemma': 0, 'parents': ['Df']},
	{'value': 'dheya', 'label': 'Deya', 'attrib': [{'pos': 'ger', 'defs': 'put'}], 'isLemma': 0, 'parents': ['DA']},
	{'value': 'dhriyamāṇa', 'label': 'DriyamARa', 'attrib': [{'pos': 'prpp', 'defs': 'hold'}], 'isLemma': 0, 'parents': ['Df']},
	{'value': 'na', 'label': 'na', 'attrib': [{'pos': 'neg_pcl', 'defs': 'not'}], 'isLemma': 1},
	{'value': 'naṁsyat', 'label': 'naMsyat', 'attrib': [{'pos': 'fap', 'defs': 'bow'}], 'isLemma': 0, 'parents': ['nam']},
	{'value': 'naṁsyamāna', 'label': 'naMsyamAna', 'attrib': [{'pos': 'fpp', 'defs': 'bow'}], 'isLemma': 0, 'parents': ['nam']},
	{'value': 'nakula', 'label': 'nakula', 'attrib': [{'pos': 'm_pn', 'defs': 'Nakula'}], 'isLemma': 1},
	{'value': 'nagara', 'label': 'nagara', 'attrib': [{'pos': 'n', 'defs': 'city'}], 'isLemma': 1},
	{'value': 'nacira', 'label': 'nacira', 'attrib': [{'pos': 'adj', 'defs': 'not long'}], 'isLemma': 1},
	{'value': 'nata', 'label': 'nata', 'attrib': [{'pos': 'ppp', 'defs': 'bow'}], 'isLemma': 0, 'parents': ['nam']},
	{'value': 'natavat', 'label': 'natavat', 'attrib': [{'pos': 'pap', 'defs': 'bow'}], 'isLemma': 0, 'parents': ['nam']},
	{'value': 'natvā', 'label': 'natvA', 'attrib': [{'pos': 'abs', 'defs': 'bow'}], 'isLemma': 0, 'parents': ['nam']},
	{'value': 'nadī', 'label': 'nadI', 'attrib': [{'pos': 'f', 'defs': 'river'}], 'isLemma': 1},
	{'value': 'nantavya', 'label': 'nantavya', 'attrib': [{'pos': 'ger', 'defs': 'bow'}], 'isLemma': 0, 'parents': ['nam']},
	{'value': 'nantum', 'label': 'nantum', 'attrib': [{'pos': 'inf', 'defs': 'bow'}], 'isLemma': 0, 'parents': ['nam']},
	{'value': 'nam', 'label': 'nam', 'attrib': [{'pos': 'vt1a', 'defs': 'bow'}], 'isLemma': 1},
	{'value': 'namat', 'label': 'namat', 'attrib': [{'pos': 'prap', 'defs': 'bow'}], 'isLemma': 0, 'parents': ['nam']},
	{'value': 'namanīya', 'label': 'namanIya', 'attrib': [{'pos': 'ger', 'defs': 'bow'}], 'isLemma': 0, 'parents': ['nam']},
	{'value': 'namaścakṛvas', 'label': 'namaScakfvas', 'attrib': [{'pos': 'pfap', 'defs': 'do obeisance, make salutation, bow to'}], 'isLemma': 0, 'parents': ['namas_kf']},
	{'value': 'namaścakrāṇa', 'label': 'namaScakrARa', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'do obeisance, make salutation, bow to'}], 'isLemma': 0, 'parents': ['namas_kf']},
	{'value': 'namas', 'label': 'namas', 'attrib': [{'pos': 'n', 'defs': 'obeisance, salutation'}], 'isLemma': 1},
	{'value': 'namaskaraṇīya', 'label': 'namaskaraRIya', 'attrib': [{'pos': 'ger', 'defs': 'do obeisance, make salutation, bow to'}], 'isLemma': 0, 'parents': ['namas_kf']},
	{'value': 'namaskariṣyat', 'label': 'namaskarizyat', 'attrib': [{'pos': 'fap', 'defs': 'do obeisance, make salutation, bow to'}], 'isLemma': 0, 'parents': ['namas_kf']},
	{'value': 'namaskariṣyamāṇa', 'label': 'namaskarizyamARa', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'do obeisance, make salutation, bow to'}], 'isLemma': 0, 'parents': ['namas_kf']},
	{'value': 'namaskartavya', 'label': 'namaskartavya', 'attrib': [{'pos': 'ger', 'defs': 'do obeisance, make salutation, bow to'}], 'isLemma': 0, 'parents': ['namas_kf']},
	{'value': 'namaskartum', 'label': 'namaskartum', 'attrib': [{'pos': 'inf', 'defs': 'do obeisance, make salutation, bow to'}], 'isLemma': 0, 'parents': ['namas_kf']},
	{'value': 'namaskārya', 'label': 'namaskArya', 'attrib': [{'pos': 'ger', 'defs': 'do obeisance, make salutation, bow to'}], 'isLemma': 0, 'parents': ['namas_kf']},
	{'value': 'namaskurvat', 'label': 'namaskurvat', 'attrib': [{'pos': 'prap', 'defs': 'do obeisance, make salutation, bow to'}], 'isLemma': 0, 'parents': ['namas_kf']},
	{'value': 'namaskurvāṇa', 'label': 'namaskurvARa', 'attrib': [{'pos': 'prmp', 'defs': 'do obeisance, make salutation, bow to'}], 'isLemma': 0, 'parents': ['namas_kf']},
	{'value': 'namaskṛta', 'label': 'namaskfta', 'attrib': [{'pos': 'ppp', 'defs': 'do obeisance, make salutation, bow to'}], 'isLemma': 0, 'parents': ['namas_kf']},
	{'value': 'namaskṛtavat', 'label': 'namaskftavat', 'attrib': [{'pos': 'pap', 'defs': 'do obeisance, make salutation, bow to'}], 'isLemma': 0, 'parents': ['namas_kf']},
	{'value': 'namaskṛtya', 'label': 'namaskftya', 'attrib': [{'pos': 'abs', 'defs': 'do obeisance, make salutation, bow to'}, {'pos': 'ger', 'defs': 'do obeisance, make salutation, bow to'}], 'isLemma': 0, 'parents': ['namas_kf']},
	{'value': 'namaskriyamāṇa', 'label': 'namaskriyamARa', 'attrib': [{'pos': 'prpp', 'defs': 'do obeisance, make salutation, bow to'}], 'isLemma': 0, 'parents': ['namas_kf']},
	{'value': 'namas_kṛ', 'label': 'namas_kf', 'attrib': [{'pos': 'vt8am', 'defs': 'do obeisance, make salutation, bow to'}], 'isLemma': 1},
	{'value': 'namya', 'label': 'namya', 'attrib': [{'pos': 'ger', 'defs': 'bow'}], 'isLemma': 0, 'parents': ['nam']},
	{'value': 'namyamāna', 'label': 'namyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'bow'}], 'isLemma': 0, 'parents': ['nam']},
	{'value': 'naś', 'label': 'naS', 'attrib': [{'pos': 'vi4a', 'defs': 'perish'}], 'isLemma': 1},
	{'value': 'naśanīya', 'label': 'naSanIya', 'attrib': [{'pos': 'ger', 'defs': 'perish'}], 'isLemma': 0, 'parents': ['naS']},
	{'value': 'naśyat', 'label': 'naSyat', 'attrib': [{'pos': 'prap', 'defs': 'perish'}], 'isLemma': 0, 'parents': ['naS']},
	{'value': 'naṣṭa', 'label': 'nazwa', 'attrib': [{'pos': 'ppp', 'defs': 'perish'}], 'isLemma': 0, 'parents': ['naS']},
	{'value': 'naṣṭavat', 'label': 'nazwavat', 'attrib': [{'pos': 'ppp', 'defs': 'perish'}], 'isLemma': 0, 'parents': ['naS']},
	{'value': 'naṣṭavya', 'label': 'nazwavya', 'attrib': [{'pos': 'ger', 'defs': 'perish'}], 'isLemma': 0, 'parents': ['naS']},
	{'value': 'naṣṭum', 'label': 'nazwum', 'attrib': [{'pos': 'inf', 'defs': 'perish'}], 'isLemma': 0, 'parents': ['naS']},
	{'value': 'naṣṭvā', 'label': 'nazwvA', 'attrib': [{'pos': 'abs', 'defs': 'perish'}], 'isLemma': 0, 'parents': ['naS']},
	{'value': 'nāman', 'label': 'nAman', 'attrib': [{'pos': 'n', 'defs': 'name'}], 'isLemma': 1},
	{'value': 'nāśya', 'label': 'nASya', 'attrib': [{'pos': 'ger', 'defs': 'perish'}], 'isLemma': 0, 'parents': ['naS']},
	{'value': 'ni', 'label': 'ni', 'attrib': [{'pos': 'preverb', 'defs': 'in'}], 'isLemma': 1},
	{'value': 'nigraha', 'label': 'nigraha', 'attrib': [{'pos': 'm', 'defs': 'restraint, suppression, defeat'}], 'isLemma': 1},
	{'value': 'nigrahanirvāsa', 'label': 'nigrahanirvAsa', 'attrib': [{'pos': 'm', 'defs': 'defeat and exile'}], 'isLemma': 1},
	{'value': 'nighātya', 'label': 'niGAtya', 'attrib': [{'pos': 'ger', 'defs': 'smite'}], 'isLemma': 0, 'parents': ['ni_han']},
	{'value': 'nighnat', 'label': 'niGnat', 'attrib': [{'pos': 'prap', 'defs': 'smite'}], 'isLemma': 0, 'parents': ['ni_han']},
	{'value': 'nijaghanvas', 'label': 'nijaGanvas', 'attrib': [{'pos': 'pfap', 'defs': 'smite'}], 'isLemma': 0, 'parents': ['ni_han']},
	{'value': 'nijaghnāna', 'label': 'nijaGnAna', 'attrib': [{'pos': 'pfpp', 'defs': 'smite'}], 'isLemma': 0, 'parents': ['ni_han']},
	{'value': 'nijaghnivas', 'label': 'nijaGnivas', 'attrib': [{'pos': 'pfap', 'defs': 'smite'}], 'isLemma': 0, 'parents': ['ni_han']},
	{'value': 'ni_grah', 'label': 'ni_grah', 'attrib': [{'pos': 'vt9a', 'defs': 'hold down, suppress'}], 'isLemma': 1},
	{'value': 'ni_vas', 'label': 'ni_vas', 'attrib': [{'pos': 'vi1a', 'defs': 'dwell, live'}], 'isLemma': 1},
	{'value': 'ni_han', 'label': 'ni_han', 'attrib': [{'pos': 'vt2a', 'defs': 'smite'}], 'isLemma': 1},
	{'value': 'nitya', 'label': 'nitya', 'attrib': [{'pos': 'adj', 'defs': 'innate, eternal, constant'}], 'isLemma': 1},
	{'value': 'nityam', 'label': 'nityam', 'attrib': [{'pos': 'adv', 'defs': 'always'}], 'isLemma': 1},
	{'value': 'nimitta', 'label': 'nimitta', 'attrib': [{'pos': 'n', 'defs': 'condition, cause, reason'}], 'isLemma': 1},
	{'value': 'nir_vas', 'label': 'nir_vas', 'attrib': [{'pos': 'vi1a', 'defs': 'dwell abroad'}], 'isLemma': 1},
	{'value': 'nirvāsa', 'label': 'nirvAsa', 'attrib': [{'pos': 'm', 'defs': 'exile, banishment'}], 'isLemma': 1},
	{'value': 'nis', 'label': 'nis', 'attrib': [{'pos': 'preverb', 'defs': 'out'}], 'isLemma': 1},
	{'value': 'nihaṁsyat', 'label': 'nihaMsyat', 'attrib': [{'pos': 'fap', 'defs': 'smite'}], 'isLemma': 0, 'parents': ['ni_han']},
	{'value': 'nihaṁsyamāna', 'label': 'nihaMsyamAna', 'attrib': [{'pos': 'fpp', 'defs': 'smite'}], 'isLemma': 0, 'parents': ['ni_han']},
	{'value': 'nihata', 'label': 'nihata', 'attrib': [{'pos': 'ppp', 'defs': 'smite'}], 'isLemma': 0, 'parents': ['ni_han']},
	{'value': 'nihatavat', 'label': 'nihatavat', 'attrib': [{'pos': 'pap', 'defs': 'smite'}], 'isLemma': 0, 'parents': ['ni_han']},
	{'value': 'nihananīya', 'label': 'nihananIya', 'attrib': [{'pos': 'ger', 'defs': 'smite'}], 'isLemma': 0, 'parents': ['ni_han']},
	{'value': 'nihaniṣyat', 'label': 'nihanizyat', 'attrib': [{'pos': 'fap', 'defs': 'smite'}], 'isLemma': 0, 'parents': ['ni_han']},
	{'value': 'nihaniṣyamāṇa', 'label': 'nihanizyamARa', 'attrib': [{'pos': 'fpp', 'defs': 'smite'}], 'isLemma': 0, 'parents': ['ni_han']},
	{'value': 'nihantavya', 'label': 'nihantavya', 'attrib': [{'pos': 'ger', 'defs': 'smite'}], 'isLemma': 0, 'parents': ['ni_han']},
	{'value': 'nihantum', 'label': 'nihantum', 'attrib': [{'pos': 'inf', 'defs': 'smite'}], 'isLemma': 0, 'parents': ['ni_han']},
	{'value': 'nihanya', 'label': 'nihanya', 'attrib': [{'pos': 'abs', 'defs': 'smite'}], 'isLemma': 0, 'parents': ['ni_han']},
	{'value': 'nihanyamāna', 'label': 'nihanyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'smite'}], 'isLemma': 0, 'parents': ['ni_han']},
	{'value': 'nṛ', 'label': 'nf', 'attrib': [{'pos': 'm', 'defs': 'man'}], 'isLemma': 1},
	{'value': 'nṛpa', 'label': 'nfpa', 'attrib': [{'pos': 'm', 'defs': 'king'}], 'isLemma': 1},
	{'value': 'nṛpasattama', 'label': 'nfpasattama', 'attrib': [{'pos': 'm', 'defs': 'most excellent king'}], 'isLemma': 1},
	{'value': 'nemivas', 'label': 'nemivas', 'attrib': [{'pos': 'pfap', 'defs': 'bow'}], 'isLemma': 0, 'parents': ['nam']},
	{'value': 'neśivas', 'label': 'neSivas', 'attrib': [{'pos': 'pfap', 'defs': 'perish'}], 'isLemma': 0, 'parents': ['naS']},
	{'value': 'neśvas', 'label': 'neSvas', 'attrib': [{'pos': 'pfap', 'defs': 'perish'}], 'isLemma': 0, 'parents': ['naS']},
	{'value': 'pañcan', 'label': 'paYcan', 'attrib': [{'pos': 'card_num', 'defs': 'five'}], 'isLemma': 1},
	{'value': 'pañcapañcāśat', 'label': 'paYcapaYcASat', 'attrib': [{'pos': 'card_num', 'defs': 'fifty-five'}], 'isLemma': 1},
	{'value': 'pañcapañcāśattama', 'label': 'paYcapaYcASattama', 'attrib': [{'pos': 'ord_num', 'defs': 'fifty-fifth'}], 'isLemma': 1},
	{'value': 'pañcāla', 'label': 'paYcAla', 'attrib': [{'pos': 'm', 'defs': 'the people of the region between the Yamuna and Gomati rivers in north India, the region itself'}], 'isLemma': 1},
	{'value': 'pañcāśat', 'label': 'paYcASat', 'attrib': [{'pos': 'card_num', 'defs': 'fifty'}], 'isLemma': 1},
	{'value': 'pattavya', 'label': 'pattavya', 'attrib': [{'pos': 'ger', 'defs': 'step'}], 'isLemma': 0, 'parents': ['pad']},
	{'value': 'pattum', 'label': 'pattum', 'attrib': [{'pos': 'inf', 'defs': 'step'}], 'isLemma': 0, 'parents': ['pad']},
	{'value': 'pattvā', 'label': 'pattvA', 'attrib': [{'pos': 'abs', 'defs': 'step'}], 'isLemma': 0, 'parents': ['pad']},
	{'value': 'patsyamāna', 'label': 'patsyamAna', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'step'}], 'isLemma': 0, 'parents': ['pad']},
	{'value': 'pathin', 'label': 'paTin', 'attrib': [{'pos': 'm', 'defs': 'path, road'}], 'isLemma': 1},
	{'value': 'pad', 'label': 'pad', 'attrib': [{'pos': 'vt4m', 'defs': 'step'}], 'isLemma': 1},
	{'value': 'pada', 'label': 'pada', 'attrib': [{'pos': 'n', 'defs': 'step, foot'}], 'isLemma': 1},
	{'value': 'padanīya', 'label': 'padanIya', 'attrib': [{'pos': 'ger', 'defs': 'step'}], 'isLemma': 0, 'parents': ['pad']},
	{'value': 'padyamāna', 'label': 'padyamAna', 'attrib': [{'pos': 'prmp', 'defs': 'step'}, {'pos': 'prpp', 'defs': 'step'}], 'isLemma': 0, 'parents': ['pad']},
	{'value': 'panna', 'label': 'panna', 'attrib': [{'pos': 'ppp', 'defs': 'step'}], 'isLemma': 0, 'parents': ['pad']},
	{'value': 'pannavat', 'label': 'pannavat', 'attrib': [{'pos': 'pap', 'defs': 'step'}], 'isLemma': 0, 'parents': ['pad']},
	{'value': 'paparāṇa', 'label': 'paparARa', 'attrib': [{'pos': 'pfpp', 'defs': 'fill'}], 'isLemma': 0, 'parents': ['pF']},
	{'value': 'paparvas', 'label': 'paparvas', 'attrib': [{'pos': 'pfap', 'defs': 'fill'}], 'isLemma': 0, 'parents': ['pF']},
	{'value': 'papāna', 'label': 'papAna', 'attrib': [{'pos': 'pfpp', 'defs': 'drink'}], 'isLemma': 0, 'parents': ['pA']},
	{'value': 'papivas', 'label': 'papivas', 'attrib': [{'pos': 'pfap', 'defs': 'drink'}], 'isLemma': 0, 'parents': ['pA']},
	{'value': 'papṝvas', 'label': 'papFvas', 'attrib': [{'pos': 'pfap', 'defs': 'fill'}], 'isLemma': 0, 'parents': ['pF']},
	{'value': 'papracchāna', 'label': 'papracCAna', 'attrib': [{'pos': 'pfpp', 'defs': 'ask'}], 'isLemma': 0, 'parents': ['pracC']},
	{'value': 'papracchvas', 'label': 'papracCvas', 'attrib': [{'pos': 'pfap', 'defs': 'ask'}], 'isLemma': 0, 'parents': ['pracC']},
	{'value': 'paprāṇa', 'label': 'paprARa', 'attrib': [{'pos': 'pfpp', 'defs': 'fill'}], 'isLemma': 0, 'parents': ['pF']},
	{'value': 'para', 'label': 'para', 'attrib': [{'pos': 'pron, pron_adj', 'defs': 'farther, other'}, {'pos': 'm', 'defs': 'enemy'}], 'isLemma': 1},
	{'value': 'paraṁtapa', 'label': 'paraMtapa', 'attrib': [{'pos': 'adj', 'defs': 'enemy-scorching'}], 'isLemma': 1},
	{'value': 'paravīra', 'label': 'paravIra', 'attrib': [{'pos': 'm', 'defs': 'enemy hero'}], 'isLemma': 1},
	{'value': 'paravīrahan', 'label': 'paravIrahan', 'attrib': [{'pos': 'adj', 'defs': 'slaying enemy heroes'}], 'isLemma': 1},
	{'value': 'parā', 'label': 'parA', 'attrib': [{'pos': 'preverb', 'defs': 'far'}], 'isLemma': 1},
	{'value': 'parākrama', 'label': 'parAkrama', 'attrib': [{'pos': 'm', 'defs': 'boldness'}], 'isLemma': 1},
	{'value': 'parā_kram', 'label': 'parA_kram', 'attrib': [{'pos': 'vt1a', 'defs': 'step beyond, be bold'}], 'isLemma': 1},
	{'value': 'parāyaṇa', 'label': 'parAyaRa', 'attrib': [{'pos': 'n', 'defs': 'final aim'}], 'isLemma': 1},
	{'value': 'pari', 'label': 'pari', 'attrib': [{'pos': 'preverb', 'defs': 'around'}], 'isLemma': 1},
	{'value': 'pariṣyat', 'label': 'parizyat', 'attrib': [{'pos': 'fap', 'defs': 'fill'}], 'isLemma': 0, 'parents': ['pF']},
	{'value': 'pariṣyamāṇa', 'label': 'parizyamARa', 'attrib': [{'pos': 'fpp', 'defs': 'fill'}], 'isLemma': 0, 'parents': ['pF']},
	{'value': 'parisaṁvatsara', 'label': 'parisaMvatsara', 'attrib': [{'pos': 'm', 'defs': 'a full year'}], 'isLemma': 1},
	{'value': 'parisaṁvatsaroṣita', 'label': 'parisaMvatsarozita', 'attrib': [{'pos': 'adj', 'defs': 'dwelt a full year'}], 'isLemma': 1},
	{'value': 'parispanda', 'label': 'parispanda', 'attrib': [{'pos': 'm', 'defs': 'stirring, retinue'}], 'isLemma': 1},
	{'value': 'parīṣyat', 'label': 'parIzyat', 'attrib': [{'pos': 'fap', 'defs': 'fill'}], 'isLemma': 0, 'parents': ['pF']},
	{'value': 'parīṣyamāṇa', 'label': 'parIzyamARa', 'attrib': [{'pos': 'fpp', 'defs': 'fill'}], 'isLemma': 0, 'parents': ['pF']},
	{'value': 'pare', 'label': 'pare', 'attrib': [{'pos': 'vt2a', 'defs': 'go away, flee, pass away'}], 'isLemma': 1},
	{'value': 'parvan', 'label': 'parvan', 'attrib': [{'pos': 'n', 'defs': 'joint, section'}], 'isLemma': 1},
	{'value': 'pavanīya', 'label': 'pavanIya', 'attrib': [{'pos': 'ger', 'defs': 'purify'}], 'isLemma': 0, 'parents': ['pU']},
	{'value': 'pavita', 'label': 'pavita', 'attrib': [{'pos': 'ppp', 'defs': 'purify'}], 'isLemma': 0, 'parents': ['pU']},
	{'value': 'pavitavat', 'label': 'pavitavat', 'attrib': [{'pos': 'pap', 'defs': 'purify'}], 'isLemma': 0, 'parents': ['pU']},
	{'value': 'pavitavya', 'label': 'pavitavya', 'attrib': [{'pos': 'ger', 'defs': 'purify'}], 'isLemma': 0, 'parents': ['pU']},
	{'value': 'pavitum', 'label': 'pavitum', 'attrib': [{'pos': 'inf', 'defs': 'purify'}], 'isLemma': 0, 'parents': ['pU']},
	{'value': 'pavitvā', 'label': 'pavitvA', 'attrib': [{'pos': 'abs', 'defs': 'purify'}], 'isLemma': 0, 'parents': ['pU']},
	{'value': 'paviṣyat', 'label': 'pavizyat', 'attrib': [{'pos': 'fap', 'defs': 'purify'}], 'isLemma': 0, 'parents': ['pU']},
	{'value': 'paviṣyamāṇa', 'label': 'pavizyamARa', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'purify'}], 'isLemma': 0, 'parents': ['pU']},
	{'value': 'pavya', 'label': 'pavya', 'attrib': [{'pos': 'ger', 'defs': 'purify'}], 'isLemma': 0, 'parents': ['pU']},
	{'value': 'paśyat', 'label': 'paSyat', 'attrib': [{'pos': 'prap', 'defs': 'see, watch'}], 'isLemma': 0, 'parents': ['dfS']},
	{'value': 'pā', 'label': 'pA', 'attrib': [{'pos': 'vt2a', 'defs': 'protect'}, {'pos': 'vt1a', 'defs': 'drink'}], 'isLemma': 1},
	{'value': 'pāñcāla', 'label': 'pAYcAla', 'attrib': [{'pos': 'adj', 'defs': 'of Pañcāla'}], 'isLemma': 1},
	{'value': 'pāṇḍava', 'label': 'pARqava', 'attrib': [{'pos': 'm', 'defs': 'son of Pāṇḍu'}], 'isLemma': 1},
	{'value': 'pāṇḍu', 'label': 'pARqu', 'attrib': [{'pos': 'adj', 'defs': 'pale'}, {'pos': 'm_pn', 'defs': 'Pāṇḍu'}], 'isLemma': 1},
	{'value': 'pātavya', 'label': 'pAtavya', 'attrib': [{'pos': 'ger', 'defs': 'drink'}], 'isLemma': 0, 'parents': ['pA']},
	{'value': 'pātum', 'label': 'pAtum', 'attrib': [{'pos': 'inf', 'defs': 'drink'}], 'isLemma': 0, 'parents': ['pA']},
	{'value': 'pātra', 'label': 'pAtra', 'attrib': [{'pos': 'n', 'defs': 'drinking-vessel, cup, begging bowl, worthy person'}], 'isLemma': 1},
	{'value': 'pādya', 'label': 'pAdya', 'attrib': [{'pos': 'ger', 'defs': 'step'}], 'isLemma': 0, 'parents': ['pad']},
	{'value': 'pānīya', 'label': 'pAnIya', 'attrib': [{'pos': 'ger', 'defs': 'drink'}], 'isLemma': 0, 'parents': ['pA']},
	{'value': 'pāpa', 'label': 'pApa', 'attrib': [{'pos': 'adj', 'defs': 'evil'}, {'pos': 'n', 'defs': 'evil'}], 'isLemma': 1},
	{'value': 'pārtha', 'label': 'pArTa', 'attrib': [{'pos': 'm', 'defs': 'son of Pṛthā'}], 'isLemma': 1},
	{'value': 'pāvaka', 'label': 'pAvaka', 'attrib': [{'pos': 'adj', 'defs': 'purifier, epithet of fire'}], 'isLemma': 1},
	{'value': 'pāsyat', 'label': 'pAsyat', 'attrib': [{'pos': 'fap', 'defs': 'drink'}], 'isLemma': 0, 'parents': ['pA']},
	{'value': 'pāsyamāna', 'label': 'pAsyamAna', 'attrib': [{'pos': 'fpp', 'defs': 'drink'}], 'isLemma': 0, 'parents': ['pA']},
	{'value': 'pitṛ', 'label': 'pitf', 'attrib': [{'pos': 'm', 'defs': 'father'}], 'isLemma': 1},
	{'value': 'pibat', 'label': 'pibat', 'attrib': [{'pos': 'prap', 'defs': 'drink'}], 'isLemma': 0, 'parents': ['pA']},
	{'value': 'pīta', 'label': 'pIta', 'attrib': [{'pos': 'ppp', 'defs': 'drink'}], 'isLemma': 0, 'parents': ['pA']},
	{'value': 'pītavat', 'label': 'pItavat', 'attrib': [{'pos': 'pap', 'defs': 'drink'}], 'isLemma': 0, 'parents': ['pA']},
	{'value': 'pītvā', 'label': 'pItvA', 'attrib': [{'pos': 'abs', 'defs': 'drink'}], 'isLemma': 0, 'parents': ['pA']},
	{'value': 'pīyamāna', 'label': 'pIyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'drink'}], 'isLemma': 0, 'parents': ['pA']},
	{'value': 'putra', 'label': 'putra', 'attrib': [{'pos': 'm', 'defs': 'son, child'}], 'isLemma': 1},
	{'value': 'punat', 'label': 'punat', 'attrib': [{'pos': 'prap', 'defs': 'purify'}], 'isLemma': 0, 'parents': ['pU']},
	{'value': 'punar', 'label': 'punar', 'attrib': [{'pos': 'adv', 'defs': 'again'}], 'isLemma': 1},
	{'value': 'punāna', 'label': 'punAna', 'attrib': [{'pos': 'prmp', 'defs': 'purify'}], 'isLemma': 0, 'parents': ['pU']},
	{'value': 'pupuvāna', 'label': 'pupuvAna', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'purify'}], 'isLemma': 0, 'parents': ['pU']},
	{'value': 'pupūvas', 'label': 'pupUvas', 'attrib': [{'pos': 'pfap', 'defs': 'purify'}], 'isLemma': 0, 'parents': ['pU']},
	{'value': 'pura', 'label': 'pura', 'attrib': [{'pos': 'n', 'defs': 'city'}], 'isLemma': 1},
	{'value': 'purā', 'label': 'purA', 'attrib': [{'pos': 'adv', 'defs': 'before, formerly'}], 'isLemma': 1},
	{'value': 'purāvṛtta', 'label': 'purAvftta', 'attrib': [{'pos': 'adj', 'defs': 'occurred in former times'}, {'pos': 'n, ck', 'defs': 'event or account of the past'}], 'isLemma': 1},
	{'value': 'pū', 'label': 'pU', 'attrib': [{'pos': 'vt9am', 'defs': 'purify'}], 'isLemma': 1},
	{'value': 'pūj', 'label': 'pUj', 'attrib': [{'pos': 'vt10m', 'defs': 'honor, perform a pūjā'}], 'isLemma': 1},
	{'value': 'pūjanīya', 'label': 'pUjanIya', 'attrib': [{'pos': 'ger', 'defs': 'honor, perform a pūjā'}], 'isLemma': 0, 'parents': ['pUj']},
	{'value': 'pūjayamāna', 'label': 'pUjayamAna', 'attrib': [{'pos': 'prmp', 'defs': 'honor, perform a pūjā'}], 'isLemma': 0, 'parents': ['pUj']},
	{'value': 'pūjayām', 'label': 'pUjayAm', 'attrib': [{'pos': 'ppf_actn', 'defs': 'honor, perform a pūjā'}], 'isLemma': 0, 'parents': ['pUj']},
	{'value': 'pūjayitum', 'label': 'pUjayitum', 'attrib': [{'pos': 'inf', 'defs': 'honor, perform a pūjā'}], 'isLemma': 0, 'parents': ['pUj']},
	{'value': 'pūjayitvā', 'label': 'pUjayitvA', 'attrib': [{'pos': 'abs', 'defs': 'honor, perform a pūjā'}], 'isLemma': 0, 'parents': ['pUj']},
	{'value': 'pūjayiṣyamāṇa', 'label': 'pUjayizyamARa', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'honor, perform a pūjā'}], 'isLemma': 0, 'parents': ['pUj']},
	{'value': 'pūjita', 'label': 'pUjita', 'attrib': [{'pos': 'ppp', 'defs': 'honor, perform a pūjā'}], 'isLemma': 0, 'parents': ['pUj']},
	{'value': 'pūjitavat', 'label': 'pUjitavat', 'attrib': [{'pos': 'pap', 'defs': 'honor, perform a pūjā'}], 'isLemma': 0, 'parents': ['pUj']},
	{'value': 'pūjitavya', 'label': 'pUjitavya', 'attrib': [{'pos': 'ger', 'defs': 'honor, perform a pūjā'}], 'isLemma': 0, 'parents': ['pUj']},
	{'value': 'pūjya', 'label': 'pUjya', 'attrib': [{'pos': 'ger', 'defs': 'honor, perform a pūjā'}], 'isLemma': 0, 'parents': ['pUj']},
	{'value': 'pūjyamāna', 'label': 'pUjyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'honor, perform a pūjā'}], 'isLemma': 0, 'parents': ['pUj']},
	{'value': 'pūta', 'label': 'pUta', 'attrib': [{'pos': 'ppp', 'defs': 'purify'}], 'isLemma': 0, 'parents': ['pU']},
	{'value': 'pūtavat', 'label': 'pUtavat', 'attrib': [{'pos': 'pap', 'defs': 'purify'}], 'isLemma': 0, 'parents': ['pU']},
	{'value': 'pūtvā', 'label': 'pUtvA', 'attrib': [{'pos': 'abs', 'defs': 'purify'}], 'isLemma': 0, 'parents': ['pU']},
	{'value': 'pūyamāna', 'label': 'pUyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'purify'}], 'isLemma': 0, 'parents': ['pU']},
	{'value': 'pūraṇīya', 'label': 'pUraRIya', 'attrib': [{'pos': 'ger', 'defs': 'fill'}], 'isLemma': 0, 'parents': ['pF']},
	{'value': 'pūritavya', 'label': 'pUritavya', 'attrib': [{'pos': 'ger', 'defs': 'fill'}], 'isLemma': 0, 'parents': ['pF']},
	{'value': 'pūritum', 'label': 'pUritum', 'attrib': [{'pos': 'inf', 'defs': 'fill'}], 'isLemma': 0, 'parents': ['pF']},
	{'value': 'pūrṇa', 'label': 'pUrRa', 'attrib': [{'pos': 'adj', 'defs': 'full'}], 'isLemma': 1},
	{'value': 'pūrta', 'label': 'pUrta', 'attrib': [{'pos': 'ppp', 'defs': 'fill'}], 'isLemma': 0, 'parents': ['pF']},
	{'value': 'pūrtavat', 'label': 'pUrtavat', 'attrib': [{'pos': 'pap', 'defs': 'fill'}], 'isLemma': 0, 'parents': ['pF']},
	{'value': 'pūrtvā', 'label': 'pUrtvA', 'attrib': [{'pos': 'abs', 'defs': 'fill'}], 'isLemma': 0, 'parents': ['pF']},
	{'value': 'pūrya', 'label': 'pUrya', 'attrib': [{'pos': 'ger', 'defs': 'fill'}], 'isLemma': 0, 'parents': ['pF']},
	{'value': 'pūryamāṇa', 'label': 'pUryamARa', 'attrib': [{'pos': 'prpp', 'defs': 'fill'}], 'isLemma': 0, 'parents': ['pF']},
	{'value': 'pṛcchat', 'label': 'pfcCat', 'attrib': [{'pos': 'prap', 'defs': 'ask'}], 'isLemma': 0, 'parents': ['pracC']},
	{'value': 'pṛcchya', 'label': 'pfcCya', 'attrib': [{'pos': 'ger', 'defs': 'ask'}], 'isLemma': 0, 'parents': ['pracC']},
	{'value': 'pṛcchyamāna', 'label': 'pfcCyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'ask'}], 'isLemma': 0, 'parents': ['pracC']},
	{'value': 'pṛṇat', 'label': 'pfRat', 'attrib': [{'pos': 'prap', 'defs': 'fill'}], 'isLemma': 0, 'parents': ['pF']},
	{'value': 'pṛtha', 'label': 'pfTa', 'attrib': [{'pos': 'adj', 'defs': 'spread out, broad'}], 'isLemma': 1},
	{'value': 'pṛthā', 'label': 'pfTA', 'attrib': [{'pos': 'f', 'defs': 'Kuntī'}], 'isLemma': 1},
	{'value': 'pṛthivī', 'label': 'pfTivI', 'attrib': [{'pos': 'f', 'defs': 'earth'}], 'isLemma': 1},
	{'value': 'pṛthivīkṣaya', 'label': 'pfTivIkzaya', 'attrib': [{'pos': 'm', 'defs': 'destruction of the earth'}], 'isLemma': 1},
	{'value': 'pṛthivīkṣayakāraka', 'label': 'pfTivIkzayakAraka', 'attrib': [{'pos': 'adj', 'defs': 'causing the destruction of the earth'}], 'isLemma': 1},
	{'value': 'pṛthu', 'label': 'pfTu', 'attrib': [{'pos': 'adj', 'defs': 'broad'}], 'isLemma': 1},
	{'value': 'pṛthvī', 'label': 'pfTvI', 'attrib': [{'pos': 'f', 'defs': 'the earth'}], 'isLemma': 1},
	{'value': 'pṛṣṭa', 'label': 'pfzwa', 'attrib': [{'pos': 'ppp', 'defs': 'ask'}], 'isLemma': 0, 'parents': ['pracC']},
	{'value': 'pṛṣṭavat', 'label': 'pfzwavat', 'attrib': [{'pos': 'pap', 'defs': 'ask'}], 'isLemma': 0, 'parents': ['pracC']},
	{'value': 'pṛṣṭvā', 'label': 'pfzwvA', 'attrib': [{'pos': 'abs', 'defs': 'ask'}], 'isLemma': 0, 'parents': ['pracC']},
	{'value': 'pṝ', 'label': 'pF', 'attrib': [{'pos': 'vt9a', 'defs': 'fill'}], 'isLemma': 1},
	{'value': 'pedāna', 'label': 'pedAna', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'step'}], 'isLemma': 0, 'parents': ['pad']},
	{'value': 'peya', 'label': 'peya', 'attrib': [{'pos': 'ger', 'defs': 'drink'}], 'isLemma': 0, 'parents': ['pA']},
	{'value': 'paura', 'label': 'pOra', 'attrib': [{'pos': 'adj', 'defs': 'urban'}, {'pos': 'm', 'defs': 'citizen'}], 'isLemma': 1},
	{'value': 'paurasaṁmata', 'label': 'pOrasaMmata', 'attrib': [{'pos': 'adj', 'defs': 'approved of by the citizens'}], 'isLemma': 1},
	{'value': 'pra', 'label': 'pra', 'attrib': [{'pos': 'preverb', 'defs': 'before'}], 'isLemma': 1},
	{'value': 'prakāra', 'label': 'prakAra', 'attrib': [{'pos': 'm', 'defs': 'doing, kind, manner'}], 'isLemma': 1},
	{'value': 'prakṣipat', 'label': 'prakzipat', 'attrib': [{'pos': 'prap', 'defs': 'throw, project'}], 'isLemma': 0, 'parents': ['pra_kzip']},
	{'value': 'prakṣipta', 'label': 'prakzipta', 'attrib': [{'pos': 'ppp', 'defs': 'throw, project'}], 'isLemma': 0, 'parents': ['pra_kzip']},
	{'value': 'prakṣiptavat', 'label': 'prakziptavat', 'attrib': [{'pos': 'pap', 'defs': 'throw, project'}], 'isLemma': 0, 'parents': ['pra_kzip']},
	{'value': 'prakṣipya', 'label': 'prakzipya', 'attrib': [{'pos': 'abs', 'defs': 'throw, project'}], 'isLemma': 0, 'parents': ['pra_kzip']},
	{'value': 'prakṣipyat', 'label': 'prakzipyat', 'attrib': [{'pos': 'prap', 'defs': 'throw, project'}], 'isLemma': 0, 'parents': ['pra_kzip']},
	{'value': 'prakṣipyamāṇa', 'label': 'prakzipyamARa', 'attrib': [{'pos': 'prmp, prpp', 'defs': 'throw, project'}], 'isLemma': 0, 'parents': ['pra_kzip']},
	{'value': 'prakṣipsyat', 'label': 'prakzipsyat', 'attrib': [{'pos': 'fap', 'defs': 'throw, project'}], 'isLemma': 0, 'parents': ['pra_kzip']},
	{'value': 'prakṣipsyamāṇa', 'label': 'prakzipsyamARa', 'attrib': [{'pos': 'fpp', 'defs': 'throw, project'}], 'isLemma': 0, 'parents': ['pra_kzip']},
	{'value': 'prakṣepayaṇīya', 'label': 'prakzepayaRIya', 'attrib': [{'pos': 'ger', 'defs': 'throw, project'}], 'isLemma': 0, 'parents': ['pra_kzip']},
	{'value': 'prakṣeptavya', 'label': 'prakzeptavya', 'attrib': [{'pos': 'ger', 'defs': 'throw, project'}], 'isLemma': 0, 'parents': ['pra_kzip']},
	{'value': 'prakṣeptum', 'label': 'prakzeptum', 'attrib': [{'pos': 'inf', 'defs': 'throw, project'}], 'isLemma': 0, 'parents': ['pra_kzip']},
	{'value': 'prakṣepya', 'label': 'prakzepya', 'attrib': [{'pos': 'ger', 'defs': 'throw, project'}], 'isLemma': 0, 'parents': ['pra_kzip']},
	{'value': 'prakṣepsyat', 'label': 'prakzepsyat', 'attrib': [{'pos': 'fap', 'defs': 'throw, project'}], 'isLemma': 0, 'parents': ['pra_kzip']},
	{'value': 'prakṣepsyamāṇa', 'label': 'prakzepsyamARa', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'throw, project'}], 'isLemma': 0, 'parents': ['pra_kzip']},
	{'value': 'prakṣyat', 'label': 'prakzyat', 'attrib': [{'pos': 'fap', 'defs': 'ask'}], 'isLemma': 0, 'parents': ['pracC']},
	{'value': 'prakṣyamāṇa', 'label': 'prakzyamARa', 'attrib': [{'pos': 'fpp', 'defs': 'ask'}], 'isLemma': 0, 'parents': ['pracC']},
	{'value': 'pracikṣipāṇa', 'label': 'pracikzipARa', 'attrib': [{'pos': 'pfpp', 'defs': 'throw, project'}], 'isLemma': 0, 'parents': ['pra_kzip']},
	{'value': 'pracikṣipivas', 'label': 'pracikzipivas', 'attrib': [{'pos': 'pfap', 'defs': 'throw, project'}], 'isLemma': 0, 'parents': ['pra_kzip']},
	{'value': 'pracch', 'label': 'pracC', 'attrib': [{'pos': 'vt6a', 'defs': 'ask'}], 'isLemma': 1},
	{'value': 'pracchanīya', 'label': 'pracCanIya', 'attrib': [{'pos': 'ger', 'defs': 'ask'}], 'isLemma': 0, 'parents': ['pracC']},
	{'value': 'pra_āp', 'label': 'pra_Ap', 'attrib': [{'pos': 'vt5a', 'defs': 'attain, obtain'}], 'isLemma': 1},
	{'value': 'pra_ud_sah', 'label': 'pra_ud_sah', 'attrib': [{'pos': 'vt1m, vt10am', 'defs': 'be eager'}], 'isLemma': 1},
	{'value': 'pra_kṛ', 'label': 'pra_kf', 'attrib': [{'pos': 'vt8am', 'defs': 'make, produce'}], 'isLemma': 1},
	{'value': 'pra_kṣip', 'label': 'pra_kzip', 'attrib': [{'pos': 'vt4a, vt6am', 'defs': 'throw, project'}], 'isLemma': 1},
	{'value': 'pra_tap', 'label': 'pra_tap', 'attrib': [{'pos': 'vt1a', 'defs': 'heat, burn, shine forth'}], 'isLemma': 1},
	{'value': 'pra_dru', 'label': 'pra_dru', 'attrib': [{'pos': 'vt1a', 'defs': 'run forth, escape, flee'}], 'isLemma': 1},
	{'value': 'pra_budh', 'label': 'pra_buD', 'attrib': [{'pos': 'vt1am', 'defs': 'know'}, {'pos': 'vt4m', 'defs': 'awake, be aware, know'}], 'isLemma': 1},
	{'value': 'pra_mad', 'label': 'pra_mad', 'attrib': [{'pos': 'vi4a', 'defs': 'err, be careless'}], 'isLemma': 1},
	{'value': 'pra_vac', 'label': 'pra_vac', 'attrib': [{'pos': 'vt2a', 'defs': 'proclaim, declare, tell'}], 'isLemma': 1},
	{'value': 'pra_sthā', 'label': 'pra_sTA', 'attrib': [{'pos': 'vt1m', 'defs': 'set out'}], 'isLemma': 1},
	{'value': 'pratapat', 'label': 'pratapat', 'attrib': [{'pos': 'prap', 'defs': 'heat, burn, shine forth'}], 'isLemma': 0, 'parents': ['pra_tap']},
	{'value': 'pratapanīya', 'label': 'pratapanIya', 'attrib': [{'pos': 'ger', 'defs': 'heat, burn, shine forth'}], 'isLemma': 0, 'parents': ['pra_tap']},
	{'value': 'pratapta', 'label': 'pratapta', 'attrib': [{'pos': 'ppp', 'defs': 'heat, burn, shine forth'}], 'isLemma': 0, 'parents': ['pra_tap']},
	{'value': 'prataptavat', 'label': 'prataptavat', 'attrib': [{'pos': 'pap', 'defs': 'heat, burn, shine forth'}], 'isLemma': 0, 'parents': ['pra_tap']},
	{'value': 'prataptavya', 'label': 'prataptavya', 'attrib': [{'pos': 'ger', 'defs': 'heat, burn, shine forth'}], 'isLemma': 0, 'parents': ['pra_tap']},
	{'value': 'prataptum', 'label': 'prataptum', 'attrib': [{'pos': 'inf', 'defs': 'heat, burn, shine forth'}], 'isLemma': 0, 'parents': ['pra_tap']},
	{'value': 'pratapya', 'label': 'pratapya', 'attrib': [{'pos': 'abs', 'defs': 'heat, burn, shine forth'}, {'pos': 'ger', 'defs': 'heat, burn, shine forth'}], 'isLemma': 0, 'parents': ['pra_tap']},
	{'value': 'pratapyamāna', 'label': 'pratapyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'heat, burn, shine forth'}], 'isLemma': 0, 'parents': ['pra_tap']},
	{'value': 'pratapsyat', 'label': 'pratapsyat', 'attrib': [{'pos': 'fap', 'defs': 'heat, burn, shine forth'}], 'isLemma': 0, 'parents': ['pra_tap']},
	{'value': 'pratapsyamāṇa', 'label': 'pratapsyamARa', 'attrib': [{'pos': 'fpp', 'defs': 'heat, burn, shine forth'}], 'isLemma': 0, 'parents': ['pra_tap']},
	{'value': 'pratasthāna', 'label': 'pratasTAna', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'set out'}], 'isLemma': 0, 'parents': ['pra_sTA']},
	{'value': 'pratāpa', 'label': 'pratApa', 'attrib': [{'pos': 'm', 'defs': 'blazing'}], 'isLemma': 1},
	{'value': 'prati', 'label': 'prati', 'attrib': [{'pos': 'preverb, pcl', 'defs': 'toward, against'}], 'isLemma': 1},
	{'value': 'pratighāta', 'label': 'pratiGAta', 'attrib': [{'pos': 'm', 'defs': 'resistance, opposition, defense'}], 'isLemma': 1},
	{'value': 'prati_ā_gam', 'label': 'prati_A_gam', 'attrib': [{'pos': 'vt1a', 'defs': 'come back, return'}], 'isLemma': 1},
	{'value': 'prati_pad', 'label': 'prati_pad', 'attrib': [{'pos': 'vt4m', 'defs': 'step back, return to'}], 'isLemma': 1},
	{'value': 'pratipattavya', 'label': 'pratipattavya', 'attrib': [{'pos': 'ger', 'defs': 'step back, return to'}], 'isLemma': 0, 'parents': ['prati_pad']},
	{'value': 'pratipattum', 'label': 'pratipattum', 'attrib': [{'pos': 'inf', 'defs': 'step back, return to'}], 'isLemma': 0, 'parents': ['prati_pad']},
	{'value': 'pratipattvā', 'label': 'pratipattvA', 'attrib': [{'pos': 'abs', 'defs': 'step back, return to'}], 'isLemma': 0, 'parents': ['prati_pad']},
	{'value': 'pratipatsyamāna', 'label': 'pratipatsyamAna', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'step back, return to'}], 'isLemma': 0, 'parents': ['prati_pad']},
	{'value': 'pratipadanīya', 'label': 'pratipadanIya', 'attrib': [{'pos': 'ger', 'defs': 'step back, return to'}], 'isLemma': 0, 'parents': ['prati_pad']},
	{'value': 'pratipadya', 'label': 'pratipadya', 'attrib': [{'pos': 'abs', 'defs': 'step back, return to'}], 'isLemma': 0, 'parents': ['prati_pad']},
	{'value': 'pratipadyamāna', 'label': 'pratipadyamAna', 'attrib': [{'pos': 'prmp', 'defs': 'step back, return to'}, {'pos': 'prpp', 'defs': 'step back, return to'}], 'isLemma': 0, 'parents': ['prati_pad']},
	{'value': 'pratipanna', 'label': 'pratipanna', 'attrib': [{'pos': 'ppp', 'defs': 'step back, return to'}], 'isLemma': 0, 'parents': ['prati_pad']},
	{'value': 'pratipannavat', 'label': 'pratipannavat', 'attrib': [{'pos': 'pap', 'defs': 'step back, return to'}], 'isLemma': 0, 'parents': ['prati_pad']},
	{'value': 'pratipādya', 'label': 'pratipAdya', 'attrib': [{'pos': 'ger', 'defs': 'step back, return to'}], 'isLemma': 0, 'parents': ['prati_pad']},
	{'value': 'pratipedāna', 'label': 'pratipedAna', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'step back, return to'}], 'isLemma': 0, 'parents': ['prati_pad']},
	{'value': 'pratiṣṭhamāna', 'label': 'pratizWamAna', 'attrib': [{'pos': 'prmp', 'defs': 'set out'}], 'isLemma': 0, 'parents': ['pra_sTA']},
	{'value': 'pratīcī', 'label': 'pratIcI', 'attrib': [{'pos': 'f', 'defs': 'the west'}], 'isLemma': 1},
	{'value': 'pratepāna', 'label': 'pratepAna', 'attrib': [{'pos': 'pfpp', 'defs': 'heat, burn, shine forth'}], 'isLemma': 0, 'parents': ['pra_tap']},
	{'value': 'pratepivas', 'label': 'pratepivas', 'attrib': [{'pos': 'pfap', 'defs': 'heat, burn, shine forth'}], 'isLemma': 0, 'parents': ['pra_tap']},
	{'value': 'pratyañc', 'label': 'pratyaYc', 'attrib': [{'pos': 'adj', 'defs': 'turned back or against, western'}], 'isLemma': 1},
	{'value': 'pratyāgacchat', 'label': 'pratyAgacCat', 'attrib': [{'pos': 'prap', 'defs': 'come back, return'}], 'isLemma': 0, 'parents': ['prati_A_gam']},
	{'value': 'pratyāgata', 'label': 'pratyAgata', 'attrib': [{'pos': 'ppp', 'defs': 'come back, return'}], 'isLemma': 0, 'parents': ['prati_A_gam']},
	{'value': 'pratyāgatavat', 'label': 'pratyAgatavat', 'attrib': [{'pos': 'pap', 'defs': 'come back, return'}], 'isLemma': 0, 'parents': ['prati_A_gam']},
	{'value': 'pratyāgatya', 'label': 'pratyAgatya', 'attrib': [{'pos': 'abs', 'defs': 'come back, return'}], 'isLemma': 0, 'parents': ['prati_A_gam']},
	{'value': 'pratyāgantavya', 'label': 'pratyAgantavya', 'attrib': [{'pos': 'ger', 'defs': 'come back, return'}], 'isLemma': 0, 'parents': ['prati_A_gam']},
	{'value': 'pratyāgantum', 'label': 'pratyAgantum', 'attrib': [{'pos': 'inf', 'defs': 'come back, return'}], 'isLemma': 0, 'parents': ['prati_A_gam']},
	{'value': 'pratyāgamanīya', 'label': 'pratyAgamanIya', 'attrib': [{'pos': 'ger', 'defs': 'come back, return'}], 'isLemma': 0, 'parents': ['prati_A_gam']},
	{'value': 'pratyāgamiṣyat', 'label': 'pratyAgamizyat', 'attrib': [{'pos': 'fap', 'defs': 'come back, return'}], 'isLemma': 0, 'parents': ['prati_A_gam']},
	{'value': 'pratyāgamiṣyamāṇa', 'label': 'pratyAgamizyamARa', 'attrib': [{'pos': 'fpp', 'defs': 'come back, return'}], 'isLemma': 0, 'parents': ['prati_A_gam']},
	{'value': 'pratyāgamya', 'label': 'pratyAgamya', 'attrib': [{'pos': 'abs', 'defs': 'come back, return'}, {'pos': 'ger', 'defs': 'come back, return'}], 'isLemma': 0, 'parents': ['prati_A_gam']},
	{'value': 'pratyāgamyamāna', 'label': 'pratyAgamyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'come back, return'}], 'isLemma': 0, 'parents': ['prati_A_gam']},
	{'value': 'pratyājaganvas', 'label': 'pratyAjaganvas', 'attrib': [{'pos': 'pfap', 'defs': 'come back, return'}], 'isLemma': 0, 'parents': ['prati_A_gam']},
	{'value': 'pratyājagmāna', 'label': 'pratyAjagmAna', 'attrib': [{'pos': 'pfpp', 'defs': 'come back, return'}], 'isLemma': 0, 'parents': ['prati_A_gam']},
	{'value': 'pratyājagmivas', 'label': 'pratyAjagmivas', 'attrib': [{'pos': 'pfap', 'defs': 'come back, return'}], 'isLemma': 0, 'parents': ['prati_A_gam']},
	{'value': 'praty_ā_gam', 'label': 'praty_A_gam', 'attrib': [{'pos': 'vt1a', 'defs': 'come back, return'}], 'isLemma': 1},
	{'value': 'prath', 'label': 'praT', 'attrib': [{'pos': 'vi1m', 'defs': 'extend, spread'}], 'isLemma': 1},
	{'value': 'pradhāna', 'label': 'praDAna', 'attrib': [{'pos': 'adj', 'defs': 'principal'}], 'isLemma': 1},
	{'value': 'prabuddha', 'label': 'prabudDa', 'attrib': [{'pos': 'ppp', 'defs': 'awake, be aware, know'}], 'isLemma': 0, 'parents': ['pra_buD']},
	{'value': 'prabuddhavat', 'label': 'prabudDavat', 'attrib': [{'pos': 'pap', 'defs': 'awake, be aware, know'}], 'isLemma': 0, 'parents': ['pra_buD']},
	{'value': 'prabuddhvā', 'label': 'prabudDvA', 'attrib': [{'pos': 'abs', 'defs': 'awake, be aware, know'}], 'isLemma': 0, 'parents': ['pra_buD']},
	{'value': 'prabudhya', 'label': 'prabuDya', 'attrib': [{'pos': 'abs', 'defs': 'awake, be aware, know'}], 'isLemma': 0, 'parents': ['pra_buD']},
	{'value': 'prabudhyamāna', 'label': 'prabuDyamAna', 'attrib': [{'pos': 'prmp', 'defs': 'awake, be aware, know'}, {'pos': 'prpp', 'defs': 'awake, be aware, know'}], 'isLemma': 0, 'parents': ['pra_buD']},
	{'value': 'prabubudhāna', 'label': 'prabubuDAna', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'awake, be aware, know'}], 'isLemma': 0, 'parents': ['pra_buD']},
	{'value': 'praboddhavya', 'label': 'prabodDavya', 'attrib': [{'pos': 'ger', 'defs': 'awake, be aware, know'}], 'isLemma': 0, 'parents': ['pra_buD']},
	{'value': 'praboddhum', 'label': 'prabodDum', 'attrib': [{'pos': 'inf', 'defs': 'awake, be aware, know'}], 'isLemma': 0, 'parents': ['pra_buD']},
	{'value': 'prabodhanīya', 'label': 'praboDanIya', 'attrib': [{'pos': 'ger', 'defs': 'awake, be aware, know'}], 'isLemma': 0, 'parents': ['pra_buD']},
	{'value': 'prabodhya', 'label': 'praboDya', 'attrib': [{'pos': 'ger', 'defs': 'awake, be aware, know'}], 'isLemma': 0, 'parents': ['pra_buD']},
	{'value': 'prabhotsyamāna', 'label': 'praBotsyamAna', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'awake, be aware, know'}], 'isLemma': 0, 'parents': ['pra_buD']},
	{'value': 'pramatta', 'label': 'pramatta', 'attrib': [{'pos': 'ppp', 'defs': 'err, be careless'}], 'isLemma': 0, 'parents': ['pra_mad']},
	{'value': 'pramāṇa', 'label': 'pramARa', 'attrib': [{'pos': 'n', 'defs': 'means of knowledge, measure, standard, authority'}], 'isLemma': 1},
	{'value': 'pramāṇakoṭi', 'label': 'pramARakowi', 'attrib': [{'pos': 'f_pn', 'defs': 'Pramāṇakoṭi'}], 'isLemma': 1},
	{'value': 'pravaktavya', 'label': 'pravaktavya', 'attrib': [{'pos': 'ger', 'defs': 'proclaim, declare, tell'}], 'isLemma': 0, 'parents': ['pra_vac']},
	{'value': 'pravaktum', 'label': 'pravaktum', 'attrib': [{'pos': 'inf', 'defs': 'proclaim, declare, tell'}], 'isLemma': 0, 'parents': ['pra_vac']},
	{'value': 'pravakṣyat', 'label': 'pravakzyat', 'attrib': [{'pos': 'fap', 'defs': 'proclaim, declare, tell'}], 'isLemma': 0, 'parents': ['pra_vac']},
	{'value': 'pravakṣyamāṇa', 'label': 'pravakzyamARa', 'attrib': [{'pos': 'fpp', 'defs': 'proclaim, declare, tell'}], 'isLemma': 0, 'parents': ['pra_vac']},
	{'value': 'pravacanīya', 'label': 'pravacanIya', 'attrib': [{'pos': 'ger', 'defs': 'proclaim, declare, tell'}], 'isLemma': 0, 'parents': ['pra_vac']},
	{'value': 'pravākya', 'label': 'pravAkya', 'attrib': [{'pos': 'ger', 'defs': 'proclaim, declare, tell'}], 'isLemma': 0, 'parents': ['pra_vac']},
	{'value': 'praṣṭavya', 'label': 'prazwavya', 'attrib': [{'pos': 'ger', 'defs': 'ask'}], 'isLemma': 0, 'parents': ['pracC']},
	{'value': 'praṣṭum', 'label': 'prazwum', 'attrib': [{'pos': 'inf', 'defs': 'ask'}], 'isLemma': 0, 'parents': ['pracC']},
	{'value': 'prastha', 'label': 'prasTa', 'attrib': [{'pos': 'm, n', 'defs': 'plateau, plain'}], 'isLemma': 1},
	{'value': 'prasthātavya', 'label': 'prasTAtavya', 'attrib': [{'pos': 'ger', 'defs': 'set out'}], 'isLemma': 0, 'parents': ['pra_sTA']},
	{'value': 'prasthātum', 'label': 'prasTAtum', 'attrib': [{'pos': 'inf', 'defs': 'set out'}], 'isLemma': 0, 'parents': ['pra_sTA']},
	{'value': 'prasthānīya', 'label': 'prasTAnIya', 'attrib': [{'pos': 'ger', 'defs': 'set out'}], 'isLemma': 0, 'parents': ['pra_sTA']},
	{'value': 'prasthāpayām', 'label': 'prasTApayAm', 'attrib': [{'pos': 'ppf_actn', 'defs': 'set out'}], 'isLemma': 0, 'parents': ['pra_sTA']},
	{'value': 'prasthāya', 'label': 'prasTAya', 'attrib': [{'pos': 'abs', 'defs': 'set out'}], 'isLemma': 0, 'parents': ['pra_sTA']},
	{'value': 'prasthāsyamāna', 'label': 'prasTAsyamAna', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'set out'}], 'isLemma': 0, 'parents': ['pra_sTA']},
	{'value': 'prasthita', 'label': 'prasTita', 'attrib': [{'pos': 'ppp', 'defs': 'set out'}], 'isLemma': 0, 'parents': ['pra_sTA']},
	{'value': 'prasthitavat', 'label': 'prasTitavat', 'attrib': [{'pos': 'pap', 'defs': 'set out'}], 'isLemma': 0, 'parents': ['pra_sTA']},
	{'value': 'prasthīyamāna', 'label': 'prasTIyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'set out'}], 'isLemma': 0, 'parents': ['pra_sTA']},
	{'value': 'prastheya', 'label': 'prasTeya', 'attrib': [{'pos': 'ger', 'defs': 'set out'}], 'isLemma': 0, 'parents': ['pra_sTA']},
	{'value': 'prāk', 'label': 'prAk', 'attrib': [{'pos': 'adv', 'defs': 'before, first'}], 'isLemma': 1},
	{'value': 'prācī', 'label': 'prAcI', 'attrib': [{'pos': 'f', 'defs': 'the east'}], 'isLemma': 1},
	{'value': 'prāñc', 'label': 'prAYc', 'attrib': [{'pos': 'adj', 'defs': 'turned or being forwards, front, eastward, eastern, before'}], 'isLemma': 1},
	{'value': 'prāpaṇīya', 'label': 'prApaRIya', 'attrib': [{'pos': 'ger', 'defs': 'attain, obtain'}], 'isLemma': 0, 'parents': ['pra_Ap']},
	{'value': 'prāpāṇa', 'label': 'prApARa', 'attrib': [{'pos': 'pfpp', 'defs': 'attain, obtain'}], 'isLemma': 0, 'parents': ['pra_Ap']},
	{'value': 'prāpivas', 'label': 'prApivas', 'attrib': [{'pos': 'pfap', 'defs': 'attain, obtain'}], 'isLemma': 0, 'parents': ['pra_Ap']},
	{'value': 'prāpta', 'label': 'prApta', 'attrib': [{'pos': 'ppp', 'defs': 'attain, obtain'}], 'isLemma': 0, 'parents': ['pra_Ap']},
	{'value': 'prāptavat', 'label': 'prAptavat', 'attrib': [{'pos': 'pap', 'defs': 'attain, obtain'}], 'isLemma': 0, 'parents': ['pra_Ap']},
	{'value': 'prāptavya', 'label': 'prAptavya', 'attrib': [{'pos': 'ger', 'defs': 'attain, obtain'}], 'isLemma': 0, 'parents': ['pra_Ap']},
	{'value': 'prāptum', 'label': 'prAptum', 'attrib': [{'pos': 'inf', 'defs': 'attain, obtain'}], 'isLemma': 0, 'parents': ['pra_Ap']},
	{'value': 'prāpnuvat', 'label': 'prApnuvat', 'attrib': [{'pos': 'prap', 'defs': 'attain, obtain'}], 'isLemma': 0, 'parents': ['pra_Ap']},
	{'value': 'prāpya', 'label': 'prApya', 'attrib': [{'pos': 'abs', 'defs': 'attain, obtain'}, {'pos': 'ger', 'defs': 'attain, obtain'}], 'isLemma': 0, 'parents': ['pra_Ap']},
	{'value': 'prāpyamāna', 'label': 'prApyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'attain, obtain'}], 'isLemma': 0, 'parents': ['pra_Ap']},
	{'value': 'prāpsyat', 'label': 'prApsyat', 'attrib': [{'pos': 'fap', 'defs': 'attain, obtain'}], 'isLemma': 0, 'parents': ['pra_Ap']},
	{'value': 'prāpsyamāna', 'label': 'prApsyamAna', 'attrib': [{'pos': 'fpp', 'defs': 'attain, obtain'}], 'isLemma': 0, 'parents': ['pra_Ap']},
	{'value': 'prī', 'label': 'prI', 'attrib': [{'pos': 'vt9am', 'defs': 'please'}, {'pos': 'vi4m', 'defs': 'be pleased'}], 'isLemma': 1},
	{'value': 'prīta', 'label': 'prIta', 'attrib': [{'pos': 'ppp', 'defs': 'be pleased'}], 'isLemma': 0, 'parents': ['prI']},
	{'value': 'prokta', 'label': 'prokta', 'attrib': [{'pos': 'ppp', 'defs': 'proclaim, declare, tell'}], 'isLemma': 0, 'parents': ['pra_vac']},
	{'value': 'proktavat', 'label': 'proktavat', 'attrib': [{'pos': 'pap', 'defs': 'proclaim, declare, tell'}], 'isLemma': 0, 'parents': ['pra_vac']},
	{'value': 'proktvā', 'label': 'proktvA', 'attrib': [{'pos': 'abs', 'defs': 'proclaim, declare, tell'}], 'isLemma': 0, 'parents': ['pra_vac']},
	{'value': 'procāna', 'label': 'procAna', 'attrib': [{'pos': 'pfpp', 'defs': 'proclaim, declare, tell'}], 'isLemma': 0, 'parents': ['pra_vac']},
	{'value': 'procivas', 'label': 'procivas', 'attrib': [{'pos': 'pfap', 'defs': 'proclaim, declare, tell'}], 'isLemma': 0, 'parents': ['pra_vac']},
	{'value': 'procya', 'label': 'procya', 'attrib': [{'pos': 'abs', 'defs': 'proclaim, declare, tell'}], 'isLemma': 0, 'parents': ['pra_vac']},
	{'value': 'procyamāna', 'label': 'procyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'proclaim, declare, tell'}], 'isLemma': 0, 'parents': ['pra_vac']},
	{'value': 'baka', 'label': 'baka', 'attrib': [{'pos': 'm', 'defs': 'heron, a cheat'}, {'pos': 'm_pn', 'defs': 'Baka'}], 'isLemma': 1},
	{'value': 'baddha', 'label': 'badDa', 'attrib': [{'pos': 'ppp', 'defs': 'bind'}], 'isLemma': 0, 'parents': ['banD']},
	{'value': 'baddhavat', 'label': 'badDavat', 'attrib': [{'pos': 'pap', 'defs': 'bind'}], 'isLemma': 0, 'parents': ['banD']},
	{'value': 'baddhvā', 'label': 'badDvA', 'attrib': [{'pos': 'abs', 'defs': 'bind'}], 'isLemma': 0, 'parents': ['banD']},
	{'value': 'badhnat', 'label': 'baDnat', 'attrib': [{'pos': 'prap', 'defs': 'bind'}], 'isLemma': 0, 'parents': ['banD']},
	{'value': 'badhyamāna', 'label': 'baDyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'bind'}], 'isLemma': 0, 'parents': ['banD']},
	{'value': 'banddhavya', 'label': 'bandDavya', 'attrib': [{'pos': 'ger', 'defs': 'bind'}], 'isLemma': 0, 'parents': ['banD']},
	{'value': 'banddhum', 'label': 'bandDum', 'attrib': [{'pos': 'inf', 'defs': 'bind'}], 'isLemma': 0, 'parents': ['banD']},
	{'value': 'bandh', 'label': 'banD', 'attrib': [{'pos': 'vt9am', 'defs': 'bind'}], 'isLemma': 1},
	{'value': 'bandhana', 'label': 'banDana', 'attrib': [{'pos': 'n', 'defs': 'binding, bond'}], 'isLemma': 1},
	{'value': 'bandhanīya', 'label': 'banDanIya', 'attrib': [{'pos': 'ger', 'defs': 'bind'}], 'isLemma': 0, 'parents': ['banD']},
	{'value': 'bandhya', 'label': 'banDya', 'attrib': [{'pos': 'ger', 'defs': 'bind'}], 'isLemma': 0, 'parents': ['banD']},
	{'value': 'babandhāna', 'label': 'babanDAna', 'attrib': [{'pos': 'pfpp', 'defs': 'bind'}], 'isLemma': 0, 'parents': ['banD']},
	{'value': 'babandhvas', 'label': 'babanDvas', 'attrib': [{'pos': 'pfap', 'defs': 'bind'}], 'isLemma': 0, 'parents': ['banD']},
	{'value': 'babhāna', 'label': 'baBAna', 'attrib': [{'pos': 'pfpp', 'defs': 'shine, appear'}], 'isLemma': 0, 'parents': ['BA']},
	{'value': 'babhāṣāṇa', 'label': 'baBAzARa', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'speak, say'}], 'isLemma': 0, 'parents': ['BAz']},
	{'value': 'babhivas', 'label': 'baBivas', 'attrib': [{'pos': 'pfap', 'defs': 'shine, appear'}], 'isLemma': 0, 'parents': ['BA']},
	{'value': 'babhūvas', 'label': 'baBUvas', 'attrib': [{'pos': 'pfap', 'defs': 'be, become'}], 'isLemma': 0, 'parents': ['BU']},
	{'value': 'babhṛvas', 'label': 'baBfvas', 'attrib': [{'pos': 'pfap', 'defs': 'bear'}], 'isLemma': 0, 'parents': ['Bf']},
	{'value': 'babhrāṇa', 'label': 'baBrARa', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'bear'}], 'isLemma': 0, 'parents': ['Bf']},
	{'value': 'bala', 'label': 'bala', 'attrib': [{'pos': 'n', 'defs': 'strength'}], 'isLemma': 1},
	{'value': 'bahu', 'label': 'bahu', 'attrib': [{'pos': 'adj', 'defs': 'much, many'}], 'isLemma': 1},
	{'value': 'bāṇa', 'label': 'bARa', 'attrib': [{'pos': 'm', 'defs': 'reed, arrow'}], 'isLemma': 1},
	{'value': 'bādh', 'label': 'bAD', 'attrib': [{'pos': 'vt1', 'defs': 'press, harass'}], 'isLemma': 1},
	{'value': 'bibhayām', 'label': 'biBayAm', 'attrib': [{'pos': 'ppf_actn', 'defs': 'fear'}], 'isLemma': 0, 'parents': ['BI']},
	{'value': 'bibharām', 'label': 'biBarAm', 'attrib': [{'pos': 'ppf_actn', 'defs': 'bear'}], 'isLemma': 0, 'parents': ['Bf']},
	{'value': 'bibhidāna', 'label': 'biBidAna', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'split'}], 'isLemma': 0, 'parents': ['Bid']},
	{'value': 'bibhidvas', 'label': 'biBidvas', 'attrib': [{'pos': 'pfap', 'defs': 'split'}], 'isLemma': 0, 'parents': ['Bid']},
	{'value': 'bibhīvas', 'label': 'biBIvas', 'attrib': [{'pos': 'pfap', 'defs': 'fear'}], 'isLemma': 0, 'parents': ['BI']},
	{'value': 'bibhyat', 'label': 'biByat', 'attrib': [{'pos': 'prap', 'defs': 'fear'}], 'isLemma': 0, 'parents': ['BI']},
	{'value': 'bibhyāna', 'label': 'biByAna', 'attrib': [{'pos': 'pfpp', 'defs': 'fear'}], 'isLemma': 0, 'parents': ['BI']},
	{'value': 'bibhrat', 'label': 'biBrat', 'attrib': [{'pos': 'prap', 'defs': 'bear'}], 'isLemma': 0, 'parents': ['Bf']},
	{'value': 'bibhrāṇa', 'label': 'biBrARa', 'attrib': [{'pos': 'prmp', 'defs': 'bear'}], 'isLemma': 0, 'parents': ['Bf']},
	{'value': 'bībhatsu', 'label': 'bIBatsu', 'attrib': [{'pos': 'adj', 'defs': 'despising, loathsome, reserved, coy'}], 'isLemma': 1},
	{'value': 'buddha', 'label': 'budDa', 'attrib': [{'pos': 'ppp', 'defs': 'be aware, know'}], 'isLemma': 0, 'parents': ['buD']},
	{'value': 'buddhavat', 'label': 'budDavat', 'attrib': [{'pos': 'pap', 'defs': 'be aware, know'}], 'isLemma': 0, 'parents': ['buD']},
	{'value': 'buddhi', 'label': 'budDi', 'attrib': [{'pos': 'f', 'defs': 'intelligence'}], 'isLemma': 1},
	{'value': 'buddhvā', 'label': 'budDvA', 'attrib': [{'pos': 'abs', 'defs': 'be aware, know'}], 'isLemma': 0, 'parents': ['buD']},
	{'value': 'budh', 'label': 'buD', 'attrib': [{'pos': 'vt1am', 'defs': 'know'}, {'pos': 'vt4m', 'defs': 'be aware, know'}], 'isLemma': 1},
	{'value': 'budhita', 'label': 'buDita', 'attrib': [{'pos': 'ppp', 'defs': 'know'}], 'isLemma': 0, 'parents': ['buD']},
	{'value': 'budhitavat', 'label': 'buDitavat', 'attrib': [{'pos': 'pap', 'defs': 'know'}], 'isLemma': 0, 'parents': ['buD']},
	{'value': 'budhitvā', 'label': 'buDitvA', 'attrib': [{'pos': 'abs', 'defs': 'know'}], 'isLemma': 0, 'parents': ['buD']},
	{'value': 'budhyamāna', 'label': 'buDyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'know'}, {'pos': 'prmp', 'defs': 'be aware, know'}, {'pos': 'prpp', 'defs': 'be aware, know'}], 'isLemma': 0, 'parents': ['buD']},
	{'value': 'bubudhāna', 'label': 'bubuDAna', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'know'}, {'pos': 'pfmp, pfpp', 'defs': 'be aware, know'}], 'isLemma': 0, 'parents': ['buD']},
	{'value': 'bubudhvas', 'label': 'bubuDvas', 'attrib': [{'pos': 'pfap', 'defs': 'know'}], 'isLemma': 0, 'parents': ['buD']},
	{'value': 'boddhavya', 'label': 'bodDavya', 'attrib': [{'pos': 'ger', 'defs': 'be aware, know'}], 'isLemma': 0, 'parents': ['buD']},
	{'value': 'boddhum', 'label': 'bodDum', 'attrib': [{'pos': 'inf', 'defs': 'be aware, know'}], 'isLemma': 0, 'parents': ['buD']},
	{'value': 'bodhat', 'label': 'boDat', 'attrib': [{'pos': 'prap', 'defs': 'know'}], 'isLemma': 0, 'parents': ['buD']},
	{'value': 'bodhanīya', 'label': 'boDanIya', 'attrib': [{'pos': 'ger', 'defs': 'know'}, {'pos': 'ger', 'defs': 'be aware, know'}], 'isLemma': 0, 'parents': ['buD']},
	{'value': 'bodhamāna', 'label': 'boDamAna', 'attrib': [{'pos': 'prmp', 'defs': 'know'}], 'isLemma': 0, 'parents': ['buD']},
	{'value': 'bodhitavya', 'label': 'boDitavya', 'attrib': [{'pos': 'ger', 'defs': 'know'}], 'isLemma': 0, 'parents': ['buD']},
	{'value': 'bodhitum', 'label': 'boDitum', 'attrib': [{'pos': 'inf', 'defs': 'know'}], 'isLemma': 0, 'parents': ['buD']},
	{'value': 'bodhitvā', 'label': 'boDitvA', 'attrib': [{'pos': 'abs', 'defs': 'know'}], 'isLemma': 0, 'parents': ['buD']},
	{'value': 'bodhya', 'label': 'boDya', 'attrib': [{'pos': 'ger', 'defs': 'know'}, {'pos': 'ger', 'defs': 'be aware, know'}], 'isLemma': 0, 'parents': ['buD']},
	{'value': 'brahman', 'label': 'brahman', 'attrib': [{'pos': 'm', 'defs': 'a brāhmaṇa'}], 'isLemma': 1},
	{'value': 'brahmarūpa', 'label': 'brahmarUpa', 'attrib': [{'pos': 'n', 'defs': 'appearance of a brahmin'}], 'isLemma': 1},
	{'value': 'brahmarūpadhara', 'label': 'brahmarUpaDara', 'attrib': [{'pos': 'adj', 'defs': 'bearing the appearance of a brahmin'}], 'isLemma': 1},
	{'value': 'brāhmaṇa', 'label': 'brAhmaRa', 'attrib': [{'pos': 'm', 'defs': 'a brahmin'}], 'isLemma': 1},
	{'value': 'brāhmaṇārtha', 'label': 'brAhmaRArTa', 'attrib': [{'pos': 'm', 'defs': 'the purpose of a brāhmaṇa'}], 'isLemma': 1},
	{'value': 'bhakta', 'label': 'Bakta', 'attrib': [{'pos': 'ppp', 'defs': 'partake of, share, love, serve'}], 'isLemma': 0, 'parents': ['Baj']},
	{'value': 'bhaktavat', 'label': 'Baktavat', 'attrib': [{'pos': 'pap', 'defs': 'partake of, share, love, serve'}], 'isLemma': 0, 'parents': ['Baj']},
	{'value': 'bhaktavya', 'label': 'Baktavya', 'attrib': [{'pos': 'ger', 'defs': 'partake of, share, love, serve'}], 'isLemma': 0, 'parents': ['Baj']},
	{'value': 'bhaktum', 'label': 'Baktum', 'attrib': [{'pos': 'inf', 'defs': 'partake of, share, love, serve'}], 'isLemma': 0, 'parents': ['Baj']},
	{'value': 'bhakṣyat', 'label': 'Bakzyat', 'attrib': [{'pos': 'fap', 'defs': 'partake of, share, love, serve'}], 'isLemma': 0, 'parents': ['Baj']},
	{'value': 'bhakṣyamāna', 'label': 'BakzyamAna', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'partake of, share, love, serve'}], 'isLemma': 0, 'parents': ['Baj']},
	{'value': 'bhaj', 'label': 'Baj', 'attrib': [{'pos': 'vt1am', 'defs': 'partake of, share, love, serve'}], 'isLemma': 1},
	{'value': 'bhajat', 'label': 'Bajat', 'attrib': [{'pos': 'prap', 'defs': 'partake of, share, love, serve'}], 'isLemma': 0, 'parents': ['Baj']},
	{'value': 'bhajanīya', 'label': 'BajanIya', 'attrib': [{'pos': 'ger', 'defs': 'partake of, share, love, serve'}], 'isLemma': 0, 'parents': ['Baj']},
	{'value': 'bhajamāna', 'label': 'BajamAna', 'attrib': [{'pos': 'prmp', 'defs': 'partake of, share, love, serve'}], 'isLemma': 0, 'parents': ['Baj']},
	{'value': 'bhajya', 'label': 'Bajya', 'attrib': [{'pos': 'ger', 'defs': 'partake of, share, love, serve'}], 'isLemma': 0, 'parents': ['Baj']},
	{'value': 'bhajyamāna', 'label': 'BajyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'partake of, share, love, serve'}], 'isLemma': 0, 'parents': ['Baj']},
	{'value': 'bhadra', 'label': 'Badra', 'attrib': [{'pos': 'adj', 'defs': 'blessed, good'}, {'pos': 'n', 'defs': 'welfare'}], 'isLemma': 1},
	{'value': 'bhadrabhāṣin', 'label': 'BadraBAzin', 'attrib': [{'pos': 'adj', 'defs': 'speaking what is auspicious'}], 'isLemma': 1},
	{'value': 'bhantsyat', 'label': 'Bantsyat', 'attrib': [{'pos': 'fap', 'defs': 'bind'}], 'isLemma': 0, 'parents': ['banD']},
	{'value': 'bhantsyamāna', 'label': 'BantsyamAna', 'attrib': [{'pos': 'fpp', 'defs': 'bind'}], 'isLemma': 0, 'parents': ['banD']},
	{'value': 'bhaya', 'label': 'Baya', 'attrib': [{'pos': 'n', 'defs': 'fear'}], 'isLemma': 1},
	{'value': 'bhayanīya', 'label': 'BayanIya', 'attrib': [{'pos': 'ger', 'defs': 'fear'}], 'isLemma': 0, 'parents': ['BI']},
	{'value': 'bhara', 'label': 'Bara', 'attrib': [{'pos': 'adj', 'defs': 'bearing'}], 'isLemma': 1},
	{'value': 'bharaṇīya', 'label': 'BaraRIya', 'attrib': [{'pos': 'ger', 'defs': 'bear'}], 'isLemma': 0, 'parents': ['Bf']},
	{'value': 'bharata', 'label': 'Barata', 'attrib': [{'pos': 'm_pn', 'defs': 'Bharata'}], 'isLemma': 1},
	{'value': 'bharatarṣabha', 'label': 'BaratarzaBa', 'attrib': [{'pos': 'm', 'defs': 'bull-like descendant of Bharata'}], 'isLemma': 1},
	{'value': 'bhariṣyat', 'label': 'Barizyat', 'attrib': [{'pos': 'fap', 'defs': 'bear'}], 'isLemma': 0, 'parents': ['Bf']},
	{'value': 'bhariṣyamāṇa', 'label': 'BarizyamARa', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'bear'}], 'isLemma': 0, 'parents': ['Bf']},
	{'value': 'bhartavya', 'label': 'Bartavya', 'attrib': [{'pos': 'ger', 'defs': 'bear'}], 'isLemma': 0, 'parents': ['Bf']},
	{'value': 'bhartum', 'label': 'Bartum', 'attrib': [{'pos': 'inf', 'defs': 'bear'}], 'isLemma': 0, 'parents': ['Bf']},
	{'value': 'bhavat', 'label': 'Bavat', 'attrib': [{'pos': 'prap', 'defs': 'be, become'}], 'isLemma': 0, 'parents': ['BU']},
	{'value': 'bhavanīya', 'label': 'BavanIya', 'attrib': [{'pos': 'ger', 'defs': 'be, become'}], 'isLemma': 0, 'parents': ['BU']},
	{'value': 'bhavitavya', 'label': 'Bavitavya', 'attrib': [{'pos': 'ger', 'defs': 'be, become'}], 'isLemma': 0, 'parents': ['BU']},
	{'value': 'bhavitum', 'label': 'Bavitum', 'attrib': [{'pos': 'inf', 'defs': 'be, become'}], 'isLemma': 0, 'parents': ['BU']},
	{'value': 'bhaviṣyat', 'label': 'Bavizyat', 'attrib': [{'pos': 'fap', 'defs': 'be, become'}], 'isLemma': 0, 'parents': ['BU']},
	{'value': 'bhaviṣyamāṇa', 'label': 'BavizyamARa', 'attrib': [{'pos': 'fpp', 'defs': 'be, become'}], 'isLemma': 0, 'parents': ['BU']},
	{'value': 'bhavya', 'label': 'Bavya', 'attrib': [{'pos': 'ger', 'defs': 'be, become'}], 'isLemma': 0, 'parents': ['BU']},
	{'value': 'bhā', 'label': 'BA', 'attrib': [{'pos': 'vi2a', 'defs': 'shine, appear'}], 'isLemma': 1},
	{'value': 'bhāktvā', 'label': 'BAktvA', 'attrib': [{'pos': 'abs', 'defs': 'partake of, share, love, serve'}], 'isLemma': 0, 'parents': ['Baj']},
	{'value': 'bhāt', 'label': 'BAt', 'attrib': [{'pos': 'prap', 'defs': 'shine, appear'}], 'isLemma': 0, 'parents': ['BA']},
	{'value': 'bhāta', 'label': 'BAta', 'attrib': [{'pos': 'ppp', 'defs': 'shine, appear'}], 'isLemma': 0, 'parents': ['BA']},
	{'value': 'bhātavat', 'label': 'BAtavat', 'attrib': [{'pos': 'pap', 'defs': 'shine, appear'}], 'isLemma': 0, 'parents': ['BA']},
	{'value': 'bhātavya', 'label': 'BAtavya', 'attrib': [{'pos': 'ger', 'defs': 'shine, appear'}], 'isLemma': 0, 'parents': ['BA']},
	{'value': 'bhātum', 'label': 'BAtum', 'attrib': [{'pos': 'inf', 'defs': 'shine, appear'}], 'isLemma': 0, 'parents': ['BA']},
	{'value': 'bhānīya', 'label': 'BAnIya', 'attrib': [{'pos': 'ger', 'defs': 'shine, appear'}], 'isLemma': 0, 'parents': ['BA']},
	{'value': 'bhāyamāna', 'label': 'BAyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'shine, appear'}], 'isLemma': 0, 'parents': ['BA']},
	{'value': 'bhāra', 'label': 'BAra', 'attrib': [{'pos': 'm', 'defs': 'burden'}], 'isLemma': 1},
	{'value': 'bhārata', 'label': 'BArata', 'attrib': [{'pos': 'm', 'defs': 'descendant of Bharata'}, {'pos': 'adj', 'defs': 'the war between the descendants of Bharata'}], 'isLemma': 1},
	{'value': 'bhāryā', 'label': 'BAryA', 'attrib': [{'pos': 'f', 'defs': 'wife'}], 'isLemma': 1},
	{'value': 'bhāvin', 'label': 'BAvin', 'attrib': [{'pos': 'adj', 'defs': 'future'}], 'isLemma': 1},
	{'value': 'bhāvyartha', 'label': 'BAvyarTa', 'attrib': [{'pos': 'm', 'defs': 'future purpose'}], 'isLemma': 1},
	{'value': 'bhāvyartharakṣita', 'label': 'BAvyarTarakzita', 'attrib': [{'pos': 'adj', 'defs': 'protected for future purposes'}], 'isLemma': 1},
	{'value': 'bhāṣ', 'label': 'BAz', 'attrib': [{'pos': 'vt1m', 'defs': 'speak, say'}], 'isLemma': 1},
	{'value': 'bhāṣaṇīya', 'label': 'BAzaRIya', 'attrib': [{'pos': 'ger', 'defs': 'speak, say'}], 'isLemma': 0, 'parents': ['BAz']},
	{'value': 'bhāṣamāṇa', 'label': 'BAzamARa', 'attrib': [{'pos': 'prmp', 'defs': 'speak, say'}], 'isLemma': 0, 'parents': ['BAz']},
	{'value': 'bhāṣita', 'label': 'BAzita', 'attrib': [{'pos': 'ppp', 'defs': 'speak, say'}], 'isLemma': 0, 'parents': ['BAz']},
	{'value': 'bhāṣitavat', 'label': 'BAzitavat', 'attrib': [{'pos': 'pap', 'defs': 'speak, say'}], 'isLemma': 0, 'parents': ['BAz']},
	{'value': 'bhāṣitavya', 'label': 'BAzitavya', 'attrib': [{'pos': 'ger', 'defs': 'speak, say'}], 'isLemma': 0, 'parents': ['BAz']},
	{'value': 'bhāṣitum', 'label': 'BAzitum', 'attrib': [{'pos': 'inf', 'defs': 'speak, say'}], 'isLemma': 0, 'parents': ['BAz']},
	{'value': 'bhāṣitvā', 'label': 'BAzitvA', 'attrib': [{'pos': 'abs', 'defs': 'speak, say'}], 'isLemma': 0, 'parents': ['BAz']},
	{'value': 'bhāṣin', 'label': 'BAzin', 'attrib': [{'pos': 'adj', 'defs': 'speaking'}], 'isLemma': 1},
	{'value': 'bhāṣiṣyamāṇa', 'label': 'BAzizyamARa', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'speak, say'}], 'isLemma': 0, 'parents': ['BAz']},
	{'value': 'bhāṣya', 'label': 'BAzya', 'attrib': [{'pos': 'ger', 'defs': 'speak, say'}], 'isLemma': 0, 'parents': ['BAz']},
	{'value': 'bhāṣyamāṇa', 'label': 'BAzyamARa', 'attrib': [{'pos': 'prpp', 'defs': 'speak, say'}], 'isLemma': 0, 'parents': ['BAz']},
	{'value': 'bhāsyat', 'label': 'BAsyat', 'attrib': [{'pos': 'fap', 'defs': 'shine, appear'}], 'isLemma': 0, 'parents': ['BA']},
	{'value': 'bhāsyamāna', 'label': 'BAsyamAna', 'attrib': [{'pos': 'fpp', 'defs': 'shine, appear'}], 'isLemma': 0, 'parents': ['BA']},
	{'value': 'bhittvā', 'label': 'BittvA', 'attrib': [{'pos': 'abs', 'defs': 'split'}], 'isLemma': 0, 'parents': ['Bid']},
	{'value': 'bhid', 'label': 'Bid', 'attrib': [{'pos': 'vt7am', 'defs': 'split'}], 'isLemma': 1},
	{'value': 'bhidyamāna', 'label': 'BidyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'split'}], 'isLemma': 0, 'parents': ['Bid']},
	{'value': 'bhindat', 'label': 'Bindat', 'attrib': [{'pos': 'prap', 'defs': 'split'}], 'isLemma': 0, 'parents': ['Bid']},
	{'value': 'bhindāna', 'label': 'BindAna', 'attrib': [{'pos': 'prmp', 'defs': 'split'}], 'isLemma': 0, 'parents': ['Bid']},
	{'value': 'bhinna', 'label': 'Binna', 'attrib': [{'pos': 'ppp', 'defs': 'split'}], 'isLemma': 0, 'parents': ['Bid']},
	{'value': 'bhinnavat', 'label': 'Binnavat', 'attrib': [{'pos': 'pap', 'defs': 'split'}], 'isLemma': 0, 'parents': ['Bid']},
	{'value': 'bhī', 'label': 'BI', 'attrib': [{'pos': 'vi3a', 'defs': 'fear'}], 'isLemma': 1},
	{'value': 'bhīta', 'label': 'BIta', 'attrib': [{'pos': 'ppp', 'defs': 'fear'}], 'isLemma': 0, 'parents': ['BI']},
	{'value': 'bhītavat', 'label': 'BItavat', 'attrib': [{'pos': 'pap', 'defs': 'fear'}], 'isLemma': 0, 'parents': ['BI']},
	{'value': 'bhītvā', 'label': 'BItvA', 'attrib': [{'pos': 'abs', 'defs': 'fear'}], 'isLemma': 0, 'parents': ['BI']},
	{'value': 'bhīma', 'label': 'BIma', 'attrib': [{'pos': 'adj', 'defs': 'frightful, terrifying'}, {'pos': 'm_pn', 'defs': 'Bhīma'}], 'isLemma': 1},
	{'value': 'bhīmaparākrama', 'label': 'BImaparAkrama', 'attrib': [{'pos': 'adj', 'defs': 'terrifyingly bold'}], 'isLemma': 1},
	{'value': 'bhīmasena', 'label': 'BImasena', 'attrib': [{'pos': 'adj', 'defs': 'having a terrifying army'}], 'isLemma': 1},
	{'value': 'bhīyamāna', 'label': 'BIyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'fear'}], 'isLemma': 0, 'parents': ['BI']},
	{'value': 'bhīṣma', 'label': 'BIzma', 'attrib': [{'pos': 'adj', 'defs': 'terrifying'}, {'pos': 'm_pn', 'defs': 'Bhīṣma'}], 'isLemma': 1},
	{'value': 'bhū', 'label': 'BU', 'attrib': [{'pos': 'vi1a', 'defs': 'be, become'}, {'pos': 'f', 'defs': 'earth'}], 'isLemma': 1},
	{'value': 'bhūta', 'label': 'BUta', 'attrib': [{'pos': 'ppp', 'defs': 'be, become'}], 'isLemma': 0, 'parents': ['BU']},
	{'value': 'bhūtavat', 'label': 'BUtavat', 'attrib': [{'pos': 'pap', 'defs': 'be, become'}], 'isLemma': 0, 'parents': ['BU']},
	{'value': 'bhūtvā', 'label': 'BUtvA', 'attrib': [{'pos': 'abs', 'defs': 'be, become'}], 'isLemma': 0, 'parents': ['BU']},
	{'value': 'bhūyamāna', 'label': 'BUyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'be, become'}], 'isLemma': 0, 'parents': ['BU']},
	{'value': 'bhūyas', 'label': 'BUyas', 'attrib': [{'pos': 'comparative_adj', 'defs': 'more'}], 'isLemma': 1},
	{'value': 'bhūyiṣṭha', 'label': 'BUyizWa', 'attrib': [{'pos': 'superlative_adj', 'defs': 'most'}], 'isLemma': 1},
	{'value': 'bhṛ', 'label': 'Bf', 'attrib': [{'pos': 'vt3am', 'defs': 'bear'}], 'isLemma': 1},
	{'value': 'bhṛta', 'label': 'Bfta', 'attrib': [{'pos': 'ppp', 'defs': 'bear'}], 'isLemma': 0, 'parents': ['Bf']},
	{'value': 'bhṛtavat', 'label': 'Bftavat', 'attrib': [{'pos': 'pap', 'defs': 'bear'}], 'isLemma': 0, 'parents': ['Bf']},
	{'value': 'bhṛtya', 'label': 'Bftya', 'attrib': [{'pos': 'ger', 'defs': 'bear'}], 'isLemma': 0, 'parents': ['Bf']},
	{'value': 'bhṛtvā', 'label': 'BftvA', 'attrib': [{'pos': 'abs', 'defs': 'bear'}], 'isLemma': 0, 'parents': ['Bf']},
	{'value': 'bhejāna', 'label': 'BejAna', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'partake of, share, love, serve'}], 'isLemma': 0, 'parents': ['Baj']},
	{'value': 'bhejivas', 'label': 'Bejivas', 'attrib': [{'pos': 'pfap', 'defs': 'partake of, share, love, serve'}], 'isLemma': 0, 'parents': ['Baj']},
	{'value': 'bhetavya', 'label': 'Betavya', 'attrib': [{'pos': 'ger', 'defs': 'fear'}], 'isLemma': 0, 'parents': ['BI']},
	{'value': 'bhetum', 'label': 'Betum', 'attrib': [{'pos': 'inf', 'defs': 'fear'}], 'isLemma': 0, 'parents': ['BI']},
	{'value': 'bhettavya', 'label': 'Bettavya', 'attrib': [{'pos': 'ger', 'defs': 'split'}], 'isLemma': 0, 'parents': ['Bid']},
	{'value': 'bhettum', 'label': 'Bettum', 'attrib': [{'pos': 'inf', 'defs': 'split'}], 'isLemma': 0, 'parents': ['Bid']},
	{'value': 'bhetsyat', 'label': 'Betsyat', 'attrib': [{'pos': 'fap', 'defs': 'split'}], 'isLemma': 0, 'parents': ['Bid']},
	{'value': 'bhetsyamāna', 'label': 'BetsyamAna', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'split'}], 'isLemma': 0, 'parents': ['Bid']},
	{'value': 'bheda', 'label': 'Beda', 'attrib': [{'pos': 'm', 'defs': 'split, division, breach'}], 'isLemma': 1},
	{'value': 'bhedanīya', 'label': 'BedanIya', 'attrib': [{'pos': 'ger', 'defs': 'split'}], 'isLemma': 0, 'parents': ['Bid']},
	{'value': 'bhedya', 'label': 'Bedya', 'attrib': [{'pos': 'ger', 'defs': 'split'}], 'isLemma': 0, 'parents': ['Bid']},
	{'value': 'bheya', 'label': 'Beya', 'attrib': [{'pos': 'ger', 'defs': 'fear'}], 'isLemma': 0, 'parents': ['BI']},
	{'value': 'bheṣyat', 'label': 'Bezyat', 'attrib': [{'pos': 'fap', 'defs': 'fear'}], 'isLemma': 0, 'parents': ['BI']},
	{'value': 'bheṣyamāṇa', 'label': 'BezyamARa', 'attrib': [{'pos': 'fpp', 'defs': 'fear'}], 'isLemma': 0, 'parents': ['BI']},
	{'value': 'bhotsyat', 'label': 'Botsyat', 'attrib': [{'pos': 'fap', 'defs': 'know'}], 'isLemma': 0, 'parents': ['buD']},
	{'value': 'bhotsyamāna', 'label': 'BotsyamAna', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'know'}, {'pos': 'fmp, fpp', 'defs': 'be aware, know'}], 'isLemma': 0, 'parents': ['buD']},
	{'value': 'bhrātṛ', 'label': 'BrAtf', 'attrib': [{'pos': 'm', 'defs': 'brother'}], 'isLemma': 1},
	{'value': 'bhriyamāṇa', 'label': 'BriyamARa', 'attrib': [{'pos': 'prpp', 'defs': 'bear'}], 'isLemma': 0, 'parents': ['Bf']},
	{'value': 'maṁsyamāna', 'label': 'maMsyamAna', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'think'}], 'isLemma': 0, 'parents': ['man']},
	{'value': 'mata', 'label': 'mata', 'attrib': [{'pos': 'n', 'defs': 'thought'}, {'pos': 'ppp', 'defs': 'think'}], 'isLemma': 1},
	{'value': 'mata', 'label': 'mata', 'attrib': [{'pos': 'n', 'defs': 'thought'}, {'pos': 'ppp', 'defs': 'think'}], 'isLemma': 0, 'parents': ['man']},
	{'value': 'matavat', 'label': 'matavat', 'attrib': [{'pos': 'pap', 'defs': 'think'}], 'isLemma': 0, 'parents': ['man']},
	{'value': 'mati', 'label': 'mati', 'attrib': [{'pos': 'f', 'defs': 'thought'}], 'isLemma': 1},
	{'value': 'matta', 'label': 'matta', 'attrib': [{'pos': 'ppp', 'defs': 'rejoice'}], 'isLemma': 0, 'parents': ['mad']},
	{'value': 'mattavat', 'label': 'mattavat', 'attrib': [{'pos': 'pap', 'defs': 'rejoice'}], 'isLemma': 0, 'parents': ['mad']},
	{'value': 'matvā', 'label': 'matvA', 'attrib': [{'pos': 'abs', 'defs': 'think'}], 'isLemma': 0, 'parents': ['man']},
	{'value': 'mad', 'label': 'mad', 'attrib': [{'pos': 'pers_pron', 'defs': 'I, me'}, {'pos': 'vi4a', 'defs': 'rejoice'}], 'isLemma': 1},
	{'value': 'madanīya', 'label': 'madanIya', 'attrib': [{'pos': 'ger', 'defs': 'rejoice'}], 'isLemma': 0, 'parents': ['mad']},
	{'value': 'maditavya', 'label': 'maditavya', 'attrib': [{'pos': 'ger', 'defs': 'rejoice'}], 'isLemma': 0, 'parents': ['mad']},
	{'value': 'maditum', 'label': 'maditum', 'attrib': [{'pos': 'inf', 'defs': 'rejoice'}], 'isLemma': 0, 'parents': ['mad']},
	{'value': 'maditvā', 'label': 'maditvA', 'attrib': [{'pos': 'abs', 'defs': 'rejoice'}], 'isLemma': 0, 'parents': ['mad']},
	{'value': 'madiṣyat', 'label': 'madizyat', 'attrib': [{'pos': 'fap', 'defs': 'rejoice'}], 'isLemma': 0, 'parents': ['mad']},
	{'value': 'madiṣyamāṇa', 'label': 'madizyamARa', 'attrib': [{'pos': 'fpp', 'defs': 'rejoice'}], 'isLemma': 0, 'parents': ['mad']},
	{'value': 'madya', 'label': 'madya', 'attrib': [{'pos': 'ger', 'defs': 'rejoice'}], 'isLemma': 0, 'parents': ['mad']},
	{'value': 'madyamāna', 'label': 'madyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'rejoice'}], 'isLemma': 0, 'parents': ['mad']},
	{'value': 'man', 'label': 'man', 'attrib': [{'pos': 'vt4m, vt8m', 'defs': 'think'}], 'isLemma': 1},
	{'value': 'mananīya', 'label': 'mananIya', 'attrib': [{'pos': 'ger', 'defs': 'think'}], 'isLemma': 0, 'parents': ['man']},
	{'value': 'manas', 'label': 'manas', 'attrib': [{'pos': 'n', 'defs': 'mind'}], 'isLemma': 1},
	{'value': 'manīya', 'label': 'manIya', 'attrib': [{'pos': 'ger', 'defs': 'measure, make'}], 'isLemma': 0, 'parents': ['mA']},
	{'value': 'manobuddhisamādhi', 'label': 'manobudDisamADi', 'attrib': [{'pos': 'm', 'defs': 'mind, intellect and settled awareness'}], 'isLemma': 1},
	{'value': 'mantavya', 'label': 'mantavya', 'attrib': [{'pos': 'ger', 'defs': 'think'}], 'isLemma': 0, 'parents': ['man']},
	{'value': 'mantum', 'label': 'mantum', 'attrib': [{'pos': 'inf', 'defs': 'think'}], 'isLemma': 0, 'parents': ['man']},
	{'value': 'mantr', 'label': 'mantr', 'attrib': [{'pos': 'v10m', 'defs': 'consult'}], 'isLemma': 1},
	{'value': 'mantra', 'label': 'mantra', 'attrib': [{'pos': 'm', 'defs': 'instrument of thought, sacred text, counsel'}], 'isLemma': 1},
	{'value': 'mantraṇīya', 'label': 'mantraRIya', 'attrib': [{'pos': 'ger', 'defs': 'consult'}], 'isLemma': 0, 'parents': ['mantr']},
	{'value': 'mantrayamāna', 'label': 'mantrayamAna', 'attrib': [{'pos': 'prmp', 'defs': 'consult'}], 'isLemma': 0, 'parents': ['mantr']},
	{'value': 'mantrayām', 'label': 'mantrayAm', 'attrib': [{'pos': 'ppf_actn', 'defs': 'consult'}], 'isLemma': 0, 'parents': ['mantr']},
	{'value': 'mantrayitavya', 'label': 'mantrayitavya', 'attrib': [{'pos': 'ger', 'defs': 'consult'}], 'isLemma': 0, 'parents': ['mantr']},
	{'value': 'mantrayitum', 'label': 'mantrayitum', 'attrib': [{'pos': 'inf', 'defs': 'consult'}], 'isLemma': 0, 'parents': ['mantr']},
	{'value': 'mantrayitvā', 'label': 'mantrayitvA', 'attrib': [{'pos': 'abs', 'defs': 'consult'}], 'isLemma': 0, 'parents': ['mantr']},
	{'value': 'mantrayiṣyamāṇa', 'label': 'mantrayizyamARa', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'consult'}], 'isLemma': 0, 'parents': ['mantr']},
	{'value': 'mantrita', 'label': 'mantrita', 'attrib': [{'pos': 'ppp', 'defs': 'consult'}], 'isLemma': 0, 'parents': ['mantr']},
	{'value': 'mantritavat', 'label': 'mantritavat', 'attrib': [{'pos': 'pap', 'defs': 'consult'}], 'isLemma': 0, 'parents': ['mantr']},
	{'value': 'mantrya', 'label': 'mantrya', 'attrib': [{'pos': 'ger', 'defs': 'consult'}], 'isLemma': 0, 'parents': ['mantr']},
	{'value': 'mantryamāna', 'label': 'mantryamAna', 'attrib': [{'pos': 'prpp', 'defs': 'consult'}], 'isLemma': 0, 'parents': ['mantr']},
	{'value': 'manda', 'label': 'manda', 'attrib': [{'pos': 'adj', 'defs': 'slow, stupid'}], 'isLemma': 1},
	{'value': 'mandira', 'label': 'mandira', 'attrib': [{'pos': 'n', 'defs': 'house, palace, temple'}], 'isLemma': 1},
	{'value': 'manyamāna', 'label': 'manyamAna', 'attrib': [{'pos': 'prmp', 'defs': 'think'}, {'pos': 'prpp', 'defs': 'think'}], 'isLemma': 0, 'parents': ['man']},
	{'value': 'manyu', 'label': 'manyu', 'attrib': [{'pos': 'm', 'defs': 'spirit, fury, anger'}], 'isLemma': 1},
	{'value': 'manvāna', 'label': 'manvAna', 'attrib': [{'pos': 'prmp', 'defs': 'think'}], 'isLemma': 0, 'parents': ['man']},
	{'value': 'mamāṇa', 'label': 'mamARa', 'attrib': [{'pos': 'pfpp', 'defs': 'measure, make'}], 'isLemma': 0, 'parents': ['mA']},
	{'value': 'mamṛvas', 'label': 'mamfvas', 'attrib': [{'pos': 'pfap', 'defs': 'die'}], 'isLemma': 0, 'parents': ['mf']},
	{'value': 'mamṛṣāna', 'label': 'mamfzAna', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'forget, tolerate'}], 'isLemma': 0, 'parents': ['mfz']},
	{'value': 'mamṛṣivas', 'label': 'mamfzivas', 'attrib': [{'pos': 'pfap', 'defs': 'forget, tolerate'}], 'isLemma': 0, 'parents': ['mfz']},
	{'value': 'mamrāṇa', 'label': 'mamrARa', 'attrib': [{'pos': 'pfpp', 'defs': 'die'}], 'isLemma': 0, 'parents': ['mf']},
	{'value': 'maya', 'label': 'maya', 'attrib': [{'pos': 'm_pn', 'defs': 'Maya'}], 'isLemma': 1},
	{'value': 'maraṇīya', 'label': 'maraRIya', 'attrib': [{'pos': 'ger', 'defs': 'die'}], 'isLemma': 0, 'parents': ['mf']},
	{'value': 'mariṣyat', 'label': 'marizyat', 'attrib': [{'pos': 'fap', 'defs': 'die'}], 'isLemma': 0, 'parents': ['mf']},
	{'value': 'mariṣyamāṇa', 'label': 'marizyamARa', 'attrib': [{'pos': 'fpp', 'defs': 'die'}], 'isLemma': 0, 'parents': ['mf']},
	{'value': 'martavya', 'label': 'martavya', 'attrib': [{'pos': 'ger', 'defs': 'die'}], 'isLemma': 0, 'parents': ['mf']},
	{'value': 'martum', 'label': 'martum', 'attrib': [{'pos': 'inf', 'defs': 'die'}], 'isLemma': 0, 'parents': ['mf']},
	{'value': 'marṣaṇīya', 'label': 'marzaRIya', 'attrib': [{'pos': 'ger', 'defs': 'forget, tolerate'}], 'isLemma': 0, 'parents': ['mfz']},
	{'value': 'marṣita', 'label': 'marzita', 'attrib': [{'pos': 'ppp', 'defs': 'forget, tolerate'}], 'isLemma': 0, 'parents': ['mfz']},
	{'value': 'marṣitavat', 'label': 'marzitavat', 'attrib': [{'pos': 'pap', 'defs': 'forget, tolerate'}], 'isLemma': 0, 'parents': ['mfz']},
	{'value': 'marṣitavya', 'label': 'marzitavya', 'attrib': [{'pos': 'ger', 'defs': 'forget, tolerate'}], 'isLemma': 0, 'parents': ['mfz']},
	{'value': 'marṣitum', 'label': 'marzitum', 'attrib': [{'pos': 'inf', 'defs': 'forget, tolerate'}], 'isLemma': 0, 'parents': ['mfz']},
	{'value': 'marṣitvā', 'label': 'marzitvA', 'attrib': [{'pos': 'abs', 'defs': 'forget, tolerate'}], 'isLemma': 0, 'parents': ['mfz']},
	{'value': 'marṣiṣyat', 'label': 'marzizyat', 'attrib': [{'pos': 'fap', 'defs': 'forget, tolerate'}], 'isLemma': 0, 'parents': ['mfz']},
	{'value': 'marṣiṣyamāṇa', 'label': 'marzizyamARa', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'forget, tolerate'}], 'isLemma': 0, 'parents': ['mfz']},
	{'value': 'mah', 'label': 'mah', 'attrib': [{'pos': 'vi1m', 'defs': 'increase'}, {'pos': 'vt10a', 'defs': 'magnify, exalt'}], 'isLemma': 1},
	{'value': 'mahat', 'label': 'mahat', 'attrib': [{'pos': 'adj', 'defs': 'great'}], 'isLemma': 1},
	{'value': 'maharṣi', 'label': 'maharzi', 'attrib': [{'pos': 'm', 'defs': 'great sage'}], 'isLemma': 1},
	{'value': 'mahāpatha', 'label': 'mahApaTa', 'attrib': [{'pos': 'm', 'defs': 'great path, road'}], 'isLemma': 1},
	{'value': 'mahābala', 'label': 'mahAbala', 'attrib': [{'pos': 'adj', 'defs': 'very strong'}], 'isLemma': 1},
	{'value': 'mahābhārata', 'label': 'mahABArata', 'attrib': [{'pos': 'n', 'defs': 'the great war between the descendants of Bharata'}], 'isLemma': 1},
	{'value': 'mahāmati', 'label': 'mahAmati', 'attrib': [{'pos': 'adj', 'defs': 'great-minded'}], 'isLemma': 1},
	{'value': 'mahārāja', 'label': 'mahArAja', 'attrib': [{'pos': 'm', 'defs': 'great king'}], 'isLemma': 1},
	{'value': 'mahāvana', 'label': 'mahAvana', 'attrib': [{'pos': 'n', 'defs': 'great forest'}], 'isLemma': 1},
	{'value': 'mahāsura', 'label': 'mahAsura', 'attrib': [{'pos': 'm', 'defs': 'great demon'}], 'isLemma': 1},
	{'value': 'mahī', 'label': 'mahI', 'attrib': [{'pos': 'f', 'defs': 'earth'}], 'isLemma': 1},
	{'value': 'mahīkṣit', 'label': 'mahIkzit', 'attrib': [{'pos': 'm', 'defs': 'ruling the earth, king'}], 'isLemma': 1},
	{'value': 'mahendra', 'label': 'mahendra', 'attrib': [{'pos': 'm', 'defs': 'the great Indra'}], 'isLemma': 1},
	{'value': 'mā', 'label': 'mA', 'attrib': [{'pos': 'vt2a', 'defs': 'measure, make'}, {'pos': 'vt4m', 'defs': 'measure, make'}, {'pos': 'vt3m', 'defs': 'measure, make'}], 'isLemma': 1},
	{'value': 'mātavya', 'label': 'mAtavya', 'attrib': [{'pos': 'ger', 'defs': 'measure, make'}], 'isLemma': 0, 'parents': ['mA']},
	{'value': 'mātum', 'label': 'mAtum', 'attrib': [{'pos': 'inf', 'defs': 'measure, make'}], 'isLemma': 0, 'parents': ['mA']},
	{'value': 'mātṛ', 'label': 'mAtf', 'attrib': [{'pos': 'f', 'defs': 'mother'}], 'isLemma': 1},
	{'value': 'mādyat', 'label': 'mAdyat', 'attrib': [{'pos': 'prap', 'defs': 'rejoice'}], 'isLemma': 0, 'parents': ['mad']},
	{'value': 'mānya', 'label': 'mAnya', 'attrib': [{'pos': 'ger', 'defs': 'think'}], 'isLemma': 0, 'parents': ['man']},
	{'value': 'mārya', 'label': 'mArya', 'attrib': [{'pos': 'ger', 'defs': 'die'}], 'isLemma': 0, 'parents': ['mf']},
	{'value': 'mās', 'label': 'mAs', 'attrib': [{'pos': 'm', 'defs': 'the moon, a month'}], 'isLemma': 1},
	{'value': 'māsa', 'label': 'mAsa', 'attrib': [{'pos': 'm', 'defs': 'the moon, a month'}], 'isLemma': 1},
	{'value': 'māsyamāna', 'label': 'mAsyamAna', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'measure, make'}], 'isLemma': 0, 'parents': ['mA']},
	{'value': 'mita', 'label': 'mita', 'attrib': [{'pos': 'ppp', 'defs': 'measure, make'}], 'isLemma': 0, 'parents': ['mA']},
	{'value': 'mitavat', 'label': 'mitavat', 'attrib': [{'pos': 'pap', 'defs': 'measure, make'}], 'isLemma': 0, 'parents': ['mA']},
	{'value': 'mitvā', 'label': 'mitvA', 'attrib': [{'pos': 'abs', 'defs': 'measure, make'}], 'isLemma': 0, 'parents': ['mA']},
	{'value': 'mimāna', 'label': 'mimAna', 'attrib': [{'pos': 'prmp', 'defs': 'measure, make'}], 'isLemma': 0, 'parents': ['mA']},
	{'value': 'mīyamāṇa', 'label': 'mIyamARa', 'attrib': [{'pos': 'prpp', 'defs': 'measure, make'}], 'isLemma': 0, 'parents': ['mA']},
	{'value': 'mukta', 'label': 'mukta', 'attrib': [{'pos': 'ppp', 'defs': 'release'}], 'isLemma': 0, 'parents': ['muc']},
	{'value': 'muktavat', 'label': 'muktavat', 'attrib': [{'pos': 'pap', 'defs': 'release'}], 'isLemma': 0, 'parents': ['muc']},
	{'value': 'muktvā', 'label': 'muktvA', 'attrib': [{'pos': 'abs', 'defs': 'release'}], 'isLemma': 0, 'parents': ['muc']},
	{'value': 'muc', 'label': 'muc', 'attrib': [{'pos': 'vt6am', 'defs': 'release'}], 'isLemma': 1},
	{'value': 'mucyamāna', 'label': 'mucyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'release'}], 'isLemma': 0, 'parents': ['muc']},
	{'value': 'muñcat', 'label': 'muYcat', 'attrib': [{'pos': 'prap', 'defs': 'release'}], 'isLemma': 0, 'parents': ['muc']},
	{'value': 'muñcamāna', 'label': 'muYcamAna', 'attrib': [{'pos': 'prmp', 'defs': 'release'}], 'isLemma': 0, 'parents': ['muc']},
	{'value': 'mud', 'label': 'mud', 'attrib': [{'pos': 'f', 'defs': 'joy'}, {'pos': 'vi1m', 'defs': 'rejoice'}], 'isLemma': 1},
	{'value': 'mumucāna', 'label': 'mumucAna', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'release'}], 'isLemma': 0, 'parents': ['muc']},
	{'value': 'mumucvas', 'label': 'mumucvas', 'attrib': [{'pos': 'pfap', 'defs': 'release'}], 'isLemma': 0, 'parents': ['muc']},
	{'value': 'mṛ', 'label': 'mf', 'attrib': [{'pos': 'vi6am', 'defs': 'die'}], 'isLemma': 1},
	{'value': 'mṛta', 'label': 'mfta', 'attrib': [{'pos': 'ppp', 'defs': 'die'}], 'isLemma': 0, 'parents': ['mf']},
	{'value': 'mṛtavat', 'label': 'mftavat', 'attrib': [{'pos': 'pap', 'defs': 'die'}], 'isLemma': 0, 'parents': ['mf']},
	{'value': 'mṛtvā', 'label': 'mftvA', 'attrib': [{'pos': 'abs', 'defs': 'die'}], 'isLemma': 0, 'parents': ['mf']},
	{'value': 'mṛṣ', 'label': 'mfz', 'attrib': [{'pos': 'vt4am', 'defs': 'forget, tolerate'}], 'isLemma': 1},
	{'value': 'mṛṣita', 'label': 'mfzita', 'attrib': [{'pos': 'ppp', 'defs': 'forget, tolerate'}], 'isLemma': 0, 'parents': ['mfz']},
	{'value': 'mṛṣitavat', 'label': 'mfzitavat', 'attrib': [{'pos': 'pap', 'defs': 'forget, tolerate'}], 'isLemma': 0, 'parents': ['mfz']},
	{'value': 'mṛṣitvā', 'label': 'mfzitvA', 'attrib': [{'pos': 'abs', 'defs': 'forget, tolerate'}], 'isLemma': 0, 'parents': ['mfz']},
	{'value': 'mṛṣya', 'label': 'mfzya', 'attrib': [{'pos': 'ger', 'defs': 'forget, tolerate'}], 'isLemma': 0, 'parents': ['mfz']},
	{'value': 'mṛṣyat', 'label': 'mfzyat', 'attrib': [{'pos': 'prap', 'defs': 'forget, tolerate'}], 'isLemma': 0, 'parents': ['mfz']},
	{'value': 'mṛṣyamāṇa', 'label': 'mfzyamARa', 'attrib': [{'pos': 'prmp', 'defs': 'forget, tolerate'}, {'pos': 'prpp', 'defs': 'forget, tolerate'}], 'isLemma': 0, 'parents': ['mfz']},
	{'value': 'medāna', 'label': 'medAna', 'attrib': [{'pos': 'pfpp', 'defs': 'rejoice'}], 'isLemma': 0, 'parents': ['mad']},
	{'value': 'medivas', 'label': 'medivas', 'attrib': [{'pos': 'pfap', 'defs': 'rejoice'}], 'isLemma': 0, 'parents': ['mad']},
	{'value': 'menāna', 'label': 'menAna', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'think'}], 'isLemma': 0, 'parents': ['man']},
	{'value': 'meya', 'label': 'meya', 'attrib': [{'pos': 'ger', 'defs': 'measure, make'}], 'isLemma': 0, 'parents': ['mA']},
	{'value': 'moktavya', 'label': 'moktavya', 'attrib': [{'pos': 'ger', 'defs': 'release'}], 'isLemma': 0, 'parents': ['muc']},
	{'value': 'moktum', 'label': 'moktum', 'attrib': [{'pos': 'inf', 'defs': 'release'}], 'isLemma': 0, 'parents': ['muc']},
	{'value': 'mokṣ', 'label': 'mokz', 'attrib': [{'pos': 'vt10a', 'defs': 'liberate'}], 'isLemma': 1},
	{'value': 'mokṣaṇa', 'label': 'mokzaRa', 'attrib': [{'pos': 'n', 'defs': 'freeing, liberation'}], 'isLemma': 1},
	{'value': 'mokṣaṇīya', 'label': 'mokzaRIya', 'attrib': [{'pos': 'ger', 'defs': 'liberate'}], 'isLemma': 0, 'parents': ['mokz']},
	{'value': 'mokṣayat', 'label': 'mokzayat', 'attrib': [{'pos': 'prap', 'defs': 'liberate'}], 'isLemma': 0, 'parents': ['mokz']},
	{'value': 'mokṣayām', 'label': 'mokzayAm', 'attrib': [{'pos': 'ppf_actn', 'defs': 'release'}], 'isLemma': 0, 'parents': ['muc']},
	{'value': 'mokṣita', 'label': 'mokzita', 'attrib': [{'pos': 'ppp', 'defs': 'liberate'}], 'isLemma': 0, 'parents': ['mokz']},
	{'value': 'mokṣitavat', 'label': 'mokzitavat', 'attrib': [{'pos': 'pap', 'defs': 'liberate'}], 'isLemma': 0, 'parents': ['mokz']},
	{'value': 'mokṣitavya', 'label': 'mokzitavya', 'attrib': [{'pos': 'ger', 'defs': 'liberate'}], 'isLemma': 0, 'parents': ['mokz']},
	{'value': 'mokṣitum', 'label': 'mokzitum', 'attrib': [{'pos': 'inf', 'defs': 'liberate'}], 'isLemma': 0, 'parents': ['mokz']},
	{'value': 'mokṣitvā', 'label': 'mokzitvA', 'attrib': [{'pos': 'abs', 'defs': 'liberate'}], 'isLemma': 0, 'parents': ['mokz']},
	{'value': 'mokṣiṣyat', 'label': 'mokzizyat', 'attrib': [{'pos': 'fap', 'defs': 'liberate'}], 'isLemma': 0, 'parents': ['mokz']},
	{'value': 'mokṣiṣyamāṇa', 'label': 'mokzizyamARa', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'liberate'}], 'isLemma': 0, 'parents': ['mokz']},
	{'value': 'mokṣya', 'label': 'mokzya', 'attrib': [{'pos': 'ger', 'defs': 'liberate'}], 'isLemma': 0, 'parents': ['mokz']},
	{'value': 'mokṣyat', 'label': 'mokzyat', 'attrib': [{'pos': 'fap', 'defs': 'release'}], 'isLemma': 0, 'parents': ['muc']},
	{'value': 'mokṣyamāṇa', 'label': 'mokzyamARa', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'release'}, {'pos': 'prpp', 'defs': 'liberate'}], 'isLemma': 0, 'parents': ['muc', 'mokz']},
	{'value': 'mocanīya', 'label': 'mocanIya', 'attrib': [{'pos': 'ger', 'defs': 'release'}], 'isLemma': 0, 'parents': ['muc']},
	{'value': 'mocya', 'label': 'mocya', 'attrib': [{'pos': 'ger', 'defs': 'release'}], 'isLemma': 0, 'parents': ['muc']},
	{'value': 'mriyamāṇa', 'label': 'mriyamARa', 'attrib': [{'pos': 'prmp', 'defs': 'die'}, {'pos': 'prpp', 'defs': 'die'}], 'isLemma': 0, 'parents': ['mf']},
	{'value': 'yat', 'label': 'yat', 'attrib': [{'pos': 'prap', 'defs': 'go'}], 'isLemma': 0, 'parents': ['i']},
	{'value': 'yatas', 'label': 'yatas', 'attrib': [{'pos': 'rel_adv', 'defs': 'whence, because'}], 'isLemma': 1},
	{'value': 'yathā', 'label': 'yaTA', 'attrib': [{'pos': 'rel_adv', 'defs': 'in which manner, as'}], 'isLemma': 1},
	{'value': 'yadā', 'label': 'yadA', 'attrib': [{'pos': 'rel_adv', 'defs': 'at which time, when'}], 'isLemma': 1},
	{'value': 'yayācāna', 'label': 'yayAcAna', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'ask'}], 'isLemma': 0, 'parents': ['yAc']},
	{'value': 'yayācivas', 'label': 'yayAcivas', 'attrib': [{'pos': 'pfap', 'defs': 'ask'}], 'isLemma': 0, 'parents': ['yAc']},
	{'value': 'yaśas', 'label': 'yaSas', 'attrib': [{'pos': 'n', 'defs': 'glory, fame'}], 'isLemma': 1},
	{'value': 'yāc', 'label': 'yAc', 'attrib': [{'pos': 'vt1am', 'defs': 'ask'}], 'isLemma': 1},
	{'value': 'yācat', 'label': 'yAcat', 'attrib': [{'pos': 'prap', 'defs': 'ask'}], 'isLemma': 0, 'parents': ['yAc']},
	{'value': 'yācanīya', 'label': 'yAcanIya', 'attrib': [{'pos': 'ger', 'defs': 'ask'}], 'isLemma': 0, 'parents': ['yAc']},
	{'value': 'yācamāna', 'label': 'yAcamAna', 'attrib': [{'pos': 'prmp', 'defs': 'ask'}], 'isLemma': 0, 'parents': ['yAc']},
	{'value': 'yācita', 'label': 'yAcita', 'attrib': [{'pos': 'ppp', 'defs': 'ask'}], 'isLemma': 0, 'parents': ['yAc']},
	{'value': 'yācitavat', 'label': 'yAcitavat', 'attrib': [{'pos': 'pap', 'defs': 'ask'}], 'isLemma': 0, 'parents': ['yAc']},
	{'value': 'yācitavya', 'label': 'yAcitavya', 'attrib': [{'pos': 'ger', 'defs': 'ask'}], 'isLemma': 0, 'parents': ['yAc']},
	{'value': 'yācitum', 'label': 'yAcitum', 'attrib': [{'pos': 'inf', 'defs': 'ask'}], 'isLemma': 0, 'parents': ['yAc']},
	{'value': 'yācitvā', 'label': 'yAcitvA', 'attrib': [{'pos': 'abs', 'defs': 'ask'}], 'isLemma': 0, 'parents': ['yAc']},
	{'value': 'yāciṣyat', 'label': 'yAcizyat', 'attrib': [{'pos': 'fap', 'defs': 'ask'}], 'isLemma': 0, 'parents': ['yAc']},
	{'value': 'yāciṣyamāṇa', 'label': 'yAcizyamARa', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'ask'}], 'isLemma': 0, 'parents': ['yAc']},
	{'value': 'yācya', 'label': 'yAcya', 'attrib': [{'pos': 'ger', 'defs': 'ask'}], 'isLemma': 0, 'parents': ['yAc']},
	{'value': 'yācyamāna', 'label': 'yAcyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'ask'}], 'isLemma': 0, 'parents': ['yAc']},
	{'value': 'yukta', 'label': 'yukta', 'attrib': [{'pos': 'ppp', 'defs': 'yoke, join, unite'}], 'isLemma': 0, 'parents': ['yuj']},
	{'value': 'yuktavat', 'label': 'yuktavat', 'attrib': [{'pos': 'pap', 'defs': 'yoke, join, unite'}], 'isLemma': 0, 'parents': ['yuj']},
	{'value': 'yuktvā', 'label': 'yuktvA', 'attrib': [{'pos': 'abs', 'defs': 'yoke, join, unite'}], 'isLemma': 0, 'parents': ['yuj']},
	{'value': 'yuj', 'label': 'yuj', 'attrib': [{'pos': 'vt7am', 'defs': 'yoke, join, unite'}], 'isLemma': 1},
	{'value': 'yujyamāna', 'label': 'yujyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'yoke, join, unite'}], 'isLemma': 0, 'parents': ['yuj']},
	{'value': 'yuñjat', 'label': 'yuYjat', 'attrib': [{'pos': 'prap', 'defs': 'yoke, join, unite'}], 'isLemma': 0, 'parents': ['yuj']},
	{'value': 'yuñjāna', 'label': 'yuYjAna', 'attrib': [{'pos': 'prmp', 'defs': 'yoke, join, unite'}], 'isLemma': 0, 'parents': ['yuj']},
	{'value': 'yuddha', 'label': 'yudDa', 'attrib': [{'pos': 'n', 'defs': 'battle, war'}, {'pos': 'ppp', 'defs': 'fight'}], 'isLemma': 1},
	{'value': 'yuddha', 'label': 'yudDa', 'attrib': [{'pos': 'n', 'defs': 'battle, war'}, {'pos': 'ppp', 'defs': 'fight'}], 'isLemma': 0, 'parents': ['yuD']},
	{'value': 'yuddhavat', 'label': 'yudDavat', 'attrib': [{'pos': 'pap', 'defs': 'fight'}], 'isLemma': 0, 'parents': ['yuD']},
	{'value': 'yuddhvā', 'label': 'yudDvA', 'attrib': [{'pos': 'abs', 'defs': 'fight'}], 'isLemma': 0, 'parents': ['yuD']},
	{'value': 'yudh', 'label': 'yuD', 'attrib': [{'pos': 'f', 'defs': 'battle, war'}, {'pos': 'vt4m', 'defs': 'fight'}], 'isLemma': 1},
	{'value': 'yudhiṣṭhira', 'label': 'yuDizWira', 'attrib': [{'pos': 'm_pn', 'defs': 'Yudhiṣṭhira'}], 'isLemma': 1},
	{'value': 'yudhyamāna', 'label': 'yuDyamAna', 'attrib': [{'pos': 'prmp', 'defs': 'fight'}, {'pos': 'prpp', 'defs': 'fight'}], 'isLemma': 0, 'parents': ['yuD']},
	{'value': 'yuyujāna', 'label': 'yuyujAna', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'yoke, join, unite'}], 'isLemma': 0, 'parents': ['yuj']},
	{'value': 'yuyujvas', 'label': 'yuyujvas', 'attrib': [{'pos': 'pfap', 'defs': 'yoke, join, unite'}], 'isLemma': 0, 'parents': ['yuj']},
	{'value': 'yuyudhāna', 'label': 'yuyuDAna', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'fight'}], 'isLemma': 0, 'parents': ['yuD']},
	{'value': 'yuṣmad', 'label': 'yuzmad', 'attrib': [{'pos': 'pers_pron', 'defs': 'you'}], 'isLemma': 1},
	{'value': 'yuṣmadvāsa', 'label': 'yuzmadvAsa', 'attrib': [{'pos': 'm', 'defs': 'your dwelling'}], 'isLemma': 1},
	{'value': 'yoktavya', 'label': 'yoktavya', 'attrib': [{'pos': 'ger', 'defs': 'yoke, join, unite'}], 'isLemma': 0, 'parents': ['yuj']},
	{'value': 'yoktum', 'label': 'yoktum', 'attrib': [{'pos': 'inf', 'defs': 'yoke, join, unite'}], 'isLemma': 0, 'parents': ['yuj']},
	{'value': 'yokṣyat', 'label': 'yokzyat', 'attrib': [{'pos': 'fap', 'defs': 'yoke, join, unite'}], 'isLemma': 0, 'parents': ['yuj']},
	{'value': 'yokṣyamāṇa', 'label': 'yokzyamARa', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'yoke, join, unite'}], 'isLemma': 0, 'parents': ['yuj']},
	{'value': 'yoga', 'label': 'yoga', 'attrib': [{'pos': 'm', 'defs': 'union, conjunction, use, work, means, stratagem'}], 'isLemma': 1},
	{'value': 'yogya', 'label': 'yogya', 'attrib': [{'pos': 'ger', 'defs': 'yoke, join, unite'}], 'isLemma': 0, 'parents': ['yuj']},
	{'value': 'yojanīya', 'label': 'yojanIya', 'attrib': [{'pos': 'ger', 'defs': 'yoke, join, unite'}], 'isLemma': 0, 'parents': ['yuj']},
	{'value': 'yotsyamāna', 'label': 'yotsyamAna', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'fight'}], 'isLemma': 0, 'parents': ['yuD']},
	{'value': 'yoddhavya', 'label': 'yodDavya', 'attrib': [{'pos': 'ger', 'defs': 'fight'}], 'isLemma': 0, 'parents': ['yuD']},
	{'value': 'yoddhum', 'label': 'yodDum', 'attrib': [{'pos': 'inf', 'defs': 'fight'}], 'isLemma': 0, 'parents': ['yuD']},
	{'value': 'yodhanīya', 'label': 'yoDanIya', 'attrib': [{'pos': 'ger', 'defs': 'fight'}], 'isLemma': 0, 'parents': ['yuD']},
	{'value': 'yodhya', 'label': 'yoDya', 'attrib': [{'pos': 'ger', 'defs': 'fight'}], 'isLemma': 0, 'parents': ['yuD']},
	{'value': 'rakṣ', 'label': 'rakz', 'attrib': [{'pos': 'vt1a', 'defs': 'keep, protect'}], 'isLemma': 1},
	{'value': 'rakṣat', 'label': 'rakzat', 'attrib': [{'pos': 'prap', 'defs': 'keep, protect'}], 'isLemma': 0, 'parents': ['rakz']},
	{'value': 'rakṣanīya', 'label': 'rakzanIya', 'attrib': [{'pos': 'ger', 'defs': 'keep, protect'}], 'isLemma': 0, 'parents': ['rakz']},
	{'value': 'rakṣamāṇa', 'label': 'rakzamARa', 'attrib': [{'pos': 'prmp', 'defs': 'keep, protect'}], 'isLemma': 0, 'parents': ['rakz']},
	{'value': 'rakṣas', 'label': 'rakzas', 'attrib': [{'pos': 'n', 'defs': 'demon'}], 'isLemma': 1},
	{'value': 'rakṣita', 'label': 'rakzita', 'attrib': [{'pos': 'ppp', 'defs': 'keep, protect'}], 'isLemma': 0, 'parents': ['rakz']},
	{'value': 'rakṣitavat', 'label': 'rakzitavat', 'attrib': [{'pos': 'pap', 'defs': 'keep, protect'}], 'isLemma': 0, 'parents': ['rakz']},
	{'value': 'rakṣitavya', 'label': 'rakzitavya', 'attrib': [{'pos': 'ger', 'defs': 'keep, protect'}], 'isLemma': 0, 'parents': ['rakz']},
	{'value': 'rakṣitum', 'label': 'rakzitum', 'attrib': [{'pos': 'inf', 'defs': 'keep, protect'}], 'isLemma': 0, 'parents': ['rakz']},
	{'value': 'rakṣitvā', 'label': 'rakzitvA', 'attrib': [{'pos': 'abs', 'defs': 'keep, protect'}], 'isLemma': 0, 'parents': ['rakz']},
	{'value': 'rakṣiṣyat', 'label': 'rakzizyat', 'attrib': [{'pos': 'fap', 'defs': 'keep, protect'}], 'isLemma': 0, 'parents': ['rakz']},
	{'value': 'rakṣiṣyamāṇa', 'label': 'rakzizyamARa', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'keep, protect'}], 'isLemma': 0, 'parents': ['rakz']},
	{'value': 'rakṣya', 'label': 'rakzya', 'attrib': [{'pos': 'ger', 'defs': 'keep, protect'}], 'isLemma': 0, 'parents': ['rakz']},
	{'value': 'rakṣyamāṇa', 'label': 'rakzyamARa', 'attrib': [{'pos': 'prpp', 'defs': 'keep, protect'}], 'isLemma': 0, 'parents': ['rakz']},
	{'value': 'rajamāna', 'label': 'rajamAna', 'attrib': [{'pos': 'prmp', 'defs': 'shine, rule'}], 'isLemma': 0, 'parents': ['rAj']},
	{'value': 'ratna', 'label': 'ratna', 'attrib': [{'pos': 'n', 'defs': 'gift, wealth, gem'}], 'isLemma': 1},
	{'value': 'ratha', 'label': 'raTa', 'attrib': [{'pos': 'm', 'defs': 'chariot, car'}], 'isLemma': 1},
	{'value': 'rapsyamāṇa', 'label': 'rapsyamARa', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'begin'}], 'isLemma': 0, 'parents': ['raB']},
	{'value': 'rabdha', 'label': 'rabDa', 'attrib': [{'pos': 'ppp', 'defs': 'begin'}], 'isLemma': 0, 'parents': ['raB']},
	{'value': 'rabdhavat', 'label': 'rabDavat', 'attrib': [{'pos': 'pap', 'defs': 'begin'}], 'isLemma': 0, 'parents': ['raB']},
	{'value': 'rabdhavya', 'label': 'rabDavya', 'attrib': [{'pos': 'ger', 'defs': 'begin'}], 'isLemma': 0, 'parents': ['raB']},
	{'value': 'rabdhum', 'label': 'rabDum', 'attrib': [{'pos': 'inf', 'defs': 'begin'}], 'isLemma': 0, 'parents': ['raB']},
	{'value': 'rabdhvā', 'label': 'rabDvA', 'attrib': [{'pos': 'abs', 'defs': 'begin'}], 'isLemma': 0, 'parents': ['raB']},
	{'value': 'rabh', 'label': 'raB', 'attrib': [{'pos': 'vt1m', 'defs': 'begin'}], 'isLemma': 1},
	{'value': 'rabhamāṇa', 'label': 'raBamARa', 'attrib': [{'pos': 'prmp', 'defs': 'begin'}], 'isLemma': 0, 'parents': ['raB']},
	{'value': 'rabhya', 'label': 'raBya', 'attrib': [{'pos': 'ger', 'defs': 'begin'}], 'isLemma': 0, 'parents': ['raB']},
	{'value': 'rabhyamāṇa', 'label': 'raByamARa', 'attrib': [{'pos': 'prpp', 'defs': 'begin'}], 'isLemma': 0, 'parents': ['raB']},
	{'value': 'rambhaṇīya', 'label': 'ramBaRIya', 'attrib': [{'pos': 'ger', 'defs': 'begin'}], 'isLemma': 0, 'parents': ['raB']},
	{'value': 'rarakṣāṇa', 'label': 'rarakzARa', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'keep, protect'}], 'isLemma': 0, 'parents': ['rakz']},
	{'value': 'rarakṣivas', 'label': 'rarakzivas', 'attrib': [{'pos': 'pfap', 'defs': 'keep, protect'}], 'isLemma': 0, 'parents': ['rakz']},
	{'value': 'rarājāna', 'label': 'rarAjAna', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'shine, rule'}], 'isLemma': 0, 'parents': ['rAj']},
	{'value': 'rarājivas', 'label': 'rarAjivas', 'attrib': [{'pos': 'pfap', 'defs': 'shine, rule'}], 'isLemma': 0, 'parents': ['rAj']},
	{'value': 'rākṣasa', 'label': 'rAkzasa', 'attrib': [{'pos': 'm', 'defs': 'demon'}], 'isLemma': 1},
	{'value': 'rāj', 'label': 'rAj', 'attrib': [{'pos': 'vt1am', 'defs': 'shine, rule'}], 'isLemma': 1},
	{'value': 'rājat', 'label': 'rAjat', 'attrib': [{'pos': 'prap', 'defs': 'shine, rule'}], 'isLemma': 0, 'parents': ['rAj']},
	{'value': 'rājan', 'label': 'rAjan', 'attrib': [{'pos': 'm', 'defs': 'king'}], 'isLemma': 1},
	{'value': 'rājanīya', 'label': 'rAjanIya', 'attrib': [{'pos': 'ger', 'defs': 'shine, rule'}], 'isLemma': 0, 'parents': ['rAj']},
	{'value': 'rājita', 'label': 'rAjita', 'attrib': [{'pos': 'ppp', 'defs': 'shine, rule'}], 'isLemma': 0, 'parents': ['rAj']},
	{'value': 'rājitavat', 'label': 'rAjitavat', 'attrib': [{'pos': 'pap', 'defs': 'shine, rule'}], 'isLemma': 0, 'parents': ['rAj']},
	{'value': 'rājitavya', 'label': 'rAjitavya', 'attrib': [{'pos': 'ger', 'defs': 'shine, rule'}], 'isLemma': 0, 'parents': ['rAj']},
	{'value': 'rājitum', 'label': 'rAjitum', 'attrib': [{'pos': 'inf', 'defs': 'shine, rule'}], 'isLemma': 0, 'parents': ['rAj']},
	{'value': 'rājitvā', 'label': 'rAjitvA', 'attrib': [{'pos': 'abs', 'defs': 'shine, rule'}], 'isLemma': 0, 'parents': ['rAj']},
	{'value': 'rājiṣyat', 'label': 'rAjizyat', 'attrib': [{'pos': 'fap', 'defs': 'shine, rule'}], 'isLemma': 0, 'parents': ['rAj']},
	{'value': 'rājiṣyamāṇa', 'label': 'rAjizyamARa', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'shine, rule'}], 'isLemma': 0, 'parents': ['rAj']},
	{'value': 'rājīva', 'label': 'rAjIva', 'attrib': [{'pos': 'n', 'defs': 'blue lotus'}], 'isLemma': 1},
	{'value': 'rājīvalocana', 'label': 'rAjIvalocana', 'attrib': [{'pos': 'adj', 'defs': 'lotus-eyed'}], 'isLemma': 1},
	{'value': 'rājya', 'label': 'rAjya', 'attrib': [{'pos': 'ger', 'defs': 'shine, rule'}, {'pos': 'n', 'defs': 'kingdom'}], 'isLemma': 1},
	{'value': 'rājya', 'label': 'rAjya', 'attrib': [{'pos': 'ger', 'defs': 'shine, rule'}, {'pos': 'n', 'defs': 'kingdom'}], 'isLemma': 0, 'parents': ['rAj']},
	{'value': 'rājyamāna', 'label': 'rAjyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'shine, rule'}], 'isLemma': 0, 'parents': ['rAj']},
	{'value': 'rājyavināśa', 'label': 'rAjyavinASa', 'attrib': [{'pos': 'm', 'defs': 'destruction of a kingdom'}], 'isLemma': 1},
	{'value': 'rājyārtha', 'label': 'rAjyArTa', 'attrib': [{'pos': 'm', 'defs': 'purpose of a kingdom'}], 'isLemma': 1},
	{'value': 'rāṣṭra', 'label': 'rAzwra', 'attrib': [{'pos': 'n', 'defs': 'kingdom'}], 'isLemma': 1},
	{'value': 'rūpa', 'label': 'rUpa', 'attrib': [{'pos': 'n', 'defs': 'appearance, form, beauty'}], 'isLemma': 1},
	{'value': 'rūpavīryaujaḥsaṁpanna', 'label': 'rUpavIryOjaHsaMpanna', 'attrib': [{'pos': 'adj', 'defs': 'endowed with beauty, power and energy'}], 'isLemma': 1},
	{'value': 'rūpavīryaujas', 'label': 'rUpavIryOjas', 'attrib': [{'pos': 'n', 'defs': 'beauty, power and energy'}], 'isLemma': 1},
	{'value': 'rebhivas', 'label': 'reBivas', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'begin'}], 'isLemma': 0, 'parents': ['raB']},
	{'value': 'lakṣaṇa', 'label': 'lakzaRa', 'attrib': [{'pos': 'n', 'defs': 'characteristic, sign'}], 'isLemma': 1},
	{'value': 'lakṣmī', 'label': 'lakzmI', 'attrib': [{'pos': 'f', 'defs': 'sign, good fortune'}, {'pos': 'f_pn', 'defs': 'Lakṣmī'}], 'isLemma': 1},
	{'value': 'lapsyamāna', 'label': 'lapsyamAna', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'obtain'}], 'isLemma': 0, 'parents': ['laB']},
	{'value': 'labdha', 'label': 'labDa', 'attrib': [{'pos': 'ppp', 'defs': 'obtain'}], 'isLemma': 0, 'parents': ['laB']},
	{'value': 'labdhavat', 'label': 'labDavat', 'attrib': [{'pos': 'pap', 'defs': 'obtain'}], 'isLemma': 0, 'parents': ['laB']},
	{'value': 'labdhavya', 'label': 'labDavya', 'attrib': [{'pos': 'ger', 'defs': 'obtain'}], 'isLemma': 0, 'parents': ['laB']},
	{'value': 'labdhum', 'label': 'labDum', 'attrib': [{'pos': 'inf', 'defs': 'obtain'}], 'isLemma': 0, 'parents': ['laB']},
	{'value': 'labdhvā', 'label': 'labDvA', 'attrib': [{'pos': 'abs', 'defs': 'obtain'}], 'isLemma': 0, 'parents': ['laB']},
	{'value': 'labh', 'label': 'laB', 'attrib': [{'pos': 'vt1m', 'defs': 'obtain'}], 'isLemma': 1},
	{'value': 'labhanīya', 'label': 'laBanIya', 'attrib': [{'pos': 'ger', 'defs': 'obtain'}], 'isLemma': 0, 'parents': ['laB']},
	{'value': 'labhamāna', 'label': 'laBamAna', 'attrib': [{'pos': 'prmp', 'defs': 'obtain'}], 'isLemma': 0, 'parents': ['laB']},
	{'value': 'labhya', 'label': 'laBya', 'attrib': [{'pos': 'ger', 'defs': 'obtain'}], 'isLemma': 0, 'parents': ['laB']},
	{'value': 'labhyamāna', 'label': 'laByamAna', 'attrib': [{'pos': 'prpp', 'defs': 'obtain'}], 'isLemma': 0, 'parents': ['laB']},
	{'value': 'lubdha', 'label': 'lubDa', 'attrib': [{'pos': 'ppp', 'defs': 'lust'}], 'isLemma': 0, 'parents': ['luB']},
	{'value': 'lubdhavat', 'label': 'lubDavat', 'attrib': [{'pos': 'pap', 'defs': 'lust'}], 'isLemma': 0, 'parents': ['luB']},
	{'value': 'lubdhvā', 'label': 'lubDvA', 'attrib': [{'pos': 'abs', 'defs': 'lust'}], 'isLemma': 0, 'parents': ['luB']},
	{'value': 'lubh', 'label': 'luB', 'attrib': [{'pos': 'vi4a', 'defs': 'lust'}], 'isLemma': 1},
	{'value': 'lubhitvā', 'label': 'luBitvA', 'attrib': [{'pos': 'abs', 'defs': 'lust'}], 'isLemma': 0, 'parents': ['luB']},
	{'value': 'lubhya', 'label': 'luBya', 'attrib': [{'pos': 'ger', 'defs': 'lust'}], 'isLemma': 0, 'parents': ['luB']},
	{'value': 'lubhyat', 'label': 'luByat', 'attrib': [{'pos': 'prap', 'defs': 'lust'}], 'isLemma': 0, 'parents': ['luB']},
	{'value': 'lubhyamāna', 'label': 'luByamAna', 'attrib': [{'pos': 'prpp', 'defs': 'lust'}], 'isLemma': 0, 'parents': ['luB']},
	{'value': 'lulubhāna', 'label': 'luluBAna', 'attrib': [{'pos': 'pfpp', 'defs': 'lust'}], 'isLemma': 0, 'parents': ['luB']},
	{'value': 'lebhāna', 'label': 'leBAna', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'obtain'}], 'isLemma': 0, 'parents': ['laB']},
	{'value': 'loka', 'label': 'loka', 'attrib': [{'pos': 'm', 'defs': 'region'}], 'isLemma': 1},
	{'value': 'locana', 'label': 'locana', 'attrib': [{'pos': 'n', 'defs': 'eye'}], 'isLemma': 1},
	{'value': 'lobdhavya', 'label': 'lobDavya', 'attrib': [{'pos': 'ger', 'defs': 'lust'}], 'isLemma': 0, 'parents': ['luB']},
	{'value': 'lobdhum', 'label': 'lobDum', 'attrib': [{'pos': 'inf', 'defs': 'lust'}], 'isLemma': 0, 'parents': ['luB']},
	{'value': 'lobha', 'label': 'loBa', 'attrib': [{'pos': 'm', 'defs': 'love, desire'}], 'isLemma': 1},
	{'value': 'lobhanīya', 'label': 'loBanIya', 'attrib': [{'pos': 'ger', 'defs': 'lust'}], 'isLemma': 0, 'parents': ['luB']},
	{'value': 'lobhitavya', 'label': 'loBitavya', 'attrib': [{'pos': 'ger', 'defs': 'lust'}], 'isLemma': 0, 'parents': ['luB']},
	{'value': 'lobhitum', 'label': 'loBitum', 'attrib': [{'pos': 'inf', 'defs': 'lust'}], 'isLemma': 0, 'parents': ['luB']},
	{'value': 'lobhitvā', 'label': 'loBitvA', 'attrib': [{'pos': 'abs', 'defs': 'lust'}], 'isLemma': 0, 'parents': ['luB']},
	{'value': 'lobhiṣyat', 'label': 'loBizyat', 'attrib': [{'pos': 'fap', 'defs': 'lust'}], 'isLemma': 0, 'parents': ['luB']},
	{'value': 'lobhiṣyamāṇa', 'label': 'loBizyamARa', 'attrib': [{'pos': 'fpp', 'defs': 'lust'}], 'isLemma': 0, 'parents': ['luB']},
	{'value': 'vakta', 'label': 'vakta', 'attrib': [{'pos': 'ppp', 'defs': 'deceive'}], 'isLemma': 0, 'parents': ['vaYc']},
	{'value': 'vaktavat', 'label': 'vaktavat', 'attrib': [{'pos': 'pap', 'defs': 'deceive'}], 'isLemma': 0, 'parents': ['vaYc']},
	{'value': 'vaktavya', 'label': 'vaktavya', 'attrib': [{'pos': 'ger', 'defs': 'say, speak'}], 'isLemma': 0, 'parents': ['vac']},
	{'value': 'vaktum', 'label': 'vaktum', 'attrib': [{'pos': 'inf', 'defs': 'say, speak'}], 'isLemma': 0, 'parents': ['vac']},
	{'value': 'vaktvā', 'label': 'vaktvA', 'attrib': [{'pos': 'abs', 'defs': 'deceive'}], 'isLemma': 0, 'parents': ['vaYc']},
	{'value': 'vakṣyat', 'label': 'vakzyat', 'attrib': [{'pos': 'fap', 'defs': 'say, speak'}, {'pos': 'fap', 'defs': 'carry, flow, wed'}], 'isLemma': 0, 'parents': ['vac', 'vah']},
	{'value': 'vakṣyamāṇa', 'label': 'vakzyamARa', 'attrib': [{'pos': 'fpp', 'defs': 'say, speak'}, {'pos': 'fmp, fpp', 'defs': 'carry, flow, wed'}], 'isLemma': 0, 'parents': ['vac', 'vah']},
	{'value': 'vac', 'label': 'vac', 'attrib': [{'pos': 'vt2a', 'defs': 'say, speak'}], 'isLemma': 1},
	{'value': 'vacana', 'label': 'vacana', 'attrib': [{'pos': 'n', 'defs': 'speech'}], 'isLemma': 1},
	{'value': 'vacanīya', 'label': 'vacanIya', 'attrib': [{'pos': 'ger', 'defs': 'say, speak'}], 'isLemma': 0, 'parents': ['vac']},
	{'value': 'vacitvā', 'label': 'vacitvA', 'attrib': [{'pos': 'abs', 'defs': 'deceive'}], 'isLemma': 0, 'parents': ['vaYc']},
	{'value': 'vañc', 'label': 'vaYc', 'attrib': [{'pos': 'vt10m', 'defs': 'deceive'}], 'isLemma': 1},
	{'value': 'vañcanīya', 'label': 'vaYcanIya', 'attrib': [{'pos': 'ger', 'defs': 'deceive'}], 'isLemma': 0, 'parents': ['vaYc']},
	{'value': 'vañcayamāna', 'label': 'vaYcayamAna', 'attrib': [{'pos': 'prmp', 'defs': 'deceive'}], 'isLemma': 0, 'parents': ['vaYc']},
	{'value': 'vañcayām', 'label': 'vaYcayAm', 'attrib': [{'pos': 'ppf_actn', 'defs': 'deceive'}], 'isLemma': 0, 'parents': ['vaYc']},
	{'value': 'vañcayitavya', 'label': 'vaYcayitavya', 'attrib': [{'pos': 'ger', 'defs': 'deceive'}], 'isLemma': 0, 'parents': ['vaYc']},
	{'value': 'vañcayitum', 'label': 'vaYcayitum', 'attrib': [{'pos': 'inf', 'defs': 'deceive'}], 'isLemma': 0, 'parents': ['vaYc']},
	{'value': 'vañcayitvā', 'label': 'vaYcayitvA', 'attrib': [{'pos': 'abs', 'defs': 'deceive'}], 'isLemma': 0, 'parents': ['vaYc']},
	{'value': 'vañcayiṣyamāṇa', 'label': 'vaYcayizyamARa', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'deceive'}], 'isLemma': 0, 'parents': ['vaYc']},
	{'value': 'vañcitvā', 'label': 'vaYcitvA', 'attrib': [{'pos': 'abs', 'defs': 'deceive'}], 'isLemma': 0, 'parents': ['vaYc']},
	{'value': 'vañcya', 'label': 'vaYcya', 'attrib': [{'pos': 'ger', 'defs': 'deceive'}], 'isLemma': 0, 'parents': ['vaYc']},
	{'value': 'vañcyamāna', 'label': 'vaYcyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'deceive'}], 'isLemma': 0, 'parents': ['vaYc']},
	{'value': 'vatsyat', 'label': 'vatsyat', 'attrib': [{'pos': 'fap', 'defs': 'dwell, live'}], 'isLemma': 0, 'parents': ['vas']},
	{'value': 'vatsyamāna', 'label': 'vatsyamAna', 'attrib': [{'pos': 'fpp', 'defs': 'dwell, live'}], 'isLemma': 0, 'parents': ['vas']},
	{'value': 'vad', 'label': 'vad', 'attrib': [{'pos': 'vt1a', 'defs': 'tell'}], 'isLemma': 1},
	{'value': 'vadat', 'label': 'vadat', 'attrib': [{'pos': 'prap', 'defs': 'tell'}], 'isLemma': 0, 'parents': ['vad']},
	{'value': 'vadanīya', 'label': 'vadanIya', 'attrib': [{'pos': 'ger', 'defs': 'tell'}], 'isLemma': 0, 'parents': ['vad']},
	{'value': 'vaditavya', 'label': 'vaditavya', 'attrib': [{'pos': 'ger', 'defs': 'tell'}], 'isLemma': 0, 'parents': ['vad']},
	{'value': 'vaditum', 'label': 'vaditum', 'attrib': [{'pos': 'inf', 'defs': 'tell'}], 'isLemma': 0, 'parents': ['vad']},
	{'value': 'vadiṣyat', 'label': 'vadizyat', 'attrib': [{'pos': 'fap', 'defs': 'tell'}], 'isLemma': 0, 'parents': ['vad']},
	{'value': 'vadiṣyamāṇa', 'label': 'vadizyamARa', 'attrib': [{'pos': 'fpp', 'defs': 'tell'}], 'isLemma': 0, 'parents': ['vad']},
	{'value': 'vadh', 'label': 'vaD', 'attrib': [{'pos': 'vta', 'defs': 'smite, slay'}], 'isLemma': 1},
	{'value': 'vadha', 'label': 'vaDa', 'attrib': [{'pos': 'm', 'defs': 'killing, slaughter'}], 'isLemma': 1},
	{'value': 'vana', 'label': 'vana', 'attrib': [{'pos': 'n', 'defs': 'forest'}], 'isLemma': 1},
	{'value': 'vanavāsa', 'label': 'vanavAsa', 'attrib': [{'pos': 'm', 'defs': 'forest-dwelling'}], 'isLemma': 1},
	{'value': 'vara', 'label': 'vara', 'attrib': [{'pos': 'adj', 'defs': 'elect, excellent'}, {'pos': 'm', 'defs': 'choice, boon'}], 'isLemma': 1},
	{'value': 'varaṇīya', 'label': 'varaRIya', 'attrib': [{'pos': 'ger', 'defs': 'cover'}, {'pos': 'ger', 'defs': 'choose'}], 'isLemma': 0, 'parents': ['vf']},
	{'value': 'varitavya', 'label': 'varitavya', 'attrib': [{'pos': 'ger', 'defs': 'cover'}, {'pos': 'ger', 'defs': 'choose'}], 'isLemma': 0, 'parents': ['vf']},
	{'value': 'varitum', 'label': 'varitum', 'attrib': [{'pos': 'inf', 'defs': 'cover'}, {'pos': 'inf', 'defs': 'choose'}], 'isLemma': 0, 'parents': ['vf']},
	{'value': 'variṣyat', 'label': 'varizyat', 'attrib': [{'pos': 'fap', 'defs': 'cover'}], 'isLemma': 0, 'parents': ['vf']},
	{'value': 'variṣyamāṇa', 'label': 'varizyamARa', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'cover'}, {'pos': 'fmp, fpp', 'defs': 'choose'}], 'isLemma': 0, 'parents': ['vf']},
	{'value': 'varītavya', 'label': 'varItavya', 'attrib': [{'pos': 'ger', 'defs': 'cover'}], 'isLemma': 0, 'parents': ['vf']},
	{'value': 'varītum', 'label': 'varItum', 'attrib': [{'pos': 'inf', 'defs': 'cover'}, {'pos': 'inf', 'defs': 'choose'}], 'isLemma': 0, 'parents': ['vf']},
	{'value': 'varīṣyat', 'label': 'varIzyat', 'attrib': [{'pos': 'fap', 'defs': 'cover'}], 'isLemma': 0, 'parents': ['vf']},
	{'value': 'varīṣyamāṇa', 'label': 'varIzyamARa', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'cover'}, {'pos': 'fmp, fpp', 'defs': 'choose'}], 'isLemma': 0, 'parents': ['vf']},
	{'value': 'vartanīya', 'label': 'vartanIya', 'attrib': [{'pos': 'ger', 'defs': 'occur, be present'}], 'isLemma': 0, 'parents': ['vft']},
	{'value': 'vartamāna', 'label': 'vartamAna', 'attrib': [{'pos': 'prmp', 'defs': 'occur, be present'}], 'isLemma': 0, 'parents': ['vft']},
	{'value': 'vartitavya', 'label': 'vartitavya', 'attrib': [{'pos': 'ger', 'defs': 'occur, be present'}], 'isLemma': 0, 'parents': ['vft']},
	{'value': 'vartitum', 'label': 'vartitum', 'attrib': [{'pos': 'inf', 'defs': 'occur, be present'}], 'isLemma': 0, 'parents': ['vft']},
	{'value': 'vartitvā', 'label': 'vartitvA', 'attrib': [{'pos': 'abs', 'defs': 'occur, be present'}], 'isLemma': 0, 'parents': ['vft']},
	{'value': 'vartiṣyamāṇa', 'label': 'vartizyamARa', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'occur, be present'}], 'isLemma': 0, 'parents': ['vft']},
	{'value': 'varṣa', 'label': 'varza', 'attrib': [{'pos': 'm, n', 'defs': 'rain, year'}], 'isLemma': 1},
	{'value': 'varṣaṇīya', 'label': 'varzaRIya', 'attrib': [{'pos': 'ger', 'defs': 'rain'}], 'isLemma': 0, 'parents': ['vfz']},
	{'value': 'varṣat', 'label': 'varzat', 'attrib': [{'pos': 'prap', 'defs': 'rain'}], 'isLemma': 0, 'parents': ['vfz']},
	{'value': 'varṣitavya', 'label': 'varzitavya', 'attrib': [{'pos': 'ger', 'defs': 'rain'}], 'isLemma': 0, 'parents': ['vfz']},
	{'value': 'varṣitum', 'label': 'varzitum', 'attrib': [{'pos': 'inf', 'defs': 'rain'}], 'isLemma': 0, 'parents': ['vfz']},
	{'value': 'varṣitvā', 'label': 'varzitvA', 'attrib': [{'pos': 'abs', 'defs': 'rain'}], 'isLemma': 0, 'parents': ['vfz']},
	{'value': 'varṣiṣyat', 'label': 'varzizyat', 'attrib': [{'pos': 'fap', 'defs': 'rain'}], 'isLemma': 0, 'parents': ['vfz']},
	{'value': 'varṣya', 'label': 'varzya', 'attrib': [{'pos': 'ger', 'defs': 'rain'}], 'isLemma': 0, 'parents': ['vfz']},
	{'value': 'varṣyamāṇa', 'label': 'varzyamARa', 'attrib': [{'pos': 'fpp', 'defs': 'rain'}], 'isLemma': 0, 'parents': ['vfz']},
	{'value': 'vavṛtāna', 'label': 'vavftAna', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'occur, be present'}], 'isLemma': 0, 'parents': ['vft']},
	{'value': 'vavṛvas', 'label': 'vavfvas', 'attrib': [{'pos': 'pfap', 'defs': 'cover'}], 'isLemma': 0, 'parents': ['vf']},
	{'value': 'vavṛṣāna', 'label': 'vavfzAna', 'attrib': [{'pos': 'pfpp', 'defs': 'rain'}], 'isLemma': 0, 'parents': ['vfz']},
	{'value': 'vavṛṣivas', 'label': 'vavfzivas', 'attrib': [{'pos': 'pfap', 'defs': 'rain'}], 'isLemma': 0, 'parents': ['vfz']},
	{'value': 'vavrajāna', 'label': 'vavrajAna', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'walk'}], 'isLemma': 0, 'parents': ['vraj']},
	{'value': 'vavrajivas', 'label': 'vavrajivas', 'attrib': [{'pos': 'pfap', 'defs': 'walk'}], 'isLemma': 0, 'parents': ['vraj']},
	{'value': 'vavrāṇa', 'label': 'vavrARa', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'cover'}, {'pos': 'pfmp, pfpp', 'defs': 'choose'}], 'isLemma': 0, 'parents': ['vf']},
	{'value': 'vaś', 'label': 'vaS', 'attrib': [{'pos': 'vt2a', 'defs': 'wish'}], 'isLemma': 1},
	{'value': 'vaśa', 'label': 'vaSa', 'attrib': [{'pos': 'm', 'defs': 'will, wish, desire, control'}], 'isLemma': 1},
	{'value': 'vaśanīya', 'label': 'vaSanIya', 'attrib': [{'pos': 'ger', 'defs': 'wish'}], 'isLemma': 0, 'parents': ['vaS']},
	{'value': 'vaśitavya', 'label': 'vaSitavya', 'attrib': [{'pos': 'ger', 'defs': 'wish'}], 'isLemma': 0, 'parents': ['vaS']},
	{'value': 'vaśitum', 'label': 'vaSitum', 'attrib': [{'pos': 'inf', 'defs': 'wish'}], 'isLemma': 0, 'parents': ['vaS']},
	{'value': 'vaśitvā', 'label': 'vaSitvA', 'attrib': [{'pos': 'abs', 'defs': 'wish'}], 'isLemma': 0, 'parents': ['vaS']},
	{'value': 'vaśiṣyat', 'label': 'vaSizyat', 'attrib': [{'pos': 'fap', 'defs': 'wish'}], 'isLemma': 0, 'parents': ['vaS']},
	{'value': 'vaśiṣyamāṇa', 'label': 'vaSizyamARa', 'attrib': [{'pos': 'fpp', 'defs': 'wish'}], 'isLemma': 0, 'parents': ['vaS']},
	{'value': 'vaśya', 'label': 'vaSya', 'attrib': [{'pos': 'ger', 'defs': 'wish'}], 'isLemma': 0, 'parents': ['vaS']},
	{'value': 'vas', 'label': 'vas', 'attrib': [{'pos': 'vi1a', 'defs': 'dwell, live'}], 'isLemma': 1},
	{'value': 'vasat', 'label': 'vasat', 'attrib': [{'pos': 'prap', 'defs': 'dwell, live'}], 'isLemma': 0, 'parents': ['vas']},
	{'value': 'vasanīya', 'label': 'vasanIya', 'attrib': [{'pos': 'ger', 'defs': 'dwell, live'}], 'isLemma': 0, 'parents': ['vas']},
	{'value': 'vasitavya', 'label': 'vasitavya', 'attrib': [{'pos': 'ger', 'defs': 'dwell, live'}], 'isLemma': 0, 'parents': ['vas']},
	{'value': 'vasu', 'label': 'vasu', 'attrib': [{'pos': 'adj', 'defs': 'excellent, good, beneficent'}, {'pos': 'n', 'defs': 'goods, property'}], 'isLemma': 1},
	{'value': 'vasuṁdhara', 'label': 'vasuMDara', 'attrib': [{'pos': 'adj', 'defs': 'holding wealth'}], 'isLemma': 1},
	{'value': 'vasuṁdharā', 'label': 'vasuMDarA', 'attrib': [{'pos': 'f', 'defs': 'the earth'}], 'isLemma': 1},
	{'value': 'vasudeva', 'label': 'vasudeva', 'attrib': [{'pos': 'm', 'defs': 'Vasudeva'}], 'isLemma': 1},
	{'value': 'vastum', 'label': 'vastum', 'attrib': [{'pos': 'inf', 'defs': 'dwell, live'}], 'isLemma': 0, 'parents': ['vas']},
	{'value': 'vah', 'label': 'vah', 'attrib': [{'pos': 'vt1am', 'defs': 'carry, flow, wed'}], 'isLemma': 1},
	{'value': 'vahat', 'label': 'vahat', 'attrib': [{'pos': 'prap', 'defs': 'carry, flow, wed'}], 'isLemma': 0, 'parents': ['vah']},
	{'value': 'vahamāna', 'label': 'vahamAna', 'attrib': [{'pos': 'prmp', 'defs': 'carry, flow, wed'}], 'isLemma': 0, 'parents': ['vah']},
	{'value': 'vahiṣyat', 'label': 'vahizyat', 'attrib': [{'pos': 'fap', 'defs': 'carry, flow, wed'}], 'isLemma': 0, 'parents': ['vah']},
	{'value': 'vahiṣyamāṇa', 'label': 'vahizyamARa', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'carry, flow, wed'}], 'isLemma': 0, 'parents': ['vah']},
	{'value': 'vākya', 'label': 'vAkya', 'attrib': [{'pos': 'ger', 'defs': 'say, speak'}], 'isLemma': 0, 'parents': ['vac']},
	{'value': 'vādya', 'label': 'vAdya', 'attrib': [{'pos': 'ger', 'defs': 'tell'}], 'isLemma': 0, 'parents': ['vad']},
	{'value': 'vāraṇīya', 'label': 'vAraRIya', 'attrib': [{'pos': 'ger', 'defs': 'block'}], 'isLemma': 0, 'parents': ['vf']},
	{'value': 'vārayat', 'label': 'vArayat', 'attrib': [{'pos': 'prap', 'defs': 'block'}], 'isLemma': 0, 'parents': ['vf']},
	{'value': 'vārayamāṇa', 'label': 'vArayamARa', 'attrib': [{'pos': 'prmp', 'defs': 'block'}], 'isLemma': 0, 'parents': ['vf']},
	{'value': 'vārayām', 'label': 'vArayAm', 'attrib': [{'pos': 'ppf_actn', 'defs': 'block'}], 'isLemma': 0, 'parents': ['vf']},
	{'value': 'vārayitavya', 'label': 'vArayitavya', 'attrib': [{'pos': 'ger', 'defs': 'block'}], 'isLemma': 0, 'parents': ['vf']},
	{'value': 'vārayitum', 'label': 'vArayitum', 'attrib': [{'pos': 'inf', 'defs': 'block'}], 'isLemma': 0, 'parents': ['vf']},
	{'value': 'vārayitvā', 'label': 'vArayitvA', 'attrib': [{'pos': 'abs', 'defs': 'block'}], 'isLemma': 0, 'parents': ['vf']},
	{'value': 'vārayiṣyat', 'label': 'vArayizyat', 'attrib': [{'pos': 'fap', 'defs': 'block'}], 'isLemma': 0, 'parents': ['vf']},
	{'value': 'vārayiṣyamāṇa', 'label': 'vArayizyamARa', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'block'}], 'isLemma': 0, 'parents': ['vf']},
	{'value': 'vārita', 'label': 'vArita', 'attrib': [{'pos': 'ppp', 'defs': 'block'}], 'isLemma': 0, 'parents': ['vf']},
	{'value': 'vāritavat', 'label': 'vAritavat', 'attrib': [{'pos': 'pap', 'defs': 'block'}], 'isLemma': 0, 'parents': ['vf']},
	{'value': 'vārya', 'label': 'vArya', 'attrib': [{'pos': 'ger', 'defs': 'block'}, {'pos': 'ger', 'defs': 'choose'}], 'isLemma': 0, 'parents': ['vf']},
	{'value': 'vāryamāṇa', 'label': 'vAryamARa', 'attrib': [{'pos': 'prpp', 'defs': 'block'}], 'isLemma': 0, 'parents': ['vf']},
	{'value': 'vāsa', 'label': 'vAsa', 'attrib': [{'pos': 'm', 'defs': 'dwelling, living'}], 'isLemma': 1},
	{'value': 'vāsayām', 'label': 'vAsayAm', 'attrib': [{'pos': 'ppf_actn', 'defs': 'dwell, live'}], 'isLemma': 0, 'parents': ['vas']},
	{'value': 'vāsudeva', 'label': 'vAsudeva', 'attrib': [{'pos': 'm', 'defs': 'son of Vasudeva'}], 'isLemma': 1},
	{'value': 'vāsya', 'label': 'vAsya', 'attrib': [{'pos': 'ger', 'defs': 'dwell, live'}], 'isLemma': 0, 'parents': ['vas']},
	{'value': 'vāhana', 'label': 'vAhana', 'attrib': [{'pos': 'adj', 'defs': 'carrying'}, {'pos': 'n', 'defs': 'vehicle'}], 'isLemma': 1},
	{'value': 'vāhanīya', 'label': 'vAhanIya', 'attrib': [{'pos': 'ger', 'defs': 'carry, flow, wed'}], 'isLemma': 0, 'parents': ['vah']},
	{'value': 'vāhya', 'label': 'vAhya', 'attrib': [{'pos': 'ger', 'defs': 'carry, flow, wed'}], 'isLemma': 0, 'parents': ['vah']},
	{'value': 'vi', 'label': 'vi', 'attrib': [{'pos': 'preverb', 'defs': 'apart'}], 'isLemma': 1},
	{'value': 'vi-vṛtya', 'label': 'vi-vftya', 'attrib': [{'pos': 'abs', 'defs': 'uncover, reveal, explain'}], 'isLemma': 0, 'parents': ['vi_vf']},
	{'value': 'vikrama', 'label': 'vikrama', 'attrib': [{'pos': 'm', 'defs': 'wide step, valor'}], 'isLemma': 1},
	{'value': 'vigraha', 'label': 'vigraha', 'attrib': [{'pos': 'm', 'defs': 'division, discord, war'}], 'isLemma': 1},
	{'value': 'vijayanīya', 'label': 'vijayanIya', 'attrib': [{'pos': 'ger', 'defs': 'conquer'}], 'isLemma': 0, 'parents': ['vi_ji']},
	{'value': 'vijayamāna', 'label': 'vijayamAna', 'attrib': [{'pos': 'prmp', 'defs': 'conquer'}], 'isLemma': 0, 'parents': ['vi_ji']},
	{'value': 'vijigyāna', 'label': 'vijigyAna', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'conquer'}], 'isLemma': 0, 'parents': ['vi_ji']},
	{'value': 'vijita', 'label': 'vijita', 'attrib': [{'pos': 'ppp', 'defs': 'conquer'}], 'isLemma': 0, 'parents': ['vi_ji']},
	{'value': 'vijitavat', 'label': 'vijitavat', 'attrib': [{'pos': 'pap', 'defs': 'conquer'}], 'isLemma': 0, 'parents': ['vi_ji']},
	{'value': 'vijitya', 'label': 'vijitya', 'attrib': [{'pos': 'abs', 'defs': 'conquer'}], 'isLemma': 0, 'parents': ['vi_ji']},
	{'value': 'vijīyamāna', 'label': 'vijIyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'conquer'}], 'isLemma': 0, 'parents': ['vi_ji']},
	{'value': 'vijetavya', 'label': 'vijetavya', 'attrib': [{'pos': 'ger', 'defs': 'conquer'}], 'isLemma': 0, 'parents': ['vi_ji']},
	{'value': 'vijetum', 'label': 'vijetum', 'attrib': [{'pos': 'inf', 'defs': 'conquer'}], 'isLemma': 0, 'parents': ['vi_ji']},
	{'value': 'vijeya', 'label': 'vijeya', 'attrib': [{'pos': 'ger', 'defs': 'conquer'}], 'isLemma': 0, 'parents': ['vi_ji']},
	{'value': 'vijeṣyamāṇa', 'label': 'vijezyamARa', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'conquer'}], 'isLemma': 0, 'parents': ['vi_ji']},
	{'value': 'vi_ava_so', 'label': 'vi_ava_so', 'attrib': [{'pos': 'vt4a', 'defs': 'conclude, determine'}], 'isLemma': 1},
	{'value': 'vi_kram', 'label': 'vi_kram', 'attrib': [{'pos': 'vt1a', 'defs': 'step wide, be bold, advance'}], 'isLemma': 1},
	{'value': 'vi_grah', 'label': 'vi_grah', 'attrib': [{'pos': 'vt9a', 'defs': 'take apart, analyse, wage war'}], 'isLemma': 1},
	{'value': 'vi_ji', 'label': 'vi_ji', 'attrib': [{'pos': 'vt1m', 'defs': 'conquer'}], 'isLemma': 1},
	{'value': 'vi_dru', 'label': 'vi_dru', 'attrib': [{'pos': 'vt1a', 'defs': 'run apart, disperse'}], 'isLemma': 1},
	{'value': 'vi_dhā', 'label': 'vi_DA', 'attrib': [{'pos': 'vt3am', 'defs': 'bestow, supply, ordain'}], 'isLemma': 1},
	{'value': 'vi_naś', 'label': 'vi_naS', 'attrib': [{'pos': 'vi4a', 'defs': 'perish'}], 'isLemma': 1},
	{'value': 'vi_ni_han', 'label': 'vi_ni_han', 'attrib': [{'pos': 'vt2a', 'defs': 'strike down, destroy'}], 'isLemma': 1},
	{'value': 'vi_bhaj', 'label': 'vi_Baj', 'attrib': [{'pos': 'vt1am', 'defs': 'divide, distribute, apportion'}], 'isLemma': 1},
	{'value': 'vi_rāj', 'label': 'vi_rAj', 'attrib': [{'pos': 'vt1am', 'defs': 'shine, rule'}], 'isLemma': 1},
	{'value': 'vi_vṛ', 'label': 'vi_vf', 'attrib': [{'pos': 'vt5am', 'defs': 'uncover, reveal, explain'}], 'isLemma': 1},
	{'value': 'vi_śrambh', 'label': 'vi_SramB', 'attrib': [{'pos': 'vi1m', 'defs': 'be careless, negligent'}], 'isLemma': 1},
	{'value': 'vi_śru', 'label': 'vi_Sru', 'attrib': [{'pos': 'vt1am', 'defs': 'hear widely'}], 'isLemma': 1},
	{'value': 'vi_srambh', 'label': 'vi_sramB', 'attrib': [{'pos': 'vi1m', 'defs': 'be confident, trust, relax'}], 'isLemma': 1},
	{'value': 'vitta', 'label': 'vitta', 'attrib': [{'pos': 'ppp', 'defs': 'find, get'}], 'isLemma': 0, 'parents': ['vid']},
	{'value': 'vittavat', 'label': 'vittavat', 'attrib': [{'pos': 'pap', 'defs': 'find, get'}], 'isLemma': 0, 'parents': ['vid']},
	{'value': 'vittvā', 'label': 'vittvA', 'attrib': [{'pos': 'abs', 'defs': 'find, get'}], 'isLemma': 0, 'parents': ['vid']},
	{'value': 'vid', 'label': 'vid', 'attrib': [{'pos': 'vt2a', 'defs': 'know'}, {'pos': 'vt6am', 'defs': 'find, get'}], 'isLemma': 1},
	{'value': 'vidadhat', 'label': 'vidaDat', 'attrib': [{'pos': 'prap', 'defs': 'bestow, supply, ordain'}], 'isLemma': 0, 'parents': ['vi_DA']},
	{'value': 'vidadhāna', 'label': 'vidaDAna', 'attrib': [{'pos': 'prmp', 'defs': 'bestow, supply, ordain'}, {'pos': 'pfmp, pfpp', 'defs': 'bestow, supply, ordain'}], 'isLemma': 0, 'parents': ['vi_DA']},
	{'value': 'vidadhivas', 'label': 'vidaDivas', 'attrib': [{'pos': 'pfap', 'defs': 'bestow, supply, ordain'}], 'isLemma': 0, 'parents': ['vi_DA']},
	{'value': 'vidām', 'label': 'vidAm', 'attrib': [{'pos': 'ppf_actn', 'defs': 'know'}], 'isLemma': 0, 'parents': ['vid']},
	{'value': 'vidita', 'label': 'vidita', 'attrib': [{'pos': 'ppp', 'defs': 'know'}], 'isLemma': 0, 'parents': ['vid']},
	{'value': 'viditavat', 'label': 'viditavat', 'attrib': [{'pos': 'pap', 'defs': 'know'}], 'isLemma': 0, 'parents': ['vid']},
	{'value': 'viditvā', 'label': 'viditvA', 'attrib': [{'pos': 'abs', 'defs': 'know'}, {'pos': 'abs', 'defs': 'find, get'}], 'isLemma': 0, 'parents': ['vid']},
	{'value': 'vidudruvas', 'label': 'vidudruvas', 'attrib': [{'pos': 'pfap', 'defs': 'run apart, disperse'}], 'isLemma': 0, 'parents': ['vi_dru']},
	{'value': 'vidudruvāṇa', 'label': 'vidudruvARa', 'attrib': [{'pos': 'pfpp', 'defs': 'run apart, disperse'}], 'isLemma': 0, 'parents': ['vi_dru']},
	{'value': 'vidura', 'label': 'vidura', 'attrib': [{'pos': 'adj', 'defs': 'knowledgeable, wise'}, {'pos': 'm_pn', 'defs': 'Vidura'}], 'isLemma': 1},
	{'value': 'vidyamāna', 'label': 'vidyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'know'}, {'pos': 'prpp', 'defs': 'find, get'}], 'isLemma': 0, 'parents': ['vid']},
	{'value': 'vidravaṇīya', 'label': 'vidravaRIya', 'attrib': [{'pos': 'ger', 'defs': 'run apart, disperse'}], 'isLemma': 0, 'parents': ['vi_dru']},
	{'value': 'vidravat', 'label': 'vidravat', 'attrib': [{'pos': 'prap', 'defs': 'run apart, disperse'}], 'isLemma': 0, 'parents': ['vi_dru']},
	{'value': 'vidravya', 'label': 'vidravya', 'attrib': [{'pos': 'ger', 'defs': 'run apart, disperse'}], 'isLemma': 0, 'parents': ['vi_dru']},
	{'value': 'vidruta', 'label': 'vidruta', 'attrib': [{'pos': 'ppp', 'defs': 'run apart, disperse'}], 'isLemma': 0, 'parents': ['vi_dru']},
	{'value': 'vidrutabhūyiṣṭha', 'label': 'vidrutaBUyizWa', 'attrib': [{'pos': 'adj', 'defs': 'mostly destroyed'}], 'isLemma': 1},
	{'value': 'vidrutavat', 'label': 'vidrutavat', 'attrib': [{'pos': 'pap', 'defs': 'run apart, disperse'}], 'isLemma': 0, 'parents': ['vi_dru']},
	{'value': 'vidrutya', 'label': 'vidrutya', 'attrib': [{'pos': 'abs', 'defs': 'run apart, disperse'}], 'isLemma': 0, 'parents': ['vi_dru']},
	{'value': 'vidrūyamāṇa', 'label': 'vidrUyamARa', 'attrib': [{'pos': 'prpp', 'defs': 'run apart, disperse'}], 'isLemma': 0, 'parents': ['vi_dru']},
	{'value': 'vidrotavya', 'label': 'vidrotavya', 'attrib': [{'pos': 'ger', 'defs': 'run apart, disperse'}], 'isLemma': 0, 'parents': ['vi_dru']},
	{'value': 'vidrotum', 'label': 'vidrotum', 'attrib': [{'pos': 'inf', 'defs': 'run apart, disperse'}], 'isLemma': 0, 'parents': ['vi_dru']},
	{'value': 'vidroṣyat', 'label': 'vidrozyat', 'attrib': [{'pos': 'fap', 'defs': 'run apart, disperse'}], 'isLemma': 0, 'parents': ['vi_dru']},
	{'value': 'vidroṣyamāṇa', 'label': 'vidrozyamARa', 'attrib': [{'pos': 'fpp', 'defs': 'run apart, disperse'}], 'isLemma': 0, 'parents': ['vi_dru']},
	{'value': 'vidvas', 'label': 'vidvas', 'attrib': [{'pos': 'prap', 'defs': 'know'}], 'isLemma': 0, 'parents': ['vid']},
	{'value': 'vidhātavya', 'label': 'viDAtavya', 'attrib': [{'pos': 'ger', 'defs': 'bestow, supply, ordain'}], 'isLemma': 0, 'parents': ['vi_DA']},
	{'value': 'vidhātum', 'label': 'viDAtum', 'attrib': [{'pos': 'inf', 'defs': 'bestow, supply, ordain'}], 'isLemma': 0, 'parents': ['vi_DA']},
	{'value': 'vidhānīya', 'label': 'viDAnIya', 'attrib': [{'pos': 'ger', 'defs': 'bestow, supply, ordain'}], 'isLemma': 0, 'parents': ['vi_DA']},
	{'value': 'vidhāya', 'label': 'viDAya', 'attrib': [{'pos': 'abs', 'defs': 'bestow, supply, ordain'}], 'isLemma': 0, 'parents': ['vi_DA']},
	{'value': 'vidhāsyat', 'label': 'viDAsyat', 'attrib': [{'pos': 'fap', 'defs': 'bestow, supply, ordain'}], 'isLemma': 0, 'parents': ['vi_DA']},
	{'value': 'vidhāsyamāna', 'label': 'viDAsyamAna', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'bestow, supply, ordain'}], 'isLemma': 0, 'parents': ['vi_DA']},
	{'value': 'vidhīyamāna', 'label': 'viDIyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'bestow, supply, ordain'}], 'isLemma': 0, 'parents': ['vi_DA']},
	{'value': 'vidheya', 'label': 'viDeya', 'attrib': [{'pos': 'ger', 'defs': 'bestow, supply, ordain'}], 'isLemma': 0, 'parents': ['vi_DA']},
	{'value': 'vinaśanīya', 'label': 'vinaSanIya', 'attrib': [{'pos': 'ger', 'defs': 'perish'}], 'isLemma': 0, 'parents': ['vi_naS']},
	{'value': 'vinaśya', 'label': 'vinaSya', 'attrib': [{'pos': 'abs', 'defs': 'perish'}], 'isLemma': 0, 'parents': ['vi_naS']},
	{'value': 'vinaśyat', 'label': 'vinaSyat', 'attrib': [{'pos': 'prap', 'defs': 'perish'}], 'isLemma': 0, 'parents': ['vi_naS']},
	{'value': 'vinaṣṭa', 'label': 'vinazwa', 'attrib': [{'pos': 'ppp', 'defs': 'perish'}], 'isLemma': 0, 'parents': ['vi_naS']},
	{'value': 'vinaṣṭavat', 'label': 'vinazwavat', 'attrib': [{'pos': 'ppp', 'defs': 'perish'}], 'isLemma': 0, 'parents': ['vi_naS']},
	{'value': 'vinaṣṭavya', 'label': 'vinazwavya', 'attrib': [{'pos': 'ger', 'defs': 'perish'}], 'isLemma': 0, 'parents': ['vi_naS']},
	{'value': 'vinaṣṭum', 'label': 'vinazwum', 'attrib': [{'pos': 'inf', 'defs': 'perish'}], 'isLemma': 0, 'parents': ['vi_naS']},
	{'value': 'vināśa', 'label': 'vinASa', 'attrib': [{'pos': 'm', 'defs': 'destruction'}], 'isLemma': 1},
	{'value': 'vināśya', 'label': 'vinASya', 'attrib': [{'pos': 'ger', 'defs': 'perish'}], 'isLemma': 0, 'parents': ['vi_naS']},
	{'value': 'vinighātya', 'label': 'viniGAtya', 'attrib': [{'pos': 'ger', 'defs': 'strike down, destroy'}], 'isLemma': 0, 'parents': ['vi_ni_han']},
	{'value': 'vinighnat', 'label': 'viniGnat', 'attrib': [{'pos': 'prap', 'defs': 'strike down, destroy'}], 'isLemma': 0, 'parents': ['vi_ni_han']},
	{'value': 'vinijaghanvas', 'label': 'vinijaGanvas', 'attrib': [{'pos': 'pfap', 'defs': 'strike down, destroy'}], 'isLemma': 0, 'parents': ['vi_ni_han']},
	{'value': 'vinijaghnāna', 'label': 'vinijaGnAna', 'attrib': [{'pos': 'pfpp', 'defs': 'strike down, destroy'}], 'isLemma': 0, 'parents': ['vi_ni_han']},
	{'value': 'vinijaghnivas', 'label': 'vinijaGnivas', 'attrib': [{'pos': 'pfap', 'defs': 'strike down, destroy'}], 'isLemma': 0, 'parents': ['vi_ni_han']},
	{'value': 'vinihaṁsyat', 'label': 'vinihaMsyat', 'attrib': [{'pos': 'fap', 'defs': 'strike down, destroy'}], 'isLemma': 0, 'parents': ['vi_ni_han']},
	{'value': 'vinihaṁsyamāna', 'label': 'vinihaMsyamAna', 'attrib': [{'pos': 'fpp', 'defs': 'strike down, destroy'}], 'isLemma': 0, 'parents': ['vi_ni_han']},
	{'value': 'vinihata', 'label': 'vinihata', 'attrib': [{'pos': 'ppp', 'defs': 'strike down, destroy'}], 'isLemma': 0, 'parents': ['vi_ni_han']},
	{'value': 'vinihatavat', 'label': 'vinihatavat', 'attrib': [{'pos': 'pap', 'defs': 'strike down, destroy'}], 'isLemma': 0, 'parents': ['vi_ni_han']},
	{'value': 'vinihananīya', 'label': 'vinihananIya', 'attrib': [{'pos': 'ger', 'defs': 'strike down, destroy'}], 'isLemma': 0, 'parents': ['vi_ni_han']},
	{'value': 'vinihaniṣyat', 'label': 'vinihanizyat', 'attrib': [{'pos': 'fap', 'defs': 'strike down, destroy'}], 'isLemma': 0, 'parents': ['vi_ni_han']},
	{'value': 'vinihaniṣyamāṇa', 'label': 'vinihanizyamARa', 'attrib': [{'pos': 'fpp', 'defs': 'strike down, destroy'}], 'isLemma': 0, 'parents': ['vi_ni_han']},
	{'value': 'vinihantavya', 'label': 'vinihantavya', 'attrib': [{'pos': 'ger', 'defs': 'strike down, destroy'}], 'isLemma': 0, 'parents': ['vi_ni_han']},
	{'value': 'vinihantum', 'label': 'vinihantum', 'attrib': [{'pos': 'inf', 'defs': 'strike down, destroy'}], 'isLemma': 0, 'parents': ['vi_ni_han']},
	{'value': 'vinihanya', 'label': 'vinihanya', 'attrib': [{'pos': 'abs', 'defs': 'strike down, destroy'}], 'isLemma': 0, 'parents': ['vi_ni_han']},
	{'value': 'vinihanyamāna', 'label': 'vinihanyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'strike down, destroy'}], 'isLemma': 0, 'parents': ['vi_ni_han']},
	{'value': 'vineśivas', 'label': 'vineSivas', 'attrib': [{'pos': 'pfap', 'defs': 'perish'}], 'isLemma': 0, 'parents': ['vi_naS']},
	{'value': 'vineśvas', 'label': 'vineSvas', 'attrib': [{'pos': 'pfap', 'defs': 'perish'}], 'isLemma': 0, 'parents': ['vi_naS']},
	{'value': 'vindat', 'label': 'vindat', 'attrib': [{'pos': 'prap', 'defs': 'find, get'}], 'isLemma': 0, 'parents': ['vid']},
	{'value': 'vindamāna', 'label': 'vindamAna', 'attrib': [{'pos': 'prmp', 'defs': 'find, get'}], 'isLemma': 0, 'parents': ['vid']},
	{'value': 'viprakāra', 'label': 'viprakAra', 'attrib': [{'pos': 'm', 'defs': 'misdeed, offense'}], 'isLemma': 1},
	{'value': 'vibhakta', 'label': 'viBakta', 'attrib': [{'pos': 'adj', 'defs': 'divided, distributed'}, {'pos': 'ppp', 'defs': 'divide, distribute, apportion'}], 'isLemma': 1},
	{'value': 'vibhakta', 'label': 'viBakta', 'attrib': [{'pos': 'adj', 'defs': 'divided, distributed'}, {'pos': 'ppp', 'defs': 'divide, distribute, apportion'}], 'isLemma': 0, 'parents': ['vi_Baj']},
	{'value': 'vibhaktavat', 'label': 'viBaktavat', 'attrib': [{'pos': 'pap', 'defs': 'divide, distribute, apportion'}], 'isLemma': 0, 'parents': ['vi_Baj']},
	{'value': 'vibhaktavya', 'label': 'viBaktavya', 'attrib': [{'pos': 'ger', 'defs': 'divide, distribute, apportion'}], 'isLemma': 0, 'parents': ['vi_Baj']},
	{'value': 'vibhaktum', 'label': 'viBaktum', 'attrib': [{'pos': 'inf', 'defs': 'divide, distribute, apportion'}], 'isLemma': 0, 'parents': ['vi_Baj']},
	{'value': 'vibhakṣyat', 'label': 'viBakzyat', 'attrib': [{'pos': 'fap', 'defs': 'divide, distribute, apportion'}], 'isLemma': 0, 'parents': ['vi_Baj']},
	{'value': 'vibhakṣyamāna', 'label': 'viBakzyamAna', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'divide, distribute, apportion'}], 'isLemma': 0, 'parents': ['vi_Baj']},
	{'value': 'vibhajat', 'label': 'viBajat', 'attrib': [{'pos': 'prap', 'defs': 'divide, distribute, apportion'}], 'isLemma': 0, 'parents': ['vi_Baj']},
	{'value': 'vibhajanīya', 'label': 'viBajanIya', 'attrib': [{'pos': 'ger', 'defs': 'divide, distribute, apportion'}], 'isLemma': 0, 'parents': ['vi_Baj']},
	{'value': 'vibhajamāna', 'label': 'viBajamAna', 'attrib': [{'pos': 'prmp', 'defs': 'divide, distribute, apportion'}], 'isLemma': 0, 'parents': ['vi_Baj']},
	{'value': 'vibhajya', 'label': 'viBajya', 'attrib': [{'pos': 'ger', 'defs': 'divide, distribute, apportion'}], 'isLemma': 0, 'parents': ['vi_Baj']},
	{'value': 'vibhajyamāna', 'label': 'viBajyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'divide, distribute, apportion'}], 'isLemma': 0, 'parents': ['vi_Baj']},
	{'value': 'vibhejāna', 'label': 'viBejAna', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'divide, distribute, apportion'}], 'isLemma': 0, 'parents': ['vi_Baj']},
	{'value': 'vibhejivas', 'label': 'viBejivas', 'attrib': [{'pos': 'pfap', 'defs': 'divide, distribute, apportion'}], 'isLemma': 0, 'parents': ['vi_Baj']},
	{'value': 'virajamāna', 'label': 'virajamAna', 'attrib': [{'pos': 'prmp', 'defs': 'shine, rule'}], 'isLemma': 0, 'parents': ['vi_rAj']},
	{'value': 'virarājāna', 'label': 'virarAjAna', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'shine, rule'}], 'isLemma': 0, 'parents': ['vi_rAj']},
	{'value': 'virarājivas', 'label': 'virarAjivas', 'attrib': [{'pos': 'pfap', 'defs': 'shine, rule'}], 'isLemma': 0, 'parents': ['vi_rAj']},
	{'value': 'virājat', 'label': 'virAjat', 'attrib': [{'pos': 'prap', 'defs': 'shine, rule'}], 'isLemma': 0, 'parents': ['vi_rAj']},
	{'value': 'virājanīya', 'label': 'virAjanIya', 'attrib': [{'pos': 'ger', 'defs': 'shine, rule'}], 'isLemma': 0, 'parents': ['vi_rAj']},
	{'value': 'virājita', 'label': 'virAjita', 'attrib': [{'pos': 'ppp', 'defs': 'shine, rule'}], 'isLemma': 0, 'parents': ['vi_rAj']},
	{'value': 'virājitavat', 'label': 'virAjitavat', 'attrib': [{'pos': 'pap', 'defs': 'shine, rule'}], 'isLemma': 0, 'parents': ['vi_rAj']},
	{'value': 'virājitavya', 'label': 'virAjitavya', 'attrib': [{'pos': 'ger', 'defs': 'shine, rule'}], 'isLemma': 0, 'parents': ['vi_rAj']},
	{'value': 'virājitum', 'label': 'virAjitum', 'attrib': [{'pos': 'inf', 'defs': 'shine, rule'}], 'isLemma': 0, 'parents': ['vi_rAj']},
	{'value': 'virājiṣyat', 'label': 'virAjizyat', 'attrib': [{'pos': 'fap', 'defs': 'shine, rule'}], 'isLemma': 0, 'parents': ['vi_rAj']},
	{'value': 'virājiṣyamāṇa', 'label': 'virAjizyamARa', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'shine, rule'}], 'isLemma': 0, 'parents': ['vi_rAj']},
	{'value': 'virājya', 'label': 'virAjya', 'attrib': [{'pos': 'abs', 'defs': 'shine, rule'}, {'pos': 'ger', 'defs': 'shine, rule'}], 'isLemma': 0, 'parents': ['vi_rAj']},
	{'value': 'virājyamāna', 'label': 'virAjyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'shine, rule'}], 'isLemma': 0, 'parents': ['vi_rAj']},
	{'value': 'vivaraṇīya', 'label': 'vivaraRIya', 'attrib': [{'pos': 'ger', 'defs': 'uncover, reveal, explain'}], 'isLemma': 0, 'parents': ['vi_vf']},
	{'value': 'vivaritavya', 'label': 'vivaritavya', 'attrib': [{'pos': 'ger', 'defs': 'uncover, reveal, explain'}], 'isLemma': 0, 'parents': ['vi_vf']},
	{'value': 'vivaritum', 'label': 'vivaritum', 'attrib': [{'pos': 'inf', 'defs': 'uncover, reveal, explain'}], 'isLemma': 0, 'parents': ['vi_vf']},
	{'value': 'vivariṣyat', 'label': 'vivarizyat', 'attrib': [{'pos': 'fap', 'defs': 'uncover, reveal, explain'}], 'isLemma': 0, 'parents': ['vi_vf']},
	{'value': 'vivariṣyamāṇa', 'label': 'vivarizyamARa', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'uncover, reveal, explain'}], 'isLemma': 0, 'parents': ['vi_vf']},
	{'value': 'vivarītavya', 'label': 'vivarItavya', 'attrib': [{'pos': 'ger', 'defs': 'uncover, reveal, explain'}], 'isLemma': 0, 'parents': ['vi_vf']},
	{'value': 'vivarītum', 'label': 'vivarItum', 'attrib': [{'pos': 'inf', 'defs': 'uncover, reveal, explain'}], 'isLemma': 0, 'parents': ['vi_vf']},
	{'value': 'vivarīṣyat', 'label': 'vivarIzyat', 'attrib': [{'pos': 'fap', 'defs': 'uncover, reveal, explain'}], 'isLemma': 0, 'parents': ['vi_vf']},
	{'value': 'vivarīṣyamāṇa', 'label': 'vivarIzyamARa', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'uncover, reveal, explain'}], 'isLemma': 0, 'parents': ['vi_vf']},
	{'value': 'vivavṛvas', 'label': 'vivavfvas', 'attrib': [{'pos': 'pfap', 'defs': 'uncover, reveal, explain'}], 'isLemma': 0, 'parents': ['vi_vf']},
	{'value': 'vivavrāṇa', 'label': 'vivavrARa', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'uncover, reveal, explain'}], 'isLemma': 0, 'parents': ['vi_vf']},
	{'value': 'vividāna', 'label': 'vividAna', 'attrib': [{'pos': 'pfpp', 'defs': 'know'}, {'pos': 'pfmp, pfpp', 'defs': 'find, get'}], 'isLemma': 0, 'parents': ['vid']},
	{'value': 'vividivas', 'label': 'vividivas', 'attrib': [{'pos': 'pfap', 'defs': 'find, get'}], 'isLemma': 0, 'parents': ['vid']},
	{'value': 'vividvas', 'label': 'vividvas', 'attrib': [{'pos': 'pfap', 'defs': 'know'}, {'pos': 'pfap', 'defs': 'find, get'}], 'isLemma': 0, 'parents': ['vid']},
	{'value': 'vividha', 'label': 'viviDa', 'attrib': [{'pos': 'adj', 'defs': 'various'}], 'isLemma': 1},
	{'value': 'vividhopāya', 'label': 'viviDopAya', 'attrib': [{'pos': 'm', 'defs': 'various means'}], 'isLemma': 1},
	{'value': 'vivṛṇvat', 'label': 'vivfRvat', 'attrib': [{'pos': 'prap', 'defs': 'uncover, reveal, explain'}], 'isLemma': 0, 'parents': ['vi_vf']},
	{'value': 'vivṛṇvāna', 'label': 'vivfRvAna', 'attrib': [{'pos': 'prmp', 'defs': 'uncover, reveal, explain'}], 'isLemma': 0, 'parents': ['vi_vf']},
	{'value': 'vivṛta', 'label': 'vivfta', 'attrib': [{'pos': 'ppp', 'defs': 'uncover, reveal, explain'}], 'isLemma': 0, 'parents': ['vi_vf']},
	{'value': 'vivṛtavat', 'label': 'vivftavat', 'attrib': [{'pos': 'pap', 'defs': 'uncover, reveal, explain'}], 'isLemma': 0, 'parents': ['vi_vf']},
	{'value': 'vivṛtya', 'label': 'vivftya', 'attrib': [{'pos': 'ger', 'defs': 'uncover, reveal, explain'}], 'isLemma': 0, 'parents': ['vi_vf']},
	{'value': 'vivṛtvā', 'label': 'vivftvA', 'attrib': [{'pos': 'abs', 'defs': 'uncover, reveal, explain'}], 'isLemma': 0, 'parents': ['vi_vf']},
	{'value': 'vivyathāna', 'label': 'vivyaTAna', 'attrib': [{'pos': 'pfpp', 'defs': 'tremble, fear'}], 'isLemma': 0, 'parents': ['vyaT']},
	{'value': 'vivyathivas', 'label': 'vivyaTivas', 'attrib': [{'pos': 'pfap', 'defs': 'tremble, fear'}], 'isLemma': 0, 'parents': ['vyaT']},
	{'value': 'vivriyamāṇa', 'label': 'vivriyamARa', 'attrib': [{'pos': 'prpp', 'defs': 'uncover, reveal, explain'}], 'isLemma': 0, 'parents': ['vi_vf']},
	{'value': 'viś', 'label': 'viS', 'attrib': [{'pos': 'm', 'defs': 'settler, people'}], 'isLemma': 1},
	{'value': 'viśaṁpa', 'label': 'viSaMpa', 'attrib': [{'pos': 'adj', 'defs': 'people-protecting'}], 'isLemma': 1},
	{'value': 'viśaśrambhāṇa', 'label': 'viSaSramBARa', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'be careless, negligent'}], 'isLemma': 0, 'parents': ['vi_SramB']},
	{'value': 'viśṛṇvat', 'label': 'viSfRvat', 'attrib': [{'pos': 'prap', 'defs': 'hear widely'}], 'isLemma': 0, 'parents': ['vi_Sru']},
	{'value': 'viśrabdha', 'label': 'viSrabDa', 'attrib': [{'pos': 'ppp', 'defs': 'be careless, negligent'}], 'isLemma': 0, 'parents': ['vi_SramB']},
	{'value': 'viśrabdhavat', 'label': 'viSrabDavat', 'attrib': [{'pos': 'pap', 'defs': 'be careless, negligent'}], 'isLemma': 0, 'parents': ['vi_SramB']},
	{'value': 'viśrabhya', 'label': 'viSraBya', 'attrib': [{'pos': 'abs', 'defs': 'be careless, negligent'}], 'isLemma': 0, 'parents': ['vi_SramB']},
	{'value': 'viśrabhyamāṇa', 'label': 'viSraByamARa', 'attrib': [{'pos': 'prpp', 'defs': 'be careless, negligent'}], 'isLemma': 0, 'parents': ['vi_SramB']},
	{'value': 'viśrambhaṇīya', 'label': 'viSramBaRIya', 'attrib': [{'pos': 'ger', 'defs': 'be careless, negligent'}], 'isLemma': 0, 'parents': ['vi_SramB']},
	{'value': 'viśrambhamāṇa', 'label': 'viSramBamARa', 'attrib': [{'pos': 'prmp', 'defs': 'be careless, negligent'}], 'isLemma': 0, 'parents': ['vi_SramB']},
	{'value': 'viśrambhitavya', 'label': 'viSramBitavya', 'attrib': [{'pos': 'ger', 'defs': 'be careless, negligent'}], 'isLemma': 0, 'parents': ['vi_SramB']},
	{'value': 'viśrambhitum', 'label': 'viSramBitum', 'attrib': [{'pos': 'inf', 'defs': 'be careless, negligent'}], 'isLemma': 0, 'parents': ['vi_SramB']},
	{'value': 'viśrambhiṣyamāṇa', 'label': 'viSramBizyamARa', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'be careless, negligent'}], 'isLemma': 0, 'parents': ['vi_SramB']},
	{'value': 'viśrambhya', 'label': 'viSramBya', 'attrib': [{'pos': 'ger', 'defs': 'be careless, negligent'}], 'isLemma': 0, 'parents': ['vi_SramB']},
	{'value': 'viśravaṇīya', 'label': 'viSravaRIya', 'attrib': [{'pos': 'ger', 'defs': 'hear widely'}], 'isLemma': 0, 'parents': ['vi_Sru']},
	{'value': 'viśravya', 'label': 'viSravya', 'attrib': [{'pos': 'ger', 'defs': 'hear widely'}], 'isLemma': 0, 'parents': ['vi_Sru']},
	{'value': 'viśruta', 'label': 'viSruta', 'attrib': [{'pos': 'ppp', 'defs': 'hear widely'}], 'isLemma': 0, 'parents': ['vi_Sru']},
	{'value': 'viśrutavat', 'label': 'viSrutavat', 'attrib': [{'pos': 'pap', 'defs': 'hear widely'}], 'isLemma': 0, 'parents': ['vi_Sru']},
	{'value': 'viśrutya', 'label': 'viSrutya', 'attrib': [{'pos': 'abs', 'defs': 'hear widely'}], 'isLemma': 0, 'parents': ['vi_Sru']},
	{'value': 'viśrūyamāṇa', 'label': 'viSrUyamARa', 'attrib': [{'pos': 'prpp', 'defs': 'hear widely'}], 'isLemma': 0, 'parents': ['vi_Sru']},
	{'value': 'viśrotavya', 'label': 'viSrotavya', 'attrib': [{'pos': 'ger', 'defs': 'hear widely'}], 'isLemma': 0, 'parents': ['vi_Sru']},
	{'value': 'viśrotum', 'label': 'viSrotum', 'attrib': [{'pos': 'inf', 'defs': 'hear widely'}], 'isLemma': 0, 'parents': ['vi_Sru']},
	{'value': 'viṣa', 'label': 'viza', 'attrib': [{'pos': 'adj', 'defs': 'active, poisonous'}, {'pos': 'n', 'defs': 'poison'}], 'isLemma': 1},
	{'value': 'viṣṇu', 'label': 'vizRu', 'attrib': [{'pos': 'm_pn', 'defs': 'Viṣṇu'}], 'isLemma': 1},
	{'value': 'visasrambhāṇa', 'label': 'visasramBARa', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'be confident, trust, relax'}], 'isLemma': 0, 'parents': ['vi_sramB']},
	{'value': 'visrabdha', 'label': 'visrabDa', 'attrib': [{'pos': 'ppp', 'defs': 'be confident, trust, relax'}], 'isLemma': 0, 'parents': ['vi_sramB']},
	{'value': 'visrabdhavat', 'label': 'visrabDavat', 'attrib': [{'pos': 'pap', 'defs': 'be confident, trust, relax'}], 'isLemma': 0, 'parents': ['vi_sramB']},
	{'value': 'visrabhya', 'label': 'visraBya', 'attrib': [{'pos': 'abs', 'defs': 'be confident, trust, relax'}], 'isLemma': 0, 'parents': ['vi_sramB']},
	{'value': 'visrabhyamāṇa', 'label': 'visraByamARa', 'attrib': [{'pos': 'prpp', 'defs': 'be confident, trust, relax'}], 'isLemma': 0, 'parents': ['vi_sramB']},
	{'value': 'visrambhaṇīya', 'label': 'visramBaRIya', 'attrib': [{'pos': 'ger', 'defs': 'be confident, trust, relax'}], 'isLemma': 0, 'parents': ['vi_sramB']},
	{'value': 'visrambhamāṇa', 'label': 'visramBamARa', 'attrib': [{'pos': 'prmp', 'defs': 'be confident, trust, relax'}], 'isLemma': 0, 'parents': ['vi_sramB']},
	{'value': 'visrambhitavya', 'label': 'visramBitavya', 'attrib': [{'pos': 'ger', 'defs': 'be confident, trust, relax'}], 'isLemma': 0, 'parents': ['vi_sramB']},
	{'value': 'visrambhitum', 'label': 'visramBitum', 'attrib': [{'pos': 'inf', 'defs': 'be confident, trust, relax'}], 'isLemma': 0, 'parents': ['vi_sramB']},
	{'value': 'visrambhiṣyamāṇa', 'label': 'visramBizyamARa', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'be confident, trust, relax'}], 'isLemma': 0, 'parents': ['vi_sramB']},
	{'value': 'visrambhya', 'label': 'visramBya', 'attrib': [{'pos': 'ger', 'defs': 'be confident, trust, relax'}], 'isLemma': 0, 'parents': ['vi_sramB']},
	{'value': 'vihita', 'label': 'vihita', 'attrib': [{'pos': 'ppp', 'defs': 'bestow, supply, ordain'}], 'isLemma': 0, 'parents': ['vi_DA']},
	{'value': 'vihitavat', 'label': 'vihitavat', 'attrib': [{'pos': 'pap', 'defs': 'bestow, supply, ordain'}], 'isLemma': 0, 'parents': ['vi_DA']},
	{'value': 'vīra', 'label': 'vIra', 'attrib': [{'pos': 'adj', 'defs': 'heroic'}, {'pos': 'm', 'defs': 'hero'}], 'isLemma': 1},
	{'value': 'vīrya', 'label': 'vIrya', 'attrib': [{'pos': 'n', 'defs': 'virility, power'}], 'isLemma': 1},
	{'value': 'vṛ', 'label': 'vf', 'attrib': [{'pos': 'vt5am', 'defs': 'cover'}, {'pos': 'vt10am', 'defs': 'block'}, {'pos': 'vt9m', 'defs': 'choose'}], 'isLemma': 1},
	{'value': 'vṛka', 'label': 'vfka', 'attrib': [{'pos': 'm', 'defs': 'wolf'}], 'isLemma': 1},
	{'value': 'vṛkodara', 'label': 'vfkodara', 'attrib': [{'pos': 'adj', 'defs': 'wolf-bellied'}], 'isLemma': 1},
	{'value': 'vṛṇāna', 'label': 'vfRAna', 'attrib': [{'pos': 'prmp', 'defs': 'choose'}], 'isLemma': 0, 'parents': ['vf']},
	{'value': 'vṛṇvat', 'label': 'vfRvat', 'attrib': [{'pos': 'prap', 'defs': 'cover'}], 'isLemma': 0, 'parents': ['vf']},
	{'value': 'vṛṇvāna', 'label': 'vfRvAna', 'attrib': [{'pos': 'prmp', 'defs': 'cover'}], 'isLemma': 0, 'parents': ['vf']},
	{'value': 'vṛt', 'label': 'vft', 'attrib': [{'pos': 'vi1m', 'defs': 'occur, be present'}], 'isLemma': 1},
	{'value': 'vṛta', 'label': 'vfta', 'attrib': [{'pos': 'ppp', 'defs': 'cover'}, {'pos': 'ppp', 'defs': 'choose'}], 'isLemma': 0, 'parents': ['vf']},
	{'value': 'vṛtavat', 'label': 'vftavat', 'attrib': [{'pos': 'pap', 'defs': 'cover'}, {'pos': 'pap', 'defs': 'choose'}], 'isLemma': 0, 'parents': ['vf']},
	{'value': 'vṛtta', 'label': 'vftta', 'attrib': [{'pos': 'ppp', 'defs': 'occur, be present'}, {'pos': 'n', 'defs': 'occurrence, event'}], 'isLemma': 1},
	{'value': 'vṛtta', 'label': 'vftta', 'attrib': [{'pos': 'ppp', 'defs': 'occur, be present'}, {'pos': 'n', 'defs': 'occurrence, event'}], 'isLemma': 0, 'parents': ['vft']},
	{'value': 'vṛttavat', 'label': 'vfttavat', 'attrib': [{'pos': 'pap', 'defs': 'occur, be present'}], 'isLemma': 0, 'parents': ['vft']},
	{'value': 'vṛtya', 'label': 'vftya', 'attrib': [{'pos': 'ger', 'defs': 'cover'}, {'pos': 'ger', 'defs': 'occur, be present'}], 'isLemma': 0, 'parents': ['vf', 'vft']},
	{'value': 'vṛtvā', 'label': 'vftvA', 'attrib': [{'pos': 'abs', 'defs': 'cover'}, {'pos': 'abs', 'defs': 'choose'}], 'isLemma': 0, 'parents': ['vf']},
	{'value': 'vṛṣ', 'label': 'vfz', 'attrib': [{'pos': 'vi1a', 'defs': 'rain'}], 'isLemma': 1},
	{'value': 'vṛṣaduḥśāsana', 'label': 'vfzaduHSAsana', 'attrib': [{'pos': 'm', 'defs': 'Karṇa and Duḥśāsana'}], 'isLemma': 1},
	{'value': 'vṛṣaduḥśāsanādi', 'label': 'vfzaduHSAsanAdi', 'attrib': [{'pos': 'adj', 'defs': 'beginning with Karṇa and Duḥśāsana'}], 'isLemma': 1},
	{'value': 'vṛṣan', 'label': 'vfzan', 'attrib': [{'pos': 'm', 'defs': 'bull'}], 'isLemma': 1},
	{'value': 'vṛṣṭa', 'label': 'vfzwa', 'attrib': [{'pos': 'ppp', 'defs': 'rain'}], 'isLemma': 0, 'parents': ['vfz']},
	{'value': 'vṛṣṭavat', 'label': 'vfzwavat', 'attrib': [{'pos': 'pap', 'defs': 'rain'}], 'isLemma': 0, 'parents': ['vfz']},
	{'value': 'vṛṣṭvā', 'label': 'vfzwvA', 'attrib': [{'pos': 'abs', 'defs': 'rain'}], 'isLemma': 0, 'parents': ['vfz']},
	{'value': 'vṛṣya', 'label': 'vfzya', 'attrib': [{'pos': 'ger', 'defs': 'rain'}], 'isLemma': 0, 'parents': ['vfz']},
	{'value': 'vṛṣyamāṇa', 'label': 'vfzyamARa', 'attrib': [{'pos': 'prpp', 'defs': 'rain'}], 'isLemma': 0, 'parents': ['vfz']},
	{'value': 'vettavya', 'label': 'vettavya', 'attrib': [{'pos': 'ger', 'defs': 'find, get'}], 'isLemma': 0, 'parents': ['vid']},
	{'value': 'vettum', 'label': 'vettum', 'attrib': [{'pos': 'inf', 'defs': 'find, get'}], 'isLemma': 0, 'parents': ['vid']},
	{'value': 'veda', 'label': 'veda', 'attrib': [{'pos': 'm', 'defs': 'knowledge, Vedic texts'}], 'isLemma': 1},
	{'value': 'vedanīya', 'label': 'vedanIya', 'attrib': [{'pos': 'ger', 'defs': 'know'}, {'pos': 'ger', 'defs': 'find, get'}], 'isLemma': 0, 'parents': ['vid']},
	{'value': 'veditavya', 'label': 'veditavya', 'attrib': [{'pos': 'ger', 'defs': 'know'}], 'isLemma': 0, 'parents': ['vid']},
	{'value': 'veditum', 'label': 'veditum', 'attrib': [{'pos': 'inf', 'defs': 'know'}], 'isLemma': 0, 'parents': ['vid']},
	{'value': 'veditvā', 'label': 'veditvA', 'attrib': [{'pos': 'abs', 'defs': 'find, get'}], 'isLemma': 0, 'parents': ['vid']},
	{'value': 'vediṣyat', 'label': 'vedizyat', 'attrib': [{'pos': 'fap', 'defs': 'know'}, {'pos': 'fap', 'defs': 'find, get'}], 'isLemma': 0, 'parents': ['vid']},
	{'value': 'vediṣyamāṇa', 'label': 'vedizyamARa', 'attrib': [{'pos': 'fpp', 'defs': 'know'}, {'pos': 'fmp, fpp', 'defs': 'find, get'}], 'isLemma': 0, 'parents': ['vid']},
	{'value': 'vedya', 'label': 'vedya', 'attrib': [{'pos': 'ger', 'defs': 'know'}, {'pos': 'ger', 'defs': 'find, get'}], 'isLemma': 0, 'parents': ['vid']},
	{'value': 'vai', 'label': 'vE', 'attrib': [{'pos': 'pcl', 'defs': 'verily'}], 'isLemma': 1},
	{'value': 'vaiśaṁpāyana', 'label': 'vESaMpAyana', 'attrib': [{'pos': 'm_pn', 'defs': 'Vaiśaṁpāyana'}], 'isLemma': 1},
	{'value': 'voḍhavya', 'label': 'voQavya', 'attrib': [{'pos': 'ger', 'defs': 'carry, flow, wed'}], 'isLemma': 0, 'parents': ['vah']},
	{'value': 'voḍhum', 'label': 'voQum', 'attrib': [{'pos': 'inf', 'defs': 'carry, flow, wed'}], 'isLemma': 0, 'parents': ['vah']},
	{'value': 'vyath', 'label': 'vyaT', 'attrib': [{'pos': 'vi1m', 'defs': 'tremble, fear'}], 'isLemma': 1},
	{'value': 'vyathanīya', 'label': 'vyaTanIya', 'attrib': [{'pos': 'ger', 'defs': 'tremble, fear'}], 'isLemma': 0, 'parents': ['vyaT']},
	{'value': 'vyathamāna', 'label': 'vyaTamAna', 'attrib': [{'pos': 'prmp', 'defs': 'tremble, fear'}], 'isLemma': 0, 'parents': ['vyaT']},
	{'value': 'vyathā', 'label': 'vyaTA', 'attrib': [{'pos': 'f', 'defs': 'trembling, fear'}], 'isLemma': 1},
	{'value': 'vyathita', 'label': 'vyaTita', 'attrib': [{'pos': 'ppp', 'defs': 'tremble, fear'}], 'isLemma': 0, 'parents': ['vyaT']},
	{'value': 'vyathitavat', 'label': 'vyaTitavat', 'attrib': [{'pos': 'pap', 'defs': 'tremble, fear'}], 'isLemma': 0, 'parents': ['vyaT']},
	{'value': 'vyathitavya', 'label': 'vyaTitavya', 'attrib': [{'pos': 'ger', 'defs': 'tremble, fear'}], 'isLemma': 0, 'parents': ['vyaT']},
	{'value': 'vyathitum', 'label': 'vyaTitum', 'attrib': [{'pos': 'inf', 'defs': 'tremble, fear'}], 'isLemma': 0, 'parents': ['vyaT']},
	{'value': 'vyathitvā', 'label': 'vyaTitvA', 'attrib': [{'pos': 'abs', 'defs': 'tremble, fear'}], 'isLemma': 0, 'parents': ['vyaT']},
	{'value': 'vyathiṣyamāṇa', 'label': 'vyaTizyamARa', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'tremble, fear'}], 'isLemma': 0, 'parents': ['vyaT']},
	{'value': 'vyathya', 'label': 'vyaTya', 'attrib': [{'pos': 'ger', 'defs': 'tremble, fear'}], 'isLemma': 0, 'parents': ['vyaT']},
	{'value': 'vyathyamāna', 'label': 'vyaTyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'tremble, fear'}], 'isLemma': 0, 'parents': ['vyaT']},
	{'value': 'vyavasasāna', 'label': 'vyavasasAna', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'conclude, determine'}], 'isLemma': 0, 'parents': ['vi_ava_so']},
	{'value': 'vyavasasivas', 'label': 'vyavasasivas', 'attrib': [{'pos': 'pfap', 'defs': 'conclude, determine'}], 'isLemma': 0, 'parents': ['vi_ava_so']},
	{'value': 'vyavasātavya', 'label': 'vyavasAtavya', 'attrib': [{'pos': 'ger', 'defs': 'conclude, determine'}], 'isLemma': 0, 'parents': ['vi_ava_so']},
	{'value': 'vyavasātum', 'label': 'vyavasAtum', 'attrib': [{'pos': 'inf', 'defs': 'conclude, determine'}], 'isLemma': 0, 'parents': ['vi_ava_so']},
	{'value': 'vyavasānīya', 'label': 'vyavasAnIya', 'attrib': [{'pos': 'ger', 'defs': 'conclude, determine'}], 'isLemma': 0, 'parents': ['vi_ava_so']},
	{'value': 'vyavasāya', 'label': 'vyavasAya', 'attrib': [{'pos': 'abs', 'defs': 'conclude, determine'}, {'pos': 'm', 'defs': 'resolution, determination'}], 'isLemma': 1},
	{'value': 'vyavasāya', 'label': 'vyavasAya', 'attrib': [{'pos': 'abs', 'defs': 'conclude, determine'}, {'pos': 'm', 'defs': 'resolution, determination'}], 'isLemma': 0, 'parents': ['vi_ava_so']},
	{'value': 'vyavasāyasahāya', 'label': 'vyavasAyasahAya', 'attrib': [{'pos': 'adj', 'defs': 'resolute'}], 'isLemma': 1},
	{'value': 'vyavasāsyat', 'label': 'vyavasAsyat', 'attrib': [{'pos': 'fap', 'defs': 'conclude, determine'}], 'isLemma': 0, 'parents': ['vi_ava_so']},
	{'value': 'vyavasāsyamāna', 'label': 'vyavasAsyamAna', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'conclude, determine'}], 'isLemma': 0, 'parents': ['vi_ava_so']},
	{'value': 'vyavasita', 'label': 'vyavasita', 'attrib': [{'pos': 'ppp', 'defs': 'conclude, determine'}], 'isLemma': 0, 'parents': ['vi_ava_so']},
	{'value': 'vyavasitavat', 'label': 'vyavasitavat', 'attrib': [{'pos': 'pap', 'defs': 'conclude, determine'}], 'isLemma': 0, 'parents': ['vi_ava_so']},
	{'value': 'vyavasīyamāna', 'label': 'vyavasIyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'conclude, determine'}], 'isLemma': 0, 'parents': ['vi_ava_so']},
	{'value': 'vyavaseya', 'label': 'vyavaseya', 'attrib': [{'pos': 'ger', 'defs': 'conclude, determine'}], 'isLemma': 0, 'parents': ['vi_ava_so']},
	{'value': 'vyavasyat', 'label': 'vyavasyat', 'attrib': [{'pos': 'prap', 'defs': 'conclude, determine'}], 'isLemma': 0, 'parents': ['vi_ava_so']},
	{'value': 'vyavasyamāna', 'label': 'vyavasyamAna', 'attrib': [{'pos': 'prmp', 'defs': 'conclude, determine'}], 'isLemma': 0, 'parents': ['vi_ava_so']},
	{'value': 'vyāsa', 'label': 'vyAsa', 'attrib': [{'pos': 'm_pn', 'defs': 'Vyāsa'}], 'isLemma': 1},
	{'value': 'vraj', 'label': 'vraj', 'attrib': [{'pos': 'vt1a', 'defs': 'walk'}], 'isLemma': 1},
	{'value': 'vrajat', 'label': 'vrajat', 'attrib': [{'pos': 'prap', 'defs': 'walk'}], 'isLemma': 0, 'parents': ['vraj']},
	{'value': 'vrajanīya', 'label': 'vrajanIya', 'attrib': [{'pos': 'ger', 'defs': 'walk'}], 'isLemma': 0, 'parents': ['vraj']},
	{'value': 'vrajita', 'label': 'vrajita', 'attrib': [{'pos': 'ppp', 'defs': 'walk'}], 'isLemma': 0, 'parents': ['vraj']},
	{'value': 'vrajitavat', 'label': 'vrajitavat', 'attrib': [{'pos': 'pap', 'defs': 'walk'}], 'isLemma': 0, 'parents': ['vraj']},
	{'value': 'vrajitavya', 'label': 'vrajitavya', 'attrib': [{'pos': 'ger', 'defs': 'walk'}], 'isLemma': 0, 'parents': ['vraj']},
	{'value': 'vrajitum', 'label': 'vrajitum', 'attrib': [{'pos': 'inf', 'defs': 'walk'}], 'isLemma': 0, 'parents': ['vraj']},
	{'value': 'vrajitvā', 'label': 'vrajitvA', 'attrib': [{'pos': 'abs', 'defs': 'walk'}], 'isLemma': 0, 'parents': ['vraj']},
	{'value': 'vrajiṣyat', 'label': 'vrajizyat', 'attrib': [{'pos': 'fap', 'defs': 'walk'}], 'isLemma': 0, 'parents': ['vraj']},
	{'value': 'vrajiṣyamāṇa', 'label': 'vrajizyamARa', 'attrib': [{'pos': 'fpp', 'defs': 'walk'}], 'isLemma': 0, 'parents': ['vraj']},
	{'value': 'vrajyamāna', 'label': 'vrajyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'walk'}], 'isLemma': 0, 'parents': ['vraj']},
	{'value': 'vrata', 'label': 'vrata', 'attrib': [{'pos': 'n', 'defs': 'vow'}], 'isLemma': 1},
	{'value': 'vrājya', 'label': 'vrAjya', 'attrib': [{'pos': 'ger', 'defs': 'walk'}], 'isLemma': 0, 'parents': ['vraj']},
	{'value': 'vriyamāṇa', 'label': 'vriyamARa', 'attrib': [{'pos': 'prpp', 'defs': 'cover'}, {'pos': 'prpp', 'defs': 'choose'}], 'isLemma': 0, 'parents': ['vf']},
	{'value': 'śaṁtanu', 'label': 'SaMtanu', 'attrib': [{'pos': 'm', 'defs': 'Śaṁtanu'}], 'isLemma': 1},
	{'value': 'śak', 'label': 'Sak', 'attrib': [{'pos': 'vi5a', 'defs': 'be able'}], 'isLemma': 1},
	{'value': 'śakanīya', 'label': 'SakanIya', 'attrib': [{'pos': 'ger', 'defs': 'be able'}], 'isLemma': 0, 'parents': ['Sak']},
	{'value': 'śakuni', 'label': 'Sakuni', 'attrib': [{'pos': 'm_pn', 'defs': 'Śakuni'}], 'isLemma': 1},
	{'value': 'śakta', 'label': 'Sakta', 'attrib': [{'pos': 'ppp', 'defs': 'be able'}], 'isLemma': 0, 'parents': ['Sak']},
	{'value': 'śaktavat', 'label': 'Saktavat', 'attrib': [{'pos': 'pap', 'defs': 'be able'}], 'isLemma': 0, 'parents': ['Sak']},
	{'value': 'śaktavya', 'label': 'Saktavya', 'attrib': [{'pos': 'ger', 'defs': 'be able'}], 'isLemma': 0, 'parents': ['Sak']},
	{'value': 'śaktum', 'label': 'Saktum', 'attrib': [{'pos': 'inf', 'defs': 'be able'}], 'isLemma': 0, 'parents': ['Sak']},
	{'value': 'śaktvā', 'label': 'SaktvA', 'attrib': [{'pos': 'abs', 'defs': 'be able'}], 'isLemma': 0, 'parents': ['Sak']},
	{'value': 'śaknuvat', 'label': 'Saknuvat', 'attrib': [{'pos': 'prap', 'defs': 'be able'}], 'isLemma': 0, 'parents': ['Sak']},
	{'value': 'śakya', 'label': 'Sakya', 'attrib': [{'pos': 'ger', 'defs': 'be able'}], 'isLemma': 0, 'parents': ['Sak']},
	{'value': 'śakyamāna', 'label': 'SakyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'be able'}], 'isLemma': 0, 'parents': ['Sak']},
	{'value': 'śakra', 'label': 'Sakra', 'attrib': [{'pos': 'adj', 'defs': 'able, capable, powerful'}, {'pos': 'm', 'defs': 'epithet of Indra'}], 'isLemma': 1},
	{'value': 'śacī', 'label': 'SacI', 'attrib': [{'pos': 'f', 'defs': 'strength'}, {'pos': 'f_pn', 'defs': 'Śacī'}], 'isLemma': 1},
	{'value': 'śatru', 'label': 'Satru', 'attrib': [{'pos': 'm', 'defs': 'feller, enemy'}], 'isLemma': 1},
	{'value': 'śatruvadha', 'label': 'SatruvaDa', 'attrib': [{'pos': 'm', 'defs': 'slaughter of enemies'}], 'isLemma': 1},
	{'value': 'śatruhan', 'label': 'Satruhan', 'attrib': [{'pos': 'm', 'defs': 'enemy-slayer'}], 'isLemma': 1},
	{'value': 'śam', 'label': 'Sam', 'attrib': [{'pos': 'n', 'defs': 'peace, happiness'}, {'pos': 'pcl', 'defs': 'auspiciously, happily'}], 'isLemma': 1},
	{'value': 'śaśāsāna', 'label': 'SaSAsAna', 'attrib': [{'pos': 'pfpp', 'defs': 'rule, govern, teach'}], 'isLemma': 0, 'parents': ['SAs']},
	{'value': 'śaśāsvas', 'label': 'SaSAsvas', 'attrib': [{'pos': 'pfap', 'defs': 'rule, govern, teach'}], 'isLemma': 0, 'parents': ['SAs']},
	{'value': 'śaśrambhāṇa', 'label': 'SaSramBARa', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'be careless, negligent'}], 'isLemma': 0, 'parents': ['SramB']},
	{'value': 'śastra', 'label': 'Sastra', 'attrib': [{'pos': 'n', 'defs': 'sword'}], 'isLemma': 1},
	{'value': 'śastrapratāpa', 'label': 'SastrapratApa', 'attrib': [{'pos': 'm', 'defs': 'blazing of swords'}], 'isLemma': 1},
	{'value': 'śāṁtanava', 'label': 'SAMtanava', 'attrib': [{'pos': 'm', 'defs': 'son of Śaṁtanu'}], 'isLemma': 1},
	{'value': 'śālā', 'label': 'SAlA', 'attrib': [{'pos': 'f', 'defs': 'hall'}], 'isLemma': 1},
	{'value': 'śās', 'label': 'SAs', 'attrib': [{'pos': 'vt2a', 'defs': 'rule, govern, teach'}], 'isLemma': 1},
	{'value': 'śāsat', 'label': 'SAsat', 'attrib': [{'pos': 'prap', 'defs': 'rule, govern, teach'}], 'isLemma': 0, 'parents': ['SAs']},
	{'value': 'śāsanīya', 'label': 'SAsanIya', 'attrib': [{'pos': 'ger', 'defs': 'rule, govern, teach'}], 'isLemma': 0, 'parents': ['SAs']},
	{'value': 'śāsitavya', 'label': 'SAsitavya', 'attrib': [{'pos': 'ger', 'defs': 'rule, govern, teach'}], 'isLemma': 0, 'parents': ['SAs']},
	{'value': 'śāsitum', 'label': 'SAsitum', 'attrib': [{'pos': 'inf', 'defs': 'rule, govern, teach'}], 'isLemma': 0, 'parents': ['SAs']},
	{'value': 'śāsitvā', 'label': 'SAsitvA', 'attrib': [{'pos': 'abs', 'defs': 'rule, govern, teach'}], 'isLemma': 0, 'parents': ['SAs']},
	{'value': 'śāsiṣyat', 'label': 'SAsizyat', 'attrib': [{'pos': 'fap', 'defs': 'rule, govern, teach'}], 'isLemma': 0, 'parents': ['SAs']},
	{'value': 'śāsiṣyamāṇa', 'label': 'SAsizyamARa', 'attrib': [{'pos': 'fpp', 'defs': 'rule, govern, teach'}], 'isLemma': 0, 'parents': ['SAs']},
	{'value': 'śiṣṭa', 'label': 'Sizwa', 'attrib': [{'pos': 'ppp', 'defs': 'rule, govern, teach'}], 'isLemma': 0, 'parents': ['SAs']},
	{'value': 'śiṣṭavat', 'label': 'Sizwavat', 'attrib': [{'pos': 'pap', 'defs': 'rule, govern, teach'}], 'isLemma': 0, 'parents': ['SAs']},
	{'value': 'śiṣṭvā', 'label': 'SizwvA', 'attrib': [{'pos': 'abs', 'defs': 'rule, govern, teach'}], 'isLemma': 0, 'parents': ['SAs']},
	{'value': 'śiṣya', 'label': 'Sizya', 'attrib': [{'pos': 'ger', 'defs': 'rule, govern, teach'}], 'isLemma': 0, 'parents': ['SAs']},
	{'value': 'śiṣyamāṇa', 'label': 'SizyamARa', 'attrib': [{'pos': 'prpp', 'defs': 'rule, govern, teach'}], 'isLemma': 0, 'parents': ['SAs']},
	{'value': 'śṛṇvat', 'label': 'SfRvat', 'attrib': [{'pos': 'prap', 'defs': 'hear, listen'}], 'isLemma': 0, 'parents': ['Sru']},
	{'value': 'śrabdha', 'label': 'SrabDa', 'attrib': [{'pos': 'ppp', 'defs': 'be careless, negligent'}], 'isLemma': 0, 'parents': ['SramB']},
	{'value': 'śrabdhavat', 'label': 'SrabDavat', 'attrib': [{'pos': 'pap', 'defs': 'be careless, negligent'}], 'isLemma': 0, 'parents': ['SramB']},
	{'value': 'śrabdhvā', 'label': 'SrabDvA', 'attrib': [{'pos': 'abs', 'defs': 'be careless, negligent'}], 'isLemma': 0, 'parents': ['SramB']},
	{'value': 'śrabhyamāṇa', 'label': 'SraByamARa', 'attrib': [{'pos': 'prpp', 'defs': 'be careless, negligent'}], 'isLemma': 0, 'parents': ['SramB']},
	{'value': 'śrambh', 'label': 'SramB', 'attrib': [{'pos': 'vi1m', 'defs': 'be careless, negligent'}], 'isLemma': 1},
	{'value': 'śrambhaṇīya', 'label': 'SramBaRIya', 'attrib': [{'pos': 'ger', 'defs': 'be careless, negligent'}], 'isLemma': 0, 'parents': ['SramB']},
	{'value': 'śrambhamāṇa', 'label': 'SramBamARa', 'attrib': [{'pos': 'prmp', 'defs': 'be careless, negligent'}], 'isLemma': 0, 'parents': ['SramB']},
	{'value': 'śrambhitavya', 'label': 'SramBitavya', 'attrib': [{'pos': 'ger', 'defs': 'be careless, negligent'}], 'isLemma': 0, 'parents': ['SramB']},
	{'value': 'śrambhitum', 'label': 'SramBitum', 'attrib': [{'pos': 'inf', 'defs': 'be careless, negligent'}], 'isLemma': 0, 'parents': ['SramB']},
	{'value': 'śrambhitvā', 'label': 'SramBitvA', 'attrib': [{'pos': 'abs', 'defs': 'be careless, negligent'}], 'isLemma': 0, 'parents': ['SramB']},
	{'value': 'śrambhiṣyamāṇa', 'label': 'SramBizyamARa', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'be careless, negligent'}], 'isLemma': 0, 'parents': ['SramB']},
	{'value': 'śrambhya', 'label': 'SramBya', 'attrib': [{'pos': 'ger', 'defs': 'be careless, negligent'}], 'isLemma': 0, 'parents': ['SramB']},
	{'value': 'śravaṇīya', 'label': 'SravaRIya', 'attrib': [{'pos': 'ger', 'defs': 'hear, listen'}], 'isLemma': 0, 'parents': ['Sru']},
	{'value': 'śravya', 'label': 'Sravya', 'attrib': [{'pos': 'ger', 'defs': 'hear, listen'}], 'isLemma': 0, 'parents': ['Sru']},
	{'value': 'śrī', 'label': 'SrI', 'attrib': [{'pos': 'f', 'defs': 'radiance, glory, wealth'}], 'isLemma': 1},
	{'value': 'śrīmat', 'label': 'SrImat', 'attrib': [{'pos': 'adj', 'defs': 'radiant, glorious'}], 'isLemma': 1},
	{'value': 'śrīmanmahābhārata', 'label': 'SrImanmahABArata', 'attrib': [{'pos': 'n', 'defs': 'the glorious epic <i>Mahābhārata</i>'}], 'isLemma': 1},
	{'value': 'śrīyaśas', 'label': 'SrIyaSas', 'attrib': [{'pos': 'n', 'defs': 'glory and fame'}], 'isLemma': 1},
	{'value': 'śrīyaśobhṛt', 'label': 'SrIyaSoBft', 'attrib': [{'pos': 'adj', 'defs': 'bearing glory and fame'}], 'isLemma': 1},
	{'value': 'śru', 'label': 'Sru', 'attrib': [{'pos': 'vt1am', 'defs': 'hear, listen'}], 'isLemma': 1},
	{'value': 'śruta', 'label': 'Sruta', 'attrib': [{'pos': 'ppp', 'defs': 'hear, listen'}], 'isLemma': 0, 'parents': ['Sru']},
	{'value': 'śrutavat', 'label': 'Srutavat', 'attrib': [{'pos': 'pap', 'defs': 'hear, listen'}], 'isLemma': 0, 'parents': ['Sru']},
	{'value': 'śrutvā', 'label': 'SrutvA', 'attrib': [{'pos': 'abs', 'defs': 'hear, listen'}], 'isLemma': 0, 'parents': ['Sru']},
	{'value': 'śrūyamāṇa', 'label': 'SrUyamARa', 'attrib': [{'pos': 'prpp', 'defs': 'hear, listen'}], 'isLemma': 0, 'parents': ['Sru']},
	{'value': 'śrotavya', 'label': 'Srotavya', 'attrib': [{'pos': 'ger', 'defs': 'hear, listen'}], 'isLemma': 0, 'parents': ['Sru']},
	{'value': 'śrotum', 'label': 'Srotum', 'attrib': [{'pos': 'inf', 'defs': 'hear, listen'}], 'isLemma': 0, 'parents': ['Sru']},
	{'value': 'ṣaṭsūrya', 'label': 'zawsUrya', 'attrib': [{'pos': 'adj', 'defs': 'six-sunned'}], 'isLemma': 1},
	{'value': 'ṣaṣ', 'label': 'zaz', 'attrib': [{'pos': 'card_num', 'defs': 'six'}], 'isLemma': 1},
	{'value': 'saṁkāśa', 'label': 'saMkASa', 'attrib': [{'pos': 'm', 'defs': 'appearance'}], 'isLemma': 1},
	{'value': 'saṁgacchamāna', 'label': 'saMgacCamAna', 'attrib': [{'pos': 'prmp', 'defs': 'go together, unite, meet'}], 'isLemma': 0, 'parents': ['sam_gam']},
	{'value': 'saṁgata', 'label': 'saMgata', 'attrib': [{'pos': 'ppp', 'defs': 'go together, unite, meet'}], 'isLemma': 0, 'parents': ['sam_gam']},
	{'value': 'saṁgatavat', 'label': 'saMgatavat', 'attrib': [{'pos': 'pap', 'defs': 'go together, unite, meet'}], 'isLemma': 0, 'parents': ['sam_gam']},
	{'value': 'saṁgatya', 'label': 'saMgatya', 'attrib': [{'pos': 'abs', 'defs': 'go together, unite, meet'}], 'isLemma': 0, 'parents': ['sam_gam']},
	{'value': 'saṁgantavya', 'label': 'saMgantavya', 'attrib': [{'pos': 'ger', 'defs': 'go together, unite, meet'}], 'isLemma': 0, 'parents': ['sam_gam']},
	{'value': 'saṁgantum', 'label': 'saMgantum', 'attrib': [{'pos': 'inf', 'defs': 'go together, unite, meet'}], 'isLemma': 0, 'parents': ['sam_gam']},
	{'value': 'saṁgamanīya', 'label': 'saMgamanIya', 'attrib': [{'pos': 'ger', 'defs': 'go together, unite, meet'}], 'isLemma': 0, 'parents': ['sam_gam']},
	{'value': 'saṁgamiṣyamāṇa', 'label': 'saMgamizyamARa', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'go together, unite, meet'}], 'isLemma': 0, 'parents': ['sam_gam']},
	{'value': 'saṁgamya', 'label': 'saMgamya', 'attrib': [{'pos': 'abs', 'defs': 'go together, unite, meet'}, {'pos': 'ger', 'defs': 'go together, unite, meet'}], 'isLemma': 0, 'parents': ['sam_gam']},
	{'value': 'saṁgamyamāna', 'label': 'saMgamyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'go together, unite, meet'}], 'isLemma': 0, 'parents': ['sam_gam']},
	{'value': 'saṁcicchidāna', 'label': 'saMcicCidAna', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'split, cut'}], 'isLemma': 0, 'parents': ['sam_Cid']},
	{'value': 'saṁcicchidvas', 'label': 'saMcicCidvas', 'attrib': [{'pos': 'pfap', 'defs': 'split, cut'}], 'isLemma': 0, 'parents': ['sam_Cid']},
	{'value': 'saṁchidya', 'label': 'saMCidya', 'attrib': [{'pos': 'abs', 'defs': 'split, cut'}], 'isLemma': 0, 'parents': ['sam_Cid']},
	{'value': 'saṁchidyamāna', 'label': 'saMCidyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'split, cut'}], 'isLemma': 0, 'parents': ['sam_Cid']},
	{'value': 'saṁchindat', 'label': 'saMCindat', 'attrib': [{'pos': 'prap', 'defs': 'split, cut'}], 'isLemma': 0, 'parents': ['sam_Cid']},
	{'value': 'saṁchindāna', 'label': 'saMCindAna', 'attrib': [{'pos': 'prmp', 'defs': 'split, cut'}], 'isLemma': 0, 'parents': ['sam_Cid']},
	{'value': 'saṁchinna', 'label': 'saMCinna', 'attrib': [{'pos': 'ppp', 'defs': 'split, cut'}], 'isLemma': 0, 'parents': ['sam_Cid']},
	{'value': 'saṁchinnavat', 'label': 'saMCinnavat', 'attrib': [{'pos': 'pap', 'defs': 'split, cut'}], 'isLemma': 0, 'parents': ['sam_Cid']},
	{'value': 'saṁchettavya', 'label': 'saMCettavya', 'attrib': [{'pos': 'ger', 'defs': 'split, cut'}], 'isLemma': 0, 'parents': ['sam_Cid']},
	{'value': 'saṁchettum', 'label': 'saMCettum', 'attrib': [{'pos': 'inf', 'defs': 'split, cut'}], 'isLemma': 0, 'parents': ['sam_Cid']},
	{'value': 'saṁchetsyat', 'label': 'saMCetsyat', 'attrib': [{'pos': 'fap', 'defs': 'split, cut'}], 'isLemma': 0, 'parents': ['sam_Cid']},
	{'value': 'saṁchetsyamāna', 'label': 'saMCetsyamAna', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'split, cut'}], 'isLemma': 0, 'parents': ['sam_Cid']},
	{'value': 'saṁchedanīya', 'label': 'saMCedanIya', 'attrib': [{'pos': 'ger', 'defs': 'split, cut'}], 'isLemma': 0, 'parents': ['sam_Cid']},
	{'value': 'saṁchedya', 'label': 'saMCedya', 'attrib': [{'pos': 'ger', 'defs': 'split, cut'}], 'isLemma': 0, 'parents': ['sam_Cid']},
	{'value': 'saṁjagmāna', 'label': 'saMjagmAna', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'go together, unite, meet'}], 'isLemma': 0, 'parents': ['sam_gam']},
	{'value': 'saṁdadhat', 'label': 'saMdaDat', 'attrib': [{'pos': 'prap', 'defs': 'put together'}], 'isLemma': 0, 'parents': ['sam_DA']},
	{'value': 'saṁdadhāna', 'label': 'saMdaDAna', 'attrib': [{'pos': 'prmp', 'defs': 'put together'}, {'pos': 'pfmp, pfpp', 'defs': 'put together'}], 'isLemma': 0, 'parents': ['sam_DA']},
	{'value': 'saṁdadhivas', 'label': 'saMdaDivas', 'attrib': [{'pos': 'pfap', 'defs': 'put together'}], 'isLemma': 0, 'parents': ['sam_DA']},
	{'value': 'saṁdhātavya', 'label': 'saMDAtavya', 'attrib': [{'pos': 'ger', 'defs': 'put together'}], 'isLemma': 0, 'parents': ['sam_DA']},
	{'value': 'saṁdhātum', 'label': 'saMDAtum', 'attrib': [{'pos': 'inf', 'defs': 'put together'}], 'isLemma': 0, 'parents': ['sam_DA']},
	{'value': 'saṁdhānīya', 'label': 'saMDAnIya', 'attrib': [{'pos': 'ger', 'defs': 'put together'}], 'isLemma': 0, 'parents': ['sam_DA']},
	{'value': 'saṁdhāya', 'label': 'saMDAya', 'attrib': [{'pos': 'abs', 'defs': 'put together'}], 'isLemma': 0, 'parents': ['sam_DA']},
	{'value': 'saṁdhāsyat', 'label': 'saMDAsyat', 'attrib': [{'pos': 'fap', 'defs': 'put together'}], 'isLemma': 0, 'parents': ['sam_DA']},
	{'value': 'saṁdhāsyamāna', 'label': 'saMDAsyamAna', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'put together'}], 'isLemma': 0, 'parents': ['sam_DA']},
	{'value': 'saṁdhīyamāna', 'label': 'saMDIyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'put together'}], 'isLemma': 0, 'parents': ['sam_DA']},
	{'value': 'saṁdheya', 'label': 'saMDeya', 'attrib': [{'pos': 'ger', 'defs': 'put together'}], 'isLemma': 0, 'parents': ['sam_DA']},
	{'value': 'saṁpattavya', 'label': 'saMpattavya', 'attrib': [{'pos': 'ger', 'defs': 'succeed, arise'}], 'isLemma': 0, 'parents': ['sam_pad']},
	{'value': 'saṁpattum', 'label': 'saMpattum', 'attrib': [{'pos': 'inf', 'defs': 'succeed, arise'}], 'isLemma': 0, 'parents': ['sam_pad']},
	{'value': 'saṁpatsyamāna', 'label': 'saMpatsyamAna', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'succeed, arise'}], 'isLemma': 0, 'parents': ['sam_pad']},
	{'value': 'saṁpadanīya', 'label': 'saMpadanIya', 'attrib': [{'pos': 'ger', 'defs': 'succeed, arise'}], 'isLemma': 0, 'parents': ['sam_pad']},
	{'value': 'saṁpadya', 'label': 'saMpadya', 'attrib': [{'pos': 'abs', 'defs': 'succeed, arise'}], 'isLemma': 0, 'parents': ['sam_pad']},
	{'value': 'saṁpadyamāna', 'label': 'saMpadyamAna', 'attrib': [{'pos': 'prmp', 'defs': 'succeed, arise'}, {'pos': 'prpp', 'defs': 'succeed, arise'}], 'isLemma': 0, 'parents': ['sam_pad']},
	{'value': 'saṁpanna', 'label': 'saMpanna', 'attrib': [{'pos': 'adj', 'defs': 'endowed with'}, {'pos': 'ppp', 'defs': 'succeed, arise'}], 'isLemma': 1},
	{'value': 'saṁpanna', 'label': 'saMpanna', 'attrib': [{'pos': 'adj', 'defs': 'endowed with'}, {'pos': 'ppp', 'defs': 'succeed, arise'}], 'isLemma': 0, 'parents': ['sam_pad']},
	{'value': 'saṁpannavat', 'label': 'saMpannavat', 'attrib': [{'pos': 'pap', 'defs': 'succeed, arise'}], 'isLemma': 0, 'parents': ['sam_pad']},
	{'value': 'saṁpādya', 'label': 'saMpAdya', 'attrib': [{'pos': 'ger', 'defs': 'succeed, arise'}], 'isLemma': 0, 'parents': ['sam_pad']},
	{'value': 'saṁpūjanīya', 'label': 'saMpUjanIya', 'attrib': [{'pos': 'ger', 'defs': 'honor'}], 'isLemma': 0, 'parents': ['sam_pUj']},
	{'value': 'saṁpūjayamāna', 'label': 'saMpUjayamAna', 'attrib': [{'pos': 'prmp', 'defs': 'honor'}], 'isLemma': 0, 'parents': ['sam_pUj']},
	{'value': 'saṁpūjayām', 'label': 'saMpUjayAm', 'attrib': [{'pos': 'ppf_actn', 'defs': 'honor'}], 'isLemma': 0, 'parents': ['sam_pUj']},
	{'value': 'saṁpūjayitum', 'label': 'saMpUjayitum', 'attrib': [{'pos': 'inf', 'defs': 'honor'}], 'isLemma': 0, 'parents': ['sam_pUj']},
	{'value': 'saṁpūjayiṣyamāṇa', 'label': 'saMpUjayizyamARa', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'honor'}], 'isLemma': 0, 'parents': ['sam_pUj']},
	{'value': 'saṁpūjita', 'label': 'saMpUjita', 'attrib': [{'pos': 'ppp', 'defs': 'honor'}], 'isLemma': 0, 'parents': ['sam_pUj']},
	{'value': 'saṁpūjitavat', 'label': 'saMpUjitavat', 'attrib': [{'pos': 'pap', 'defs': 'honor'}], 'isLemma': 0, 'parents': ['sam_pUj']},
	{'value': 'saṁpūjitavya', 'label': 'saMpUjitavya', 'attrib': [{'pos': 'ger', 'defs': 'honor'}], 'isLemma': 0, 'parents': ['sam_pUj']},
	{'value': 'saṁpūjya', 'label': 'saMpUjya', 'attrib': [{'pos': 'abs', 'defs': 'honor'}, {'pos': 'ger', 'defs': 'honor'}], 'isLemma': 0, 'parents': ['sam_pUj']},
	{'value': 'saṁpūjyamāna', 'label': 'saMpUjyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'honor'}], 'isLemma': 0, 'parents': ['sam_pUj']},
	{'value': 'saṁpedāna', 'label': 'saMpedAna', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'succeed, arise'}], 'isLemma': 0, 'parents': ['sam_pad']},
	{'value': 'saṁpravaktavya', 'label': 'saMpravaktavya', 'attrib': [{'pos': 'ger', 'defs': 'communicate, declare, relate in full'}], 'isLemma': 0, 'parents': ['sam_pra_vac']},
	{'value': 'saṁpravaktum', 'label': 'saMpravaktum', 'attrib': [{'pos': 'inf', 'defs': 'communicate, declare, relate in full'}], 'isLemma': 0, 'parents': ['sam_pra_vac']},
	{'value': 'saṁpravakṣyat', 'label': 'saMpravakzyat', 'attrib': [{'pos': 'fap', 'defs': 'communicate, declare, relate in full'}], 'isLemma': 0, 'parents': ['sam_pra_vac']},
	{'value': 'saṁpravakṣyamāṇa', 'label': 'saMpravakzyamARa', 'attrib': [{'pos': 'fpp', 'defs': 'communicate, declare, relate in full'}], 'isLemma': 0, 'parents': ['sam_pra_vac']},
	{'value': 'saṁpravacanīya', 'label': 'saMpravacanIya', 'attrib': [{'pos': 'ger', 'defs': 'communicate, declare, relate in full'}], 'isLemma': 0, 'parents': ['sam_pra_vac']},
	{'value': 'saṁpravākya', 'label': 'saMpravAkya', 'attrib': [{'pos': 'ger', 'defs': 'communicate, declare, relate in full'}], 'isLemma': 0, 'parents': ['sam_pra_vac']},
	{'value': 'saṁprokta', 'label': 'saMprokta', 'attrib': [{'pos': 'ppp', 'defs': 'communicate, declare, relate in full'}], 'isLemma': 0, 'parents': ['sam_pra_vac']},
	{'value': 'saṁproktavat', 'label': 'saMproktavat', 'attrib': [{'pos': 'pap', 'defs': 'communicate, declare, relate in full'}], 'isLemma': 0, 'parents': ['sam_pra_vac']},
	{'value': 'saṁproktvā', 'label': 'saMproktvA', 'attrib': [{'pos': 'abs', 'defs': 'communicate, declare, relate in full'}], 'isLemma': 0, 'parents': ['sam_pra_vac']},
	{'value': 'saṁprocāna', 'label': 'saMprocAna', 'attrib': [{'pos': 'pfpp', 'defs': 'communicate, declare, relate in full'}], 'isLemma': 0, 'parents': ['sam_pra_vac']},
	{'value': 'saṁprocivas', 'label': 'saMprocivas', 'attrib': [{'pos': 'pfap', 'defs': 'communicate, declare, relate in full'}], 'isLemma': 0, 'parents': ['sam_pra_vac']},
	{'value': 'saṁprocya', 'label': 'saMprocya', 'attrib': [{'pos': 'abs', 'defs': 'communicate, declare, relate in full'}], 'isLemma': 0, 'parents': ['sam_pra_vac']},
	{'value': 'saṁprocyamāna', 'label': 'saMprocyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'communicate, declare, relate in full'}], 'isLemma': 0, 'parents': ['sam_pra_vac']},
	{'value': 'saṁbabhūvas', 'label': 'saMbaBUvas', 'attrib': [{'pos': 'pfap', 'defs': 'come to be, arise, be possible'}], 'isLemma': 0, 'parents': ['sam_BU']},
	{'value': 'saṁbhavat', 'label': 'saMBavat', 'attrib': [{'pos': 'prap', 'defs': 'come to be, arise, be possible'}], 'isLemma': 0, 'parents': ['sam_BU']},
	{'value': 'saṁbhavanīya', 'label': 'saMBavanIya', 'attrib': [{'pos': 'ger', 'defs': 'come to be, arise, be possible'}], 'isLemma': 0, 'parents': ['sam_BU']},
	{'value': 'saṁbhavitavya', 'label': 'saMBavitavya', 'attrib': [{'pos': 'ger', 'defs': 'come to be, arise, be possible'}], 'isLemma': 0, 'parents': ['sam_BU']},
	{'value': 'saṁbhavitum', 'label': 'saMBavitum', 'attrib': [{'pos': 'inf', 'defs': 'come to be, arise, be possible'}], 'isLemma': 0, 'parents': ['sam_BU']},
	{'value': 'saṁbhaviṣyat', 'label': 'saMBavizyat', 'attrib': [{'pos': 'fap', 'defs': 'come to be, arise, be possible'}], 'isLemma': 0, 'parents': ['sam_BU']},
	{'value': 'saṁbhaviṣyamāṇa', 'label': 'saMBavizyamARa', 'attrib': [{'pos': 'fpp', 'defs': 'come to be, arise, be possible'}], 'isLemma': 0, 'parents': ['sam_BU']},
	{'value': 'saṁbhavya', 'label': 'saMBavya', 'attrib': [{'pos': 'ger', 'defs': 'come to be, arise, be possible'}], 'isLemma': 0, 'parents': ['sam_BU']},
	{'value': 'saṁbhūta', 'label': 'saMBUta', 'attrib': [{'pos': 'ppp', 'defs': 'come to be, arise, be possible'}], 'isLemma': 0, 'parents': ['sam_BU']},
	{'value': 'saṁbhūtavat', 'label': 'saMBUtavat', 'attrib': [{'pos': 'pap', 'defs': 'come to be, arise, be possible'}], 'isLemma': 0, 'parents': ['sam_BU']},
	{'value': 'saṁbhūya', 'label': 'saMBUya', 'attrib': [{'pos': 'abs', 'defs': 'come to be, arise, be possible'}], 'isLemma': 0, 'parents': ['sam_BU']},
	{'value': 'saṁbhūyamāna', 'label': 'saMBUyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'come to be, arise, be possible'}], 'isLemma': 0, 'parents': ['sam_BU']},
	{'value': 'saṁmaṁsyamāna', 'label': 'saMmaMsyamAna', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'agree, approve, esteem, respect'}], 'isLemma': 0, 'parents': ['sam_man']},
	{'value': 'saṁmata', 'label': 'saMmata', 'attrib': [{'pos': 'ppp', 'defs': 'agree, approve, esteem, respect'}], 'isLemma': 0, 'parents': ['sam_man']},
	{'value': 'saṁmatavat', 'label': 'saMmatavat', 'attrib': [{'pos': 'pap', 'defs': 'agree, approve, esteem, respect'}], 'isLemma': 0, 'parents': ['sam_man']},
	{'value': 'saṁmananīya', 'label': 'saMmananIya', 'attrib': [{'pos': 'ger', 'defs': 'agree, approve, esteem, respect'}], 'isLemma': 0, 'parents': ['sam_man']},
	{'value': 'saṁmantavya', 'label': 'saMmantavya', 'attrib': [{'pos': 'ger', 'defs': 'agree, approve, esteem, respect'}], 'isLemma': 0, 'parents': ['sam_man']},
	{'value': 'saṁmantum', 'label': 'saMmantum', 'attrib': [{'pos': 'inf', 'defs': 'agree, approve, esteem, respect'}], 'isLemma': 0, 'parents': ['sam_man']},
	{'value': 'saṁmantraṇīya', 'label': 'saMmantraRIya', 'attrib': [{'pos': 'ger', 'defs': 'consult'}], 'isLemma': 0, 'parents': ['sam_mantr']},
	{'value': 'saṁmantrayamāna', 'label': 'saMmantrayamAna', 'attrib': [{'pos': 'prmp', 'defs': 'consult'}], 'isLemma': 0, 'parents': ['sam_mantr']},
	{'value': 'saṁmantrayām', 'label': 'saMmantrayAm', 'attrib': [{'pos': 'ppf_actn', 'defs': 'consult'}], 'isLemma': 0, 'parents': ['sam_mantr']},
	{'value': 'saṁmantrayitavya', 'label': 'saMmantrayitavya', 'attrib': [{'pos': 'ger', 'defs': 'consult'}], 'isLemma': 0, 'parents': ['sam_mantr']},
	{'value': 'saṁmantrayitum', 'label': 'saMmantrayitum', 'attrib': [{'pos': 'inf', 'defs': 'consult'}], 'isLemma': 0, 'parents': ['sam_mantr']},
	{'value': 'saṁmantrayiṣyamāṇa', 'label': 'saMmantrayizyamARa', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'consult'}], 'isLemma': 0, 'parents': ['sam_mantr']},
	{'value': 'saṁmantrita', 'label': 'saMmantrita', 'attrib': [{'pos': 'ppp', 'defs': 'consult'}], 'isLemma': 0, 'parents': ['sam_mantr']},
	{'value': 'saṁmantritavat', 'label': 'saMmantritavat', 'attrib': [{'pos': 'pap', 'defs': 'consult'}], 'isLemma': 0, 'parents': ['sam_mantr']},
	{'value': 'saṁmantrya', 'label': 'saMmantrya', 'attrib': [{'pos': 'abs', 'defs': 'consult'}, {'pos': 'ger', 'defs': 'consult'}], 'isLemma': 0, 'parents': ['sam_mantr']},
	{'value': 'saṁmantryamāna', 'label': 'saMmantryamAna', 'attrib': [{'pos': 'prpp', 'defs': 'consult'}], 'isLemma': 0, 'parents': ['sam_mantr']},
	{'value': 'saṁmanya', 'label': 'saMmanya', 'attrib': [{'pos': 'abs', 'defs': 'agree, approve, esteem, respect'}], 'isLemma': 0, 'parents': ['sam_man']},
	{'value': 'saṁmanyamāna', 'label': 'saMmanyamAna', 'attrib': [{'pos': 'prmp', 'defs': 'agree, approve, esteem, respect'}, {'pos': 'prpp', 'defs': 'agree, approve, esteem, respect'}], 'isLemma': 0, 'parents': ['sam_man']},
	{'value': 'saṁmanvāna', 'label': 'saMmanvAna', 'attrib': [{'pos': 'prmp', 'defs': 'agree, approve, esteem, respect'}], 'isLemma': 0, 'parents': ['sam_man']},
	{'value': 'saṁmānya', 'label': 'saMmAnya', 'attrib': [{'pos': 'ger', 'defs': 'agree, approve, esteem, respect'}], 'isLemma': 0, 'parents': ['sam_man']},
	{'value': 'saṁmenāna', 'label': 'saMmenAna', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'agree, approve, esteem, respect'}], 'isLemma': 0, 'parents': ['sam_man']},
	{'value': 'saṁvatsara', 'label': 'saMvatsara', 'attrib': [{'pos': 'm', 'defs': 'year'}], 'isLemma': 1},
	{'value': 'saṁvatsaragaṇa', 'label': 'saMvatsaragaRa', 'attrib': [{'pos': 'm', 'defs': 'many years'}], 'isLemma': 1},
	{'value': 'saṁvaraṇīya', 'label': 'saMvaraRIya', 'attrib': [{'pos': 'ger', 'defs': 'cover, conceal'}], 'isLemma': 0, 'parents': ['sam_vf']},
	{'value': 'saṁvaritavya', 'label': 'saMvaritavya', 'attrib': [{'pos': 'ger', 'defs': 'cover, conceal'}], 'isLemma': 0, 'parents': ['sam_vf']},
	{'value': 'saṁvaritum', 'label': 'saMvaritum', 'attrib': [{'pos': 'inf', 'defs': 'cover, conceal'}], 'isLemma': 0, 'parents': ['sam_vf']},
	{'value': 'saṁvariṣyat', 'label': 'saMvarizyat', 'attrib': [{'pos': 'fap', 'defs': 'cover, conceal'}], 'isLemma': 0, 'parents': ['sam_vf']},
	{'value': 'saṁvariṣyamāṇa', 'label': 'saMvarizyamARa', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'cover, conceal'}], 'isLemma': 0, 'parents': ['sam_vf']},
	{'value': 'saṁvarītavya', 'label': 'saMvarItavya', 'attrib': [{'pos': 'ger', 'defs': 'cover, conceal'}], 'isLemma': 0, 'parents': ['sam_vf']},
	{'value': 'saṁvarītum', 'label': 'saMvarItum', 'attrib': [{'pos': 'inf', 'defs': 'cover, conceal'}], 'isLemma': 0, 'parents': ['sam_vf']},
	{'value': 'saṁvarīṣyat', 'label': 'saMvarIzyat', 'attrib': [{'pos': 'fap', 'defs': 'cover, conceal'}], 'isLemma': 0, 'parents': ['sam_vf']},
	{'value': 'saṁvarīṣyamāṇa', 'label': 'saMvarIzyamARa', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'cover, conceal'}], 'isLemma': 0, 'parents': ['sam_vf']},
	{'value': 'saṁvavṛvas', 'label': 'saMvavfvas', 'attrib': [{'pos': 'pfap', 'defs': 'cover, conceal'}], 'isLemma': 0, 'parents': ['sam_vf']},
	{'value': 'saṁvavrāṇa', 'label': 'saMvavrARa', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'cover, conceal'}], 'isLemma': 0, 'parents': ['sam_vf']},
	{'value': 'saṁvṛṇvat', 'label': 'saMvfRvat', 'attrib': [{'pos': 'prap', 'defs': 'cover, conceal'}], 'isLemma': 0, 'parents': ['sam_vf']},
	{'value': 'saṁvṛṇvāna', 'label': 'saMvfRvAna', 'attrib': [{'pos': 'prmp', 'defs': 'cover, conceal'}], 'isLemma': 0, 'parents': ['sam_vf']},
	{'value': 'saṁvṛta', 'label': 'saMvfta', 'attrib': [{'pos': 'ppp', 'defs': 'cover, conceal'}], 'isLemma': 0, 'parents': ['sam_vf']},
	{'value': 'saṁvṛtavat', 'label': 'saMvftavat', 'attrib': [{'pos': 'pap', 'defs': 'cover, conceal'}], 'isLemma': 0, 'parents': ['sam_vf']},
	{'value': 'saṁvṛtya', 'label': 'saMvftya', 'attrib': [{'pos': 'abs', 'defs': 'cover, conceal'}, {'pos': 'ger', 'defs': 'cover, conceal'}], 'isLemma': 0, 'parents': ['sam_vf']},
	{'value': 'saṁvriyamāṇa', 'label': 'saMvriyamARa', 'attrib': [{'pos': 'prpp', 'defs': 'cover, conceal'}], 'isLemma': 0, 'parents': ['sam_vf']},
	{'value': 'saṁsupta', 'label': 'saMsupta', 'attrib': [{'pos': 'ppp', 'defs': 'sleep'}], 'isLemma': 0, 'parents': ['sam_svap']},
	{'value': 'saṁsuptavat', 'label': 'saMsuptavat', 'attrib': [{'pos': 'pap', 'defs': 'sleep'}], 'isLemma': 0, 'parents': ['sam_svap']},
	{'value': 'saṁsupya', 'label': 'saMsupya', 'attrib': [{'pos': 'abs', 'defs': 'sleep'}], 'isLemma': 0, 'parents': ['sam_svap']},
	{'value': 'saṁsupyamāṇa', 'label': 'saMsupyamARa', 'attrib': [{'pos': 'prpp', 'defs': 'sleep'}], 'isLemma': 0, 'parents': ['sam_svap']},
	{'value': 'saṁsuṣupāna', 'label': 'saMsuzupAna', 'attrib': [{'pos': 'pfpp', 'defs': 'sleep'}], 'isLemma': 0, 'parents': ['sam_svap']},
	{'value': 'saṁsuṣupivas', 'label': 'saMsuzupivas', 'attrib': [{'pos': 'pfap', 'defs': 'sleep'}], 'isLemma': 0, 'parents': ['sam_svap']},
	{'value': 'saṁsvapat', 'label': 'saMsvapat', 'attrib': [{'pos': 'prap', 'defs': 'sleep'}], 'isLemma': 0, 'parents': ['sam_svap']},
	{'value': 'saṁsvapanīya', 'label': 'saMsvapanIya', 'attrib': [{'pos': 'ger', 'defs': 'sleep'}], 'isLemma': 0, 'parents': ['sam_svap']},
	{'value': 'saṁsvaptavya', 'label': 'saMsvaptavya', 'attrib': [{'pos': 'ger', 'defs': 'sleep'}], 'isLemma': 0, 'parents': ['sam_svap']},
	{'value': 'saṁsvaptum', 'label': 'saMsvaptum', 'attrib': [{'pos': 'inf', 'defs': 'sleep'}], 'isLemma': 0, 'parents': ['sam_svap']},
	{'value': 'saṁsvapya', 'label': 'saMsvapya', 'attrib': [{'pos': 'ger', 'defs': 'sleep'}], 'isLemma': 0, 'parents': ['sam_svap']},
	{'value': 'saṁsvapsyat', 'label': 'saMsvapsyat', 'attrib': [{'pos': 'fap', 'defs': 'sleep'}], 'isLemma': 0, 'parents': ['sam_svap']},
	{'value': 'saṁsvapsyamāṇa', 'label': 'saMsvapsyamARa', 'attrib': [{'pos': 'fpp', 'defs': 'sleep'}], 'isLemma': 0, 'parents': ['sam_svap']},
	{'value': 'saṁhita', 'label': 'saMhita', 'attrib': [{'pos': 'ppp', 'defs': 'put together'}], 'isLemma': 0, 'parents': ['sam_DA']},
	{'value': 'saṁhitavat', 'label': 'saMhitavat', 'attrib': [{'pos': 'pap', 'defs': 'put together'}], 'isLemma': 0, 'parents': ['sam_DA']},
	{'value': 'sac', 'label': 'sac', 'attrib': [{'pos': 'vt1am', 'defs': 'be associated with'}], 'isLemma': 1},
	{'value': 'saciva', 'label': 'saciva', 'attrib': [{'pos': 'm', 'defs': 'associate, counsellor, minister'}], 'isLemma': 1},
	{'value': 'sat', 'label': 'sat', 'attrib': [{'pos': 'prap', 'defs': 'be'}, {'pos': 'adj', 'defs': 'good'}], 'isLemma': 1},
	{'value': 'sat', 'label': 'sat', 'attrib': [{'pos': 'prap', 'defs': 'be'}, {'pos': 'adj', 'defs': 'good'}], 'isLemma': 0, 'parents': ['as']},
	{'value': 'sattama', 'label': 'sattama', 'attrib': [{'pos': 'adj', 'defs': 'most excellent'}], 'isLemma': 1},
	{'value': 'sattavya', 'label': 'sattavya', 'attrib': [{'pos': 'ger', 'defs': 'sit, be sad'}], 'isLemma': 0, 'parents': ['sad']},
	{'value': 'sattum', 'label': 'sattum', 'attrib': [{'pos': 'inf', 'defs': 'sit, be sad'}], 'isLemma': 0, 'parents': ['sad']},
	{'value': 'sattvā', 'label': 'sattvA', 'attrib': [{'pos': 'abs', 'defs': 'sit, be sad'}], 'isLemma': 0, 'parents': ['sad']},
	{'value': 'satya', 'label': 'satya', 'attrib': [{'pos': 'adj', 'defs': 'true, honest'}, {'pos': 'n', 'defs': 'truth'}], 'isLemma': 1},
	{'value': 'satyavikrama', 'label': 'satyavikrama', 'attrib': [{'pos': 'adj', 'defs': 'truly powerful'}], 'isLemma': 1},
	{'value': 'satyavrata', 'label': 'satyavrata', 'attrib': [{'pos': 'n', 'defs': 'truth and vows'}], 'isLemma': 1},
	{'value': 'satyavrataparāyaṇa', 'label': 'satyavrataparAyaRa', 'attrib': [{'pos': 'adj', 'defs': 'devoted to truth and vows'}], 'isLemma': 1},
	{'value': 'satsyat', 'label': 'satsyat', 'attrib': [{'pos': 'fap', 'defs': 'sit, be sad'}], 'isLemma': 0, 'parents': ['sad']},
	{'value': 'satsyamāna', 'label': 'satsyamAna', 'attrib': [{'pos': 'fpp', 'defs': 'sit, be sad'}], 'isLemma': 0, 'parents': ['sad']},
	{'value': 'sad', 'label': 'sad', 'attrib': [{'pos': 'vi1a, vi6a', 'defs': 'sit, be sad'}], 'isLemma': 1},
	{'value': 'sadanīya', 'label': 'sadanIya', 'attrib': [{'pos': 'ger', 'defs': 'sit, be sad'}], 'isLemma': 0, 'parents': ['sad']},
	{'value': 'sadyamāna', 'label': 'sadyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'sit, be sad'}], 'isLemma': 0, 'parents': ['sad']},
	{'value': 'sanna', 'label': 'sanna', 'attrib': [{'pos': 'ppp', 'defs': 'sit, be sad'}], 'isLemma': 0, 'parents': ['sad']},
	{'value': 'sannavat', 'label': 'sannavat', 'attrib': [{'pos': 'pap', 'defs': 'sit, be sad'}], 'isLemma': 0, 'parents': ['sad']},
	{'value': 'saptan', 'label': 'saptan', 'attrib': [{'pos': 'card_num', 'defs': 'seven'}], 'isLemma': 1},
	{'value': 'sabhā', 'label': 'saBA', 'attrib': [{'pos': 'f', 'defs': 'hall'}], 'isLemma': 1},
	{'value': 'sam', 'label': 'sam', 'attrib': [{'pos': 'preverb', 'defs': 'together'}], 'isLemma': 1},
	{'value': 'samācayanīya', 'label': 'samAcayanIya', 'attrib': [{'pos': 'ger', 'defs': 'cover or fill with'}], 'isLemma': 0, 'parents': ['sam_A_ci']},
	{'value': 'samācaraṇīya', 'label': 'samAcaraRIya', 'attrib': [{'pos': 'ger', 'defs': 'do, practice'}], 'isLemma': 0, 'parents': ['sam_A_car']},
	{'value': 'samācarat', 'label': 'samAcarat', 'attrib': [{'pos': 'prap', 'defs': 'do, practice'}], 'isLemma': 0, 'parents': ['sam_A_car']},
	{'value': 'samācaramāṇa', 'label': 'samAcaramARa', 'attrib': [{'pos': 'prmp', 'defs': 'do, practice'}], 'isLemma': 0, 'parents': ['sam_A_car']},
	{'value': 'samācarita', 'label': 'samAcarita', 'attrib': [{'pos': 'ppp', 'defs': 'do, practice'}], 'isLemma': 0, 'parents': ['sam_A_car']},
	{'value': 'samācaritavat', 'label': 'samAcaritavat', 'attrib': [{'pos': 'pap', 'defs': 'do, practice'}], 'isLemma': 0, 'parents': ['sam_A_car']},
	{'value': 'samācaritavya', 'label': 'samAcaritavya', 'attrib': [{'pos': 'ger', 'defs': 'do, practice'}], 'isLemma': 0, 'parents': ['sam_A_car']},
	{'value': 'samācaritum', 'label': 'samAcaritum', 'attrib': [{'pos': 'inf', 'defs': 'do, practice'}], 'isLemma': 0, 'parents': ['sam_A_car']},
	{'value': 'samācariṣyat', 'label': 'samAcarizyat', 'attrib': [{'pos': 'fap', 'defs': 'do, practice'}], 'isLemma': 0, 'parents': ['sam_A_car']},
	{'value': 'samācariṣyamāṇa', 'label': 'samAcarizyamARa', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'do, practice'}], 'isLemma': 0, 'parents': ['sam_A_car']},
	{'value': 'samācarya', 'label': 'samAcarya', 'attrib': [{'pos': 'abs', 'defs': 'do, practice'}, {'pos': 'ger', 'defs': 'do, practice'}], 'isLemma': 0, 'parents': ['sam_A_car']},
	{'value': 'samācaryamāṇa', 'label': 'samAcaryamARa', 'attrib': [{'pos': 'prpp', 'defs': 'do, practice'}], 'isLemma': 0, 'parents': ['sam_A_car']},
	{'value': 'samācikivas', 'label': 'samAcikivas', 'attrib': [{'pos': 'pfap', 'defs': 'cover or fill with'}], 'isLemma': 0, 'parents': ['sam_A_ci']},
	{'value': 'samācicivas', 'label': 'samAcicivas', 'attrib': [{'pos': 'pfap', 'defs': 'cover or fill with'}], 'isLemma': 0, 'parents': ['sam_A_ci']},
	{'value': 'samācita', 'label': 'samAcita', 'attrib': [{'pos': 'ppp', 'defs': 'cover or fill with'}], 'isLemma': 0, 'parents': ['sam_A_ci']},
	{'value': 'samācitavat', 'label': 'samAcitavat', 'attrib': [{'pos': 'pap', 'defs': 'cover or fill with'}], 'isLemma': 0, 'parents': ['sam_A_ci']},
	{'value': 'samācitya', 'label': 'samAcitya', 'attrib': [{'pos': 'abs', 'defs': 'cover or fill with'}], 'isLemma': 0, 'parents': ['sam_A_ci']},
	{'value': 'samācinvat', 'label': 'samAcinvat', 'attrib': [{'pos': 'prap', 'defs': 'cover or fill with'}], 'isLemma': 0, 'parents': ['sam_A_ci']},
	{'value': 'samācinvāna', 'label': 'samAcinvAna', 'attrib': [{'pos': 'prmp', 'defs': 'cover or fill with'}], 'isLemma': 0, 'parents': ['sam_A_ci']},
	{'value': 'samāciyāna', 'label': 'samAciyAna', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'cover or fill with'}], 'isLemma': 0, 'parents': ['sam_A_ci']},
	{'value': 'samācīyamāṇa', 'label': 'samAcIyamARa', 'attrib': [{'pos': 'prpp', 'defs': 'cover or fill with'}], 'isLemma': 0, 'parents': ['sam_A_ci']},
	{'value': 'samācetavya', 'label': 'samAcetavya', 'attrib': [{'pos': 'ger', 'defs': 'cover or fill with'}], 'isLemma': 0, 'parents': ['sam_A_ci']},
	{'value': 'samācetum', 'label': 'samAcetum', 'attrib': [{'pos': 'inf', 'defs': 'cover or fill with'}], 'isLemma': 0, 'parents': ['sam_A_ci']},
	{'value': 'samāceya', 'label': 'samAceya', 'attrib': [{'pos': 'ger', 'defs': 'cover or fill with'}], 'isLemma': 0, 'parents': ['sam_A_ci']},
	{'value': 'samācerāṇa', 'label': 'samAcerARa', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'do, practice'}], 'isLemma': 0, 'parents': ['sam_A_car']},
	{'value': 'samācerivas', 'label': 'samAcerivas', 'attrib': [{'pos': 'pfap', 'defs': 'do, practice'}], 'isLemma': 0, 'parents': ['sam_A_car']},
	{'value': 'samāceṣyat', 'label': 'samAcezyat', 'attrib': [{'pos': 'fap', 'defs': 'cover or fill with'}], 'isLemma': 0, 'parents': ['sam_A_ci']},
	{'value': 'samāceṣyamāṇa', 'label': 'samAcezyamARa', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'cover or fill with'}], 'isLemma': 0, 'parents': ['sam_A_ci']},
	{'value': 'samādadhat', 'label': 'samAdaDat', 'attrib': [{'pos': 'prap', 'defs': 'place together, compose, establish'}], 'isLemma': 0, 'parents': ['sam_A_DA']},
	{'value': 'samādadhāna', 'label': 'samAdaDAna', 'attrib': [{'pos': 'prmp', 'defs': 'place together, compose, establish'}, {'pos': 'pfmp, pfpp', 'defs': 'place together, compose, establish'}], 'isLemma': 0, 'parents': ['sam_A_DA']},
	{'value': 'samādadhivas', 'label': 'samAdaDivas', 'attrib': [{'pos': 'pfap', 'defs': 'place together, compose, establish'}], 'isLemma': 0, 'parents': ['sam_A_DA']},
	{'value': 'samādhātavya', 'label': 'samADAtavya', 'attrib': [{'pos': 'ger', 'defs': 'place together, compose, establish'}], 'isLemma': 0, 'parents': ['sam_A_DA']},
	{'value': 'samādhātum', 'label': 'samADAtum', 'attrib': [{'pos': 'inf', 'defs': 'place together, compose, establish'}], 'isLemma': 0, 'parents': ['sam_A_DA']},
	{'value': 'samādhānīya', 'label': 'samADAnIya', 'attrib': [{'pos': 'ger', 'defs': 'place together, compose, establish'}], 'isLemma': 0, 'parents': ['sam_A_DA']},
	{'value': 'samādhāya', 'label': 'samADAya', 'attrib': [{'pos': 'abs', 'defs': 'place together, compose, establish'}], 'isLemma': 0, 'parents': ['sam_A_DA']},
	{'value': 'samādhāsyat', 'label': 'samADAsyat', 'attrib': [{'pos': 'fap', 'defs': 'place together, compose, establish'}], 'isLemma': 0, 'parents': ['sam_A_DA']},
	{'value': 'samādhāsyamāna', 'label': 'samADAsyamAna', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'place together, compose, establish'}], 'isLemma': 0, 'parents': ['sam_A_DA']},
	{'value': 'samādhi', 'label': 'samADi', 'attrib': [{'pos': 'm', 'defs': 'putting together, completion, concentration, settled awareness'}], 'isLemma': 1},
	{'value': 'samādhīyamāna', 'label': 'samADIyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'place together, compose, establish'}], 'isLemma': 0, 'parents': ['sam_A_DA']},
	{'value': 'samādheya', 'label': 'samADeya', 'attrib': [{'pos': 'ger', 'defs': 'place together, compose, establish'}], 'isLemma': 0, 'parents': ['sam_A_DA']},
	{'value': 'samāpanīya', 'label': 'samApanIya', 'attrib': [{'pos': 'ger', 'defs': 'accomplish, complete'}], 'isLemma': 0, 'parents': ['sam_Ap']},
	{'value': 'samāpāna', 'label': 'samApAna', 'attrib': [{'pos': 'pfpp', 'defs': 'accomplish, complete'}], 'isLemma': 0, 'parents': ['sam_Ap']},
	{'value': 'samāpivas', 'label': 'samApivas', 'attrib': [{'pos': 'pfap', 'defs': 'accomplish, complete'}], 'isLemma': 0, 'parents': ['sam_Ap']},
	{'value': 'samāpta', 'label': 'samApta', 'attrib': [{'pos': 'ppp', 'defs': 'accomplish, complete'}], 'isLemma': 0, 'parents': ['sam_Ap']},
	{'value': 'samāptavat', 'label': 'samAptavat', 'attrib': [{'pos': 'pap', 'defs': 'accomplish, complete'}], 'isLemma': 0, 'parents': ['sam_Ap']},
	{'value': 'samāptavya', 'label': 'samAptavya', 'attrib': [{'pos': 'ger', 'defs': 'accomplish, complete'}], 'isLemma': 0, 'parents': ['sam_Ap']},
	{'value': 'samāptum', 'label': 'samAptum', 'attrib': [{'pos': 'inf', 'defs': 'accomplish, complete'}], 'isLemma': 0, 'parents': ['sam_Ap']},
	{'value': 'samāpnuvat', 'label': 'samApnuvat', 'attrib': [{'pos': 'prap', 'defs': 'accomplish, complete'}], 'isLemma': 0, 'parents': ['sam_Ap']},
	{'value': 'samāpya', 'label': 'samApya', 'attrib': [{'pos': 'abs', 'defs': 'accomplish, complete'}, {'pos': 'ger', 'defs': 'accomplish, complete'}], 'isLemma': 0, 'parents': ['sam_Ap']},
	{'value': 'samāpyamāna', 'label': 'samApyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'accomplish, complete'}], 'isLemma': 0, 'parents': ['sam_Ap']},
	{'value': 'samāpsyat', 'label': 'samApsyat', 'attrib': [{'pos': 'fap', 'defs': 'accomplish, complete'}], 'isLemma': 0, 'parents': ['sam_Ap']},
	{'value': 'samāpsyamāna', 'label': 'samApsyamAna', 'attrib': [{'pos': 'fpp', 'defs': 'accomplish, complete'}], 'isLemma': 0, 'parents': ['sam_Ap']},
	{'value': 'samāhita', 'label': 'samAhita', 'attrib': [{'pos': 'ppp', 'defs': 'place together, compose, establish'}], 'isLemma': 0, 'parents': ['sam_A_DA']},
	{'value': 'samāhitavat', 'label': 'samAhitavat', 'attrib': [{'pos': 'pap', 'defs': 'place together, compose, establish'}], 'isLemma': 0, 'parents': ['sam_A_DA']},
	{'value': 'sam_ā_car', 'label': 'sam_A_car', 'attrib': [{'pos': 'vt1am', 'defs': 'do, practice'}], 'isLemma': 1},
	{'value': 'sam_ā_ci', 'label': 'sam_A_ci', 'attrib': [{'pos': 'vt5am', 'defs': 'cover or fill with'}], 'isLemma': 1},
	{'value': 'sam_ā_dhā', 'label': 'sam_A_DA', 'attrib': [{'pos': 'vt3am', 'defs': 'place together, compose, establish'}], 'isLemma': 1},
	{'value': 'sam_āp', 'label': 'sam_Ap', 'attrib': [{'pos': 'vt5a', 'defs': 'accomplish, complete'}], 'isLemma': 1},
	{'value': 'sam_ud_sah', 'label': 'sam_ud_sah', 'attrib': [{'pos': 'vt1m', 'defs': 'be encouraged, endure, overcome; caus.: encourage, inspire'}], 'isLemma': 1},
	{'value': 'sam_kāś', 'label': 'sam_kAS', 'attrib': [{'pos': 'vt1m, vt4m', 'defs': 'shine'}], 'isLemma': 1},
	{'value': 'sam_gam', 'label': 'sam_gam', 'attrib': [{'pos': 'vi1m', 'defs': 'go together, unite, meet'}], 'isLemma': 1},
	{'value': 'sam_chid', 'label': 'sam_Cid', 'attrib': [{'pos': 'vt7am', 'defs': 'split, cut'}], 'isLemma': 1},
	{'value': 'sam_dhā', 'label': 'sam_DA', 'attrib': [{'pos': 'vt3am', 'defs': 'put together'}], 'isLemma': 1},
	{'value': 'sam_pad', 'label': 'sam_pad', 'attrib': [{'pos': 'vt4m', 'defs': 'succeed, arise'}], 'isLemma': 1},
	{'value': 'sam_pūj', 'label': 'sam_pUj', 'attrib': [{'pos': 'vt10m', 'defs': 'honor'}], 'isLemma': 1},
	{'value': 'sam_pra_vac', 'label': 'sam_pra_vac', 'attrib': [{'pos': 'vt2a', 'defs': 'communicate, declare, relate in full'}], 'isLemma': 1},
	{'value': 'sam_bhū', 'label': 'sam_BU', 'attrib': [{'pos': 'vi1a', 'defs': 'come to be, arise, be possible'}], 'isLemma': 1},
	{'value': 'sam_man', 'label': 'sam_man', 'attrib': [{'pos': 'vt4m, vt8m', 'defs': 'agree, approve, esteem, respect'}], 'isLemma': 1},
	{'value': 'sam_mantr', 'label': 'sam_mantr', 'attrib': [{'pos': 'vt10m', 'defs': 'consult'}], 'isLemma': 1},
	{'value': 'sam_vṛ', 'label': 'sam_vf', 'attrib': [{'pos': 'vt5am', 'defs': 'cover, conceal'}], 'isLemma': 1},
	{'value': 'sam_svap', 'label': 'sam_svap', 'attrib': [{'pos': 'vi2a', 'defs': 'sleep'}], 'isLemma': 1},
	{'value': 'sarpa', 'label': 'sarpa', 'attrib': [{'pos': 'm', 'defs': 'serpent, snake'}], 'isLemma': 1},
	{'value': 'sarva', 'label': 'sarva', 'attrib': [{'pos': 'pron_adj, pron', 'defs': 'all, whole'}], 'isLemma': 1},
	{'value': 'sarvaratna', 'label': 'sarvaratna', 'attrib': [{'pos': 'n', 'defs': 'all jewels'}], 'isLemma': 1},
	{'value': 'sarvaratnasamācita', 'label': 'sarvaratnasamAcita', 'attrib': [{'pos': 'adj', 'defs': 'covered with all jewels, filled with all wealth'}], 'isLemma': 1},
	{'value': 'sarvaloka', 'label': 'sarvaloka', 'attrib': [{'pos': 'm', 'defs': 'all regions'}], 'isLemma': 1},
	{'value': 'sarvaśas', 'label': 'sarvaSas', 'attrib': [{'pos': 'adv', 'defs': 'wholly, in every way, everywhere'}], 'isLemma': 1},
	{'value': 'sasāna', 'label': 'sasAna', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'end, finish'}], 'isLemma': 0, 'parents': ['so']},
	{'value': 'sasivas', 'label': 'sasivas', 'attrib': [{'pos': 'pfap', 'defs': 'end, finish'}], 'isLemma': 0, 'parents': ['so']},
	{'value': 'sasrambhāṇa', 'label': 'sasramBARa', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'be confident, relax'}], 'isLemma': 0, 'parents': ['sramB']},
	{'value': 'sah', 'label': 'sah', 'attrib': [{'pos': 'vt1m', 'defs': 'bear, endure, overcome'}], 'isLemma': 1},
	{'value': 'saha', 'label': 'saha', 'attrib': [{'pos': 'pcl', 'defs': 'with'}], 'isLemma': 1},
	{'value': 'sahadeva', 'label': 'sahadeva', 'attrib': [{'pos': 'm_pn', 'defs': 'Sahadeva'}], 'isLemma': 1},
	{'value': 'sahanīya', 'label': 'sahanIya', 'attrib': [{'pos': 'ger', 'defs': 'bear, endure, overcome'}], 'isLemma': 0, 'parents': ['sah']},
	{'value': 'sahamāna', 'label': 'sahamAna', 'attrib': [{'pos': 'prmp', 'defs': 'bear, endure, overcome'}], 'isLemma': 0, 'parents': ['sah']},
	{'value': 'sahasaubala', 'label': 'sahasObala', 'attrib': [{'pos': 'adj', 'defs': 'with the son of Subala'}], 'isLemma': 1},
	{'value': 'sahāya', 'label': 'sahAya', 'attrib': [{'pos': 'm', 'defs': 'companion'}], 'isLemma': 1},
	{'value': 'sahita', 'label': 'sahita', 'attrib': [{'pos': 'adj', 'defs': 'accompanied by'}], 'isLemma': 1},
	{'value': 'sahitavya', 'label': 'sahitavya', 'attrib': [{'pos': 'ger', 'defs': 'bear, endure, overcome'}], 'isLemma': 0, 'parents': ['sah']},
	{'value': 'sahitum', 'label': 'sahitum', 'attrib': [{'pos': 'inf', 'defs': 'bear, endure, overcome'}], 'isLemma': 0, 'parents': ['sah']},
	{'value': 'sahitvā', 'label': 'sahitvA', 'attrib': [{'pos': 'abs', 'defs': 'bear, endure, overcome'}], 'isLemma': 0, 'parents': ['sah']},
	{'value': 'sahiṣyamāṇa', 'label': 'sahizyamARa', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'bear, endure, overcome'}], 'isLemma': 0, 'parents': ['sah']},
	{'value': 'sahya', 'label': 'sahya', 'attrib': [{'pos': 'ger', 'defs': 'bear, endure, overcome'}], 'isLemma': 0, 'parents': ['sah']},
	{'value': 'sahyamāna', 'label': 'sahyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'bear, endure, overcome'}], 'isLemma': 0, 'parents': ['sah']},
	{'value': 'sātavya', 'label': 'sAtavya', 'attrib': [{'pos': 'ger', 'defs': 'end, finish'}], 'isLemma': 0, 'parents': ['so']},
	{'value': 'sātum', 'label': 'sAtum', 'attrib': [{'pos': 'inf', 'defs': 'end, finish'}], 'isLemma': 0, 'parents': ['so']},
	{'value': 'sādya', 'label': 'sAdya', 'attrib': [{'pos': 'ger', 'defs': 'sit, be sad'}], 'isLemma': 0, 'parents': ['sad']},
	{'value': 'sānīya', 'label': 'sAnIya', 'attrib': [{'pos': 'ger', 'defs': 'end, finish'}], 'isLemma': 0, 'parents': ['so']},
	{'value': 'sāsyat', 'label': 'sAsyat', 'attrib': [{'pos': 'fap', 'defs': 'end, finish'}], 'isLemma': 0, 'parents': ['so']},
	{'value': 'sāsyamāna', 'label': 'sAsyamAna', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'end, finish'}], 'isLemma': 0, 'parents': ['so']},
	{'value': 'sita', 'label': 'sita', 'attrib': [{'pos': 'ppp', 'defs': 'end, finish'}], 'isLemma': 0, 'parents': ['so']},
	{'value': 'sitavat', 'label': 'sitavat', 'attrib': [{'pos': 'pap', 'defs': 'end, finish'}], 'isLemma': 0, 'parents': ['so']},
	{'value': 'sitvā', 'label': 'sitvA', 'attrib': [{'pos': 'abs', 'defs': 'end, finish'}], 'isLemma': 0, 'parents': ['so']},
	{'value': 'sīdat', 'label': 'sIdat', 'attrib': [{'pos': 'prap', 'defs': 'sit, be sad'}], 'isLemma': 0, 'parents': ['sad']},
	{'value': 'sīyamāna', 'label': 'sIyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'end, finish'}], 'isLemma': 0, 'parents': ['so']},
	{'value': 'su', 'label': 'su', 'attrib': [{'pos': 'preverb', 'defs': 'well'}], 'isLemma': 1},
	{'value': 'sukha', 'label': 'suKa', 'attrib': [{'pos': 'adj', 'defs': 'happy'}, {'pos': 'n', 'defs': 'happiness'}], 'isLemma': 1},
	{'value': 'sukhāvaha', 'label': 'suKAvaha', 'attrib': [{'pos': 'adj', 'defs': 'bringing happiness'}], 'isLemma': 1},
	{'value': 'sudurmati', 'label': 'sudurmati', 'attrib': [{'pos': 'adj', 'defs': 'very weak-minded, very evil-minded'}], 'isLemma': 1},
	{'value': 'supta', 'label': 'supta', 'attrib': [{'pos': 'ppp', 'defs': 'sleep'}], 'isLemma': 0, 'parents': ['svap']},
	{'value': 'suptavat', 'label': 'suptavat', 'attrib': [{'pos': 'pap', 'defs': 'sleep'}], 'isLemma': 0, 'parents': ['svap']},
	{'value': 'suptvā', 'label': 'suptvA', 'attrib': [{'pos': 'abs', 'defs': 'sleep'}], 'isLemma': 0, 'parents': ['svap']},
	{'value': 'supyamāṇa', 'label': 'supyamARa', 'attrib': [{'pos': 'prpp', 'defs': 'sleep'}], 'isLemma': 0, 'parents': ['svap']},
	{'value': 'subala', 'label': 'subala', 'attrib': [{'pos': 'm_pn', 'defs': 'Subala'}, {'pos': 'adj', 'defs': 'very powerful'}], 'isLemma': 1},
	{'value': 'subhadra', 'label': 'suBadra', 'attrib': [{'pos': 'adj', 'defs': 'very auspicious'}], 'isLemma': 1},
	{'value': 'subhadrā', 'label': 'suBadrA', 'attrib': [{'pos': 'f_pn', 'defs': 'Subhadrā'}], 'isLemma': 1},
	{'value': 'suvibhakta', 'label': 'suviBakta', 'attrib': [{'pos': 'adj', 'defs': 'well distributed'}], 'isLemma': 1},
	{'value': 'suvibhaktamahāpatha', 'label': 'suviBaktamahApaTa', 'attrib': [{'pos': 'adj', 'defs': 'whose great roads are well-distributed'}], 'isLemma': 1},
	{'value': 'suṣupāna', 'label': 'suzupAna', 'attrib': [{'pos': 'pfpp', 'defs': 'sleep'}], 'isLemma': 0, 'parents': ['svap']},
	{'value': 'suṣupivas', 'label': 'suzupivas', 'attrib': [{'pos': 'pfap', 'defs': 'sleep'}], 'isLemma': 0, 'parents': ['svap']},
	{'value': 'suhṛjjana', 'label': 'suhfjjana', 'attrib': [{'pos': 'm', 'defs': 'friendly people'}], 'isLemma': 1},
	{'value': 'suhṛd', 'label': 'suhfd', 'attrib': [{'pos': 'm', 'defs': 'friend'}, {'pos': 'adj', 'defs': 'good-hearted, well-wishing'}], 'isLemma': 1},
	{'value': 'sūrya', 'label': 'sUrya', 'attrib': [{'pos': 'm', 'defs': 'sun'}], 'isLemma': 1},
	{'value': 'sūryasaṁkāśa', 'label': 'sUryasaMkASa', 'attrib': [{'pos': 'adj', 'defs': 'whose appearance is like the sun'}], 'isLemma': 1},
	{'value': 'sedāna', 'label': 'sedAna', 'attrib': [{'pos': 'pfpp', 'defs': 'sit, be sad'}], 'isLemma': 0, 'parents': ['sad']},
	{'value': 'sedivas', 'label': 'sedivas', 'attrib': [{'pos': 'pfap', 'defs': 'sit, be sad'}], 'isLemma': 0, 'parents': ['sad']},
	{'value': 'senā', 'label': 'senA', 'attrib': [{'pos': 'f', 'defs': 'army'}], 'isLemma': 1},
	{'value': 'seya', 'label': 'seya', 'attrib': [{'pos': 'ger', 'defs': 'end, finish'}], 'isLemma': 0, 'parents': ['so']},
	{'value': 'sehāna', 'label': 'sehAna', 'attrib': [{'pos': 'pfmp', 'defs': 'bear, endure, overcome'}], 'isLemma': 0, 'parents': ['sah']},
	{'value': 'so', 'label': 'so', 'attrib': [{'pos': 'vt4a', 'defs': 'end, finish'}], 'isLemma': 1},
	{'value': 'soḍha', 'label': 'soQa', 'attrib': [{'pos': 'ppp', 'defs': 'bear, endure, overcome'}], 'isLemma': 0, 'parents': ['sah']},
	{'value': 'soḍhavat', 'label': 'soQavat', 'attrib': [{'pos': 'pap', 'defs': 'bear, endure, overcome'}], 'isLemma': 0, 'parents': ['sah']},
	{'value': 'soḍhavya', 'label': 'soQavya', 'attrib': [{'pos': 'ger', 'defs': 'bear, endure, overcome'}], 'isLemma': 0, 'parents': ['sah']},
	{'value': 'soḍhum', 'label': 'soQum', 'attrib': [{'pos': 'inf', 'defs': 'bear, endure, overcome'}], 'isLemma': 0, 'parents': ['sah']},
	{'value': 'soḍhvā', 'label': 'soQvA', 'attrib': [{'pos': 'abs', 'defs': 'bear, endure, overcome'}], 'isLemma': 0, 'parents': ['sah']},
	{'value': 'saubala', 'label': 'sObala', 'attrib': [{'pos': 'm', 'defs': 'son of Subala'}], 'isLemma': 1},
	{'value': 'stha', 'label': 'sTa', 'attrib': [{'pos': 'adj', 'defs': 'standing'}], 'isLemma': 1},
	{'value': 'sthā', 'label': 'sTA', 'attrib': [{'pos': 'vi1am', 'defs': 'stand, stay, be present'}], 'isLemma': 1},
	{'value': 'sthātavya', 'label': 'sTAtavya', 'attrib': [{'pos': 'ger', 'defs': 'stand, stay, be present'}], 'isLemma': 0, 'parents': ['sTA']},
	{'value': 'sthātum', 'label': 'sTAtum', 'attrib': [{'pos': 'inf', 'defs': 'stand, stay, be present'}], 'isLemma': 0, 'parents': ['sTA']},
	{'value': 'sthānīya', 'label': 'sTAnIya', 'attrib': [{'pos': 'ger', 'defs': 'stand, stay, be present'}], 'isLemma': 0, 'parents': ['sTA']},
	{'value': 'sthāpayitum', 'label': 'sTApayitum', 'attrib': [{'pos': 'inf', 'defs': 'stand, stay, be present'}], 'isLemma': 0, 'parents': ['sTA']},
	{'value': 'sthāsyat', 'label': 'sTAsyat', 'attrib': [{'pos': 'fap', 'defs': 'stand, stay, be present'}], 'isLemma': 0, 'parents': ['sTA']},
	{'value': 'sthāsyamāna', 'label': 'sTAsyamAna', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'stand, stay, be present'}], 'isLemma': 0, 'parents': ['sTA']},
	{'value': 'sthita', 'label': 'sTita', 'attrib': [{'pos': 'ppp', 'defs': 'stand, stay, be present'}], 'isLemma': 0, 'parents': ['sTA']},
	{'value': 'sthitavat', 'label': 'sTitavat', 'attrib': [{'pos': 'pap', 'defs': 'stand, stay, be present'}], 'isLemma': 0, 'parents': ['sTA']},
	{'value': 'sthitvā', 'label': 'sTitvA', 'attrib': [{'pos': 'abs', 'defs': 'stand, stay, be present'}], 'isLemma': 0, 'parents': ['sTA']},
	{'value': 'sthira', 'label': 'sTira', 'attrib': [{'pos': 'adj', 'defs': 'steady, firm'}], 'isLemma': 1},
	{'value': 'sthīyamāna', 'label': 'sTIyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'stand, stay, be present'}], 'isLemma': 0, 'parents': ['sTA']},
	{'value': 'stheya', 'label': 'sTeya', 'attrib': [{'pos': 'ger', 'defs': 'stand, stay, be present'}], 'isLemma': 0, 'parents': ['sTA']},
	{'value': 'spanda', 'label': 'spanda', 'attrib': [{'pos': 'm', 'defs': 'vibration, activity'}], 'isLemma': 1},
	{'value': 'syat', 'label': 'syat', 'attrib': [{'pos': 'prap', 'defs': 'end, finish'}], 'isLemma': 0, 'parents': ['so']},
	{'value': 'syamāna', 'label': 'syamAna', 'attrib': [{'pos': 'prmp', 'defs': 'end, finish'}], 'isLemma': 0, 'parents': ['so']},
	{'value': 'srabdha', 'label': 'srabDa', 'attrib': [{'pos': 'ppp', 'defs': 'be confident, relax'}], 'isLemma': 0, 'parents': ['sramB']},
	{'value': 'srabdhavat', 'label': 'srabDavat', 'attrib': [{'pos': 'pap', 'defs': 'be confident, relax'}], 'isLemma': 0, 'parents': ['sramB']},
	{'value': 'srabdhvā', 'label': 'srabDvA', 'attrib': [{'pos': 'abs', 'defs': 'be confident, relax'}], 'isLemma': 0, 'parents': ['sramB']},
	{'value': 'srabhyamāṇa', 'label': 'sraByamARa', 'attrib': [{'pos': 'prpp', 'defs': 'be confident, relax'}], 'isLemma': 0, 'parents': ['sramB']},
	{'value': 'srambh', 'label': 'sramB', 'attrib': [{'pos': 'vi1m', 'defs': 'be confident, relax'}], 'isLemma': 1},
	{'value': 'srambhaṇīya', 'label': 'sramBaRIya', 'attrib': [{'pos': 'ger', 'defs': 'be confident, relax'}], 'isLemma': 0, 'parents': ['sramB']},
	{'value': 'srambhamāṇa', 'label': 'sramBamARa', 'attrib': [{'pos': 'prmp', 'defs': 'be confident, relax'}], 'isLemma': 0, 'parents': ['sramB']},
	{'value': 'srambhitavya', 'label': 'sramBitavya', 'attrib': [{'pos': 'ger', 'defs': 'be confident, relax'}], 'isLemma': 0, 'parents': ['sramB']},
	{'value': 'srambhitum', 'label': 'sramBitum', 'attrib': [{'pos': 'inf', 'defs': 'be confident, relax'}], 'isLemma': 0, 'parents': ['sramB']},
	{'value': 'srambhitvā', 'label': 'sramBitvA', 'attrib': [{'pos': 'abs', 'defs': 'be confident, relax'}], 'isLemma': 0, 'parents': ['sramB']},
	{'value': 'srambhiṣyamāṇa', 'label': 'sramBizyamARa', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'be confident, relax'}], 'isLemma': 0, 'parents': ['sramB']},
	{'value': 'srambhya', 'label': 'sramBya', 'attrib': [{'pos': 'ger', 'defs': 'be confident, relax'}], 'isLemma': 0, 'parents': ['sramB']},
	{'value': 'sva', 'label': 'sva', 'attrib': [{'pos': 'pron, pron_adj', 'defs': "one's own"}], 'isLemma': 1},
	{'value': 'svaka', 'label': 'svaka', 'attrib': [{'pos': 'adj', 'defs': "own, one's own"}], 'isLemma': 1},
	{'value': 'svap', 'label': 'svap', 'attrib': [{'pos': 'vi2a', 'defs': 'sleep'}], 'isLemma': 1},
	{'value': 'svapat', 'label': 'svapat', 'attrib': [{'pos': 'prap', 'defs': 'sleep'}], 'isLemma': 0, 'parents': ['svap']},
	{'value': 'svapanīya', 'label': 'svapanIya', 'attrib': [{'pos': 'ger', 'defs': 'sleep'}], 'isLemma': 0, 'parents': ['svap']},
	{'value': 'svaptavya', 'label': 'svaptavya', 'attrib': [{'pos': 'ger', 'defs': 'sleep'}], 'isLemma': 0, 'parents': ['svap']},
	{'value': 'svaptum', 'label': 'svaptum', 'attrib': [{'pos': 'inf', 'defs': 'sleep'}], 'isLemma': 0, 'parents': ['svap']},
	{'value': 'svapya', 'label': 'svapya', 'attrib': [{'pos': 'ger', 'defs': 'sleep'}], 'isLemma': 0, 'parents': ['svap']},
	{'value': 'svapsyat', 'label': 'svapsyat', 'attrib': [{'pos': 'fap', 'defs': 'sleep'}], 'isLemma': 0, 'parents': ['svap']},
	{'value': 'svapsyamāṇa', 'label': 'svapsyamARa', 'attrib': [{'pos': 'fpp', 'defs': 'sleep'}], 'isLemma': 0, 'parents': ['svap']},
	{'value': 'svamandira', 'label': 'svamandira', 'attrib': [{'pos': 'n', 'defs': "one's own house, palace"}], 'isLemma': 1},
	{'value': 'svar', 'label': 'svar', 'attrib': [{'pos': 'pcl', 'defs': 'sun, heaven'}], 'isLemma': 1},
	{'value': 'svarga', 'label': 'svarga', 'attrib': [{'pos': 'm', 'defs': 'heaven'}], 'isLemma': 1},
	{'value': 'svargastha', 'label': 'svargasTa', 'attrib': [{'pos': 'adj', 'defs': 'standing in, present in heaven'}], 'isLemma': 1},
	{'value': 'ha', 'label': 'ha', 'attrib': [{'pos': 'pcl', 'defs': 'indeed'}, {'pos': 'adj', 'defs': 'smiting'}], 'isLemma': 1},
	{'value': 'haṁsyat', 'label': 'haMsyat', 'attrib': [{'pos': 'fap', 'defs': 'smite'}], 'isLemma': 0, 'parents': ['han']},
	{'value': 'haṁsyamāna', 'label': 'haMsyamAna', 'attrib': [{'pos': 'fpp', 'defs': 'smite'}], 'isLemma': 0, 'parents': ['han']},
	{'value': 'hata', 'label': 'hata', 'attrib': [{'pos': 'ppp', 'defs': 'smite'}], 'isLemma': 0, 'parents': ['han']},
	{'value': 'hatavat', 'label': 'hatavat', 'attrib': [{'pos': 'pap', 'defs': 'smite'}], 'isLemma': 0, 'parents': ['han']},
	{'value': 'hatvā', 'label': 'hatvA', 'attrib': [{'pos': 'abs', 'defs': 'smite'}], 'isLemma': 0, 'parents': ['han']},
	{'value': 'han', 'label': 'han', 'attrib': [{'pos': 'vt2a', 'defs': 'smite'}, {'pos': 'adj', 'defs': 'smiting'}], 'isLemma': 1},
	{'value': 'hananīya', 'label': 'hananIya', 'attrib': [{'pos': 'ger', 'defs': 'smite'}], 'isLemma': 0, 'parents': ['han']},
	{'value': 'haniṣyat', 'label': 'hanizyat', 'attrib': [{'pos': 'fap', 'defs': 'smite'}], 'isLemma': 0, 'parents': ['han']},
	{'value': 'haniṣyamāṇa', 'label': 'hanizyamARa', 'attrib': [{'pos': 'fpp', 'defs': 'smite'}], 'isLemma': 0, 'parents': ['han']},
	{'value': 'hantavya', 'label': 'hantavya', 'attrib': [{'pos': 'ger', 'defs': 'smite'}], 'isLemma': 0, 'parents': ['han']},
	{'value': 'hantum', 'label': 'hantum', 'attrib': [{'pos': 'inf', 'defs': 'smite'}], 'isLemma': 0, 'parents': ['han']},
	{'value': 'hanyamāna', 'label': 'hanyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'smite'}], 'isLemma': 0, 'parents': ['han']},
	{'value': 'havanīya', 'label': 'havanIya', 'attrib': [{'pos': 'ger', 'defs': 'offer, worship'}], 'isLemma': 0, 'parents': ['hu']},
	{'value': 'havya', 'label': 'havya', 'attrib': [{'pos': 'n', 'defs': 'oblation'}, {'pos': 'ger', 'defs': 'offer, worship'}], 'isLemma': 1},
	{'value': 'havya', 'label': 'havya', 'attrib': [{'pos': 'n', 'defs': 'oblation'}, {'pos': 'ger', 'defs': 'offer, worship'}], 'isLemma': 0, 'parents': ['hu']},
	{'value': 'havyavāhana', 'label': 'havyavAhana', 'attrib': [{'pos': 'm', 'defs': 'oblation-bearer, fire'}], 'isLemma': 1},
	{'value': 'hasta', 'label': 'hasta', 'attrib': [{'pos': 'm', 'defs': 'hand'}], 'isLemma': 1},
	{'value': 'hastin', 'label': 'hastin', 'attrib': [{'pos': 'adj', 'defs': 'having a hand'}, {'pos': 'm', 'defs': 'elephant'}, {'pos': 'm_pn', 'defs': 'Hastin'}], 'isLemma': 1},
	{'value': 'hāstina', 'label': 'hAstina', 'attrib': [{'pos': 'adj', 'defs': 'founded by Hastin'}], 'isLemma': 1},
	{'value': 'hāstinapura', 'label': 'hAstinapura', 'attrib': [{'pos': 'n_pn', 'defs': 'Hāstinapura'}], 'isLemma': 1},
	{'value': 'hi', 'label': 'hi', 'attrib': [{'pos': 'sub_pcl', 'defs': 'for, because'}], 'isLemma': 1},
	{'value': 'hiḍimba', 'label': 'hiqimba', 'attrib': [{'pos': 'm_pn', 'defs': 'Hiḍimba'}], 'isLemma': 1},
	{'value': 'hita', 'label': 'hita', 'attrib': [{'pos': 'ppp', 'defs': 'put'}, {'pos': 'adj', 'defs': 'beneficial, good'}], 'isLemma': 1},
	{'value': 'hita', 'label': 'hita', 'attrib': [{'pos': 'ppp', 'defs': 'put'}, {'pos': 'adj', 'defs': 'beneficial, good'}], 'isLemma': 0, 'parents': ['DA']},
	{'value': 'hitavat', 'label': 'hitavat', 'attrib': [{'pos': 'pap', 'defs': 'put'}], 'isLemma': 0, 'parents': ['DA']},
	{'value': 'hitvā', 'label': 'hitvA', 'attrib': [{'pos': 'abs', 'defs': 'put'}], 'isLemma': 0, 'parents': ['DA']},
	{'value': 'hu', 'label': 'hu', 'attrib': [{'pos': 'vt3a', 'defs': 'offer, worship'}], 'isLemma': 1},
	{'value': 'huta', 'label': 'huta', 'attrib': [{'pos': 'ppp', 'defs': 'offer, worship'}], 'isLemma': 0, 'parents': ['hu']},
	{'value': 'hutavat', 'label': 'hutavat', 'attrib': [{'pos': 'pap', 'defs': 'offer, worship'}], 'isLemma': 0, 'parents': ['hu']},
	{'value': 'hutvā', 'label': 'hutvA', 'attrib': [{'pos': 'abs', 'defs': 'offer, worship'}], 'isLemma': 0, 'parents': ['hu']},
	{'value': 'hūyamāna', 'label': 'hUyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'offer, worship'}], 'isLemma': 0, 'parents': ['hu']},
	{'value': 'hṛd', 'label': 'hfd', 'attrib': [{'pos': 'n', 'defs': 'heart'}], 'isLemma': 1},
	{'value': 'hṛṣ', 'label': 'hfz', 'attrib': [{'pos': 'vi4a', 'defs': 'be excited, rejoice'}], 'isLemma': 1},
	{'value': 'hṛṣīka', 'label': 'hfzIka', 'attrib': [{'pos': 'n', 'defs': 'sense-organ'}], 'isLemma': 1},
	{'value': 'hṛṣīkeśa', 'label': 'hfzIkeSa', 'attrib': [{'pos': 'm', 'defs': 'lord of the senses'}], 'isLemma': 1},
	{'value': 'he', 'label': 'he', 'attrib': [{'pos': 'pcl', 'defs': 'O, hey'}], 'isLemma': 1},
	{'value': 'hotavya', 'label': 'hotavya', 'attrib': [{'pos': 'ger', 'defs': 'offer, worship'}], 'isLemma': 0, 'parents': ['hu']},
	{'value': 'hotum', 'label': 'hotum', 'attrib': [{'pos': 'inf', 'defs': 'offer, worship'}], 'isLemma': 0, 'parents': ['hu']},
	{'value': 'hoṣyat', 'label': 'hozyat', 'attrib': [{'pos': 'fap', 'defs': 'offer, worship'}], 'isLemma': 0, 'parents': ['hu']},
	{'value': 'hoṣyamāṇa', 'label': 'hozyamARa', 'attrib': [{'pos': 'fpp', 'defs': 'offer, worship'}], 'isLemma': 0, 'parents': ['hu']}
]

