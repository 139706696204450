export const vocab_deva = [
	{'value': 'अक्लिष्ट', 'label': 'aklizwa', 'attrib': [{'pos': 'adj', 'defs': 'unafflicted, untarnished'}], 'isLemma': 1},
	{'value': 'अक्लिष्टकर्मन्', 'label': 'aklizwakarman', 'attrib': [{'pos': 'adj', 'defs': 'of untarnished deeds'}], 'isLemma': 1},
	{'value': 'अक्ष', 'label': 'akza', 'attrib': [{'pos': 'm', 'defs': 'a die for gambling'}], 'isLemma': 1},
	{'value': 'अक्षय', 'label': 'akzaya', 'attrib': [{'pos': 'adj', 'defs': 'imperishable, inexhaustible'}], 'isLemma': 1},
	{'value': 'अग्नि', 'label': 'agni', 'attrib': [{'pos': 'm', 'defs': 'fire'}], 'isLemma': 1},
	{'value': 'अङ्ग', 'label': 'aNga', 'attrib': [{'pos': 'n', 'defs': 'limb, body'}, {'pos': 'm', 'defs': 'Bengal'}], 'isLemma': 1},
	{'value': 'अङ्गदेश', 'label': 'aNgadeSa', 'attrib': [{'pos': 'm', 'defs': 'place on the body'}], 'isLemma': 1},
	{'value': 'अचिर', 'label': 'acira', 'attrib': [{'pos': 'adj', 'defs': 'not a long time'}], 'isLemma': 1},
	{'value': 'अज्ञात', 'label': 'ajYAta', 'attrib': [{'pos': 'adj', 'defs': 'unknown'}], 'isLemma': 1},
	{'value': 'अञ्च्', 'label': 'aYc', 'attrib': [{'pos': 'v1am', 'defs': 'bend'}], 'isLemma': 1},
	{'value': 'अति', 'label': 'ati', 'attrib': [{'pos': 'preverb', 'defs': 'beyond'}], 'isLemma': 1},
	{'value': 'अतिभार', 'label': 'atiBAra', 'attrib': [{'pos': 'm', 'defs': 'an excessive burden'}], 'isLemma': 1},
	{'value': 'अत्तव्य', 'label': 'attavya', 'attrib': [{'pos': 'ger', 'defs': 'eat'}], 'isLemma': 0, 'parents': ['ad']},
	{'value': 'अत्तुम्', 'label': 'attum', 'attrib': [{'pos': 'inf', 'defs': 'eat'}], 'isLemma': 0, 'parents': ['ad']},
	{'value': 'अत्त्वा', 'label': 'attvA', 'attrib': [{'pos': 'abs', 'defs': 'eat'}], 'isLemma': 0, 'parents': ['ad']},
	{'value': 'अत्स्यत्', 'label': 'atsyat', 'attrib': [{'pos': 'fap', 'defs': 'eat'}], 'isLemma': 0, 'parents': ['ad']},
	{'value': 'अत्स्यमान', 'label': 'atsyamAna', 'attrib': [{'pos': 'fpp', 'defs': 'eat'}], 'isLemma': 0, 'parents': ['ad']},
	{'value': 'अथ', 'label': 'aTa', 'attrib': [{'pos': 'pcl', 'defs': 'now, then'}], 'isLemma': 1},
	{'value': 'अद्', 'label': 'ad', 'attrib': [{'pos': 'vt2a', 'defs': 'eat'}], 'isLemma': 1},
	{'value': 'अदत्', 'label': 'adat', 'attrib': [{'pos': 'prap', 'defs': 'eat'}], 'isLemma': 0, 'parents': ['ad']},
	{'value': 'अदनीय', 'label': 'adanIya', 'attrib': [{'pos': 'ger', 'defs': 'eat'}], 'isLemma': 0, 'parents': ['ad']},
	{'value': 'अद्यमान', 'label': 'adyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'eat'}], 'isLemma': 0, 'parents': ['ad']},
	{'value': 'अधि', 'label': 'aDi', 'attrib': [{'pos': 'preverb', 'defs': 'over'}], 'isLemma': 1},
	{'value': 'अधिजगान', 'label': 'aDijagAna', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'go over, study, read, recite'}], 'isLemma': 0, 'parents': ['aDi_i']},
	{'value': 'अधि_इ', 'label': 'aDi_i', 'attrib': [{'pos': 'vt2m', 'defs': 'go over, study, read, recite'}], 'isLemma': 1},
	{'value': 'अधीत', 'label': 'aDIta', 'attrib': [{'pos': 'ppp', 'defs': 'go over, study, read, recite'}], 'isLemma': 0, 'parents': ['aDi_i']},
	{'value': 'अधीतवत्', 'label': 'aDItavat', 'attrib': [{'pos': 'pap', 'defs': 'go over, study, read, recite'}], 'isLemma': 0, 'parents': ['aDi_i']},
	{'value': 'अधीत्य', 'label': 'aDItya', 'attrib': [{'pos': 'abs', 'defs': 'go over, study, read, recite'}], 'isLemma': 0, 'parents': ['aDi_i']},
	{'value': 'अधीयमान', 'label': 'aDIyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'go over, study, read, recite'}], 'isLemma': 0, 'parents': ['aDi_i']},
	{'value': 'अधीयान', 'label': 'aDIyAna', 'attrib': [{'pos': 'prmp', 'defs': 'go over, study, read, recite'}], 'isLemma': 0, 'parents': ['aDi_i']},
	{'value': 'अध्ययनीय', 'label': 'aDyayanIya', 'attrib': [{'pos': 'ger', 'defs': 'go over, study, read, recite'}], 'isLemma': 0, 'parents': ['aDi_i']},
	{'value': 'अध्याय', 'label': 'aDyAya', 'attrib': [{'pos': 'm', 'defs': 'chapter'}], 'isLemma': 1},
	{'value': 'अध्येतव्य', 'label': 'aDyetavya', 'attrib': [{'pos': 'ger', 'defs': 'go over, study, read, recite'}], 'isLemma': 0, 'parents': ['aDi_i']},
	{'value': 'अध्येतुम्', 'label': 'aDyetum', 'attrib': [{'pos': 'inf', 'defs': 'go over, study, read, recite'}], 'isLemma': 0, 'parents': ['aDi_i']},
	{'value': 'अध्येय', 'label': 'aDyeya', 'attrib': [{'pos': 'ger', 'defs': 'go over, study, read, recite'}], 'isLemma': 0, 'parents': ['aDi_i']},
	{'value': 'अध्येष्यमाण', 'label': 'aDyezyamARa', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'go over, study, read, recite'}], 'isLemma': 0, 'parents': ['aDi_i']},
	{'value': 'अनु', 'label': 'anu', 'attrib': [{'pos': 'preverb', 'defs': 'after'}], 'isLemma': 1},
	{'value': 'अनुचिन्तित', 'label': 'anucintita', 'attrib': [{'pos': 'ppp', 'defs': 'consider'}], 'isLemma': 0, 'parents': ['anu_cint']},
	{'value': 'अनुज', 'label': 'anuja', 'attrib': [{'pos': 'adj', 'defs': 'born after'}, {'pos': 'm', 'defs': 'younger brother'}], 'isLemma': 1},
	{'value': 'अनुजज्ञान', 'label': 'anujajYAna', 'attrib': [{'pos': 'pfpp', 'defs': 'consent, permit'}], 'isLemma': 0, 'parents': ['anu_jYA']},
	{'value': 'अनुजज्ञिवस्', 'label': 'anujajYivas', 'attrib': [{'pos': 'pfap', 'defs': 'consent, permit'}], 'isLemma': 0, 'parents': ['anu_jYA']},
	{'value': 'अनुजा', 'label': 'anujA', 'attrib': [{'pos': 'f', 'defs': 'younger sister'}], 'isLemma': 1},
	{'value': 'अनुजानत्', 'label': 'anujAnat', 'attrib': [{'pos': 'prap', 'defs': 'consent, permit'}], 'isLemma': 0, 'parents': ['anu_jYA']},
	{'value': 'अनुज्ञात', 'label': 'anujYAta', 'attrib': [{'pos': 'ppp', 'defs': 'consent, permit'}], 'isLemma': 0, 'parents': ['anu_jYA']},
	{'value': 'अनुज्ञातवत्', 'label': 'anujYAtavat', 'attrib': [{'pos': 'pap', 'defs': 'consent, permit'}], 'isLemma': 0, 'parents': ['anu_jYA']},
	{'value': 'अनुज्ञातव्य', 'label': 'anujYAtavya', 'attrib': [{'pos': 'ger', 'defs': 'consent, permit'}], 'isLemma': 0, 'parents': ['anu_jYA']},
	{'value': 'अनुज्ञातुम्', 'label': 'anujYAtum', 'attrib': [{'pos': 'inf', 'defs': 'consent, permit'}], 'isLemma': 0, 'parents': ['anu_jYA']},
	{'value': 'अनुज्ञानीय', 'label': 'anujYAnIya', 'attrib': [{'pos': 'ger', 'defs': 'consent, permit'}], 'isLemma': 0, 'parents': ['anu_jYA']},
	{'value': 'अनुज्ञाप्य', 'label': 'anujYApya', 'attrib': [{'pos': 'abs', 'defs': 'consent, permit'}], 'isLemma': 0, 'parents': ['anu_jYA']},
	{'value': 'अनुज्ञाय', 'label': 'anujYAya', 'attrib': [{'pos': 'abs', 'defs': 'consent, permit'}], 'isLemma': 0, 'parents': ['anu_jYA']},
	{'value': 'अनुज्ञायमान', 'label': 'anujYAyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'consent, permit'}], 'isLemma': 0, 'parents': ['anu_jYA']},
	{'value': 'अनुज्ञास्यत्', 'label': 'anujYAsyat', 'attrib': [{'pos': 'fap', 'defs': 'consent, permit'}], 'isLemma': 0, 'parents': ['anu_jYA']},
	{'value': 'अनुज्ञास्यमान', 'label': 'anujYAsyamAna', 'attrib': [{'pos': 'fpp', 'defs': 'consent, permit'}], 'isLemma': 0, 'parents': ['anu_jYA']},
	{'value': 'अनुज्ञेय', 'label': 'anujYeya', 'attrib': [{'pos': 'ger', 'defs': 'consent, permit'}], 'isLemma': 0, 'parents': ['anu_jYA']},
	{'value': 'अनु_चिन्त्', 'label': 'anu_cint', 'attrib': [{'pos': 'vt10am', 'defs': 'consider'}], 'isLemma': 1},
	{'value': 'अनु_जन्', 'label': 'anu_jan', 'attrib': [{'pos': 'vi4m', 'defs': 'be born after'}], 'isLemma': 1},
	{'value': 'अनु_ज्ञा', 'label': 'anu_jYA', 'attrib': [{'pos': 'vt9a', 'defs': 'consent, permit'}], 'isLemma': 1},
	{'value': 'अन्न', 'label': 'anna', 'attrib': [{'pos': 'ppp', 'defs': 'eat'}, {'pos': 'n', 'defs': 'food'}], 'isLemma': 1},
	{'value': 'अन्न', 'label': 'anna', 'attrib': [{'pos': 'ppp', 'defs': 'eat'}, {'pos': 'n', 'defs': 'food'}], 'isLemma': 0, 'parents': ['ad']},
	{'value': 'अन्नवत्', 'label': 'annavat', 'attrib': [{'pos': 'pap', 'defs': 'eat'}], 'isLemma': 0, 'parents': ['ad']},
	{'value': 'अन्य', 'label': 'anya', 'attrib': [{'pos': 'pron, pron_adj', 'defs': 'other'}], 'isLemma': 1},
	{'value': 'अपि', 'label': 'api', 'attrib': [{'pos': 'pcl', 'defs': 'even'}], 'isLemma': 1},
	{'value': 'अप्रमत्त', 'label': 'apramatta', 'attrib': [{'pos': 'adj', 'defs': 'not careless, careful'}], 'isLemma': 1},
	{'value': 'अप्रमत्तोत्थित', 'label': 'apramattotTita', 'attrib': [{'pos': 'adj', 'defs': 'risen carefully'}], 'isLemma': 1},
	{'value': 'अमित', 'label': 'amita', 'attrib': [{'pos': 'adj', 'defs': 'unmeasured, infinite'}], 'isLemma': 1},
	{'value': 'अमिततेजस्', 'label': 'amitatejas', 'attrib': [{'pos': 'adj', 'defs': 'infinitely brilliant'}], 'isLemma': 1},
	{'value': 'अमितौजस्', 'label': 'amitOjas', 'attrib': [{'pos': 'adj', 'defs': 'infinitely vigorous'}], 'isLemma': 1},
	{'value': 'अयनीय', 'label': 'ayanIya', 'attrib': [{'pos': 'ger', 'defs': 'go'}], 'isLemma': 0, 'parents': ['i']},
	{'value': 'अरि', 'label': 'ari', 'attrib': [{'pos': 'm', 'defs': 'enemy'}], 'isLemma': 1},
	{'value': 'अरिंदम', 'label': 'ariMdama', 'attrib': [{'pos': 'm', 'defs': 'enemy tamer'}], 'isLemma': 1},
	{'value': 'अर्जुन', 'label': 'arjuna', 'attrib': [{'pos': 'm_pn', 'defs': 'Arjuna'}], 'isLemma': 1},
	{'value': 'अर्थ', 'label': 'arTa', 'attrib': [{'pos': 'm', 'defs': 'aim, object'}], 'isLemma': 1},
	{'value': 'अव', 'label': 'ava', 'attrib': [{'pos': 'preverb', 'defs': 'down'}], 'isLemma': 1},
	{'value': 'अव_धा', 'label': 'ava_DA', 'attrib': [{'pos': 'vt3am', 'defs': 'put down, deposit, apply'}], 'isLemma': 1},
	{'value': 'अवसो', 'label': 'avaso', 'attrib': [{'pos': 'v4a', 'defs': 'end, finish'}], 'isLemma': 1},
	{'value': 'अवहित', 'label': 'avahita', 'attrib': [{'pos': 'ppp', 'defs': 'put down, deposit, apply'}, {'pos': 'adj', 'defs': 'attentive'}], 'isLemma': 1},
	{'value': 'अवहित', 'label': 'avahita', 'attrib': [{'pos': 'ppp', 'defs': 'put down, deposit, apply'}, {'pos': 'adj', 'defs': 'attentive'}], 'isLemma': 0, 'parents': ['ava_DA']},
	{'value': 'अस्', 'label': 'as', 'attrib': [{'pos': 'vi2a', 'defs': 'be'}], 'isLemma': 1},
	{'value': 'असुर', 'label': 'asura', 'attrib': [{'pos': 'm', 'defs': 'demon'}], 'isLemma': 1},
	{'value': 'अस्मद्', 'label': 'asmad', 'attrib': [{'pos': 'pers_pron', 'defs': 'we, us'}], 'isLemma': 1},
	{'value': 'अहित', 'label': 'ahita', 'attrib': [{'pos': 'adj', 'defs': 'not good'}, {'pos': 'n', 'defs': 'enemy'}], 'isLemma': 1},
	{'value': 'आ', 'label': 'A', 'attrib': [{'pos': 'preverb', 'defs': 'unto'}], 'isLemma': 1},
	{'value': 'आ_इ', 'label': 'A_i', 'attrib': [{'pos': 'vt2a', 'defs': 'come'}], 'isLemma': 1},
	{'value': 'आ_गम्', 'label': 'A_gam', 'attrib': [{'pos': 'v1a', 'defs': 'come'}], 'isLemma': 1},
	{'value': 'आ_चर् ', 'label': 'A_car ', 'attrib': [{'pos': 'vt1a', 'defs': 'move, do'}], 'isLemma': 1},
	{'value': 'आ_चि', 'label': 'A_ci', 'attrib': [{'pos': 'vt5am', 'defs': 'accumulate'}], 'isLemma': 1},
	{'value': 'आ_दा', 'label': 'A_dA', 'attrib': [{'pos': 'vt3am', 'defs': 'receive, take'}], 'isLemma': 1},
	{'value': 'आ_दिश्', 'label': 'A_diS', 'attrib': [{'pos': 'vt6am', 'defs': 'point out, assign, order'}], 'isLemma': 1},
	{'value': 'आ_धा', 'label': 'A_DA', 'attrib': [{'pos': 'vt3am', 'defs': 'put on, apply'}], 'isLemma': 1},
	{'value': 'आ_धी', 'label': 'A_DI', 'attrib': [{'pos': 'vt2m', 'defs': 'recite, read, study'}], 'isLemma': 1},
	{'value': 'आ_भा', 'label': 'A_BA', 'attrib': [{'pos': 'vi2a', 'defs': 'shine, appear'}], 'isLemma': 1},
	{'value': 'आ_रभ्', 'label': 'A_raB', 'attrib': [{'pos': 'vt1m', 'defs': 'begin'}], 'isLemma': 1},
	{'value': 'आ_वह्', 'label': 'A_vah', 'attrib': [{'pos': 'vt1am', 'defs': 'carry towards, bring'}], 'isLemma': 1},
	{'value': 'आ_व्रज्', 'label': 'A_vraj', 'attrib': [{'pos': 'vt1a', 'defs': 'walk to'}], 'isLemma': 1},
	{'value': 'आत्त', 'label': 'Atta', 'attrib': [{'pos': 'ppp', 'defs': 'receive, take'}], 'isLemma': 0, 'parents': ['A_dA']},
	{'value': 'आदत्तवत्', 'label': 'Adattavat', 'attrib': [{'pos': 'pap', 'defs': 'receive, take'}], 'isLemma': 0, 'parents': ['A_dA']},
	{'value': 'आददत्', 'label': 'Adadat', 'attrib': [{'pos': 'prap', 'defs': 'receive, take'}], 'isLemma': 0, 'parents': ['A_dA']},
	{'value': 'आददान', 'label': 'AdadAna', 'attrib': [{'pos': 'prmp', 'defs': 'receive, take'}, {'pos': 'pfmp, pfpp', 'defs': 'receive, take'}], 'isLemma': 0, 'parents': ['A_dA']},
	{'value': 'आददिवस्', 'label': 'Adadivas', 'attrib': [{'pos': 'pfap', 'defs': 'receive, take'}], 'isLemma': 0, 'parents': ['A_dA']},
	{'value': 'आदधत्', 'label': 'AdaDat', 'attrib': [{'pos': 'prap', 'defs': 'put on, apply'}], 'isLemma': 0, 'parents': ['A_DA']},
	{'value': 'आदधान', 'label': 'AdaDAna', 'attrib': [{'pos': 'prmp', 'defs': 'put on, apply'}, {'pos': 'pfmp, pfpp', 'defs': 'put on, apply'}], 'isLemma': 0, 'parents': ['A_DA']},
	{'value': 'आदधिवस्', 'label': 'AdaDivas', 'attrib': [{'pos': 'pfap', 'defs': 'put on, apply'}], 'isLemma': 0, 'parents': ['A_DA']},
	{'value': 'आदातव्य', 'label': 'AdAtavya', 'attrib': [{'pos': 'ger', 'defs': 'receive, take'}], 'isLemma': 0, 'parents': ['A_dA']},
	{'value': 'आदातुम्', 'label': 'AdAtum', 'attrib': [{'pos': 'inf', 'defs': 'receive, take'}], 'isLemma': 0, 'parents': ['A_dA']},
	{'value': 'आदान', 'label': 'AdAna', 'attrib': [{'pos': 'pfpp', 'defs': 'eat'}], 'isLemma': 0, 'parents': ['ad']},
	{'value': 'आदानीय', 'label': 'AdAnIya', 'attrib': [{'pos': 'ger', 'defs': 'receive, take'}], 'isLemma': 0, 'parents': ['A_dA']},
	{'value': 'आदाय', 'label': 'AdAya', 'attrib': [{'pos': 'abs', 'defs': 'receive, take'}], 'isLemma': 0, 'parents': ['A_dA']},
	{'value': 'आदास्यत्', 'label': 'AdAsyat', 'attrib': [{'pos': 'fap', 'defs': 'receive, take'}], 'isLemma': 0, 'parents': ['A_dA']},
	{'value': 'आदास्यमान', 'label': 'AdAsyamAna', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'receive, take'}], 'isLemma': 0, 'parents': ['A_dA']},
	{'value': 'आदि', 'label': 'Adi', 'attrib': [{'pos': 'm', 'defs': 'beginning'}], 'isLemma': 1},
	{'value': 'आदिपर्वन्', 'label': 'Adiparvan', 'attrib': [{'pos': 'n', 'defs': 'first book'}], 'isLemma': 1},
	{'value': 'आदिवस्', 'label': 'Adivas', 'attrib': [{'pos': 'pfap', 'defs': 'eat'}], 'isLemma': 0, 'parents': ['ad']},
	{'value': 'आदीयमान', 'label': 'AdIyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'receive, take'}], 'isLemma': 0, 'parents': ['A_dA']},
	{'value': 'आदेय', 'label': 'Adeya', 'attrib': [{'pos': 'ger', 'defs': 'receive, take'}], 'isLemma': 0, 'parents': ['A_dA']},
	{'value': 'आद्य', 'label': 'Adya', 'attrib': [{'pos': 'ger', 'defs': 'eat'}], 'isLemma': 0, 'parents': ['ad']},
	{'value': 'आधातव्य', 'label': 'ADAtavya', 'attrib': [{'pos': 'ger', 'defs': 'put on, apply'}], 'isLemma': 0, 'parents': ['A_DA']},
	{'value': 'आधातुम्', 'label': 'ADAtum', 'attrib': [{'pos': 'inf', 'defs': 'put on, apply'}], 'isLemma': 0, 'parents': ['A_DA']},
	{'value': 'आधानीय', 'label': 'ADAnIya', 'attrib': [{'pos': 'ger', 'defs': 'put on, apply'}], 'isLemma': 0, 'parents': ['A_DA']},
	{'value': 'आधाय', 'label': 'ADAya', 'attrib': [{'pos': 'abs', 'defs': 'put on, apply'}], 'isLemma': 0, 'parents': ['A_DA']},
	{'value': 'आधास्यत्', 'label': 'ADAsyat', 'attrib': [{'pos': 'fap', 'defs': 'put on, apply'}], 'isLemma': 0, 'parents': ['A_DA']},
	{'value': 'आधास्यमान', 'label': 'ADAsyamAna', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'put on, apply'}], 'isLemma': 0, 'parents': ['A_DA']},
	{'value': 'आधीयमान', 'label': 'ADIyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'put on, apply'}], 'isLemma': 0, 'parents': ['A_DA']},
	{'value': 'आधेय', 'label': 'ADeya', 'attrib': [{'pos': 'ger', 'defs': 'put on, apply'}], 'isLemma': 0, 'parents': ['A_DA']},
	{'value': 'आप्', 'label': 'Ap', 'attrib': [{'pos': 'vt5a', 'defs': 'attain'}], 'isLemma': 1},
	{'value': 'आपनीय', 'label': 'ApanIya', 'attrib': [{'pos': 'ger', 'defs': 'attain'}], 'isLemma': 0, 'parents': ['Ap']},
	{'value': 'आपान', 'label': 'ApAna', 'attrib': [{'pos': 'pfpp', 'defs': 'attain'}], 'isLemma': 0, 'parents': ['Ap']},
	{'value': 'आपिवस्', 'label': 'Apivas', 'attrib': [{'pos': 'pfap', 'defs': 'attain'}], 'isLemma': 0, 'parents': ['Ap']},
	{'value': 'आप्त', 'label': 'Apta', 'attrib': [{'pos': 'ppp', 'defs': 'attain'}], 'isLemma': 0, 'parents': ['Ap']},
	{'value': 'आप्तवत्', 'label': 'Aptavat', 'attrib': [{'pos': 'pap', 'defs': 'attain'}], 'isLemma': 0, 'parents': ['Ap']},
	{'value': 'आप्तव्य', 'label': 'Aptavya', 'attrib': [{'pos': 'ger', 'defs': 'attain'}], 'isLemma': 0, 'parents': ['Ap']},
	{'value': 'आप्तुम्', 'label': 'Aptum', 'attrib': [{'pos': 'inf', 'defs': 'attain'}], 'isLemma': 0, 'parents': ['Ap']},
	{'value': 'आप्त्वा', 'label': 'AptvA', 'attrib': [{'pos': 'abs', 'defs': 'attain'}], 'isLemma': 0, 'parents': ['Ap']},
	{'value': 'आप्नुवत्', 'label': 'Apnuvat', 'attrib': [{'pos': 'prap', 'defs': 'attain'}], 'isLemma': 0, 'parents': ['Ap']},
	{'value': 'आप्य', 'label': 'Apya', 'attrib': [{'pos': 'ger', 'defs': 'attain'}], 'isLemma': 0, 'parents': ['Ap']},
	{'value': 'आप्यमान', 'label': 'ApyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'attain'}], 'isLemma': 0, 'parents': ['Ap']},
	{'value': 'आप्स्यत्', 'label': 'Apsyat', 'attrib': [{'pos': 'fap', 'defs': 'attain'}], 'isLemma': 0, 'parents': ['Ap']},
	{'value': 'आप्स्यमान', 'label': 'ApsyamAna', 'attrib': [{'pos': 'fpp', 'defs': 'attain'}], 'isLemma': 0, 'parents': ['Ap']},
	{'value': 'आयत्', 'label': 'Ayat', 'attrib': [{'pos': 'prap', 'defs': 'come'}], 'isLemma': 0, 'parents': ['A_i']},
	{'value': 'आयनीय', 'label': 'AyanIya', 'attrib': [{'pos': 'ger', 'defs': 'come'}], 'isLemma': 0, 'parents': ['A_i']},
	{'value': 'आरप्स्यमान', 'label': 'ArapsyamAna', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'begin'}], 'isLemma': 0, 'parents': ['A_raB']},
	{'value': 'आरब्ध', 'label': 'ArabDa', 'attrib': [{'pos': 'ppp', 'defs': 'begin'}], 'isLemma': 0, 'parents': ['A_raB']},
	{'value': 'आरब्धवत्', 'label': 'ArabDavat', 'attrib': [{'pos': 'pap', 'defs': 'begin'}], 'isLemma': 0, 'parents': ['A_raB']},
	{'value': 'आरब्धव्य', 'label': 'ArabDavya', 'attrib': [{'pos': 'ger', 'defs': 'begin'}], 'isLemma': 0, 'parents': ['A_raB']},
	{'value': 'आरब्धुम्', 'label': 'ArabDum', 'attrib': [{'pos': 'inf', 'defs': 'begin'}], 'isLemma': 0, 'parents': ['A_raB']},
	{'value': 'आरभमाण', 'label': 'AraBamARa', 'attrib': [{'pos': 'prmp', 'defs': 'begin'}], 'isLemma': 0, 'parents': ['A_raB']},
	{'value': 'आरभ्य', 'label': 'AraBya', 'attrib': [{'pos': 'abs', 'defs': 'begin'}, {'pos': 'ger', 'defs': 'begin'}], 'isLemma': 0, 'parents': ['A_raB']},
	{'value': 'आरभ्यमाण', 'label': 'AraByamARa', 'attrib': [{'pos': 'prpp', 'defs': 'begin'}], 'isLemma': 0, 'parents': ['A_raB']},
	{'value': 'आरम्भनीय', 'label': 'AramBanIya', 'attrib': [{'pos': 'ger', 'defs': 'begin'}], 'isLemma': 0, 'parents': ['A_raB']},
	{'value': 'आरेभाण', 'label': 'AreBARa', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'begin'}], 'isLemma': 0, 'parents': ['A_raB']},
	{'value': 'आवह', 'label': 'Avaha', 'attrib': [{'pos': 'adj', 'defs': 'bringing'}], 'isLemma': 1},
	{'value': 'आशिस्', 'label': 'ASis', 'attrib': [{'pos': 'f', 'defs': "serpent's fang"}], 'isLemma': 1},
	{'value': 'आशी', 'label': 'ASI', 'attrib': [{'pos': 'f', 'defs': "serpent's fang"}], 'isLemma': 1},
	{'value': 'आशीविष', 'label': 'ASIviza', 'attrib': [{'pos': 'adj', 'defs': 'venom-fanged'}], 'isLemma': 1},
	{'value': 'आहित', 'label': 'Ahita', 'attrib': [{'pos': 'ppp', 'defs': 'put on, apply'}], 'isLemma': 0, 'parents': ['A_DA']},
	{'value': 'आहितवत्', 'label': 'Ahitavat', 'attrib': [{'pos': 'pap', 'defs': 'put on, apply'}], 'isLemma': 0, 'parents': ['A_DA']},
	{'value': 'इ', 'label': 'i', 'attrib': [{'pos': 'vt2a', 'defs': 'go'}], 'isLemma': 1},
	{'value': 'इच्छत्', 'label': 'icCat', 'attrib': [{'pos': 'prap', 'defs': 'desire'}], 'isLemma': 0, 'parents': ['iz']},
	{'value': 'इत', 'label': 'ita', 'attrib': [{'pos': 'ppp', 'defs': 'go'}], 'isLemma': 0, 'parents': ['i']},
	{'value': 'इतवत्', 'label': 'itavat', 'attrib': [{'pos': 'pap', 'defs': 'go'}], 'isLemma': 0, 'parents': ['i']},
	{'value': 'इति', 'label': 'iti', 'attrib': [{'pos': 'pcl', 'defs': 'thus, close quote'}], 'isLemma': 1},
	{'value': 'इत्य', 'label': 'itya', 'attrib': [{'pos': 'ger', 'defs': 'go'}], 'isLemma': 0, 'parents': ['i']},
	{'value': 'इत्वा', 'label': 'itvA', 'attrib': [{'pos': 'abs', 'defs': 'go'}], 'isLemma': 0, 'parents': ['i']},
	{'value': 'इदम्', 'label': 'idam', 'attrib': [{'pos': 'dem_adj, dem_pron', 'defs': 'this'}], 'isLemma': 1},
	{'value': 'इन्द्र', 'label': 'indra', 'attrib': [{'pos': 'm_pn', 'defs': 'Indra'}], 'isLemma': 1},
	{'value': 'इव', 'label': 'iva', 'attrib': [{'pos': 'pcl', 'defs': 'like, as, as if'}], 'isLemma': 1},
	{'value': 'इष्', 'label': 'iz', 'attrib': [{'pos': 'vt6a', 'defs': 'desire'}], 'isLemma': 1},
	{'value': 'इषु', 'label': 'izu', 'attrib': [{'pos': 'm, f', 'defs': 'arrow'}], 'isLemma': 1},
	{'value': 'इषुधि', 'label': 'izuDi', 'attrib': [{'pos': 'm', 'defs': 'quiver'}], 'isLemma': 1},
	{'value': 'इष्ट', 'label': 'izwa', 'attrib': [{'pos': 'ppp', 'defs': 'desire'}], 'isLemma': 0, 'parents': ['iz']},
	{'value': 'इष्टवत्', 'label': 'izwavat', 'attrib': [{'pos': 'pap', 'defs': 'desire'}], 'isLemma': 0, 'parents': ['iz']},
	{'value': 'इष्ट्वा', 'label': 'izwvA', 'attrib': [{'pos': 'abs', 'defs': 'desire'}], 'isLemma': 0, 'parents': ['iz']},
	{'value': 'इष्यमाण', 'label': 'izyamARa', 'attrib': [{'pos': 'prpp', 'defs': 'desire'}], 'isLemma': 0, 'parents': ['iz']},
	{'value': 'ईयमान', 'label': 'IyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'go'}], 'isLemma': 0, 'parents': ['i']},
	{'value': 'ईयान', 'label': 'IyAna', 'attrib': [{'pos': 'pfpp', 'defs': 'go'}], 'isLemma': 0, 'parents': ['i']},
	{'value': 'ईयिवस्', 'label': 'Iyivas', 'attrib': [{'pos': 'pfap', 'defs': 'go'}], 'isLemma': 0, 'parents': ['i']},
	{'value': 'ईश्', 'label': 'IS', 'attrib': [{'pos': 'vt2m', 'defs': 'rule'}], 'isLemma': 1},
	{'value': 'ईश', 'label': 'ISa', 'attrib': [{'pos': 'm', 'defs': 'lord'}], 'isLemma': 1},
	{'value': 'ईषाण', 'label': 'IzARa', 'attrib': [{'pos': 'pfpp', 'defs': 'desire'}], 'isLemma': 0, 'parents': ['iz']},
	{'value': 'ईषिवस्', 'label': 'Izivas', 'attrib': [{'pos': 'pfap', 'defs': 'desire'}], 'isLemma': 0, 'parents': ['iz']},
	{'value': 'उक्त', 'label': 'ukta', 'attrib': [{'pos': 'ppp', 'defs': 'say, speak'}], 'isLemma': 0, 'parents': ['vac']},
	{'value': 'उक्तवत्', 'label': 'uktavat', 'attrib': [{'pos': 'pap', 'defs': 'say, speak'}], 'isLemma': 0, 'parents': ['vac']},
	{'value': 'उक्त्वा', 'label': 'uktvA', 'attrib': [{'pos': 'abs', 'defs': 'say, speak'}], 'isLemma': 0, 'parents': ['vac']},
	{'value': 'उच्यमान', 'label': 'ucyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'say, speak'}], 'isLemma': 0, 'parents': ['vac']},
	{'value': 'उत्तम', 'label': 'uttama', 'attrib': [{'pos': 'superlative_adj', 'defs': 'highest, supreme'}], 'isLemma': 1},
	{'value': 'उत्तस्थान', 'label': 'uttasTAna', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'stand up, arise'}], 'isLemma': 0, 'parents': ['ud_sTA']},
	{'value': 'उत्तस्थिवस्', 'label': 'uttasTivas', 'attrib': [{'pos': 'pfap', 'defs': 'stand up, arise'}], 'isLemma': 0, 'parents': ['ud_sTA']},
	{'value': 'उत्तिष्ठत्', 'label': 'uttizWat', 'attrib': [{'pos': 'prap', 'defs': 'stand up, arise'}], 'isLemma': 0, 'parents': ['ud_sTA']},
	{'value': 'उत्तिष्ठमान', 'label': 'uttizWamAna', 'attrib': [{'pos': 'prmp', 'defs': 'stand up, arise'}], 'isLemma': 0, 'parents': ['ud_sTA']},
	{'value': 'उत्थातव्य', 'label': 'utTAtavya', 'attrib': [{'pos': 'ger', 'defs': 'stand up, arise'}], 'isLemma': 0, 'parents': ['ud_sTA']},
	{'value': 'उत्थातुम्', 'label': 'utTAtum', 'attrib': [{'pos': 'inf', 'defs': 'stand up, arise'}], 'isLemma': 0, 'parents': ['ud_sTA']},
	{'value': 'उत्थानीय', 'label': 'utTAnIya', 'attrib': [{'pos': 'ger', 'defs': 'stand up, arise'}], 'isLemma': 0, 'parents': ['ud_sTA']},
	{'value': 'उत्थाय', 'label': 'utTAya', 'attrib': [{'pos': 'abs', 'defs': 'stand up, arise'}], 'isLemma': 0, 'parents': ['ud_sTA']},
	{'value': 'उत्थास्यत्', 'label': 'utTAsyat', 'attrib': [{'pos': 'fap', 'defs': 'stand up, arise'}], 'isLemma': 0, 'parents': ['ud_sTA']},
	{'value': 'उत्थास्यमान', 'label': 'utTAsyamAna', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'stand up, arise'}], 'isLemma': 0, 'parents': ['ud_sTA']},
	{'value': 'उत्थित', 'label': 'utTita', 'attrib': [{'pos': 'ppp', 'defs': 'stand up, arise'}], 'isLemma': 0, 'parents': ['ud_sTA']},
	{'value': 'उत्थितवत्', 'label': 'utTitavat', 'attrib': [{'pos': 'pap', 'defs': 'stand up, arise'}], 'isLemma': 0, 'parents': ['ud_sTA']},
	{'value': 'उत्थेय', 'label': 'utTeya', 'attrib': [{'pos': 'ger', 'defs': 'stand up, arise'}], 'isLemma': 0, 'parents': ['ud_sTA']},
	{'value': 'उत्सत्तव्य', 'label': 'utsattavya', 'attrib': [{'pos': 'ger', 'defs': 'sit up, withdraw, disappear, be ruined; caus.: ruin, destroy'}], 'isLemma': 0, 'parents': ['ud_sad']},
	{'value': 'उत्सत्तुम्', 'label': 'utsattum', 'attrib': [{'pos': 'inf', 'defs': 'sit up, withdraw, disappear, be ruined; caus.: ruin, destroy'}], 'isLemma': 0, 'parents': ['ud_sad']},
	{'value': 'उत्सत्स्यत्', 'label': 'utsatsyat', 'attrib': [{'pos': 'fap', 'defs': 'sit up, withdraw, disappear, be ruined; caus.: ruin, destroy'}], 'isLemma': 0, 'parents': ['ud_sad']},
	{'value': 'उत्सत्स्यमान', 'label': 'utsatsyamAna', 'attrib': [{'pos': 'fpp', 'defs': 'sit up, withdraw, disappear, be ruined; caus.: ruin, destroy'}], 'isLemma': 0, 'parents': ['ud_sad']},
	{'value': 'उत्सदनीय', 'label': 'utsadanIya', 'attrib': [{'pos': 'ger', 'defs': 'sit up, withdraw, disappear, be ruined; caus.: ruin, destroy'}], 'isLemma': 0, 'parents': ['ud_sad']},
	{'value': 'उत्सद्य', 'label': 'utsadya', 'attrib': [{'pos': 'abs', 'defs': 'sit up, withdraw, disappear, be ruined; caus.: ruin, destroy'}], 'isLemma': 0, 'parents': ['ud_sad']},
	{'value': 'उत्सद्यमान', 'label': 'utsadyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'sit up, withdraw, disappear, be ruined; caus.: ruin, destroy'}], 'isLemma': 0, 'parents': ['ud_sad']},
	{'value': 'उत्सन्न', 'label': 'utsanna', 'attrib': [{'pos': 'ppp', 'defs': 'sit up, withdraw, disappear, be ruined; caus.: ruin, destroy'}], 'isLemma': 0, 'parents': ['ud_sad']},
	{'value': 'उत्सन्नवत्', 'label': 'utsannavat', 'attrib': [{'pos': 'pap', 'defs': 'sit up, withdraw, disappear, be ruined; caus.: ruin, destroy'}], 'isLemma': 0, 'parents': ['ud_sad']},
	{'value': 'उत्साद्य', 'label': 'utsAdya', 'attrib': [{'pos': 'abs', 'defs': 'sit up, withdraw, disappear, be ruined; caus.: ruin, destroy'}, {'pos': 'ger', 'defs': 'sit up, withdraw, disappear, be ruined; caus.: ruin, destroy'}], 'isLemma': 0, 'parents': ['ud_sad']},
	{'value': 'उत्सीदत्', 'label': 'utsIdat', 'attrib': [{'pos': 'prap', 'defs': 'sit up, withdraw, disappear, be ruined; caus.: ruin, destroy'}], 'isLemma': 0, 'parents': ['ud_sad']},
	{'value': 'उत्सेदान', 'label': 'utsedAna', 'attrib': [{'pos': 'pfpp', 'defs': 'sit up, withdraw, disappear, be ruined; caus.: ruin, destroy'}], 'isLemma': 0, 'parents': ['ud_sad']},
	{'value': 'उत्सेदिवस्', 'label': 'utsedivas', 'attrib': [{'pos': 'pfap', 'defs': 'sit up, withdraw, disappear, be ruined; caus.: ruin, destroy'}], 'isLemma': 0, 'parents': ['ud_sad']},
	{'value': 'उत्स्थीयमान', 'label': 'utsTIyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'stand up, arise'}], 'isLemma': 0, 'parents': ['ud_sTA']},
	{'value': 'उद्', 'label': 'ud', 'attrib': [{'pos': 'preverb', 'defs': 'up'}], 'isLemma': 1},
	{'value': 'उदञ्च्', 'label': 'udaYc', 'attrib': [{'pos': 'adj', 'defs': 'upward, northern'}], 'isLemma': 1},
	{'value': 'उदर', 'label': 'udara', 'attrib': [{'pos': 'n', 'defs': 'uterus, womb, belly'}], 'isLemma': 1},
	{'value': 'उदित', 'label': 'udita', 'attrib': [{'pos': 'ppp', 'defs': 'tell'}], 'isLemma': 0, 'parents': ['vad']},
	{'value': 'उदितवत्', 'label': 'uditavat', 'attrib': [{'pos': 'pap', 'defs': 'tell'}], 'isLemma': 0, 'parents': ['vad']},
	{'value': 'उदित्वा', 'label': 'uditvA', 'attrib': [{'pos': 'abs', 'defs': 'tell'}], 'isLemma': 0, 'parents': ['vad']},
	{'value': 'उदीची', 'label': 'udIcI', 'attrib': [{'pos': 'f', 'defs': 'the north'}], 'isLemma': 1},
	{'value': 'उद्_अञ्च्', 'label': 'ud_aYc', 'attrib': [{'pos': 'v1am', 'defs': 'bend up, rise'}], 'isLemma': 1},
	{'value': 'उद्_सद्', 'label': 'ud_sad', 'attrib': [{'pos': 'vi1a, vi6a', 'defs': 'sit up, withdraw, disappear, be ruined; caus.: ruin, destroy'}], 'isLemma': 1},
	{'value': 'उद्_सह्', 'label': 'ud_sah', 'attrib': [{'pos': 'vt1m', 'defs': 'be eager; caus.: encourage, inspire'}], 'isLemma': 1},
	{'value': 'उद्_स्था', 'label': 'ud_sTA', 'attrib': [{'pos': 'vi1am', 'defs': 'stand up, arise'}], 'isLemma': 1},
	{'value': 'उद्यमान', 'label': 'udyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'tell'}], 'isLemma': 0, 'parents': ['vad']},
	{'value': 'उप', 'label': 'upa', 'attrib': [{'pos': 'preverb', 'defs': 'under, near'}], 'isLemma': 1},
	{'value': 'उप_इ', 'label': 'upa_i', 'attrib': [{'pos': 'vt2a', 'defs': 'approach'}], 'isLemma': 1},
	{'value': 'उपयत्', 'label': 'upayat', 'attrib': [{'pos': 'prap', 'defs': 'approach'}], 'isLemma': 0, 'parents': ['upa_i']},
	{'value': 'उपाय', 'label': 'upAya', 'attrib': [{'pos': 'm', 'defs': 'approach, means'}], 'isLemma': 1},
	{'value': 'उपायनीय', 'label': 'upAyanIya', 'attrib': [{'pos': 'ger', 'defs': 'approach'}], 'isLemma': 0, 'parents': ['upa_i']},
	{'value': 'उपेत', 'label': 'upeta', 'attrib': [{'pos': 'ppp', 'defs': 'approach'}, {'pos': 'adj', 'defs': 'endowed with'}], 'isLemma': 1},
	{'value': 'उपेत', 'label': 'upeta', 'attrib': [{'pos': 'ppp', 'defs': 'approach'}, {'pos': 'adj', 'defs': 'endowed with'}], 'isLemma': 0, 'parents': ['upa_i']},
	{'value': 'उपेतवत्', 'label': 'upetavat', 'attrib': [{'pos': 'pap', 'defs': 'approach'}], 'isLemma': 0, 'parents': ['upa_i']},
	{'value': 'उपेत्य', 'label': 'upetya', 'attrib': [{'pos': 'abs', 'defs': 'approach'}, {'pos': 'ger', 'defs': 'approach'}], 'isLemma': 0, 'parents': ['upa_i']},
	{'value': 'उपेयमान', 'label': 'upeyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'approach'}], 'isLemma': 0, 'parents': ['upa_i']},
	{'value': 'उपेयान', 'label': 'upeyAna', 'attrib': [{'pos': 'pfpp', 'defs': 'approach'}], 'isLemma': 0, 'parents': ['upa_i']},
	{'value': 'उपेयिवस्', 'label': 'upeyivas', 'attrib': [{'pos': 'pfap', 'defs': 'approach'}], 'isLemma': 0, 'parents': ['upa_i']},
	{'value': 'उपैतव्य', 'label': 'upEtavya', 'attrib': [{'pos': 'ger', 'defs': 'approach'}], 'isLemma': 0, 'parents': ['upa_i']},
	{'value': 'उपैतुम्', 'label': 'upEtum', 'attrib': [{'pos': 'inf', 'defs': 'approach'}], 'isLemma': 0, 'parents': ['upa_i']},
	{'value': 'उपैष्यत्', 'label': 'upEzyat', 'attrib': [{'pos': 'fap', 'defs': 'approach'}], 'isLemma': 0, 'parents': ['upa_i']},
	{'value': 'उपैष्यमाण', 'label': 'upEzyamARa', 'attrib': [{'pos': 'fpp', 'defs': 'approach'}], 'isLemma': 0, 'parents': ['upa_i']},
	{'value': 'उशत्', 'label': 'uSat', 'attrib': [{'pos': 'prap', 'defs': 'wish'}], 'isLemma': 0, 'parents': ['vaS']},
	{'value': 'उशित', 'label': 'uSita', 'attrib': [{'pos': 'ppp', 'defs': 'wish'}], 'isLemma': 0, 'parents': ['vaS']},
	{'value': 'उशितवत्', 'label': 'uSitavat', 'attrib': [{'pos': 'pap', 'defs': 'wish'}], 'isLemma': 0, 'parents': ['vaS']},
	{'value': 'उश्यमान', 'label': 'uSyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'wish'}], 'isLemma': 0, 'parents': ['vaS']},
	{'value': 'उषित', 'label': 'uzita', 'attrib': [{'pos': 'ppp', 'defs': 'dwell, live'}], 'isLemma': 0, 'parents': ['vas']},
	{'value': 'उषितवत्', 'label': 'uzitavat', 'attrib': [{'pos': 'pap', 'defs': 'dwell, live'}], 'isLemma': 0, 'parents': ['vas']},
	{'value': 'उषित्वा', 'label': 'uzitvA', 'attrib': [{'pos': 'abs', 'defs': 'dwell, live'}], 'isLemma': 0, 'parents': ['vas']},
	{'value': 'उष्यमाण', 'label': 'uzyamARa', 'attrib': [{'pos': 'prpp', 'defs': 'dwell, live'}], 'isLemma': 0, 'parents': ['vas']},
	{'value': 'उह्यमान', 'label': 'uhyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'carry, flow, wed'}], 'isLemma': 0, 'parents': ['vah']},
	{'value': 'ऊचान', 'label': 'UcAna', 'attrib': [{'pos': 'pfpp', 'defs': 'say, speak'}], 'isLemma': 0, 'parents': ['vac']},
	{'value': 'ऊचिवस्', 'label': 'Ucivas', 'attrib': [{'pos': 'pfap', 'defs': 'say, speak'}], 'isLemma': 0, 'parents': ['vac']},
	{'value': 'ऊढ', 'label': 'UQa', 'attrib': [{'pos': 'ppp', 'defs': 'carry, flow, wed'}], 'isLemma': 0, 'parents': ['vah']},
	{'value': 'ऊढवत्', 'label': 'UQavat', 'attrib': [{'pos': 'pap', 'defs': 'carry, flow, wed'}], 'isLemma': 0, 'parents': ['vah']},
	{'value': 'ऊढ्वा', 'label': 'UQvA', 'attrib': [{'pos': 'abs', 'defs': 'carry, flow, wed'}], 'isLemma': 0, 'parents': ['vah']},
	{'value': 'ऊदान', 'label': 'UdAna', 'attrib': [{'pos': 'pfpp', 'defs': 'tell'}], 'isLemma': 0, 'parents': ['vad']},
	{'value': 'ऊदिवस्', 'label': 'Udivas', 'attrib': [{'pos': 'pfap', 'defs': 'tell'}], 'isLemma': 0, 'parents': ['vad']},
	{'value': 'ऊषाण', 'label': 'UzARa', 'attrib': [{'pos': 'pfpp', 'defs': 'dwell, live'}], 'isLemma': 0, 'parents': ['vas']},
	{'value': 'ऊषिवस्', 'label': 'Uzivas', 'attrib': [{'pos': 'pfap', 'defs': 'dwell, live'}], 'isLemma': 0, 'parents': ['vas']},
	{'value': 'ऊहान', 'label': 'UhAna', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'carry, flow, wed'}], 'isLemma': 0, 'parents': ['vah']},
	{'value': 'ऊहिवस्', 'label': 'Uhivas', 'attrib': [{'pos': 'pfap', 'defs': 'carry, flow, wed'}], 'isLemma': 0, 'parents': ['vah']},
	{'value': 'ऋषभ', 'label': 'fzaBa', 'attrib': [{'pos': 'm', 'defs': 'bull'}], 'isLemma': 1},
	{'value': 'ऋषि', 'label': 'fzi', 'attrib': [{'pos': 'm', 'defs': 'Vedic seer'}], 'isLemma': 1},
	{'value': 'एक', 'label': 'eka', 'attrib': [{'pos': 'card_num', 'defs': 'one'}], 'isLemma': 1},
	{'value': 'एकचक्र', 'label': 'ekacakra', 'attrib': [{'pos': 'adj', 'defs': "one-wheeled, epithet of the sun's chariot, one-kinged, one-armied"}], 'isLemma': 1},
	{'value': 'एकचक्रा', 'label': 'ekacakrA', 'attrib': [{'pos': 'f_pn', 'defs': 'Ekacakrā'}], 'isLemma': 1},
	{'value': 'एत', 'label': 'eta', 'attrib': [{'pos': 'ppp', 'defs': 'come'}], 'isLemma': 0, 'parents': ['A_i']},
	{'value': 'एतद्', 'label': 'etad', 'attrib': [{'pos': 'dem_adj, dem_pron', 'defs': 'this'}], 'isLemma': 1},
	{'value': 'एतवत्', 'label': 'etavat', 'attrib': [{'pos': 'pap', 'defs': 'come'}], 'isLemma': 0, 'parents': ['A_i']},
	{'value': 'एतव्य', 'label': 'etavya', 'attrib': [{'pos': 'ger', 'defs': 'go'}], 'isLemma': 0, 'parents': ['i']},
	{'value': 'एतुम्', 'label': 'etum', 'attrib': [{'pos': 'inf', 'defs': 'go'}], 'isLemma': 0, 'parents': ['i']},
	{'value': 'एत्य', 'label': 'etya', 'attrib': [{'pos': 'abs', 'defs': 'come'}, {'pos': 'ger', 'defs': 'come'}], 'isLemma': 0, 'parents': ['A_i']},
	{'value': 'एनद्', 'label': 'enad', 'attrib': [{'pos': 'dem_adj, dem_pron', 'defs': 'this, it'}], 'isLemma': 1},
	{'value': 'एयमान', 'label': 'eyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'come'}], 'isLemma': 0, 'parents': ['A_i']},
	{'value': 'एयान', 'label': 'eyAna', 'attrib': [{'pos': 'pfpp', 'defs': 'come'}], 'isLemma': 0, 'parents': ['A_i']},
	{'value': 'एयिवस्', 'label': 'eyivas', 'attrib': [{'pos': 'pfap', 'defs': 'come'}], 'isLemma': 0, 'parents': ['A_i']},
	{'value': 'एव', 'label': 'eva', 'attrib': [{'pos': 'pcl', 'defs': 'only'}], 'isLemma': 1},
	{'value': 'एवम्', 'label': 'evam', 'attrib': [{'pos': 'dem_adv', 'defs': 'in this way, thus'}], 'isLemma': 1},
	{'value': 'एषणीय', 'label': 'ezaRIya', 'attrib': [{'pos': 'ger', 'defs': 'desire'}], 'isLemma': 0, 'parents': ['iz']},
	{'value': 'एषिष्यत्', 'label': 'ezizyat', 'attrib': [{'pos': 'fap', 'defs': 'desire'}], 'isLemma': 0, 'parents': ['iz']},
	{'value': 'एषिष्यमाण', 'label': 'ezizyamARa', 'attrib': [{'pos': 'fpp', 'defs': 'desire'}], 'isLemma': 0, 'parents': ['iz']},
	{'value': 'एष्टव्य', 'label': 'ezwavya', 'attrib': [{'pos': 'ger', 'defs': 'desire'}], 'isLemma': 0, 'parents': ['iz']},
	{'value': 'एष्टुम्', 'label': 'ezwum', 'attrib': [{'pos': 'inf', 'defs': 'desire'}], 'isLemma': 0, 'parents': ['iz']},
	{'value': 'एष्य', 'label': 'ezya', 'attrib': [{'pos': 'ger', 'defs': 'desire'}], 'isLemma': 0, 'parents': ['iz']},
	{'value': 'एष्यत्', 'label': 'ezyat', 'attrib': [{'pos': 'fap', 'defs': 'go'}], 'isLemma': 0, 'parents': ['i']},
	{'value': 'एष्यमाण', 'label': 'ezyamARa', 'attrib': [{'pos': 'fpp', 'defs': 'go'}], 'isLemma': 0, 'parents': ['i']},
	{'value': 'ऐतव्य', 'label': 'Etavya', 'attrib': [{'pos': 'ger', 'defs': 'come'}], 'isLemma': 0, 'parents': ['A_i']},
	{'value': 'ऐतुम्', 'label': 'Etum', 'attrib': [{'pos': 'inf', 'defs': 'come'}], 'isLemma': 0, 'parents': ['A_i']},
	{'value': 'ऐष्यत्', 'label': 'Ezyat', 'attrib': [{'pos': 'fap', 'defs': 'come'}], 'isLemma': 0, 'parents': ['A_i']},
	{'value': 'ऐष्यमाण', 'label': 'EzyamARa', 'attrib': [{'pos': 'fpp', 'defs': 'come'}], 'isLemma': 0, 'parents': ['A_i']},
	{'value': 'ओजस्', 'label': 'ojas', 'attrib': [{'pos': 'n', 'defs': 'vigour, energy'}], 'isLemma': 1},
	{'value': 'कथ्', 'label': 'kaT', 'attrib': [{'pos': 'vt10am', 'defs': 'tell, narrate'}], 'isLemma': 1},
	{'value': 'कथनीय', 'label': 'kaTanIya', 'attrib': [{'pos': 'ger', 'defs': 'tell, narrate'}], 'isLemma': 0, 'parents': ['kaT']},
	{'value': 'कथम्', 'label': 'kaTam', 'attrib': [{'pos': 'int_adv', 'defs': 'how'}], 'isLemma': 1},
	{'value': 'कथयत्', 'label': 'kaTayat', 'attrib': [{'pos': 'prap', 'defs': 'tell, narrate'}], 'isLemma': 0, 'parents': ['kaT']},
	{'value': 'कथयमान', 'label': 'kaTayamAna', 'attrib': [{'pos': 'prmp', 'defs': 'tell, narrate'}], 'isLemma': 0, 'parents': ['kaT']},
	{'value': 'कथयाम्', 'label': 'kaTayAm', 'attrib': [{'pos': 'ppf_actn', 'defs': 'tell, narrate'}], 'isLemma': 0, 'parents': ['kaT']},
	{'value': 'कथयितव्य', 'label': 'kaTayitavya', 'attrib': [{'pos': 'ger', 'defs': 'tell, narrate'}], 'isLemma': 0, 'parents': ['kaT']},
	{'value': 'कथयितुम्', 'label': 'kaTayitum', 'attrib': [{'pos': 'inf', 'defs': 'tell, narrate'}], 'isLemma': 0, 'parents': ['kaT']},
	{'value': 'कथयित्वा', 'label': 'kaTayitvA', 'attrib': [{'pos': 'abs', 'defs': 'tell, narrate'}], 'isLemma': 0, 'parents': ['kaT']},
	{'value': 'कथयिष्यत्', 'label': 'kaTayizyat', 'attrib': [{'pos': 'fap', 'defs': 'tell, narrate'}], 'isLemma': 0, 'parents': ['kaT']},
	{'value': 'कथयिष्यमाण', 'label': 'kaTayizyamARa', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'tell, narrate'}], 'isLemma': 0, 'parents': ['kaT']},
	{'value': 'कथा', 'label': 'kaTA', 'attrib': [{'pos': 'f', 'defs': 'story, narration'}], 'isLemma': 1},
	{'value': 'कथित', 'label': 'kaTita', 'attrib': [{'pos': 'ppp', 'defs': 'tell, narrate'}], 'isLemma': 0, 'parents': ['kaT']},
	{'value': 'कथितवत्', 'label': 'kaTitavat', 'attrib': [{'pos': 'pap', 'defs': 'tell, narrate'}], 'isLemma': 0, 'parents': ['kaT']},
	{'value': 'कथ्य', 'label': 'kaTya', 'attrib': [{'pos': 'ger', 'defs': 'tell, narrate'}], 'isLemma': 0, 'parents': ['kaT']},
	{'value': 'कथ्यमान', 'label': 'kaTyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'tell, narrate'}], 'isLemma': 0, 'parents': ['kaT']},
	{'value': 'कदा', 'label': 'kadA', 'attrib': [{'pos': 'int_adv', 'defs': 'when'}], 'isLemma': 1},
	{'value': 'कन्या', 'label': 'kanyA', 'attrib': [{'pos': 'f', 'defs': 'girl'}], 'isLemma': 1},
	{'value': 'कपि', 'label': 'kapi', 'attrib': [{'pos': 'm', 'defs': 'monkey'}], 'isLemma': 1},
	{'value': 'कपिलक्षण', 'label': 'kapilakzaRa', 'attrib': [{'pos': 'adj', 'defs': 'monkey-bannered'}], 'isLemma': 1},
	{'value': 'करणीय', 'label': 'karaRIya', 'attrib': [{'pos': 'ger', 'defs': 'do, make'}], 'isLemma': 0, 'parents': ['kf']},
	{'value': 'करिष्यत्', 'label': 'karizyat', 'attrib': [{'pos': 'fap', 'defs': 'do, make'}], 'isLemma': 0, 'parents': ['kf']},
	{'value': 'करिष्यमाण', 'label': 'karizyamARa', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'do, make'}], 'isLemma': 0, 'parents': ['kf']},
	{'value': 'कर्ण', 'label': 'karRa', 'attrib': [{'pos': 'm', 'defs': 'ear'}, {'pos': 'm_pn', 'defs': 'Karṇa'}], 'isLemma': 1},
	{'value': 'कर्तव्य', 'label': 'kartavya', 'attrib': [{'pos': 'ger', 'defs': 'do, make'}], 'isLemma': 0, 'parents': ['kf']},
	{'value': 'कर्तुम्', 'label': 'kartum', 'attrib': [{'pos': 'inf', 'defs': 'do, make'}], 'isLemma': 0, 'parents': ['kf']},
	{'value': 'कर्मन्', 'label': 'karman', 'attrib': [{'pos': 'n', 'defs': 'action, deed'}], 'isLemma': 1},
	{'value': 'कारक', 'label': 'kAraka', 'attrib': [{'pos': 'adj', 'defs': 'making, causing'}, {'pos': 'n', 'defs': 'cause'}], 'isLemma': 1},
	{'value': 'कार्य', 'label': 'kArya', 'attrib': [{'pos': 'ger', 'defs': 'do, make'}], 'isLemma': 0, 'parents': ['kf']},
	{'value': 'काश्', 'label': 'kAS', 'attrib': [{'pos': 'vi1m, vi4m', 'defs': 'shine'}], 'isLemma': 1},
	{'value': 'काशनीय', 'label': 'kASanIya', 'attrib': [{'pos': 'ger', 'defs': 'shine'}], 'isLemma': 0, 'parents': ['kAS']},
	{'value': 'काशमान', 'label': 'kASamAna', 'attrib': [{'pos': 'prmp', 'defs': 'shine'}], 'isLemma': 0, 'parents': ['kAS']},
	{'value': 'काशाण', 'label': 'kASARa', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'shine'}], 'isLemma': 0, 'parents': ['kAS']},
	{'value': 'काशित', 'label': 'kASita', 'attrib': [{'pos': 'ppp', 'defs': 'shine'}], 'isLemma': 0, 'parents': ['kAS']},
	{'value': 'काशितवत्', 'label': 'kASitavat', 'attrib': [{'pos': 'pap', 'defs': 'shine'}], 'isLemma': 0, 'parents': ['kAS']},
	{'value': 'काशितव्य', 'label': 'kASitavya', 'attrib': [{'pos': 'ger', 'defs': 'shine'}], 'isLemma': 0, 'parents': ['kAS']},
	{'value': 'काशितुम्', 'label': 'kASitum', 'attrib': [{'pos': 'inf', 'defs': 'shine'}], 'isLemma': 0, 'parents': ['kAS']},
	{'value': 'काशित्वा', 'label': 'kASitvA', 'attrib': [{'pos': 'abs', 'defs': 'shine'}], 'isLemma': 0, 'parents': ['kAS']},
	{'value': 'काशिष्यमाण', 'label': 'kASizyamARa', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'shine'}], 'isLemma': 0, 'parents': ['kAS']},
	{'value': 'काश्य', 'label': 'kASya', 'attrib': [{'pos': 'ger', 'defs': 'shine'}], 'isLemma': 0, 'parents': ['kAS']},
	{'value': 'काश्यमान', 'label': 'kASyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'shine'}], 'isLemma': 0, 'parents': ['kAS']},
	{'value': 'किम्', 'label': 'kim', 'attrib': [{'pos': 'int_adj, int_pron', 'defs': 'what'}], 'isLemma': 1},
	{'value': 'कुन्ती', 'label': 'kuntI', 'attrib': [{'pos': 'f_pn', 'defs': 'Kuntī'}], 'isLemma': 1},
	{'value': 'कुरु', 'label': 'kuru', 'attrib': [{'pos': 'm_pn', 'defs': 'Kuru ; pl. his descendants'}], 'isLemma': 1},
	{'value': 'कुरुपाण्डव', 'label': 'kurupARqava', 'attrib': [{'pos': 'm', 'defs': 'the descendants of Kuru and the sons of Pāṇḍu'}], 'isLemma': 1},
	{'value': 'कुर्वत्', 'label': 'kurvat', 'attrib': [{'pos': 'prap', 'defs': 'do, make'}], 'isLemma': 0, 'parents': ['kf']},
	{'value': 'कुर्वाण', 'label': 'kurvARa', 'attrib': [{'pos': 'prmp', 'defs': 'do, make'}], 'isLemma': 0, 'parents': ['kf']},
	{'value': 'कृ', 'label': 'kf', 'attrib': [{'pos': 'vt8am', 'defs': 'do, make'}], 'isLemma': 1},
	{'value': 'कृत', 'label': 'kfta', 'attrib': [{'pos': 'ppp', 'defs': 'do, make'}], 'isLemma': 0, 'parents': ['kf']},
	{'value': 'कृतवत्', 'label': 'kftavat', 'attrib': [{'pos': 'pap', 'defs': 'do, make'}], 'isLemma': 0, 'parents': ['kf']},
	{'value': 'कृत्य', 'label': 'kftya', 'attrib': [{'pos': 'ger', 'defs': 'do, make'}], 'isLemma': 0, 'parents': ['kf']},
	{'value': 'कृत्वा', 'label': 'kftvA', 'attrib': [{'pos': 'abs', 'defs': 'do, make'}], 'isLemma': 0, 'parents': ['kf']},
	{'value': 'कृत्स्न', 'label': 'kftsna', 'attrib': [{'pos': 'adj', 'defs': 'whole, entire'}], 'isLemma': 1},
	{'value': 'कृष्ण', 'label': 'kfzRa', 'attrib': [{'pos': 'adj', 'defs': 'dark, black'}, {'pos': 'm_pn', 'defs': 'Kṛṣṇa'}], 'isLemma': 1},
	{'value': 'कृष्णसर्प', 'label': 'kfzRasarpa', 'attrib': [{'pos': 'm', 'defs': 'black snake, cobra'}], 'isLemma': 1},
	{'value': 'केश', 'label': 'keSa', 'attrib': [{'pos': 'm', 'defs': 'hair (of the head), mane'}], 'isLemma': 1},
	{'value': 'केशव', 'label': 'keSava', 'attrib': [{'pos': 'adj', 'defs': 'having hair'}], 'isLemma': 1},
	{'value': 'कोटि', 'label': 'kowi', 'attrib': [{'pos': 'f', 'defs': 'end, top'}], 'isLemma': 1},
	{'value': 'कौन्तेय', 'label': 'kOnteya', 'attrib': [{'pos': 'm', 'defs': 'son of Kuntī'}], 'isLemma': 1},
	{'value': 'क्रम्', 'label': 'kram', 'attrib': [{'pos': 'vt1a', 'defs': 'step'}], 'isLemma': 1},
	{'value': 'क्रम', 'label': 'krama', 'attrib': [{'pos': 'm', 'defs': 'step, succession'}], 'isLemma': 1},
	{'value': 'क्रियमाण', 'label': 'kriyamARa', 'attrib': [{'pos': 'prpp', 'defs': 'do, make'}], 'isLemma': 0, 'parents': ['kf']},
	{'value': 'क्रुद्ध', 'label': 'krudDa', 'attrib': [{'pos': 'ppp', 'defs': 'be angry'}], 'isLemma': 0, 'parents': ['kruD']},
	{'value': 'क्रुद्धवत्', 'label': 'krudDavat', 'attrib': [{'pos': 'pap', 'defs': 'be angry'}], 'isLemma': 0, 'parents': ['kruD']},
	{'value': 'क्रुद्ध्वा', 'label': 'krudDvA', 'attrib': [{'pos': 'abs', 'defs': 'be angry'}], 'isLemma': 0, 'parents': ['kruD']},
	{'value': 'क्रुध्', 'label': 'kruD', 'attrib': [{'pos': 'vi4a', 'defs': 'be angry'}], 'isLemma': 1},
	{'value': 'क्रुध्यत्', 'label': 'kruDyat', 'attrib': [{'pos': 'prap', 'defs': 'be angry'}], 'isLemma': 0, 'parents': ['kruD']},
	{'value': 'क्रुध्यमान', 'label': 'kruDyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'be angry'}], 'isLemma': 0, 'parents': ['kruD']},
	{'value': 'क्रूर', 'label': 'krUra', 'attrib': [{'pos': 'adj', 'defs': 'cruel'}], 'isLemma': 1},
	{'value': 'क्रोत्स्यत्', 'label': 'krotsyat', 'attrib': [{'pos': 'fap', 'defs': 'be angry'}], 'isLemma': 0, 'parents': ['kruD']},
	{'value': 'क्रोत्स्यमान', 'label': 'krotsyamAna', 'attrib': [{'pos': 'fpp', 'defs': 'be angry'}], 'isLemma': 0, 'parents': ['kruD']},
	{'value': 'क्रोद्धव्य', 'label': 'krodDavya', 'attrib': [{'pos': 'ger', 'defs': 'be angry'}], 'isLemma': 0, 'parents': ['kruD']},
	{'value': 'क्रोद्धुम्', 'label': 'krodDum', 'attrib': [{'pos': 'inf', 'defs': 'be angry'}], 'isLemma': 0, 'parents': ['kruD']},
	{'value': 'क्रोधनीय', 'label': 'kroDanIya', 'attrib': [{'pos': 'ger', 'defs': 'be angry'}], 'isLemma': 0, 'parents': ['kruD']},
	{'value': 'क्रोध्य', 'label': 'kroDya', 'attrib': [{'pos': 'ger', 'defs': 'be angry'}], 'isLemma': 0, 'parents': ['kruD']},
	{'value': 'क्लिश्', 'label': 'kliS', 'attrib': [{'pos': 'vt9a', 'defs': 'torment, afflict'}], 'isLemma': 1},
	{'value': 'क्लिशित', 'label': 'kliSita', 'attrib': [{'pos': 'ppp', 'defs': 'torment, afflict'}], 'isLemma': 0, 'parents': ['kliS']},
	{'value': 'क्लिशितवत्', 'label': 'kliSitavat', 'attrib': [{'pos': 'pap', 'defs': 'torment, afflict'}], 'isLemma': 0, 'parents': ['kliS']},
	{'value': 'क्लिशित्वा', 'label': 'kliSitvA', 'attrib': [{'pos': 'abs', 'defs': 'torment, afflict'}], 'isLemma': 0, 'parents': ['kliS']},
	{'value': 'क्लिश्नत्', 'label': 'kliSnat', 'attrib': [{'pos': 'prap', 'defs': 'torment, afflict'}], 'isLemma': 0, 'parents': ['kliS']},
	{'value': 'क्लिश्यमान', 'label': 'kliSyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'torment, afflict'}], 'isLemma': 0, 'parents': ['kliS']},
	{'value': 'क्लिष्ट', 'label': 'klizwa', 'attrib': [{'pos': 'ppp', 'defs': 'torment, afflict'}], 'isLemma': 0, 'parents': ['kliS']},
	{'value': 'क्लिष्टवत्', 'label': 'klizwavat', 'attrib': [{'pos': 'pap', 'defs': 'torment, afflict'}], 'isLemma': 0, 'parents': ['kliS']},
	{'value': 'क्लिष्ट्वा', 'label': 'klizwvA', 'attrib': [{'pos': 'abs', 'defs': 'torment, afflict'}], 'isLemma': 0, 'parents': ['kliS']},
	{'value': 'क्लेक्ष्यत्', 'label': 'klekzyat', 'attrib': [{'pos': 'fap', 'defs': 'torment, afflict'}], 'isLemma': 0, 'parents': ['kliS']},
	{'value': 'क्लेक्ष्यमाण', 'label': 'klekzyamARa', 'attrib': [{'pos': 'fpp', 'defs': 'torment, afflict'}], 'isLemma': 0, 'parents': ['kliS']},
	{'value': 'क्लेशनीय', 'label': 'kleSanIya', 'attrib': [{'pos': 'ger', 'defs': 'torment, afflict'}], 'isLemma': 0, 'parents': ['kliS']},
	{'value': 'क्लेशितव्य', 'label': 'kleSitavya', 'attrib': [{'pos': 'ger', 'defs': 'torment, afflict'}], 'isLemma': 0, 'parents': ['kliS']},
	{'value': 'क्लेशितुम्', 'label': 'kleSitum', 'attrib': [{'pos': 'inf', 'defs': 'torment, afflict'}], 'isLemma': 0, 'parents': ['kliS']},
	{'value': 'क्लेशिष्यत्', 'label': 'kleSizyat', 'attrib': [{'pos': 'fap', 'defs': 'torment, afflict'}], 'isLemma': 0, 'parents': ['kliS']},
	{'value': 'क्लेशिष्यमाण', 'label': 'kleSizyamARa', 'attrib': [{'pos': 'fpp', 'defs': 'torment, afflict'}], 'isLemma': 0, 'parents': ['kliS']},
	{'value': 'क्लेश्य', 'label': 'kleSya', 'attrib': [{'pos': 'ger', 'defs': 'torment, afflict'}], 'isLemma': 0, 'parents': ['kliS']},
	{'value': 'क्लेष्टव्य', 'label': 'klezwavya', 'attrib': [{'pos': 'ger', 'defs': 'torment, afflict'}], 'isLemma': 0, 'parents': ['kliS']},
	{'value': 'क्लेष्टुम्', 'label': 'klezwum', 'attrib': [{'pos': 'inf', 'defs': 'torment, afflict'}], 'isLemma': 0, 'parents': ['kliS']},
	{'value': 'क्षंस्यत्', 'label': 'kzaMsyat', 'attrib': [{'pos': 'fap', 'defs': 'tolerate, forgive'}], 'isLemma': 0, 'parents': ['kzam']},
	{'value': 'क्षंस्यमान', 'label': 'kzaMsyamAna', 'attrib': [{'pos': 'fpp', 'defs': 'tolerate, forgive'}], 'isLemma': 0, 'parents': ['kzam']},
	{'value': 'क्षन्तव्य', 'label': 'kzantavya', 'attrib': [{'pos': 'ger', 'defs': 'tolerate, forgive'}], 'isLemma': 0, 'parents': ['kzam']},
	{'value': 'क्षन्तुम्', 'label': 'kzantum', 'attrib': [{'pos': 'inf', 'defs': 'tolerate, forgive'}], 'isLemma': 0, 'parents': ['kzam']},
	{'value': 'क्षन्त्वा', 'label': 'kzantvA', 'attrib': [{'pos': 'abs', 'defs': 'tolerate, forgive'}], 'isLemma': 0, 'parents': ['kzam']},
	{'value': 'क्षम्', 'label': 'kzam', 'attrib': [{'pos': 'vi4a, vi1m', 'defs': 'tolerate, forgive'}], 'isLemma': 1},
	{'value': 'क्षमणीय', 'label': 'kzamaRIya', 'attrib': [{'pos': 'ger', 'defs': 'tolerate, forgive'}], 'isLemma': 0, 'parents': ['kzam']},
	{'value': 'क्षमाण', 'label': 'kzamARa', 'attrib': [{'pos': 'pfpp', 'defs': 'tolerate, forgive'}], 'isLemma': 0, 'parents': ['kzam']},
	{'value': 'क्षमितव्य', 'label': 'kzamitavya', 'attrib': [{'pos': 'ger', 'defs': 'tolerate, forgive'}], 'isLemma': 0, 'parents': ['kzam']},
	{'value': 'क्षमितुम्', 'label': 'kzamitum', 'attrib': [{'pos': 'inf', 'defs': 'tolerate, forgive'}], 'isLemma': 0, 'parents': ['kzam']},
	{'value': 'क्षमित्वा', 'label': 'kzamitvA', 'attrib': [{'pos': 'abs', 'defs': 'tolerate, forgive'}], 'isLemma': 0, 'parents': ['kzam']},
	{'value': 'क्षमिवस्', 'label': 'kzamivas', 'attrib': [{'pos': 'pfap', 'defs': 'tolerate, forgive'}], 'isLemma': 0, 'parents': ['kzam']},
	{'value': 'क्षमिष्यत्', 'label': 'kzamizyat', 'attrib': [{'pos': 'fap', 'defs': 'tolerate, forgive'}], 'isLemma': 0, 'parents': ['kzam']},
	{'value': 'क्षमिष्यमाण', 'label': 'kzamizyamARa', 'attrib': [{'pos': 'fpp', 'defs': 'tolerate, forgive'}], 'isLemma': 0, 'parents': ['kzam']},
	{'value': 'क्षम्य', 'label': 'kzamya', 'attrib': [{'pos': 'ger', 'defs': 'tolerate, forgive'}], 'isLemma': 0, 'parents': ['kzam']},
	{'value': 'क्षम्यमाण', 'label': 'kzamyamARa', 'attrib': [{'pos': 'prpp', 'defs': 'tolerate, forgive'}], 'isLemma': 0, 'parents': ['kzam']},
	{'value': 'क्षय', 'label': 'kzaya', 'attrib': [{'pos': 'm', 'defs': 'destruction'}], 'isLemma': 1},
	{'value': 'क्षयनीय', 'label': 'kzayanIya', 'attrib': [{'pos': 'ger', 'defs': 'perish, destroy'}, {'pos': 'ger', 'defs': 'possess, rule'}, {'pos': 'ger', 'defs': 'destroy'}], 'isLemma': 0, 'parents': ['kzi']},
	{'value': 'क्षय्य', 'label': 'kzayya', 'attrib': [{'pos': 'ger', 'defs': 'perish, destroy'}], 'isLemma': 0, 'parents': ['kzi']},
	{'value': 'क्षान्त', 'label': 'kzAnta', 'attrib': [{'pos': 'ppp', 'defs': 'tolerate, forgive'}], 'isLemma': 0, 'parents': ['kzam']},
	{'value': 'क्षान्तवत्', 'label': 'kzAntavat', 'attrib': [{'pos': 'pap', 'defs': 'tolerate, forgive'}], 'isLemma': 0, 'parents': ['kzam']},
	{'value': 'क्षाम्यत्', 'label': 'kzAmyat', 'attrib': [{'pos': 'prap', 'defs': 'tolerate, forgive'}], 'isLemma': 0, 'parents': ['kzam']},
	{'value': 'क्षि', 'label': 'kzi', 'attrib': [{'pos': 'vt1a', 'defs': 'perish, destroy'}, {'pos': 'vt6a', 'defs': 'possess, rule'}, {'pos': 'vt5a', 'defs': 'destroy'}], 'isLemma': 1},
	{'value': 'क्षिण्वत्', 'label': 'kziRvat', 'attrib': [{'pos': 'prap', 'defs': 'destroy'}], 'isLemma': 0, 'parents': ['kzi']},
	{'value': 'क्षित्', 'label': 'kzit', 'attrib': [{'pos': 'adj', 'defs': 'ruling'}], 'isLemma': 1},
	{'value': 'क्षित', 'label': 'kzita', 'attrib': [{'pos': 'ppp', 'defs': 'perish, destroy'}, {'pos': 'ppp', 'defs': 'possess, rule'}, {'pos': 'ppp', 'defs': 'destroy'}], 'isLemma': 0, 'parents': ['kzi']},
	{'value': 'क्षितवत्', 'label': 'kzitavat', 'attrib': [{'pos': 'pap', 'defs': 'perish, destroy'}, {'pos': 'pap', 'defs': 'possess, rule'}, {'pos': 'pap', 'defs': 'destroy'}], 'isLemma': 0, 'parents': ['kzi']},
	{'value': 'क्षित्वा', 'label': 'kzitvA', 'attrib': [{'pos': 'abs', 'defs': 'perish, destroy'}, {'pos': 'abs', 'defs': 'possess, rule'}, {'pos': 'abs', 'defs': 'destroy'}], 'isLemma': 0, 'parents': ['kzi']},
	{'value': 'क्षिप्', 'label': 'kzip', 'attrib': [{'pos': 'vt4a, vt6am', 'defs': 'throw'}], 'isLemma': 1},
	{'value': 'क्षिपत्', 'label': 'kzipat', 'attrib': [{'pos': 'prap', 'defs': 'throw'}], 'isLemma': 0, 'parents': ['kzip']},
	{'value': 'क्षिप्त', 'label': 'kzipta', 'attrib': [{'pos': 'ppp', 'defs': 'throw'}], 'isLemma': 0, 'parents': ['kzip']},
	{'value': 'क्षिप्तवत्', 'label': 'kziptavat', 'attrib': [{'pos': 'pap', 'defs': 'throw'}], 'isLemma': 0, 'parents': ['kzip']},
	{'value': 'क्षिप्तुम्', 'label': 'kziptum', 'attrib': [{'pos': 'inf', 'defs': 'throw'}], 'isLemma': 0, 'parents': ['kzip']},
	{'value': 'क्षिप्त्वा', 'label': 'kziptvA', 'attrib': [{'pos': 'abs', 'defs': 'throw'}], 'isLemma': 0, 'parents': ['kzip']},
	{'value': 'क्षिप्यत्', 'label': 'kzipyat', 'attrib': [{'pos': 'prap', 'defs': 'throw'}], 'isLemma': 0, 'parents': ['kzip']},
	{'value': 'क्षिप्यमाण', 'label': 'kzipyamARa', 'attrib': [{'pos': 'prmp, prpp', 'defs': 'throw'}], 'isLemma': 0, 'parents': ['kzip']},
	{'value': 'क्षिप्स्यत्', 'label': 'kzipsyat', 'attrib': [{'pos': 'fap', 'defs': 'throw'}], 'isLemma': 0, 'parents': ['kzip']},
	{'value': 'क्षिप्स्यमाण', 'label': 'kzipsyamARa', 'attrib': [{'pos': 'fpp', 'defs': 'throw'}], 'isLemma': 0, 'parents': ['kzip']},
	{'value': 'क्षियत्', 'label': 'kziyat', 'attrib': [{'pos': 'prap', 'defs': 'perish, destroy'}, {'pos': 'prap', 'defs': 'possess, rule'}], 'isLemma': 0, 'parents': ['kzi']},
	{'value': 'क्षीण', 'label': 'kzIRa', 'attrib': [{'pos': 'ppp', 'defs': 'perish, destroy'}, {'pos': 'ppp', 'defs': 'possess, rule'}, {'pos': 'ppp', 'defs': 'destroy'}], 'isLemma': 0, 'parents': ['kzi']},
	{'value': 'क्षीणवत्', 'label': 'kzIRavat', 'attrib': [{'pos': 'pap', 'defs': 'perish, destroy'}, {'pos': 'pap', 'defs': 'possess, rule'}, {'pos': 'pap', 'defs': 'destroy'}], 'isLemma': 0, 'parents': ['kzi']},
	{'value': 'क्षीयमाण', 'label': 'kzIyamARa', 'attrib': [{'pos': 'prpp', 'defs': 'perish, destroy'}, {'pos': 'prpp', 'defs': 'possess, rule'}, {'pos': 'prpp', 'defs': 'destroy'}], 'isLemma': 0, 'parents': ['kzi']},
	{'value': 'क्षेतव्य', 'label': 'kzetavya', 'attrib': [{'pos': 'ger', 'defs': 'perish, destroy'}, {'pos': 'ger', 'defs': 'possess, rule'}, {'pos': 'ger', 'defs': 'destroy'}], 'isLemma': 0, 'parents': ['kzi']},
	{'value': 'क्षेतुम्', 'label': 'kzetum', 'attrib': [{'pos': 'inf', 'defs': 'perish, destroy'}, {'pos': 'inf', 'defs': 'possess, rule'}, {'pos': 'inf', 'defs': 'destroy'}], 'isLemma': 0, 'parents': ['kzi']},
	{'value': 'क्षेपणीय', 'label': 'kzepaRIya', 'attrib': [{'pos': 'ger', 'defs': 'throw'}], 'isLemma': 0, 'parents': ['kzip']},
	{'value': 'क्षेप्तव्य', 'label': 'kzeptavya', 'attrib': [{'pos': 'ger', 'defs': 'throw'}], 'isLemma': 0, 'parents': ['kzip']},
	{'value': 'क्षेप्य', 'label': 'kzepya', 'attrib': [{'pos': 'ger', 'defs': 'throw'}], 'isLemma': 0, 'parents': ['kzip']},
	{'value': 'क्षेप्स्यत्', 'label': 'kzepsyat', 'attrib': [{'pos': 'fap', 'defs': 'throw'}], 'isLemma': 0, 'parents': ['kzip']},
	{'value': 'क्षेप्स्यमाण', 'label': 'kzepsyamARa', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'throw'}], 'isLemma': 0, 'parents': ['kzip']},
	{'value': 'क्षेय', 'label': 'kzeya', 'attrib': [{'pos': 'ger', 'defs': 'perish, destroy'}, {'pos': 'ger', 'defs': 'possess, rule'}, {'pos': 'ger', 'defs': 'destroy'}], 'isLemma': 0, 'parents': ['kzi']},
	{'value': 'क्षेष्यत्', 'label': 'kzezyat', 'attrib': [{'pos': 'fap', 'defs': 'perish, destroy'}, {'pos': 'fap', 'defs': 'possess, rule'}, {'pos': 'fap', 'defs': 'destroy'}], 'isLemma': 0, 'parents': ['kzi']},
	{'value': 'क्षेष्यमाण', 'label': 'kzezyamARa', 'attrib': [{'pos': 'fpp', 'defs': 'perish, destroy'}, {'pos': 'fpp', 'defs': 'possess, rule'}, {'pos': 'fpp', 'defs': 'destroy'}], 'isLemma': 0, 'parents': ['kzi']},
	{'value': 'खन्', 'label': 'Kan', 'attrib': [{'pos': 'vt1am', 'defs': 'dig'}], 'isLemma': 1},
	{'value': 'खनित्र', 'label': 'Kanitra', 'attrib': [{'pos': 'n', 'defs': 'instrument for digging, shovel'}], 'isLemma': 1},
	{'value': 'खनित्रिन्', 'label': 'Kanitrin', 'attrib': [{'pos': 'm', 'defs': 'an excavator'}], 'isLemma': 1},
	{'value': 'खनित्री', 'label': 'KanitrI', 'attrib': [{'pos': 'f', 'defs': 'shovel'}], 'isLemma': 1},
	{'value': 'खाण्डव', 'label': 'KARqava', 'attrib': [{'pos': 'm_pn, n_pn', 'defs': 'Khāṇḍava'}], 'isLemma': 1},
	{'value': 'खाण्डवप्रस्थ', 'label': 'KARqavaprasTa', 'attrib': [{'pos': 'm', 'defs': 'the Khāṇḍava plain'}], 'isLemma': 1},
	{'value': 'गङ्गा', 'label': 'gaNgA', 'attrib': [{'pos': 'f_pn', 'defs': 'the river Ganges'}], 'isLemma': 1},
	{'value': 'गच्छत्', 'label': 'gacCat', 'attrib': [{'pos': 'prap', 'defs': 'go'}], 'isLemma': 0, 'parents': ['gam']},
	{'value': 'गण', 'label': 'gaRa', 'attrib': [{'pos': 'm', 'defs': 'group, multitude'}], 'isLemma': 1},
	{'value': 'गत', 'label': 'gata', 'attrib': [{'pos': 'ppp', 'defs': 'go'}], 'isLemma': 0, 'parents': ['gam']},
	{'value': 'गतमन्यु', 'label': 'gatamanyu', 'attrib': [{'pos': 'adj', 'defs': 'free of anger'}], 'isLemma': 1},
	{'value': 'गतवत्', 'label': 'gatavat', 'attrib': [{'pos': 'pap', 'defs': 'go'}], 'isLemma': 0, 'parents': ['gam']},
	{'value': 'गतव्यथ', 'label': 'gatavyaTa', 'attrib': [{'pos': 'adj', 'defs': 'fearless, free of trembling'}], 'isLemma': 1},
	{'value': 'गत्वा', 'label': 'gatvA', 'attrib': [{'pos': 'abs', 'defs': 'go'}], 'isLemma': 0, 'parents': ['gam']},
	{'value': 'गन्तव्य', 'label': 'gantavya', 'attrib': [{'pos': 'ger', 'defs': 'go'}], 'isLemma': 0, 'parents': ['gam']},
	{'value': 'गन्तुम्', 'label': 'gantum', 'attrib': [{'pos': 'inf', 'defs': 'go'}], 'isLemma': 0, 'parents': ['gam']},
	{'value': 'गम्', 'label': 'gam', 'attrib': [{'pos': 'vt1a', 'defs': 'go'}], 'isLemma': 1},
	{'value': 'गमनीय', 'label': 'gamanIya', 'attrib': [{'pos': 'ger', 'defs': 'go'}], 'isLemma': 0, 'parents': ['gam']},
	{'value': 'गमिष्यत्', 'label': 'gamizyat', 'attrib': [{'pos': 'fap', 'defs': 'go'}], 'isLemma': 0, 'parents': ['gam']},
	{'value': 'गमिष्यमाण', 'label': 'gamizyamARa', 'attrib': [{'pos': 'fpp', 'defs': 'go'}], 'isLemma': 0, 'parents': ['gam']},
	{'value': 'गम्य', 'label': 'gamya', 'attrib': [{'pos': 'ger', 'defs': 'go'}], 'isLemma': 0, 'parents': ['gam']},
	{'value': 'गम्यमान', 'label': 'gamyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'go'}], 'isLemma': 0, 'parents': ['gam']},
	{'value': 'गाण्डी', 'label': 'gARqI', 'attrib': [{'pos': 'f', 'defs': 'rhinoceros'}], 'isLemma': 1},
	{'value': 'गाण्डीव', 'label': 'gARqIva', 'attrib': [{'pos': 'm_pn, n_pn', 'defs': 'Gāṇḍīva'}], 'isLemma': 1},
	{'value': 'गुरु', 'label': 'guru', 'attrib': [{'pos': 'adj', 'defs': 'heavy'}, {'pos': 'm', 'defs': 'teacher, elder'}], 'isLemma': 1},
	{'value': 'गृह', 'label': 'gfha', 'attrib': [{'pos': 'n', 'defs': 'room, house'}], 'isLemma': 1},
	{'value': 'ग्रह्', 'label': 'grah', 'attrib': [{'pos': 'vt9a', 'defs': 'grab'}], 'isLemma': 1},
	{'value': 'घात्य', 'label': 'GAtya', 'attrib': [{'pos': 'ger', 'defs': 'smite'}], 'isLemma': 0, 'parents': ['han']},
	{'value': 'घोर', 'label': 'Gora', 'attrib': [{'pos': 'adj', 'defs': 'awful'}], 'isLemma': 1},
	{'value': 'घ्नत्', 'label': 'Gnat', 'attrib': [{'pos': 'prap', 'defs': 'smite'}], 'isLemma': 0, 'parents': ['han']},
	{'value': 'च', 'label': 'ca', 'attrib': [{'pos': 'conj_pcl', 'defs': 'and'}], 'isLemma': 1},
	{'value': 'चकृवस्', 'label': 'cakfvas', 'attrib': [{'pos': 'pfap', 'defs': 'do, make'}], 'isLemma': 0, 'parents': ['kf']},
	{'value': 'चक्र', 'label': 'cakra', 'attrib': [{'pos': 'n', 'defs': 'wheel, discus, circle'}], 'isLemma': 1},
	{'value': 'चक्राण', 'label': 'cakrARa', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'do, make'}], 'isLemma': 0, 'parents': ['kf']},
	{'value': 'चतुर्', 'label': 'catur', 'attrib': [{'pos': 'card_num', 'defs': 'four'}], 'isLemma': 1},
	{'value': 'चतुर्दश', 'label': 'caturdaSa', 'attrib': [{'pos': 'ord_num', 'defs': 'fourteenth'}], 'isLemma': 1},
	{'value': 'चन', 'label': 'cana', 'attrib': [{'pos': 'pcl', 'defs': '/'}], 'isLemma': 1},
	{'value': 'चयनीय', 'label': 'cayanIya', 'attrib': [{'pos': 'ger', 'defs': 'pile up'}], 'isLemma': 0, 'parents': ['ci']},
	{'value': 'चर्', 'label': 'car', 'attrib': [{'pos': 'vt1am', 'defs': 'move, do'}], 'isLemma': 1},
	{'value': 'चरणीय', 'label': 'caraRIya', 'attrib': [{'pos': 'ger', 'defs': 'move, do'}], 'isLemma': 0, 'parents': ['car']},
	{'value': 'चरत्', 'label': 'carat', 'attrib': [{'pos': 'prap', 'defs': 'move, do'}], 'isLemma': 0, 'parents': ['car']},
	{'value': 'चरमाण', 'label': 'caramARa', 'attrib': [{'pos': 'prmp', 'defs': 'move, do'}], 'isLemma': 0, 'parents': ['car']},
	{'value': 'चरित', 'label': 'carita', 'attrib': [{'pos': 'ppp', 'defs': 'move, do'}], 'isLemma': 0, 'parents': ['car']},
	{'value': 'चरितवत्', 'label': 'caritavat', 'attrib': [{'pos': 'pap', 'defs': 'move, do'}], 'isLemma': 0, 'parents': ['car']},
	{'value': 'चरितव्य', 'label': 'caritavya', 'attrib': [{'pos': 'ger', 'defs': 'move, do'}], 'isLemma': 0, 'parents': ['car']},
	{'value': 'चरितुम्', 'label': 'caritum', 'attrib': [{'pos': 'inf', 'defs': 'move, do'}], 'isLemma': 0, 'parents': ['car']},
	{'value': 'चरित्वा', 'label': 'caritvA', 'attrib': [{'pos': 'abs', 'defs': 'move, do'}], 'isLemma': 0, 'parents': ['car']},
	{'value': 'चरिष्यत्', 'label': 'carizyat', 'attrib': [{'pos': 'fap', 'defs': 'move, do'}], 'isLemma': 0, 'parents': ['car']},
	{'value': 'चरिष्यमाण', 'label': 'carizyamARa', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'move, do'}], 'isLemma': 0, 'parents': ['car']},
	{'value': 'चर्य', 'label': 'carya', 'attrib': [{'pos': 'ger', 'defs': 'move, do'}], 'isLemma': 0, 'parents': ['car']},
	{'value': 'चर्यमाण', 'label': 'caryamARa', 'attrib': [{'pos': 'prpp', 'defs': 'move, do'}], 'isLemma': 0, 'parents': ['car']},
	{'value': 'चि', 'label': 'ci', 'attrib': [{'pos': 'vt5am', 'defs': 'pile up'}], 'isLemma': 1},
	{'value': 'चिकिवस्', 'label': 'cikivas', 'attrib': [{'pos': 'pfap', 'defs': 'pile up'}], 'isLemma': 0, 'parents': ['ci']},
	{'value': 'चिक्लिशान', 'label': 'cikliSAna', 'attrib': [{'pos': 'pfpp', 'defs': 'torment, afflict'}], 'isLemma': 0, 'parents': ['kliS']},
	{'value': 'चिक्लिशिवस्', 'label': 'cikliSivas', 'attrib': [{'pos': 'pfap', 'defs': 'torment, afflict'}], 'isLemma': 0, 'parents': ['kliS']},
	{'value': 'चिक्षिपाण', 'label': 'cikzipARa', 'attrib': [{'pos': 'pfpp', 'defs': 'throw'}], 'isLemma': 0, 'parents': ['kzip']},
	{'value': 'चिक्षिपिवस्', 'label': 'cikzipivas', 'attrib': [{'pos': 'pfap', 'defs': 'throw'}], 'isLemma': 0, 'parents': ['kzip']},
	{'value': 'चिक्षियाण', 'label': 'cikziyARa', 'attrib': [{'pos': 'pfpp', 'defs': 'perish, destroy'}, {'pos': 'pfpp', 'defs': 'possess, rule'}, {'pos': 'pfpp', 'defs': 'destroy'}], 'isLemma': 0, 'parents': ['kzi']},
	{'value': 'चिक्षिवस्', 'label': 'cikzivas', 'attrib': [{'pos': 'pfap', 'defs': 'perish, destroy'}, {'pos': 'pfap', 'defs': 'possess, rule'}, {'pos': 'pfap', 'defs': 'destroy'}], 'isLemma': 0, 'parents': ['kzi']},
	{'value': 'चिचिवस्', 'label': 'cicivas', 'attrib': [{'pos': 'pfap', 'defs': 'pile up'}], 'isLemma': 0, 'parents': ['ci']},
	{'value': 'चिच्छिदान', 'label': 'cicCidAna', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'split, cut'}], 'isLemma': 0, 'parents': ['Cid']},
	{'value': 'चिच्छिद्वस्', 'label': 'cicCidvas', 'attrib': [{'pos': 'pfap', 'defs': 'split, cut'}], 'isLemma': 0, 'parents': ['Cid']},
	{'value': 'चित्', 'label': 'cit', 'attrib': [{'pos': 'pcl', 'defs': '/'}], 'isLemma': 1},
	{'value': 'चित', 'label': 'cita', 'attrib': [{'pos': 'ppp', 'defs': 'pile up'}], 'isLemma': 0, 'parents': ['ci']},
	{'value': 'चितवत्', 'label': 'citavat', 'attrib': [{'pos': 'pap', 'defs': 'pile up'}], 'isLemma': 0, 'parents': ['ci']},
	{'value': 'चित्वा', 'label': 'citvA', 'attrib': [{'pos': 'abs', 'defs': 'pile up'}], 'isLemma': 0, 'parents': ['ci']},
	{'value': 'चिन्त्', 'label': 'cint', 'attrib': [{'pos': 'vt10am', 'defs': 'consider'}], 'isLemma': 1},
	{'value': 'चिन्तनीय', 'label': 'cintanIya', 'attrib': [{'pos': 'ger', 'defs': 'consider'}], 'isLemma': 0, 'parents': ['cint']},
	{'value': 'चिन्तयत्', 'label': 'cintayat', 'attrib': [{'pos': 'prap', 'defs': 'consider'}], 'isLemma': 0, 'parents': ['cint']},
	{'value': 'चिन्तयमान', 'label': 'cintayamAna', 'attrib': [{'pos': 'prmp', 'defs': 'consider'}], 'isLemma': 0, 'parents': ['cint']},
	{'value': 'चिन्तयाम्', 'label': 'cintayAm', 'attrib': [{'pos': 'ppf_actn', 'defs': 'consider'}], 'isLemma': 0, 'parents': ['cint']},
	{'value': 'चिन्तयिष्यत्', 'label': 'cintayizyat', 'attrib': [{'pos': 'fap', 'defs': 'consider'}], 'isLemma': 0, 'parents': ['cint']},
	{'value': 'चिन्तयिष्यमाण', 'label': 'cintayizyamARa', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'consider'}], 'isLemma': 0, 'parents': ['cint']},
	{'value': 'चिन्तित', 'label': 'cintita', 'attrib': [{'pos': 'ppp', 'defs': 'consider'}], 'isLemma': 0, 'parents': ['cint']},
	{'value': 'चिन्तितवत्', 'label': 'cintitavat', 'attrib': [{'pos': 'pap', 'defs': 'consider'}], 'isLemma': 0, 'parents': ['cint']},
	{'value': 'चिन्तितव्य', 'label': 'cintitavya', 'attrib': [{'pos': 'ger', 'defs': 'consider'}], 'isLemma': 0, 'parents': ['cint']},
	{'value': 'चिन्तितुम्', 'label': 'cintitum', 'attrib': [{'pos': 'inf', 'defs': 'consider'}], 'isLemma': 0, 'parents': ['cint']},
	{'value': 'चिन्तित्वा', 'label': 'cintitvA', 'attrib': [{'pos': 'abs', 'defs': 'consider'}], 'isLemma': 0, 'parents': ['cint']},
	{'value': 'चिन्त्य', 'label': 'cintya', 'attrib': [{'pos': 'ger', 'defs': 'consider'}], 'isLemma': 0, 'parents': ['cint']},
	{'value': 'चिन्त्यमान', 'label': 'cintyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'consider'}], 'isLemma': 0, 'parents': ['cint']},
	{'value': 'चिन्वत्', 'label': 'cinvat', 'attrib': [{'pos': 'prap', 'defs': 'pile up'}], 'isLemma': 0, 'parents': ['ci']},
	{'value': 'चिन्वान', 'label': 'cinvAna', 'attrib': [{'pos': 'prmp', 'defs': 'pile up'}], 'isLemma': 0, 'parents': ['ci']},
	{'value': 'चियान', 'label': 'ciyAna', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'pile up'}], 'isLemma': 0, 'parents': ['ci']},
	{'value': 'चिर', 'label': 'cira', 'attrib': [{'pos': 'adj', 'defs': 'long'}, {'pos': 'n', 'defs': 'delay'}], 'isLemma': 1},
	{'value': 'चीयमाण', 'label': 'cIyamARa', 'attrib': [{'pos': 'prpp', 'defs': 'pile up'}], 'isLemma': 0, 'parents': ['ci']},
	{'value': 'चुक्रुधान', 'label': 'cukruDAna', 'attrib': [{'pos': 'pfpp', 'defs': 'be angry'}], 'isLemma': 0, 'parents': ['kruD']},
	{'value': 'चुक्रुध्वस्', 'label': 'cukruDvas', 'attrib': [{'pos': 'pfap', 'defs': 'be angry'}], 'isLemma': 0, 'parents': ['kruD']},
	{'value': 'चेतव्य', 'label': 'cetavya', 'attrib': [{'pos': 'ger', 'defs': 'pile up'}], 'isLemma': 0, 'parents': ['ci']},
	{'value': 'चेतुम्', 'label': 'cetum', 'attrib': [{'pos': 'inf', 'defs': 'pile up'}], 'isLemma': 0, 'parents': ['ci']},
	{'value': 'चेय', 'label': 'ceya', 'attrib': [{'pos': 'ger', 'defs': 'pile up'}], 'isLemma': 0, 'parents': ['ci']},
	{'value': 'चेराण', 'label': 'cerARa', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'move, do'}], 'isLemma': 0, 'parents': ['car']},
	{'value': 'चेरिवस्', 'label': 'cerivas', 'attrib': [{'pos': 'pfap', 'defs': 'move, do'}], 'isLemma': 0, 'parents': ['car']},
	{'value': 'चेष्यत्', 'label': 'cezyat', 'attrib': [{'pos': 'fap', 'defs': 'pile up'}], 'isLemma': 0, 'parents': ['ci']},
	{'value': 'चेष्यमाण', 'label': 'cezyamARa', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'pile up'}], 'isLemma': 0, 'parents': ['ci']},
	{'value': 'छित्त्वा', 'label': 'CittvA', 'attrib': [{'pos': 'abs', 'defs': 'split, cut'}], 'isLemma': 0, 'parents': ['Cid']},
	{'value': 'छिद्', 'label': 'Cid', 'attrib': [{'pos': 'vt7am', 'defs': 'split, cut'}], 'isLemma': 1},
	{'value': 'छिद्यमान', 'label': 'CidyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'split, cut'}], 'isLemma': 0, 'parents': ['Cid']},
	{'value': 'छिन्दत्', 'label': 'Cindat', 'attrib': [{'pos': 'prap', 'defs': 'split, cut'}], 'isLemma': 0, 'parents': ['Cid']},
	{'value': 'छिन्दान', 'label': 'CindAna', 'attrib': [{'pos': 'prmp', 'defs': 'split, cut'}], 'isLemma': 0, 'parents': ['Cid']},
	{'value': 'छिन्न', 'label': 'Cinna', 'attrib': [{'pos': 'ppp', 'defs': 'split, cut'}], 'isLemma': 0, 'parents': ['Cid']},
	{'value': 'छिन्नवत्', 'label': 'Cinnavat', 'attrib': [{'pos': 'pap', 'defs': 'split, cut'}], 'isLemma': 0, 'parents': ['Cid']},
	{'value': 'छेत्तव्य', 'label': 'Cettavya', 'attrib': [{'pos': 'ger', 'defs': 'split, cut'}], 'isLemma': 0, 'parents': ['Cid']},
	{'value': 'छेत्तुम्', 'label': 'Cettum', 'attrib': [{'pos': 'inf', 'defs': 'split, cut'}], 'isLemma': 0, 'parents': ['Cid']},
	{'value': 'छेत्स्यत्', 'label': 'Cetsyat', 'attrib': [{'pos': 'fap', 'defs': 'split, cut'}], 'isLemma': 0, 'parents': ['Cid']},
	{'value': 'छेत्स्यमान', 'label': 'CetsyamAna', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'split, cut'}], 'isLemma': 0, 'parents': ['Cid']},
	{'value': 'छेदनीय', 'label': 'CedanIya', 'attrib': [{'pos': 'ger', 'defs': 'split, cut'}], 'isLemma': 0, 'parents': ['Cid']},
	{'value': 'छेद्य', 'label': 'Cedya', 'attrib': [{'pos': 'ger', 'defs': 'split, cut'}], 'isLemma': 0, 'parents': ['Cid']},
	{'value': 'ज', 'label': 'ja', 'attrib': [{'pos': 'adj', 'defs': 'born'}], 'isLemma': 1},
	{'value': 'जगन्वस्', 'label': 'jaganvas', 'attrib': [{'pos': 'pfap', 'defs': 'go'}], 'isLemma': 0, 'parents': ['gam']},
	{'value': 'जग्मान', 'label': 'jagmAna', 'attrib': [{'pos': 'pfpp', 'defs': 'go'}], 'isLemma': 0, 'parents': ['gam']},
	{'value': 'जग्मिवस्', 'label': 'jagmivas', 'attrib': [{'pos': 'pfap', 'defs': 'go'}], 'isLemma': 0, 'parents': ['gam']},
	{'value': 'जघन्वस्', 'label': 'jaGanvas', 'attrib': [{'pos': 'pfap', 'defs': 'smite'}], 'isLemma': 0, 'parents': ['han']},
	{'value': 'जघ्नान', 'label': 'jaGnAna', 'attrib': [{'pos': 'pfpp', 'defs': 'smite'}], 'isLemma': 0, 'parents': ['han']},
	{'value': 'जघ्निवस्', 'label': 'jaGnivas', 'attrib': [{'pos': 'pfap', 'defs': 'smite'}], 'isLemma': 0, 'parents': ['han']},
	{'value': 'जजराण', 'label': 'jajarARa', 'attrib': [{'pos': 'pfpp', 'defs': 'wither, age, grow old; caus.: digest'}], 'isLemma': 0, 'parents': ['jF']},
	{'value': 'जजर्वस्', 'label': 'jajarvas', 'attrib': [{'pos': 'pfap', 'defs': 'wither, age, grow old; caus.: digest'}], 'isLemma': 0, 'parents': ['jF']},
	{'value': 'जज्ञान', 'label': 'jajYAna', 'attrib': [{'pos': 'pfpp', 'defs': 'know'}], 'isLemma': 0, 'parents': ['jYA']},
	{'value': 'जज्ञिवस्', 'label': 'jajYivas', 'attrib': [{'pos': 'pfap', 'defs': 'know'}], 'isLemma': 0, 'parents': ['jYA']},
	{'value': 'जतु', 'label': 'jatu', 'attrib': [{'pos': 'n', 'defs': 'lac'}], 'isLemma': 1},
	{'value': 'जन्', 'label': 'jan', 'attrib': [{'pos': 'vi4m', 'defs': 'be born'}], 'isLemma': 1},
	{'value': 'जन', 'label': 'jana', 'attrib': [{'pos': 'm', 'defs': 'people'}], 'isLemma': 1},
	{'value': 'जनपद', 'label': 'janapada', 'attrib': [{'pos': 'n', 'defs': 'district'}], 'isLemma': 1},
	{'value': 'जनपदोपेत', 'label': 'janapadopeta', 'attrib': [{'pos': 'adj', 'defs': 'endowed with a district'}], 'isLemma': 1},
	{'value': 'जनमेजय', 'label': 'janamejaya', 'attrib': [{'pos': 'm_pn', 'defs': 'Janamejaya'}], 'isLemma': 1},
	{'value': 'जय', 'label': 'jaya', 'attrib': [{'pos': 'm', 'defs': 'victory'}], 'isLemma': 1},
	{'value': 'जयत्', 'label': 'jayat', 'attrib': [{'pos': 'prap', 'defs': 'conquer'}], 'isLemma': 0, 'parents': ['ji']},
	{'value': 'जयनीय', 'label': 'jayanIya', 'attrib': [{'pos': 'ger', 'defs': 'conquer'}], 'isLemma': 0, 'parents': ['ji']},
	{'value': 'जरणीय', 'label': 'jaraRIya', 'attrib': [{'pos': 'ger', 'defs': 'wither, age, grow old; caus.: digest'}], 'isLemma': 0, 'parents': ['jF']},
	{'value': 'जरयाम्', 'label': 'jarayAm', 'attrib': [{'pos': 'ppf_actn', 'defs': 'wither, age, grow old; caus.: digest'}], 'isLemma': 0, 'parents': ['jF']},
	{'value': 'जरितव्य', 'label': 'jaritavya', 'attrib': [{'pos': 'ger', 'defs': 'wither, age, grow old; caus.: digest'}], 'isLemma': 0, 'parents': ['jF']},
	{'value': 'जरितुम्', 'label': 'jaritum', 'attrib': [{'pos': 'inf', 'defs': 'wither, age, grow old; caus.: digest'}], 'isLemma': 0, 'parents': ['jF']},
	{'value': 'जरित्वा', 'label': 'jaritvA', 'attrib': [{'pos': 'abs', 'defs': 'wither, age, grow old; caus.: digest'}], 'isLemma': 0, 'parents': ['jF']},
	{'value': 'जरिष्यत्', 'label': 'jarizyat', 'attrib': [{'pos': 'fap', 'defs': 'wither, age, grow old; caus.: digest'}], 'isLemma': 0, 'parents': ['jF']},
	{'value': 'जरिष्यमाण', 'label': 'jarizyamARa', 'attrib': [{'pos': 'fpp', 'defs': 'wither, age, grow old; caus.: digest'}], 'isLemma': 0, 'parents': ['jF']},
	{'value': 'जरीतव्य', 'label': 'jarItavya', 'attrib': [{'pos': 'ger', 'defs': 'wither, age, grow old; caus.: digest'}], 'isLemma': 0, 'parents': ['jF']},
	{'value': 'जरीतुम्', 'label': 'jarItum', 'attrib': [{'pos': 'inf', 'defs': 'wither, age, grow old; caus.: digest'}], 'isLemma': 0, 'parents': ['jF']},
	{'value': 'जरीत्वा', 'label': 'jarItvA', 'attrib': [{'pos': 'abs', 'defs': 'wither, age, grow old; caus.: digest'}], 'isLemma': 0, 'parents': ['jF']},
	{'value': 'जर्य', 'label': 'jarya', 'attrib': [{'pos': 'ger', 'defs': 'wither, age, grow old; caus.: digest'}], 'isLemma': 0, 'parents': ['jF']},
	{'value': 'जातुष', 'label': 'jAtuza', 'attrib': [{'pos': 'adj', 'defs': 'made of lac'}], 'isLemma': 1},
	{'value': 'जानत्', 'label': 'jAnat', 'attrib': [{'pos': 'prap', 'defs': 'know'}], 'isLemma': 0, 'parents': ['jYA']},
	{'value': 'जार्य', 'label': 'jArya', 'attrib': [{'pos': 'ger', 'defs': 'wither, age, grow old; caus.: digest'}], 'isLemma': 0, 'parents': ['jF']},
	{'value': 'जि', 'label': 'ji', 'attrib': [{'pos': 'vt1a', 'defs': 'conquer'}], 'isLemma': 1},
	{'value': 'जिगिवस्', 'label': 'jigivas', 'attrib': [{'pos': 'pfap', 'defs': 'conquer'}], 'isLemma': 0, 'parents': ['ji']},
	{'value': 'जिग्यान', 'label': 'jigyAna', 'attrib': [{'pos': 'pfpp', 'defs': 'conquer'}], 'isLemma': 0, 'parents': ['ji']},
	{'value': 'जिजीवस्', 'label': 'jijIvas', 'attrib': [{'pos': 'pfap', 'defs': 'live'}], 'isLemma': 0, 'parents': ['jIv']},
	{'value': 'जिजीवान', 'label': 'jijIvAna', 'attrib': [{'pos': 'pfpp', 'defs': 'live'}], 'isLemma': 0, 'parents': ['jIv']},
	{'value': 'जित', 'label': 'jita', 'attrib': [{'pos': 'ppp', 'defs': 'conquer'}], 'isLemma': 0, 'parents': ['ji']},
	{'value': 'जितवत्', 'label': 'jitavat', 'attrib': [{'pos': 'pap', 'defs': 'conquer'}], 'isLemma': 0, 'parents': ['ji']},
	{'value': 'जित्वा', 'label': 'jitvA', 'attrib': [{'pos': 'abs', 'defs': 'conquer'}], 'isLemma': 0, 'parents': ['ji']},
	{'value': 'जीयमान', 'label': 'jIyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'conquer'}], 'isLemma': 0, 'parents': ['ji']},
	{'value': 'जीर्ण', 'label': 'jIrRa', 'attrib': [{'pos': 'ppp', 'defs': 'wither, age, grow old; caus.: digest'}], 'isLemma': 0, 'parents': ['jF']},
	{'value': 'जीर्णवत्', 'label': 'jIrRavat', 'attrib': [{'pos': 'pap', 'defs': 'wither, age, grow old; caus.: digest'}], 'isLemma': 0, 'parents': ['jF']},
	{'value': 'जीर्यत्', 'label': 'jIryat', 'attrib': [{'pos': 'prap', 'defs': 'wither, age, grow old; caus.: digest'}], 'isLemma': 0, 'parents': ['jF']},
	{'value': 'जीर्यमाण', 'label': 'jIryamARa', 'attrib': [{'pos': 'prpp', 'defs': 'wither, age, grow old; caus.: digest'}], 'isLemma': 0, 'parents': ['jF']},
	{'value': 'जीव्', 'label': 'jIv', 'attrib': [{'pos': 'vi1a', 'defs': 'live'}], 'isLemma': 1},
	{'value': 'जीव', 'label': 'jIva', 'attrib': [{'pos': 'm', 'defs': 'a living being, individual soul'}], 'isLemma': 1},
	{'value': 'जीवत्', 'label': 'jIvat', 'attrib': [{'pos': 'prap', 'defs': 'live'}], 'isLemma': 0, 'parents': ['jIv']},
	{'value': 'जीवनीय', 'label': 'jIvanIya', 'attrib': [{'pos': 'ger', 'defs': 'live'}], 'isLemma': 0, 'parents': ['jIv']},
	{'value': 'जीवलोक', 'label': 'jIvaloka', 'attrib': [{'pos': 'm', 'defs': 'the world of living beings'}], 'isLemma': 1},
	{'value': 'जीवित', 'label': 'jIvita', 'attrib': [{'pos': 'ppp', 'defs': 'live'}], 'isLemma': 0, 'parents': ['jIv']},
	{'value': 'जीवितवत्', 'label': 'jIvitavat', 'attrib': [{'pos': 'pap', 'defs': 'live'}], 'isLemma': 0, 'parents': ['jIv']},
	{'value': 'जीवितव्य', 'label': 'jIvitavya', 'attrib': [{'pos': 'ger', 'defs': 'live'}], 'isLemma': 0, 'parents': ['jIv']},
	{'value': 'जीवितुम्', 'label': 'jIvitum', 'attrib': [{'pos': 'inf', 'defs': 'live'}], 'isLemma': 0, 'parents': ['jIv']},
	{'value': 'जीवित्वा', 'label': 'jIvitvA', 'attrib': [{'pos': 'abs', 'defs': 'live'}], 'isLemma': 0, 'parents': ['jIv']},
	{'value': 'जीविष्यत्', 'label': 'jIvizyat', 'attrib': [{'pos': 'fap', 'defs': 'live'}], 'isLemma': 0, 'parents': ['jIv']},
	{'value': 'जीविष्यमाण', 'label': 'jIvizyamARa', 'attrib': [{'pos': 'fpp', 'defs': 'live'}], 'isLemma': 0, 'parents': ['jIv']},
	{'value': 'जीव्य', 'label': 'jIvya', 'attrib': [{'pos': 'ger', 'defs': 'live'}], 'isLemma': 0, 'parents': ['jIv']},
	{'value': 'जीव्यमान', 'label': 'jIvyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'live'}], 'isLemma': 0, 'parents': ['jIv']},
	{'value': 'जुहवाम्', 'label': 'juhavAm', 'attrib': [{'pos': 'ppf_actn', 'defs': 'offer, worship'}], 'isLemma': 0, 'parents': ['hu']},
	{'value': 'जुह्वत्', 'label': 'juhvat', 'attrib': [{'pos': 'prap', 'defs': 'offer, worship'}], 'isLemma': 0, 'parents': ['hu']},
	{'value': 'जॄ', 'label': 'jF', 'attrib': [{'pos': 'vi4a', 'defs': 'wither, age, grow old; caus.: digest'}], 'isLemma': 1},
	{'value': 'जेतव्य', 'label': 'jetavya', 'attrib': [{'pos': 'ger', 'defs': 'conquer'}], 'isLemma': 0, 'parents': ['ji']},
	{'value': 'जेतुम्', 'label': 'jetum', 'attrib': [{'pos': 'inf', 'defs': 'conquer'}], 'isLemma': 0, 'parents': ['ji']},
	{'value': 'जेय', 'label': 'jeya', 'attrib': [{'pos': 'ger', 'defs': 'conquer'}], 'isLemma': 0, 'parents': ['ji']},
	{'value': 'जेराण', 'label': 'jerARa', 'attrib': [{'pos': 'pfpp', 'defs': 'wither, age, grow old; caus.: digest'}], 'isLemma': 0, 'parents': ['jF']},
	{'value': 'जेरिवस्', 'label': 'jerivas', 'attrib': [{'pos': 'pfap', 'defs': 'wither, age, grow old; caus.: digest'}], 'isLemma': 0, 'parents': ['jF']},
	{'value': 'जेष्यत्', 'label': 'jezyat', 'attrib': [{'pos': 'fap', 'defs': 'conquer'}], 'isLemma': 0, 'parents': ['ji']},
	{'value': 'जेष्यमाण', 'label': 'jezyamARa', 'attrib': [{'pos': 'fpp', 'defs': 'conquer'}], 'isLemma': 0, 'parents': ['ji']},
	{'value': 'ज्ञा', 'label': 'jYA', 'attrib': [{'pos': 'vt9am', 'defs': 'know'}], 'isLemma': 1},
	{'value': 'ज्ञात', 'label': 'jYAta', 'attrib': [{'pos': 'ppp', 'defs': 'know'}], 'isLemma': 0, 'parents': ['jYA']},
	{'value': 'ज्ञातवत्', 'label': 'jYAtavat', 'attrib': [{'pos': 'pap', 'defs': 'know'}], 'isLemma': 0, 'parents': ['jYA']},
	{'value': 'ज्ञातव्य', 'label': 'jYAtavya', 'attrib': [{'pos': 'ger', 'defs': 'know'}], 'isLemma': 0, 'parents': ['jYA']},
	{'value': 'ज्ञातुम्', 'label': 'jYAtum', 'attrib': [{'pos': 'inf', 'defs': 'know'}], 'isLemma': 0, 'parents': ['jYA']},
	{'value': 'ज्ञात्वा', 'label': 'jYAtvA', 'attrib': [{'pos': 'abs', 'defs': 'know'}], 'isLemma': 0, 'parents': ['jYA']},
	{'value': 'ज्ञानीय', 'label': 'jYAnIya', 'attrib': [{'pos': 'ger', 'defs': 'know'}], 'isLemma': 0, 'parents': ['jYA']},
	{'value': 'ज्ञाप्य', 'label': 'jYApya', 'attrib': [{'pos': 'abs', 'defs': 'know'}], 'isLemma': 0, 'parents': ['jYA']},
	{'value': 'ज्ञायमान', 'label': 'jYAyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'know'}], 'isLemma': 0, 'parents': ['jYA']},
	{'value': 'ज्ञास्यत्', 'label': 'jYAsyat', 'attrib': [{'pos': 'fap', 'defs': 'know'}], 'isLemma': 0, 'parents': ['jYA']},
	{'value': 'ज्ञास्यमान', 'label': 'jYAsyamAna', 'attrib': [{'pos': 'fpp', 'defs': 'know'}], 'isLemma': 0, 'parents': ['jYA']},
	{'value': 'ज्ञेय', 'label': 'jYeya', 'attrib': [{'pos': 'ger', 'defs': 'know'}], 'isLemma': 0, 'parents': ['jYA']},
	{'value': 'तंस्यत्', 'label': 'taMsyat', 'attrib': [{'pos': 'fap', 'defs': 'extend'}], 'isLemma': 0, 'parents': ['tan']},
	{'value': 'तंस्यमान', 'label': 'taMsyamAna', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'extend'}], 'isLemma': 0, 'parents': ['tan']},
	{'value': 'तत', 'label': 'tata', 'attrib': [{'pos': 'ppp', 'defs': 'extend'}], 'isLemma': 0, 'parents': ['tan']},
	{'value': 'ततवत्', 'label': 'tatavat', 'attrib': [{'pos': 'pap', 'defs': 'extend'}], 'isLemma': 0, 'parents': ['tan']},
	{'value': 'ततस्', 'label': 'tatas', 'attrib': [{'pos': 'dem_adv', 'defs': 'after that, therefore, then'}], 'isLemma': 1},
	{'value': 'ततृपिवस्', 'label': 'tatfpivas', 'attrib': [{'pos': 'pfap', 'defs': 'become satisfied, be pleased; caus. satiate, satisfy, please'}], 'isLemma': 0, 'parents': ['tfp']},
	{'value': 'तत्र', 'label': 'tatra', 'attrib': [{'pos': 'dem_adv', 'defs': 'in that, there'}], 'isLemma': 1},
	{'value': 'तथा', 'label': 'taTA', 'attrib': [{'pos': 'dem_adv', 'defs': 'thus'}], 'isLemma': 1},
	{'value': 'तद्', 'label': 'tad', 'attrib': [{'pos': 'dem_adj, dem_pron', 'defs': 'that'}], 'isLemma': 1},
	{'value': 'तदा', 'label': 'tadA', 'attrib': [{'pos': 'dem_adv', 'defs': 'at that time, then'}], 'isLemma': 1},
	{'value': 'तन्', 'label': 'tan', 'attrib': [{'pos': 'vt8am', 'defs': 'extend'}], 'isLemma': 1},
	{'value': 'तननीय', 'label': 'tananIya', 'attrib': [{'pos': 'ger', 'defs': 'extend'}], 'isLemma': 0, 'parents': ['tan']},
	{'value': 'तनितव्य', 'label': 'tanitavya', 'attrib': [{'pos': 'ger', 'defs': 'extend'}], 'isLemma': 0, 'parents': ['tan']},
	{'value': 'तनितुम्', 'label': 'tanitum', 'attrib': [{'pos': 'inf', 'defs': 'extend'}], 'isLemma': 0, 'parents': ['tan']},
	{'value': 'तनित्वा', 'label': 'tanitvA', 'attrib': [{'pos': 'abs', 'defs': 'extend'}], 'isLemma': 0, 'parents': ['tan']},
	{'value': 'तनिष्यत्', 'label': 'tanizyat', 'attrib': [{'pos': 'fap', 'defs': 'extend'}], 'isLemma': 0, 'parents': ['tan']},
	{'value': 'तनिष्यमाण', 'label': 'tanizyamARa', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'extend'}], 'isLemma': 0, 'parents': ['tan']},
	{'value': 'तनु', 'label': 'tanu', 'attrib': [{'pos': 'adj', 'defs': 'thin'}, {'pos': 'f', 'defs': 'body, self'}], 'isLemma': 1},
	{'value': 'तन्यमान', 'label': 'tanyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'extend'}], 'isLemma': 0, 'parents': ['tan']},
	{'value': 'तन्वत्', 'label': 'tanvat', 'attrib': [{'pos': 'prap', 'defs': 'extend'}], 'isLemma': 0, 'parents': ['tan']},
	{'value': 'तन्वान', 'label': 'tanvAna', 'attrib': [{'pos': 'prmp', 'defs': 'extend'}], 'isLemma': 0, 'parents': ['tan']},
	{'value': 'तप्', 'label': 'tap', 'attrib': [{'pos': 'vi4m', 'defs': 'burn, feel pain'}, {'pos': 'vt1a', 'defs': 'heat, scorch'}], 'isLemma': 1},
	{'value': 'तप', 'label': 'tapa', 'attrib': [{'pos': 'adj', 'defs': 'scorching'}], 'isLemma': 1},
	{'value': 'तपत्', 'label': 'tapat', 'attrib': [{'pos': 'prap', 'defs': 'heat, scorch'}], 'isLemma': 0, 'parents': ['tap']},
	{'value': 'तपनीय', 'label': 'tapanIya', 'attrib': [{'pos': 'ger', 'defs': 'heat, scorch'}], 'isLemma': 0, 'parents': ['tap']},
	{'value': 'तप्त', 'label': 'tapta', 'attrib': [{'pos': 'ppp', 'defs': 'heat, scorch'}], 'isLemma': 0, 'parents': ['tap']},
	{'value': 'तप्तवत्', 'label': 'taptavat', 'attrib': [{'pos': 'pap', 'defs': 'heat, scorch'}], 'isLemma': 0, 'parents': ['tap']},
	{'value': 'तप्तव्य', 'label': 'taptavya', 'attrib': [{'pos': 'ger', 'defs': 'heat, scorch'}], 'isLemma': 0, 'parents': ['tap']},
	{'value': 'तप्तुम्', 'label': 'taptum', 'attrib': [{'pos': 'inf', 'defs': 'heat, scorch'}], 'isLemma': 0, 'parents': ['tap']},
	{'value': 'तप्त्वा', 'label': 'taptvA', 'attrib': [{'pos': 'abs', 'defs': 'heat, scorch'}], 'isLemma': 0, 'parents': ['tap']},
	{'value': 'तप्य', 'label': 'tapya', 'attrib': [{'pos': 'ger', 'defs': 'heat, scorch'}], 'isLemma': 0, 'parents': ['tap']},
	{'value': 'तप्यमान', 'label': 'tapyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'heat, scorch'}], 'isLemma': 0, 'parents': ['tap']},
	{'value': 'तप्स्यत्', 'label': 'tapsyat', 'attrib': [{'pos': 'fap', 'defs': 'heat, scorch'}], 'isLemma': 0, 'parents': ['tap']},
	{'value': 'तप्स्यमाण', 'label': 'tapsyamARa', 'attrib': [{'pos': 'fpp', 'defs': 'heat, scorch'}], 'isLemma': 0, 'parents': ['tap']},
	{'value': 'तर्पितव्य', 'label': 'tarpitavya', 'attrib': [{'pos': 'ger', 'defs': 'become satisfied, be pleased; caus. satiate, satisfy, please'}], 'isLemma': 0, 'parents': ['tfp']},
	{'value': 'तर्पितुम्', 'label': 'tarpitum', 'attrib': [{'pos': 'inf', 'defs': 'become satisfied, be pleased; caus. satiate, satisfy, please'}], 'isLemma': 0, 'parents': ['tfp']},
	{'value': 'तर्पित्वा', 'label': 'tarpitvA', 'attrib': [{'pos': 'abs', 'defs': 'become satisfied, be pleased; caus. satiate, satisfy, please'}], 'isLemma': 0, 'parents': ['tfp']},
	{'value': 'तर्पिष्यत्', 'label': 'tarpizyat', 'attrib': [{'pos': 'fap', 'defs': 'become satisfied, be pleased; caus. satiate, satisfy, please'}], 'isLemma': 0, 'parents': ['tfp']},
	{'value': 'तर्प्तव्य', 'label': 'tarptavya', 'attrib': [{'pos': 'ger', 'defs': 'become satisfied, be pleased; caus. satiate, satisfy, please'}], 'isLemma': 0, 'parents': ['tfp']},
	{'value': 'तर्प्तुम्', 'label': 'tarptum', 'attrib': [{'pos': 'inf', 'defs': 'become satisfied, be pleased; caus. satiate, satisfy, please'}], 'isLemma': 0, 'parents': ['tfp']},
	{'value': 'तर्प्स्यत्', 'label': 'tarpsyat', 'attrib': [{'pos': 'fap', 'defs': 'become satisfied, be pleased; caus. satiate, satisfy, please'}], 'isLemma': 0, 'parents': ['tfp']},
	{'value': 'तस्थान', 'label': 'tasTAna', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'stand, stay, be present'}], 'isLemma': 0, 'parents': ['sTA']},
	{'value': 'तस्थिवस्', 'label': 'tasTivas', 'attrib': [{'pos': 'pfap', 'defs': 'stand, stay, be present'}], 'isLemma': 0, 'parents': ['sTA']},
	{'value': 'तात', 'label': 'tAta', 'attrib': [{'pos': 'm', 'defs': 'dad, son'}], 'isLemma': 1},
	{'value': 'तान्य', 'label': 'tAnya', 'attrib': [{'pos': 'ger', 'defs': 'extend'}], 'isLemma': 0, 'parents': ['tan']},
	{'value': 'तिष्ठत्', 'label': 'tizWat', 'attrib': [{'pos': 'prap', 'defs': 'stand, stay, be present'}], 'isLemma': 0, 'parents': ['sTA']},
	{'value': 'तिष्ठमान', 'label': 'tizWamAna', 'attrib': [{'pos': 'prmp', 'defs': 'stand, stay, be present'}], 'isLemma': 0, 'parents': ['sTA']},
	{'value': 'तु', 'label': 'tu', 'attrib': [{'pos': 'conj_pcl', 'defs': 'but'}], 'isLemma': 1},
	{'value': 'तृप्', 'label': 'tfp', 'attrib': [{'pos': 'vi4a', 'defs': 'become satisfied, be pleased; caus. satiate, satisfy, please'}], 'isLemma': 1},
	{'value': 'तृप्त', 'label': 'tfpta', 'attrib': [{'pos': 'ppp', 'defs': 'become satisfied, be pleased; caus. satiate, satisfy, please'}], 'isLemma': 0, 'parents': ['tfp']},
	{'value': 'तृप्तवत्', 'label': 'tfptavat', 'attrib': [{'pos': 'pap', 'defs': 'become satisfied, be pleased; caus. satiate, satisfy, please'}], 'isLemma': 0, 'parents': ['tfp']},
	{'value': 'तृप्त्वा', 'label': 'tfptvA', 'attrib': [{'pos': 'abs', 'defs': 'become satisfied, be pleased; caus. satiate, satisfy, please'}], 'isLemma': 0, 'parents': ['tfp']},
	{'value': 'तृप्यत्', 'label': 'tfpyat', 'attrib': [{'pos': 'prap', 'defs': 'become satisfied, be pleased; caus. satiate, satisfy, please'}], 'isLemma': 0, 'parents': ['tfp']},
	{'value': 'तृप्यमाण', 'label': 'tfpyamARa', 'attrib': [{'pos': 'prpp', 'defs': 'become satisfied, be pleased; caus. satiate, satisfy, please'}], 'isLemma': 0, 'parents': ['tfp']},
	{'value': 'तेजस्', 'label': 'tejas', 'attrib': [{'pos': 'n', 'defs': 'brilliance'}], 'isLemma': 1},
	{'value': 'तेनान', 'label': 'tenAna', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'extend'}], 'isLemma': 0, 'parents': ['tan']},
	{'value': 'तेनिवस्', 'label': 'tenivas', 'attrib': [{'pos': 'pfap', 'defs': 'extend'}], 'isLemma': 0, 'parents': ['tan']},
	{'value': 'तेपान', 'label': 'tepAna', 'attrib': [{'pos': 'pfpp', 'defs': 'heat, scorch'}], 'isLemma': 0, 'parents': ['tap']},
	{'value': 'तेपिवस्', 'label': 'tepivas', 'attrib': [{'pos': 'pfap', 'defs': 'heat, scorch'}], 'isLemma': 0, 'parents': ['tap']},
	{'value': 'तोय', 'label': 'toya', 'attrib': [{'pos': 'n', 'defs': 'water'}], 'isLemma': 1},
	{'value': 'त्रप्तव्य', 'label': 'traptavya', 'attrib': [{'pos': 'ger', 'defs': 'become satisfied, be pleased; caus. satiate, satisfy, please'}], 'isLemma': 0, 'parents': ['tfp']},
	{'value': 'त्रप्तुम्', 'label': 'traptum', 'attrib': [{'pos': 'inf', 'defs': 'become satisfied, be pleased; caus. satiate, satisfy, please'}], 'isLemma': 0, 'parents': ['tfp']},
	{'value': 'त्रप्स्यत्', 'label': 'trapsyat', 'attrib': [{'pos': 'fap', 'defs': 'become satisfied, be pleased; caus. satiate, satisfy, please'}], 'isLemma': 0, 'parents': ['tfp']},
	{'value': 'त्रयस्', 'label': 'trayas', 'attrib': [{'pos': 'm', 'defs': 'three'}], 'isLemma': 1},
	{'value': 'त्रयोदश', 'label': 'trayodaSa', 'attrib': [{'pos': 'ord_num', 'defs': 'thirteenth'}], 'isLemma': 1},
	{'value': 'त्रि', 'label': 'tri', 'attrib': [{'pos': 'card_num', 'defs': 'three'}], 'isLemma': 1},
	{'value': 'त्वद्', 'label': 'tvad', 'attrib': [{'pos': 'pers_pron', 'defs': 'you'}], 'isLemma': 1},
	{'value': 'दंश्', 'label': 'daMS', 'attrib': [{'pos': 'vt1a', 'defs': 'bite'}], 'isLemma': 1},
	{'value': 'दंशनीय', 'label': 'daMSanIya', 'attrib': [{'pos': 'ger', 'defs': 'bite'}], 'isLemma': 0, 'parents': ['daMS']},
	{'value': 'दंश्य', 'label': 'daMSya', 'attrib': [{'pos': 'ger', 'defs': 'bite'}], 'isLemma': 0, 'parents': ['daMS']},
	{'value': 'दंष्टव्य', 'label': 'daMzwavya', 'attrib': [{'pos': 'ger', 'defs': 'bite'}], 'isLemma': 0, 'parents': ['daMS']},
	{'value': 'दंष्टुम्', 'label': 'daMzwum', 'attrib': [{'pos': 'inf', 'defs': 'bite'}], 'isLemma': 0, 'parents': ['daMS']},
	{'value': 'दक्षिण', 'label': 'dakziRa', 'attrib': [{'pos': 'adj', 'defs': 'skilful'}, {'pos': 'pron_adj, pron', 'defs': 'right, southern'}], 'isLemma': 1},
	{'value': 'दक्षिणा', 'label': 'dakziRA', 'attrib': [{'pos': 'f', 'defs': "the south, officiant's fee"}], 'isLemma': 1},
	{'value': 'दग्ध', 'label': 'dagDa', 'attrib': [{'pos': 'ppp', 'defs': 'burn'}], 'isLemma': 0, 'parents': ['dah']},
	{'value': 'दग्धवत्', 'label': 'dagDavat', 'attrib': [{'pos': 'pap', 'defs': 'burn'}], 'isLemma': 0, 'parents': ['dah']},
	{'value': 'दग्धव्य', 'label': 'dagDavya', 'attrib': [{'pos': 'ger', 'defs': 'burn'}], 'isLemma': 0, 'parents': ['dah']},
	{'value': 'दग्धुम्', 'label': 'dagDum', 'attrib': [{'pos': 'inf', 'defs': 'burn'}], 'isLemma': 0, 'parents': ['dah']},
	{'value': 'दग्ध्वा', 'label': 'dagDvA', 'attrib': [{'pos': 'abs', 'defs': 'burn'}], 'isLemma': 0, 'parents': ['dah']},
	{'value': 'दङ्क्ष्यत्', 'label': 'daNkzyat', 'attrib': [{'pos': 'fap', 'defs': 'bite'}], 'isLemma': 0, 'parents': ['daMS']},
	{'value': 'दत्त', 'label': 'datta', 'attrib': [{'pos': 'ppp', 'defs': 'give'}], 'isLemma': 0, 'parents': ['dA']},
	{'value': 'दत्तवत्', 'label': 'dattavat', 'attrib': [{'pos': 'pap', 'defs': 'give'}], 'isLemma': 0, 'parents': ['dA']},
	{'value': 'दत्त्वा', 'label': 'dattvA', 'attrib': [{'pos': 'abs', 'defs': 'give'}], 'isLemma': 0, 'parents': ['dA']},
	{'value': 'ददंशिवस्', 'label': 'dadaMSivas', 'attrib': [{'pos': 'pfap', 'defs': 'bite'}], 'isLemma': 0, 'parents': ['daMS']},
	{'value': 'ददत्', 'label': 'dadat', 'attrib': [{'pos': 'prap', 'defs': 'give'}], 'isLemma': 0, 'parents': ['dA']},
	{'value': 'ददहान', 'label': 'dadahAna', 'attrib': [{'pos': 'pfpp', 'defs': 'burn'}], 'isLemma': 0, 'parents': ['dah']},
	{'value': 'ददह्वस्', 'label': 'dadahvas', 'attrib': [{'pos': 'pfap', 'defs': 'burn'}], 'isLemma': 0, 'parents': ['dah']},
	{'value': 'ददान', 'label': 'dadAna', 'attrib': [{'pos': 'prmp', 'defs': 'give'}, {'pos': 'pfmp, pfpp', 'defs': 'give'}], 'isLemma': 0, 'parents': ['dA']},
	{'value': 'ददिवस्', 'label': 'dadivas', 'attrib': [{'pos': 'pfap', 'defs': 'give'}], 'isLemma': 0, 'parents': ['dA']},
	{'value': 'ददृशान', 'label': 'dadfSAna', 'attrib': [{'pos': 'pfpp', 'defs': 'see, watch'}], 'isLemma': 0, 'parents': ['dfS']},
	{'value': 'ददृशिवस्', 'label': 'dadfSivas', 'attrib': [{'pos': 'pfap', 'defs': 'see, watch'}], 'isLemma': 0, 'parents': ['dfS']},
	{'value': 'ददृश्वस्', 'label': 'dadfSvas', 'attrib': [{'pos': 'pfap', 'defs': 'see, watch'}], 'isLemma': 0, 'parents': ['dfS']},
	{'value': 'दधत्', 'label': 'daDat', 'attrib': [{'pos': 'prap', 'defs': 'put'}], 'isLemma': 0, 'parents': ['DA']},
	{'value': 'दधान', 'label': 'daDAna', 'attrib': [{'pos': 'prmp', 'defs': 'put'}, {'pos': 'pfmp, pfpp', 'defs': 'put'}], 'isLemma': 0, 'parents': ['DA']},
	{'value': 'दधिवस्', 'label': 'daDivas', 'attrib': [{'pos': 'pfap', 'defs': 'put'}], 'isLemma': 0, 'parents': ['DA']},
	{'value': 'दधृवस्', 'label': 'daDfvas', 'attrib': [{'pos': 'pfap', 'defs': 'hold'}], 'isLemma': 0, 'parents': ['Df']},
	{'value': 'दध्राण', 'label': 'daDrARa', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'hold'}], 'isLemma': 0, 'parents': ['Df']},
	{'value': 'दम्', 'label': 'dam', 'attrib': [{'pos': 'vt4a', 'defs': 'tame'}], 'isLemma': 1},
	{'value': 'दम', 'label': 'dama', 'attrib': [{'pos': 'adj', 'defs': 'taming'}], 'isLemma': 1},
	{'value': 'दर्शनीय', 'label': 'darSanIya', 'attrib': [{'pos': 'ger', 'defs': 'see, watch'}], 'isLemma': 0, 'parents': ['dfS']},
	{'value': 'दशत्', 'label': 'daSat', 'attrib': [{'pos': 'prap', 'defs': 'bite'}], 'isLemma': 0, 'parents': ['daMS']},
	{'value': 'दशन्', 'label': 'daSan', 'attrib': [{'pos': 'card_num', 'defs': 'ten'}], 'isLemma': 1},
	{'value': 'दश्यमान', 'label': 'daSyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'bite'}], 'isLemma': 0, 'parents': ['daMS']},
	{'value': 'दष्ट', 'label': 'dazwa', 'attrib': [{'pos': 'ppp', 'defs': 'bite'}], 'isLemma': 0, 'parents': ['daMS']},
	{'value': 'दष्टवत्', 'label': 'dazwavat', 'attrib': [{'pos': 'pap', 'defs': 'bite'}], 'isLemma': 0, 'parents': ['daMS']},
	{'value': 'दष्ट्वा', 'label': 'dazwvA', 'attrib': [{'pos': 'abs', 'defs': 'bite'}], 'isLemma': 0, 'parents': ['daMS']},
	{'value': 'दह्', 'label': 'dah', 'attrib': [{'pos': 'vt1a', 'defs': 'burn'}], 'isLemma': 1},
	{'value': 'दहत्', 'label': 'dahat', 'attrib': [{'pos': 'prap', 'defs': 'burn'}], 'isLemma': 0, 'parents': ['dah']},
	{'value': 'दहनीय', 'label': 'dahanIya', 'attrib': [{'pos': 'ger', 'defs': 'burn'}], 'isLemma': 0, 'parents': ['dah']},
	{'value': 'दह्यमान', 'label': 'dahyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'burn'}], 'isLemma': 0, 'parents': ['dah']},
	{'value': 'दा', 'label': 'dA', 'attrib': [{'pos': 'vt3am', 'defs': 'give'}], 'isLemma': 1},
	{'value': 'दातव्य', 'label': 'dAtavya', 'attrib': [{'pos': 'ger', 'defs': 'give'}], 'isLemma': 0, 'parents': ['dA']},
	{'value': 'दातुम्', 'label': 'dAtum', 'attrib': [{'pos': 'inf', 'defs': 'give'}], 'isLemma': 0, 'parents': ['dA']},
	{'value': 'दानीय', 'label': 'dAnIya', 'attrib': [{'pos': 'ger', 'defs': 'give'}], 'isLemma': 0, 'parents': ['dA']},
	{'value': 'दास्यत्', 'label': 'dAsyat', 'attrib': [{'pos': 'fap', 'defs': 'give'}], 'isLemma': 0, 'parents': ['dA']},
	{'value': 'दास्यमान', 'label': 'dAsyamAna', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'give'}], 'isLemma': 0, 'parents': ['dA']},
	{'value': 'दाह्य', 'label': 'dAhya', 'attrib': [{'pos': 'ger', 'defs': 'burn'}], 'isLemma': 0, 'parents': ['dah']},
	{'value': 'दिदिशान', 'label': 'didiSAna', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'point'}], 'isLemma': 0, 'parents': ['diS']},
	{'value': 'दिदिशिवस्', 'label': 'didiSivas', 'attrib': [{'pos': 'pfap', 'defs': 'point'}], 'isLemma': 0, 'parents': ['diS']},
	{'value': 'दिव्', 'label': 'div', 'attrib': [{'pos': 'm, f', 'defs': 'sky, the vault of heaven, day'}], 'isLemma': 1},
	{'value': 'दिव्य', 'label': 'divya', 'attrib': [{'pos': 'adj', 'defs': 'divine'}], 'isLemma': 1},
	{'value': 'दिश्', 'label': 'diS', 'attrib': [{'pos': 'f', 'defs': 'direction'}, {'pos': 'vt6am', 'defs': 'point'}], 'isLemma': 1},
	{'value': 'दिशत्', 'label': 'diSat', 'attrib': [{'pos': 'prap', 'defs': 'point'}], 'isLemma': 0, 'parents': ['diS']},
	{'value': 'दिशमान', 'label': 'diSamAna', 'attrib': [{'pos': 'prmp', 'defs': 'point'}], 'isLemma': 0, 'parents': ['diS']},
	{'value': 'दिश्यमान', 'label': 'diSyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'point'}], 'isLemma': 0, 'parents': ['diS']},
	{'value': 'दिष्ट', 'label': 'dizwa', 'attrib': [{'pos': 'ppp', 'defs': 'point'}], 'isLemma': 0, 'parents': ['diS']},
	{'value': 'दिष्टवत्', 'label': 'dizwavat', 'attrib': [{'pos': 'pap', 'defs': 'point'}], 'isLemma': 0, 'parents': ['diS']},
	{'value': 'दिष्ट्वा', 'label': 'dizwvA', 'attrib': [{'pos': 'abs', 'defs': 'point'}], 'isLemma': 0, 'parents': ['diS']},
	{'value': 'दीयमान', 'label': 'dIyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'give'}], 'isLemma': 0, 'parents': ['dA']},
	{'value': 'दुःशासन', 'label': 'duHSAsana', 'attrib': [{'pos': 'm_pn', 'defs': 'Duḥśāsana'}], 'isLemma': 1},
	{'value': 'दुद्रुवस्', 'label': 'dudruvas', 'attrib': [{'pos': 'pfap', 'defs': 'run'}], 'isLemma': 0, 'parents': ['dru']},
	{'value': 'दुद्रुवाण', 'label': 'dudruvARa', 'attrib': [{'pos': 'pfpp', 'defs': 'run'}], 'isLemma': 0, 'parents': ['dru']},
	{'value': 'दुर्मति', 'label': 'durmati', 'attrib': [{'pos': 'adj', 'defs': 'ill-minded, weak-minded, evil-minded'}], 'isLemma': 1},
	{'value': 'दुर्योधन', 'label': 'duryoDana', 'attrib': [{'pos': 'm_pn', 'defs': 'Duryodhana'}], 'isLemma': 1},
	{'value': 'दुस्', 'label': 'dus', 'attrib': [{'pos': 'preverb', 'defs': 'ill'}], 'isLemma': 1},
	{'value': 'दृश्', 'label': 'dfS', 'attrib': [{'pos': 'vt1a', 'defs': 'see, watch'}], 'isLemma': 1},
	{'value': 'दृश्य', 'label': 'dfSya', 'attrib': [{'pos': 'ger', 'defs': 'see, watch'}], 'isLemma': 0, 'parents': ['dfS']},
	{'value': 'दृश्यमान', 'label': 'dfSyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'see, watch'}], 'isLemma': 0, 'parents': ['dfS']},
	{'value': 'दृष्ट', 'label': 'dfzwa', 'attrib': [{'pos': 'ppp', 'defs': 'see, watch'}], 'isLemma': 0, 'parents': ['dfS']},
	{'value': 'दृष्टवत्', 'label': 'dfzwavat', 'attrib': [{'pos': 'pap', 'defs': 'see, watch'}], 'isLemma': 0, 'parents': ['dfS']},
	{'value': 'दृष्ट्वा', 'label': 'dfzwvA', 'attrib': [{'pos': 'abs', 'defs': 'see, watch'}], 'isLemma': 0, 'parents': ['dfS']},
	{'value': 'देक्ष्यत्', 'label': 'dekzyat', 'attrib': [{'pos': 'fap', 'defs': 'point'}], 'isLemma': 0, 'parents': ['diS']},
	{'value': 'देक्ष्यमाण', 'label': 'dekzyamARa', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'point'}], 'isLemma': 0, 'parents': ['diS']},
	{'value': 'देय', 'label': 'deya', 'attrib': [{'pos': 'ger', 'defs': 'give'}], 'isLemma': 0, 'parents': ['dA']},
	{'value': 'देव', 'label': 'deva', 'attrib': [{'pos': 'm', 'defs': 'luminous being, god'}], 'isLemma': 1},
	{'value': 'देश', 'label': 'deSa', 'attrib': [{'pos': 'm', 'defs': 'place'}], 'isLemma': 1},
	{'value': 'देशनीय', 'label': 'deSanIya', 'attrib': [{'pos': 'ger', 'defs': 'point'}], 'isLemma': 0, 'parents': ['diS']},
	{'value': 'देश्य', 'label': 'deSya', 'attrib': [{'pos': 'ger', 'defs': 'point'}], 'isLemma': 0, 'parents': ['diS']},
	{'value': 'देष्टव्य', 'label': 'dezwavya', 'attrib': [{'pos': 'ger', 'defs': 'point'}], 'isLemma': 0, 'parents': ['diS']},
	{'value': 'देष्टुम्', 'label': 'dezwum', 'attrib': [{'pos': 'inf', 'defs': 'point'}], 'isLemma': 0, 'parents': ['diS']},
	{'value': 'दैव', 'label': 'dEva', 'attrib': [{'pos': 'n', 'defs': 'caused by the gods (deva), fate'}], 'isLemma': 1},
	{'value': 'दैवभाव्यर्थरक्षित', 'label': 'dEvaBAvyarTarakzita', 'attrib': [{'pos': 'adj', 'defs': 'protected for future purposes by divine cause'}], 'isLemma': 1},
	{'value': 'द्यूत', 'label': 'dyUta', 'attrib': [{'pos': 'n', 'defs': 'playing with dice, gambling'}], 'isLemma': 1},
	{'value': 'द्यूतसंभूत', 'label': 'dyUtasaMBUta', 'attrib': [{'pos': 'adj', 'defs': 'arisen from the gambling match'}], 'isLemma': 1},
	{'value': 'द्रक्ष्यत्', 'label': 'drakzyat', 'attrib': [{'pos': 'fap', 'defs': 'see, watch'}], 'isLemma': 0, 'parents': ['dfS']},
	{'value': 'द्रक्ष्यमाण', 'label': 'drakzyamARa', 'attrib': [{'pos': 'fpp', 'defs': 'see, watch'}], 'isLemma': 0, 'parents': ['dfS']},
	{'value': 'द्रवणीय', 'label': 'dravaRIya', 'attrib': [{'pos': 'ger', 'defs': 'run'}], 'isLemma': 0, 'parents': ['dru']},
	{'value': 'द्रवत्', 'label': 'dravat', 'attrib': [{'pos': 'prap', 'defs': 'run'}], 'isLemma': 0, 'parents': ['dru']},
	{'value': 'द्रव्य', 'label': 'dravya', 'attrib': [{'pos': 'ger', 'defs': 'run'}], 'isLemma': 0, 'parents': ['dru']},
	{'value': 'द्रष्टव्य', 'label': 'drazwavya', 'attrib': [{'pos': 'ger', 'defs': 'see, watch'}], 'isLemma': 0, 'parents': ['dfS']},
	{'value': 'द्रष्टुम्', 'label': 'drazwum', 'attrib': [{'pos': 'inf', 'defs': 'see, watch'}], 'isLemma': 0, 'parents': ['dfS']},
	{'value': 'द्रु', 'label': 'dru', 'attrib': [{'pos': 'm, n', 'defs': 'wood, wooden implement'}, {'pos': 'vt1a', 'defs': 'run'}], 'isLemma': 1},
	{'value': 'द्रुत', 'label': 'druta', 'attrib': [{'pos': 'ppp', 'defs': 'run'}, {'pos': 'adj', 'defs': 'swift, fluid'}], 'isLemma': 1},
	{'value': 'द्रुत', 'label': 'druta', 'attrib': [{'pos': 'ppp', 'defs': 'run'}, {'pos': 'adj', 'defs': 'swift, fluid'}], 'isLemma': 0, 'parents': ['dru']},
	{'value': 'द्रुतवत्', 'label': 'drutavat', 'attrib': [{'pos': 'pap', 'defs': 'run'}], 'isLemma': 0, 'parents': ['dru']},
	{'value': 'द्रुत्वा', 'label': 'drutvA', 'attrib': [{'pos': 'abs', 'defs': 'run'}], 'isLemma': 0, 'parents': ['dru']},
	{'value': 'द्रुपद', 'label': 'drupada', 'attrib': [{'pos': 'm_pn', 'defs': 'Drupada'}, {'pos': 'n', 'defs': 'wooden pillar'}], 'isLemma': 1},
	{'value': 'द्रूयमाण', 'label': 'drUyamARa', 'attrib': [{'pos': 'prpp', 'defs': 'run'}], 'isLemma': 0, 'parents': ['dru']},
	{'value': 'द्रोतव्य', 'label': 'drotavya', 'attrib': [{'pos': 'ger', 'defs': 'run'}], 'isLemma': 0, 'parents': ['dru']},
	{'value': 'द्रोतुम्', 'label': 'drotum', 'attrib': [{'pos': 'inf', 'defs': 'run'}], 'isLemma': 0, 'parents': ['dru']},
	{'value': 'द्रोष्यत्', 'label': 'drozyat', 'attrib': [{'pos': 'fap', 'defs': 'run'}], 'isLemma': 0, 'parents': ['dru']},
	{'value': 'द्रोष्यमाण', 'label': 'drozyamARa', 'attrib': [{'pos': 'fpp', 'defs': 'run'}], 'isLemma': 0, 'parents': ['dru']},
	{'value': 'द्रौपदी', 'label': 'drOpadI', 'attrib': [{'pos': 'f', 'defs': 'daughter of Drupada'}], 'isLemma': 1},
	{'value': 'द्वार', 'label': 'dvAra', 'attrib': [{'pos': 'n', 'defs': 'door, gate'}], 'isLemma': 1},
	{'value': 'द्वारवत्', 'label': 'dvAravat', 'attrib': [{'pos': 'adj', 'defs': 'having doors, many-gated'}], 'isLemma': 1},
	{'value': 'द्वारवती', 'label': 'dvAravatI', 'attrib': [{'pos': 'f', 'defs': 'Dvāraka'}], 'isLemma': 1},
	{'value': 'द्वि', 'label': 'dvi', 'attrib': [{'pos': 'card_num', 'defs': 'two'}], 'isLemma': 1},
	{'value': 'द्विज', 'label': 'dvija', 'attrib': [{'pos': 'adj', 'defs': 'twice-born'}], 'isLemma': 1},
	{'value': 'धक्ष्यत्', 'label': 'Dakzyat', 'attrib': [{'pos': 'fap', 'defs': 'burn'}], 'isLemma': 0, 'parents': ['dah']},
	{'value': 'धक्ष्यमाण', 'label': 'DakzyamARa', 'attrib': [{'pos': 'fpp', 'defs': 'burn'}], 'isLemma': 0, 'parents': ['dah']},
	{'value': 'धन', 'label': 'Dana', 'attrib': [{'pos': 'n', 'defs': 'wealth'}], 'isLemma': 1},
	{'value': 'धनंजय', 'label': 'DanaMjaya', 'attrib': [{'pos': 'm', 'defs': 'winner of wealth'}], 'isLemma': 1},
	{'value': 'धनुस्', 'label': 'Danus', 'attrib': [{'pos': 'n', 'defs': 'bow'}], 'isLemma': 1},
	{'value': 'धर', 'label': 'Dara', 'attrib': [{'pos': 'adj', 'defs': 'holding'}], 'isLemma': 1},
	{'value': 'धरणीय', 'label': 'DaraRIya', 'attrib': [{'pos': 'ger', 'defs': 'hold'}], 'isLemma': 0, 'parents': ['Df']},
	{'value': 'धरत्', 'label': 'Darat', 'attrib': [{'pos': 'prap', 'defs': 'hold'}], 'isLemma': 0, 'parents': ['Df']},
	{'value': 'धरमाण', 'label': 'DaramARa', 'attrib': [{'pos': 'prmp', 'defs': 'hold'}], 'isLemma': 0, 'parents': ['Df']},
	{'value': 'धरिष्यत्', 'label': 'Darizyat', 'attrib': [{'pos': 'fap', 'defs': 'hold'}], 'isLemma': 0, 'parents': ['Df']},
	{'value': 'धरिष्यमाण', 'label': 'DarizyamARa', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'hold'}], 'isLemma': 0, 'parents': ['Df']},
	{'value': 'धर्तव्य', 'label': 'Dartavya', 'attrib': [{'pos': 'ger', 'defs': 'hold'}], 'isLemma': 0, 'parents': ['Df']},
	{'value': 'धर्तुम्', 'label': 'Dartum', 'attrib': [{'pos': 'inf', 'defs': 'hold'}], 'isLemma': 0, 'parents': ['Df']},
	{'value': 'धर्म', 'label': 'Darma', 'attrib': [{'pos': 'm', 'defs': 'that which upholds, cosmic order, truth, proper function, right action, duty'}], 'isLemma': 1},
	{'value': 'धर्मप्रधान', 'label': 'DarmapraDAna', 'attrib': [{'pos': 'adj', 'defs': 'devoted to dharma'}], 'isLemma': 1},
	{'value': 'धर्मराज', 'label': 'DarmarAja', 'attrib': [{'pos': 'm', 'defs': 'king dharma'}], 'isLemma': 1},
	{'value': 'धा', 'label': 'DA', 'attrib': [{'pos': 'vt3am', 'defs': 'put'}], 'isLemma': 1},
	{'value': 'धातव्य', 'label': 'DAtavya', 'attrib': [{'pos': 'ger', 'defs': 'put'}], 'isLemma': 0, 'parents': ['DA']},
	{'value': 'धातुम्', 'label': 'DAtum', 'attrib': [{'pos': 'inf', 'defs': 'put'}], 'isLemma': 0, 'parents': ['DA']},
	{'value': 'धानीय', 'label': 'DAnIya', 'attrib': [{'pos': 'ger', 'defs': 'put'}], 'isLemma': 0, 'parents': ['DA']},
	{'value': 'धास्यत्', 'label': 'DAsyat', 'attrib': [{'pos': 'fap', 'defs': 'put'}], 'isLemma': 0, 'parents': ['DA']},
	{'value': 'धास्यमान', 'label': 'DAsyamAna', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'put'}], 'isLemma': 0, 'parents': ['DA']},
	{'value': 'धी', 'label': 'DI', 'attrib': [{'pos': 'f', 'defs': 'intelligence'}], 'isLemma': 1},
	{'value': 'धीमत्', 'label': 'DImat', 'attrib': [{'pos': 'adj', 'defs': 'intelligent, wise'}], 'isLemma': 1},
	{'value': 'धीयमान', 'label': 'DIyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'put'}], 'isLemma': 0, 'parents': ['DA']},
	{'value': 'धृ', 'label': 'Df', 'attrib': [{'pos': 'vt1am', 'defs': 'hold'}], 'isLemma': 1},
	{'value': 'धृत', 'label': 'Dfta', 'attrib': [{'pos': 'ppp', 'defs': 'hold'}], 'isLemma': 0, 'parents': ['Df']},
	{'value': 'धृतराष्ट्र', 'label': 'DftarAzwra', 'attrib': [{'pos': 'm_pn', 'defs': 'Dhṛtarāṣṭra'}], 'isLemma': 1},
	{'value': 'धृतराष्ट्रज', 'label': 'DftarAzwraja', 'attrib': [{'pos': 'adj', 'defs': 'born of Dhṛtarāṣṭra'}], 'isLemma': 1},
	{'value': 'धृतराष्ट्रभीष्म', 'label': 'DftarAzwraBIzma', 'attrib': [{'pos': 'm', 'defs': 'Dhṛtarāṣṭra and Bhīṣma'}], 'isLemma': 1},
	{'value': 'धृतवत्', 'label': 'Dftavat', 'attrib': [{'pos': 'pap', 'defs': 'hold'}], 'isLemma': 0, 'parents': ['Df']},
	{'value': 'धृत्य', 'label': 'Dftya', 'attrib': [{'pos': 'ger', 'defs': 'hold'}], 'isLemma': 0, 'parents': ['Df']},
	{'value': 'धृत्वा', 'label': 'DftvA', 'attrib': [{'pos': 'abs', 'defs': 'hold'}], 'isLemma': 0, 'parents': ['Df']},
	{'value': 'धेय', 'label': 'Deya', 'attrib': [{'pos': 'ger', 'defs': 'put'}], 'isLemma': 0, 'parents': ['DA']},
	{'value': 'ध्रियमाण', 'label': 'DriyamARa', 'attrib': [{'pos': 'prpp', 'defs': 'hold'}], 'isLemma': 0, 'parents': ['Df']},
	{'value': 'न', 'label': 'na', 'attrib': [{'pos': 'neg_pcl', 'defs': 'not'}], 'isLemma': 1},
	{'value': 'नंस्यत्', 'label': 'naMsyat', 'attrib': [{'pos': 'fap', 'defs': 'bow'}], 'isLemma': 0, 'parents': ['nam']},
	{'value': 'नंस्यमान', 'label': 'naMsyamAna', 'attrib': [{'pos': 'fpp', 'defs': 'bow'}], 'isLemma': 0, 'parents': ['nam']},
	{'value': 'नकुल', 'label': 'nakula', 'attrib': [{'pos': 'm_pn', 'defs': 'Nakula'}], 'isLemma': 1},
	{'value': 'नगर', 'label': 'nagara', 'attrib': [{'pos': 'n', 'defs': 'city'}], 'isLemma': 1},
	{'value': 'नचिर', 'label': 'nacira', 'attrib': [{'pos': 'adj', 'defs': 'not long'}], 'isLemma': 1},
	{'value': 'नत', 'label': 'nata', 'attrib': [{'pos': 'ppp', 'defs': 'bow'}], 'isLemma': 0, 'parents': ['nam']},
	{'value': 'नतवत्', 'label': 'natavat', 'attrib': [{'pos': 'pap', 'defs': 'bow'}], 'isLemma': 0, 'parents': ['nam']},
	{'value': 'नत्वा', 'label': 'natvA', 'attrib': [{'pos': 'abs', 'defs': 'bow'}], 'isLemma': 0, 'parents': ['nam']},
	{'value': 'नदी', 'label': 'nadI', 'attrib': [{'pos': 'f', 'defs': 'river'}], 'isLemma': 1},
	{'value': 'नन्तव्य', 'label': 'nantavya', 'attrib': [{'pos': 'ger', 'defs': 'bow'}], 'isLemma': 0, 'parents': ['nam']},
	{'value': 'नन्तुम्', 'label': 'nantum', 'attrib': [{'pos': 'inf', 'defs': 'bow'}], 'isLemma': 0, 'parents': ['nam']},
	{'value': 'नम्', 'label': 'nam', 'attrib': [{'pos': 'vt1a', 'defs': 'bow'}], 'isLemma': 1},
	{'value': 'नमत्', 'label': 'namat', 'attrib': [{'pos': 'prap', 'defs': 'bow'}], 'isLemma': 0, 'parents': ['nam']},
	{'value': 'नमनीय', 'label': 'namanIya', 'attrib': [{'pos': 'ger', 'defs': 'bow'}], 'isLemma': 0, 'parents': ['nam']},
	{'value': 'नमश्चकृवस्', 'label': 'namaScakfvas', 'attrib': [{'pos': 'pfap', 'defs': 'do obeisance, make salutation, bow to'}], 'isLemma': 0, 'parents': ['namas_kf']},
	{'value': 'नमश्चक्राण', 'label': 'namaScakrARa', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'do obeisance, make salutation, bow to'}], 'isLemma': 0, 'parents': ['namas_kf']},
	{'value': 'नमस्', 'label': 'namas', 'attrib': [{'pos': 'n', 'defs': 'obeisance, salutation'}], 'isLemma': 1},
	{'value': 'नमस्करणीय', 'label': 'namaskaraRIya', 'attrib': [{'pos': 'ger', 'defs': 'do obeisance, make salutation, bow to'}], 'isLemma': 0, 'parents': ['namas_kf']},
	{'value': 'नमस्करिष्यत्', 'label': 'namaskarizyat', 'attrib': [{'pos': 'fap', 'defs': 'do obeisance, make salutation, bow to'}], 'isLemma': 0, 'parents': ['namas_kf']},
	{'value': 'नमस्करिष्यमाण', 'label': 'namaskarizyamARa', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'do obeisance, make salutation, bow to'}], 'isLemma': 0, 'parents': ['namas_kf']},
	{'value': 'नमस्कर्तव्य', 'label': 'namaskartavya', 'attrib': [{'pos': 'ger', 'defs': 'do obeisance, make salutation, bow to'}], 'isLemma': 0, 'parents': ['namas_kf']},
	{'value': 'नमस्कर्तुम्', 'label': 'namaskartum', 'attrib': [{'pos': 'inf', 'defs': 'do obeisance, make salutation, bow to'}], 'isLemma': 0, 'parents': ['namas_kf']},
	{'value': 'नमस्कार्य', 'label': 'namaskArya', 'attrib': [{'pos': 'ger', 'defs': 'do obeisance, make salutation, bow to'}], 'isLemma': 0, 'parents': ['namas_kf']},
	{'value': 'नमस्कुर्वत्', 'label': 'namaskurvat', 'attrib': [{'pos': 'prap', 'defs': 'do obeisance, make salutation, bow to'}], 'isLemma': 0, 'parents': ['namas_kf']},
	{'value': 'नमस्कुर्वाण', 'label': 'namaskurvARa', 'attrib': [{'pos': 'prmp', 'defs': 'do obeisance, make salutation, bow to'}], 'isLemma': 0, 'parents': ['namas_kf']},
	{'value': 'नमस्कृत', 'label': 'namaskfta', 'attrib': [{'pos': 'ppp', 'defs': 'do obeisance, make salutation, bow to'}], 'isLemma': 0, 'parents': ['namas_kf']},
	{'value': 'नमस्कृतवत्', 'label': 'namaskftavat', 'attrib': [{'pos': 'pap', 'defs': 'do obeisance, make salutation, bow to'}], 'isLemma': 0, 'parents': ['namas_kf']},
	{'value': 'नमस्कृत्य', 'label': 'namaskftya', 'attrib': [{'pos': 'abs', 'defs': 'do obeisance, make salutation, bow to'}, {'pos': 'ger', 'defs': 'do obeisance, make salutation, bow to'}], 'isLemma': 0, 'parents': ['namas_kf']},
	{'value': 'नमस्क्रियमाण', 'label': 'namaskriyamARa', 'attrib': [{'pos': 'prpp', 'defs': 'do obeisance, make salutation, bow to'}], 'isLemma': 0, 'parents': ['namas_kf']},
	{'value': 'नमस्_कृ', 'label': 'namas_kf', 'attrib': [{'pos': 'vt8am', 'defs': 'do obeisance, make salutation, bow to'}], 'isLemma': 1},
	{'value': 'नम्य', 'label': 'namya', 'attrib': [{'pos': 'ger', 'defs': 'bow'}], 'isLemma': 0, 'parents': ['nam']},
	{'value': 'नम्यमान', 'label': 'namyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'bow'}], 'isLemma': 0, 'parents': ['nam']},
	{'value': 'नश्', 'label': 'naS', 'attrib': [{'pos': 'vi4a', 'defs': 'perish'}], 'isLemma': 1},
	{'value': 'नशनीय', 'label': 'naSanIya', 'attrib': [{'pos': 'ger', 'defs': 'perish'}], 'isLemma': 0, 'parents': ['naS']},
	{'value': 'नश्यत्', 'label': 'naSyat', 'attrib': [{'pos': 'prap', 'defs': 'perish'}], 'isLemma': 0, 'parents': ['naS']},
	{'value': 'नष्ट', 'label': 'nazwa', 'attrib': [{'pos': 'ppp', 'defs': 'perish'}], 'isLemma': 0, 'parents': ['naS']},
	{'value': 'नष्टवत्', 'label': 'nazwavat', 'attrib': [{'pos': 'ppp', 'defs': 'perish'}], 'isLemma': 0, 'parents': ['naS']},
	{'value': 'नष्टव्य', 'label': 'nazwavya', 'attrib': [{'pos': 'ger', 'defs': 'perish'}], 'isLemma': 0, 'parents': ['naS']},
	{'value': 'नष्टुम्', 'label': 'nazwum', 'attrib': [{'pos': 'inf', 'defs': 'perish'}], 'isLemma': 0, 'parents': ['naS']},
	{'value': 'नष्ट्वा', 'label': 'nazwvA', 'attrib': [{'pos': 'abs', 'defs': 'perish'}], 'isLemma': 0, 'parents': ['naS']},
	{'value': 'नामन्', 'label': 'nAman', 'attrib': [{'pos': 'n', 'defs': 'name'}], 'isLemma': 1},
	{'value': 'नाश्य', 'label': 'nASya', 'attrib': [{'pos': 'ger', 'defs': 'perish'}], 'isLemma': 0, 'parents': ['naS']},
	{'value': 'नि', 'label': 'ni', 'attrib': [{'pos': 'preverb', 'defs': 'in'}], 'isLemma': 1},
	{'value': 'निग्रह', 'label': 'nigraha', 'attrib': [{'pos': 'm', 'defs': 'restraint, suppression, defeat'}], 'isLemma': 1},
	{'value': 'निग्रहनिर्वास', 'label': 'nigrahanirvAsa', 'attrib': [{'pos': 'm', 'defs': 'defeat and exile'}], 'isLemma': 1},
	{'value': 'निघात्य', 'label': 'niGAtya', 'attrib': [{'pos': 'ger', 'defs': 'smite'}], 'isLemma': 0, 'parents': ['ni_han']},
	{'value': 'निघ्नत्', 'label': 'niGnat', 'attrib': [{'pos': 'prap', 'defs': 'smite'}], 'isLemma': 0, 'parents': ['ni_han']},
	{'value': 'निजघन्वस्', 'label': 'nijaGanvas', 'attrib': [{'pos': 'pfap', 'defs': 'smite'}], 'isLemma': 0, 'parents': ['ni_han']},
	{'value': 'निजघ्नान', 'label': 'nijaGnAna', 'attrib': [{'pos': 'pfpp', 'defs': 'smite'}], 'isLemma': 0, 'parents': ['ni_han']},
	{'value': 'निजघ्निवस्', 'label': 'nijaGnivas', 'attrib': [{'pos': 'pfap', 'defs': 'smite'}], 'isLemma': 0, 'parents': ['ni_han']},
	{'value': 'नि_ग्रह्', 'label': 'ni_grah', 'attrib': [{'pos': 'vt9a', 'defs': 'hold down, suppress'}], 'isLemma': 1},
	{'value': 'नि_वस्', 'label': 'ni_vas', 'attrib': [{'pos': 'vi1a', 'defs': 'dwell, live'}], 'isLemma': 1},
	{'value': 'नि_हन्', 'label': 'ni_han', 'attrib': [{'pos': 'vt2a', 'defs': 'smite'}], 'isLemma': 1},
	{'value': 'नित्य', 'label': 'nitya', 'attrib': [{'pos': 'adj', 'defs': 'innate, eternal, constant'}], 'isLemma': 1},
	{'value': 'नित्यम्', 'label': 'nityam', 'attrib': [{'pos': 'adv', 'defs': 'always'}], 'isLemma': 1},
	{'value': 'निमित्त', 'label': 'nimitta', 'attrib': [{'pos': 'n', 'defs': 'condition, cause, reason'}], 'isLemma': 1},
	{'value': 'निर्_वस्', 'label': 'nir_vas', 'attrib': [{'pos': 'vi1a', 'defs': 'dwell abroad'}], 'isLemma': 1},
	{'value': 'निर्वास', 'label': 'nirvAsa', 'attrib': [{'pos': 'm', 'defs': 'exile, banishment'}], 'isLemma': 1},
	{'value': 'निस्', 'label': 'nis', 'attrib': [{'pos': 'preverb', 'defs': 'out'}], 'isLemma': 1},
	{'value': 'निहंस्यत्', 'label': 'nihaMsyat', 'attrib': [{'pos': 'fap', 'defs': 'smite'}], 'isLemma': 0, 'parents': ['ni_han']},
	{'value': 'निहंस्यमान', 'label': 'nihaMsyamAna', 'attrib': [{'pos': 'fpp', 'defs': 'smite'}], 'isLemma': 0, 'parents': ['ni_han']},
	{'value': 'निहत', 'label': 'nihata', 'attrib': [{'pos': 'ppp', 'defs': 'smite'}], 'isLemma': 0, 'parents': ['ni_han']},
	{'value': 'निहतवत्', 'label': 'nihatavat', 'attrib': [{'pos': 'pap', 'defs': 'smite'}], 'isLemma': 0, 'parents': ['ni_han']},
	{'value': 'निहननीय', 'label': 'nihananIya', 'attrib': [{'pos': 'ger', 'defs': 'smite'}], 'isLemma': 0, 'parents': ['ni_han']},
	{'value': 'निहनिष्यत्', 'label': 'nihanizyat', 'attrib': [{'pos': 'fap', 'defs': 'smite'}], 'isLemma': 0, 'parents': ['ni_han']},
	{'value': 'निहनिष्यमाण', 'label': 'nihanizyamARa', 'attrib': [{'pos': 'fpp', 'defs': 'smite'}], 'isLemma': 0, 'parents': ['ni_han']},
	{'value': 'निहन्तव्य', 'label': 'nihantavya', 'attrib': [{'pos': 'ger', 'defs': 'smite'}], 'isLemma': 0, 'parents': ['ni_han']},
	{'value': 'निहन्तुम्', 'label': 'nihantum', 'attrib': [{'pos': 'inf', 'defs': 'smite'}], 'isLemma': 0, 'parents': ['ni_han']},
	{'value': 'निहन्य', 'label': 'nihanya', 'attrib': [{'pos': 'abs', 'defs': 'smite'}], 'isLemma': 0, 'parents': ['ni_han']},
	{'value': 'निहन्यमान', 'label': 'nihanyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'smite'}], 'isLemma': 0, 'parents': ['ni_han']},
	{'value': 'नृ', 'label': 'nf', 'attrib': [{'pos': 'm', 'defs': 'man'}], 'isLemma': 1},
	{'value': 'नृप', 'label': 'nfpa', 'attrib': [{'pos': 'm', 'defs': 'king'}], 'isLemma': 1},
	{'value': 'नृपसत्तम', 'label': 'nfpasattama', 'attrib': [{'pos': 'm', 'defs': 'most excellent king'}], 'isLemma': 1},
	{'value': 'नेमिवस्', 'label': 'nemivas', 'attrib': [{'pos': 'pfap', 'defs': 'bow'}], 'isLemma': 0, 'parents': ['nam']},
	{'value': 'नेशिवस्', 'label': 'neSivas', 'attrib': [{'pos': 'pfap', 'defs': 'perish'}], 'isLemma': 0, 'parents': ['naS']},
	{'value': 'नेश्वस्', 'label': 'neSvas', 'attrib': [{'pos': 'pfap', 'defs': 'perish'}], 'isLemma': 0, 'parents': ['naS']},
	{'value': 'पञ्चन्', 'label': 'paYcan', 'attrib': [{'pos': 'card_num', 'defs': 'five'}], 'isLemma': 1},
	{'value': 'पञ्चपञ्चाशत्', 'label': 'paYcapaYcASat', 'attrib': [{'pos': 'card_num', 'defs': 'fifty-five'}], 'isLemma': 1},
	{'value': 'पञ्चपञ्चाशत्तम', 'label': 'paYcapaYcASattama', 'attrib': [{'pos': 'ord_num', 'defs': 'fifty-fifth'}], 'isLemma': 1},
	{'value': 'पञ्चाल', 'label': 'paYcAla', 'attrib': [{'pos': 'm', 'defs': 'the people of the region between the Yamuna and Gomati rivers in north India, the region itself'}], 'isLemma': 1},
	{'value': 'पञ्चाशत्', 'label': 'paYcASat', 'attrib': [{'pos': 'card_num', 'defs': 'fifty'}], 'isLemma': 1},
	{'value': 'पत्तव्य', 'label': 'pattavya', 'attrib': [{'pos': 'ger', 'defs': 'step'}], 'isLemma': 0, 'parents': ['pad']},
	{'value': 'पत्तुम्', 'label': 'pattum', 'attrib': [{'pos': 'inf', 'defs': 'step'}], 'isLemma': 0, 'parents': ['pad']},
	{'value': 'पत्त्वा', 'label': 'pattvA', 'attrib': [{'pos': 'abs', 'defs': 'step'}], 'isLemma': 0, 'parents': ['pad']},
	{'value': 'पत्स्यमान', 'label': 'patsyamAna', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'step'}], 'isLemma': 0, 'parents': ['pad']},
	{'value': 'पथिन्', 'label': 'paTin', 'attrib': [{'pos': 'm', 'defs': 'path, road'}], 'isLemma': 1},
	{'value': 'पद्', 'label': 'pad', 'attrib': [{'pos': 'vt4m', 'defs': 'step'}], 'isLemma': 1},
	{'value': 'पद', 'label': 'pada', 'attrib': [{'pos': 'n', 'defs': 'step, foot'}], 'isLemma': 1},
	{'value': 'पदनीय', 'label': 'padanIya', 'attrib': [{'pos': 'ger', 'defs': 'step'}], 'isLemma': 0, 'parents': ['pad']},
	{'value': 'पद्यमान', 'label': 'padyamAna', 'attrib': [{'pos': 'prmp', 'defs': 'step'}, {'pos': 'prpp', 'defs': 'step'}], 'isLemma': 0, 'parents': ['pad']},
	{'value': 'पन्न', 'label': 'panna', 'attrib': [{'pos': 'ppp', 'defs': 'step'}], 'isLemma': 0, 'parents': ['pad']},
	{'value': 'पन्नवत्', 'label': 'pannavat', 'attrib': [{'pos': 'pap', 'defs': 'step'}], 'isLemma': 0, 'parents': ['pad']},
	{'value': 'पपराण', 'label': 'paparARa', 'attrib': [{'pos': 'pfpp', 'defs': 'fill'}], 'isLemma': 0, 'parents': ['pF']},
	{'value': 'पपर्वस्', 'label': 'paparvas', 'attrib': [{'pos': 'pfap', 'defs': 'fill'}], 'isLemma': 0, 'parents': ['pF']},
	{'value': 'पपान', 'label': 'papAna', 'attrib': [{'pos': 'pfpp', 'defs': 'drink'}], 'isLemma': 0, 'parents': ['pA']},
	{'value': 'पपिवस्', 'label': 'papivas', 'attrib': [{'pos': 'pfap', 'defs': 'drink'}], 'isLemma': 0, 'parents': ['pA']},
	{'value': 'पपॄवस्', 'label': 'papFvas', 'attrib': [{'pos': 'pfap', 'defs': 'fill'}], 'isLemma': 0, 'parents': ['pF']},
	{'value': 'पप्रच्छान', 'label': 'papracCAna', 'attrib': [{'pos': 'pfpp', 'defs': 'ask'}], 'isLemma': 0, 'parents': ['pracC']},
	{'value': 'पप्रच्छ्वस्', 'label': 'papracCvas', 'attrib': [{'pos': 'pfap', 'defs': 'ask'}], 'isLemma': 0, 'parents': ['pracC']},
	{'value': 'पप्राण', 'label': 'paprARa', 'attrib': [{'pos': 'pfpp', 'defs': 'fill'}], 'isLemma': 0, 'parents': ['pF']},
	{'value': 'पर', 'label': 'para', 'attrib': [{'pos': 'pron, pron_adj', 'defs': 'farther, other'}, {'pos': 'm', 'defs': 'enemy'}], 'isLemma': 1},
	{'value': 'परंतप', 'label': 'paraMtapa', 'attrib': [{'pos': 'adj', 'defs': 'enemy-scorching'}], 'isLemma': 1},
	{'value': 'परवीर', 'label': 'paravIra', 'attrib': [{'pos': 'm', 'defs': 'enemy hero'}], 'isLemma': 1},
	{'value': 'परवीरहन्', 'label': 'paravIrahan', 'attrib': [{'pos': 'adj', 'defs': 'slaying enemy heroes'}], 'isLemma': 1},
	{'value': 'परा', 'label': 'parA', 'attrib': [{'pos': 'preverb', 'defs': 'far'}], 'isLemma': 1},
	{'value': 'पराक्रम', 'label': 'parAkrama', 'attrib': [{'pos': 'm', 'defs': 'boldness'}], 'isLemma': 1},
	{'value': 'परा_क्रम्', 'label': 'parA_kram', 'attrib': [{'pos': 'vt1a', 'defs': 'step beyond, be bold'}], 'isLemma': 1},
	{'value': 'परायण', 'label': 'parAyaRa', 'attrib': [{'pos': 'n', 'defs': 'final aim'}], 'isLemma': 1},
	{'value': 'परि', 'label': 'pari', 'attrib': [{'pos': 'preverb', 'defs': 'around'}], 'isLemma': 1},
	{'value': 'परिष्यत्', 'label': 'parizyat', 'attrib': [{'pos': 'fap', 'defs': 'fill'}], 'isLemma': 0, 'parents': ['pF']},
	{'value': 'परिष्यमाण', 'label': 'parizyamARa', 'attrib': [{'pos': 'fpp', 'defs': 'fill'}], 'isLemma': 0, 'parents': ['pF']},
	{'value': 'परिसंवत्सर', 'label': 'parisaMvatsara', 'attrib': [{'pos': 'm', 'defs': 'a full year'}], 'isLemma': 1},
	{'value': 'परिसंवत्सरोषित', 'label': 'parisaMvatsarozita', 'attrib': [{'pos': 'adj', 'defs': 'dwelt a full year'}], 'isLemma': 1},
	{'value': 'परिस्पन्द', 'label': 'parispanda', 'attrib': [{'pos': 'm', 'defs': 'stirring, retinue'}], 'isLemma': 1},
	{'value': 'परीष्यत्', 'label': 'parIzyat', 'attrib': [{'pos': 'fap', 'defs': 'fill'}], 'isLemma': 0, 'parents': ['pF']},
	{'value': 'परीष्यमाण', 'label': 'parIzyamARa', 'attrib': [{'pos': 'fpp', 'defs': 'fill'}], 'isLemma': 0, 'parents': ['pF']},
	{'value': 'परे', 'label': 'pare', 'attrib': [{'pos': 'vt2a', 'defs': 'go away, flee, pass away'}], 'isLemma': 1},
	{'value': 'पर्वन्', 'label': 'parvan', 'attrib': [{'pos': 'n', 'defs': 'joint, section'}], 'isLemma': 1},
	{'value': 'पवनीय', 'label': 'pavanIya', 'attrib': [{'pos': 'ger', 'defs': 'purify'}], 'isLemma': 0, 'parents': ['pU']},
	{'value': 'पवित', 'label': 'pavita', 'attrib': [{'pos': 'ppp', 'defs': 'purify'}], 'isLemma': 0, 'parents': ['pU']},
	{'value': 'पवितवत्', 'label': 'pavitavat', 'attrib': [{'pos': 'pap', 'defs': 'purify'}], 'isLemma': 0, 'parents': ['pU']},
	{'value': 'पवितव्य', 'label': 'pavitavya', 'attrib': [{'pos': 'ger', 'defs': 'purify'}], 'isLemma': 0, 'parents': ['pU']},
	{'value': 'पवितुम्', 'label': 'pavitum', 'attrib': [{'pos': 'inf', 'defs': 'purify'}], 'isLemma': 0, 'parents': ['pU']},
	{'value': 'पवित्वा', 'label': 'pavitvA', 'attrib': [{'pos': 'abs', 'defs': 'purify'}], 'isLemma': 0, 'parents': ['pU']},
	{'value': 'पविष्यत्', 'label': 'pavizyat', 'attrib': [{'pos': 'fap', 'defs': 'purify'}], 'isLemma': 0, 'parents': ['pU']},
	{'value': 'पविष्यमाण', 'label': 'pavizyamARa', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'purify'}], 'isLemma': 0, 'parents': ['pU']},
	{'value': 'पव्य', 'label': 'pavya', 'attrib': [{'pos': 'ger', 'defs': 'purify'}], 'isLemma': 0, 'parents': ['pU']},
	{'value': 'पश्यत्', 'label': 'paSyat', 'attrib': [{'pos': 'prap', 'defs': 'see, watch'}], 'isLemma': 0, 'parents': ['dfS']},
	{'value': 'पा', 'label': 'pA', 'attrib': [{'pos': 'vt2a', 'defs': 'protect'}, {'pos': 'vt1a', 'defs': 'drink'}], 'isLemma': 1},
	{'value': 'पाञ्चाल', 'label': 'pAYcAla', 'attrib': [{'pos': 'adj', 'defs': 'of Pañcāla'}], 'isLemma': 1},
	{'value': 'पाण्डव', 'label': 'pARqava', 'attrib': [{'pos': 'm', 'defs': 'son of Pāṇḍu'}], 'isLemma': 1},
	{'value': 'पाण्डु', 'label': 'pARqu', 'attrib': [{'pos': 'adj', 'defs': 'pale'}, {'pos': 'm_pn', 'defs': 'Pāṇḍu'}], 'isLemma': 1},
	{'value': 'पातव्य', 'label': 'pAtavya', 'attrib': [{'pos': 'ger', 'defs': 'drink'}], 'isLemma': 0, 'parents': ['pA']},
	{'value': 'पातुम्', 'label': 'pAtum', 'attrib': [{'pos': 'inf', 'defs': 'drink'}], 'isLemma': 0, 'parents': ['pA']},
	{'value': 'पात्र', 'label': 'pAtra', 'attrib': [{'pos': 'n', 'defs': 'drinking-vessel, cup, begging bowl, worthy person'}], 'isLemma': 1},
	{'value': 'पाद्य', 'label': 'pAdya', 'attrib': [{'pos': 'ger', 'defs': 'step'}], 'isLemma': 0, 'parents': ['pad']},
	{'value': 'पानीय', 'label': 'pAnIya', 'attrib': [{'pos': 'ger', 'defs': 'drink'}], 'isLemma': 0, 'parents': ['pA']},
	{'value': 'पाप', 'label': 'pApa', 'attrib': [{'pos': 'adj', 'defs': 'evil'}, {'pos': 'n', 'defs': 'evil'}], 'isLemma': 1},
	{'value': 'पार्थ', 'label': 'pArTa', 'attrib': [{'pos': 'm', 'defs': 'son of Pṛthā'}], 'isLemma': 1},
	{'value': 'पावक', 'label': 'pAvaka', 'attrib': [{'pos': 'adj', 'defs': 'purifier, epithet of fire'}], 'isLemma': 1},
	{'value': 'पास्यत्', 'label': 'pAsyat', 'attrib': [{'pos': 'fap', 'defs': 'drink'}], 'isLemma': 0, 'parents': ['pA']},
	{'value': 'पास्यमान', 'label': 'pAsyamAna', 'attrib': [{'pos': 'fpp', 'defs': 'drink'}], 'isLemma': 0, 'parents': ['pA']},
	{'value': 'पितृ', 'label': 'pitf', 'attrib': [{'pos': 'm', 'defs': 'father'}], 'isLemma': 1},
	{'value': 'पिबत्', 'label': 'pibat', 'attrib': [{'pos': 'prap', 'defs': 'drink'}], 'isLemma': 0, 'parents': ['pA']},
	{'value': 'पीत', 'label': 'pIta', 'attrib': [{'pos': 'ppp', 'defs': 'drink'}], 'isLemma': 0, 'parents': ['pA']},
	{'value': 'पीतवत्', 'label': 'pItavat', 'attrib': [{'pos': 'pap', 'defs': 'drink'}], 'isLemma': 0, 'parents': ['pA']},
	{'value': 'पीत्वा', 'label': 'pItvA', 'attrib': [{'pos': 'abs', 'defs': 'drink'}], 'isLemma': 0, 'parents': ['pA']},
	{'value': 'पीयमान', 'label': 'pIyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'drink'}], 'isLemma': 0, 'parents': ['pA']},
	{'value': 'पुत्र', 'label': 'putra', 'attrib': [{'pos': 'm', 'defs': 'son, child'}], 'isLemma': 1},
	{'value': 'पुनत्', 'label': 'punat', 'attrib': [{'pos': 'prap', 'defs': 'purify'}], 'isLemma': 0, 'parents': ['pU']},
	{'value': 'पुनर्', 'label': 'punar', 'attrib': [{'pos': 'adv', 'defs': 'again'}], 'isLemma': 1},
	{'value': 'पुनान', 'label': 'punAna', 'attrib': [{'pos': 'prmp', 'defs': 'purify'}], 'isLemma': 0, 'parents': ['pU']},
	{'value': 'पुपुवान', 'label': 'pupuvAna', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'purify'}], 'isLemma': 0, 'parents': ['pU']},
	{'value': 'पुपूवस्', 'label': 'pupUvas', 'attrib': [{'pos': 'pfap', 'defs': 'purify'}], 'isLemma': 0, 'parents': ['pU']},
	{'value': 'पुर', 'label': 'pura', 'attrib': [{'pos': 'n', 'defs': 'city'}], 'isLemma': 1},
	{'value': 'पुरा', 'label': 'purA', 'attrib': [{'pos': 'adv', 'defs': 'before, formerly'}], 'isLemma': 1},
	{'value': 'पुरावृत्त', 'label': 'purAvftta', 'attrib': [{'pos': 'adj', 'defs': 'occurred in former times'}, {'pos': 'n, ck', 'defs': 'event or account of the past'}], 'isLemma': 1},
	{'value': 'पू', 'label': 'pU', 'attrib': [{'pos': 'vt9am', 'defs': 'purify'}], 'isLemma': 1},
	{'value': 'पूज्', 'label': 'pUj', 'attrib': [{'pos': 'vt10m', 'defs': 'honor, perform a pūjā'}], 'isLemma': 1},
	{'value': 'पूजनीय', 'label': 'pUjanIya', 'attrib': [{'pos': 'ger', 'defs': 'honor, perform a pūjā'}], 'isLemma': 0, 'parents': ['pUj']},
	{'value': 'पूजयमान', 'label': 'pUjayamAna', 'attrib': [{'pos': 'prmp', 'defs': 'honor, perform a pūjā'}], 'isLemma': 0, 'parents': ['pUj']},
	{'value': 'पूजयाम्', 'label': 'pUjayAm', 'attrib': [{'pos': 'ppf_actn', 'defs': 'honor, perform a pūjā'}], 'isLemma': 0, 'parents': ['pUj']},
	{'value': 'पूजयितुम्', 'label': 'pUjayitum', 'attrib': [{'pos': 'inf', 'defs': 'honor, perform a pūjā'}], 'isLemma': 0, 'parents': ['pUj']},
	{'value': 'पूजयित्वा', 'label': 'pUjayitvA', 'attrib': [{'pos': 'abs', 'defs': 'honor, perform a pūjā'}], 'isLemma': 0, 'parents': ['pUj']},
	{'value': 'पूजयिष्यमाण', 'label': 'pUjayizyamARa', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'honor, perform a pūjā'}], 'isLemma': 0, 'parents': ['pUj']},
	{'value': 'पूजित', 'label': 'pUjita', 'attrib': [{'pos': 'ppp', 'defs': 'honor, perform a pūjā'}], 'isLemma': 0, 'parents': ['pUj']},
	{'value': 'पूजितवत्', 'label': 'pUjitavat', 'attrib': [{'pos': 'pap', 'defs': 'honor, perform a pūjā'}], 'isLemma': 0, 'parents': ['pUj']},
	{'value': 'पूजितव्य', 'label': 'pUjitavya', 'attrib': [{'pos': 'ger', 'defs': 'honor, perform a pūjā'}], 'isLemma': 0, 'parents': ['pUj']},
	{'value': 'पूज्य', 'label': 'pUjya', 'attrib': [{'pos': 'ger', 'defs': 'honor, perform a pūjā'}], 'isLemma': 0, 'parents': ['pUj']},
	{'value': 'पूज्यमान', 'label': 'pUjyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'honor, perform a pūjā'}], 'isLemma': 0, 'parents': ['pUj']},
	{'value': 'पूत', 'label': 'pUta', 'attrib': [{'pos': 'ppp', 'defs': 'purify'}], 'isLemma': 0, 'parents': ['pU']},
	{'value': 'पूतवत्', 'label': 'pUtavat', 'attrib': [{'pos': 'pap', 'defs': 'purify'}], 'isLemma': 0, 'parents': ['pU']},
	{'value': 'पूत्वा', 'label': 'pUtvA', 'attrib': [{'pos': 'abs', 'defs': 'purify'}], 'isLemma': 0, 'parents': ['pU']},
	{'value': 'पूयमान', 'label': 'pUyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'purify'}], 'isLemma': 0, 'parents': ['pU']},
	{'value': 'पूरणीय', 'label': 'pUraRIya', 'attrib': [{'pos': 'ger', 'defs': 'fill'}], 'isLemma': 0, 'parents': ['pF']},
	{'value': 'पूरितव्य', 'label': 'pUritavya', 'attrib': [{'pos': 'ger', 'defs': 'fill'}], 'isLemma': 0, 'parents': ['pF']},
	{'value': 'पूरितुम्', 'label': 'pUritum', 'attrib': [{'pos': 'inf', 'defs': 'fill'}], 'isLemma': 0, 'parents': ['pF']},
	{'value': 'पूर्ण', 'label': 'pUrRa', 'attrib': [{'pos': 'adj', 'defs': 'full'}], 'isLemma': 1},
	{'value': 'पूर्त', 'label': 'pUrta', 'attrib': [{'pos': 'ppp', 'defs': 'fill'}], 'isLemma': 0, 'parents': ['pF']},
	{'value': 'पूर्तवत्', 'label': 'pUrtavat', 'attrib': [{'pos': 'pap', 'defs': 'fill'}], 'isLemma': 0, 'parents': ['pF']},
	{'value': 'पूर्त्वा', 'label': 'pUrtvA', 'attrib': [{'pos': 'abs', 'defs': 'fill'}], 'isLemma': 0, 'parents': ['pF']},
	{'value': 'पूर्य', 'label': 'pUrya', 'attrib': [{'pos': 'ger', 'defs': 'fill'}], 'isLemma': 0, 'parents': ['pF']},
	{'value': 'पूर्यमाण', 'label': 'pUryamARa', 'attrib': [{'pos': 'prpp', 'defs': 'fill'}], 'isLemma': 0, 'parents': ['pF']},
	{'value': 'पृच्छत्', 'label': 'pfcCat', 'attrib': [{'pos': 'prap', 'defs': 'ask'}], 'isLemma': 0, 'parents': ['pracC']},
	{'value': 'पृच्छ्य', 'label': 'pfcCya', 'attrib': [{'pos': 'ger', 'defs': 'ask'}], 'isLemma': 0, 'parents': ['pracC']},
	{'value': 'पृच्छ्यमान', 'label': 'pfcCyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'ask'}], 'isLemma': 0, 'parents': ['pracC']},
	{'value': 'पृणत्', 'label': 'pfRat', 'attrib': [{'pos': 'prap', 'defs': 'fill'}], 'isLemma': 0, 'parents': ['pF']},
	{'value': 'पृथ', 'label': 'pfTa', 'attrib': [{'pos': 'adj', 'defs': 'spread out, broad'}], 'isLemma': 1},
	{'value': 'पृथा', 'label': 'pfTA', 'attrib': [{'pos': 'f', 'defs': 'Kuntī'}], 'isLemma': 1},
	{'value': 'पृथिवी', 'label': 'pfTivI', 'attrib': [{'pos': 'f', 'defs': 'earth'}], 'isLemma': 1},
	{'value': 'पृथिवीक्षय', 'label': 'pfTivIkzaya', 'attrib': [{'pos': 'm', 'defs': 'destruction of the earth'}], 'isLemma': 1},
	{'value': 'पृथिवीक्षयकारक', 'label': 'pfTivIkzayakAraka', 'attrib': [{'pos': 'adj', 'defs': 'causing the destruction of the earth'}], 'isLemma': 1},
	{'value': 'पृथु', 'label': 'pfTu', 'attrib': [{'pos': 'adj', 'defs': 'broad'}], 'isLemma': 1},
	{'value': 'पृथ्वी', 'label': 'pfTvI', 'attrib': [{'pos': 'f', 'defs': 'the earth'}], 'isLemma': 1},
	{'value': 'पृष्ट', 'label': 'pfzwa', 'attrib': [{'pos': 'ppp', 'defs': 'ask'}], 'isLemma': 0, 'parents': ['pracC']},
	{'value': 'पृष्टवत्', 'label': 'pfzwavat', 'attrib': [{'pos': 'pap', 'defs': 'ask'}], 'isLemma': 0, 'parents': ['pracC']},
	{'value': 'पृष्ट्वा', 'label': 'pfzwvA', 'attrib': [{'pos': 'abs', 'defs': 'ask'}], 'isLemma': 0, 'parents': ['pracC']},
	{'value': 'पॄ', 'label': 'pF', 'attrib': [{'pos': 'vt9a', 'defs': 'fill'}], 'isLemma': 1},
	{'value': 'पेदान', 'label': 'pedAna', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'step'}], 'isLemma': 0, 'parents': ['pad']},
	{'value': 'पेय', 'label': 'peya', 'attrib': [{'pos': 'ger', 'defs': 'drink'}], 'isLemma': 0, 'parents': ['pA']},
	{'value': 'पौर', 'label': 'pOra', 'attrib': [{'pos': 'adj', 'defs': 'urban'}, {'pos': 'm', 'defs': 'citizen'}], 'isLemma': 1},
	{'value': 'पौरसंमत', 'label': 'pOrasaMmata', 'attrib': [{'pos': 'adj', 'defs': 'approved of by the citizens'}], 'isLemma': 1},
	{'value': 'प्र', 'label': 'pra', 'attrib': [{'pos': 'preverb', 'defs': 'before'}], 'isLemma': 1},
	{'value': 'प्रकार', 'label': 'prakAra', 'attrib': [{'pos': 'm', 'defs': 'doing, kind, manner'}], 'isLemma': 1},
	{'value': 'प्रक्षिपत्', 'label': 'prakzipat', 'attrib': [{'pos': 'prap', 'defs': 'throw, project'}], 'isLemma': 0, 'parents': ['pra_kzip']},
	{'value': 'प्रक्षिप्त', 'label': 'prakzipta', 'attrib': [{'pos': 'ppp', 'defs': 'throw, project'}], 'isLemma': 0, 'parents': ['pra_kzip']},
	{'value': 'प्रक्षिप्तवत्', 'label': 'prakziptavat', 'attrib': [{'pos': 'pap', 'defs': 'throw, project'}], 'isLemma': 0, 'parents': ['pra_kzip']},
	{'value': 'प्रक्षिप्य', 'label': 'prakzipya', 'attrib': [{'pos': 'abs', 'defs': 'throw, project'}], 'isLemma': 0, 'parents': ['pra_kzip']},
	{'value': 'प्रक्षिप्यत्', 'label': 'prakzipyat', 'attrib': [{'pos': 'prap', 'defs': 'throw, project'}], 'isLemma': 0, 'parents': ['pra_kzip']},
	{'value': 'प्रक्षिप्यमाण', 'label': 'prakzipyamARa', 'attrib': [{'pos': 'prmp, prpp', 'defs': 'throw, project'}], 'isLemma': 0, 'parents': ['pra_kzip']},
	{'value': 'प्रक्षिप्स्यत्', 'label': 'prakzipsyat', 'attrib': [{'pos': 'fap', 'defs': 'throw, project'}], 'isLemma': 0, 'parents': ['pra_kzip']},
	{'value': 'प्रक्षिप्स्यमाण', 'label': 'prakzipsyamARa', 'attrib': [{'pos': 'fpp', 'defs': 'throw, project'}], 'isLemma': 0, 'parents': ['pra_kzip']},
	{'value': 'प्रक्षेपयणीय', 'label': 'prakzepayaRIya', 'attrib': [{'pos': 'ger', 'defs': 'throw, project'}], 'isLemma': 0, 'parents': ['pra_kzip']},
	{'value': 'प्रक्षेप्तव्य', 'label': 'prakzeptavya', 'attrib': [{'pos': 'ger', 'defs': 'throw, project'}], 'isLemma': 0, 'parents': ['pra_kzip']},
	{'value': 'प्रक्षेप्तुम्', 'label': 'prakzeptum', 'attrib': [{'pos': 'inf', 'defs': 'throw, project'}], 'isLemma': 0, 'parents': ['pra_kzip']},
	{'value': 'प्रक्षेप्य', 'label': 'prakzepya', 'attrib': [{'pos': 'ger', 'defs': 'throw, project'}], 'isLemma': 0, 'parents': ['pra_kzip']},
	{'value': 'प्रक्षेप्स्यत्', 'label': 'prakzepsyat', 'attrib': [{'pos': 'fap', 'defs': 'throw, project'}], 'isLemma': 0, 'parents': ['pra_kzip']},
	{'value': 'प्रक्षेप्स्यमाण', 'label': 'prakzepsyamARa', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'throw, project'}], 'isLemma': 0, 'parents': ['pra_kzip']},
	{'value': 'प्रक्ष्यत्', 'label': 'prakzyat', 'attrib': [{'pos': 'fap', 'defs': 'ask'}], 'isLemma': 0, 'parents': ['pracC']},
	{'value': 'प्रक्ष्यमाण', 'label': 'prakzyamARa', 'attrib': [{'pos': 'fpp', 'defs': 'ask'}], 'isLemma': 0, 'parents': ['pracC']},
	{'value': 'प्रचिक्षिपाण', 'label': 'pracikzipARa', 'attrib': [{'pos': 'pfpp', 'defs': 'throw, project'}], 'isLemma': 0, 'parents': ['pra_kzip']},
	{'value': 'प्रचिक्षिपिवस्', 'label': 'pracikzipivas', 'attrib': [{'pos': 'pfap', 'defs': 'throw, project'}], 'isLemma': 0, 'parents': ['pra_kzip']},
	{'value': 'प्रच्छ्', 'label': 'pracC', 'attrib': [{'pos': 'vt6a', 'defs': 'ask'}], 'isLemma': 1},
	{'value': 'प्रच्छनीय', 'label': 'pracCanIya', 'attrib': [{'pos': 'ger', 'defs': 'ask'}], 'isLemma': 0, 'parents': ['pracC']},
	{'value': 'प्र_आप्', 'label': 'pra_Ap', 'attrib': [{'pos': 'vt5a', 'defs': 'attain, obtain'}], 'isLemma': 1},
	{'value': 'प्र_उद्_सह्', 'label': 'pra_ud_sah', 'attrib': [{'pos': 'vt1m, vt10am', 'defs': 'be eager'}], 'isLemma': 1},
	{'value': 'प्र_कृ', 'label': 'pra_kf', 'attrib': [{'pos': 'vt8am', 'defs': 'make, produce'}], 'isLemma': 1},
	{'value': 'प्र_क्षिप्', 'label': 'pra_kzip', 'attrib': [{'pos': 'vt4a, vt6am', 'defs': 'throw, project'}], 'isLemma': 1},
	{'value': 'प्र_तप्', 'label': 'pra_tap', 'attrib': [{'pos': 'vt1a', 'defs': 'heat, burn, shine forth'}], 'isLemma': 1},
	{'value': 'प्र_द्रु', 'label': 'pra_dru', 'attrib': [{'pos': 'vt1a', 'defs': 'run forth, escape, flee'}], 'isLemma': 1},
	{'value': 'प्र_बुध्', 'label': 'pra_buD', 'attrib': [{'pos': 'vt1am', 'defs': 'know'}, {'pos': 'vt4m', 'defs': 'awake, be aware, know'}], 'isLemma': 1},
	{'value': 'प्र_मद्', 'label': 'pra_mad', 'attrib': [{'pos': 'vi4a', 'defs': 'err, be careless'}], 'isLemma': 1},
	{'value': 'प्र_वच्', 'label': 'pra_vac', 'attrib': [{'pos': 'vt2a', 'defs': 'proclaim, declare, tell'}], 'isLemma': 1},
	{'value': 'प्र_स्था', 'label': 'pra_sTA', 'attrib': [{'pos': 'vt1m', 'defs': 'set out'}], 'isLemma': 1},
	{'value': 'प्रतपत्', 'label': 'pratapat', 'attrib': [{'pos': 'prap', 'defs': 'heat, burn, shine forth'}], 'isLemma': 0, 'parents': ['pra_tap']},
	{'value': 'प्रतपनीय', 'label': 'pratapanIya', 'attrib': [{'pos': 'ger', 'defs': 'heat, burn, shine forth'}], 'isLemma': 0, 'parents': ['pra_tap']},
	{'value': 'प्रतप्त', 'label': 'pratapta', 'attrib': [{'pos': 'ppp', 'defs': 'heat, burn, shine forth'}], 'isLemma': 0, 'parents': ['pra_tap']},
	{'value': 'प्रतप्तवत्', 'label': 'prataptavat', 'attrib': [{'pos': 'pap', 'defs': 'heat, burn, shine forth'}], 'isLemma': 0, 'parents': ['pra_tap']},
	{'value': 'प्रतप्तव्य', 'label': 'prataptavya', 'attrib': [{'pos': 'ger', 'defs': 'heat, burn, shine forth'}], 'isLemma': 0, 'parents': ['pra_tap']},
	{'value': 'प्रतप्तुम्', 'label': 'prataptum', 'attrib': [{'pos': 'inf', 'defs': 'heat, burn, shine forth'}], 'isLemma': 0, 'parents': ['pra_tap']},
	{'value': 'प्रतप्य', 'label': 'pratapya', 'attrib': [{'pos': 'abs', 'defs': 'heat, burn, shine forth'}, {'pos': 'ger', 'defs': 'heat, burn, shine forth'}], 'isLemma': 0, 'parents': ['pra_tap']},
	{'value': 'प्रतप्यमान', 'label': 'pratapyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'heat, burn, shine forth'}], 'isLemma': 0, 'parents': ['pra_tap']},
	{'value': 'प्रतप्स्यत्', 'label': 'pratapsyat', 'attrib': [{'pos': 'fap', 'defs': 'heat, burn, shine forth'}], 'isLemma': 0, 'parents': ['pra_tap']},
	{'value': 'प्रतप्स्यमाण', 'label': 'pratapsyamARa', 'attrib': [{'pos': 'fpp', 'defs': 'heat, burn, shine forth'}], 'isLemma': 0, 'parents': ['pra_tap']},
	{'value': 'प्रतस्थान', 'label': 'pratasTAna', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'set out'}], 'isLemma': 0, 'parents': ['pra_sTA']},
	{'value': 'प्रताप', 'label': 'pratApa', 'attrib': [{'pos': 'm', 'defs': 'blazing'}], 'isLemma': 1},
	{'value': 'प्रति', 'label': 'prati', 'attrib': [{'pos': 'preverb, pcl', 'defs': 'toward, against'}], 'isLemma': 1},
	{'value': 'प्रतिघात', 'label': 'pratiGAta', 'attrib': [{'pos': 'm', 'defs': 'resistance, opposition, defense'}], 'isLemma': 1},
	{'value': 'प्रति_आ_गम्', 'label': 'prati_A_gam', 'attrib': [{'pos': 'vt1a', 'defs': 'come back, return'}], 'isLemma': 1},
	{'value': 'प्रति_पद्', 'label': 'prati_pad', 'attrib': [{'pos': 'vt4m', 'defs': 'step back, return to'}], 'isLemma': 1},
	{'value': 'प्रतिपत्तव्य', 'label': 'pratipattavya', 'attrib': [{'pos': 'ger', 'defs': 'step back, return to'}], 'isLemma': 0, 'parents': ['prati_pad']},
	{'value': 'प्रतिपत्तुम्', 'label': 'pratipattum', 'attrib': [{'pos': 'inf', 'defs': 'step back, return to'}], 'isLemma': 0, 'parents': ['prati_pad']},
	{'value': 'प्रतिपत्त्वा', 'label': 'pratipattvA', 'attrib': [{'pos': 'abs', 'defs': 'step back, return to'}], 'isLemma': 0, 'parents': ['prati_pad']},
	{'value': 'प्रतिपत्स्यमान', 'label': 'pratipatsyamAna', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'step back, return to'}], 'isLemma': 0, 'parents': ['prati_pad']},
	{'value': 'प्रतिपदनीय', 'label': 'pratipadanIya', 'attrib': [{'pos': 'ger', 'defs': 'step back, return to'}], 'isLemma': 0, 'parents': ['prati_pad']},
	{'value': 'प्रतिपद्य', 'label': 'pratipadya', 'attrib': [{'pos': 'abs', 'defs': 'step back, return to'}], 'isLemma': 0, 'parents': ['prati_pad']},
	{'value': 'प्रतिपद्यमान', 'label': 'pratipadyamAna', 'attrib': [{'pos': 'prmp', 'defs': 'step back, return to'}, {'pos': 'prpp', 'defs': 'step back, return to'}], 'isLemma': 0, 'parents': ['prati_pad']},
	{'value': 'प्रतिपन्न', 'label': 'pratipanna', 'attrib': [{'pos': 'ppp', 'defs': 'step back, return to'}], 'isLemma': 0, 'parents': ['prati_pad']},
	{'value': 'प्रतिपन्नवत्', 'label': 'pratipannavat', 'attrib': [{'pos': 'pap', 'defs': 'step back, return to'}], 'isLemma': 0, 'parents': ['prati_pad']},
	{'value': 'प्रतिपाद्य', 'label': 'pratipAdya', 'attrib': [{'pos': 'ger', 'defs': 'step back, return to'}], 'isLemma': 0, 'parents': ['prati_pad']},
	{'value': 'प्रतिपेदान', 'label': 'pratipedAna', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'step back, return to'}], 'isLemma': 0, 'parents': ['prati_pad']},
	{'value': 'प्रतिष्ठमान', 'label': 'pratizWamAna', 'attrib': [{'pos': 'prmp', 'defs': 'set out'}], 'isLemma': 0, 'parents': ['pra_sTA']},
	{'value': 'प्रतीची', 'label': 'pratIcI', 'attrib': [{'pos': 'f', 'defs': 'the west'}], 'isLemma': 1},
	{'value': 'प्रतेपान', 'label': 'pratepAna', 'attrib': [{'pos': 'pfpp', 'defs': 'heat, burn, shine forth'}], 'isLemma': 0, 'parents': ['pra_tap']},
	{'value': 'प्रतेपिवस्', 'label': 'pratepivas', 'attrib': [{'pos': 'pfap', 'defs': 'heat, burn, shine forth'}], 'isLemma': 0, 'parents': ['pra_tap']},
	{'value': 'प्रत्यञ्च्', 'label': 'pratyaYc', 'attrib': [{'pos': 'adj', 'defs': 'turned back or against, western'}], 'isLemma': 1},
	{'value': 'प्रत्यागच्छत्', 'label': 'pratyAgacCat', 'attrib': [{'pos': 'prap', 'defs': 'come back, return'}], 'isLemma': 0, 'parents': ['prati_A_gam']},
	{'value': 'प्रत्यागत', 'label': 'pratyAgata', 'attrib': [{'pos': 'ppp', 'defs': 'come back, return'}], 'isLemma': 0, 'parents': ['prati_A_gam']},
	{'value': 'प्रत्यागतवत्', 'label': 'pratyAgatavat', 'attrib': [{'pos': 'pap', 'defs': 'come back, return'}], 'isLemma': 0, 'parents': ['prati_A_gam']},
	{'value': 'प्रत्यागत्य', 'label': 'pratyAgatya', 'attrib': [{'pos': 'abs', 'defs': 'come back, return'}], 'isLemma': 0, 'parents': ['prati_A_gam']},
	{'value': 'प्रत्यागन्तव्य', 'label': 'pratyAgantavya', 'attrib': [{'pos': 'ger', 'defs': 'come back, return'}], 'isLemma': 0, 'parents': ['prati_A_gam']},
	{'value': 'प्रत्यागन्तुम्', 'label': 'pratyAgantum', 'attrib': [{'pos': 'inf', 'defs': 'come back, return'}], 'isLemma': 0, 'parents': ['prati_A_gam']},
	{'value': 'प्रत्यागमनीय', 'label': 'pratyAgamanIya', 'attrib': [{'pos': 'ger', 'defs': 'come back, return'}], 'isLemma': 0, 'parents': ['prati_A_gam']},
	{'value': 'प्रत्यागमिष्यत्', 'label': 'pratyAgamizyat', 'attrib': [{'pos': 'fap', 'defs': 'come back, return'}], 'isLemma': 0, 'parents': ['prati_A_gam']},
	{'value': 'प्रत्यागमिष्यमाण', 'label': 'pratyAgamizyamARa', 'attrib': [{'pos': 'fpp', 'defs': 'come back, return'}], 'isLemma': 0, 'parents': ['prati_A_gam']},
	{'value': 'प्रत्यागम्य', 'label': 'pratyAgamya', 'attrib': [{'pos': 'abs', 'defs': 'come back, return'}, {'pos': 'ger', 'defs': 'come back, return'}], 'isLemma': 0, 'parents': ['prati_A_gam']},
	{'value': 'प्रत्यागम्यमान', 'label': 'pratyAgamyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'come back, return'}], 'isLemma': 0, 'parents': ['prati_A_gam']},
	{'value': 'प्रत्याजगन्वस्', 'label': 'pratyAjaganvas', 'attrib': [{'pos': 'pfap', 'defs': 'come back, return'}], 'isLemma': 0, 'parents': ['prati_A_gam']},
	{'value': 'प्रत्याजग्मान', 'label': 'pratyAjagmAna', 'attrib': [{'pos': 'pfpp', 'defs': 'come back, return'}], 'isLemma': 0, 'parents': ['prati_A_gam']},
	{'value': 'प्रत्याजग्मिवस्', 'label': 'pratyAjagmivas', 'attrib': [{'pos': 'pfap', 'defs': 'come back, return'}], 'isLemma': 0, 'parents': ['prati_A_gam']},
	{'value': 'प्रत्य्_आ_गम्', 'label': 'praty_A_gam', 'attrib': [{'pos': 'vt1a', 'defs': 'come back, return'}], 'isLemma': 1},
	{'value': 'प्रथ्', 'label': 'praT', 'attrib': [{'pos': 'vi1m', 'defs': 'extend, spread'}], 'isLemma': 1},
	{'value': 'प्रधान', 'label': 'praDAna', 'attrib': [{'pos': 'adj', 'defs': 'principal'}], 'isLemma': 1},
	{'value': 'प्रबुद्ध', 'label': 'prabudDa', 'attrib': [{'pos': 'ppp', 'defs': 'awake, be aware, know'}], 'isLemma': 0, 'parents': ['pra_buD']},
	{'value': 'प्रबुद्धवत्', 'label': 'prabudDavat', 'attrib': [{'pos': 'pap', 'defs': 'awake, be aware, know'}], 'isLemma': 0, 'parents': ['pra_buD']},
	{'value': 'प्रबुद्ध्वा', 'label': 'prabudDvA', 'attrib': [{'pos': 'abs', 'defs': 'awake, be aware, know'}], 'isLemma': 0, 'parents': ['pra_buD']},
	{'value': 'प्रबुध्य', 'label': 'prabuDya', 'attrib': [{'pos': 'abs', 'defs': 'awake, be aware, know'}], 'isLemma': 0, 'parents': ['pra_buD']},
	{'value': 'प्रबुध्यमान', 'label': 'prabuDyamAna', 'attrib': [{'pos': 'prmp', 'defs': 'awake, be aware, know'}, {'pos': 'prpp', 'defs': 'awake, be aware, know'}], 'isLemma': 0, 'parents': ['pra_buD']},
	{'value': 'प्रबुबुधान', 'label': 'prabubuDAna', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'awake, be aware, know'}], 'isLemma': 0, 'parents': ['pra_buD']},
	{'value': 'प्रबोद्धव्य', 'label': 'prabodDavya', 'attrib': [{'pos': 'ger', 'defs': 'awake, be aware, know'}], 'isLemma': 0, 'parents': ['pra_buD']},
	{'value': 'प्रबोद्धुम्', 'label': 'prabodDum', 'attrib': [{'pos': 'inf', 'defs': 'awake, be aware, know'}], 'isLemma': 0, 'parents': ['pra_buD']},
	{'value': 'प्रबोधनीय', 'label': 'praboDanIya', 'attrib': [{'pos': 'ger', 'defs': 'awake, be aware, know'}], 'isLemma': 0, 'parents': ['pra_buD']},
	{'value': 'प्रबोध्य', 'label': 'praboDya', 'attrib': [{'pos': 'ger', 'defs': 'awake, be aware, know'}], 'isLemma': 0, 'parents': ['pra_buD']},
	{'value': 'प्रभोत्स्यमान', 'label': 'praBotsyamAna', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'awake, be aware, know'}], 'isLemma': 0, 'parents': ['pra_buD']},
	{'value': 'प्रमत्त', 'label': 'pramatta', 'attrib': [{'pos': 'ppp', 'defs': 'err, be careless'}], 'isLemma': 0, 'parents': ['pra_mad']},
	{'value': 'प्रमाण', 'label': 'pramARa', 'attrib': [{'pos': 'n', 'defs': 'means of knowledge, measure, standard, authority'}], 'isLemma': 1},
	{'value': 'प्रमाणकोटि', 'label': 'pramARakowi', 'attrib': [{'pos': 'f_pn', 'defs': 'Pramāṇakoṭi'}], 'isLemma': 1},
	{'value': 'प्रवक्तव्य', 'label': 'pravaktavya', 'attrib': [{'pos': 'ger', 'defs': 'proclaim, declare, tell'}], 'isLemma': 0, 'parents': ['pra_vac']},
	{'value': 'प्रवक्तुम्', 'label': 'pravaktum', 'attrib': [{'pos': 'inf', 'defs': 'proclaim, declare, tell'}], 'isLemma': 0, 'parents': ['pra_vac']},
	{'value': 'प्रवक्ष्यत्', 'label': 'pravakzyat', 'attrib': [{'pos': 'fap', 'defs': 'proclaim, declare, tell'}], 'isLemma': 0, 'parents': ['pra_vac']},
	{'value': 'प्रवक्ष्यमाण', 'label': 'pravakzyamARa', 'attrib': [{'pos': 'fpp', 'defs': 'proclaim, declare, tell'}], 'isLemma': 0, 'parents': ['pra_vac']},
	{'value': 'प्रवचनीय', 'label': 'pravacanIya', 'attrib': [{'pos': 'ger', 'defs': 'proclaim, declare, tell'}], 'isLemma': 0, 'parents': ['pra_vac']},
	{'value': 'प्रवाक्य', 'label': 'pravAkya', 'attrib': [{'pos': 'ger', 'defs': 'proclaim, declare, tell'}], 'isLemma': 0, 'parents': ['pra_vac']},
	{'value': 'प्रष्टव्य', 'label': 'prazwavya', 'attrib': [{'pos': 'ger', 'defs': 'ask'}], 'isLemma': 0, 'parents': ['pracC']},
	{'value': 'प्रष्टुम्', 'label': 'prazwum', 'attrib': [{'pos': 'inf', 'defs': 'ask'}], 'isLemma': 0, 'parents': ['pracC']},
	{'value': 'प्रस्थ', 'label': 'prasTa', 'attrib': [{'pos': 'm, n', 'defs': 'plateau, plain'}], 'isLemma': 1},
	{'value': 'प्रस्थातव्य', 'label': 'prasTAtavya', 'attrib': [{'pos': 'ger', 'defs': 'set out'}], 'isLemma': 0, 'parents': ['pra_sTA']},
	{'value': 'प्रस्थातुम्', 'label': 'prasTAtum', 'attrib': [{'pos': 'inf', 'defs': 'set out'}], 'isLemma': 0, 'parents': ['pra_sTA']},
	{'value': 'प्रस्थानीय', 'label': 'prasTAnIya', 'attrib': [{'pos': 'ger', 'defs': 'set out'}], 'isLemma': 0, 'parents': ['pra_sTA']},
	{'value': 'प्रस्थापयाम्', 'label': 'prasTApayAm', 'attrib': [{'pos': 'ppf_actn', 'defs': 'set out'}], 'isLemma': 0, 'parents': ['pra_sTA']},
	{'value': 'प्रस्थाय', 'label': 'prasTAya', 'attrib': [{'pos': 'abs', 'defs': 'set out'}], 'isLemma': 0, 'parents': ['pra_sTA']},
	{'value': 'प्रस्थास्यमान', 'label': 'prasTAsyamAna', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'set out'}], 'isLemma': 0, 'parents': ['pra_sTA']},
	{'value': 'प्रस्थित', 'label': 'prasTita', 'attrib': [{'pos': 'ppp', 'defs': 'set out'}], 'isLemma': 0, 'parents': ['pra_sTA']},
	{'value': 'प्रस्थितवत्', 'label': 'prasTitavat', 'attrib': [{'pos': 'pap', 'defs': 'set out'}], 'isLemma': 0, 'parents': ['pra_sTA']},
	{'value': 'प्रस्थीयमान', 'label': 'prasTIyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'set out'}], 'isLemma': 0, 'parents': ['pra_sTA']},
	{'value': 'प्रस्थेय', 'label': 'prasTeya', 'attrib': [{'pos': 'ger', 'defs': 'set out'}], 'isLemma': 0, 'parents': ['pra_sTA']},
	{'value': 'प्राक्', 'label': 'prAk', 'attrib': [{'pos': 'adv', 'defs': 'before, first'}], 'isLemma': 1},
	{'value': 'प्राची', 'label': 'prAcI', 'attrib': [{'pos': 'f', 'defs': 'the east'}], 'isLemma': 1},
	{'value': 'प्राञ्च्', 'label': 'prAYc', 'attrib': [{'pos': 'adj', 'defs': 'turned or being forwards, front, eastward, eastern, before'}], 'isLemma': 1},
	{'value': 'प्रापणीय', 'label': 'prApaRIya', 'attrib': [{'pos': 'ger', 'defs': 'attain, obtain'}], 'isLemma': 0, 'parents': ['pra_Ap']},
	{'value': 'प्रापाण', 'label': 'prApARa', 'attrib': [{'pos': 'pfpp', 'defs': 'attain, obtain'}], 'isLemma': 0, 'parents': ['pra_Ap']},
	{'value': 'प्रापिवस्', 'label': 'prApivas', 'attrib': [{'pos': 'pfap', 'defs': 'attain, obtain'}], 'isLemma': 0, 'parents': ['pra_Ap']},
	{'value': 'प्राप्त', 'label': 'prApta', 'attrib': [{'pos': 'ppp', 'defs': 'attain, obtain'}], 'isLemma': 0, 'parents': ['pra_Ap']},
	{'value': 'प्राप्तवत्', 'label': 'prAptavat', 'attrib': [{'pos': 'pap', 'defs': 'attain, obtain'}], 'isLemma': 0, 'parents': ['pra_Ap']},
	{'value': 'प्राप्तव्य', 'label': 'prAptavya', 'attrib': [{'pos': 'ger', 'defs': 'attain, obtain'}], 'isLemma': 0, 'parents': ['pra_Ap']},
	{'value': 'प्राप्तुम्', 'label': 'prAptum', 'attrib': [{'pos': 'inf', 'defs': 'attain, obtain'}], 'isLemma': 0, 'parents': ['pra_Ap']},
	{'value': 'प्राप्नुवत्', 'label': 'prApnuvat', 'attrib': [{'pos': 'prap', 'defs': 'attain, obtain'}], 'isLemma': 0, 'parents': ['pra_Ap']},
	{'value': 'प्राप्य', 'label': 'prApya', 'attrib': [{'pos': 'abs', 'defs': 'attain, obtain'}, {'pos': 'ger', 'defs': 'attain, obtain'}], 'isLemma': 0, 'parents': ['pra_Ap']},
	{'value': 'प्राप्यमान', 'label': 'prApyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'attain, obtain'}], 'isLemma': 0, 'parents': ['pra_Ap']},
	{'value': 'प्राप्स्यत्', 'label': 'prApsyat', 'attrib': [{'pos': 'fap', 'defs': 'attain, obtain'}], 'isLemma': 0, 'parents': ['pra_Ap']},
	{'value': 'प्राप्स्यमान', 'label': 'prApsyamAna', 'attrib': [{'pos': 'fpp', 'defs': 'attain, obtain'}], 'isLemma': 0, 'parents': ['pra_Ap']},
	{'value': 'प्री', 'label': 'prI', 'attrib': [{'pos': 'vt9am', 'defs': 'please'}, {'pos': 'vi4m', 'defs': 'be pleased'}], 'isLemma': 1},
	{'value': 'प्रीत', 'label': 'prIta', 'attrib': [{'pos': 'ppp', 'defs': 'be pleased'}], 'isLemma': 0, 'parents': ['prI']},
	{'value': 'प्रोक्त', 'label': 'prokta', 'attrib': [{'pos': 'ppp', 'defs': 'proclaim, declare, tell'}], 'isLemma': 0, 'parents': ['pra_vac']},
	{'value': 'प्रोक्तवत्', 'label': 'proktavat', 'attrib': [{'pos': 'pap', 'defs': 'proclaim, declare, tell'}], 'isLemma': 0, 'parents': ['pra_vac']},
	{'value': 'प्रोक्त्वा', 'label': 'proktvA', 'attrib': [{'pos': 'abs', 'defs': 'proclaim, declare, tell'}], 'isLemma': 0, 'parents': ['pra_vac']},
	{'value': 'प्रोचान', 'label': 'procAna', 'attrib': [{'pos': 'pfpp', 'defs': 'proclaim, declare, tell'}], 'isLemma': 0, 'parents': ['pra_vac']},
	{'value': 'प्रोचिवस्', 'label': 'procivas', 'attrib': [{'pos': 'pfap', 'defs': 'proclaim, declare, tell'}], 'isLemma': 0, 'parents': ['pra_vac']},
	{'value': 'प्रोच्य', 'label': 'procya', 'attrib': [{'pos': 'abs', 'defs': 'proclaim, declare, tell'}], 'isLemma': 0, 'parents': ['pra_vac']},
	{'value': 'प्रोच्यमान', 'label': 'procyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'proclaim, declare, tell'}], 'isLemma': 0, 'parents': ['pra_vac']},
	{'value': 'बक', 'label': 'baka', 'attrib': [{'pos': 'm', 'defs': 'heron, a cheat'}, {'pos': 'm_pn', 'defs': 'Baka'}], 'isLemma': 1},
	{'value': 'बद्ध', 'label': 'badDa', 'attrib': [{'pos': 'ppp', 'defs': 'bind'}], 'isLemma': 0, 'parents': ['banD']},
	{'value': 'बद्धवत्', 'label': 'badDavat', 'attrib': [{'pos': 'pap', 'defs': 'bind'}], 'isLemma': 0, 'parents': ['banD']},
	{'value': 'बद्ध्वा', 'label': 'badDvA', 'attrib': [{'pos': 'abs', 'defs': 'bind'}], 'isLemma': 0, 'parents': ['banD']},
	{'value': 'बध्नत्', 'label': 'baDnat', 'attrib': [{'pos': 'prap', 'defs': 'bind'}], 'isLemma': 0, 'parents': ['banD']},
	{'value': 'बध्यमान', 'label': 'baDyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'bind'}], 'isLemma': 0, 'parents': ['banD']},
	{'value': 'बन्द्धव्य', 'label': 'bandDavya', 'attrib': [{'pos': 'ger', 'defs': 'bind'}], 'isLemma': 0, 'parents': ['banD']},
	{'value': 'बन्द्धुम्', 'label': 'bandDum', 'attrib': [{'pos': 'inf', 'defs': 'bind'}], 'isLemma': 0, 'parents': ['banD']},
	{'value': 'बन्ध्', 'label': 'banD', 'attrib': [{'pos': 'vt9am', 'defs': 'bind'}], 'isLemma': 1},
	{'value': 'बन्धन', 'label': 'banDana', 'attrib': [{'pos': 'n', 'defs': 'binding, bond'}], 'isLemma': 1},
	{'value': 'बन्धनीय', 'label': 'banDanIya', 'attrib': [{'pos': 'ger', 'defs': 'bind'}], 'isLemma': 0, 'parents': ['banD']},
	{'value': 'बन्ध्य', 'label': 'banDya', 'attrib': [{'pos': 'ger', 'defs': 'bind'}], 'isLemma': 0, 'parents': ['banD']},
	{'value': 'बबन्धान', 'label': 'babanDAna', 'attrib': [{'pos': 'pfpp', 'defs': 'bind'}], 'isLemma': 0, 'parents': ['banD']},
	{'value': 'बबन्ध्वस्', 'label': 'babanDvas', 'attrib': [{'pos': 'pfap', 'defs': 'bind'}], 'isLemma': 0, 'parents': ['banD']},
	{'value': 'बभान', 'label': 'baBAna', 'attrib': [{'pos': 'pfpp', 'defs': 'shine, appear'}], 'isLemma': 0, 'parents': ['BA']},
	{'value': 'बभाषाण', 'label': 'baBAzARa', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'speak, say'}], 'isLemma': 0, 'parents': ['BAz']},
	{'value': 'बभिवस्', 'label': 'baBivas', 'attrib': [{'pos': 'pfap', 'defs': 'shine, appear'}], 'isLemma': 0, 'parents': ['BA']},
	{'value': 'बभूवस्', 'label': 'baBUvas', 'attrib': [{'pos': 'pfap', 'defs': 'be, become'}], 'isLemma': 0, 'parents': ['BU']},
	{'value': 'बभृवस्', 'label': 'baBfvas', 'attrib': [{'pos': 'pfap', 'defs': 'bear'}], 'isLemma': 0, 'parents': ['Bf']},
	{'value': 'बभ्राण', 'label': 'baBrARa', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'bear'}], 'isLemma': 0, 'parents': ['Bf']},
	{'value': 'बल', 'label': 'bala', 'attrib': [{'pos': 'n', 'defs': 'strength'}], 'isLemma': 1},
	{'value': 'बहु', 'label': 'bahu', 'attrib': [{'pos': 'adj', 'defs': 'much, many'}], 'isLemma': 1},
	{'value': 'बाण', 'label': 'bARa', 'attrib': [{'pos': 'm', 'defs': 'reed, arrow'}], 'isLemma': 1},
	{'value': 'बाध्', 'label': 'bAD', 'attrib': [{'pos': 'vt1', 'defs': 'press, harass'}], 'isLemma': 1},
	{'value': 'बिभयाम्', 'label': 'biBayAm', 'attrib': [{'pos': 'ppf_actn', 'defs': 'fear'}], 'isLemma': 0, 'parents': ['BI']},
	{'value': 'बिभराम्', 'label': 'biBarAm', 'attrib': [{'pos': 'ppf_actn', 'defs': 'bear'}], 'isLemma': 0, 'parents': ['Bf']},
	{'value': 'बिभिदान', 'label': 'biBidAna', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'split'}], 'isLemma': 0, 'parents': ['Bid']},
	{'value': 'बिभिद्वस्', 'label': 'biBidvas', 'attrib': [{'pos': 'pfap', 'defs': 'split'}], 'isLemma': 0, 'parents': ['Bid']},
	{'value': 'बिभीवस्', 'label': 'biBIvas', 'attrib': [{'pos': 'pfap', 'defs': 'fear'}], 'isLemma': 0, 'parents': ['BI']},
	{'value': 'बिभ्यत्', 'label': 'biByat', 'attrib': [{'pos': 'prap', 'defs': 'fear'}], 'isLemma': 0, 'parents': ['BI']},
	{'value': 'बिभ्यान', 'label': 'biByAna', 'attrib': [{'pos': 'pfpp', 'defs': 'fear'}], 'isLemma': 0, 'parents': ['BI']},
	{'value': 'बिभ्रत्', 'label': 'biBrat', 'attrib': [{'pos': 'prap', 'defs': 'bear'}], 'isLemma': 0, 'parents': ['Bf']},
	{'value': 'बिभ्राण', 'label': 'biBrARa', 'attrib': [{'pos': 'prmp', 'defs': 'bear'}], 'isLemma': 0, 'parents': ['Bf']},
	{'value': 'बीभत्सु', 'label': 'bIBatsu', 'attrib': [{'pos': 'adj', 'defs': 'despising, loathsome, reserved, coy'}], 'isLemma': 1},
	{'value': 'बुद्ध', 'label': 'budDa', 'attrib': [{'pos': 'ppp', 'defs': 'be aware, know'}], 'isLemma': 0, 'parents': ['buD']},
	{'value': 'बुद्धवत्', 'label': 'budDavat', 'attrib': [{'pos': 'pap', 'defs': 'be aware, know'}], 'isLemma': 0, 'parents': ['buD']},
	{'value': 'बुद्धि', 'label': 'budDi', 'attrib': [{'pos': 'f', 'defs': 'intelligence'}], 'isLemma': 1},
	{'value': 'बुद्ध्वा', 'label': 'budDvA', 'attrib': [{'pos': 'abs', 'defs': 'be aware, know'}], 'isLemma': 0, 'parents': ['buD']},
	{'value': 'बुध्', 'label': 'buD', 'attrib': [{'pos': 'vt1am', 'defs': 'know'}, {'pos': 'vt4m', 'defs': 'be aware, know'}], 'isLemma': 1},
	{'value': 'बुधित', 'label': 'buDita', 'attrib': [{'pos': 'ppp', 'defs': 'know'}], 'isLemma': 0, 'parents': ['buD']},
	{'value': 'बुधितवत्', 'label': 'buDitavat', 'attrib': [{'pos': 'pap', 'defs': 'know'}], 'isLemma': 0, 'parents': ['buD']},
	{'value': 'बुधित्वा', 'label': 'buDitvA', 'attrib': [{'pos': 'abs', 'defs': 'know'}], 'isLemma': 0, 'parents': ['buD']},
	{'value': 'बुध्यमान', 'label': 'buDyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'know'}, {'pos': 'prmp', 'defs': 'be aware, know'}, {'pos': 'prpp', 'defs': 'be aware, know'}], 'isLemma': 0, 'parents': ['buD']},
	{'value': 'बुबुधान', 'label': 'bubuDAna', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'know'}, {'pos': 'pfmp, pfpp', 'defs': 'be aware, know'}], 'isLemma': 0, 'parents': ['buD']},
	{'value': 'बुबुध्वस्', 'label': 'bubuDvas', 'attrib': [{'pos': 'pfap', 'defs': 'know'}], 'isLemma': 0, 'parents': ['buD']},
	{'value': 'बोद्धव्य', 'label': 'bodDavya', 'attrib': [{'pos': 'ger', 'defs': 'be aware, know'}], 'isLemma': 0, 'parents': ['buD']},
	{'value': 'बोद्धुम्', 'label': 'bodDum', 'attrib': [{'pos': 'inf', 'defs': 'be aware, know'}], 'isLemma': 0, 'parents': ['buD']},
	{'value': 'बोधत्', 'label': 'boDat', 'attrib': [{'pos': 'prap', 'defs': 'know'}], 'isLemma': 0, 'parents': ['buD']},
	{'value': 'बोधनीय', 'label': 'boDanIya', 'attrib': [{'pos': 'ger', 'defs': 'know'}, {'pos': 'ger', 'defs': 'be aware, know'}], 'isLemma': 0, 'parents': ['buD']},
	{'value': 'बोधमान', 'label': 'boDamAna', 'attrib': [{'pos': 'prmp', 'defs': 'know'}], 'isLemma': 0, 'parents': ['buD']},
	{'value': 'बोधितव्य', 'label': 'boDitavya', 'attrib': [{'pos': 'ger', 'defs': 'know'}], 'isLemma': 0, 'parents': ['buD']},
	{'value': 'बोधितुम्', 'label': 'boDitum', 'attrib': [{'pos': 'inf', 'defs': 'know'}], 'isLemma': 0, 'parents': ['buD']},
	{'value': 'बोधित्वा', 'label': 'boDitvA', 'attrib': [{'pos': 'abs', 'defs': 'know'}], 'isLemma': 0, 'parents': ['buD']},
	{'value': 'बोध्य', 'label': 'boDya', 'attrib': [{'pos': 'ger', 'defs': 'know'}, {'pos': 'ger', 'defs': 'be aware, know'}], 'isLemma': 0, 'parents': ['buD']},
	{'value': 'ब्रह्मन्', 'label': 'brahman', 'attrib': [{'pos': 'm', 'defs': 'a brāhmaṇa'}], 'isLemma': 1},
	{'value': 'ब्रह्मरूप', 'label': 'brahmarUpa', 'attrib': [{'pos': 'n', 'defs': 'appearance of a brahmin'}], 'isLemma': 1},
	{'value': 'ब्रह्मरूपधर', 'label': 'brahmarUpaDara', 'attrib': [{'pos': 'adj', 'defs': 'bearing the appearance of a brahmin'}], 'isLemma': 1},
	{'value': 'ब्राह्मण', 'label': 'brAhmaRa', 'attrib': [{'pos': 'm', 'defs': 'a brahmin'}], 'isLemma': 1},
	{'value': 'ब्राह्मणार्थ', 'label': 'brAhmaRArTa', 'attrib': [{'pos': 'm', 'defs': 'the purpose of a brāhmaṇa'}], 'isLemma': 1},
	{'value': 'भक्त', 'label': 'Bakta', 'attrib': [{'pos': 'ppp', 'defs': 'partake of, share, love, serve'}], 'isLemma': 0, 'parents': ['Baj']},
	{'value': 'भक्तवत्', 'label': 'Baktavat', 'attrib': [{'pos': 'pap', 'defs': 'partake of, share, love, serve'}], 'isLemma': 0, 'parents': ['Baj']},
	{'value': 'भक्तव्य', 'label': 'Baktavya', 'attrib': [{'pos': 'ger', 'defs': 'partake of, share, love, serve'}], 'isLemma': 0, 'parents': ['Baj']},
	{'value': 'भक्तुम्', 'label': 'Baktum', 'attrib': [{'pos': 'inf', 'defs': 'partake of, share, love, serve'}], 'isLemma': 0, 'parents': ['Baj']},
	{'value': 'भक्ष्यत्', 'label': 'Bakzyat', 'attrib': [{'pos': 'fap', 'defs': 'partake of, share, love, serve'}], 'isLemma': 0, 'parents': ['Baj']},
	{'value': 'भक्ष्यमान', 'label': 'BakzyamAna', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'partake of, share, love, serve'}], 'isLemma': 0, 'parents': ['Baj']},
	{'value': 'भज्', 'label': 'Baj', 'attrib': [{'pos': 'vt1am', 'defs': 'partake of, share, love, serve'}], 'isLemma': 1},
	{'value': 'भजत्', 'label': 'Bajat', 'attrib': [{'pos': 'prap', 'defs': 'partake of, share, love, serve'}], 'isLemma': 0, 'parents': ['Baj']},
	{'value': 'भजनीय', 'label': 'BajanIya', 'attrib': [{'pos': 'ger', 'defs': 'partake of, share, love, serve'}], 'isLemma': 0, 'parents': ['Baj']},
	{'value': 'भजमान', 'label': 'BajamAna', 'attrib': [{'pos': 'prmp', 'defs': 'partake of, share, love, serve'}], 'isLemma': 0, 'parents': ['Baj']},
	{'value': 'भज्य', 'label': 'Bajya', 'attrib': [{'pos': 'ger', 'defs': 'partake of, share, love, serve'}], 'isLemma': 0, 'parents': ['Baj']},
	{'value': 'भज्यमान', 'label': 'BajyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'partake of, share, love, serve'}], 'isLemma': 0, 'parents': ['Baj']},
	{'value': 'भद्र', 'label': 'Badra', 'attrib': [{'pos': 'adj', 'defs': 'blessed, good'}, {'pos': 'n', 'defs': 'welfare'}], 'isLemma': 1},
	{'value': 'भद्रभाषिन्', 'label': 'BadraBAzin', 'attrib': [{'pos': 'adj', 'defs': 'speaking what is auspicious'}], 'isLemma': 1},
	{'value': 'भन्त्स्यत्', 'label': 'Bantsyat', 'attrib': [{'pos': 'fap', 'defs': 'bind'}], 'isLemma': 0, 'parents': ['banD']},
	{'value': 'भन्त्स्यमान', 'label': 'BantsyamAna', 'attrib': [{'pos': 'fpp', 'defs': 'bind'}], 'isLemma': 0, 'parents': ['banD']},
	{'value': 'भय', 'label': 'Baya', 'attrib': [{'pos': 'n', 'defs': 'fear'}], 'isLemma': 1},
	{'value': 'भयनीय', 'label': 'BayanIya', 'attrib': [{'pos': 'ger', 'defs': 'fear'}], 'isLemma': 0, 'parents': ['BI']},
	{'value': 'भर', 'label': 'Bara', 'attrib': [{'pos': 'adj', 'defs': 'bearing'}], 'isLemma': 1},
	{'value': 'भरणीय', 'label': 'BaraRIya', 'attrib': [{'pos': 'ger', 'defs': 'bear'}], 'isLemma': 0, 'parents': ['Bf']},
	{'value': 'भरत', 'label': 'Barata', 'attrib': [{'pos': 'm_pn', 'defs': 'Bharata'}], 'isLemma': 1},
	{'value': 'भरतर्षभ', 'label': 'BaratarzaBa', 'attrib': [{'pos': 'm', 'defs': 'bull-like descendant of Bharata'}], 'isLemma': 1},
	{'value': 'भरिष्यत्', 'label': 'Barizyat', 'attrib': [{'pos': 'fap', 'defs': 'bear'}], 'isLemma': 0, 'parents': ['Bf']},
	{'value': 'भरिष्यमाण', 'label': 'BarizyamARa', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'bear'}], 'isLemma': 0, 'parents': ['Bf']},
	{'value': 'भर्तव्य', 'label': 'Bartavya', 'attrib': [{'pos': 'ger', 'defs': 'bear'}], 'isLemma': 0, 'parents': ['Bf']},
	{'value': 'भर्तुम्', 'label': 'Bartum', 'attrib': [{'pos': 'inf', 'defs': 'bear'}], 'isLemma': 0, 'parents': ['Bf']},
	{'value': 'भवत्', 'label': 'Bavat', 'attrib': [{'pos': 'prap', 'defs': 'be, become'}], 'isLemma': 0, 'parents': ['BU']},
	{'value': 'भवनीय', 'label': 'BavanIya', 'attrib': [{'pos': 'ger', 'defs': 'be, become'}], 'isLemma': 0, 'parents': ['BU']},
	{'value': 'भवितव्य', 'label': 'Bavitavya', 'attrib': [{'pos': 'ger', 'defs': 'be, become'}], 'isLemma': 0, 'parents': ['BU']},
	{'value': 'भवितुम्', 'label': 'Bavitum', 'attrib': [{'pos': 'inf', 'defs': 'be, become'}], 'isLemma': 0, 'parents': ['BU']},
	{'value': 'भविष्यत्', 'label': 'Bavizyat', 'attrib': [{'pos': 'fap', 'defs': 'be, become'}], 'isLemma': 0, 'parents': ['BU']},
	{'value': 'भविष्यमाण', 'label': 'BavizyamARa', 'attrib': [{'pos': 'fpp', 'defs': 'be, become'}], 'isLemma': 0, 'parents': ['BU']},
	{'value': 'भव्य', 'label': 'Bavya', 'attrib': [{'pos': 'ger', 'defs': 'be, become'}], 'isLemma': 0, 'parents': ['BU']},
	{'value': 'भा', 'label': 'BA', 'attrib': [{'pos': 'vi2a', 'defs': 'shine, appear'}], 'isLemma': 1},
	{'value': 'भाक्त्वा', 'label': 'BAktvA', 'attrib': [{'pos': 'abs', 'defs': 'partake of, share, love, serve'}], 'isLemma': 0, 'parents': ['Baj']},
	{'value': 'भात्', 'label': 'BAt', 'attrib': [{'pos': 'prap', 'defs': 'shine, appear'}], 'isLemma': 0, 'parents': ['BA']},
	{'value': 'भात', 'label': 'BAta', 'attrib': [{'pos': 'ppp', 'defs': 'shine, appear'}], 'isLemma': 0, 'parents': ['BA']},
	{'value': 'भातवत्', 'label': 'BAtavat', 'attrib': [{'pos': 'pap', 'defs': 'shine, appear'}], 'isLemma': 0, 'parents': ['BA']},
	{'value': 'भातव्य', 'label': 'BAtavya', 'attrib': [{'pos': 'ger', 'defs': 'shine, appear'}], 'isLemma': 0, 'parents': ['BA']},
	{'value': 'भातुम्', 'label': 'BAtum', 'attrib': [{'pos': 'inf', 'defs': 'shine, appear'}], 'isLemma': 0, 'parents': ['BA']},
	{'value': 'भानीय', 'label': 'BAnIya', 'attrib': [{'pos': 'ger', 'defs': 'shine, appear'}], 'isLemma': 0, 'parents': ['BA']},
	{'value': 'भायमान', 'label': 'BAyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'shine, appear'}], 'isLemma': 0, 'parents': ['BA']},
	{'value': 'भार', 'label': 'BAra', 'attrib': [{'pos': 'm', 'defs': 'burden'}], 'isLemma': 1},
	{'value': 'भारत', 'label': 'BArata', 'attrib': [{'pos': 'm', 'defs': 'descendant of Bharata'}, {'pos': 'adj', 'defs': 'the war between the descendants of Bharata'}], 'isLemma': 1},
	{'value': 'भार्या', 'label': 'BAryA', 'attrib': [{'pos': 'f', 'defs': 'wife'}], 'isLemma': 1},
	{'value': 'भाविन्', 'label': 'BAvin', 'attrib': [{'pos': 'adj', 'defs': 'future'}], 'isLemma': 1},
	{'value': 'भाव्यर्थ', 'label': 'BAvyarTa', 'attrib': [{'pos': 'm', 'defs': 'future purpose'}], 'isLemma': 1},
	{'value': 'भाव्यर्थरक्षित', 'label': 'BAvyarTarakzita', 'attrib': [{'pos': 'adj', 'defs': 'protected for future purposes'}], 'isLemma': 1},
	{'value': 'भाष्', 'label': 'BAz', 'attrib': [{'pos': 'vt1m', 'defs': 'speak, say'}], 'isLemma': 1},
	{'value': 'भाषणीय', 'label': 'BAzaRIya', 'attrib': [{'pos': 'ger', 'defs': 'speak, say'}], 'isLemma': 0, 'parents': ['BAz']},
	{'value': 'भाषमाण', 'label': 'BAzamARa', 'attrib': [{'pos': 'prmp', 'defs': 'speak, say'}], 'isLemma': 0, 'parents': ['BAz']},
	{'value': 'भाषित', 'label': 'BAzita', 'attrib': [{'pos': 'ppp', 'defs': 'speak, say'}], 'isLemma': 0, 'parents': ['BAz']},
	{'value': 'भाषितवत्', 'label': 'BAzitavat', 'attrib': [{'pos': 'pap', 'defs': 'speak, say'}], 'isLemma': 0, 'parents': ['BAz']},
	{'value': 'भाषितव्य', 'label': 'BAzitavya', 'attrib': [{'pos': 'ger', 'defs': 'speak, say'}], 'isLemma': 0, 'parents': ['BAz']},
	{'value': 'भाषितुम्', 'label': 'BAzitum', 'attrib': [{'pos': 'inf', 'defs': 'speak, say'}], 'isLemma': 0, 'parents': ['BAz']},
	{'value': 'भाषित्वा', 'label': 'BAzitvA', 'attrib': [{'pos': 'abs', 'defs': 'speak, say'}], 'isLemma': 0, 'parents': ['BAz']},
	{'value': 'भाषिन्', 'label': 'BAzin', 'attrib': [{'pos': 'adj', 'defs': 'speaking'}], 'isLemma': 1},
	{'value': 'भाषिष्यमाण', 'label': 'BAzizyamARa', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'speak, say'}], 'isLemma': 0, 'parents': ['BAz']},
	{'value': 'भाष्य', 'label': 'BAzya', 'attrib': [{'pos': 'ger', 'defs': 'speak, say'}], 'isLemma': 0, 'parents': ['BAz']},
	{'value': 'भाष्यमाण', 'label': 'BAzyamARa', 'attrib': [{'pos': 'prpp', 'defs': 'speak, say'}], 'isLemma': 0, 'parents': ['BAz']},
	{'value': 'भास्यत्', 'label': 'BAsyat', 'attrib': [{'pos': 'fap', 'defs': 'shine, appear'}], 'isLemma': 0, 'parents': ['BA']},
	{'value': 'भास्यमान', 'label': 'BAsyamAna', 'attrib': [{'pos': 'fpp', 'defs': 'shine, appear'}], 'isLemma': 0, 'parents': ['BA']},
	{'value': 'भित्त्वा', 'label': 'BittvA', 'attrib': [{'pos': 'abs', 'defs': 'split'}], 'isLemma': 0, 'parents': ['Bid']},
	{'value': 'भिद्', 'label': 'Bid', 'attrib': [{'pos': 'vt7am', 'defs': 'split'}], 'isLemma': 1},
	{'value': 'भिद्यमान', 'label': 'BidyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'split'}], 'isLemma': 0, 'parents': ['Bid']},
	{'value': 'भिन्दत्', 'label': 'Bindat', 'attrib': [{'pos': 'prap', 'defs': 'split'}], 'isLemma': 0, 'parents': ['Bid']},
	{'value': 'भिन्दान', 'label': 'BindAna', 'attrib': [{'pos': 'prmp', 'defs': 'split'}], 'isLemma': 0, 'parents': ['Bid']},
	{'value': 'भिन्न', 'label': 'Binna', 'attrib': [{'pos': 'ppp', 'defs': 'split'}], 'isLemma': 0, 'parents': ['Bid']},
	{'value': 'भिन्नवत्', 'label': 'Binnavat', 'attrib': [{'pos': 'pap', 'defs': 'split'}], 'isLemma': 0, 'parents': ['Bid']},
	{'value': 'भी', 'label': 'BI', 'attrib': [{'pos': 'vi3a', 'defs': 'fear'}], 'isLemma': 1},
	{'value': 'भीत', 'label': 'BIta', 'attrib': [{'pos': 'ppp', 'defs': 'fear'}], 'isLemma': 0, 'parents': ['BI']},
	{'value': 'भीतवत्', 'label': 'BItavat', 'attrib': [{'pos': 'pap', 'defs': 'fear'}], 'isLemma': 0, 'parents': ['BI']},
	{'value': 'भीत्वा', 'label': 'BItvA', 'attrib': [{'pos': 'abs', 'defs': 'fear'}], 'isLemma': 0, 'parents': ['BI']},
	{'value': 'भीम', 'label': 'BIma', 'attrib': [{'pos': 'adj', 'defs': 'frightful, terrifying'}, {'pos': 'm_pn', 'defs': 'Bhīma'}], 'isLemma': 1},
	{'value': 'भीमपराक्रम', 'label': 'BImaparAkrama', 'attrib': [{'pos': 'adj', 'defs': 'terrifyingly bold'}], 'isLemma': 1},
	{'value': 'भीमसेन', 'label': 'BImasena', 'attrib': [{'pos': 'adj', 'defs': 'having a terrifying army'}], 'isLemma': 1},
	{'value': 'भीयमान', 'label': 'BIyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'fear'}], 'isLemma': 0, 'parents': ['BI']},
	{'value': 'भीष्म', 'label': 'BIzma', 'attrib': [{'pos': 'adj', 'defs': 'terrifying'}, {'pos': 'm_pn', 'defs': 'Bhīṣma'}], 'isLemma': 1},
	{'value': 'भू', 'label': 'BU', 'attrib': [{'pos': 'vi1a', 'defs': 'be, become'}, {'pos': 'f', 'defs': 'earth'}], 'isLemma': 1},
	{'value': 'भूत', 'label': 'BUta', 'attrib': [{'pos': 'ppp', 'defs': 'be, become'}], 'isLemma': 0, 'parents': ['BU']},
	{'value': 'भूतवत्', 'label': 'BUtavat', 'attrib': [{'pos': 'pap', 'defs': 'be, become'}], 'isLemma': 0, 'parents': ['BU']},
	{'value': 'भूत्वा', 'label': 'BUtvA', 'attrib': [{'pos': 'abs', 'defs': 'be, become'}], 'isLemma': 0, 'parents': ['BU']},
	{'value': 'भूयमान', 'label': 'BUyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'be, become'}], 'isLemma': 0, 'parents': ['BU']},
	{'value': 'भूयस्', 'label': 'BUyas', 'attrib': [{'pos': 'comparative_adj', 'defs': 'more'}], 'isLemma': 1},
	{'value': 'भूयिष्ठ', 'label': 'BUyizWa', 'attrib': [{'pos': 'superlative_adj', 'defs': 'most'}], 'isLemma': 1},
	{'value': 'भृ', 'label': 'Bf', 'attrib': [{'pos': 'vt3am', 'defs': 'bear'}], 'isLemma': 1},
	{'value': 'भृत', 'label': 'Bfta', 'attrib': [{'pos': 'ppp', 'defs': 'bear'}], 'isLemma': 0, 'parents': ['Bf']},
	{'value': 'भृतवत्', 'label': 'Bftavat', 'attrib': [{'pos': 'pap', 'defs': 'bear'}], 'isLemma': 0, 'parents': ['Bf']},
	{'value': 'भृत्य', 'label': 'Bftya', 'attrib': [{'pos': 'ger', 'defs': 'bear'}], 'isLemma': 0, 'parents': ['Bf']},
	{'value': 'भृत्वा', 'label': 'BftvA', 'attrib': [{'pos': 'abs', 'defs': 'bear'}], 'isLemma': 0, 'parents': ['Bf']},
	{'value': 'भेजान', 'label': 'BejAna', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'partake of, share, love, serve'}], 'isLemma': 0, 'parents': ['Baj']},
	{'value': 'भेजिवस्', 'label': 'Bejivas', 'attrib': [{'pos': 'pfap', 'defs': 'partake of, share, love, serve'}], 'isLemma': 0, 'parents': ['Baj']},
	{'value': 'भेतव्य', 'label': 'Betavya', 'attrib': [{'pos': 'ger', 'defs': 'fear'}], 'isLemma': 0, 'parents': ['BI']},
	{'value': 'भेतुम्', 'label': 'Betum', 'attrib': [{'pos': 'inf', 'defs': 'fear'}], 'isLemma': 0, 'parents': ['BI']},
	{'value': 'भेत्तव्य', 'label': 'Bettavya', 'attrib': [{'pos': 'ger', 'defs': 'split'}], 'isLemma': 0, 'parents': ['Bid']},
	{'value': 'भेत्तुम्', 'label': 'Bettum', 'attrib': [{'pos': 'inf', 'defs': 'split'}], 'isLemma': 0, 'parents': ['Bid']},
	{'value': 'भेत्स्यत्', 'label': 'Betsyat', 'attrib': [{'pos': 'fap', 'defs': 'split'}], 'isLemma': 0, 'parents': ['Bid']},
	{'value': 'भेत्स्यमान', 'label': 'BetsyamAna', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'split'}], 'isLemma': 0, 'parents': ['Bid']},
	{'value': 'भेद', 'label': 'Beda', 'attrib': [{'pos': 'm', 'defs': 'split, division, breach'}], 'isLemma': 1},
	{'value': 'भेदनीय', 'label': 'BedanIya', 'attrib': [{'pos': 'ger', 'defs': 'split'}], 'isLemma': 0, 'parents': ['Bid']},
	{'value': 'भेद्य', 'label': 'Bedya', 'attrib': [{'pos': 'ger', 'defs': 'split'}], 'isLemma': 0, 'parents': ['Bid']},
	{'value': 'भेय', 'label': 'Beya', 'attrib': [{'pos': 'ger', 'defs': 'fear'}], 'isLemma': 0, 'parents': ['BI']},
	{'value': 'भेष्यत्', 'label': 'Bezyat', 'attrib': [{'pos': 'fap', 'defs': 'fear'}], 'isLemma': 0, 'parents': ['BI']},
	{'value': 'भेष्यमाण', 'label': 'BezyamARa', 'attrib': [{'pos': 'fpp', 'defs': 'fear'}], 'isLemma': 0, 'parents': ['BI']},
	{'value': 'भोत्स्यत्', 'label': 'Botsyat', 'attrib': [{'pos': 'fap', 'defs': 'know'}], 'isLemma': 0, 'parents': ['buD']},
	{'value': 'भोत्स्यमान', 'label': 'BotsyamAna', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'know'}, {'pos': 'fmp, fpp', 'defs': 'be aware, know'}], 'isLemma': 0, 'parents': ['buD']},
	{'value': 'भ्रातृ', 'label': 'BrAtf', 'attrib': [{'pos': 'm', 'defs': 'brother'}], 'isLemma': 1},
	{'value': 'भ्रियमाण', 'label': 'BriyamARa', 'attrib': [{'pos': 'prpp', 'defs': 'bear'}], 'isLemma': 0, 'parents': ['Bf']},
	{'value': 'मंस्यमान', 'label': 'maMsyamAna', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'think'}], 'isLemma': 0, 'parents': ['man']},
	{'value': 'मत', 'label': 'mata', 'attrib': [{'pos': 'n', 'defs': 'thought'}, {'pos': 'ppp', 'defs': 'think'}], 'isLemma': 1},
	{'value': 'मत', 'label': 'mata', 'attrib': [{'pos': 'n', 'defs': 'thought'}, {'pos': 'ppp', 'defs': 'think'}], 'isLemma': 0, 'parents': ['man']},
	{'value': 'मतवत्', 'label': 'matavat', 'attrib': [{'pos': 'pap', 'defs': 'think'}], 'isLemma': 0, 'parents': ['man']},
	{'value': 'मति', 'label': 'mati', 'attrib': [{'pos': 'f', 'defs': 'thought'}], 'isLemma': 1},
	{'value': 'मत्त', 'label': 'matta', 'attrib': [{'pos': 'ppp', 'defs': 'rejoice'}], 'isLemma': 0, 'parents': ['mad']},
	{'value': 'मत्तवत्', 'label': 'mattavat', 'attrib': [{'pos': 'pap', 'defs': 'rejoice'}], 'isLemma': 0, 'parents': ['mad']},
	{'value': 'मत्वा', 'label': 'matvA', 'attrib': [{'pos': 'abs', 'defs': 'think'}], 'isLemma': 0, 'parents': ['man']},
	{'value': 'मद्', 'label': 'mad', 'attrib': [{'pos': 'pers_pron', 'defs': 'I, me'}, {'pos': 'vi4a', 'defs': 'rejoice'}], 'isLemma': 1},
	{'value': 'मदनीय', 'label': 'madanIya', 'attrib': [{'pos': 'ger', 'defs': 'rejoice'}], 'isLemma': 0, 'parents': ['mad']},
	{'value': 'मदितव्य', 'label': 'maditavya', 'attrib': [{'pos': 'ger', 'defs': 'rejoice'}], 'isLemma': 0, 'parents': ['mad']},
	{'value': 'मदितुम्', 'label': 'maditum', 'attrib': [{'pos': 'inf', 'defs': 'rejoice'}], 'isLemma': 0, 'parents': ['mad']},
	{'value': 'मदित्वा', 'label': 'maditvA', 'attrib': [{'pos': 'abs', 'defs': 'rejoice'}], 'isLemma': 0, 'parents': ['mad']},
	{'value': 'मदिष्यत्', 'label': 'madizyat', 'attrib': [{'pos': 'fap', 'defs': 'rejoice'}], 'isLemma': 0, 'parents': ['mad']},
	{'value': 'मदिष्यमाण', 'label': 'madizyamARa', 'attrib': [{'pos': 'fpp', 'defs': 'rejoice'}], 'isLemma': 0, 'parents': ['mad']},
	{'value': 'मद्य', 'label': 'madya', 'attrib': [{'pos': 'ger', 'defs': 'rejoice'}], 'isLemma': 0, 'parents': ['mad']},
	{'value': 'मद्यमान', 'label': 'madyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'rejoice'}], 'isLemma': 0, 'parents': ['mad']},
	{'value': 'मन्', 'label': 'man', 'attrib': [{'pos': 'vt4m, vt8m', 'defs': 'think'}], 'isLemma': 1},
	{'value': 'मननीय', 'label': 'mananIya', 'attrib': [{'pos': 'ger', 'defs': 'think'}], 'isLemma': 0, 'parents': ['man']},
	{'value': 'मनस्', 'label': 'manas', 'attrib': [{'pos': 'n', 'defs': 'mind'}], 'isLemma': 1},
	{'value': 'मनीय', 'label': 'manIya', 'attrib': [{'pos': 'ger', 'defs': 'measure, make'}], 'isLemma': 0, 'parents': ['mA']},
	{'value': 'मनोबुद्धिसमाधि', 'label': 'manobudDisamADi', 'attrib': [{'pos': 'm', 'defs': 'mind, intellect and settled awareness'}], 'isLemma': 1},
	{'value': 'मन्तव्य', 'label': 'mantavya', 'attrib': [{'pos': 'ger', 'defs': 'think'}], 'isLemma': 0, 'parents': ['man']},
	{'value': 'मन्तुम्', 'label': 'mantum', 'attrib': [{'pos': 'inf', 'defs': 'think'}], 'isLemma': 0, 'parents': ['man']},
	{'value': 'मन्त्र्', 'label': 'mantr', 'attrib': [{'pos': 'v10m', 'defs': 'consult'}], 'isLemma': 1},
	{'value': 'मन्त्र', 'label': 'mantra', 'attrib': [{'pos': 'm', 'defs': 'instrument of thought, sacred text, counsel'}], 'isLemma': 1},
	{'value': 'मन्त्रणीय', 'label': 'mantraRIya', 'attrib': [{'pos': 'ger', 'defs': 'consult'}], 'isLemma': 0, 'parents': ['mantr']},
	{'value': 'मन्त्रयमान', 'label': 'mantrayamAna', 'attrib': [{'pos': 'prmp', 'defs': 'consult'}], 'isLemma': 0, 'parents': ['mantr']},
	{'value': 'मन्त्रयाम्', 'label': 'mantrayAm', 'attrib': [{'pos': 'ppf_actn', 'defs': 'consult'}], 'isLemma': 0, 'parents': ['mantr']},
	{'value': 'मन्त्रयितव्य', 'label': 'mantrayitavya', 'attrib': [{'pos': 'ger', 'defs': 'consult'}], 'isLemma': 0, 'parents': ['mantr']},
	{'value': 'मन्त्रयितुम्', 'label': 'mantrayitum', 'attrib': [{'pos': 'inf', 'defs': 'consult'}], 'isLemma': 0, 'parents': ['mantr']},
	{'value': 'मन्त्रयित्वा', 'label': 'mantrayitvA', 'attrib': [{'pos': 'abs', 'defs': 'consult'}], 'isLemma': 0, 'parents': ['mantr']},
	{'value': 'मन्त्रयिष्यमाण', 'label': 'mantrayizyamARa', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'consult'}], 'isLemma': 0, 'parents': ['mantr']},
	{'value': 'मन्त्रित', 'label': 'mantrita', 'attrib': [{'pos': 'ppp', 'defs': 'consult'}], 'isLemma': 0, 'parents': ['mantr']},
	{'value': 'मन्त्रितवत्', 'label': 'mantritavat', 'attrib': [{'pos': 'pap', 'defs': 'consult'}], 'isLemma': 0, 'parents': ['mantr']},
	{'value': 'मन्त्र्य', 'label': 'mantrya', 'attrib': [{'pos': 'ger', 'defs': 'consult'}], 'isLemma': 0, 'parents': ['mantr']},
	{'value': 'मन्त्र्यमान', 'label': 'mantryamAna', 'attrib': [{'pos': 'prpp', 'defs': 'consult'}], 'isLemma': 0, 'parents': ['mantr']},
	{'value': 'मन्द', 'label': 'manda', 'attrib': [{'pos': 'adj', 'defs': 'slow, stupid'}], 'isLemma': 1},
	{'value': 'मन्दिर', 'label': 'mandira', 'attrib': [{'pos': 'n', 'defs': 'house, palace, temple'}], 'isLemma': 1},
	{'value': 'मन्यमान', 'label': 'manyamAna', 'attrib': [{'pos': 'prmp', 'defs': 'think'}, {'pos': 'prpp', 'defs': 'think'}], 'isLemma': 0, 'parents': ['man']},
	{'value': 'मन्यु', 'label': 'manyu', 'attrib': [{'pos': 'm', 'defs': 'spirit, fury, anger'}], 'isLemma': 1},
	{'value': 'मन्वान', 'label': 'manvAna', 'attrib': [{'pos': 'prmp', 'defs': 'think'}], 'isLemma': 0, 'parents': ['man']},
	{'value': 'ममाण', 'label': 'mamARa', 'attrib': [{'pos': 'pfpp', 'defs': 'measure, make'}], 'isLemma': 0, 'parents': ['mA']},
	{'value': 'ममृवस्', 'label': 'mamfvas', 'attrib': [{'pos': 'pfap', 'defs': 'die'}], 'isLemma': 0, 'parents': ['mf']},
	{'value': 'ममृषान', 'label': 'mamfzAna', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'forget, tolerate'}], 'isLemma': 0, 'parents': ['mfz']},
	{'value': 'ममृषिवस्', 'label': 'mamfzivas', 'attrib': [{'pos': 'pfap', 'defs': 'forget, tolerate'}], 'isLemma': 0, 'parents': ['mfz']},
	{'value': 'मम्राण', 'label': 'mamrARa', 'attrib': [{'pos': 'pfpp', 'defs': 'die'}], 'isLemma': 0, 'parents': ['mf']},
	{'value': 'मय', 'label': 'maya', 'attrib': [{'pos': 'm_pn', 'defs': 'Maya'}], 'isLemma': 1},
	{'value': 'मरणीय', 'label': 'maraRIya', 'attrib': [{'pos': 'ger', 'defs': 'die'}], 'isLemma': 0, 'parents': ['mf']},
	{'value': 'मरिष्यत्', 'label': 'marizyat', 'attrib': [{'pos': 'fap', 'defs': 'die'}], 'isLemma': 0, 'parents': ['mf']},
	{'value': 'मरिष्यमाण', 'label': 'marizyamARa', 'attrib': [{'pos': 'fpp', 'defs': 'die'}], 'isLemma': 0, 'parents': ['mf']},
	{'value': 'मर्तव्य', 'label': 'martavya', 'attrib': [{'pos': 'ger', 'defs': 'die'}], 'isLemma': 0, 'parents': ['mf']},
	{'value': 'मर्तुम्', 'label': 'martum', 'attrib': [{'pos': 'inf', 'defs': 'die'}], 'isLemma': 0, 'parents': ['mf']},
	{'value': 'मर्षणीय', 'label': 'marzaRIya', 'attrib': [{'pos': 'ger', 'defs': 'forget, tolerate'}], 'isLemma': 0, 'parents': ['mfz']},
	{'value': 'मर्षित', 'label': 'marzita', 'attrib': [{'pos': 'ppp', 'defs': 'forget, tolerate'}], 'isLemma': 0, 'parents': ['mfz']},
	{'value': 'मर्षितवत्', 'label': 'marzitavat', 'attrib': [{'pos': 'pap', 'defs': 'forget, tolerate'}], 'isLemma': 0, 'parents': ['mfz']},
	{'value': 'मर्षितव्य', 'label': 'marzitavya', 'attrib': [{'pos': 'ger', 'defs': 'forget, tolerate'}], 'isLemma': 0, 'parents': ['mfz']},
	{'value': 'मर्षितुम्', 'label': 'marzitum', 'attrib': [{'pos': 'inf', 'defs': 'forget, tolerate'}], 'isLemma': 0, 'parents': ['mfz']},
	{'value': 'मर्षित्वा', 'label': 'marzitvA', 'attrib': [{'pos': 'abs', 'defs': 'forget, tolerate'}], 'isLemma': 0, 'parents': ['mfz']},
	{'value': 'मर्षिष्यत्', 'label': 'marzizyat', 'attrib': [{'pos': 'fap', 'defs': 'forget, tolerate'}], 'isLemma': 0, 'parents': ['mfz']},
	{'value': 'मर्षिष्यमाण', 'label': 'marzizyamARa', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'forget, tolerate'}], 'isLemma': 0, 'parents': ['mfz']},
	{'value': 'मह्', 'label': 'mah', 'attrib': [{'pos': 'vi1m', 'defs': 'increase'}, {'pos': 'vt10a', 'defs': 'magnify, exalt'}], 'isLemma': 1},
	{'value': 'महत्', 'label': 'mahat', 'attrib': [{'pos': 'adj', 'defs': 'great'}], 'isLemma': 1},
	{'value': 'महर्षि', 'label': 'maharzi', 'attrib': [{'pos': 'm', 'defs': 'great sage'}], 'isLemma': 1},
	{'value': 'महापथ', 'label': 'mahApaTa', 'attrib': [{'pos': 'm', 'defs': 'great path, road'}], 'isLemma': 1},
	{'value': 'महाबल', 'label': 'mahAbala', 'attrib': [{'pos': 'adj', 'defs': 'very strong'}], 'isLemma': 1},
	{'value': 'महाभारत', 'label': 'mahABArata', 'attrib': [{'pos': 'n', 'defs': 'the great war between the descendants of Bharata'}], 'isLemma': 1},
	{'value': 'महामति', 'label': 'mahAmati', 'attrib': [{'pos': 'adj', 'defs': 'great-minded'}], 'isLemma': 1},
	{'value': 'महाराज', 'label': 'mahArAja', 'attrib': [{'pos': 'm', 'defs': 'great king'}], 'isLemma': 1},
	{'value': 'महावन', 'label': 'mahAvana', 'attrib': [{'pos': 'n', 'defs': 'great forest'}], 'isLemma': 1},
	{'value': 'महासुर', 'label': 'mahAsura', 'attrib': [{'pos': 'm', 'defs': 'great demon'}], 'isLemma': 1},
	{'value': 'मही', 'label': 'mahI', 'attrib': [{'pos': 'f', 'defs': 'earth'}], 'isLemma': 1},
	{'value': 'महीक्षित्', 'label': 'mahIkzit', 'attrib': [{'pos': 'm', 'defs': 'ruling the earth, king'}], 'isLemma': 1},
	{'value': 'महेन्द्र', 'label': 'mahendra', 'attrib': [{'pos': 'm', 'defs': 'the great Indra'}], 'isLemma': 1},
	{'value': 'मा', 'label': 'mA', 'attrib': [{'pos': 'vt2a', 'defs': 'measure, make'}, {'pos': 'vt4m', 'defs': 'measure, make'}, {'pos': 'vt3m', 'defs': 'measure, make'}], 'isLemma': 1},
	{'value': 'मातव्य', 'label': 'mAtavya', 'attrib': [{'pos': 'ger', 'defs': 'measure, make'}], 'isLemma': 0, 'parents': ['mA']},
	{'value': 'मातुम्', 'label': 'mAtum', 'attrib': [{'pos': 'inf', 'defs': 'measure, make'}], 'isLemma': 0, 'parents': ['mA']},
	{'value': 'मातृ', 'label': 'mAtf', 'attrib': [{'pos': 'f', 'defs': 'mother'}], 'isLemma': 1},
	{'value': 'माद्यत्', 'label': 'mAdyat', 'attrib': [{'pos': 'prap', 'defs': 'rejoice'}], 'isLemma': 0, 'parents': ['mad']},
	{'value': 'मान्य', 'label': 'mAnya', 'attrib': [{'pos': 'ger', 'defs': 'think'}], 'isLemma': 0, 'parents': ['man']},
	{'value': 'मार्य', 'label': 'mArya', 'attrib': [{'pos': 'ger', 'defs': 'die'}], 'isLemma': 0, 'parents': ['mf']},
	{'value': 'मास्', 'label': 'mAs', 'attrib': [{'pos': 'm', 'defs': 'the moon, a month'}], 'isLemma': 1},
	{'value': 'मास', 'label': 'mAsa', 'attrib': [{'pos': 'm', 'defs': 'the moon, a month'}], 'isLemma': 1},
	{'value': 'मास्यमान', 'label': 'mAsyamAna', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'measure, make'}], 'isLemma': 0, 'parents': ['mA']},
	{'value': 'मित', 'label': 'mita', 'attrib': [{'pos': 'ppp', 'defs': 'measure, make'}], 'isLemma': 0, 'parents': ['mA']},
	{'value': 'मितवत्', 'label': 'mitavat', 'attrib': [{'pos': 'pap', 'defs': 'measure, make'}], 'isLemma': 0, 'parents': ['mA']},
	{'value': 'मित्वा', 'label': 'mitvA', 'attrib': [{'pos': 'abs', 'defs': 'measure, make'}], 'isLemma': 0, 'parents': ['mA']},
	{'value': 'मिमान', 'label': 'mimAna', 'attrib': [{'pos': 'prmp', 'defs': 'measure, make'}], 'isLemma': 0, 'parents': ['mA']},
	{'value': 'मीयमाण', 'label': 'mIyamARa', 'attrib': [{'pos': 'prpp', 'defs': 'measure, make'}], 'isLemma': 0, 'parents': ['mA']},
	{'value': 'मुक्त', 'label': 'mukta', 'attrib': [{'pos': 'ppp', 'defs': 'release'}], 'isLemma': 0, 'parents': ['muc']},
	{'value': 'मुक्तवत्', 'label': 'muktavat', 'attrib': [{'pos': 'pap', 'defs': 'release'}], 'isLemma': 0, 'parents': ['muc']},
	{'value': 'मुक्त्वा', 'label': 'muktvA', 'attrib': [{'pos': 'abs', 'defs': 'release'}], 'isLemma': 0, 'parents': ['muc']},
	{'value': 'मुच्', 'label': 'muc', 'attrib': [{'pos': 'vt6am', 'defs': 'release'}], 'isLemma': 1},
	{'value': 'मुच्यमान', 'label': 'mucyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'release'}], 'isLemma': 0, 'parents': ['muc']},
	{'value': 'मुञ्चत्', 'label': 'muYcat', 'attrib': [{'pos': 'prap', 'defs': 'release'}], 'isLemma': 0, 'parents': ['muc']},
	{'value': 'मुञ्चमान', 'label': 'muYcamAna', 'attrib': [{'pos': 'prmp', 'defs': 'release'}], 'isLemma': 0, 'parents': ['muc']},
	{'value': 'मुद्', 'label': 'mud', 'attrib': [{'pos': 'f', 'defs': 'joy'}, {'pos': 'vi1m', 'defs': 'rejoice'}], 'isLemma': 1},
	{'value': 'मुमुचान', 'label': 'mumucAna', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'release'}], 'isLemma': 0, 'parents': ['muc']},
	{'value': 'मुमुच्वस्', 'label': 'mumucvas', 'attrib': [{'pos': 'pfap', 'defs': 'release'}], 'isLemma': 0, 'parents': ['muc']},
	{'value': 'मृ', 'label': 'mf', 'attrib': [{'pos': 'vi6am', 'defs': 'die'}], 'isLemma': 1},
	{'value': 'मृत', 'label': 'mfta', 'attrib': [{'pos': 'ppp', 'defs': 'die'}], 'isLemma': 0, 'parents': ['mf']},
	{'value': 'मृतवत्', 'label': 'mftavat', 'attrib': [{'pos': 'pap', 'defs': 'die'}], 'isLemma': 0, 'parents': ['mf']},
	{'value': 'मृत्वा', 'label': 'mftvA', 'attrib': [{'pos': 'abs', 'defs': 'die'}], 'isLemma': 0, 'parents': ['mf']},
	{'value': 'मृष्', 'label': 'mfz', 'attrib': [{'pos': 'vt4am', 'defs': 'forget, tolerate'}], 'isLemma': 1},
	{'value': 'मृषित', 'label': 'mfzita', 'attrib': [{'pos': 'ppp', 'defs': 'forget, tolerate'}], 'isLemma': 0, 'parents': ['mfz']},
	{'value': 'मृषितवत्', 'label': 'mfzitavat', 'attrib': [{'pos': 'pap', 'defs': 'forget, tolerate'}], 'isLemma': 0, 'parents': ['mfz']},
	{'value': 'मृषित्वा', 'label': 'mfzitvA', 'attrib': [{'pos': 'abs', 'defs': 'forget, tolerate'}], 'isLemma': 0, 'parents': ['mfz']},
	{'value': 'मृष्य', 'label': 'mfzya', 'attrib': [{'pos': 'ger', 'defs': 'forget, tolerate'}], 'isLemma': 0, 'parents': ['mfz']},
	{'value': 'मृष्यत्', 'label': 'mfzyat', 'attrib': [{'pos': 'prap', 'defs': 'forget, tolerate'}], 'isLemma': 0, 'parents': ['mfz']},
	{'value': 'मृष्यमाण', 'label': 'mfzyamARa', 'attrib': [{'pos': 'prmp', 'defs': 'forget, tolerate'}, {'pos': 'prpp', 'defs': 'forget, tolerate'}], 'isLemma': 0, 'parents': ['mfz']},
	{'value': 'मेदान', 'label': 'medAna', 'attrib': [{'pos': 'pfpp', 'defs': 'rejoice'}], 'isLemma': 0, 'parents': ['mad']},
	{'value': 'मेदिवस्', 'label': 'medivas', 'attrib': [{'pos': 'pfap', 'defs': 'rejoice'}], 'isLemma': 0, 'parents': ['mad']},
	{'value': 'मेनान', 'label': 'menAna', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'think'}], 'isLemma': 0, 'parents': ['man']},
	{'value': 'मेय', 'label': 'meya', 'attrib': [{'pos': 'ger', 'defs': 'measure, make'}], 'isLemma': 0, 'parents': ['mA']},
	{'value': 'मोक्तव्य', 'label': 'moktavya', 'attrib': [{'pos': 'ger', 'defs': 'release'}], 'isLemma': 0, 'parents': ['muc']},
	{'value': 'मोक्तुम्', 'label': 'moktum', 'attrib': [{'pos': 'inf', 'defs': 'release'}], 'isLemma': 0, 'parents': ['muc']},
	{'value': 'मोक्ष्', 'label': 'mokz', 'attrib': [{'pos': 'vt10a', 'defs': 'liberate'}], 'isLemma': 1},
	{'value': 'मोक्षण', 'label': 'mokzaRa', 'attrib': [{'pos': 'n', 'defs': 'freeing, liberation'}], 'isLemma': 1},
	{'value': 'मोक्षणीय', 'label': 'mokzaRIya', 'attrib': [{'pos': 'ger', 'defs': 'liberate'}], 'isLemma': 0, 'parents': ['mokz']},
	{'value': 'मोक्षयत्', 'label': 'mokzayat', 'attrib': [{'pos': 'prap', 'defs': 'liberate'}], 'isLemma': 0, 'parents': ['mokz']},
	{'value': 'मोक्षयाम्', 'label': 'mokzayAm', 'attrib': [{'pos': 'ppf_actn', 'defs': 'release'}], 'isLemma': 0, 'parents': ['muc']},
	{'value': 'मोक्षित', 'label': 'mokzita', 'attrib': [{'pos': 'ppp', 'defs': 'liberate'}], 'isLemma': 0, 'parents': ['mokz']},
	{'value': 'मोक्षितवत्', 'label': 'mokzitavat', 'attrib': [{'pos': 'pap', 'defs': 'liberate'}], 'isLemma': 0, 'parents': ['mokz']},
	{'value': 'मोक्षितव्य', 'label': 'mokzitavya', 'attrib': [{'pos': 'ger', 'defs': 'liberate'}], 'isLemma': 0, 'parents': ['mokz']},
	{'value': 'मोक्षितुम्', 'label': 'mokzitum', 'attrib': [{'pos': 'inf', 'defs': 'liberate'}], 'isLemma': 0, 'parents': ['mokz']},
	{'value': 'मोक्षित्वा', 'label': 'mokzitvA', 'attrib': [{'pos': 'abs', 'defs': 'liberate'}], 'isLemma': 0, 'parents': ['mokz']},
	{'value': 'मोक्षिष्यत्', 'label': 'mokzizyat', 'attrib': [{'pos': 'fap', 'defs': 'liberate'}], 'isLemma': 0, 'parents': ['mokz']},
	{'value': 'मोक्षिष्यमाण', 'label': 'mokzizyamARa', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'liberate'}], 'isLemma': 0, 'parents': ['mokz']},
	{'value': 'मोक्ष्य', 'label': 'mokzya', 'attrib': [{'pos': 'ger', 'defs': 'liberate'}], 'isLemma': 0, 'parents': ['mokz']},
	{'value': 'मोक्ष्यत्', 'label': 'mokzyat', 'attrib': [{'pos': 'fap', 'defs': 'release'}], 'isLemma': 0, 'parents': ['muc']},
	{'value': 'मोक्ष्यमाण', 'label': 'mokzyamARa', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'release'}, {'pos': 'prpp', 'defs': 'liberate'}], 'isLemma': 0, 'parents': ['muc', 'mokz']},
	{'value': 'मोचनीय', 'label': 'mocanIya', 'attrib': [{'pos': 'ger', 'defs': 'release'}], 'isLemma': 0, 'parents': ['muc']},
	{'value': 'मोच्य', 'label': 'mocya', 'attrib': [{'pos': 'ger', 'defs': 'release'}], 'isLemma': 0, 'parents': ['muc']},
	{'value': 'म्रियमाण', 'label': 'mriyamARa', 'attrib': [{'pos': 'prmp', 'defs': 'die'}, {'pos': 'prpp', 'defs': 'die'}], 'isLemma': 0, 'parents': ['mf']},
	{'value': 'यत्', 'label': 'yat', 'attrib': [{'pos': 'prap', 'defs': 'go'}], 'isLemma': 0, 'parents': ['i']},
	{'value': 'यतस्', 'label': 'yatas', 'attrib': [{'pos': 'rel_adv', 'defs': 'whence, because'}], 'isLemma': 1},
	{'value': 'यथा', 'label': 'yaTA', 'attrib': [{'pos': 'rel_adv', 'defs': 'in which manner, as'}], 'isLemma': 1},
	{'value': 'यदा', 'label': 'yadA', 'attrib': [{'pos': 'rel_adv', 'defs': 'at which time, when'}], 'isLemma': 1},
	{'value': 'ययाचान', 'label': 'yayAcAna', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'ask'}], 'isLemma': 0, 'parents': ['yAc']},
	{'value': 'ययाचिवस्', 'label': 'yayAcivas', 'attrib': [{'pos': 'pfap', 'defs': 'ask'}], 'isLemma': 0, 'parents': ['yAc']},
	{'value': 'यशस्', 'label': 'yaSas', 'attrib': [{'pos': 'n', 'defs': 'glory, fame'}], 'isLemma': 1},
	{'value': 'याच्', 'label': 'yAc', 'attrib': [{'pos': 'vt1am', 'defs': 'ask'}], 'isLemma': 1},
	{'value': 'याचत्', 'label': 'yAcat', 'attrib': [{'pos': 'prap', 'defs': 'ask'}], 'isLemma': 0, 'parents': ['yAc']},
	{'value': 'याचनीय', 'label': 'yAcanIya', 'attrib': [{'pos': 'ger', 'defs': 'ask'}], 'isLemma': 0, 'parents': ['yAc']},
	{'value': 'याचमान', 'label': 'yAcamAna', 'attrib': [{'pos': 'prmp', 'defs': 'ask'}], 'isLemma': 0, 'parents': ['yAc']},
	{'value': 'याचित', 'label': 'yAcita', 'attrib': [{'pos': 'ppp', 'defs': 'ask'}], 'isLemma': 0, 'parents': ['yAc']},
	{'value': 'याचितवत्', 'label': 'yAcitavat', 'attrib': [{'pos': 'pap', 'defs': 'ask'}], 'isLemma': 0, 'parents': ['yAc']},
	{'value': 'याचितव्य', 'label': 'yAcitavya', 'attrib': [{'pos': 'ger', 'defs': 'ask'}], 'isLemma': 0, 'parents': ['yAc']},
	{'value': 'याचितुम्', 'label': 'yAcitum', 'attrib': [{'pos': 'inf', 'defs': 'ask'}], 'isLemma': 0, 'parents': ['yAc']},
	{'value': 'याचित्वा', 'label': 'yAcitvA', 'attrib': [{'pos': 'abs', 'defs': 'ask'}], 'isLemma': 0, 'parents': ['yAc']},
	{'value': 'याचिष्यत्', 'label': 'yAcizyat', 'attrib': [{'pos': 'fap', 'defs': 'ask'}], 'isLemma': 0, 'parents': ['yAc']},
	{'value': 'याचिष्यमाण', 'label': 'yAcizyamARa', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'ask'}], 'isLemma': 0, 'parents': ['yAc']},
	{'value': 'याच्य', 'label': 'yAcya', 'attrib': [{'pos': 'ger', 'defs': 'ask'}], 'isLemma': 0, 'parents': ['yAc']},
	{'value': 'याच्यमान', 'label': 'yAcyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'ask'}], 'isLemma': 0, 'parents': ['yAc']},
	{'value': 'युक्त', 'label': 'yukta', 'attrib': [{'pos': 'ppp', 'defs': 'yoke, join, unite'}], 'isLemma': 0, 'parents': ['yuj']},
	{'value': 'युक्तवत्', 'label': 'yuktavat', 'attrib': [{'pos': 'pap', 'defs': 'yoke, join, unite'}], 'isLemma': 0, 'parents': ['yuj']},
	{'value': 'युक्त्वा', 'label': 'yuktvA', 'attrib': [{'pos': 'abs', 'defs': 'yoke, join, unite'}], 'isLemma': 0, 'parents': ['yuj']},
	{'value': 'युज्', 'label': 'yuj', 'attrib': [{'pos': 'vt7am', 'defs': 'yoke, join, unite'}], 'isLemma': 1},
	{'value': 'युज्यमान', 'label': 'yujyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'yoke, join, unite'}], 'isLemma': 0, 'parents': ['yuj']},
	{'value': 'युञ्जत्', 'label': 'yuYjat', 'attrib': [{'pos': 'prap', 'defs': 'yoke, join, unite'}], 'isLemma': 0, 'parents': ['yuj']},
	{'value': 'युञ्जान', 'label': 'yuYjAna', 'attrib': [{'pos': 'prmp', 'defs': 'yoke, join, unite'}], 'isLemma': 0, 'parents': ['yuj']},
	{'value': 'युद्ध', 'label': 'yudDa', 'attrib': [{'pos': 'n', 'defs': 'battle, war'}, {'pos': 'ppp', 'defs': 'fight'}], 'isLemma': 1},
	{'value': 'युद्ध', 'label': 'yudDa', 'attrib': [{'pos': 'n', 'defs': 'battle, war'}, {'pos': 'ppp', 'defs': 'fight'}], 'isLemma': 0, 'parents': ['yuD']},
	{'value': 'युद्धवत्', 'label': 'yudDavat', 'attrib': [{'pos': 'pap', 'defs': 'fight'}], 'isLemma': 0, 'parents': ['yuD']},
	{'value': 'युद्ध्वा', 'label': 'yudDvA', 'attrib': [{'pos': 'abs', 'defs': 'fight'}], 'isLemma': 0, 'parents': ['yuD']},
	{'value': 'युध्', 'label': 'yuD', 'attrib': [{'pos': 'f', 'defs': 'battle, war'}, {'pos': 'vt4m', 'defs': 'fight'}], 'isLemma': 1},
	{'value': 'युधिष्ठिर', 'label': 'yuDizWira', 'attrib': [{'pos': 'm_pn', 'defs': 'Yudhiṣṭhira'}], 'isLemma': 1},
	{'value': 'युध्यमान', 'label': 'yuDyamAna', 'attrib': [{'pos': 'prmp', 'defs': 'fight'}, {'pos': 'prpp', 'defs': 'fight'}], 'isLemma': 0, 'parents': ['yuD']},
	{'value': 'युयुजान', 'label': 'yuyujAna', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'yoke, join, unite'}], 'isLemma': 0, 'parents': ['yuj']},
	{'value': 'युयुज्वस्', 'label': 'yuyujvas', 'attrib': [{'pos': 'pfap', 'defs': 'yoke, join, unite'}], 'isLemma': 0, 'parents': ['yuj']},
	{'value': 'युयुधान', 'label': 'yuyuDAna', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'fight'}], 'isLemma': 0, 'parents': ['yuD']},
	{'value': 'युष्मद्', 'label': 'yuzmad', 'attrib': [{'pos': 'pers_pron', 'defs': 'you'}], 'isLemma': 1},
	{'value': 'युष्मद्वास', 'label': 'yuzmadvAsa', 'attrib': [{'pos': 'm', 'defs': 'your dwelling'}], 'isLemma': 1},
	{'value': 'योक्तव्य', 'label': 'yoktavya', 'attrib': [{'pos': 'ger', 'defs': 'yoke, join, unite'}], 'isLemma': 0, 'parents': ['yuj']},
	{'value': 'योक्तुम्', 'label': 'yoktum', 'attrib': [{'pos': 'inf', 'defs': 'yoke, join, unite'}], 'isLemma': 0, 'parents': ['yuj']},
	{'value': 'योक्ष्यत्', 'label': 'yokzyat', 'attrib': [{'pos': 'fap', 'defs': 'yoke, join, unite'}], 'isLemma': 0, 'parents': ['yuj']},
	{'value': 'योक्ष्यमाण', 'label': 'yokzyamARa', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'yoke, join, unite'}], 'isLemma': 0, 'parents': ['yuj']},
	{'value': 'योग', 'label': 'yoga', 'attrib': [{'pos': 'm', 'defs': 'union, conjunction, use, work, means, stratagem'}], 'isLemma': 1},
	{'value': 'योग्य', 'label': 'yogya', 'attrib': [{'pos': 'ger', 'defs': 'yoke, join, unite'}], 'isLemma': 0, 'parents': ['yuj']},
	{'value': 'योजनीय', 'label': 'yojanIya', 'attrib': [{'pos': 'ger', 'defs': 'yoke, join, unite'}], 'isLemma': 0, 'parents': ['yuj']},
	{'value': 'योत्स्यमान', 'label': 'yotsyamAna', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'fight'}], 'isLemma': 0, 'parents': ['yuD']},
	{'value': 'योद्धव्य', 'label': 'yodDavya', 'attrib': [{'pos': 'ger', 'defs': 'fight'}], 'isLemma': 0, 'parents': ['yuD']},
	{'value': 'योद्धुम्', 'label': 'yodDum', 'attrib': [{'pos': 'inf', 'defs': 'fight'}], 'isLemma': 0, 'parents': ['yuD']},
	{'value': 'योधनीय', 'label': 'yoDanIya', 'attrib': [{'pos': 'ger', 'defs': 'fight'}], 'isLemma': 0, 'parents': ['yuD']},
	{'value': 'योध्य', 'label': 'yoDya', 'attrib': [{'pos': 'ger', 'defs': 'fight'}], 'isLemma': 0, 'parents': ['yuD']},
	{'value': 'रक्ष्', 'label': 'rakz', 'attrib': [{'pos': 'vt1a', 'defs': 'keep, protect'}], 'isLemma': 1},
	{'value': 'रक्षत्', 'label': 'rakzat', 'attrib': [{'pos': 'prap', 'defs': 'keep, protect'}], 'isLemma': 0, 'parents': ['rakz']},
	{'value': 'रक्षनीय', 'label': 'rakzanIya', 'attrib': [{'pos': 'ger', 'defs': 'keep, protect'}], 'isLemma': 0, 'parents': ['rakz']},
	{'value': 'रक्षमाण', 'label': 'rakzamARa', 'attrib': [{'pos': 'prmp', 'defs': 'keep, protect'}], 'isLemma': 0, 'parents': ['rakz']},
	{'value': 'रक्षस्', 'label': 'rakzas', 'attrib': [{'pos': 'n', 'defs': 'demon'}], 'isLemma': 1},
	{'value': 'रक्षित', 'label': 'rakzita', 'attrib': [{'pos': 'ppp', 'defs': 'keep, protect'}], 'isLemma': 0, 'parents': ['rakz']},
	{'value': 'रक्षितवत्', 'label': 'rakzitavat', 'attrib': [{'pos': 'pap', 'defs': 'keep, protect'}], 'isLemma': 0, 'parents': ['rakz']},
	{'value': 'रक्षितव्य', 'label': 'rakzitavya', 'attrib': [{'pos': 'ger', 'defs': 'keep, protect'}], 'isLemma': 0, 'parents': ['rakz']},
	{'value': 'रक्षितुम्', 'label': 'rakzitum', 'attrib': [{'pos': 'inf', 'defs': 'keep, protect'}], 'isLemma': 0, 'parents': ['rakz']},
	{'value': 'रक्षित्वा', 'label': 'rakzitvA', 'attrib': [{'pos': 'abs', 'defs': 'keep, protect'}], 'isLemma': 0, 'parents': ['rakz']},
	{'value': 'रक्षिष्यत्', 'label': 'rakzizyat', 'attrib': [{'pos': 'fap', 'defs': 'keep, protect'}], 'isLemma': 0, 'parents': ['rakz']},
	{'value': 'रक्षिष्यमाण', 'label': 'rakzizyamARa', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'keep, protect'}], 'isLemma': 0, 'parents': ['rakz']},
	{'value': 'रक्ष्य', 'label': 'rakzya', 'attrib': [{'pos': 'ger', 'defs': 'keep, protect'}], 'isLemma': 0, 'parents': ['rakz']},
	{'value': 'रक्ष्यमाण', 'label': 'rakzyamARa', 'attrib': [{'pos': 'prpp', 'defs': 'keep, protect'}], 'isLemma': 0, 'parents': ['rakz']},
	{'value': 'रजमान', 'label': 'rajamAna', 'attrib': [{'pos': 'prmp', 'defs': 'shine, rule'}], 'isLemma': 0, 'parents': ['rAj']},
	{'value': 'रत्न', 'label': 'ratna', 'attrib': [{'pos': 'n', 'defs': 'gift, wealth, gem'}], 'isLemma': 1},
	{'value': 'रथ', 'label': 'raTa', 'attrib': [{'pos': 'm', 'defs': 'chariot, car'}], 'isLemma': 1},
	{'value': 'रप्स्यमाण', 'label': 'rapsyamARa', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'begin'}], 'isLemma': 0, 'parents': ['raB']},
	{'value': 'रब्ध', 'label': 'rabDa', 'attrib': [{'pos': 'ppp', 'defs': 'begin'}], 'isLemma': 0, 'parents': ['raB']},
	{'value': 'रब्धवत्', 'label': 'rabDavat', 'attrib': [{'pos': 'pap', 'defs': 'begin'}], 'isLemma': 0, 'parents': ['raB']},
	{'value': 'रब्धव्य', 'label': 'rabDavya', 'attrib': [{'pos': 'ger', 'defs': 'begin'}], 'isLemma': 0, 'parents': ['raB']},
	{'value': 'रब्धुम्', 'label': 'rabDum', 'attrib': [{'pos': 'inf', 'defs': 'begin'}], 'isLemma': 0, 'parents': ['raB']},
	{'value': 'रब्ध्वा', 'label': 'rabDvA', 'attrib': [{'pos': 'abs', 'defs': 'begin'}], 'isLemma': 0, 'parents': ['raB']},
	{'value': 'रभ्', 'label': 'raB', 'attrib': [{'pos': 'vt1m', 'defs': 'begin'}], 'isLemma': 1},
	{'value': 'रभमाण', 'label': 'raBamARa', 'attrib': [{'pos': 'prmp', 'defs': 'begin'}], 'isLemma': 0, 'parents': ['raB']},
	{'value': 'रभ्य', 'label': 'raBya', 'attrib': [{'pos': 'ger', 'defs': 'begin'}], 'isLemma': 0, 'parents': ['raB']},
	{'value': 'रभ्यमाण', 'label': 'raByamARa', 'attrib': [{'pos': 'prpp', 'defs': 'begin'}], 'isLemma': 0, 'parents': ['raB']},
	{'value': 'रम्भणीय', 'label': 'ramBaRIya', 'attrib': [{'pos': 'ger', 'defs': 'begin'}], 'isLemma': 0, 'parents': ['raB']},
	{'value': 'ररक्षाण', 'label': 'rarakzARa', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'keep, protect'}], 'isLemma': 0, 'parents': ['rakz']},
	{'value': 'ररक्षिवस्', 'label': 'rarakzivas', 'attrib': [{'pos': 'pfap', 'defs': 'keep, protect'}], 'isLemma': 0, 'parents': ['rakz']},
	{'value': 'रराजान', 'label': 'rarAjAna', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'shine, rule'}], 'isLemma': 0, 'parents': ['rAj']},
	{'value': 'रराजिवस्', 'label': 'rarAjivas', 'attrib': [{'pos': 'pfap', 'defs': 'shine, rule'}], 'isLemma': 0, 'parents': ['rAj']},
	{'value': 'राक्षस', 'label': 'rAkzasa', 'attrib': [{'pos': 'm', 'defs': 'demon'}], 'isLemma': 1},
	{'value': 'राज्', 'label': 'rAj', 'attrib': [{'pos': 'vt1am', 'defs': 'shine, rule'}], 'isLemma': 1},
	{'value': 'राजत्', 'label': 'rAjat', 'attrib': [{'pos': 'prap', 'defs': 'shine, rule'}], 'isLemma': 0, 'parents': ['rAj']},
	{'value': 'राजन्', 'label': 'rAjan', 'attrib': [{'pos': 'm', 'defs': 'king'}], 'isLemma': 1},
	{'value': 'राजनीय', 'label': 'rAjanIya', 'attrib': [{'pos': 'ger', 'defs': 'shine, rule'}], 'isLemma': 0, 'parents': ['rAj']},
	{'value': 'राजित', 'label': 'rAjita', 'attrib': [{'pos': 'ppp', 'defs': 'shine, rule'}], 'isLemma': 0, 'parents': ['rAj']},
	{'value': 'राजितवत्', 'label': 'rAjitavat', 'attrib': [{'pos': 'pap', 'defs': 'shine, rule'}], 'isLemma': 0, 'parents': ['rAj']},
	{'value': 'राजितव्य', 'label': 'rAjitavya', 'attrib': [{'pos': 'ger', 'defs': 'shine, rule'}], 'isLemma': 0, 'parents': ['rAj']},
	{'value': 'राजितुम्', 'label': 'rAjitum', 'attrib': [{'pos': 'inf', 'defs': 'shine, rule'}], 'isLemma': 0, 'parents': ['rAj']},
	{'value': 'राजित्वा', 'label': 'rAjitvA', 'attrib': [{'pos': 'abs', 'defs': 'shine, rule'}], 'isLemma': 0, 'parents': ['rAj']},
	{'value': 'राजिष्यत्', 'label': 'rAjizyat', 'attrib': [{'pos': 'fap', 'defs': 'shine, rule'}], 'isLemma': 0, 'parents': ['rAj']},
	{'value': 'राजिष्यमाण', 'label': 'rAjizyamARa', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'shine, rule'}], 'isLemma': 0, 'parents': ['rAj']},
	{'value': 'राजीव', 'label': 'rAjIva', 'attrib': [{'pos': 'n', 'defs': 'blue lotus'}], 'isLemma': 1},
	{'value': 'राजीवलोचन', 'label': 'rAjIvalocana', 'attrib': [{'pos': 'adj', 'defs': 'lotus-eyed'}], 'isLemma': 1},
	{'value': 'राज्य', 'label': 'rAjya', 'attrib': [{'pos': 'ger', 'defs': 'shine, rule'}, {'pos': 'n', 'defs': 'kingdom'}], 'isLemma': 1},
	{'value': 'राज्य', 'label': 'rAjya', 'attrib': [{'pos': 'ger', 'defs': 'shine, rule'}, {'pos': 'n', 'defs': 'kingdom'}], 'isLemma': 0, 'parents': ['rAj']},
	{'value': 'राज्यमान', 'label': 'rAjyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'shine, rule'}], 'isLemma': 0, 'parents': ['rAj']},
	{'value': 'राज्यविनाश', 'label': 'rAjyavinASa', 'attrib': [{'pos': 'm', 'defs': 'destruction of a kingdom'}], 'isLemma': 1},
	{'value': 'राज्यार्थ', 'label': 'rAjyArTa', 'attrib': [{'pos': 'm', 'defs': 'purpose of a kingdom'}], 'isLemma': 1},
	{'value': 'राष्ट्र', 'label': 'rAzwra', 'attrib': [{'pos': 'n', 'defs': 'kingdom'}], 'isLemma': 1},
	{'value': 'रूप', 'label': 'rUpa', 'attrib': [{'pos': 'n', 'defs': 'appearance, form, beauty'}], 'isLemma': 1},
	{'value': 'रूपवीर्यौजःसंपन्न', 'label': 'rUpavIryOjaHsaMpanna', 'attrib': [{'pos': 'adj', 'defs': 'endowed with beauty, power and energy'}], 'isLemma': 1},
	{'value': 'रूपवीर्यौजस्', 'label': 'rUpavIryOjas', 'attrib': [{'pos': 'n', 'defs': 'beauty, power and energy'}], 'isLemma': 1},
	{'value': 'रेभिवस्', 'label': 'reBivas', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'begin'}], 'isLemma': 0, 'parents': ['raB']},
	{'value': 'लक्षण', 'label': 'lakzaRa', 'attrib': [{'pos': 'n', 'defs': 'characteristic, sign'}], 'isLemma': 1},
	{'value': 'लक्ष्मी', 'label': 'lakzmI', 'attrib': [{'pos': 'f', 'defs': 'sign, good fortune'}, {'pos': 'f_pn', 'defs': 'Lakṣmī'}], 'isLemma': 1},
	{'value': 'लप्स्यमान', 'label': 'lapsyamAna', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'obtain'}], 'isLemma': 0, 'parents': ['laB']},
	{'value': 'लब्ध', 'label': 'labDa', 'attrib': [{'pos': 'ppp', 'defs': 'obtain'}], 'isLemma': 0, 'parents': ['laB']},
	{'value': 'लब्धवत्', 'label': 'labDavat', 'attrib': [{'pos': 'pap', 'defs': 'obtain'}], 'isLemma': 0, 'parents': ['laB']},
	{'value': 'लब्धव्य', 'label': 'labDavya', 'attrib': [{'pos': 'ger', 'defs': 'obtain'}], 'isLemma': 0, 'parents': ['laB']},
	{'value': 'लब्धुम्', 'label': 'labDum', 'attrib': [{'pos': 'inf', 'defs': 'obtain'}], 'isLemma': 0, 'parents': ['laB']},
	{'value': 'लब्ध्वा', 'label': 'labDvA', 'attrib': [{'pos': 'abs', 'defs': 'obtain'}], 'isLemma': 0, 'parents': ['laB']},
	{'value': 'लभ्', 'label': 'laB', 'attrib': [{'pos': 'vt1m', 'defs': 'obtain'}], 'isLemma': 1},
	{'value': 'लभनीय', 'label': 'laBanIya', 'attrib': [{'pos': 'ger', 'defs': 'obtain'}], 'isLemma': 0, 'parents': ['laB']},
	{'value': 'लभमान', 'label': 'laBamAna', 'attrib': [{'pos': 'prmp', 'defs': 'obtain'}], 'isLemma': 0, 'parents': ['laB']},
	{'value': 'लभ्य', 'label': 'laBya', 'attrib': [{'pos': 'ger', 'defs': 'obtain'}], 'isLemma': 0, 'parents': ['laB']},
	{'value': 'लभ्यमान', 'label': 'laByamAna', 'attrib': [{'pos': 'prpp', 'defs': 'obtain'}], 'isLemma': 0, 'parents': ['laB']},
	{'value': 'लुब्ध', 'label': 'lubDa', 'attrib': [{'pos': 'ppp', 'defs': 'lust'}], 'isLemma': 0, 'parents': ['luB']},
	{'value': 'लुब्धवत्', 'label': 'lubDavat', 'attrib': [{'pos': 'pap', 'defs': 'lust'}], 'isLemma': 0, 'parents': ['luB']},
	{'value': 'लुब्ध्वा', 'label': 'lubDvA', 'attrib': [{'pos': 'abs', 'defs': 'lust'}], 'isLemma': 0, 'parents': ['luB']},
	{'value': 'लुभ्', 'label': 'luB', 'attrib': [{'pos': 'vi4a', 'defs': 'lust'}], 'isLemma': 1},
	{'value': 'लुभित्वा', 'label': 'luBitvA', 'attrib': [{'pos': 'abs', 'defs': 'lust'}], 'isLemma': 0, 'parents': ['luB']},
	{'value': 'लुभ्य', 'label': 'luBya', 'attrib': [{'pos': 'ger', 'defs': 'lust'}], 'isLemma': 0, 'parents': ['luB']},
	{'value': 'लुभ्यत्', 'label': 'luByat', 'attrib': [{'pos': 'prap', 'defs': 'lust'}], 'isLemma': 0, 'parents': ['luB']},
	{'value': 'लुभ्यमान', 'label': 'luByamAna', 'attrib': [{'pos': 'prpp', 'defs': 'lust'}], 'isLemma': 0, 'parents': ['luB']},
	{'value': 'लुलुभान', 'label': 'luluBAna', 'attrib': [{'pos': 'pfpp', 'defs': 'lust'}], 'isLemma': 0, 'parents': ['luB']},
	{'value': 'लेभान', 'label': 'leBAna', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'obtain'}], 'isLemma': 0, 'parents': ['laB']},
	{'value': 'लोक', 'label': 'loka', 'attrib': [{'pos': 'm', 'defs': 'region'}], 'isLemma': 1},
	{'value': 'लोचन', 'label': 'locana', 'attrib': [{'pos': 'n', 'defs': 'eye'}], 'isLemma': 1},
	{'value': 'लोब्धव्य', 'label': 'lobDavya', 'attrib': [{'pos': 'ger', 'defs': 'lust'}], 'isLemma': 0, 'parents': ['luB']},
	{'value': 'लोब्धुम्', 'label': 'lobDum', 'attrib': [{'pos': 'inf', 'defs': 'lust'}], 'isLemma': 0, 'parents': ['luB']},
	{'value': 'लोभ', 'label': 'loBa', 'attrib': [{'pos': 'm', 'defs': 'love, desire'}], 'isLemma': 1},
	{'value': 'लोभनीय', 'label': 'loBanIya', 'attrib': [{'pos': 'ger', 'defs': 'lust'}], 'isLemma': 0, 'parents': ['luB']},
	{'value': 'लोभितव्य', 'label': 'loBitavya', 'attrib': [{'pos': 'ger', 'defs': 'lust'}], 'isLemma': 0, 'parents': ['luB']},
	{'value': 'लोभितुम्', 'label': 'loBitum', 'attrib': [{'pos': 'inf', 'defs': 'lust'}], 'isLemma': 0, 'parents': ['luB']},
	{'value': 'लोभित्वा', 'label': 'loBitvA', 'attrib': [{'pos': 'abs', 'defs': 'lust'}], 'isLemma': 0, 'parents': ['luB']},
	{'value': 'लोभिष्यत्', 'label': 'loBizyat', 'attrib': [{'pos': 'fap', 'defs': 'lust'}], 'isLemma': 0, 'parents': ['luB']},
	{'value': 'लोभिष्यमाण', 'label': 'loBizyamARa', 'attrib': [{'pos': 'fpp', 'defs': 'lust'}], 'isLemma': 0, 'parents': ['luB']},
	{'value': 'वक्त', 'label': 'vakta', 'attrib': [{'pos': 'ppp', 'defs': 'deceive'}], 'isLemma': 0, 'parents': ['vaYc']},
	{'value': 'वक्तवत्', 'label': 'vaktavat', 'attrib': [{'pos': 'pap', 'defs': 'deceive'}], 'isLemma': 0, 'parents': ['vaYc']},
	{'value': 'वक्तव्य', 'label': 'vaktavya', 'attrib': [{'pos': 'ger', 'defs': 'say, speak'}], 'isLemma': 0, 'parents': ['vac']},
	{'value': 'वक्तुम्', 'label': 'vaktum', 'attrib': [{'pos': 'inf', 'defs': 'say, speak'}], 'isLemma': 0, 'parents': ['vac']},
	{'value': 'वक्त्वा', 'label': 'vaktvA', 'attrib': [{'pos': 'abs', 'defs': 'deceive'}], 'isLemma': 0, 'parents': ['vaYc']},
	{'value': 'वक्ष्यत्', 'label': 'vakzyat', 'attrib': [{'pos': 'fap', 'defs': 'say, speak'}, {'pos': 'fap', 'defs': 'carry, flow, wed'}], 'isLemma': 0, 'parents': ['vac', 'vah']},
	{'value': 'वक्ष्यमाण', 'label': 'vakzyamARa', 'attrib': [{'pos': 'fpp', 'defs': 'say, speak'}, {'pos': 'fmp, fpp', 'defs': 'carry, flow, wed'}], 'isLemma': 0, 'parents': ['vac', 'vah']},
	{'value': 'वच्', 'label': 'vac', 'attrib': [{'pos': 'vt2a', 'defs': 'say, speak'}], 'isLemma': 1},
	{'value': 'वचन', 'label': 'vacana', 'attrib': [{'pos': 'n', 'defs': 'speech'}], 'isLemma': 1},
	{'value': 'वचनीय', 'label': 'vacanIya', 'attrib': [{'pos': 'ger', 'defs': 'say, speak'}], 'isLemma': 0, 'parents': ['vac']},
	{'value': 'वचित्वा', 'label': 'vacitvA', 'attrib': [{'pos': 'abs', 'defs': 'deceive'}], 'isLemma': 0, 'parents': ['vaYc']},
	{'value': 'वञ्च्', 'label': 'vaYc', 'attrib': [{'pos': 'vt10m', 'defs': 'deceive'}], 'isLemma': 1},
	{'value': 'वञ्चनीय', 'label': 'vaYcanIya', 'attrib': [{'pos': 'ger', 'defs': 'deceive'}], 'isLemma': 0, 'parents': ['vaYc']},
	{'value': 'वञ्चयमान', 'label': 'vaYcayamAna', 'attrib': [{'pos': 'prmp', 'defs': 'deceive'}], 'isLemma': 0, 'parents': ['vaYc']},
	{'value': 'वञ्चयाम्', 'label': 'vaYcayAm', 'attrib': [{'pos': 'ppf_actn', 'defs': 'deceive'}], 'isLemma': 0, 'parents': ['vaYc']},
	{'value': 'वञ्चयितव्य', 'label': 'vaYcayitavya', 'attrib': [{'pos': 'ger', 'defs': 'deceive'}], 'isLemma': 0, 'parents': ['vaYc']},
	{'value': 'वञ्चयितुम्', 'label': 'vaYcayitum', 'attrib': [{'pos': 'inf', 'defs': 'deceive'}], 'isLemma': 0, 'parents': ['vaYc']},
	{'value': 'वञ्चयित्वा', 'label': 'vaYcayitvA', 'attrib': [{'pos': 'abs', 'defs': 'deceive'}], 'isLemma': 0, 'parents': ['vaYc']},
	{'value': 'वञ्चयिष्यमाण', 'label': 'vaYcayizyamARa', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'deceive'}], 'isLemma': 0, 'parents': ['vaYc']},
	{'value': 'वञ्चित्वा', 'label': 'vaYcitvA', 'attrib': [{'pos': 'abs', 'defs': 'deceive'}], 'isLemma': 0, 'parents': ['vaYc']},
	{'value': 'वञ्च्य', 'label': 'vaYcya', 'attrib': [{'pos': 'ger', 'defs': 'deceive'}], 'isLemma': 0, 'parents': ['vaYc']},
	{'value': 'वञ्च्यमान', 'label': 'vaYcyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'deceive'}], 'isLemma': 0, 'parents': ['vaYc']},
	{'value': 'वत्स्यत्', 'label': 'vatsyat', 'attrib': [{'pos': 'fap', 'defs': 'dwell, live'}], 'isLemma': 0, 'parents': ['vas']},
	{'value': 'वत्स्यमान', 'label': 'vatsyamAna', 'attrib': [{'pos': 'fpp', 'defs': 'dwell, live'}], 'isLemma': 0, 'parents': ['vas']},
	{'value': 'वद्', 'label': 'vad', 'attrib': [{'pos': 'vt1a', 'defs': 'tell'}], 'isLemma': 1},
	{'value': 'वदत्', 'label': 'vadat', 'attrib': [{'pos': 'prap', 'defs': 'tell'}], 'isLemma': 0, 'parents': ['vad']},
	{'value': 'वदनीय', 'label': 'vadanIya', 'attrib': [{'pos': 'ger', 'defs': 'tell'}], 'isLemma': 0, 'parents': ['vad']},
	{'value': 'वदितव्य', 'label': 'vaditavya', 'attrib': [{'pos': 'ger', 'defs': 'tell'}], 'isLemma': 0, 'parents': ['vad']},
	{'value': 'वदितुम्', 'label': 'vaditum', 'attrib': [{'pos': 'inf', 'defs': 'tell'}], 'isLemma': 0, 'parents': ['vad']},
	{'value': 'वदिष्यत्', 'label': 'vadizyat', 'attrib': [{'pos': 'fap', 'defs': 'tell'}], 'isLemma': 0, 'parents': ['vad']},
	{'value': 'वदिष्यमाण', 'label': 'vadizyamARa', 'attrib': [{'pos': 'fpp', 'defs': 'tell'}], 'isLemma': 0, 'parents': ['vad']},
	{'value': 'वध्', 'label': 'vaD', 'attrib': [{'pos': 'vta', 'defs': 'smite, slay'}], 'isLemma': 1},
	{'value': 'वध', 'label': 'vaDa', 'attrib': [{'pos': 'm', 'defs': 'killing, slaughter'}], 'isLemma': 1},
	{'value': 'वन', 'label': 'vana', 'attrib': [{'pos': 'n', 'defs': 'forest'}], 'isLemma': 1},
	{'value': 'वनवास', 'label': 'vanavAsa', 'attrib': [{'pos': 'm', 'defs': 'forest-dwelling'}], 'isLemma': 1},
	{'value': 'वर', 'label': 'vara', 'attrib': [{'pos': 'adj', 'defs': 'elect, excellent'}, {'pos': 'm', 'defs': 'choice, boon'}], 'isLemma': 1},
	{'value': 'वरणीय', 'label': 'varaRIya', 'attrib': [{'pos': 'ger', 'defs': 'cover'}, {'pos': 'ger', 'defs': 'choose'}], 'isLemma': 0, 'parents': ['vf']},
	{'value': 'वरितव्य', 'label': 'varitavya', 'attrib': [{'pos': 'ger', 'defs': 'cover'}, {'pos': 'ger', 'defs': 'choose'}], 'isLemma': 0, 'parents': ['vf']},
	{'value': 'वरितुम्', 'label': 'varitum', 'attrib': [{'pos': 'inf', 'defs': 'cover'}, {'pos': 'inf', 'defs': 'choose'}], 'isLemma': 0, 'parents': ['vf']},
	{'value': 'वरिष्यत्', 'label': 'varizyat', 'attrib': [{'pos': 'fap', 'defs': 'cover'}], 'isLemma': 0, 'parents': ['vf']},
	{'value': 'वरिष्यमाण', 'label': 'varizyamARa', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'cover'}, {'pos': 'fmp, fpp', 'defs': 'choose'}], 'isLemma': 0, 'parents': ['vf']},
	{'value': 'वरीतव्य', 'label': 'varItavya', 'attrib': [{'pos': 'ger', 'defs': 'cover'}], 'isLemma': 0, 'parents': ['vf']},
	{'value': 'वरीतुम्', 'label': 'varItum', 'attrib': [{'pos': 'inf', 'defs': 'cover'}, {'pos': 'inf', 'defs': 'choose'}], 'isLemma': 0, 'parents': ['vf']},
	{'value': 'वरीष्यत्', 'label': 'varIzyat', 'attrib': [{'pos': 'fap', 'defs': 'cover'}], 'isLemma': 0, 'parents': ['vf']},
	{'value': 'वरीष्यमाण', 'label': 'varIzyamARa', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'cover'}, {'pos': 'fmp, fpp', 'defs': 'choose'}], 'isLemma': 0, 'parents': ['vf']},
	{'value': 'वर्तनीय', 'label': 'vartanIya', 'attrib': [{'pos': 'ger', 'defs': 'occur, be present'}], 'isLemma': 0, 'parents': ['vft']},
	{'value': 'वर्तमान', 'label': 'vartamAna', 'attrib': [{'pos': 'prmp', 'defs': 'occur, be present'}], 'isLemma': 0, 'parents': ['vft']},
	{'value': 'वर्तितव्य', 'label': 'vartitavya', 'attrib': [{'pos': 'ger', 'defs': 'occur, be present'}], 'isLemma': 0, 'parents': ['vft']},
	{'value': 'वर्तितुम्', 'label': 'vartitum', 'attrib': [{'pos': 'inf', 'defs': 'occur, be present'}], 'isLemma': 0, 'parents': ['vft']},
	{'value': 'वर्तित्वा', 'label': 'vartitvA', 'attrib': [{'pos': 'abs', 'defs': 'occur, be present'}], 'isLemma': 0, 'parents': ['vft']},
	{'value': 'वर्तिष्यमाण', 'label': 'vartizyamARa', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'occur, be present'}], 'isLemma': 0, 'parents': ['vft']},
	{'value': 'वर्ष', 'label': 'varza', 'attrib': [{'pos': 'm, n', 'defs': 'rain, year'}], 'isLemma': 1},
	{'value': 'वर्षणीय', 'label': 'varzaRIya', 'attrib': [{'pos': 'ger', 'defs': 'rain'}], 'isLemma': 0, 'parents': ['vfz']},
	{'value': 'वर्षत्', 'label': 'varzat', 'attrib': [{'pos': 'prap', 'defs': 'rain'}], 'isLemma': 0, 'parents': ['vfz']},
	{'value': 'वर्षितव्य', 'label': 'varzitavya', 'attrib': [{'pos': 'ger', 'defs': 'rain'}], 'isLemma': 0, 'parents': ['vfz']},
	{'value': 'वर्षितुम्', 'label': 'varzitum', 'attrib': [{'pos': 'inf', 'defs': 'rain'}], 'isLemma': 0, 'parents': ['vfz']},
	{'value': 'वर्षित्वा', 'label': 'varzitvA', 'attrib': [{'pos': 'abs', 'defs': 'rain'}], 'isLemma': 0, 'parents': ['vfz']},
	{'value': 'वर्षिष्यत्', 'label': 'varzizyat', 'attrib': [{'pos': 'fap', 'defs': 'rain'}], 'isLemma': 0, 'parents': ['vfz']},
	{'value': 'वर्ष्य', 'label': 'varzya', 'attrib': [{'pos': 'ger', 'defs': 'rain'}], 'isLemma': 0, 'parents': ['vfz']},
	{'value': 'वर्ष्यमाण', 'label': 'varzyamARa', 'attrib': [{'pos': 'fpp', 'defs': 'rain'}], 'isLemma': 0, 'parents': ['vfz']},
	{'value': 'ववृतान', 'label': 'vavftAna', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'occur, be present'}], 'isLemma': 0, 'parents': ['vft']},
	{'value': 'ववृवस्', 'label': 'vavfvas', 'attrib': [{'pos': 'pfap', 'defs': 'cover'}], 'isLemma': 0, 'parents': ['vf']},
	{'value': 'ववृषान', 'label': 'vavfzAna', 'attrib': [{'pos': 'pfpp', 'defs': 'rain'}], 'isLemma': 0, 'parents': ['vfz']},
	{'value': 'ववृषिवस्', 'label': 'vavfzivas', 'attrib': [{'pos': 'pfap', 'defs': 'rain'}], 'isLemma': 0, 'parents': ['vfz']},
	{'value': 'वव्रजान', 'label': 'vavrajAna', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'walk'}], 'isLemma': 0, 'parents': ['vraj']},
	{'value': 'वव्रजिवस्', 'label': 'vavrajivas', 'attrib': [{'pos': 'pfap', 'defs': 'walk'}], 'isLemma': 0, 'parents': ['vraj']},
	{'value': 'वव्राण', 'label': 'vavrARa', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'cover'}, {'pos': 'pfmp, pfpp', 'defs': 'choose'}], 'isLemma': 0, 'parents': ['vf']},
	{'value': 'वश्', 'label': 'vaS', 'attrib': [{'pos': 'vt2a', 'defs': 'wish'}], 'isLemma': 1},
	{'value': 'वश', 'label': 'vaSa', 'attrib': [{'pos': 'm', 'defs': 'will, wish, desire, control'}], 'isLemma': 1},
	{'value': 'वशनीय', 'label': 'vaSanIya', 'attrib': [{'pos': 'ger', 'defs': 'wish'}], 'isLemma': 0, 'parents': ['vaS']},
	{'value': 'वशितव्य', 'label': 'vaSitavya', 'attrib': [{'pos': 'ger', 'defs': 'wish'}], 'isLemma': 0, 'parents': ['vaS']},
	{'value': 'वशितुम्', 'label': 'vaSitum', 'attrib': [{'pos': 'inf', 'defs': 'wish'}], 'isLemma': 0, 'parents': ['vaS']},
	{'value': 'वशित्वा', 'label': 'vaSitvA', 'attrib': [{'pos': 'abs', 'defs': 'wish'}], 'isLemma': 0, 'parents': ['vaS']},
	{'value': 'वशिष्यत्', 'label': 'vaSizyat', 'attrib': [{'pos': 'fap', 'defs': 'wish'}], 'isLemma': 0, 'parents': ['vaS']},
	{'value': 'वशिष्यमाण', 'label': 'vaSizyamARa', 'attrib': [{'pos': 'fpp', 'defs': 'wish'}], 'isLemma': 0, 'parents': ['vaS']},
	{'value': 'वश्य', 'label': 'vaSya', 'attrib': [{'pos': 'ger', 'defs': 'wish'}], 'isLemma': 0, 'parents': ['vaS']},
	{'value': 'वस्', 'label': 'vas', 'attrib': [{'pos': 'vi1a', 'defs': 'dwell, live'}], 'isLemma': 1},
	{'value': 'वसत्', 'label': 'vasat', 'attrib': [{'pos': 'prap', 'defs': 'dwell, live'}], 'isLemma': 0, 'parents': ['vas']},
	{'value': 'वसनीय', 'label': 'vasanIya', 'attrib': [{'pos': 'ger', 'defs': 'dwell, live'}], 'isLemma': 0, 'parents': ['vas']},
	{'value': 'वसितव्य', 'label': 'vasitavya', 'attrib': [{'pos': 'ger', 'defs': 'dwell, live'}], 'isLemma': 0, 'parents': ['vas']},
	{'value': 'वसु', 'label': 'vasu', 'attrib': [{'pos': 'adj', 'defs': 'excellent, good, beneficent'}, {'pos': 'n', 'defs': 'goods, property'}], 'isLemma': 1},
	{'value': 'वसुंधर', 'label': 'vasuMDara', 'attrib': [{'pos': 'adj', 'defs': 'holding wealth'}], 'isLemma': 1},
	{'value': 'वसुंधरा', 'label': 'vasuMDarA', 'attrib': [{'pos': 'f', 'defs': 'the earth'}], 'isLemma': 1},
	{'value': 'वसुदेव', 'label': 'vasudeva', 'attrib': [{'pos': 'm', 'defs': 'Vasudeva'}], 'isLemma': 1},
	{'value': 'वस्तुम्', 'label': 'vastum', 'attrib': [{'pos': 'inf', 'defs': 'dwell, live'}], 'isLemma': 0, 'parents': ['vas']},
	{'value': 'वह्', 'label': 'vah', 'attrib': [{'pos': 'vt1am', 'defs': 'carry, flow, wed'}], 'isLemma': 1},
	{'value': 'वहत्', 'label': 'vahat', 'attrib': [{'pos': 'prap', 'defs': 'carry, flow, wed'}], 'isLemma': 0, 'parents': ['vah']},
	{'value': 'वहमान', 'label': 'vahamAna', 'attrib': [{'pos': 'prmp', 'defs': 'carry, flow, wed'}], 'isLemma': 0, 'parents': ['vah']},
	{'value': 'वहिष्यत्', 'label': 'vahizyat', 'attrib': [{'pos': 'fap', 'defs': 'carry, flow, wed'}], 'isLemma': 0, 'parents': ['vah']},
	{'value': 'वहिष्यमाण', 'label': 'vahizyamARa', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'carry, flow, wed'}], 'isLemma': 0, 'parents': ['vah']},
	{'value': 'वाक्य', 'label': 'vAkya', 'attrib': [{'pos': 'ger', 'defs': 'say, speak'}], 'isLemma': 0, 'parents': ['vac']},
	{'value': 'वाद्य', 'label': 'vAdya', 'attrib': [{'pos': 'ger', 'defs': 'tell'}], 'isLemma': 0, 'parents': ['vad']},
	{'value': 'वारणीय', 'label': 'vAraRIya', 'attrib': [{'pos': 'ger', 'defs': 'block'}], 'isLemma': 0, 'parents': ['vf']},
	{'value': 'वारयत्', 'label': 'vArayat', 'attrib': [{'pos': 'prap', 'defs': 'block'}], 'isLemma': 0, 'parents': ['vf']},
	{'value': 'वारयमाण', 'label': 'vArayamARa', 'attrib': [{'pos': 'prmp', 'defs': 'block'}], 'isLemma': 0, 'parents': ['vf']},
	{'value': 'वारयाम्', 'label': 'vArayAm', 'attrib': [{'pos': 'ppf_actn', 'defs': 'block'}], 'isLemma': 0, 'parents': ['vf']},
	{'value': 'वारयितव्य', 'label': 'vArayitavya', 'attrib': [{'pos': 'ger', 'defs': 'block'}], 'isLemma': 0, 'parents': ['vf']},
	{'value': 'वारयितुम्', 'label': 'vArayitum', 'attrib': [{'pos': 'inf', 'defs': 'block'}], 'isLemma': 0, 'parents': ['vf']},
	{'value': 'वारयित्वा', 'label': 'vArayitvA', 'attrib': [{'pos': 'abs', 'defs': 'block'}], 'isLemma': 0, 'parents': ['vf']},
	{'value': 'वारयिष्यत्', 'label': 'vArayizyat', 'attrib': [{'pos': 'fap', 'defs': 'block'}], 'isLemma': 0, 'parents': ['vf']},
	{'value': 'वारयिष्यमाण', 'label': 'vArayizyamARa', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'block'}], 'isLemma': 0, 'parents': ['vf']},
	{'value': 'वारित', 'label': 'vArita', 'attrib': [{'pos': 'ppp', 'defs': 'block'}], 'isLemma': 0, 'parents': ['vf']},
	{'value': 'वारितवत्', 'label': 'vAritavat', 'attrib': [{'pos': 'pap', 'defs': 'block'}], 'isLemma': 0, 'parents': ['vf']},
	{'value': 'वार्य', 'label': 'vArya', 'attrib': [{'pos': 'ger', 'defs': 'block'}, {'pos': 'ger', 'defs': 'choose'}], 'isLemma': 0, 'parents': ['vf']},
	{'value': 'वार्यमाण', 'label': 'vAryamARa', 'attrib': [{'pos': 'prpp', 'defs': 'block'}], 'isLemma': 0, 'parents': ['vf']},
	{'value': 'वास', 'label': 'vAsa', 'attrib': [{'pos': 'm', 'defs': 'dwelling, living'}], 'isLemma': 1},
	{'value': 'वासयाम्', 'label': 'vAsayAm', 'attrib': [{'pos': 'ppf_actn', 'defs': 'dwell, live'}], 'isLemma': 0, 'parents': ['vas']},
	{'value': 'वासुदेव', 'label': 'vAsudeva', 'attrib': [{'pos': 'm', 'defs': 'son of Vasudeva'}], 'isLemma': 1},
	{'value': 'वास्य', 'label': 'vAsya', 'attrib': [{'pos': 'ger', 'defs': 'dwell, live'}], 'isLemma': 0, 'parents': ['vas']},
	{'value': 'वाहन', 'label': 'vAhana', 'attrib': [{'pos': 'adj', 'defs': 'carrying'}, {'pos': 'n', 'defs': 'vehicle'}], 'isLemma': 1},
	{'value': 'वाहनीय', 'label': 'vAhanIya', 'attrib': [{'pos': 'ger', 'defs': 'carry, flow, wed'}], 'isLemma': 0, 'parents': ['vah']},
	{'value': 'वाह्य', 'label': 'vAhya', 'attrib': [{'pos': 'ger', 'defs': 'carry, flow, wed'}], 'isLemma': 0, 'parents': ['vah']},
	{'value': 'वि', 'label': 'vi', 'attrib': [{'pos': 'preverb', 'defs': 'apart'}], 'isLemma': 1},
	{'value': 'वि-वृत्य', 'label': 'vi-vftya', 'attrib': [{'pos': 'abs', 'defs': 'uncover, reveal, explain'}], 'isLemma': 0, 'parents': ['vi_vf']},
	{'value': 'विक्रम', 'label': 'vikrama', 'attrib': [{'pos': 'm', 'defs': 'wide step, valor'}], 'isLemma': 1},
	{'value': 'विग्रह', 'label': 'vigraha', 'attrib': [{'pos': 'm', 'defs': 'division, discord, war'}], 'isLemma': 1},
	{'value': 'विजयनीय', 'label': 'vijayanIya', 'attrib': [{'pos': 'ger', 'defs': 'conquer'}], 'isLemma': 0, 'parents': ['vi_ji']},
	{'value': 'विजयमान', 'label': 'vijayamAna', 'attrib': [{'pos': 'prmp', 'defs': 'conquer'}], 'isLemma': 0, 'parents': ['vi_ji']},
	{'value': 'विजिग्यान', 'label': 'vijigyAna', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'conquer'}], 'isLemma': 0, 'parents': ['vi_ji']},
	{'value': 'विजित', 'label': 'vijita', 'attrib': [{'pos': 'ppp', 'defs': 'conquer'}], 'isLemma': 0, 'parents': ['vi_ji']},
	{'value': 'विजितवत्', 'label': 'vijitavat', 'attrib': [{'pos': 'pap', 'defs': 'conquer'}], 'isLemma': 0, 'parents': ['vi_ji']},
	{'value': 'विजित्य', 'label': 'vijitya', 'attrib': [{'pos': 'abs', 'defs': 'conquer'}], 'isLemma': 0, 'parents': ['vi_ji']},
	{'value': 'विजीयमान', 'label': 'vijIyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'conquer'}], 'isLemma': 0, 'parents': ['vi_ji']},
	{'value': 'विजेतव्य', 'label': 'vijetavya', 'attrib': [{'pos': 'ger', 'defs': 'conquer'}], 'isLemma': 0, 'parents': ['vi_ji']},
	{'value': 'विजेतुम्', 'label': 'vijetum', 'attrib': [{'pos': 'inf', 'defs': 'conquer'}], 'isLemma': 0, 'parents': ['vi_ji']},
	{'value': 'विजेय', 'label': 'vijeya', 'attrib': [{'pos': 'ger', 'defs': 'conquer'}], 'isLemma': 0, 'parents': ['vi_ji']},
	{'value': 'विजेष्यमाण', 'label': 'vijezyamARa', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'conquer'}], 'isLemma': 0, 'parents': ['vi_ji']},
	{'value': 'वि_अव_सो', 'label': 'vi_ava_so', 'attrib': [{'pos': 'vt4a', 'defs': 'conclude, determine'}], 'isLemma': 1},
	{'value': 'वि_क्रम्', 'label': 'vi_kram', 'attrib': [{'pos': 'vt1a', 'defs': 'step wide, be bold, advance'}], 'isLemma': 1},
	{'value': 'वि_ग्रह्', 'label': 'vi_grah', 'attrib': [{'pos': 'vt9a', 'defs': 'take apart, analyse, wage war'}], 'isLemma': 1},
	{'value': 'वि_जि', 'label': 'vi_ji', 'attrib': [{'pos': 'vt1m', 'defs': 'conquer'}], 'isLemma': 1},
	{'value': 'वि_द्रु', 'label': 'vi_dru', 'attrib': [{'pos': 'vt1a', 'defs': 'run apart, disperse'}], 'isLemma': 1},
	{'value': 'वि_धा', 'label': 'vi_DA', 'attrib': [{'pos': 'vt3am', 'defs': 'bestow, supply, ordain'}], 'isLemma': 1},
	{'value': 'वि_नश्', 'label': 'vi_naS', 'attrib': [{'pos': 'vi4a', 'defs': 'perish'}], 'isLemma': 1},
	{'value': 'वि_नि_हन्', 'label': 'vi_ni_han', 'attrib': [{'pos': 'vt2a', 'defs': 'strike down, destroy'}], 'isLemma': 1},
	{'value': 'वि_भज्', 'label': 'vi_Baj', 'attrib': [{'pos': 'vt1am', 'defs': 'divide, distribute, apportion'}], 'isLemma': 1},
	{'value': 'वि_राज्', 'label': 'vi_rAj', 'attrib': [{'pos': 'vt1am', 'defs': 'shine, rule'}], 'isLemma': 1},
	{'value': 'वि_वृ', 'label': 'vi_vf', 'attrib': [{'pos': 'vt5am', 'defs': 'uncover, reveal, explain'}], 'isLemma': 1},
	{'value': 'वि_श्रम्भ्', 'label': 'vi_SramB', 'attrib': [{'pos': 'vi1m', 'defs': 'be careless, negligent'}], 'isLemma': 1},
	{'value': 'वि_श्रु', 'label': 'vi_Sru', 'attrib': [{'pos': 'vt1am', 'defs': 'hear widely'}], 'isLemma': 1},
	{'value': 'वि_स्रम्भ्', 'label': 'vi_sramB', 'attrib': [{'pos': 'vi1m', 'defs': 'be confident, trust, relax'}], 'isLemma': 1},
	{'value': 'वित्त', 'label': 'vitta', 'attrib': [{'pos': 'ppp', 'defs': 'find, get'}], 'isLemma': 0, 'parents': ['vid']},
	{'value': 'वित्तवत्', 'label': 'vittavat', 'attrib': [{'pos': 'pap', 'defs': 'find, get'}], 'isLemma': 0, 'parents': ['vid']},
	{'value': 'वित्त्वा', 'label': 'vittvA', 'attrib': [{'pos': 'abs', 'defs': 'find, get'}], 'isLemma': 0, 'parents': ['vid']},
	{'value': 'विद्', 'label': 'vid', 'attrib': [{'pos': 'vt2a', 'defs': 'know'}, {'pos': 'vt6am', 'defs': 'find, get'}], 'isLemma': 1},
	{'value': 'विदधत्', 'label': 'vidaDat', 'attrib': [{'pos': 'prap', 'defs': 'bestow, supply, ordain'}], 'isLemma': 0, 'parents': ['vi_DA']},
	{'value': 'विदधान', 'label': 'vidaDAna', 'attrib': [{'pos': 'prmp', 'defs': 'bestow, supply, ordain'}, {'pos': 'pfmp, pfpp', 'defs': 'bestow, supply, ordain'}], 'isLemma': 0, 'parents': ['vi_DA']},
	{'value': 'विदधिवस्', 'label': 'vidaDivas', 'attrib': [{'pos': 'pfap', 'defs': 'bestow, supply, ordain'}], 'isLemma': 0, 'parents': ['vi_DA']},
	{'value': 'विदाम्', 'label': 'vidAm', 'attrib': [{'pos': 'ppf_actn', 'defs': 'know'}], 'isLemma': 0, 'parents': ['vid']},
	{'value': 'विदित', 'label': 'vidita', 'attrib': [{'pos': 'ppp', 'defs': 'know'}], 'isLemma': 0, 'parents': ['vid']},
	{'value': 'विदितवत्', 'label': 'viditavat', 'attrib': [{'pos': 'pap', 'defs': 'know'}], 'isLemma': 0, 'parents': ['vid']},
	{'value': 'विदित्वा', 'label': 'viditvA', 'attrib': [{'pos': 'abs', 'defs': 'know'}, {'pos': 'abs', 'defs': 'find, get'}], 'isLemma': 0, 'parents': ['vid']},
	{'value': 'विदुद्रुवस्', 'label': 'vidudruvas', 'attrib': [{'pos': 'pfap', 'defs': 'run apart, disperse'}], 'isLemma': 0, 'parents': ['vi_dru']},
	{'value': 'विदुद्रुवाण', 'label': 'vidudruvARa', 'attrib': [{'pos': 'pfpp', 'defs': 'run apart, disperse'}], 'isLemma': 0, 'parents': ['vi_dru']},
	{'value': 'विदुर', 'label': 'vidura', 'attrib': [{'pos': 'adj', 'defs': 'knowledgeable, wise'}, {'pos': 'm_pn', 'defs': 'Vidura'}], 'isLemma': 1},
	{'value': 'विद्यमान', 'label': 'vidyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'know'}, {'pos': 'prpp', 'defs': 'find, get'}], 'isLemma': 0, 'parents': ['vid']},
	{'value': 'विद्रवणीय', 'label': 'vidravaRIya', 'attrib': [{'pos': 'ger', 'defs': 'run apart, disperse'}], 'isLemma': 0, 'parents': ['vi_dru']},
	{'value': 'विद्रवत्', 'label': 'vidravat', 'attrib': [{'pos': 'prap', 'defs': 'run apart, disperse'}], 'isLemma': 0, 'parents': ['vi_dru']},
	{'value': 'विद्रव्य', 'label': 'vidravya', 'attrib': [{'pos': 'ger', 'defs': 'run apart, disperse'}], 'isLemma': 0, 'parents': ['vi_dru']},
	{'value': 'विद्रुत', 'label': 'vidruta', 'attrib': [{'pos': 'ppp', 'defs': 'run apart, disperse'}], 'isLemma': 0, 'parents': ['vi_dru']},
	{'value': 'विद्रुतभूयिष्ठ', 'label': 'vidrutaBUyizWa', 'attrib': [{'pos': 'adj', 'defs': 'mostly destroyed'}], 'isLemma': 1},
	{'value': 'विद्रुतवत्', 'label': 'vidrutavat', 'attrib': [{'pos': 'pap', 'defs': 'run apart, disperse'}], 'isLemma': 0, 'parents': ['vi_dru']},
	{'value': 'विद्रुत्य', 'label': 'vidrutya', 'attrib': [{'pos': 'abs', 'defs': 'run apart, disperse'}], 'isLemma': 0, 'parents': ['vi_dru']},
	{'value': 'विद्रूयमाण', 'label': 'vidrUyamARa', 'attrib': [{'pos': 'prpp', 'defs': 'run apart, disperse'}], 'isLemma': 0, 'parents': ['vi_dru']},
	{'value': 'विद्रोतव्य', 'label': 'vidrotavya', 'attrib': [{'pos': 'ger', 'defs': 'run apart, disperse'}], 'isLemma': 0, 'parents': ['vi_dru']},
	{'value': 'विद्रोतुम्', 'label': 'vidrotum', 'attrib': [{'pos': 'inf', 'defs': 'run apart, disperse'}], 'isLemma': 0, 'parents': ['vi_dru']},
	{'value': 'विद्रोष्यत्', 'label': 'vidrozyat', 'attrib': [{'pos': 'fap', 'defs': 'run apart, disperse'}], 'isLemma': 0, 'parents': ['vi_dru']},
	{'value': 'विद्रोष्यमाण', 'label': 'vidrozyamARa', 'attrib': [{'pos': 'fpp', 'defs': 'run apart, disperse'}], 'isLemma': 0, 'parents': ['vi_dru']},
	{'value': 'विद्वस्', 'label': 'vidvas', 'attrib': [{'pos': 'prap', 'defs': 'know'}], 'isLemma': 0, 'parents': ['vid']},
	{'value': 'विधातव्य', 'label': 'viDAtavya', 'attrib': [{'pos': 'ger', 'defs': 'bestow, supply, ordain'}], 'isLemma': 0, 'parents': ['vi_DA']},
	{'value': 'विधातुम्', 'label': 'viDAtum', 'attrib': [{'pos': 'inf', 'defs': 'bestow, supply, ordain'}], 'isLemma': 0, 'parents': ['vi_DA']},
	{'value': 'विधानीय', 'label': 'viDAnIya', 'attrib': [{'pos': 'ger', 'defs': 'bestow, supply, ordain'}], 'isLemma': 0, 'parents': ['vi_DA']},
	{'value': 'विधाय', 'label': 'viDAya', 'attrib': [{'pos': 'abs', 'defs': 'bestow, supply, ordain'}], 'isLemma': 0, 'parents': ['vi_DA']},
	{'value': 'विधास्यत्', 'label': 'viDAsyat', 'attrib': [{'pos': 'fap', 'defs': 'bestow, supply, ordain'}], 'isLemma': 0, 'parents': ['vi_DA']},
	{'value': 'विधास्यमान', 'label': 'viDAsyamAna', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'bestow, supply, ordain'}], 'isLemma': 0, 'parents': ['vi_DA']},
	{'value': 'विधीयमान', 'label': 'viDIyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'bestow, supply, ordain'}], 'isLemma': 0, 'parents': ['vi_DA']},
	{'value': 'विधेय', 'label': 'viDeya', 'attrib': [{'pos': 'ger', 'defs': 'bestow, supply, ordain'}], 'isLemma': 0, 'parents': ['vi_DA']},
	{'value': 'विनशनीय', 'label': 'vinaSanIya', 'attrib': [{'pos': 'ger', 'defs': 'perish'}], 'isLemma': 0, 'parents': ['vi_naS']},
	{'value': 'विनश्य', 'label': 'vinaSya', 'attrib': [{'pos': 'abs', 'defs': 'perish'}], 'isLemma': 0, 'parents': ['vi_naS']},
	{'value': 'विनश्यत्', 'label': 'vinaSyat', 'attrib': [{'pos': 'prap', 'defs': 'perish'}], 'isLemma': 0, 'parents': ['vi_naS']},
	{'value': 'विनष्ट', 'label': 'vinazwa', 'attrib': [{'pos': 'ppp', 'defs': 'perish'}], 'isLemma': 0, 'parents': ['vi_naS']},
	{'value': 'विनष्टवत्', 'label': 'vinazwavat', 'attrib': [{'pos': 'ppp', 'defs': 'perish'}], 'isLemma': 0, 'parents': ['vi_naS']},
	{'value': 'विनष्टव्य', 'label': 'vinazwavya', 'attrib': [{'pos': 'ger', 'defs': 'perish'}], 'isLemma': 0, 'parents': ['vi_naS']},
	{'value': 'विनष्टुम्', 'label': 'vinazwum', 'attrib': [{'pos': 'inf', 'defs': 'perish'}], 'isLemma': 0, 'parents': ['vi_naS']},
	{'value': 'विनाश', 'label': 'vinASa', 'attrib': [{'pos': 'm', 'defs': 'destruction'}], 'isLemma': 1},
	{'value': 'विनाश्य', 'label': 'vinASya', 'attrib': [{'pos': 'ger', 'defs': 'perish'}], 'isLemma': 0, 'parents': ['vi_naS']},
	{'value': 'विनिघात्य', 'label': 'viniGAtya', 'attrib': [{'pos': 'ger', 'defs': 'strike down, destroy'}], 'isLemma': 0, 'parents': ['vi_ni_han']},
	{'value': 'विनिघ्नत्', 'label': 'viniGnat', 'attrib': [{'pos': 'prap', 'defs': 'strike down, destroy'}], 'isLemma': 0, 'parents': ['vi_ni_han']},
	{'value': 'विनिजघन्वस्', 'label': 'vinijaGanvas', 'attrib': [{'pos': 'pfap', 'defs': 'strike down, destroy'}], 'isLemma': 0, 'parents': ['vi_ni_han']},
	{'value': 'विनिजघ्नान', 'label': 'vinijaGnAna', 'attrib': [{'pos': 'pfpp', 'defs': 'strike down, destroy'}], 'isLemma': 0, 'parents': ['vi_ni_han']},
	{'value': 'विनिजघ्निवस्', 'label': 'vinijaGnivas', 'attrib': [{'pos': 'pfap', 'defs': 'strike down, destroy'}], 'isLemma': 0, 'parents': ['vi_ni_han']},
	{'value': 'विनिहंस्यत्', 'label': 'vinihaMsyat', 'attrib': [{'pos': 'fap', 'defs': 'strike down, destroy'}], 'isLemma': 0, 'parents': ['vi_ni_han']},
	{'value': 'विनिहंस्यमान', 'label': 'vinihaMsyamAna', 'attrib': [{'pos': 'fpp', 'defs': 'strike down, destroy'}], 'isLemma': 0, 'parents': ['vi_ni_han']},
	{'value': 'विनिहत', 'label': 'vinihata', 'attrib': [{'pos': 'ppp', 'defs': 'strike down, destroy'}], 'isLemma': 0, 'parents': ['vi_ni_han']},
	{'value': 'विनिहतवत्', 'label': 'vinihatavat', 'attrib': [{'pos': 'pap', 'defs': 'strike down, destroy'}], 'isLemma': 0, 'parents': ['vi_ni_han']},
	{'value': 'विनिहननीय', 'label': 'vinihananIya', 'attrib': [{'pos': 'ger', 'defs': 'strike down, destroy'}], 'isLemma': 0, 'parents': ['vi_ni_han']},
	{'value': 'विनिहनिष्यत्', 'label': 'vinihanizyat', 'attrib': [{'pos': 'fap', 'defs': 'strike down, destroy'}], 'isLemma': 0, 'parents': ['vi_ni_han']},
	{'value': 'विनिहनिष्यमाण', 'label': 'vinihanizyamARa', 'attrib': [{'pos': 'fpp', 'defs': 'strike down, destroy'}], 'isLemma': 0, 'parents': ['vi_ni_han']},
	{'value': 'विनिहन्तव्य', 'label': 'vinihantavya', 'attrib': [{'pos': 'ger', 'defs': 'strike down, destroy'}], 'isLemma': 0, 'parents': ['vi_ni_han']},
	{'value': 'विनिहन्तुम्', 'label': 'vinihantum', 'attrib': [{'pos': 'inf', 'defs': 'strike down, destroy'}], 'isLemma': 0, 'parents': ['vi_ni_han']},
	{'value': 'विनिहन्य', 'label': 'vinihanya', 'attrib': [{'pos': 'abs', 'defs': 'strike down, destroy'}], 'isLemma': 0, 'parents': ['vi_ni_han']},
	{'value': 'विनिहन्यमान', 'label': 'vinihanyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'strike down, destroy'}], 'isLemma': 0, 'parents': ['vi_ni_han']},
	{'value': 'विनेशिवस्', 'label': 'vineSivas', 'attrib': [{'pos': 'pfap', 'defs': 'perish'}], 'isLemma': 0, 'parents': ['vi_naS']},
	{'value': 'विनेश्वस्', 'label': 'vineSvas', 'attrib': [{'pos': 'pfap', 'defs': 'perish'}], 'isLemma': 0, 'parents': ['vi_naS']},
	{'value': 'विन्दत्', 'label': 'vindat', 'attrib': [{'pos': 'prap', 'defs': 'find, get'}], 'isLemma': 0, 'parents': ['vid']},
	{'value': 'विन्दमान', 'label': 'vindamAna', 'attrib': [{'pos': 'prmp', 'defs': 'find, get'}], 'isLemma': 0, 'parents': ['vid']},
	{'value': 'विप्रकार', 'label': 'viprakAra', 'attrib': [{'pos': 'm', 'defs': 'misdeed, offense'}], 'isLemma': 1},
	{'value': 'विभक्त', 'label': 'viBakta', 'attrib': [{'pos': 'adj', 'defs': 'divided, distributed'}, {'pos': 'ppp', 'defs': 'divide, distribute, apportion'}], 'isLemma': 1},
	{'value': 'विभक्त', 'label': 'viBakta', 'attrib': [{'pos': 'adj', 'defs': 'divided, distributed'}, {'pos': 'ppp', 'defs': 'divide, distribute, apportion'}], 'isLemma': 0, 'parents': ['vi_Baj']},
	{'value': 'विभक्तवत्', 'label': 'viBaktavat', 'attrib': [{'pos': 'pap', 'defs': 'divide, distribute, apportion'}], 'isLemma': 0, 'parents': ['vi_Baj']},
	{'value': 'विभक्तव्य', 'label': 'viBaktavya', 'attrib': [{'pos': 'ger', 'defs': 'divide, distribute, apportion'}], 'isLemma': 0, 'parents': ['vi_Baj']},
	{'value': 'विभक्तुम्', 'label': 'viBaktum', 'attrib': [{'pos': 'inf', 'defs': 'divide, distribute, apportion'}], 'isLemma': 0, 'parents': ['vi_Baj']},
	{'value': 'विभक्ष्यत्', 'label': 'viBakzyat', 'attrib': [{'pos': 'fap', 'defs': 'divide, distribute, apportion'}], 'isLemma': 0, 'parents': ['vi_Baj']},
	{'value': 'विभक्ष्यमान', 'label': 'viBakzyamAna', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'divide, distribute, apportion'}], 'isLemma': 0, 'parents': ['vi_Baj']},
	{'value': 'विभजत्', 'label': 'viBajat', 'attrib': [{'pos': 'prap', 'defs': 'divide, distribute, apportion'}], 'isLemma': 0, 'parents': ['vi_Baj']},
	{'value': 'विभजनीय', 'label': 'viBajanIya', 'attrib': [{'pos': 'ger', 'defs': 'divide, distribute, apportion'}], 'isLemma': 0, 'parents': ['vi_Baj']},
	{'value': 'विभजमान', 'label': 'viBajamAna', 'attrib': [{'pos': 'prmp', 'defs': 'divide, distribute, apportion'}], 'isLemma': 0, 'parents': ['vi_Baj']},
	{'value': 'विभज्य', 'label': 'viBajya', 'attrib': [{'pos': 'ger', 'defs': 'divide, distribute, apportion'}], 'isLemma': 0, 'parents': ['vi_Baj']},
	{'value': 'विभज्यमान', 'label': 'viBajyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'divide, distribute, apportion'}], 'isLemma': 0, 'parents': ['vi_Baj']},
	{'value': 'विभेजान', 'label': 'viBejAna', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'divide, distribute, apportion'}], 'isLemma': 0, 'parents': ['vi_Baj']},
	{'value': 'विभेजिवस्', 'label': 'viBejivas', 'attrib': [{'pos': 'pfap', 'defs': 'divide, distribute, apportion'}], 'isLemma': 0, 'parents': ['vi_Baj']},
	{'value': 'विरजमान', 'label': 'virajamAna', 'attrib': [{'pos': 'prmp', 'defs': 'shine, rule'}], 'isLemma': 0, 'parents': ['vi_rAj']},
	{'value': 'विरराजान', 'label': 'virarAjAna', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'shine, rule'}], 'isLemma': 0, 'parents': ['vi_rAj']},
	{'value': 'विरराजिवस्', 'label': 'virarAjivas', 'attrib': [{'pos': 'pfap', 'defs': 'shine, rule'}], 'isLemma': 0, 'parents': ['vi_rAj']},
	{'value': 'विराजत्', 'label': 'virAjat', 'attrib': [{'pos': 'prap', 'defs': 'shine, rule'}], 'isLemma': 0, 'parents': ['vi_rAj']},
	{'value': 'विराजनीय', 'label': 'virAjanIya', 'attrib': [{'pos': 'ger', 'defs': 'shine, rule'}], 'isLemma': 0, 'parents': ['vi_rAj']},
	{'value': 'विराजित', 'label': 'virAjita', 'attrib': [{'pos': 'ppp', 'defs': 'shine, rule'}], 'isLemma': 0, 'parents': ['vi_rAj']},
	{'value': 'विराजितवत्', 'label': 'virAjitavat', 'attrib': [{'pos': 'pap', 'defs': 'shine, rule'}], 'isLemma': 0, 'parents': ['vi_rAj']},
	{'value': 'विराजितव्य', 'label': 'virAjitavya', 'attrib': [{'pos': 'ger', 'defs': 'shine, rule'}], 'isLemma': 0, 'parents': ['vi_rAj']},
	{'value': 'विराजितुम्', 'label': 'virAjitum', 'attrib': [{'pos': 'inf', 'defs': 'shine, rule'}], 'isLemma': 0, 'parents': ['vi_rAj']},
	{'value': 'विराजिष्यत्', 'label': 'virAjizyat', 'attrib': [{'pos': 'fap', 'defs': 'shine, rule'}], 'isLemma': 0, 'parents': ['vi_rAj']},
	{'value': 'विराजिष्यमाण', 'label': 'virAjizyamARa', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'shine, rule'}], 'isLemma': 0, 'parents': ['vi_rAj']},
	{'value': 'विराज्य', 'label': 'virAjya', 'attrib': [{'pos': 'abs', 'defs': 'shine, rule'}, {'pos': 'ger', 'defs': 'shine, rule'}], 'isLemma': 0, 'parents': ['vi_rAj']},
	{'value': 'विराज्यमान', 'label': 'virAjyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'shine, rule'}], 'isLemma': 0, 'parents': ['vi_rAj']},
	{'value': 'विवरणीय', 'label': 'vivaraRIya', 'attrib': [{'pos': 'ger', 'defs': 'uncover, reveal, explain'}], 'isLemma': 0, 'parents': ['vi_vf']},
	{'value': 'विवरितव्य', 'label': 'vivaritavya', 'attrib': [{'pos': 'ger', 'defs': 'uncover, reveal, explain'}], 'isLemma': 0, 'parents': ['vi_vf']},
	{'value': 'विवरितुम्', 'label': 'vivaritum', 'attrib': [{'pos': 'inf', 'defs': 'uncover, reveal, explain'}], 'isLemma': 0, 'parents': ['vi_vf']},
	{'value': 'विवरिष्यत्', 'label': 'vivarizyat', 'attrib': [{'pos': 'fap', 'defs': 'uncover, reveal, explain'}], 'isLemma': 0, 'parents': ['vi_vf']},
	{'value': 'विवरिष्यमाण', 'label': 'vivarizyamARa', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'uncover, reveal, explain'}], 'isLemma': 0, 'parents': ['vi_vf']},
	{'value': 'विवरीतव्य', 'label': 'vivarItavya', 'attrib': [{'pos': 'ger', 'defs': 'uncover, reveal, explain'}], 'isLemma': 0, 'parents': ['vi_vf']},
	{'value': 'विवरीतुम्', 'label': 'vivarItum', 'attrib': [{'pos': 'inf', 'defs': 'uncover, reveal, explain'}], 'isLemma': 0, 'parents': ['vi_vf']},
	{'value': 'विवरीष्यत्', 'label': 'vivarIzyat', 'attrib': [{'pos': 'fap', 'defs': 'uncover, reveal, explain'}], 'isLemma': 0, 'parents': ['vi_vf']},
	{'value': 'विवरीष्यमाण', 'label': 'vivarIzyamARa', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'uncover, reveal, explain'}], 'isLemma': 0, 'parents': ['vi_vf']},
	{'value': 'विववृवस्', 'label': 'vivavfvas', 'attrib': [{'pos': 'pfap', 'defs': 'uncover, reveal, explain'}], 'isLemma': 0, 'parents': ['vi_vf']},
	{'value': 'विवव्राण', 'label': 'vivavrARa', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'uncover, reveal, explain'}], 'isLemma': 0, 'parents': ['vi_vf']},
	{'value': 'विविदान', 'label': 'vividAna', 'attrib': [{'pos': 'pfpp', 'defs': 'know'}, {'pos': 'pfmp, pfpp', 'defs': 'find, get'}], 'isLemma': 0, 'parents': ['vid']},
	{'value': 'विविदिवस्', 'label': 'vividivas', 'attrib': [{'pos': 'pfap', 'defs': 'find, get'}], 'isLemma': 0, 'parents': ['vid']},
	{'value': 'विविद्वस्', 'label': 'vividvas', 'attrib': [{'pos': 'pfap', 'defs': 'know'}, {'pos': 'pfap', 'defs': 'find, get'}], 'isLemma': 0, 'parents': ['vid']},
	{'value': 'विविध', 'label': 'viviDa', 'attrib': [{'pos': 'adj', 'defs': 'various'}], 'isLemma': 1},
	{'value': 'विविधोपाय', 'label': 'viviDopAya', 'attrib': [{'pos': 'm', 'defs': 'various means'}], 'isLemma': 1},
	{'value': 'विवृण्वत्', 'label': 'vivfRvat', 'attrib': [{'pos': 'prap', 'defs': 'uncover, reveal, explain'}], 'isLemma': 0, 'parents': ['vi_vf']},
	{'value': 'विवृण्वान', 'label': 'vivfRvAna', 'attrib': [{'pos': 'prmp', 'defs': 'uncover, reveal, explain'}], 'isLemma': 0, 'parents': ['vi_vf']},
	{'value': 'विवृत', 'label': 'vivfta', 'attrib': [{'pos': 'ppp', 'defs': 'uncover, reveal, explain'}], 'isLemma': 0, 'parents': ['vi_vf']},
	{'value': 'विवृतवत्', 'label': 'vivftavat', 'attrib': [{'pos': 'pap', 'defs': 'uncover, reveal, explain'}], 'isLemma': 0, 'parents': ['vi_vf']},
	{'value': 'विवृत्य', 'label': 'vivftya', 'attrib': [{'pos': 'ger', 'defs': 'uncover, reveal, explain'}], 'isLemma': 0, 'parents': ['vi_vf']},
	{'value': 'विवृत्वा', 'label': 'vivftvA', 'attrib': [{'pos': 'abs', 'defs': 'uncover, reveal, explain'}], 'isLemma': 0, 'parents': ['vi_vf']},
	{'value': 'विव्यथान', 'label': 'vivyaTAna', 'attrib': [{'pos': 'pfpp', 'defs': 'tremble, fear'}], 'isLemma': 0, 'parents': ['vyaT']},
	{'value': 'विव्यथिवस्', 'label': 'vivyaTivas', 'attrib': [{'pos': 'pfap', 'defs': 'tremble, fear'}], 'isLemma': 0, 'parents': ['vyaT']},
	{'value': 'विव्रियमाण', 'label': 'vivriyamARa', 'attrib': [{'pos': 'prpp', 'defs': 'uncover, reveal, explain'}], 'isLemma': 0, 'parents': ['vi_vf']},
	{'value': 'विश्', 'label': 'viS', 'attrib': [{'pos': 'm', 'defs': 'settler, people'}], 'isLemma': 1},
	{'value': 'विशंप', 'label': 'viSaMpa', 'attrib': [{'pos': 'adj', 'defs': 'people-protecting'}], 'isLemma': 1},
	{'value': 'विशश्रम्भाण', 'label': 'viSaSramBARa', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'be careless, negligent'}], 'isLemma': 0, 'parents': ['vi_SramB']},
	{'value': 'विशृण्वत्', 'label': 'viSfRvat', 'attrib': [{'pos': 'prap', 'defs': 'hear widely'}], 'isLemma': 0, 'parents': ['vi_Sru']},
	{'value': 'विश्रब्ध', 'label': 'viSrabDa', 'attrib': [{'pos': 'ppp', 'defs': 'be careless, negligent'}], 'isLemma': 0, 'parents': ['vi_SramB']},
	{'value': 'विश्रब्धवत्', 'label': 'viSrabDavat', 'attrib': [{'pos': 'pap', 'defs': 'be careless, negligent'}], 'isLemma': 0, 'parents': ['vi_SramB']},
	{'value': 'विश्रभ्य', 'label': 'viSraBya', 'attrib': [{'pos': 'abs', 'defs': 'be careless, negligent'}], 'isLemma': 0, 'parents': ['vi_SramB']},
	{'value': 'विश्रभ्यमाण', 'label': 'viSraByamARa', 'attrib': [{'pos': 'prpp', 'defs': 'be careless, negligent'}], 'isLemma': 0, 'parents': ['vi_SramB']},
	{'value': 'विश्रम्भणीय', 'label': 'viSramBaRIya', 'attrib': [{'pos': 'ger', 'defs': 'be careless, negligent'}], 'isLemma': 0, 'parents': ['vi_SramB']},
	{'value': 'विश्रम्भमाण', 'label': 'viSramBamARa', 'attrib': [{'pos': 'prmp', 'defs': 'be careless, negligent'}], 'isLemma': 0, 'parents': ['vi_SramB']},
	{'value': 'विश्रम्भितव्य', 'label': 'viSramBitavya', 'attrib': [{'pos': 'ger', 'defs': 'be careless, negligent'}], 'isLemma': 0, 'parents': ['vi_SramB']},
	{'value': 'विश्रम्भितुम्', 'label': 'viSramBitum', 'attrib': [{'pos': 'inf', 'defs': 'be careless, negligent'}], 'isLemma': 0, 'parents': ['vi_SramB']},
	{'value': 'विश्रम्भिष्यमाण', 'label': 'viSramBizyamARa', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'be careless, negligent'}], 'isLemma': 0, 'parents': ['vi_SramB']},
	{'value': 'विश्रम्भ्य', 'label': 'viSramBya', 'attrib': [{'pos': 'ger', 'defs': 'be careless, negligent'}], 'isLemma': 0, 'parents': ['vi_SramB']},
	{'value': 'विश्रवणीय', 'label': 'viSravaRIya', 'attrib': [{'pos': 'ger', 'defs': 'hear widely'}], 'isLemma': 0, 'parents': ['vi_Sru']},
	{'value': 'विश्रव्य', 'label': 'viSravya', 'attrib': [{'pos': 'ger', 'defs': 'hear widely'}], 'isLemma': 0, 'parents': ['vi_Sru']},
	{'value': 'विश्रुत', 'label': 'viSruta', 'attrib': [{'pos': 'ppp', 'defs': 'hear widely'}], 'isLemma': 0, 'parents': ['vi_Sru']},
	{'value': 'विश्रुतवत्', 'label': 'viSrutavat', 'attrib': [{'pos': 'pap', 'defs': 'hear widely'}], 'isLemma': 0, 'parents': ['vi_Sru']},
	{'value': 'विश्रुत्य', 'label': 'viSrutya', 'attrib': [{'pos': 'abs', 'defs': 'hear widely'}], 'isLemma': 0, 'parents': ['vi_Sru']},
	{'value': 'विश्रूयमाण', 'label': 'viSrUyamARa', 'attrib': [{'pos': 'prpp', 'defs': 'hear widely'}], 'isLemma': 0, 'parents': ['vi_Sru']},
	{'value': 'विश्रोतव्य', 'label': 'viSrotavya', 'attrib': [{'pos': 'ger', 'defs': 'hear widely'}], 'isLemma': 0, 'parents': ['vi_Sru']},
	{'value': 'विश्रोतुम्', 'label': 'viSrotum', 'attrib': [{'pos': 'inf', 'defs': 'hear widely'}], 'isLemma': 0, 'parents': ['vi_Sru']},
	{'value': 'विष', 'label': 'viza', 'attrib': [{'pos': 'adj', 'defs': 'active, poisonous'}, {'pos': 'n', 'defs': 'poison'}], 'isLemma': 1},
	{'value': 'विष्णु', 'label': 'vizRu', 'attrib': [{'pos': 'm_pn', 'defs': 'Viṣṇu'}], 'isLemma': 1},
	{'value': 'विसस्रम्भाण', 'label': 'visasramBARa', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'be confident, trust, relax'}], 'isLemma': 0, 'parents': ['vi_sramB']},
	{'value': 'विस्रब्ध', 'label': 'visrabDa', 'attrib': [{'pos': 'ppp', 'defs': 'be confident, trust, relax'}], 'isLemma': 0, 'parents': ['vi_sramB']},
	{'value': 'विस्रब्धवत्', 'label': 'visrabDavat', 'attrib': [{'pos': 'pap', 'defs': 'be confident, trust, relax'}], 'isLemma': 0, 'parents': ['vi_sramB']},
	{'value': 'विस्रभ्य', 'label': 'visraBya', 'attrib': [{'pos': 'abs', 'defs': 'be confident, trust, relax'}], 'isLemma': 0, 'parents': ['vi_sramB']},
	{'value': 'विस्रभ्यमाण', 'label': 'visraByamARa', 'attrib': [{'pos': 'prpp', 'defs': 'be confident, trust, relax'}], 'isLemma': 0, 'parents': ['vi_sramB']},
	{'value': 'विस्रम्भणीय', 'label': 'visramBaRIya', 'attrib': [{'pos': 'ger', 'defs': 'be confident, trust, relax'}], 'isLemma': 0, 'parents': ['vi_sramB']},
	{'value': 'विस्रम्भमाण', 'label': 'visramBamARa', 'attrib': [{'pos': 'prmp', 'defs': 'be confident, trust, relax'}], 'isLemma': 0, 'parents': ['vi_sramB']},
	{'value': 'विस्रम्भितव्य', 'label': 'visramBitavya', 'attrib': [{'pos': 'ger', 'defs': 'be confident, trust, relax'}], 'isLemma': 0, 'parents': ['vi_sramB']},
	{'value': 'विस्रम्भितुम्', 'label': 'visramBitum', 'attrib': [{'pos': 'inf', 'defs': 'be confident, trust, relax'}], 'isLemma': 0, 'parents': ['vi_sramB']},
	{'value': 'विस्रम्भिष्यमाण', 'label': 'visramBizyamARa', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'be confident, trust, relax'}], 'isLemma': 0, 'parents': ['vi_sramB']},
	{'value': 'विस्रम्भ्य', 'label': 'visramBya', 'attrib': [{'pos': 'ger', 'defs': 'be confident, trust, relax'}], 'isLemma': 0, 'parents': ['vi_sramB']},
	{'value': 'विहित', 'label': 'vihita', 'attrib': [{'pos': 'ppp', 'defs': 'bestow, supply, ordain'}], 'isLemma': 0, 'parents': ['vi_DA']},
	{'value': 'विहितवत्', 'label': 'vihitavat', 'attrib': [{'pos': 'pap', 'defs': 'bestow, supply, ordain'}], 'isLemma': 0, 'parents': ['vi_DA']},
	{'value': 'वीर', 'label': 'vIra', 'attrib': [{'pos': 'adj', 'defs': 'heroic'}, {'pos': 'm', 'defs': 'hero'}], 'isLemma': 1},
	{'value': 'वीर्य', 'label': 'vIrya', 'attrib': [{'pos': 'n', 'defs': 'virility, power'}], 'isLemma': 1},
	{'value': 'वृ', 'label': 'vf', 'attrib': [{'pos': 'vt5am', 'defs': 'cover'}, {'pos': 'vt10am', 'defs': 'block'}, {'pos': 'vt9m', 'defs': 'choose'}], 'isLemma': 1},
	{'value': 'वृक', 'label': 'vfka', 'attrib': [{'pos': 'm', 'defs': 'wolf'}], 'isLemma': 1},
	{'value': 'वृकोदर', 'label': 'vfkodara', 'attrib': [{'pos': 'adj', 'defs': 'wolf-bellied'}], 'isLemma': 1},
	{'value': 'वृणान', 'label': 'vfRAna', 'attrib': [{'pos': 'prmp', 'defs': 'choose'}], 'isLemma': 0, 'parents': ['vf']},
	{'value': 'वृण्वत्', 'label': 'vfRvat', 'attrib': [{'pos': 'prap', 'defs': 'cover'}], 'isLemma': 0, 'parents': ['vf']},
	{'value': 'वृण्वान', 'label': 'vfRvAna', 'attrib': [{'pos': 'prmp', 'defs': 'cover'}], 'isLemma': 0, 'parents': ['vf']},
	{'value': 'वृत्', 'label': 'vft', 'attrib': [{'pos': 'vi1m', 'defs': 'occur, be present'}], 'isLemma': 1},
	{'value': 'वृत', 'label': 'vfta', 'attrib': [{'pos': 'ppp', 'defs': 'cover'}, {'pos': 'ppp', 'defs': 'choose'}], 'isLemma': 0, 'parents': ['vf']},
	{'value': 'वृतवत्', 'label': 'vftavat', 'attrib': [{'pos': 'pap', 'defs': 'cover'}, {'pos': 'pap', 'defs': 'choose'}], 'isLemma': 0, 'parents': ['vf']},
	{'value': 'वृत्त', 'label': 'vftta', 'attrib': [{'pos': 'ppp', 'defs': 'occur, be present'}, {'pos': 'n', 'defs': 'occurrence, event'}], 'isLemma': 1},
	{'value': 'वृत्त', 'label': 'vftta', 'attrib': [{'pos': 'ppp', 'defs': 'occur, be present'}, {'pos': 'n', 'defs': 'occurrence, event'}], 'isLemma': 0, 'parents': ['vft']},
	{'value': 'वृत्तवत्', 'label': 'vfttavat', 'attrib': [{'pos': 'pap', 'defs': 'occur, be present'}], 'isLemma': 0, 'parents': ['vft']},
	{'value': 'वृत्य', 'label': 'vftya', 'attrib': [{'pos': 'ger', 'defs': 'cover'}, {'pos': 'ger', 'defs': 'occur, be present'}], 'isLemma': 0, 'parents': ['vf', 'vft']},
	{'value': 'वृत्वा', 'label': 'vftvA', 'attrib': [{'pos': 'abs', 'defs': 'cover'}, {'pos': 'abs', 'defs': 'choose'}], 'isLemma': 0, 'parents': ['vf']},
	{'value': 'वृष्', 'label': 'vfz', 'attrib': [{'pos': 'vi1a', 'defs': 'rain'}], 'isLemma': 1},
	{'value': 'वृषदुःशासन', 'label': 'vfzaduHSAsana', 'attrib': [{'pos': 'm', 'defs': 'Karṇa and Duḥśāsana'}], 'isLemma': 1},
	{'value': 'वृषदुःशासनादि', 'label': 'vfzaduHSAsanAdi', 'attrib': [{'pos': 'adj', 'defs': 'beginning with Karṇa and Duḥśāsana'}], 'isLemma': 1},
	{'value': 'वृषन्', 'label': 'vfzan', 'attrib': [{'pos': 'm', 'defs': 'bull'}], 'isLemma': 1},
	{'value': 'वृष्ट', 'label': 'vfzwa', 'attrib': [{'pos': 'ppp', 'defs': 'rain'}], 'isLemma': 0, 'parents': ['vfz']},
	{'value': 'वृष्टवत्', 'label': 'vfzwavat', 'attrib': [{'pos': 'pap', 'defs': 'rain'}], 'isLemma': 0, 'parents': ['vfz']},
	{'value': 'वृष्ट्वा', 'label': 'vfzwvA', 'attrib': [{'pos': 'abs', 'defs': 'rain'}], 'isLemma': 0, 'parents': ['vfz']},
	{'value': 'वृष्य', 'label': 'vfzya', 'attrib': [{'pos': 'ger', 'defs': 'rain'}], 'isLemma': 0, 'parents': ['vfz']},
	{'value': 'वृष्यमाण', 'label': 'vfzyamARa', 'attrib': [{'pos': 'prpp', 'defs': 'rain'}], 'isLemma': 0, 'parents': ['vfz']},
	{'value': 'वेत्तव्य', 'label': 'vettavya', 'attrib': [{'pos': 'ger', 'defs': 'find, get'}], 'isLemma': 0, 'parents': ['vid']},
	{'value': 'वेत्तुम्', 'label': 'vettum', 'attrib': [{'pos': 'inf', 'defs': 'find, get'}], 'isLemma': 0, 'parents': ['vid']},
	{'value': 'वेद', 'label': 'veda', 'attrib': [{'pos': 'm', 'defs': 'knowledge, Vedic texts'}], 'isLemma': 1},
	{'value': 'वेदनीय', 'label': 'vedanIya', 'attrib': [{'pos': 'ger', 'defs': 'know'}, {'pos': 'ger', 'defs': 'find, get'}], 'isLemma': 0, 'parents': ['vid']},
	{'value': 'वेदितव्य', 'label': 'veditavya', 'attrib': [{'pos': 'ger', 'defs': 'know'}], 'isLemma': 0, 'parents': ['vid']},
	{'value': 'वेदितुम्', 'label': 'veditum', 'attrib': [{'pos': 'inf', 'defs': 'know'}], 'isLemma': 0, 'parents': ['vid']},
	{'value': 'वेदित्वा', 'label': 'veditvA', 'attrib': [{'pos': 'abs', 'defs': 'find, get'}], 'isLemma': 0, 'parents': ['vid']},
	{'value': 'वेदिष्यत्', 'label': 'vedizyat', 'attrib': [{'pos': 'fap', 'defs': 'know'}, {'pos': 'fap', 'defs': 'find, get'}], 'isLemma': 0, 'parents': ['vid']},
	{'value': 'वेदिष्यमाण', 'label': 'vedizyamARa', 'attrib': [{'pos': 'fpp', 'defs': 'know'}, {'pos': 'fmp, fpp', 'defs': 'find, get'}], 'isLemma': 0, 'parents': ['vid']},
	{'value': 'वेद्य', 'label': 'vedya', 'attrib': [{'pos': 'ger', 'defs': 'know'}, {'pos': 'ger', 'defs': 'find, get'}], 'isLemma': 0, 'parents': ['vid']},
	{'value': 'वै', 'label': 'vE', 'attrib': [{'pos': 'pcl', 'defs': 'verily'}], 'isLemma': 1},
	{'value': 'वैशंपायन', 'label': 'vESaMpAyana', 'attrib': [{'pos': 'm_pn', 'defs': 'Vaiśaṁpāyana'}], 'isLemma': 1},
	{'value': 'वोढव्य', 'label': 'voQavya', 'attrib': [{'pos': 'ger', 'defs': 'carry, flow, wed'}], 'isLemma': 0, 'parents': ['vah']},
	{'value': 'वोढुम्', 'label': 'voQum', 'attrib': [{'pos': 'inf', 'defs': 'carry, flow, wed'}], 'isLemma': 0, 'parents': ['vah']},
	{'value': 'व्यथ्', 'label': 'vyaT', 'attrib': [{'pos': 'vi1m', 'defs': 'tremble, fear'}], 'isLemma': 1},
	{'value': 'व्यथनीय', 'label': 'vyaTanIya', 'attrib': [{'pos': 'ger', 'defs': 'tremble, fear'}], 'isLemma': 0, 'parents': ['vyaT']},
	{'value': 'व्यथमान', 'label': 'vyaTamAna', 'attrib': [{'pos': 'prmp', 'defs': 'tremble, fear'}], 'isLemma': 0, 'parents': ['vyaT']},
	{'value': 'व्यथा', 'label': 'vyaTA', 'attrib': [{'pos': 'f', 'defs': 'trembling, fear'}], 'isLemma': 1},
	{'value': 'व्यथित', 'label': 'vyaTita', 'attrib': [{'pos': 'ppp', 'defs': 'tremble, fear'}], 'isLemma': 0, 'parents': ['vyaT']},
	{'value': 'व्यथितवत्', 'label': 'vyaTitavat', 'attrib': [{'pos': 'pap', 'defs': 'tremble, fear'}], 'isLemma': 0, 'parents': ['vyaT']},
	{'value': 'व्यथितव्य', 'label': 'vyaTitavya', 'attrib': [{'pos': 'ger', 'defs': 'tremble, fear'}], 'isLemma': 0, 'parents': ['vyaT']},
	{'value': 'व्यथितुम्', 'label': 'vyaTitum', 'attrib': [{'pos': 'inf', 'defs': 'tremble, fear'}], 'isLemma': 0, 'parents': ['vyaT']},
	{'value': 'व्यथित्वा', 'label': 'vyaTitvA', 'attrib': [{'pos': 'abs', 'defs': 'tremble, fear'}], 'isLemma': 0, 'parents': ['vyaT']},
	{'value': 'व्यथिष्यमाण', 'label': 'vyaTizyamARa', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'tremble, fear'}], 'isLemma': 0, 'parents': ['vyaT']},
	{'value': 'व्यथ्य', 'label': 'vyaTya', 'attrib': [{'pos': 'ger', 'defs': 'tremble, fear'}], 'isLemma': 0, 'parents': ['vyaT']},
	{'value': 'व्यथ्यमान', 'label': 'vyaTyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'tremble, fear'}], 'isLemma': 0, 'parents': ['vyaT']},
	{'value': 'व्यवससान', 'label': 'vyavasasAna', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'conclude, determine'}], 'isLemma': 0, 'parents': ['vi_ava_so']},
	{'value': 'व्यवससिवस्', 'label': 'vyavasasivas', 'attrib': [{'pos': 'pfap', 'defs': 'conclude, determine'}], 'isLemma': 0, 'parents': ['vi_ava_so']},
	{'value': 'व्यवसातव्य', 'label': 'vyavasAtavya', 'attrib': [{'pos': 'ger', 'defs': 'conclude, determine'}], 'isLemma': 0, 'parents': ['vi_ava_so']},
	{'value': 'व्यवसातुम्', 'label': 'vyavasAtum', 'attrib': [{'pos': 'inf', 'defs': 'conclude, determine'}], 'isLemma': 0, 'parents': ['vi_ava_so']},
	{'value': 'व्यवसानीय', 'label': 'vyavasAnIya', 'attrib': [{'pos': 'ger', 'defs': 'conclude, determine'}], 'isLemma': 0, 'parents': ['vi_ava_so']},
	{'value': 'व्यवसाय', 'label': 'vyavasAya', 'attrib': [{'pos': 'abs', 'defs': 'conclude, determine'}, {'pos': 'm', 'defs': 'resolution, determination'}], 'isLemma': 1},
	{'value': 'व्यवसाय', 'label': 'vyavasAya', 'attrib': [{'pos': 'abs', 'defs': 'conclude, determine'}, {'pos': 'm', 'defs': 'resolution, determination'}], 'isLemma': 0, 'parents': ['vi_ava_so']},
	{'value': 'व्यवसायसहाय', 'label': 'vyavasAyasahAya', 'attrib': [{'pos': 'adj', 'defs': 'resolute'}], 'isLemma': 1},
	{'value': 'व्यवसास्यत्', 'label': 'vyavasAsyat', 'attrib': [{'pos': 'fap', 'defs': 'conclude, determine'}], 'isLemma': 0, 'parents': ['vi_ava_so']},
	{'value': 'व्यवसास्यमान', 'label': 'vyavasAsyamAna', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'conclude, determine'}], 'isLemma': 0, 'parents': ['vi_ava_so']},
	{'value': 'व्यवसित', 'label': 'vyavasita', 'attrib': [{'pos': 'ppp', 'defs': 'conclude, determine'}], 'isLemma': 0, 'parents': ['vi_ava_so']},
	{'value': 'व्यवसितवत्', 'label': 'vyavasitavat', 'attrib': [{'pos': 'pap', 'defs': 'conclude, determine'}], 'isLemma': 0, 'parents': ['vi_ava_so']},
	{'value': 'व्यवसीयमान', 'label': 'vyavasIyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'conclude, determine'}], 'isLemma': 0, 'parents': ['vi_ava_so']},
	{'value': 'व्यवसेय', 'label': 'vyavaseya', 'attrib': [{'pos': 'ger', 'defs': 'conclude, determine'}], 'isLemma': 0, 'parents': ['vi_ava_so']},
	{'value': 'व्यवस्यत्', 'label': 'vyavasyat', 'attrib': [{'pos': 'prap', 'defs': 'conclude, determine'}], 'isLemma': 0, 'parents': ['vi_ava_so']},
	{'value': 'व्यवस्यमान', 'label': 'vyavasyamAna', 'attrib': [{'pos': 'prmp', 'defs': 'conclude, determine'}], 'isLemma': 0, 'parents': ['vi_ava_so']},
	{'value': 'व्यास', 'label': 'vyAsa', 'attrib': [{'pos': 'm_pn', 'defs': 'Vyāsa'}], 'isLemma': 1},
	{'value': 'व्रज्', 'label': 'vraj', 'attrib': [{'pos': 'vt1a', 'defs': 'walk'}], 'isLemma': 1},
	{'value': 'व्रजत्', 'label': 'vrajat', 'attrib': [{'pos': 'prap', 'defs': 'walk'}], 'isLemma': 0, 'parents': ['vraj']},
	{'value': 'व्रजनीय', 'label': 'vrajanIya', 'attrib': [{'pos': 'ger', 'defs': 'walk'}], 'isLemma': 0, 'parents': ['vraj']},
	{'value': 'व्रजित', 'label': 'vrajita', 'attrib': [{'pos': 'ppp', 'defs': 'walk'}], 'isLemma': 0, 'parents': ['vraj']},
	{'value': 'व्रजितवत्', 'label': 'vrajitavat', 'attrib': [{'pos': 'pap', 'defs': 'walk'}], 'isLemma': 0, 'parents': ['vraj']},
	{'value': 'व्रजितव्य', 'label': 'vrajitavya', 'attrib': [{'pos': 'ger', 'defs': 'walk'}], 'isLemma': 0, 'parents': ['vraj']},
	{'value': 'व्रजितुम्', 'label': 'vrajitum', 'attrib': [{'pos': 'inf', 'defs': 'walk'}], 'isLemma': 0, 'parents': ['vraj']},
	{'value': 'व्रजित्वा', 'label': 'vrajitvA', 'attrib': [{'pos': 'abs', 'defs': 'walk'}], 'isLemma': 0, 'parents': ['vraj']},
	{'value': 'व्रजिष्यत्', 'label': 'vrajizyat', 'attrib': [{'pos': 'fap', 'defs': 'walk'}], 'isLemma': 0, 'parents': ['vraj']},
	{'value': 'व्रजिष्यमाण', 'label': 'vrajizyamARa', 'attrib': [{'pos': 'fpp', 'defs': 'walk'}], 'isLemma': 0, 'parents': ['vraj']},
	{'value': 'व्रज्यमान', 'label': 'vrajyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'walk'}], 'isLemma': 0, 'parents': ['vraj']},
	{'value': 'व्रत', 'label': 'vrata', 'attrib': [{'pos': 'n', 'defs': 'vow'}], 'isLemma': 1},
	{'value': 'व्राज्य', 'label': 'vrAjya', 'attrib': [{'pos': 'ger', 'defs': 'walk'}], 'isLemma': 0, 'parents': ['vraj']},
	{'value': 'व्रियमाण', 'label': 'vriyamARa', 'attrib': [{'pos': 'prpp', 'defs': 'cover'}, {'pos': 'prpp', 'defs': 'choose'}], 'isLemma': 0, 'parents': ['vf']},
	{'value': 'शंतनु', 'label': 'SaMtanu', 'attrib': [{'pos': 'm', 'defs': 'Śaṁtanu'}], 'isLemma': 1},
	{'value': 'शक्', 'label': 'Sak', 'attrib': [{'pos': 'vi5a', 'defs': 'be able'}], 'isLemma': 1},
	{'value': 'शकनीय', 'label': 'SakanIya', 'attrib': [{'pos': 'ger', 'defs': 'be able'}], 'isLemma': 0, 'parents': ['Sak']},
	{'value': 'शकुनि', 'label': 'Sakuni', 'attrib': [{'pos': 'm_pn', 'defs': 'Śakuni'}], 'isLemma': 1},
	{'value': 'शक्त', 'label': 'Sakta', 'attrib': [{'pos': 'ppp', 'defs': 'be able'}], 'isLemma': 0, 'parents': ['Sak']},
	{'value': 'शक्तवत्', 'label': 'Saktavat', 'attrib': [{'pos': 'pap', 'defs': 'be able'}], 'isLemma': 0, 'parents': ['Sak']},
	{'value': 'शक्तव्य', 'label': 'Saktavya', 'attrib': [{'pos': 'ger', 'defs': 'be able'}], 'isLemma': 0, 'parents': ['Sak']},
	{'value': 'शक्तुम्', 'label': 'Saktum', 'attrib': [{'pos': 'inf', 'defs': 'be able'}], 'isLemma': 0, 'parents': ['Sak']},
	{'value': 'शक्त्वा', 'label': 'SaktvA', 'attrib': [{'pos': 'abs', 'defs': 'be able'}], 'isLemma': 0, 'parents': ['Sak']},
	{'value': 'शक्नुवत्', 'label': 'Saknuvat', 'attrib': [{'pos': 'prap', 'defs': 'be able'}], 'isLemma': 0, 'parents': ['Sak']},
	{'value': 'शक्य', 'label': 'Sakya', 'attrib': [{'pos': 'ger', 'defs': 'be able'}], 'isLemma': 0, 'parents': ['Sak']},
	{'value': 'शक्यमान', 'label': 'SakyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'be able'}], 'isLemma': 0, 'parents': ['Sak']},
	{'value': 'शक्र', 'label': 'Sakra', 'attrib': [{'pos': 'adj', 'defs': 'able, capable, powerful'}, {'pos': 'm', 'defs': 'epithet of Indra'}], 'isLemma': 1},
	{'value': 'शची', 'label': 'SacI', 'attrib': [{'pos': 'f', 'defs': 'strength'}, {'pos': 'f_pn', 'defs': 'Śacī'}], 'isLemma': 1},
	{'value': 'शत्रु', 'label': 'Satru', 'attrib': [{'pos': 'm', 'defs': 'feller, enemy'}], 'isLemma': 1},
	{'value': 'शत्रुवध', 'label': 'SatruvaDa', 'attrib': [{'pos': 'm', 'defs': 'slaughter of enemies'}], 'isLemma': 1},
	{'value': 'शत्रुहन्', 'label': 'Satruhan', 'attrib': [{'pos': 'm', 'defs': 'enemy-slayer'}], 'isLemma': 1},
	{'value': 'शम्', 'label': 'Sam', 'attrib': [{'pos': 'n', 'defs': 'peace, happiness'}, {'pos': 'pcl', 'defs': 'auspiciously, happily'}], 'isLemma': 1},
	{'value': 'शशासान', 'label': 'SaSAsAna', 'attrib': [{'pos': 'pfpp', 'defs': 'rule, govern, teach'}], 'isLemma': 0, 'parents': ['SAs']},
	{'value': 'शशास्वस्', 'label': 'SaSAsvas', 'attrib': [{'pos': 'pfap', 'defs': 'rule, govern, teach'}], 'isLemma': 0, 'parents': ['SAs']},
	{'value': 'शश्रम्भाण', 'label': 'SaSramBARa', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'be careless, negligent'}], 'isLemma': 0, 'parents': ['SramB']},
	{'value': 'शस्त्र', 'label': 'Sastra', 'attrib': [{'pos': 'n', 'defs': 'sword'}], 'isLemma': 1},
	{'value': 'शस्त्रप्रताप', 'label': 'SastrapratApa', 'attrib': [{'pos': 'm', 'defs': 'blazing of swords'}], 'isLemma': 1},
	{'value': 'शांतनव', 'label': 'SAMtanava', 'attrib': [{'pos': 'm', 'defs': 'son of Śaṁtanu'}], 'isLemma': 1},
	{'value': 'शाला', 'label': 'SAlA', 'attrib': [{'pos': 'f', 'defs': 'hall'}], 'isLemma': 1},
	{'value': 'शास्', 'label': 'SAs', 'attrib': [{'pos': 'vt2a', 'defs': 'rule, govern, teach'}], 'isLemma': 1},
	{'value': 'शासत्', 'label': 'SAsat', 'attrib': [{'pos': 'prap', 'defs': 'rule, govern, teach'}], 'isLemma': 0, 'parents': ['SAs']},
	{'value': 'शासनीय', 'label': 'SAsanIya', 'attrib': [{'pos': 'ger', 'defs': 'rule, govern, teach'}], 'isLemma': 0, 'parents': ['SAs']},
	{'value': 'शासितव्य', 'label': 'SAsitavya', 'attrib': [{'pos': 'ger', 'defs': 'rule, govern, teach'}], 'isLemma': 0, 'parents': ['SAs']},
	{'value': 'शासितुम्', 'label': 'SAsitum', 'attrib': [{'pos': 'inf', 'defs': 'rule, govern, teach'}], 'isLemma': 0, 'parents': ['SAs']},
	{'value': 'शासित्वा', 'label': 'SAsitvA', 'attrib': [{'pos': 'abs', 'defs': 'rule, govern, teach'}], 'isLemma': 0, 'parents': ['SAs']},
	{'value': 'शासिष्यत्', 'label': 'SAsizyat', 'attrib': [{'pos': 'fap', 'defs': 'rule, govern, teach'}], 'isLemma': 0, 'parents': ['SAs']},
	{'value': 'शासिष्यमाण', 'label': 'SAsizyamARa', 'attrib': [{'pos': 'fpp', 'defs': 'rule, govern, teach'}], 'isLemma': 0, 'parents': ['SAs']},
	{'value': 'शिष्ट', 'label': 'Sizwa', 'attrib': [{'pos': 'ppp', 'defs': 'rule, govern, teach'}], 'isLemma': 0, 'parents': ['SAs']},
	{'value': 'शिष्टवत्', 'label': 'Sizwavat', 'attrib': [{'pos': 'pap', 'defs': 'rule, govern, teach'}], 'isLemma': 0, 'parents': ['SAs']},
	{'value': 'शिष्ट्वा', 'label': 'SizwvA', 'attrib': [{'pos': 'abs', 'defs': 'rule, govern, teach'}], 'isLemma': 0, 'parents': ['SAs']},
	{'value': 'शिष्य', 'label': 'Sizya', 'attrib': [{'pos': 'ger', 'defs': 'rule, govern, teach'}], 'isLemma': 0, 'parents': ['SAs']},
	{'value': 'शिष्यमाण', 'label': 'SizyamARa', 'attrib': [{'pos': 'prpp', 'defs': 'rule, govern, teach'}], 'isLemma': 0, 'parents': ['SAs']},
	{'value': 'शृण्वत्', 'label': 'SfRvat', 'attrib': [{'pos': 'prap', 'defs': 'hear, listen'}], 'isLemma': 0, 'parents': ['Sru']},
	{'value': 'श्रब्ध', 'label': 'SrabDa', 'attrib': [{'pos': 'ppp', 'defs': 'be careless, negligent'}], 'isLemma': 0, 'parents': ['SramB']},
	{'value': 'श्रब्धवत्', 'label': 'SrabDavat', 'attrib': [{'pos': 'pap', 'defs': 'be careless, negligent'}], 'isLemma': 0, 'parents': ['SramB']},
	{'value': 'श्रब्ध्वा', 'label': 'SrabDvA', 'attrib': [{'pos': 'abs', 'defs': 'be careless, negligent'}], 'isLemma': 0, 'parents': ['SramB']},
	{'value': 'श्रभ्यमाण', 'label': 'SraByamARa', 'attrib': [{'pos': 'prpp', 'defs': 'be careless, negligent'}], 'isLemma': 0, 'parents': ['SramB']},
	{'value': 'श्रम्भ्', 'label': 'SramB', 'attrib': [{'pos': 'vi1m', 'defs': 'be careless, negligent'}], 'isLemma': 1},
	{'value': 'श्रम्भणीय', 'label': 'SramBaRIya', 'attrib': [{'pos': 'ger', 'defs': 'be careless, negligent'}], 'isLemma': 0, 'parents': ['SramB']},
	{'value': 'श्रम्भमाण', 'label': 'SramBamARa', 'attrib': [{'pos': 'prmp', 'defs': 'be careless, negligent'}], 'isLemma': 0, 'parents': ['SramB']},
	{'value': 'श्रम्भितव्य', 'label': 'SramBitavya', 'attrib': [{'pos': 'ger', 'defs': 'be careless, negligent'}], 'isLemma': 0, 'parents': ['SramB']},
	{'value': 'श्रम्भितुम्', 'label': 'SramBitum', 'attrib': [{'pos': 'inf', 'defs': 'be careless, negligent'}], 'isLemma': 0, 'parents': ['SramB']},
	{'value': 'श्रम्भित्वा', 'label': 'SramBitvA', 'attrib': [{'pos': 'abs', 'defs': 'be careless, negligent'}], 'isLemma': 0, 'parents': ['SramB']},
	{'value': 'श्रम्भिष्यमाण', 'label': 'SramBizyamARa', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'be careless, negligent'}], 'isLemma': 0, 'parents': ['SramB']},
	{'value': 'श्रम्भ्य', 'label': 'SramBya', 'attrib': [{'pos': 'ger', 'defs': 'be careless, negligent'}], 'isLemma': 0, 'parents': ['SramB']},
	{'value': 'श्रवणीय', 'label': 'SravaRIya', 'attrib': [{'pos': 'ger', 'defs': 'hear, listen'}], 'isLemma': 0, 'parents': ['Sru']},
	{'value': 'श्रव्य', 'label': 'Sravya', 'attrib': [{'pos': 'ger', 'defs': 'hear, listen'}], 'isLemma': 0, 'parents': ['Sru']},
	{'value': 'श्री', 'label': 'SrI', 'attrib': [{'pos': 'f', 'defs': 'radiance, glory, wealth'}], 'isLemma': 1},
	{'value': 'श्रीमत्', 'label': 'SrImat', 'attrib': [{'pos': 'adj', 'defs': 'radiant, glorious'}], 'isLemma': 1},
	{'value': 'श्रीमन्महाभारत', 'label': 'SrImanmahABArata', 'attrib': [{'pos': 'n', 'defs': 'the glorious epic <i>Mahābhārata</i>'}], 'isLemma': 1},
	{'value': 'श्रीयशस्', 'label': 'SrIyaSas', 'attrib': [{'pos': 'n', 'defs': 'glory and fame'}], 'isLemma': 1},
	{'value': 'श्रीयशोभृत्', 'label': 'SrIyaSoBft', 'attrib': [{'pos': 'adj', 'defs': 'bearing glory and fame'}], 'isLemma': 1},
	{'value': 'श्रु', 'label': 'Sru', 'attrib': [{'pos': 'vt1am', 'defs': 'hear, listen'}], 'isLemma': 1},
	{'value': 'श्रुत', 'label': 'Sruta', 'attrib': [{'pos': 'ppp', 'defs': 'hear, listen'}], 'isLemma': 0, 'parents': ['Sru']},
	{'value': 'श्रुतवत्', 'label': 'Srutavat', 'attrib': [{'pos': 'pap', 'defs': 'hear, listen'}], 'isLemma': 0, 'parents': ['Sru']},
	{'value': 'श्रुत्वा', 'label': 'SrutvA', 'attrib': [{'pos': 'abs', 'defs': 'hear, listen'}], 'isLemma': 0, 'parents': ['Sru']},
	{'value': 'श्रूयमाण', 'label': 'SrUyamARa', 'attrib': [{'pos': 'prpp', 'defs': 'hear, listen'}], 'isLemma': 0, 'parents': ['Sru']},
	{'value': 'श्रोतव्य', 'label': 'Srotavya', 'attrib': [{'pos': 'ger', 'defs': 'hear, listen'}], 'isLemma': 0, 'parents': ['Sru']},
	{'value': 'श्रोतुम्', 'label': 'Srotum', 'attrib': [{'pos': 'inf', 'defs': 'hear, listen'}], 'isLemma': 0, 'parents': ['Sru']},
	{'value': 'षट्सूर्य', 'label': 'zawsUrya', 'attrib': [{'pos': 'adj', 'defs': 'six-sunned'}], 'isLemma': 1},
	{'value': 'षष्', 'label': 'zaz', 'attrib': [{'pos': 'card_num', 'defs': 'six'}], 'isLemma': 1},
	{'value': 'संकाश', 'label': 'saMkASa', 'attrib': [{'pos': 'm', 'defs': 'appearance'}], 'isLemma': 1},
	{'value': 'संगच्छमान', 'label': 'saMgacCamAna', 'attrib': [{'pos': 'prmp', 'defs': 'go together, unite, meet'}], 'isLemma': 0, 'parents': ['sam_gam']},
	{'value': 'संगत', 'label': 'saMgata', 'attrib': [{'pos': 'ppp', 'defs': 'go together, unite, meet'}], 'isLemma': 0, 'parents': ['sam_gam']},
	{'value': 'संगतवत्', 'label': 'saMgatavat', 'attrib': [{'pos': 'pap', 'defs': 'go together, unite, meet'}], 'isLemma': 0, 'parents': ['sam_gam']},
	{'value': 'संगत्य', 'label': 'saMgatya', 'attrib': [{'pos': 'abs', 'defs': 'go together, unite, meet'}], 'isLemma': 0, 'parents': ['sam_gam']},
	{'value': 'संगन्तव्य', 'label': 'saMgantavya', 'attrib': [{'pos': 'ger', 'defs': 'go together, unite, meet'}], 'isLemma': 0, 'parents': ['sam_gam']},
	{'value': 'संगन्तुम्', 'label': 'saMgantum', 'attrib': [{'pos': 'inf', 'defs': 'go together, unite, meet'}], 'isLemma': 0, 'parents': ['sam_gam']},
	{'value': 'संगमनीय', 'label': 'saMgamanIya', 'attrib': [{'pos': 'ger', 'defs': 'go together, unite, meet'}], 'isLemma': 0, 'parents': ['sam_gam']},
	{'value': 'संगमिष्यमाण', 'label': 'saMgamizyamARa', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'go together, unite, meet'}], 'isLemma': 0, 'parents': ['sam_gam']},
	{'value': 'संगम्य', 'label': 'saMgamya', 'attrib': [{'pos': 'abs', 'defs': 'go together, unite, meet'}, {'pos': 'ger', 'defs': 'go together, unite, meet'}], 'isLemma': 0, 'parents': ['sam_gam']},
	{'value': 'संगम्यमान', 'label': 'saMgamyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'go together, unite, meet'}], 'isLemma': 0, 'parents': ['sam_gam']},
	{'value': 'संचिच्छिदान', 'label': 'saMcicCidAna', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'split, cut'}], 'isLemma': 0, 'parents': ['sam_Cid']},
	{'value': 'संचिच्छिद्वस्', 'label': 'saMcicCidvas', 'attrib': [{'pos': 'pfap', 'defs': 'split, cut'}], 'isLemma': 0, 'parents': ['sam_Cid']},
	{'value': 'संछिद्य', 'label': 'saMCidya', 'attrib': [{'pos': 'abs', 'defs': 'split, cut'}], 'isLemma': 0, 'parents': ['sam_Cid']},
	{'value': 'संछिद्यमान', 'label': 'saMCidyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'split, cut'}], 'isLemma': 0, 'parents': ['sam_Cid']},
	{'value': 'संछिन्दत्', 'label': 'saMCindat', 'attrib': [{'pos': 'prap', 'defs': 'split, cut'}], 'isLemma': 0, 'parents': ['sam_Cid']},
	{'value': 'संछिन्दान', 'label': 'saMCindAna', 'attrib': [{'pos': 'prmp', 'defs': 'split, cut'}], 'isLemma': 0, 'parents': ['sam_Cid']},
	{'value': 'संछिन्न', 'label': 'saMCinna', 'attrib': [{'pos': 'ppp', 'defs': 'split, cut'}], 'isLemma': 0, 'parents': ['sam_Cid']},
	{'value': 'संछिन्नवत्', 'label': 'saMCinnavat', 'attrib': [{'pos': 'pap', 'defs': 'split, cut'}], 'isLemma': 0, 'parents': ['sam_Cid']},
	{'value': 'संछेत्तव्य', 'label': 'saMCettavya', 'attrib': [{'pos': 'ger', 'defs': 'split, cut'}], 'isLemma': 0, 'parents': ['sam_Cid']},
	{'value': 'संछेत्तुम्', 'label': 'saMCettum', 'attrib': [{'pos': 'inf', 'defs': 'split, cut'}], 'isLemma': 0, 'parents': ['sam_Cid']},
	{'value': 'संछेत्स्यत्', 'label': 'saMCetsyat', 'attrib': [{'pos': 'fap', 'defs': 'split, cut'}], 'isLemma': 0, 'parents': ['sam_Cid']},
	{'value': 'संछेत्स्यमान', 'label': 'saMCetsyamAna', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'split, cut'}], 'isLemma': 0, 'parents': ['sam_Cid']},
	{'value': 'संछेदनीय', 'label': 'saMCedanIya', 'attrib': [{'pos': 'ger', 'defs': 'split, cut'}], 'isLemma': 0, 'parents': ['sam_Cid']},
	{'value': 'संछेद्य', 'label': 'saMCedya', 'attrib': [{'pos': 'ger', 'defs': 'split, cut'}], 'isLemma': 0, 'parents': ['sam_Cid']},
	{'value': 'संजग्मान', 'label': 'saMjagmAna', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'go together, unite, meet'}], 'isLemma': 0, 'parents': ['sam_gam']},
	{'value': 'संदधत्', 'label': 'saMdaDat', 'attrib': [{'pos': 'prap', 'defs': 'put together'}], 'isLemma': 0, 'parents': ['sam_DA']},
	{'value': 'संदधान', 'label': 'saMdaDAna', 'attrib': [{'pos': 'prmp', 'defs': 'put together'}, {'pos': 'pfmp, pfpp', 'defs': 'put together'}], 'isLemma': 0, 'parents': ['sam_DA']},
	{'value': 'संदधिवस्', 'label': 'saMdaDivas', 'attrib': [{'pos': 'pfap', 'defs': 'put together'}], 'isLemma': 0, 'parents': ['sam_DA']},
	{'value': 'संधातव्य', 'label': 'saMDAtavya', 'attrib': [{'pos': 'ger', 'defs': 'put together'}], 'isLemma': 0, 'parents': ['sam_DA']},
	{'value': 'संधातुम्', 'label': 'saMDAtum', 'attrib': [{'pos': 'inf', 'defs': 'put together'}], 'isLemma': 0, 'parents': ['sam_DA']},
	{'value': 'संधानीय', 'label': 'saMDAnIya', 'attrib': [{'pos': 'ger', 'defs': 'put together'}], 'isLemma': 0, 'parents': ['sam_DA']},
	{'value': 'संधाय', 'label': 'saMDAya', 'attrib': [{'pos': 'abs', 'defs': 'put together'}], 'isLemma': 0, 'parents': ['sam_DA']},
	{'value': 'संधास्यत्', 'label': 'saMDAsyat', 'attrib': [{'pos': 'fap', 'defs': 'put together'}], 'isLemma': 0, 'parents': ['sam_DA']},
	{'value': 'संधास्यमान', 'label': 'saMDAsyamAna', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'put together'}], 'isLemma': 0, 'parents': ['sam_DA']},
	{'value': 'संधीयमान', 'label': 'saMDIyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'put together'}], 'isLemma': 0, 'parents': ['sam_DA']},
	{'value': 'संधेय', 'label': 'saMDeya', 'attrib': [{'pos': 'ger', 'defs': 'put together'}], 'isLemma': 0, 'parents': ['sam_DA']},
	{'value': 'संपत्तव्य', 'label': 'saMpattavya', 'attrib': [{'pos': 'ger', 'defs': 'succeed, arise'}], 'isLemma': 0, 'parents': ['sam_pad']},
	{'value': 'संपत्तुम्', 'label': 'saMpattum', 'attrib': [{'pos': 'inf', 'defs': 'succeed, arise'}], 'isLemma': 0, 'parents': ['sam_pad']},
	{'value': 'संपत्स्यमान', 'label': 'saMpatsyamAna', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'succeed, arise'}], 'isLemma': 0, 'parents': ['sam_pad']},
	{'value': 'संपदनीय', 'label': 'saMpadanIya', 'attrib': [{'pos': 'ger', 'defs': 'succeed, arise'}], 'isLemma': 0, 'parents': ['sam_pad']},
	{'value': 'संपद्य', 'label': 'saMpadya', 'attrib': [{'pos': 'abs', 'defs': 'succeed, arise'}], 'isLemma': 0, 'parents': ['sam_pad']},
	{'value': 'संपद्यमान', 'label': 'saMpadyamAna', 'attrib': [{'pos': 'prmp', 'defs': 'succeed, arise'}, {'pos': 'prpp', 'defs': 'succeed, arise'}], 'isLemma': 0, 'parents': ['sam_pad']},
	{'value': 'संपन्न', 'label': 'saMpanna', 'attrib': [{'pos': 'adj', 'defs': 'endowed with'}, {'pos': 'ppp', 'defs': 'succeed, arise'}], 'isLemma': 1},
	{'value': 'संपन्न', 'label': 'saMpanna', 'attrib': [{'pos': 'adj', 'defs': 'endowed with'}, {'pos': 'ppp', 'defs': 'succeed, arise'}], 'isLemma': 0, 'parents': ['sam_pad']},
	{'value': 'संपन्नवत्', 'label': 'saMpannavat', 'attrib': [{'pos': 'pap', 'defs': 'succeed, arise'}], 'isLemma': 0, 'parents': ['sam_pad']},
	{'value': 'संपाद्य', 'label': 'saMpAdya', 'attrib': [{'pos': 'ger', 'defs': 'succeed, arise'}], 'isLemma': 0, 'parents': ['sam_pad']},
	{'value': 'संपूजनीय', 'label': 'saMpUjanIya', 'attrib': [{'pos': 'ger', 'defs': 'honor'}], 'isLemma': 0, 'parents': ['sam_pUj']},
	{'value': 'संपूजयमान', 'label': 'saMpUjayamAna', 'attrib': [{'pos': 'prmp', 'defs': 'honor'}], 'isLemma': 0, 'parents': ['sam_pUj']},
	{'value': 'संपूजयाम्', 'label': 'saMpUjayAm', 'attrib': [{'pos': 'ppf_actn', 'defs': 'honor'}], 'isLemma': 0, 'parents': ['sam_pUj']},
	{'value': 'संपूजयितुम्', 'label': 'saMpUjayitum', 'attrib': [{'pos': 'inf', 'defs': 'honor'}], 'isLemma': 0, 'parents': ['sam_pUj']},
	{'value': 'संपूजयिष्यमाण', 'label': 'saMpUjayizyamARa', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'honor'}], 'isLemma': 0, 'parents': ['sam_pUj']},
	{'value': 'संपूजित', 'label': 'saMpUjita', 'attrib': [{'pos': 'ppp', 'defs': 'honor'}], 'isLemma': 0, 'parents': ['sam_pUj']},
	{'value': 'संपूजितवत्', 'label': 'saMpUjitavat', 'attrib': [{'pos': 'pap', 'defs': 'honor'}], 'isLemma': 0, 'parents': ['sam_pUj']},
	{'value': 'संपूजितव्य', 'label': 'saMpUjitavya', 'attrib': [{'pos': 'ger', 'defs': 'honor'}], 'isLemma': 0, 'parents': ['sam_pUj']},
	{'value': 'संपूज्य', 'label': 'saMpUjya', 'attrib': [{'pos': 'abs', 'defs': 'honor'}, {'pos': 'ger', 'defs': 'honor'}], 'isLemma': 0, 'parents': ['sam_pUj']},
	{'value': 'संपूज्यमान', 'label': 'saMpUjyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'honor'}], 'isLemma': 0, 'parents': ['sam_pUj']},
	{'value': 'संपेदान', 'label': 'saMpedAna', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'succeed, arise'}], 'isLemma': 0, 'parents': ['sam_pad']},
	{'value': 'संप्रवक्तव्य', 'label': 'saMpravaktavya', 'attrib': [{'pos': 'ger', 'defs': 'communicate, declare, relate in full'}], 'isLemma': 0, 'parents': ['sam_pra_vac']},
	{'value': 'संप्रवक्तुम्', 'label': 'saMpravaktum', 'attrib': [{'pos': 'inf', 'defs': 'communicate, declare, relate in full'}], 'isLemma': 0, 'parents': ['sam_pra_vac']},
	{'value': 'संप्रवक्ष्यत्', 'label': 'saMpravakzyat', 'attrib': [{'pos': 'fap', 'defs': 'communicate, declare, relate in full'}], 'isLemma': 0, 'parents': ['sam_pra_vac']},
	{'value': 'संप्रवक्ष्यमाण', 'label': 'saMpravakzyamARa', 'attrib': [{'pos': 'fpp', 'defs': 'communicate, declare, relate in full'}], 'isLemma': 0, 'parents': ['sam_pra_vac']},
	{'value': 'संप्रवचनीय', 'label': 'saMpravacanIya', 'attrib': [{'pos': 'ger', 'defs': 'communicate, declare, relate in full'}], 'isLemma': 0, 'parents': ['sam_pra_vac']},
	{'value': 'संप्रवाक्य', 'label': 'saMpravAkya', 'attrib': [{'pos': 'ger', 'defs': 'communicate, declare, relate in full'}], 'isLemma': 0, 'parents': ['sam_pra_vac']},
	{'value': 'संप्रोक्त', 'label': 'saMprokta', 'attrib': [{'pos': 'ppp', 'defs': 'communicate, declare, relate in full'}], 'isLemma': 0, 'parents': ['sam_pra_vac']},
	{'value': 'संप्रोक्तवत्', 'label': 'saMproktavat', 'attrib': [{'pos': 'pap', 'defs': 'communicate, declare, relate in full'}], 'isLemma': 0, 'parents': ['sam_pra_vac']},
	{'value': 'संप्रोक्त्वा', 'label': 'saMproktvA', 'attrib': [{'pos': 'abs', 'defs': 'communicate, declare, relate in full'}], 'isLemma': 0, 'parents': ['sam_pra_vac']},
	{'value': 'संप्रोचान', 'label': 'saMprocAna', 'attrib': [{'pos': 'pfpp', 'defs': 'communicate, declare, relate in full'}], 'isLemma': 0, 'parents': ['sam_pra_vac']},
	{'value': 'संप्रोचिवस्', 'label': 'saMprocivas', 'attrib': [{'pos': 'pfap', 'defs': 'communicate, declare, relate in full'}], 'isLemma': 0, 'parents': ['sam_pra_vac']},
	{'value': 'संप्रोच्य', 'label': 'saMprocya', 'attrib': [{'pos': 'abs', 'defs': 'communicate, declare, relate in full'}], 'isLemma': 0, 'parents': ['sam_pra_vac']},
	{'value': 'संप्रोच्यमान', 'label': 'saMprocyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'communicate, declare, relate in full'}], 'isLemma': 0, 'parents': ['sam_pra_vac']},
	{'value': 'संबभूवस्', 'label': 'saMbaBUvas', 'attrib': [{'pos': 'pfap', 'defs': 'come to be, arise, be possible'}], 'isLemma': 0, 'parents': ['sam_BU']},
	{'value': 'संभवत्', 'label': 'saMBavat', 'attrib': [{'pos': 'prap', 'defs': 'come to be, arise, be possible'}], 'isLemma': 0, 'parents': ['sam_BU']},
	{'value': 'संभवनीय', 'label': 'saMBavanIya', 'attrib': [{'pos': 'ger', 'defs': 'come to be, arise, be possible'}], 'isLemma': 0, 'parents': ['sam_BU']},
	{'value': 'संभवितव्य', 'label': 'saMBavitavya', 'attrib': [{'pos': 'ger', 'defs': 'come to be, arise, be possible'}], 'isLemma': 0, 'parents': ['sam_BU']},
	{'value': 'संभवितुम्', 'label': 'saMBavitum', 'attrib': [{'pos': 'inf', 'defs': 'come to be, arise, be possible'}], 'isLemma': 0, 'parents': ['sam_BU']},
	{'value': 'संभविष्यत्', 'label': 'saMBavizyat', 'attrib': [{'pos': 'fap', 'defs': 'come to be, arise, be possible'}], 'isLemma': 0, 'parents': ['sam_BU']},
	{'value': 'संभविष्यमाण', 'label': 'saMBavizyamARa', 'attrib': [{'pos': 'fpp', 'defs': 'come to be, arise, be possible'}], 'isLemma': 0, 'parents': ['sam_BU']},
	{'value': 'संभव्य', 'label': 'saMBavya', 'attrib': [{'pos': 'ger', 'defs': 'come to be, arise, be possible'}], 'isLemma': 0, 'parents': ['sam_BU']},
	{'value': 'संभूत', 'label': 'saMBUta', 'attrib': [{'pos': 'ppp', 'defs': 'come to be, arise, be possible'}], 'isLemma': 0, 'parents': ['sam_BU']},
	{'value': 'संभूतवत्', 'label': 'saMBUtavat', 'attrib': [{'pos': 'pap', 'defs': 'come to be, arise, be possible'}], 'isLemma': 0, 'parents': ['sam_BU']},
	{'value': 'संभूय', 'label': 'saMBUya', 'attrib': [{'pos': 'abs', 'defs': 'come to be, arise, be possible'}], 'isLemma': 0, 'parents': ['sam_BU']},
	{'value': 'संभूयमान', 'label': 'saMBUyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'come to be, arise, be possible'}], 'isLemma': 0, 'parents': ['sam_BU']},
	{'value': 'संमंस्यमान', 'label': 'saMmaMsyamAna', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'agree, approve, esteem, respect'}], 'isLemma': 0, 'parents': ['sam_man']},
	{'value': 'संमत', 'label': 'saMmata', 'attrib': [{'pos': 'ppp', 'defs': 'agree, approve, esteem, respect'}], 'isLemma': 0, 'parents': ['sam_man']},
	{'value': 'संमतवत्', 'label': 'saMmatavat', 'attrib': [{'pos': 'pap', 'defs': 'agree, approve, esteem, respect'}], 'isLemma': 0, 'parents': ['sam_man']},
	{'value': 'संमननीय', 'label': 'saMmananIya', 'attrib': [{'pos': 'ger', 'defs': 'agree, approve, esteem, respect'}], 'isLemma': 0, 'parents': ['sam_man']},
	{'value': 'संमन्तव्य', 'label': 'saMmantavya', 'attrib': [{'pos': 'ger', 'defs': 'agree, approve, esteem, respect'}], 'isLemma': 0, 'parents': ['sam_man']},
	{'value': 'संमन्तुम्', 'label': 'saMmantum', 'attrib': [{'pos': 'inf', 'defs': 'agree, approve, esteem, respect'}], 'isLemma': 0, 'parents': ['sam_man']},
	{'value': 'संमन्त्रणीय', 'label': 'saMmantraRIya', 'attrib': [{'pos': 'ger', 'defs': 'consult'}], 'isLemma': 0, 'parents': ['sam_mantr']},
	{'value': 'संमन्त्रयमान', 'label': 'saMmantrayamAna', 'attrib': [{'pos': 'prmp', 'defs': 'consult'}], 'isLemma': 0, 'parents': ['sam_mantr']},
	{'value': 'संमन्त्रयाम्', 'label': 'saMmantrayAm', 'attrib': [{'pos': 'ppf_actn', 'defs': 'consult'}], 'isLemma': 0, 'parents': ['sam_mantr']},
	{'value': 'संमन्त्रयितव्य', 'label': 'saMmantrayitavya', 'attrib': [{'pos': 'ger', 'defs': 'consult'}], 'isLemma': 0, 'parents': ['sam_mantr']},
	{'value': 'संमन्त्रयितुम्', 'label': 'saMmantrayitum', 'attrib': [{'pos': 'inf', 'defs': 'consult'}], 'isLemma': 0, 'parents': ['sam_mantr']},
	{'value': 'संमन्त्रयिष्यमाण', 'label': 'saMmantrayizyamARa', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'consult'}], 'isLemma': 0, 'parents': ['sam_mantr']},
	{'value': 'संमन्त्रित', 'label': 'saMmantrita', 'attrib': [{'pos': 'ppp', 'defs': 'consult'}], 'isLemma': 0, 'parents': ['sam_mantr']},
	{'value': 'संमन्त्रितवत्', 'label': 'saMmantritavat', 'attrib': [{'pos': 'pap', 'defs': 'consult'}], 'isLemma': 0, 'parents': ['sam_mantr']},
	{'value': 'संमन्त्र्य', 'label': 'saMmantrya', 'attrib': [{'pos': 'abs', 'defs': 'consult'}, {'pos': 'ger', 'defs': 'consult'}], 'isLemma': 0, 'parents': ['sam_mantr']},
	{'value': 'संमन्त्र्यमान', 'label': 'saMmantryamAna', 'attrib': [{'pos': 'prpp', 'defs': 'consult'}], 'isLemma': 0, 'parents': ['sam_mantr']},
	{'value': 'संमन्य', 'label': 'saMmanya', 'attrib': [{'pos': 'abs', 'defs': 'agree, approve, esteem, respect'}], 'isLemma': 0, 'parents': ['sam_man']},
	{'value': 'संमन्यमान', 'label': 'saMmanyamAna', 'attrib': [{'pos': 'prmp', 'defs': 'agree, approve, esteem, respect'}, {'pos': 'prpp', 'defs': 'agree, approve, esteem, respect'}], 'isLemma': 0, 'parents': ['sam_man']},
	{'value': 'संमन्वान', 'label': 'saMmanvAna', 'attrib': [{'pos': 'prmp', 'defs': 'agree, approve, esteem, respect'}], 'isLemma': 0, 'parents': ['sam_man']},
	{'value': 'संमान्य', 'label': 'saMmAnya', 'attrib': [{'pos': 'ger', 'defs': 'agree, approve, esteem, respect'}], 'isLemma': 0, 'parents': ['sam_man']},
	{'value': 'संमेनान', 'label': 'saMmenAna', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'agree, approve, esteem, respect'}], 'isLemma': 0, 'parents': ['sam_man']},
	{'value': 'संवत्सर', 'label': 'saMvatsara', 'attrib': [{'pos': 'm', 'defs': 'year'}], 'isLemma': 1},
	{'value': 'संवत्सरगण', 'label': 'saMvatsaragaRa', 'attrib': [{'pos': 'm', 'defs': 'many years'}], 'isLemma': 1},
	{'value': 'संवरणीय', 'label': 'saMvaraRIya', 'attrib': [{'pos': 'ger', 'defs': 'cover, conceal'}], 'isLemma': 0, 'parents': ['sam_vf']},
	{'value': 'संवरितव्य', 'label': 'saMvaritavya', 'attrib': [{'pos': 'ger', 'defs': 'cover, conceal'}], 'isLemma': 0, 'parents': ['sam_vf']},
	{'value': 'संवरितुम्', 'label': 'saMvaritum', 'attrib': [{'pos': 'inf', 'defs': 'cover, conceal'}], 'isLemma': 0, 'parents': ['sam_vf']},
	{'value': 'संवरिष्यत्', 'label': 'saMvarizyat', 'attrib': [{'pos': 'fap', 'defs': 'cover, conceal'}], 'isLemma': 0, 'parents': ['sam_vf']},
	{'value': 'संवरिष्यमाण', 'label': 'saMvarizyamARa', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'cover, conceal'}], 'isLemma': 0, 'parents': ['sam_vf']},
	{'value': 'संवरीतव्य', 'label': 'saMvarItavya', 'attrib': [{'pos': 'ger', 'defs': 'cover, conceal'}], 'isLemma': 0, 'parents': ['sam_vf']},
	{'value': 'संवरीतुम्', 'label': 'saMvarItum', 'attrib': [{'pos': 'inf', 'defs': 'cover, conceal'}], 'isLemma': 0, 'parents': ['sam_vf']},
	{'value': 'संवरीष्यत्', 'label': 'saMvarIzyat', 'attrib': [{'pos': 'fap', 'defs': 'cover, conceal'}], 'isLemma': 0, 'parents': ['sam_vf']},
	{'value': 'संवरीष्यमाण', 'label': 'saMvarIzyamARa', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'cover, conceal'}], 'isLemma': 0, 'parents': ['sam_vf']},
	{'value': 'संववृवस्', 'label': 'saMvavfvas', 'attrib': [{'pos': 'pfap', 'defs': 'cover, conceal'}], 'isLemma': 0, 'parents': ['sam_vf']},
	{'value': 'संवव्राण', 'label': 'saMvavrARa', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'cover, conceal'}], 'isLemma': 0, 'parents': ['sam_vf']},
	{'value': 'संवृण्वत्', 'label': 'saMvfRvat', 'attrib': [{'pos': 'prap', 'defs': 'cover, conceal'}], 'isLemma': 0, 'parents': ['sam_vf']},
	{'value': 'संवृण्वान', 'label': 'saMvfRvAna', 'attrib': [{'pos': 'prmp', 'defs': 'cover, conceal'}], 'isLemma': 0, 'parents': ['sam_vf']},
	{'value': 'संवृत', 'label': 'saMvfta', 'attrib': [{'pos': 'ppp', 'defs': 'cover, conceal'}], 'isLemma': 0, 'parents': ['sam_vf']},
	{'value': 'संवृतवत्', 'label': 'saMvftavat', 'attrib': [{'pos': 'pap', 'defs': 'cover, conceal'}], 'isLemma': 0, 'parents': ['sam_vf']},
	{'value': 'संवृत्य', 'label': 'saMvftya', 'attrib': [{'pos': 'abs', 'defs': 'cover, conceal'}, {'pos': 'ger', 'defs': 'cover, conceal'}], 'isLemma': 0, 'parents': ['sam_vf']},
	{'value': 'संव्रियमाण', 'label': 'saMvriyamARa', 'attrib': [{'pos': 'prpp', 'defs': 'cover, conceal'}], 'isLemma': 0, 'parents': ['sam_vf']},
	{'value': 'संसुप्त', 'label': 'saMsupta', 'attrib': [{'pos': 'ppp', 'defs': 'sleep'}], 'isLemma': 0, 'parents': ['sam_svap']},
	{'value': 'संसुप्तवत्', 'label': 'saMsuptavat', 'attrib': [{'pos': 'pap', 'defs': 'sleep'}], 'isLemma': 0, 'parents': ['sam_svap']},
	{'value': 'संसुप्य', 'label': 'saMsupya', 'attrib': [{'pos': 'abs', 'defs': 'sleep'}], 'isLemma': 0, 'parents': ['sam_svap']},
	{'value': 'संसुप्यमाण', 'label': 'saMsupyamARa', 'attrib': [{'pos': 'prpp', 'defs': 'sleep'}], 'isLemma': 0, 'parents': ['sam_svap']},
	{'value': 'संसुषुपान', 'label': 'saMsuzupAna', 'attrib': [{'pos': 'pfpp', 'defs': 'sleep'}], 'isLemma': 0, 'parents': ['sam_svap']},
	{'value': 'संसुषुपिवस्', 'label': 'saMsuzupivas', 'attrib': [{'pos': 'pfap', 'defs': 'sleep'}], 'isLemma': 0, 'parents': ['sam_svap']},
	{'value': 'संस्वपत्', 'label': 'saMsvapat', 'attrib': [{'pos': 'prap', 'defs': 'sleep'}], 'isLemma': 0, 'parents': ['sam_svap']},
	{'value': 'संस्वपनीय', 'label': 'saMsvapanIya', 'attrib': [{'pos': 'ger', 'defs': 'sleep'}], 'isLemma': 0, 'parents': ['sam_svap']},
	{'value': 'संस्वप्तव्य', 'label': 'saMsvaptavya', 'attrib': [{'pos': 'ger', 'defs': 'sleep'}], 'isLemma': 0, 'parents': ['sam_svap']},
	{'value': 'संस्वप्तुम्', 'label': 'saMsvaptum', 'attrib': [{'pos': 'inf', 'defs': 'sleep'}], 'isLemma': 0, 'parents': ['sam_svap']},
	{'value': 'संस्वप्य', 'label': 'saMsvapya', 'attrib': [{'pos': 'ger', 'defs': 'sleep'}], 'isLemma': 0, 'parents': ['sam_svap']},
	{'value': 'संस्वप्स्यत्', 'label': 'saMsvapsyat', 'attrib': [{'pos': 'fap', 'defs': 'sleep'}], 'isLemma': 0, 'parents': ['sam_svap']},
	{'value': 'संस्वप्स्यमाण', 'label': 'saMsvapsyamARa', 'attrib': [{'pos': 'fpp', 'defs': 'sleep'}], 'isLemma': 0, 'parents': ['sam_svap']},
	{'value': 'संहित', 'label': 'saMhita', 'attrib': [{'pos': 'ppp', 'defs': 'put together'}], 'isLemma': 0, 'parents': ['sam_DA']},
	{'value': 'संहितवत्', 'label': 'saMhitavat', 'attrib': [{'pos': 'pap', 'defs': 'put together'}], 'isLemma': 0, 'parents': ['sam_DA']},
	{'value': 'सच्', 'label': 'sac', 'attrib': [{'pos': 'vt1am', 'defs': 'be associated with'}], 'isLemma': 1},
	{'value': 'सचिव', 'label': 'saciva', 'attrib': [{'pos': 'm', 'defs': 'associate, counsellor, minister'}], 'isLemma': 1},
	{'value': 'सत्', 'label': 'sat', 'attrib': [{'pos': 'prap', 'defs': 'be'}, {'pos': 'adj', 'defs': 'good'}], 'isLemma': 1},
	{'value': 'सत्', 'label': 'sat', 'attrib': [{'pos': 'prap', 'defs': 'be'}, {'pos': 'adj', 'defs': 'good'}], 'isLemma': 0, 'parents': ['as']},
	{'value': 'सत्तम', 'label': 'sattama', 'attrib': [{'pos': 'adj', 'defs': 'most excellent'}], 'isLemma': 1},
	{'value': 'सत्तव्य', 'label': 'sattavya', 'attrib': [{'pos': 'ger', 'defs': 'sit, be sad'}], 'isLemma': 0, 'parents': ['sad']},
	{'value': 'सत्तुम्', 'label': 'sattum', 'attrib': [{'pos': 'inf', 'defs': 'sit, be sad'}], 'isLemma': 0, 'parents': ['sad']},
	{'value': 'सत्त्वा', 'label': 'sattvA', 'attrib': [{'pos': 'abs', 'defs': 'sit, be sad'}], 'isLemma': 0, 'parents': ['sad']},
	{'value': 'सत्य', 'label': 'satya', 'attrib': [{'pos': 'adj', 'defs': 'true, honest'}, {'pos': 'n', 'defs': 'truth'}], 'isLemma': 1},
	{'value': 'सत्यविक्रम', 'label': 'satyavikrama', 'attrib': [{'pos': 'adj', 'defs': 'truly powerful'}], 'isLemma': 1},
	{'value': 'सत्यव्रत', 'label': 'satyavrata', 'attrib': [{'pos': 'n', 'defs': 'truth and vows'}], 'isLemma': 1},
	{'value': 'सत्यव्रतपरायण', 'label': 'satyavrataparAyaRa', 'attrib': [{'pos': 'adj', 'defs': 'devoted to truth and vows'}], 'isLemma': 1},
	{'value': 'सत्स्यत्', 'label': 'satsyat', 'attrib': [{'pos': 'fap', 'defs': 'sit, be sad'}], 'isLemma': 0, 'parents': ['sad']},
	{'value': 'सत्स्यमान', 'label': 'satsyamAna', 'attrib': [{'pos': 'fpp', 'defs': 'sit, be sad'}], 'isLemma': 0, 'parents': ['sad']},
	{'value': 'सद्', 'label': 'sad', 'attrib': [{'pos': 'vi1a, vi6a', 'defs': 'sit, be sad'}], 'isLemma': 1},
	{'value': 'सदनीय', 'label': 'sadanIya', 'attrib': [{'pos': 'ger', 'defs': 'sit, be sad'}], 'isLemma': 0, 'parents': ['sad']},
	{'value': 'सद्यमान', 'label': 'sadyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'sit, be sad'}], 'isLemma': 0, 'parents': ['sad']},
	{'value': 'सन्न', 'label': 'sanna', 'attrib': [{'pos': 'ppp', 'defs': 'sit, be sad'}], 'isLemma': 0, 'parents': ['sad']},
	{'value': 'सन्नवत्', 'label': 'sannavat', 'attrib': [{'pos': 'pap', 'defs': 'sit, be sad'}], 'isLemma': 0, 'parents': ['sad']},
	{'value': 'सप्तन्', 'label': 'saptan', 'attrib': [{'pos': 'card_num', 'defs': 'seven'}], 'isLemma': 1},
	{'value': 'सभा', 'label': 'saBA', 'attrib': [{'pos': 'f', 'defs': 'hall'}], 'isLemma': 1},
	{'value': 'सम्', 'label': 'sam', 'attrib': [{'pos': 'preverb', 'defs': 'together'}], 'isLemma': 1},
	{'value': 'समाचयनीय', 'label': 'samAcayanIya', 'attrib': [{'pos': 'ger', 'defs': 'cover or fill with'}], 'isLemma': 0, 'parents': ['sam_A_ci']},
	{'value': 'समाचरणीय', 'label': 'samAcaraRIya', 'attrib': [{'pos': 'ger', 'defs': 'do, practice'}], 'isLemma': 0, 'parents': ['sam_A_car']},
	{'value': 'समाचरत्', 'label': 'samAcarat', 'attrib': [{'pos': 'prap', 'defs': 'do, practice'}], 'isLemma': 0, 'parents': ['sam_A_car']},
	{'value': 'समाचरमाण', 'label': 'samAcaramARa', 'attrib': [{'pos': 'prmp', 'defs': 'do, practice'}], 'isLemma': 0, 'parents': ['sam_A_car']},
	{'value': 'समाचरित', 'label': 'samAcarita', 'attrib': [{'pos': 'ppp', 'defs': 'do, practice'}], 'isLemma': 0, 'parents': ['sam_A_car']},
	{'value': 'समाचरितवत्', 'label': 'samAcaritavat', 'attrib': [{'pos': 'pap', 'defs': 'do, practice'}], 'isLemma': 0, 'parents': ['sam_A_car']},
	{'value': 'समाचरितव्य', 'label': 'samAcaritavya', 'attrib': [{'pos': 'ger', 'defs': 'do, practice'}], 'isLemma': 0, 'parents': ['sam_A_car']},
	{'value': 'समाचरितुम्', 'label': 'samAcaritum', 'attrib': [{'pos': 'inf', 'defs': 'do, practice'}], 'isLemma': 0, 'parents': ['sam_A_car']},
	{'value': 'समाचरिष्यत्', 'label': 'samAcarizyat', 'attrib': [{'pos': 'fap', 'defs': 'do, practice'}], 'isLemma': 0, 'parents': ['sam_A_car']},
	{'value': 'समाचरिष्यमाण', 'label': 'samAcarizyamARa', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'do, practice'}], 'isLemma': 0, 'parents': ['sam_A_car']},
	{'value': 'समाचर्य', 'label': 'samAcarya', 'attrib': [{'pos': 'abs', 'defs': 'do, practice'}, {'pos': 'ger', 'defs': 'do, practice'}], 'isLemma': 0, 'parents': ['sam_A_car']},
	{'value': 'समाचर्यमाण', 'label': 'samAcaryamARa', 'attrib': [{'pos': 'prpp', 'defs': 'do, practice'}], 'isLemma': 0, 'parents': ['sam_A_car']},
	{'value': 'समाचिकिवस्', 'label': 'samAcikivas', 'attrib': [{'pos': 'pfap', 'defs': 'cover or fill with'}], 'isLemma': 0, 'parents': ['sam_A_ci']},
	{'value': 'समाचिचिवस्', 'label': 'samAcicivas', 'attrib': [{'pos': 'pfap', 'defs': 'cover or fill with'}], 'isLemma': 0, 'parents': ['sam_A_ci']},
	{'value': 'समाचित', 'label': 'samAcita', 'attrib': [{'pos': 'ppp', 'defs': 'cover or fill with'}], 'isLemma': 0, 'parents': ['sam_A_ci']},
	{'value': 'समाचितवत्', 'label': 'samAcitavat', 'attrib': [{'pos': 'pap', 'defs': 'cover or fill with'}], 'isLemma': 0, 'parents': ['sam_A_ci']},
	{'value': 'समाचित्य', 'label': 'samAcitya', 'attrib': [{'pos': 'abs', 'defs': 'cover or fill with'}], 'isLemma': 0, 'parents': ['sam_A_ci']},
	{'value': 'समाचिन्वत्', 'label': 'samAcinvat', 'attrib': [{'pos': 'prap', 'defs': 'cover or fill with'}], 'isLemma': 0, 'parents': ['sam_A_ci']},
	{'value': 'समाचिन्वान', 'label': 'samAcinvAna', 'attrib': [{'pos': 'prmp', 'defs': 'cover or fill with'}], 'isLemma': 0, 'parents': ['sam_A_ci']},
	{'value': 'समाचियान', 'label': 'samAciyAna', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'cover or fill with'}], 'isLemma': 0, 'parents': ['sam_A_ci']},
	{'value': 'समाचीयमाण', 'label': 'samAcIyamARa', 'attrib': [{'pos': 'prpp', 'defs': 'cover or fill with'}], 'isLemma': 0, 'parents': ['sam_A_ci']},
	{'value': 'समाचेतव्य', 'label': 'samAcetavya', 'attrib': [{'pos': 'ger', 'defs': 'cover or fill with'}], 'isLemma': 0, 'parents': ['sam_A_ci']},
	{'value': 'समाचेतुम्', 'label': 'samAcetum', 'attrib': [{'pos': 'inf', 'defs': 'cover or fill with'}], 'isLemma': 0, 'parents': ['sam_A_ci']},
	{'value': 'समाचेय', 'label': 'samAceya', 'attrib': [{'pos': 'ger', 'defs': 'cover or fill with'}], 'isLemma': 0, 'parents': ['sam_A_ci']},
	{'value': 'समाचेराण', 'label': 'samAcerARa', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'do, practice'}], 'isLemma': 0, 'parents': ['sam_A_car']},
	{'value': 'समाचेरिवस्', 'label': 'samAcerivas', 'attrib': [{'pos': 'pfap', 'defs': 'do, practice'}], 'isLemma': 0, 'parents': ['sam_A_car']},
	{'value': 'समाचेष्यत्', 'label': 'samAcezyat', 'attrib': [{'pos': 'fap', 'defs': 'cover or fill with'}], 'isLemma': 0, 'parents': ['sam_A_ci']},
	{'value': 'समाचेष्यमाण', 'label': 'samAcezyamARa', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'cover or fill with'}], 'isLemma': 0, 'parents': ['sam_A_ci']},
	{'value': 'समादधत्', 'label': 'samAdaDat', 'attrib': [{'pos': 'prap', 'defs': 'place together, compose, establish'}], 'isLemma': 0, 'parents': ['sam_A_DA']},
	{'value': 'समादधान', 'label': 'samAdaDAna', 'attrib': [{'pos': 'prmp', 'defs': 'place together, compose, establish'}, {'pos': 'pfmp, pfpp', 'defs': 'place together, compose, establish'}], 'isLemma': 0, 'parents': ['sam_A_DA']},
	{'value': 'समादधिवस्', 'label': 'samAdaDivas', 'attrib': [{'pos': 'pfap', 'defs': 'place together, compose, establish'}], 'isLemma': 0, 'parents': ['sam_A_DA']},
	{'value': 'समाधातव्य', 'label': 'samADAtavya', 'attrib': [{'pos': 'ger', 'defs': 'place together, compose, establish'}], 'isLemma': 0, 'parents': ['sam_A_DA']},
	{'value': 'समाधातुम्', 'label': 'samADAtum', 'attrib': [{'pos': 'inf', 'defs': 'place together, compose, establish'}], 'isLemma': 0, 'parents': ['sam_A_DA']},
	{'value': 'समाधानीय', 'label': 'samADAnIya', 'attrib': [{'pos': 'ger', 'defs': 'place together, compose, establish'}], 'isLemma': 0, 'parents': ['sam_A_DA']},
	{'value': 'समाधाय', 'label': 'samADAya', 'attrib': [{'pos': 'abs', 'defs': 'place together, compose, establish'}], 'isLemma': 0, 'parents': ['sam_A_DA']},
	{'value': 'समाधास्यत्', 'label': 'samADAsyat', 'attrib': [{'pos': 'fap', 'defs': 'place together, compose, establish'}], 'isLemma': 0, 'parents': ['sam_A_DA']},
	{'value': 'समाधास्यमान', 'label': 'samADAsyamAna', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'place together, compose, establish'}], 'isLemma': 0, 'parents': ['sam_A_DA']},
	{'value': 'समाधि', 'label': 'samADi', 'attrib': [{'pos': 'm', 'defs': 'putting together, completion, concentration, settled awareness'}], 'isLemma': 1},
	{'value': 'समाधीयमान', 'label': 'samADIyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'place together, compose, establish'}], 'isLemma': 0, 'parents': ['sam_A_DA']},
	{'value': 'समाधेय', 'label': 'samADeya', 'attrib': [{'pos': 'ger', 'defs': 'place together, compose, establish'}], 'isLemma': 0, 'parents': ['sam_A_DA']},
	{'value': 'समापनीय', 'label': 'samApanIya', 'attrib': [{'pos': 'ger', 'defs': 'accomplish, complete'}], 'isLemma': 0, 'parents': ['sam_Ap']},
	{'value': 'समापान', 'label': 'samApAna', 'attrib': [{'pos': 'pfpp', 'defs': 'accomplish, complete'}], 'isLemma': 0, 'parents': ['sam_Ap']},
	{'value': 'समापिवस्', 'label': 'samApivas', 'attrib': [{'pos': 'pfap', 'defs': 'accomplish, complete'}], 'isLemma': 0, 'parents': ['sam_Ap']},
	{'value': 'समाप्त', 'label': 'samApta', 'attrib': [{'pos': 'ppp', 'defs': 'accomplish, complete'}], 'isLemma': 0, 'parents': ['sam_Ap']},
	{'value': 'समाप्तवत्', 'label': 'samAptavat', 'attrib': [{'pos': 'pap', 'defs': 'accomplish, complete'}], 'isLemma': 0, 'parents': ['sam_Ap']},
	{'value': 'समाप्तव्य', 'label': 'samAptavya', 'attrib': [{'pos': 'ger', 'defs': 'accomplish, complete'}], 'isLemma': 0, 'parents': ['sam_Ap']},
	{'value': 'समाप्तुम्', 'label': 'samAptum', 'attrib': [{'pos': 'inf', 'defs': 'accomplish, complete'}], 'isLemma': 0, 'parents': ['sam_Ap']},
	{'value': 'समाप्नुवत्', 'label': 'samApnuvat', 'attrib': [{'pos': 'prap', 'defs': 'accomplish, complete'}], 'isLemma': 0, 'parents': ['sam_Ap']},
	{'value': 'समाप्य', 'label': 'samApya', 'attrib': [{'pos': 'abs', 'defs': 'accomplish, complete'}, {'pos': 'ger', 'defs': 'accomplish, complete'}], 'isLemma': 0, 'parents': ['sam_Ap']},
	{'value': 'समाप्यमान', 'label': 'samApyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'accomplish, complete'}], 'isLemma': 0, 'parents': ['sam_Ap']},
	{'value': 'समाप्स्यत्', 'label': 'samApsyat', 'attrib': [{'pos': 'fap', 'defs': 'accomplish, complete'}], 'isLemma': 0, 'parents': ['sam_Ap']},
	{'value': 'समाप्स्यमान', 'label': 'samApsyamAna', 'attrib': [{'pos': 'fpp', 'defs': 'accomplish, complete'}], 'isLemma': 0, 'parents': ['sam_Ap']},
	{'value': 'समाहित', 'label': 'samAhita', 'attrib': [{'pos': 'ppp', 'defs': 'place together, compose, establish'}], 'isLemma': 0, 'parents': ['sam_A_DA']},
	{'value': 'समाहितवत्', 'label': 'samAhitavat', 'attrib': [{'pos': 'pap', 'defs': 'place together, compose, establish'}], 'isLemma': 0, 'parents': ['sam_A_DA']},
	{'value': 'सम्_आ_चर्', 'label': 'sam_A_car', 'attrib': [{'pos': 'vt1am', 'defs': 'do, practice'}], 'isLemma': 1},
	{'value': 'सम्_आ_चि', 'label': 'sam_A_ci', 'attrib': [{'pos': 'vt5am', 'defs': 'cover or fill with'}], 'isLemma': 1},
	{'value': 'सम्_आ_धा', 'label': 'sam_A_DA', 'attrib': [{'pos': 'vt3am', 'defs': 'place together, compose, establish'}], 'isLemma': 1},
	{'value': 'सम्_आप्', 'label': 'sam_Ap', 'attrib': [{'pos': 'vt5a', 'defs': 'accomplish, complete'}], 'isLemma': 1},
	{'value': 'सम्_उद्_सह्', 'label': 'sam_ud_sah', 'attrib': [{'pos': 'vt1m', 'defs': 'be encouraged, endure, overcome; caus.: encourage, inspire'}], 'isLemma': 1},
	{'value': 'सम्_काश्', 'label': 'sam_kAS', 'attrib': [{'pos': 'vt1m, vt4m', 'defs': 'shine'}], 'isLemma': 1},
	{'value': 'सम्_गम्', 'label': 'sam_gam', 'attrib': [{'pos': 'vi1m', 'defs': 'go together, unite, meet'}], 'isLemma': 1},
	{'value': 'सम्_छिद्', 'label': 'sam_Cid', 'attrib': [{'pos': 'vt7am', 'defs': 'split, cut'}], 'isLemma': 1},
	{'value': 'सम्_धा', 'label': 'sam_DA', 'attrib': [{'pos': 'vt3am', 'defs': 'put together'}], 'isLemma': 1},
	{'value': 'सम्_पद्', 'label': 'sam_pad', 'attrib': [{'pos': 'vt4m', 'defs': 'succeed, arise'}], 'isLemma': 1},
	{'value': 'सम्_पूज्', 'label': 'sam_pUj', 'attrib': [{'pos': 'vt10m', 'defs': 'honor'}], 'isLemma': 1},
	{'value': 'सम्_प्र_वच्', 'label': 'sam_pra_vac', 'attrib': [{'pos': 'vt2a', 'defs': 'communicate, declare, relate in full'}], 'isLemma': 1},
	{'value': 'सम्_भू', 'label': 'sam_BU', 'attrib': [{'pos': 'vi1a', 'defs': 'come to be, arise, be possible'}], 'isLemma': 1},
	{'value': 'सम्_मन्', 'label': 'sam_man', 'attrib': [{'pos': 'vt4m, vt8m', 'defs': 'agree, approve, esteem, respect'}], 'isLemma': 1},
	{'value': 'सम्_मन्त्र्', 'label': 'sam_mantr', 'attrib': [{'pos': 'vt10m', 'defs': 'consult'}], 'isLemma': 1},
	{'value': 'सम्_वृ', 'label': 'sam_vf', 'attrib': [{'pos': 'vt5am', 'defs': 'cover, conceal'}], 'isLemma': 1},
	{'value': 'सम्_स्वप्', 'label': 'sam_svap', 'attrib': [{'pos': 'vi2a', 'defs': 'sleep'}], 'isLemma': 1},
	{'value': 'सर्प', 'label': 'sarpa', 'attrib': [{'pos': 'm', 'defs': 'serpent, snake'}], 'isLemma': 1},
	{'value': 'सर्व', 'label': 'sarva', 'attrib': [{'pos': 'pron_adj, pron', 'defs': 'all, whole'}], 'isLemma': 1},
	{'value': 'सर्वरत्न', 'label': 'sarvaratna', 'attrib': [{'pos': 'n', 'defs': 'all jewels'}], 'isLemma': 1},
	{'value': 'सर्वरत्नसमाचित', 'label': 'sarvaratnasamAcita', 'attrib': [{'pos': 'adj', 'defs': 'covered with all jewels, filled with all wealth'}], 'isLemma': 1},
	{'value': 'सर्वलोक', 'label': 'sarvaloka', 'attrib': [{'pos': 'm', 'defs': 'all regions'}], 'isLemma': 1},
	{'value': 'सर्वशस्', 'label': 'sarvaSas', 'attrib': [{'pos': 'adv', 'defs': 'wholly, in every way, everywhere'}], 'isLemma': 1},
	{'value': 'ससान', 'label': 'sasAna', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'end, finish'}], 'isLemma': 0, 'parents': ['so']},
	{'value': 'ससिवस्', 'label': 'sasivas', 'attrib': [{'pos': 'pfap', 'defs': 'end, finish'}], 'isLemma': 0, 'parents': ['so']},
	{'value': 'सस्रम्भाण', 'label': 'sasramBARa', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'be confident, relax'}], 'isLemma': 0, 'parents': ['sramB']},
	{'value': 'सह्', 'label': 'sah', 'attrib': [{'pos': 'vt1m', 'defs': 'bear, endure, overcome'}], 'isLemma': 1},
	{'value': 'सह', 'label': 'saha', 'attrib': [{'pos': 'pcl', 'defs': 'with'}], 'isLemma': 1},
	{'value': 'सहदेव', 'label': 'sahadeva', 'attrib': [{'pos': 'm_pn', 'defs': 'Sahadeva'}], 'isLemma': 1},
	{'value': 'सहनीय', 'label': 'sahanIya', 'attrib': [{'pos': 'ger', 'defs': 'bear, endure, overcome'}], 'isLemma': 0, 'parents': ['sah']},
	{'value': 'सहमान', 'label': 'sahamAna', 'attrib': [{'pos': 'prmp', 'defs': 'bear, endure, overcome'}], 'isLemma': 0, 'parents': ['sah']},
	{'value': 'सहसौबल', 'label': 'sahasObala', 'attrib': [{'pos': 'adj', 'defs': 'with the son of Subala'}], 'isLemma': 1},
	{'value': 'सहाय', 'label': 'sahAya', 'attrib': [{'pos': 'm', 'defs': 'companion'}], 'isLemma': 1},
	{'value': 'सहित', 'label': 'sahita', 'attrib': [{'pos': 'adj', 'defs': 'accompanied by'}], 'isLemma': 1},
	{'value': 'सहितव्य', 'label': 'sahitavya', 'attrib': [{'pos': 'ger', 'defs': 'bear, endure, overcome'}], 'isLemma': 0, 'parents': ['sah']},
	{'value': 'सहितुम्', 'label': 'sahitum', 'attrib': [{'pos': 'inf', 'defs': 'bear, endure, overcome'}], 'isLemma': 0, 'parents': ['sah']},
	{'value': 'सहित्वा', 'label': 'sahitvA', 'attrib': [{'pos': 'abs', 'defs': 'bear, endure, overcome'}], 'isLemma': 0, 'parents': ['sah']},
	{'value': 'सहिष्यमाण', 'label': 'sahizyamARa', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'bear, endure, overcome'}], 'isLemma': 0, 'parents': ['sah']},
	{'value': 'सह्य', 'label': 'sahya', 'attrib': [{'pos': 'ger', 'defs': 'bear, endure, overcome'}], 'isLemma': 0, 'parents': ['sah']},
	{'value': 'सह्यमान', 'label': 'sahyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'bear, endure, overcome'}], 'isLemma': 0, 'parents': ['sah']},
	{'value': 'सातव्य', 'label': 'sAtavya', 'attrib': [{'pos': 'ger', 'defs': 'end, finish'}], 'isLemma': 0, 'parents': ['so']},
	{'value': 'सातुम्', 'label': 'sAtum', 'attrib': [{'pos': 'inf', 'defs': 'end, finish'}], 'isLemma': 0, 'parents': ['so']},
	{'value': 'साद्य', 'label': 'sAdya', 'attrib': [{'pos': 'ger', 'defs': 'sit, be sad'}], 'isLemma': 0, 'parents': ['sad']},
	{'value': 'सानीय', 'label': 'sAnIya', 'attrib': [{'pos': 'ger', 'defs': 'end, finish'}], 'isLemma': 0, 'parents': ['so']},
	{'value': 'सास्यत्', 'label': 'sAsyat', 'attrib': [{'pos': 'fap', 'defs': 'end, finish'}], 'isLemma': 0, 'parents': ['so']},
	{'value': 'सास्यमान', 'label': 'sAsyamAna', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'end, finish'}], 'isLemma': 0, 'parents': ['so']},
	{'value': 'सित', 'label': 'sita', 'attrib': [{'pos': 'ppp', 'defs': 'end, finish'}], 'isLemma': 0, 'parents': ['so']},
	{'value': 'सितवत्', 'label': 'sitavat', 'attrib': [{'pos': 'pap', 'defs': 'end, finish'}], 'isLemma': 0, 'parents': ['so']},
	{'value': 'सित्वा', 'label': 'sitvA', 'attrib': [{'pos': 'abs', 'defs': 'end, finish'}], 'isLemma': 0, 'parents': ['so']},
	{'value': 'सीदत्', 'label': 'sIdat', 'attrib': [{'pos': 'prap', 'defs': 'sit, be sad'}], 'isLemma': 0, 'parents': ['sad']},
	{'value': 'सीयमान', 'label': 'sIyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'end, finish'}], 'isLemma': 0, 'parents': ['so']},
	{'value': 'सु', 'label': 'su', 'attrib': [{'pos': 'preverb', 'defs': 'well'}], 'isLemma': 1},
	{'value': 'सुख', 'label': 'suKa', 'attrib': [{'pos': 'adj', 'defs': 'happy'}, {'pos': 'n', 'defs': 'happiness'}], 'isLemma': 1},
	{'value': 'सुखावह', 'label': 'suKAvaha', 'attrib': [{'pos': 'adj', 'defs': 'bringing happiness'}], 'isLemma': 1},
	{'value': 'सुदुर्मति', 'label': 'sudurmati', 'attrib': [{'pos': 'adj', 'defs': 'very weak-minded, very evil-minded'}], 'isLemma': 1},
	{'value': 'सुप्त', 'label': 'supta', 'attrib': [{'pos': 'ppp', 'defs': 'sleep'}], 'isLemma': 0, 'parents': ['svap']},
	{'value': 'सुप्तवत्', 'label': 'suptavat', 'attrib': [{'pos': 'pap', 'defs': 'sleep'}], 'isLemma': 0, 'parents': ['svap']},
	{'value': 'सुप्त्वा', 'label': 'suptvA', 'attrib': [{'pos': 'abs', 'defs': 'sleep'}], 'isLemma': 0, 'parents': ['svap']},
	{'value': 'सुप्यमाण', 'label': 'supyamARa', 'attrib': [{'pos': 'prpp', 'defs': 'sleep'}], 'isLemma': 0, 'parents': ['svap']},
	{'value': 'सुबल', 'label': 'subala', 'attrib': [{'pos': 'm_pn', 'defs': 'Subala'}, {'pos': 'adj', 'defs': 'very powerful'}], 'isLemma': 1},
	{'value': 'सुभद्र', 'label': 'suBadra', 'attrib': [{'pos': 'adj', 'defs': 'very auspicious'}], 'isLemma': 1},
	{'value': 'सुभद्रा', 'label': 'suBadrA', 'attrib': [{'pos': 'f_pn', 'defs': 'Subhadrā'}], 'isLemma': 1},
	{'value': 'सुविभक्त', 'label': 'suviBakta', 'attrib': [{'pos': 'adj', 'defs': 'well distributed'}], 'isLemma': 1},
	{'value': 'सुविभक्तमहापथ', 'label': 'suviBaktamahApaTa', 'attrib': [{'pos': 'adj', 'defs': 'whose great roads are well-distributed'}], 'isLemma': 1},
	{'value': 'सुषुपान', 'label': 'suzupAna', 'attrib': [{'pos': 'pfpp', 'defs': 'sleep'}], 'isLemma': 0, 'parents': ['svap']},
	{'value': 'सुषुपिवस्', 'label': 'suzupivas', 'attrib': [{'pos': 'pfap', 'defs': 'sleep'}], 'isLemma': 0, 'parents': ['svap']},
	{'value': 'सुहृज्जन', 'label': 'suhfjjana', 'attrib': [{'pos': 'm', 'defs': 'friendly people'}], 'isLemma': 1},
	{'value': 'सुहृद्', 'label': 'suhfd', 'attrib': [{'pos': 'm', 'defs': 'friend'}, {'pos': 'adj', 'defs': 'good-hearted, well-wishing'}], 'isLemma': 1},
	{'value': 'सूर्य', 'label': 'sUrya', 'attrib': [{'pos': 'm', 'defs': 'sun'}], 'isLemma': 1},
	{'value': 'सूर्यसंकाश', 'label': 'sUryasaMkASa', 'attrib': [{'pos': 'adj', 'defs': 'whose appearance is like the sun'}], 'isLemma': 1},
	{'value': 'सेदान', 'label': 'sedAna', 'attrib': [{'pos': 'pfpp', 'defs': 'sit, be sad'}], 'isLemma': 0, 'parents': ['sad']},
	{'value': 'सेदिवस्', 'label': 'sedivas', 'attrib': [{'pos': 'pfap', 'defs': 'sit, be sad'}], 'isLemma': 0, 'parents': ['sad']},
	{'value': 'सेना', 'label': 'senA', 'attrib': [{'pos': 'f', 'defs': 'army'}], 'isLemma': 1},
	{'value': 'सेय', 'label': 'seya', 'attrib': [{'pos': 'ger', 'defs': 'end, finish'}], 'isLemma': 0, 'parents': ['so']},
	{'value': 'सेहान', 'label': 'sehAna', 'attrib': [{'pos': 'pfmp', 'defs': 'bear, endure, overcome'}], 'isLemma': 0, 'parents': ['sah']},
	{'value': 'सो', 'label': 'so', 'attrib': [{'pos': 'vt4a', 'defs': 'end, finish'}], 'isLemma': 1},
	{'value': 'सोढ', 'label': 'soQa', 'attrib': [{'pos': 'ppp', 'defs': 'bear, endure, overcome'}], 'isLemma': 0, 'parents': ['sah']},
	{'value': 'सोढवत्', 'label': 'soQavat', 'attrib': [{'pos': 'pap', 'defs': 'bear, endure, overcome'}], 'isLemma': 0, 'parents': ['sah']},
	{'value': 'सोढव्य', 'label': 'soQavya', 'attrib': [{'pos': 'ger', 'defs': 'bear, endure, overcome'}], 'isLemma': 0, 'parents': ['sah']},
	{'value': 'सोढुम्', 'label': 'soQum', 'attrib': [{'pos': 'inf', 'defs': 'bear, endure, overcome'}], 'isLemma': 0, 'parents': ['sah']},
	{'value': 'सोढ्वा', 'label': 'soQvA', 'attrib': [{'pos': 'abs', 'defs': 'bear, endure, overcome'}], 'isLemma': 0, 'parents': ['sah']},
	{'value': 'सौबल', 'label': 'sObala', 'attrib': [{'pos': 'm', 'defs': 'son of Subala'}], 'isLemma': 1},
	{'value': 'स्थ', 'label': 'sTa', 'attrib': [{'pos': 'adj', 'defs': 'standing'}], 'isLemma': 1},
	{'value': 'स्था', 'label': 'sTA', 'attrib': [{'pos': 'vi1am', 'defs': 'stand, stay, be present'}], 'isLemma': 1},
	{'value': 'स्थातव्य', 'label': 'sTAtavya', 'attrib': [{'pos': 'ger', 'defs': 'stand, stay, be present'}], 'isLemma': 0, 'parents': ['sTA']},
	{'value': 'स्थातुम्', 'label': 'sTAtum', 'attrib': [{'pos': 'inf', 'defs': 'stand, stay, be present'}], 'isLemma': 0, 'parents': ['sTA']},
	{'value': 'स्थानीय', 'label': 'sTAnIya', 'attrib': [{'pos': 'ger', 'defs': 'stand, stay, be present'}], 'isLemma': 0, 'parents': ['sTA']},
	{'value': 'स्थापयितुम्', 'label': 'sTApayitum', 'attrib': [{'pos': 'inf', 'defs': 'stand, stay, be present'}], 'isLemma': 0, 'parents': ['sTA']},
	{'value': 'स्थास्यत्', 'label': 'sTAsyat', 'attrib': [{'pos': 'fap', 'defs': 'stand, stay, be present'}], 'isLemma': 0, 'parents': ['sTA']},
	{'value': 'स्थास्यमान', 'label': 'sTAsyamAna', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'stand, stay, be present'}], 'isLemma': 0, 'parents': ['sTA']},
	{'value': 'स्थित', 'label': 'sTita', 'attrib': [{'pos': 'ppp', 'defs': 'stand, stay, be present'}], 'isLemma': 0, 'parents': ['sTA']},
	{'value': 'स्थितवत्', 'label': 'sTitavat', 'attrib': [{'pos': 'pap', 'defs': 'stand, stay, be present'}], 'isLemma': 0, 'parents': ['sTA']},
	{'value': 'स्थित्वा', 'label': 'sTitvA', 'attrib': [{'pos': 'abs', 'defs': 'stand, stay, be present'}], 'isLemma': 0, 'parents': ['sTA']},
	{'value': 'स्थिर', 'label': 'sTira', 'attrib': [{'pos': 'adj', 'defs': 'steady, firm'}], 'isLemma': 1},
	{'value': 'स्थीयमान', 'label': 'sTIyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'stand, stay, be present'}], 'isLemma': 0, 'parents': ['sTA']},
	{'value': 'स्थेय', 'label': 'sTeya', 'attrib': [{'pos': 'ger', 'defs': 'stand, stay, be present'}], 'isLemma': 0, 'parents': ['sTA']},
	{'value': 'स्पन्द', 'label': 'spanda', 'attrib': [{'pos': 'm', 'defs': 'vibration, activity'}], 'isLemma': 1},
	{'value': 'स्यत्', 'label': 'syat', 'attrib': [{'pos': 'prap', 'defs': 'end, finish'}], 'isLemma': 0, 'parents': ['so']},
	{'value': 'स्यमान', 'label': 'syamAna', 'attrib': [{'pos': 'prmp', 'defs': 'end, finish'}], 'isLemma': 0, 'parents': ['so']},
	{'value': 'स्रब्ध', 'label': 'srabDa', 'attrib': [{'pos': 'ppp', 'defs': 'be confident, relax'}], 'isLemma': 0, 'parents': ['sramB']},
	{'value': 'स्रब्धवत्', 'label': 'srabDavat', 'attrib': [{'pos': 'pap', 'defs': 'be confident, relax'}], 'isLemma': 0, 'parents': ['sramB']},
	{'value': 'स्रब्ध्वा', 'label': 'srabDvA', 'attrib': [{'pos': 'abs', 'defs': 'be confident, relax'}], 'isLemma': 0, 'parents': ['sramB']},
	{'value': 'स्रभ्यमाण', 'label': 'sraByamARa', 'attrib': [{'pos': 'prpp', 'defs': 'be confident, relax'}], 'isLemma': 0, 'parents': ['sramB']},
	{'value': 'स्रम्भ्', 'label': 'sramB', 'attrib': [{'pos': 'vi1m', 'defs': 'be confident, relax'}], 'isLemma': 1},
	{'value': 'स्रम्भणीय', 'label': 'sramBaRIya', 'attrib': [{'pos': 'ger', 'defs': 'be confident, relax'}], 'isLemma': 0, 'parents': ['sramB']},
	{'value': 'स्रम्भमाण', 'label': 'sramBamARa', 'attrib': [{'pos': 'prmp', 'defs': 'be confident, relax'}], 'isLemma': 0, 'parents': ['sramB']},
	{'value': 'स्रम्भितव्य', 'label': 'sramBitavya', 'attrib': [{'pos': 'ger', 'defs': 'be confident, relax'}], 'isLemma': 0, 'parents': ['sramB']},
	{'value': 'स्रम्भितुम्', 'label': 'sramBitum', 'attrib': [{'pos': 'inf', 'defs': 'be confident, relax'}], 'isLemma': 0, 'parents': ['sramB']},
	{'value': 'स्रम्भित्वा', 'label': 'sramBitvA', 'attrib': [{'pos': 'abs', 'defs': 'be confident, relax'}], 'isLemma': 0, 'parents': ['sramB']},
	{'value': 'स्रम्भिष्यमाण', 'label': 'sramBizyamARa', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'be confident, relax'}], 'isLemma': 0, 'parents': ['sramB']},
	{'value': 'स्रम्भ्य', 'label': 'sramBya', 'attrib': [{'pos': 'ger', 'defs': 'be confident, relax'}], 'isLemma': 0, 'parents': ['sramB']},
	{'value': 'स्व', 'label': 'sva', 'attrib': [{'pos': 'pron, pron_adj', 'defs': "one's own"}], 'isLemma': 1},
	{'value': 'स्वक', 'label': 'svaka', 'attrib': [{'pos': 'adj', 'defs': "own, one's own"}], 'isLemma': 1},
	{'value': 'स्वप्', 'label': 'svap', 'attrib': [{'pos': 'vi2a', 'defs': 'sleep'}], 'isLemma': 1},
	{'value': 'स्वपत्', 'label': 'svapat', 'attrib': [{'pos': 'prap', 'defs': 'sleep'}], 'isLemma': 0, 'parents': ['svap']},
	{'value': 'स्वपनीय', 'label': 'svapanIya', 'attrib': [{'pos': 'ger', 'defs': 'sleep'}], 'isLemma': 0, 'parents': ['svap']},
	{'value': 'स्वप्तव्य', 'label': 'svaptavya', 'attrib': [{'pos': 'ger', 'defs': 'sleep'}], 'isLemma': 0, 'parents': ['svap']},
	{'value': 'स्वप्तुम्', 'label': 'svaptum', 'attrib': [{'pos': 'inf', 'defs': 'sleep'}], 'isLemma': 0, 'parents': ['svap']},
	{'value': 'स्वप्य', 'label': 'svapya', 'attrib': [{'pos': 'ger', 'defs': 'sleep'}], 'isLemma': 0, 'parents': ['svap']},
	{'value': 'स्वप्स्यत्', 'label': 'svapsyat', 'attrib': [{'pos': 'fap', 'defs': 'sleep'}], 'isLemma': 0, 'parents': ['svap']},
	{'value': 'स्वप्स्यमाण', 'label': 'svapsyamARa', 'attrib': [{'pos': 'fpp', 'defs': 'sleep'}], 'isLemma': 0, 'parents': ['svap']},
	{'value': 'स्वमन्दिर', 'label': 'svamandira', 'attrib': [{'pos': 'n', 'defs': "one's own house, palace"}], 'isLemma': 1},
	{'value': 'स्वर्', 'label': 'svar', 'attrib': [{'pos': 'pcl', 'defs': 'sun, heaven'}], 'isLemma': 1},
	{'value': 'स्वर्ग', 'label': 'svarga', 'attrib': [{'pos': 'm', 'defs': 'heaven'}], 'isLemma': 1},
	{'value': 'स्वर्गस्थ', 'label': 'svargasTa', 'attrib': [{'pos': 'adj', 'defs': 'standing in, present in heaven'}], 'isLemma': 1},
	{'value': 'ह', 'label': 'ha', 'attrib': [{'pos': 'pcl', 'defs': 'indeed'}, {'pos': 'adj', 'defs': 'smiting'}], 'isLemma': 1},
	{'value': 'हंस्यत्', 'label': 'haMsyat', 'attrib': [{'pos': 'fap', 'defs': 'smite'}], 'isLemma': 0, 'parents': ['han']},
	{'value': 'हंस्यमान', 'label': 'haMsyamAna', 'attrib': [{'pos': 'fpp', 'defs': 'smite'}], 'isLemma': 0, 'parents': ['han']},
	{'value': 'हत', 'label': 'hata', 'attrib': [{'pos': 'ppp', 'defs': 'smite'}], 'isLemma': 0, 'parents': ['han']},
	{'value': 'हतवत्', 'label': 'hatavat', 'attrib': [{'pos': 'pap', 'defs': 'smite'}], 'isLemma': 0, 'parents': ['han']},
	{'value': 'हत्वा', 'label': 'hatvA', 'attrib': [{'pos': 'abs', 'defs': 'smite'}], 'isLemma': 0, 'parents': ['han']},
	{'value': 'हन्', 'label': 'han', 'attrib': [{'pos': 'vt2a', 'defs': 'smite'}, {'pos': 'adj', 'defs': 'smiting'}], 'isLemma': 1},
	{'value': 'हननीय', 'label': 'hananIya', 'attrib': [{'pos': 'ger', 'defs': 'smite'}], 'isLemma': 0, 'parents': ['han']},
	{'value': 'हनिष्यत्', 'label': 'hanizyat', 'attrib': [{'pos': 'fap', 'defs': 'smite'}], 'isLemma': 0, 'parents': ['han']},
	{'value': 'हनिष्यमाण', 'label': 'hanizyamARa', 'attrib': [{'pos': 'fpp', 'defs': 'smite'}], 'isLemma': 0, 'parents': ['han']},
	{'value': 'हन्तव्य', 'label': 'hantavya', 'attrib': [{'pos': 'ger', 'defs': 'smite'}], 'isLemma': 0, 'parents': ['han']},
	{'value': 'हन्तुम्', 'label': 'hantum', 'attrib': [{'pos': 'inf', 'defs': 'smite'}], 'isLemma': 0, 'parents': ['han']},
	{'value': 'हन्यमान', 'label': 'hanyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'smite'}], 'isLemma': 0, 'parents': ['han']},
	{'value': 'हवनीय', 'label': 'havanIya', 'attrib': [{'pos': 'ger', 'defs': 'offer, worship'}], 'isLemma': 0, 'parents': ['hu']},
	{'value': 'हव्य', 'label': 'havya', 'attrib': [{'pos': 'n', 'defs': 'oblation'}, {'pos': 'ger', 'defs': 'offer, worship'}], 'isLemma': 1},
	{'value': 'हव्य', 'label': 'havya', 'attrib': [{'pos': 'n', 'defs': 'oblation'}, {'pos': 'ger', 'defs': 'offer, worship'}], 'isLemma': 0, 'parents': ['hu']},
	{'value': 'हव्यवाहन', 'label': 'havyavAhana', 'attrib': [{'pos': 'm', 'defs': 'oblation-bearer, fire'}], 'isLemma': 1},
	{'value': 'हस्त', 'label': 'hasta', 'attrib': [{'pos': 'm', 'defs': 'hand'}], 'isLemma': 1},
	{'value': 'हस्तिन्', 'label': 'hastin', 'attrib': [{'pos': 'adj', 'defs': 'having a hand'}, {'pos': 'm', 'defs': 'elephant'}, {'pos': 'm_pn', 'defs': 'Hastin'}], 'isLemma': 1},
	{'value': 'हास्तिन', 'label': 'hAstina', 'attrib': [{'pos': 'adj', 'defs': 'founded by Hastin'}], 'isLemma': 1},
	{'value': 'हास्तिनपुर', 'label': 'hAstinapura', 'attrib': [{'pos': 'n_pn', 'defs': 'Hāstinapura'}], 'isLemma': 1},
	{'value': 'हि', 'label': 'hi', 'attrib': [{'pos': 'sub_pcl', 'defs': 'for, because'}], 'isLemma': 1},
	{'value': 'हिडिम्ब', 'label': 'hiqimba', 'attrib': [{'pos': 'm_pn', 'defs': 'Hiḍimba'}], 'isLemma': 1},
	{'value': 'हित', 'label': 'hita', 'attrib': [{'pos': 'ppp', 'defs': 'put'}, {'pos': 'adj', 'defs': 'beneficial, good'}], 'isLemma': 1},
	{'value': 'हित', 'label': 'hita', 'attrib': [{'pos': 'ppp', 'defs': 'put'}, {'pos': 'adj', 'defs': 'beneficial, good'}], 'isLemma': 0, 'parents': ['DA']},
	{'value': 'हितवत्', 'label': 'hitavat', 'attrib': [{'pos': 'pap', 'defs': 'put'}], 'isLemma': 0, 'parents': ['DA']},
	{'value': 'हित्वा', 'label': 'hitvA', 'attrib': [{'pos': 'abs', 'defs': 'put'}], 'isLemma': 0, 'parents': ['DA']},
	{'value': 'हु', 'label': 'hu', 'attrib': [{'pos': 'vt3a', 'defs': 'offer, worship'}], 'isLemma': 1},
	{'value': 'हुत', 'label': 'huta', 'attrib': [{'pos': 'ppp', 'defs': 'offer, worship'}], 'isLemma': 0, 'parents': ['hu']},
	{'value': 'हुतवत्', 'label': 'hutavat', 'attrib': [{'pos': 'pap', 'defs': 'offer, worship'}], 'isLemma': 0, 'parents': ['hu']},
	{'value': 'हुत्वा', 'label': 'hutvA', 'attrib': [{'pos': 'abs', 'defs': 'offer, worship'}], 'isLemma': 0, 'parents': ['hu']},
	{'value': 'हूयमान', 'label': 'hUyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'offer, worship'}], 'isLemma': 0, 'parents': ['hu']},
	{'value': 'हृद्', 'label': 'hfd', 'attrib': [{'pos': 'n', 'defs': 'heart'}], 'isLemma': 1},
	{'value': 'हृष्', 'label': 'hfz', 'attrib': [{'pos': 'vi4a', 'defs': 'be excited, rejoice'}], 'isLemma': 1},
	{'value': 'हृषीक', 'label': 'hfzIka', 'attrib': [{'pos': 'n', 'defs': 'sense-organ'}], 'isLemma': 1},
	{'value': 'हृषीकेश', 'label': 'hfzIkeSa', 'attrib': [{'pos': 'm', 'defs': 'lord of the senses'}], 'isLemma': 1},
	{'value': 'हे', 'label': 'he', 'attrib': [{'pos': 'pcl', 'defs': 'O, hey'}], 'isLemma': 1},
	{'value': 'होतव्य', 'label': 'hotavya', 'attrib': [{'pos': 'ger', 'defs': 'offer, worship'}], 'isLemma': 0, 'parents': ['hu']},
	{'value': 'होतुम्', 'label': 'hotum', 'attrib': [{'pos': 'inf', 'defs': 'offer, worship'}], 'isLemma': 0, 'parents': ['hu']},
	{'value': 'होष्यत्', 'label': 'hozyat', 'attrib': [{'pos': 'fap', 'defs': 'offer, worship'}], 'isLemma': 0, 'parents': ['hu']},
	{'value': 'होष्यमाण', 'label': 'hozyamARa', 'attrib': [{'pos': 'fpp', 'defs': 'offer, worship'}], 'isLemma': 0, 'parents': ['hu']}
]

