import React, {useContext, useState} from 'react';

import {Accordion, AccordionContext, 
			useAccordionButton, Button, Modal} from 'react-bootstrap';

import '../../css/Components.css';

// Lists of words
const articles =['a', 'the'];
const prepositions =['to', 'with', 'by', 'for', 'from', 'in', 'on', 'at', 'of']
const pronouns =['he', 'she', 'they', 'you', 'I', 'we', 'him', 'them', 'us', 'his', 'her', 'their', 'your', 'my', 'our']
const verbs =['has', 'have', 'had'] 

const WordsList =({curWords}) => (
	<ul style={{fontSize:'14pt', maxHeight:'200px', minHeight:'200px',
						display:'flex', flexFlow:'column wrap',
						columnGap:'30px', overflowX:'auto',
						listStylePosition:'inside'
				}}>
		{curWords.map(word => (
			<li>{word}</li>
		))}
	</ul>
);


// Main Component
const AuxWords = () =>{

	const [open, setOpen] =useState(false);

	const CustomToggle = ({ btnPart, eventKey, callback }) => {
		const currentEventKey = useContext(AccordionContext);
		const isCurrentEventKey = currentEventKey.activeEventKey === eventKey;
		const decoratedOnClick = useAccordionButton(eventKey, 
			() => callback && callback(eventKey),
		);
	
		return (
			<Button key={btnPart} variant="outline-primary" 
				active={isCurrentEventKey}
				onClick={() =>{
					decoratedOnClick();
				}}>
				{btnPart}
			</Button>
		);
	};

	return(
		<>
			<Modal show={open} onHide={()=>setOpen(false)} style={{marginTop:'15vh'}}>
				<Accordion defaultActiveKey={1}>

					<Modal.Header closeButton>
						<div className="interfaceTitle" style={{minWidth:'94%'}}>
							Auxiliary words
						</div>
					</Modal.Header>
					
					<Modal.Body style={{width:'fit-content',marginLeft:'auto', marginRight:'auto'}}>
						<div className="mb-2">
							<CustomToggle key={1} btnPart="Articles" eventKey={1} />
							<CustomToggle key={2} btnPart="Prepositions" eventKey={2} />
							<CustomToggle key={3} btnPart="Pronouns" eventKey={3} />
							<CustomToggle key={4} btnPart="Verbs" eventKey={4} />
						</div>
						
						<Accordion.Collapse key={1} eventKey={1}>
							<WordsList curWords={articles} />
						</Accordion.Collapse>
						<Accordion.Collapse key={2} eventKey={2}>
							<WordsList curWords={prepositions} />
						</Accordion.Collapse>
						<Accordion.Collapse key={3} eventKey={3}>
							<WordsList curWords={pronouns} />
						</Accordion.Collapse>
						<Accordion.Collapse key={4} eventKey={4}>
							<WordsList curWords={verbs} />
						</Accordion.Collapse>			
					</Modal.Body>

				</Accordion>
			</Modal>
			<span onClick={()=>setOpen(true)} style={{color:'#0d6efd', cursor:'pointer'}}>Auxiliary words</span>
		</>
	);
};

export default AuxWords