import React, { useState, useEffect, useContext, useRef } from 'react';

// import Alert from "react-bootstrap/Alert";
import Button from 'react-bootstrap/Button';
import { ButtonGroup } from 'react-bootstrap';
import Breadcrumb from 'react-bootstrap/Breadcrumb';

import Accordion from 'react-bootstrap/Accordion';
import AccordionContext from 'react-bootstrap/AccordionContext';
import { useAccordionButton } from 'react-bootstrap/AccordionButton';

import Tooltip from 'react-bootstrap/Tooltip'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'

import Error from './Error';
import QnA from './qparts/QnA';
import RightSidebar from './RightSidebar';
import { UserContext } from '../providers/UserProvider';

import styled from 'styled-components';
import './css/Components.css';

const Sentence =styled.div`
	// font-weight: ${props=>props.isCur?600:"light"};
	color: ${props=>props.isCur?"black":"rgb(95, 95, 95)"};
	font-family: 'Praja';
	font-size: 30px;
	margin-left: 20px;
`;
const Speaker =styled.div`
	color: var(--primary-color);
	font-family: 'Praja';
	font-size: 32px;
	margin-left: 5px;
`;

const OneQuestion = ({match}) =>{
	// Alert on leaving page
	const [ansSaved, setAnsSaved] =useState(false);
	const [btnClicked, setBtnClicked] =useState(false);
	const [blockNavigation, setBlockNavigation]=useState(true);
	
	const [gotoLink, setGotoLink] =useState("");

	const confirmNavigation=(e)=>{
		e.preventDefault();
		var message = "Are you sure you want to leave?";
		return blockNavigation ?message : void(0);
	}
	window.onbeforeunload = confirmNavigation;

	// Utility Functions
	// console.log('blockNavigation:',blockNavigation)
	useEffect(() => {
		console.log('useEffect ansSaved=', ansSaved);
		if (ansSaved===true){
			// Reset
			setAnsSaved(false);
			setBtnClicked(false);
			
			// Goes to link only when answer is saved
			window.location.href =gotoLink;
		};
	}, [ansSaved, gotoLink]);

	const goto =async (link)=>{
		setGotoLink(link);
		setBlockNavigation(false);
		
		setBtnClicked(true);
		/*
			When btnClicked is made true, 
				useEffect in question parts will be triggered and
				this will set ansSaved to true and 
					the above useEffect will be called
		*/
	};
	const NextButton =({showNext, completed, link, chLink, css})=>{
		if (!showNext){
			if (completed){
				return <Button onClick={()=>goto(chLink)} variant="light" className={css}>Finished</Button>
			}
			return <></>
		}

		// console.log('nxtLink:', link);
		if (completed){
			return <Button onClick={()=>goto(link)} variant="light" className={css}>Next</Button>
		}
		return (
			<OverlayTrigger
				placement="top"
				delay={{ show: 100, hide: 200 }}
				overlay={
					<Tooltip id={`tooltip-top`}>
						Please complete all parts
					</Tooltip>
					}
			>
				<Button href="#" variant="dark" className={css}>Next</Button>
			</OverlayTrigger>
		)
	}

	// Variables
	const {currentUser} =useContext(UserContext);
	// /p=:p/a=:a/:type/:n/:i
	// const ch =match.params.ch, exIndex =match.params.i, qIndex = match.params.j;
	const p =match.params.p, a =match.params.a, type =match.params.type, n =match.params.n, s =match.params.s;

	const [done, setDone] = useState(false);
	const [access, setAccess] = useState({restricted: true, msg: "Context Restricted"});

	const [total, setTotal] =useState(0);
	
	// Question Details
	const [curQ, setQ] = useState({});				 // curQ: current Question's Details !
	const [parts, setParts] = useState([]);			// Parts of the question (transliteration, sandhi...etc)
	const [curPart, setCurPart] =useState('transliteration');	//previously pi/curPi or cur part's index
	const [partInst, setPartInst] =useState("");//Instruction for the specific part
	const [context, setContext] =useState([]);
	const [speaker, setSpeaker] =useState("");

	const [compounds, setCompounds] =useState([]);

	// secondPartBtn
	// const [partTwoBtn, setPartTwoBtn]=useState(null);
	const partTwoBtn =React.createRef();

	const [curQComplete, setCurQComplete] =useState(false);

	// LElement: 2nd QnA
	const [curQ2, setQ2] =useState({});
	const [curQComplete2, setCurQComplete2] =useState([]);

	// instruction or context
	const [selectedInfo, setSelectedInfo] =useState('Instruction');

	// Help sidebar
	const [sidebar, setSidebar] =useState([]);

	useEffect(() => {		
		setSelectedInfo('Instruction');
	}, [partInst]);

	useEffect(() => {
		const getDetails =async (type, s) => {
			console.log('getting:', type, s);
			setDone(false);
			const postObj ={
				method: 'POST',
				cache: 'no-cache',
				headers: {'Content-Type': 'application/json'},
				body: JSON.stringify({p: p, a: a, type: type, n: n, s: s,
												curPart: curPart, email:currentUser.email})
			}
			const response = await fetch(process.env.REACT_APP_FLASK_API+'get_question', postObj);
			const data = await response.json();
			// console.log(data);
			
			console.log("1. DATA", data);
						
			if (type==='lElement' && s===2) {
				console.log("2. L2", data);
				setQ2(data.curQ);
				setCurQComplete2(data.curQ.completed);
			} else {
				console.log("3. rest", data);
				setQ(data.curQ);
				setCurQComplete(data.curQ.completed);
			}
			console.log("4. outside", data);
			setTotal(data.curQ.total);
			setParts(data.curQ.parts); 
			setPartInst(data.curQ.partInst);
			setCompounds(data.curQ.text.compounds);

			console.log("5. outside", data);
			setContext(data.curQ.context.context);
			setSpeaker(data.curQ.context.speaker);
			setAccess({restricted: data.restricted, msg: data.msg});

			console.log(data.curQ.sidebar.length, data.curQ.sidebar);
			setSidebar(data.curQ.sidebar);

			if (type==='lElement' && s===1) {
				setDone(false);
			}
			else {
				setDone(true);
			}
			
		};
		getDetails(type, s);
		if (type==='lElement') {
			getDetails(type, parseInt(s, 10)+1);
		}

   }, [p, a, type, n, s, curPart, currentUser.email]);

	const CustomToggle = ({idx, btnPart, eventKey, callback }) => {
		const currentEventKey = useContext(AccordionContext);

		const isCurrentEventKey = currentEventKey.activeEventKey === eventKey;
		const decoratedOnClick = useAccordionButton(eventKey, 
			() => callback && callback(eventKey)
		);
	   		
		// console.log('accordion', idx, btnPart);
		if (idx ===1){
			return (
				<Button key={btnPart} variant="outline-primary" 
					ref={partTwoBtn} active={isCurrentEventKey}
					onClick={() =>{
						decoratedOnClick();
						setCurPart(btnPart);
					}}>
					
					{btnPart.charAt(0).toUpperCase()+btnPart.toLowerCase().replaceAll('-', ' ').substring(1)}
				</Button>
			);
		}
		else {
			return (
				<Button key={btnPart} variant="outline-primary" 
					active={isCurrentEventKey}
					onClick={() =>{
						decoratedOnClick();
						setCurPart(btnPart);
					}}>
					
					{btnPart.charAt(0).toUpperCase()+btnPart.toLowerCase().replaceAll('-', ' ').substring(1)}
				</Button>
			);
		}
	};
	const toggleText=(i)=>{
		var dots = document.getElementsByClassName("dots");
		var moreText = document.getElementsByClassName("more");
		var btnText = document.getElementsByClassName("triggerText");

		if (dots[i].style.display === "none") {
			dots[i].style.display = "inline";
			btnText[i].innerHTML = "See more";
			moreText[i].style.display = "none";
		} else {
			dots[i].style.display = "none";
			btnText[i].innerHTML = "See less";
			moreText[i].style.display = "inline";
		}
	};

	const isEmpty = (obj) =>{
		return Object.keys(obj).length===0 &&
					obj.constructor === Object;
	};
	useEffect(() => {
		window.addEventListener('keydown', e=>console.log(''));
	}, []);
	const clickCR =() =>{
		var k =new KeyboardEvent('keydown', {
			key: 'Enter',
			code: 'Enter',
			which: 13,
			keyCode: 13,
		});
		window.dispatchEvent(k);
		console.log('CR keypress');
	}

   	if(done && access.restricted){
	   return( <Error message={access.msg} />)
   }
   	else if(done && isEmpty(curQ)){
		return(<h1>No such page</h1>)
	}
	else if(done){	
		return (
		<div className='OneQuestion'>
			
			<div className='oqQuestionPart' >
				<Breadcrumb className='breadcrumbs'>
					<Breadcrumb.Item href={ `/p=${p}/a=${a}`}><span style={{fontFamily:'praja', fontSize:'22pt'}}>अध्याय </span>{`${match.params.a}`}</Breadcrumb.Item>
					<Breadcrumb.Item style={{paddingTop:'8px'}}  href={ `/p=${p}/a=${a}/${curQ.parentType}/${n}`}>{curQ.parent}</Breadcrumb.Item>
					<Breadcrumb.Item style={{paddingTop:'8px'}}  href="#" active>{curQ.title}</Breadcrumb.Item>
				</Breadcrumb>

				{/* each parts button and it's collapsible instruction */}
				{console.log('OneQ: parts: ', parts)}
				<Accordion defaultActiveKey={curPart}>
					<div className="widthVW d-flex justify-content-between">	
						<div>
							{parts.map((part, idx) => (
								<CustomToggle idx ={idx} key={part} 
									btnPart={part} eventKey={part}/>
							))}
						</div>
						{context.length ?
							<div>
								<ButtonGroup>
									{['Instruction','Context'].map(info=>(
										<Button
											key={info}
											type="radio"
											variant="info"
											active={info===selectedInfo}
											onClick={e=>setSelectedInfo(info)}
										>
											{info}
										</Button>
									))}
								</ButtonGroup>
							</div>
							:<></>
						}
					</div>
					{/* {console.log('OneQ: partInst', partInst)} */}
					{parts.map((part, i) => (
						<Accordion.Collapse key={part} eventKey={part}>
							<div className="partInst widthVW">
							{
								selectedInfo==='Context' 
								?
									<div className='contextArea'>
										{speaker &&
											<Speaker>{speaker} {'\u2012'}</Speaker>
										}
										{context.map((cur, i)=>(
											<div key={i} style={{display:'flex', flexFlow:'row nowrap', alignContent:'center'}}>
												<div style={{marginLeft:'15px', maxHeight:'10px'}}>{i+1}</div> 
												<Sentence isCur={cur.isCur} key={i}>
													{cur.sentence}
												</Sentence>
											</div>
										))}
									</div>
								:
									<>
										<span dangerouslySetInnerHTML={{__html: partInst.slice(0,100)}} />
										{partInst.length>100 &&
										<>
											<span className="dots">...</span>
											<span className="more" dangerouslySetInnerHTML={{__html: partInst.slice(100)}} />
											<span className="triggerText" variant="secondary" 
														size="sm" onClick={()=>toggleText(i)}>See more</span>
										</>
										}
									</>
							}
							</div>
						</Accordion.Collapse>
					))}
				</Accordion>

				{/* {console.log('2. Before QnA = ', curQ.text, compounds)} */}
				<QnA curQ={curQ} setQ={setQ} setCurQComplete={setCurQComplete} 
						setPartInst={setPartInst} curPi={parts.indexOf(curPart)} curQComplete={curQComplete} 
						// required to implement autosave on next and previous buttons
						btnClicked={btnClicked} setAnsSaved={setAnsSaved}
						// button to click sandhi part 
						partTwoBtn={partTwoBtn}
						// Extra for compoud to be similar to OneQuestion
						p={p} a={a} type={type} n={n} s={s} email={currentUser.email}
						// compounds
						compounds={compounds} setSidebar={setSidebar}
						// LQVerse's states
						curQ2={curQ} setQ2={setQ} setCurQComplete2={setCurQComplete} 
				/>
				{type==='lElement' &&
					<>
					{/* 
						- Backend should send list of L questions
						- Call a sub component which creates state curLQ based on parameter passed
							and returns QnA from it.
						- New button checkAll for lElement questions
					*/}
						<div>
							<QnA curQ={curQ2} setQ={setQ2} setCurQComplete={setCurQComplete2} 
								setPartInst={setPartInst} curPi={parts.indexOf(curPart)} curQComplete={curQComplete} 
								// required to implement autosave on next and previous buttons
								btnClicked={btnClicked} setAnsSaved={setAnsSaved}
								// button to click sandhi part 
								partTwoBtn={partTwoBtn}
								// Extra for compoud to be similar to OneQuestion
								p={p} a={a} type={type} n={n} s={parseInt(s, 10)+1} email={currentUser.email}
								// compounds
								compounds={compounds} setSidebar={setSidebar}
								// LQVerse's states
								curQ2={curQ2} setQ2={setQ2} setCurQComplete2={setCurQComplete2} 
							/>
						</div>
						<div className="widthVW fitHeight">
							<div className="KeepRight">
								<Button id='clickCRID' onClick={()=>clickCR()}>Check All</Button>
							</div>
						</div>
					</>
				}
			</div>

			
			<div className="oqBtns widthVW p-2 d-flex">
				<div className="prevNxtBtns">
					{n>0 && <Button onClick={()=>goto(`/p=${p}/a=${a}/${type}/${n}/${parseInt(s)-1}`)} variant="light" className="leftBtn">Previous</Button>}
					{<NextButton showNext={n<(total-1)} completed={curQComplete} link={`/p=${p}/a=${a}/${type}/${n}/${parseInt(s)+1}`} chLink ={`/p=${p}/a=${a}`} css="rightBtn"/> }
				</div>
			</div>	

			<div className="oqRightArea">
				<div  className="rightGrid">
					<RightSidebar curQ={curQ} part={curPart} sidebar={sidebar}/>
				</div>
			</div>
			
		</div>
		);

	} 
	else {
		return (<></>);
	}

}

export default OneQuestion;