import React, {useState, useEffect, useContext} from 'react';
import {Button, Form, FloatingLabel} from 'react-bootstrap';
import  {UserContext} from "../../../providers/UserProvider";

import { keyListener } from '../helpers/blocks';
import LoadingPage from "../helpers/LoadingPage";

import '../../css/Components.css';

const Vigraha =({curQ, cxmlid, setPartInst, curPi, setCurQComplete,
							btnClicked, setAnsSaved, setCompounds, type}) =>{
	// To autosave when next or previous buttons are clicked
	useEffect(()=>{
		if (btnClicked===true){
			checkAns();
			setAnsSaved(true);
		};
	}, [btnClicked]);
	
	const [loading, setLoading] =useState(false);

	const {currentUser} = useContext(UserContext);
	
	const [translation, setTranslation] =useState(curQ.uans[1]);
	const [paraphrase, setParaphrase] =useState(curQ.uans[0]);

	// console.log('translation:', translation);
	// console.log('paraphrase:', paraphrase);
	//Check User's Answer
	const checkAns =() => {
		const postObj = {
			method: 'POST',
			cache: 'no-cache',
			headers: {'Content-Type': 'application/json'},
			body: JSON.stringify({ uans:[paraphrase, translation], pi: curPi, email:currentUser.email,
											curPart: 'vigraha',
											curQ: {'p':curQ.p, 'a':curQ.a, 'type':curQ.type, 'n':curQ.n, 's': curQ.s, 
													'parts': curQ.parts, 'text': curQ.text, 'xmlid':cxmlid, 'total':curQ.total }
										})
		};
		const getFeedback =async () => {
			setLoading(true);

			const response = await fetch(process.env.REACT_APP_FLASK_API+'check_uans', postObj);
			const data = await response.json();
			setPartInst(data.analysis.inst);
			setCurQComplete(data.completed);
			// setCompounds(data.analysis.compounds);

			setLoading(false);
		};
		getFeedback();
	};

	//Enter = Submit
	const [btn, setBtn]=useState(null);
	useEffect(() => {
		if (btn){
			// create an eventListener
			console.log('vigraha keyListener');
			window.addEventListener('keydown', e=>keyListener(e, btn));
		}
	}, [btn]);
	useEffect(() =>{
		console.log('mounted vigraha');
		// cleanup this component
		return () => {
			console.log('unmounted vigraha');
			window.removeEventListener('keydown', e=>keyListener(e, btn));
		};
	}, [])
	
	return (
		<div>
			<LoadingPage loading={loading} />
			
			{curQ.takeInput &&
			<>
				<h5><span className="blueText">Q:</span>
					<span className="praja32px">
						{curQ.text.question}
					</span>
				</h5>
	
				<div className="FlexBox">
					<h5><span className="blueText">A:</span></h5>
					<div className="AnsForm">
						
						<Form>
							<FloatingLabel
								controlId="paraphrase"
								label="Paraphrase"
								className="mb-3"
							>
								<Form.Control autoFocus name="paraphrase" 
													as="textarea" value={paraphrase} 
													onChange={e=>setParaphrase(e.target.value)}			
													onFocus={e => {
														var val = e.target.value;
														e.target.value = '';
														e.target.value = val;
													}}
								/>
							</FloatingLabel>
							<FloatingLabel
								controlId="translation"
								label="Translation"
								className="mb-3"
							>
								<Form.Control name="translation" as="textarea" value={translation} 
													onChange={e=>setTranslation(e.target.value)}/>
							</FloatingLabel>
							{type !='lElement' && 
								<div className="KeepRight">
									<Button onClick={()=>checkAns()}
												ref={node=>setBtn(node)}
									>
										Submit
									</Button>
								</div>
							}
							{type==='lElement' && 
								<div className="KeepRight" style={{visibility:'hidden'}}>
									<Button onClick={()=>checkAns()}
												ref={node=>setBtn(node)}
									>
										Submit
									</Button>
								</div>
							}
						</Form>
						
					</div>
				</div>
			</>
			}
		</div>
	);
};

export default Vigraha;