import React, {useContext} from 'react';

import Button from 'react-bootstrap/Button';
import {UserContext} from '../providers/UserProvider';

import './css/Components.css';

const Home = () => {
	const {currentUser} =useContext(UserContext);
	const greeting =<h3>
								<br/> Welcome to The Sanskrit Library's
								<br /> <span style={{fontFamily:'praja', fontSize:'26pt'}}>संक्षिप्तमहाभारत</span> Exercise Platform !
							</h3>
	
	
	const notLoggedIn =() =>(
		<>
			{greeting}
			<br/>
			<h5>Please login to proceed !</h5>
			<div className="text-center mt-3">
				<Button href="/login" variant="primary" size="lg" style={{color:'white'}}>Login</Button>
			</div>
		</>
	);
	const loggedIn =() =>(
		<div style={{textAlign:'center'}}>
			{greeting}
			<br/>
			{/* <h5>Please proceed to the <span style={{fontFamily:'praja', fontSize:'26pt'}}>अध्याय ५५</span> of <span style={{fontFamily:'praja', fontSize:'26pt'}}>पर्वन् १</span></h5> */}
			<h5>Please proceed to </h5>

			<div className="text-center mt-3">
				<Button href="/p=1/a=55" variant="primary" size="lg" 
							 style={{color:'white', paddingTop:'0', paddingBottom:'0'}}>
					{/* <span style={{fontFamily:'praja', fontSize:'26pt'}}>अध्याय ५५</span> */}
					<span>The table of contents</span>
				</Button>
			</div>
		</div>
	);

	return(
		
		<div className="Home"  style={{ wordWrap:"break-word" }}>
			{/* {console.log('@Home.js:', currentUser)} */}
			{currentUser===null && notLoggedIn()}
			{currentUser!==null && loggedIn()}
		</div>
	
	);
};

export default Home;
