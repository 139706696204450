import React, {useContext, useEffect, useState} from 'react';

import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import SandhiAnalysisPopUp from '../errorPopUp/SandhiAnalysisPopUp';

import BigText from '../helpers/BigText';
import LoadingPage from "../helpers/LoadingPage";

import { keyListener } from '../helpers/blocks';
import { UserContext } from '../../../providers/UserProvider';

import '../../css/Components.css';

// This function handles both sandhi and sandhi analysis type of question's parts
const CompoundSandhiAnalysis =({curQ, cxmlid, setPartInst, curPi, setCurQComplete,
													btnClicked, setAnsSaved, setCompounds, type, ch=20
											}) =>{
	// To autosave when next or previous buttons are clicked
	useEffect(()=>{
		if (btnClicked===true){
			checkAns();
			setAnsSaved(true);
		};
	}, [btnClicked]);
	
	const [loading, setLoading] =useState(false);
	const {currentUser} =useContext(UserContext);

	const [uans, setUans] = useState(curQ.uans); 

	//Check User's Answer
	const [analysis, setAnalysis] = useState({});
	const [qText, setQtext] = useState(curQ.text.question);//Just a provision to use in the future if the question text is changed after the backend call
	const checkAns = () => {
		const postObj = {
			method: 'POST',
			cache: 'no-cache',
			headers: {'Content-Type': 'application/json'},
			body: JSON.stringify({ uans:uans, pi: curPi, email:currentUser.email,
											curPart: 'compound-sandhi-analysis',
											curQ: {'p':curQ.p, 'a':curQ.a, 'type':curQ.type, 'n':curQ.n, 's': curQ.s,
													'parts': curQ.parts, 'text': curQ.text, 'xmlid': cxmlid, 'total':curQ.total}
										})
		}
		const getFeedback =async () => {
			setLoading(true);

			const response = await fetch(process.env.REACT_APP_FLASK_API+'check_uans', postObj);
			const data = await response.json();
			// console.log("data.analysis:", data.analysis);
			setCurQComplete(data.completed);
			// setCompounds(data.analysis.compounds);

			if(Object.keys(data.analysis).length) {
				// console.log("data.analysis found !");
				setAnalysis(data.analysis);
				setPartInst(data.analysis.inst);
			}

			setLoading(false);
		}
		getFeedback();
	};

	//Enter = Submit
	const [btn, setBtn]=useState(null);
	useEffect(() => {
		if (btn){
			// create an eventListener
			console.log('compound sandhi analysis keyListener');
			window.addEventListener('keydown', e=>keyListener(e, btn));
		}
	}, [btn]);
	useEffect(() =>{
		console.log('mounted Compound Sandhi Analysis');
		// cleanup this component
		return () => {
			console.log('unmounted Compound Sandhi Analysis');
			window.removeEventListener('keydown', e=>keyListener(e, btn));
		};
	}, [])

	return (
		<div>
			<LoadingPage loading={loading} />
			
			{curQ.takeInput &&
			<>
				<span style={{fontFamily:'Praja'}}>
					{/* {console.log('qText:', typeof(qText[0]))} */}
					{typeof(qText)==='object'
					?
						<div className="d-flex align-items-center">
							<BigText ch={ch} sp="Q:"/>
							<div className="analysis">
								<BigText ch={ch} text={qText[0]} />
								<h4>{qText[1]}</h4>
							</div>
						</div>
					:
						<BigText ch={ch} sp="Q:" text={qText}/>
					}
				</span>
	
				<div className="FlexBox">
					<h5><span className="blueText">A:</span></h5>
					<div className="AnsForm">
					{analysis.show && 
							// <Feedback />
							<div>
								<div className="analysis" style={{fontFamily:'Praja'}}>							
									<SandhiAnalysisPopUp 
										devaUnits={analysis.text.devaUnits}
										romanUnits={analysis.text.romanUnits}
									/>
								</div>
							</div>
						}

						<Form>
							<Form.Control autoFocus as="textarea" value={uans}
												placeholder="Type your answer"
												onChange={e=>setUans(e.target.value)}
												onFocus={e => {
													var val = e.target.value;
													e.target.value = '';
													e.target.value = val;
												}}
							/>
							{type !='lElement' && 
								<div className="KeepRight">
									<Button onClick={()=>checkAns()} ref={node=>setBtn(node)}>Check</Button>
								</div>
							}
							{type ==='lElement' && 
								<div className="KeepRight" style={{visibility:'hidden'}}>
									<Button onClick={()=>checkAns()} ref={node=>setBtn(node)}>Check</Button>
								</div>
							}
						</Form>

					</div>
				</div>
			</>
			}
		</div>
	);
};

export default CompoundSandhiAnalysis;