import React,  {memo} from 'react';

// import Devanagari from './Devanagari';
import Transliteration from './Transliteration';
import Sandhi from './Sandhi';
import Identification from './Identification';

import Syntax from './Syntax';
import Translation from './Translation';
import Compounds from './Compounds';


import LQVerse_Transliteration from './LQVerse_Transliteration';

// import RightSidebar from '../RightSidebar';
// import {UserContext} from '../../providers/UserProvider';

import '../css/Components.css';

const QnA =memo(({curQ, setQ, setCurQComplete, 
								setPartInst, curPi, curQComplete, 
								// states required to implement autosave on next and previous buttons
								btnClicked, setAnsSaved,
								// button to click sandhi part 
								partTwoBtn,
								// extra for compounds to be similar to OneQuestion
								p, a, type, n, s, email,
								// compounds
								compounds, setSidebar,
								// LQVerse's second set of states
								curQ2, setQ2, setCurQComplete2, 
							}) =>{
	
	// const {currentUser} =useContext(UserContext); 
	console.log(curQ.curPart, p, a, n, s);
	const partComponent = (curPart, takeInput) =>{
		switch(curPart){
			case "transliteration":
				return <Transliteration curQ={curQ} setQ={setQ} setCurQComplete={setCurQComplete} 
					curPi={curPi} type={type} setPartInst={setPartInst} 
					setAnsSaved={setAnsSaved} btnClicked={btnClicked} partTwoBtn={partTwoBtn}/>;
				// return <LQVerse_Transliteration curQ={curQ} setQ={setQ} setCurQComplete={setCurQComplete} 
				// 	curQ2={curQ2} setQ2={setQ2} setCurQComplete2={setCurQComplete2} 
				// 	curPi={curPi} type={type} setPartInst={setPartInst} 
				// 	setAnsSaved={setAnsSaved} btnClicked={btnClicked} partTwoBtn={partTwoBtn}/>;
			
			case "sandhi-analysis":
				return <Sandhi curQ={curQ} curPi={curPi} setCurQComplete={setCurQComplete} 
					type={type} setPartInst={setPartInst} setAnsSaved={setAnsSaved} btnClicked={btnClicked}/>;

			case "identification":
				return <Identification curQ={curQ} curPi={curPi} setCurQComplete={setCurQComplete}  
					type={type} setPartInst={setPartInst} setAnsSaved={setAnsSaved} btnClicked={btnClicked}/>;
			
			case "syntax":
				return <Syntax curQ={curQ} curPi={curPi} setCurQComplete={setCurQComplete}
					type={type} setPartInst={setPartInst} setAnsSaved={setAnsSaved} btnClicked={btnClicked}/>;

			case "translation":
				return <Translation curQ={curQ} curPi={curPi} setCurQComplete={setCurQComplete}
					type={type} setPartInst={setPartInst} setAnsSaved={setAnsSaved} btnClicked={btnClicked}/>;

			// Compound special case:
			case "compounds":
				return <Compounds parentQ={curQ} parentPi={curPi} setParentQComplete={setCurQComplete}
							setParentPartInst={setPartInst} setAnsSaved={setAnsSaved} btnClicked={btnClicked}
							p={p} a={a} type={type} n={n} s={s} email={email} compoundsInit={compounds} setSidebar={setSidebar}/>;

			default:
				if (takeInput)
					return "Work in progress ! Please come back later !";
				else
					return '';
		}
	}

	// console.log('3. @ QnA = ', curQ)
	return (
		<div>
			<div className="QnA widthVW">
				{/* {console.log("@QnA", curQ.curPart, curQ.takeInput)} */}
				{partComponent(curQ.curPart, curQ.takeInput)}
			</div>
		</div>
	);
});

export default QnA;