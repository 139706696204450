import React from 'react';
import { Link } from 'react-router-dom';

import Tooltip from 'react-bootstrap/Tooltip'
import ProgressBar from 'react-bootstrap/ProgressBar';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'

import '../css/Components.css';

// const radius = 30;

const Title = ({title, progress, to, restricted}) => {

	return(
		<>
		<Link to={to} >
			<div className='titleCard'>
				<div className="innerCard">
					<p style={{fontSize:'85%', marginBottom:'0px'}}><span dangerouslySetInnerHTML={{__html: title }} />
					</p>
				</div>
				<ProgressBar now={progress} label={`${progress}%`}/>
			</div>
		</Link>	
		{/* {restricted //Not used right now
		?
			<OverlayTrigger
				placement="top"
				delay={{ show: 5, hide: 100 }}
				overlay= {<Tooltip>Please complete 30% of the previous chapter first.</Tooltip>}
			>
			<div className='titleCard restrictedTitle'>
				<div className="innerCard">
					<p style={{fontSize:'85%', marginBottom:'0px'}}><span dangerouslySetInnerHTML={{__html: title }} />
					</p>
				</div>
				<ProgressBar now={progress} label={`${progress}%`}/>
			</div>
			</OverlayTrigger>
		:
			<Link to={to} >
			<div className='titleCard'>
				<div className="innerCard">
					<p style={{fontSize:'85%', marginBottom:'0px'}}><span dangerouslySetInnerHTML={{__html: title }} />
					</p>
				</div>
				<ProgressBar now={progress} label={`${progress}%`}/>
			</div>
			</Link>	
		} */}
		</>
	);
};

export default Title;